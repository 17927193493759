/** @format */

import apis from "../../../utils/apis";

export const getJobLineItemsByJobId = (
  setRows,
  setTempData,
  setLoad,
  jobId,
  setLineItemsForAutoselect,
  setOpenAllRows
) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/job/line-items/${jobId}`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      res.data.sort(function (a, b) {
        return a.categorySequenceId - b.categorySequenceId;
      });

      // --------------------------Sorting-for-autoselectlineitem----------------------------
      let lineItems = [];
      let filteredData = res.data.map((obj) => {
        obj.items.map((item) => {
          lineItems.push({
            itemName: item.itemName,
            itemDescription: item.itemDescription,
            type: item.type,
            uom: item.uom,
            unitCost: item.actualUnitCost,
            qty: item.actualQty,
            gst: 0,
            itemGroupName: item.itemGroupName,
          });
        });
      });
      setLineItemsForAutoselect(lineItems);
      // ----------------------------------------------------------------------------------------
      setRows(res.data);
      setTempData(res.data);
      setLoad(false);
    })
    .catch((err) => console.log("No costings data "));
};

export const getJobLineItemsByJobIdForAutoselect = (
  setJobLineItemsForAutoselect,
  jobId,
  setDataLoaded
) => {
  console.log("getJobLineItemsByJobIdForAutoselect");
  apis
    .GET({
      endpoint: `/job/line-items/list/${jobId}`,
    })
    .then((res) => {
      setDataLoaded(true);

      let lineItems = res.data.map((obj) => {
        return {
          lineItemId: obj.lineItemId,
          itemName: obj.itemName,
          itemCode: obj.itemCode,
          itemDescription: obj.itemDescription,
          itemGroupName: obj.itemGroupName,
          itemGroupCode: obj.itemGroupCode,
          type: obj.type,
          uom: obj.uom,
          unitCost: obj.actualUnitCost,
          qty: obj.actualQty,
          gst: obj.gst,
        };
      });
      setJobLineItemsForAutoselect(lineItems);
    })
    .catch((err) => console.log("No costings data "));
};

export const getJobLineItemsGroupByJobIdForAutoselect = (jobId, setJobLineItemsGroupForAutoselect) => {
  console.log("getJobLineItemsByJobIdForAutoselect");
  apis
    .GET({
      endpoint: `/job/line-items/groups/${jobId}`,
    })
    .then((res) => {
      console.log("groupssss", res.data);
      setJobLineItemsGroupForAutoselect(res.data);
    })
    .catch((err) => console.log("No costings data "));
};

export const createJobLineItems = (
  setRows,
  arr,
  setRoundLoader,
  jobId,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  //setOpen(false);
  setRoundLoader(true);
  // var jobId = '5f5cc19f-f2d0-46c8-ad4e-7d0bb92377d8';
  apis
    .PUT({
      endpoint: `/job/line-items/${jobId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setRows(arr);
      setRoundLoader(false);
      setSnackbarMSG("Costing Updated successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      console.log("data posting >>>", arr);
    });
};

/* --------------------------- */
export const updateJobLineItems = (
  rows,
  jobId,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRows,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/job/line-items/${jobId}`,
      payload: rows,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setRows(res.data);
      setOpenSnackbar(true);
      setSnackbarMSG("LineItems updated Successfully");
      setSnackbarSeverity("success");
      setRoundLoader(false);
      console.log("data posting >>>", res.message);
    });
};
/* --------------------------- */
/* NOT USED API */
export const getlineItemsByJobId = (setRows, jobId) => {
  apis
    .GET({
      endpoint: `/job/line-items/${jobId}`,
    })
    .then((res) => {
      // res.data.sort(function (a, b) {
      //   return a.categorySequenceId - b.categorySequenceId;
      // });
      setRows(res.data);
    })
    .catch((err) => console.log("No costings data "));
};
/* --------------------------- */

export const getJobLineItemsByJobIdForPdf = (setTempPdfData, jobId) => {
  apis
    .GET({
      endpoint: `/job/line-items/${jobId}`,
    })
    .then((res) => {
      console.log("get actuals by id...");
      console.log(res.data);
      res.data.sort(function (a, b) {
        return a.categorySequenceId - b.categorySequenceId;
      });
      // console.log("Result after Sorting: ", res.data);

      setTempPdfData(res.data);
    })
    .catch((err) => console.log("No costings data "));
};
