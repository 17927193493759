/** @format */

import { Drawer, Grid, Table, TableHead, Stack, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  deleteInvoiceByInvoiceId,
  getAllInvoicesByOrderId,
  getInvoiceByInvoiceId,
  updateInvoice,
} from "./Invoice.helper";
import Typography from "@mui/material/Typography";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import ProgressBar from "../../UI/ProgressBar";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import MomentDate from "../../UI/MomentDate";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { Tooltip, Zoom } from "@mui/material";
import { Chip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import DateCreator from "../../UI/DateCreator";
import UpdateInvoiceLineItemsTable from "./UpdateInvoiceLineItemsTable";
import { checkProperties, getGst } from "../../UI/GlobalApi";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));
export default function InvoicesByOrder({
  orderId,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type,
  setRoundLoader,
  userConfigs,
  userConfigsuom,
  lineItemsForAutoselect,
  lineItemsGroupsForAutoselect,
}) {
  const classes = useStyles();
  const [invoices, setInvoices] = useState([]);
  const [isBtnDisbl, setIsBtnDisbl] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [invoiceState, setInvoiceState] = React.useState({
    right: false,
  });
  const [invoiceData, setInvoiceData] = useState({
    orderId: "",
    referenceNo: "",
    invoiceDate: "",
    notes: "",
    status: "",
    totalAmount: "",
    invoiceLineItems: [],
  });
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [invoiceStatus, setInvoiceStatus] = useState(["RECIEVED", "PAID", "CANCELLED"]);

  // -------------------------------------------------------------------------------

  const handleClose = () => {
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  const handleYes = () => {
    deleteInvoiceByInvoiceId(
      invoiceToDelete.orderId,
      invoiceToDelete.invoiceId,
      invoices,
      setInvoices,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      type
    );
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  const handleNo = () => {
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  const handleDeleteInvoice = (invoice) => {
    setInvoiceToDelete(invoice);
    setConfirmDelete(true);
  };
  // --------------------------------------------------------------------------------------
  useEffect(() => {
    getAllInvoicesByOrderId(orderId, setInvoices, setDataLoaded, type);
  }, []);
  // --------------------------------------------------------------------------------------

  useEffect(() => {
    if (invoiceDate.$d) {
      setInvoiceData({
        ...invoiceData,
        invoiceDate: invoiceDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
    }
  }, [invoiceDate]);
  // --------------------------------------------------------------------------------------
  const toggleInvoiceDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setInvoiceState({ ...invoiceState, [anchor]: open });
  };
  // --------------------------------------------------------------------------------------
  const handleOpenInvoiceDrawer = (invoice) => {
    setIsBtnDisbl(false);
    getInvoiceByInvoiceId(invoice.orderId, invoice.invoiceId, setInvoiceData, setLoading, type);
    setInvoiceState({ ...invoiceState, ["right"]: true });
  };
  // --------------------------------------------------------------------------------------
  const handleInvoiceInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };
  // --------------------------------------------------------------------------------------
  const handleCancel = () => {
    setInvoiceState({ ...invoiceState, ["right"]: false });
  };
  // --------------------------------------------------------------------------------------
  const getInvoiceTotal = () => {
    var finalSubTotal = 0;
    var finalTax = 0;
    var finalTotal = 0;
    var itemTax = 0;
    var mult = 0;
    invoiceData.invoiceLineItems.filter((e) => {
      mult = parseInt(e.unitCost) * parseInt(e.qty);
      if (isNaN(mult)) mult = 0;
      finalSubTotal = finalSubTotal + mult;
      itemTax = (mult * getGst(e.gst)) / 100;
      if (isNaN(itemTax)) itemTax = 0;
      finalTax = finalTax + parseInt(itemTax);
      finalTotal = finalSubTotal + finalTax;
    });
    return finalTotal;
  };
  // --------------------------------------------------------------------------------------
  const handleUpdateInvoice = () => {
    let finalData = { ...invoiceData, totalAmount: getInvoiceTotal() };
    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      updateInvoice(
        finalData,
        invoices,
        setInvoices,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        type,
        setRoundLoader,
        setInvoiceState
      );
    }
  };
  // --------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Box sx={{ margin: 1 }}>
            {dataLoaded == false ? (
              <>
                <br />
                <Typography variant="h6" gutterBottom component="div">
                  Invoices
                </Typography>
                <ProgressBar />
                <br />
              </>
            ) : (
              <>
                {invoices.length === 0 ? (
                  <>
                    <br />
                    <EmptyEstimatesDialog
                      heading="Empty Invoices"
                      msg="You don't have any invoices in this order  yet.
            Click on 'Invoice' icon above table to create new invoice"
                    />
                    <br />
                  </>
                ) : (
                  <>
                    <Typography variant="h6" gutterBottom component="div">
                      Invoices
                    </Typography>

                    <Table
                      sx={{
                        borderBottom: "none",
                      }}
                      size="small"
                      aria-label="purchases"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: "10px", maxWidth: "10px" }}>
                            <strong>Ref No.</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                            <strong>Invoice Date</strong>
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: "130px", maxWidth: "130px" }}>
                            <strong>Notes</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                            <strong>Status</strong>
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: "10px", maxWidth: "10px" }}>
                            <strong> Action</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoices.map((invoice) => (
                          <>
                            <TableRow>
                              <TableCell sx={{ minWidth: "10px", maxWidth: "10px" }}>
                                {invoice.referenceNo}
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                                <MomentDate getDate={invoice.invoiceDate} />
                              </TableCell>
                              <TableCell align="left" sx={{ minWidth: "130px", maxWidth: "130px" }}>
                                {invoice.notes}
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                                <NumberFormat
                                  value={(invoice.totalAmount * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                                {invoice.status === "RECIEVED" && (
                                  <strong>
                                    <Chip size="small" label="Recieved" color="primary" />
                                  </strong>
                                )}
                                {invoice.status === "PAID" && (
                                  <strong>
                                    <Chip size="small" label="Paid" color="warning" />
                                  </strong>
                                )}
                                {invoice.status === "CANCELLED" && (
                                  <strong>
                                    <Chip size="small" label="Cancelled" color="secondary" />
                                  </strong>
                                )}
                              </TableCell>
                              <TableCell align="right" sx={{ minWidth: "10px", maxWidth: "10px" }}>
                                <ButtonGroup
                                  size="small"
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                >
                                  <Tooltip
                                    title="Edit Invoice/Reciept"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOpenInvoiceDrawer(invoice)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    title="Delete Invoice/Reciept"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      color="secondary"
                                      size="small"
                                      onClick={() => handleDeleteInvoice(invoice)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                    <br />
                  </>
                )}
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {confirmDelete && (
        <Dialog
          open={confirmDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleNo} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Drawer
        anchor={"right"}
        open={invoiceState["right"]}
        onClose={toggleInvoiceDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "80%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          {loading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Invoice/Reciept
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label={"Reference Number"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={invoiceData.referenceNo}
                  name="referenceNo"
                  onChange={(e) => handleInvoiceInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  required
                  onChange={(event, newValue) => {
                    setInvoiceData({ ...invoiceData, status: newValue });
                  }}
                  value={invoiceData.status}
                  options={invoiceStatus}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Status" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Stack sx={{ width: "100%" }}>
                  <DateCreator
                    expiryDate={invoiceDate}
                    setExpiryDate={setInvoiceDate}
                    label={"Invoice Date"}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <TextareaAutosize
                  name="notes"
                  variant="outlined"
                  minRows={4}
                  aria-label="maximum height"
                  placeholder="Notes"
                  value={invoiceData.notes}
                  style={{
                    width: 1490,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                  onChange={(e) => handleInvoiceInputChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <UpdateInvoiceLineItemsTable
                  invoiceData={invoiceData}
                  setInvoiceData={setInvoiceData}
                  userConfigs={userConfigs}
                  userConfigsuom={userConfigsuom}
                  lineItemsForAutoselect={lineItemsForAutoselect}
                  lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                  setShowSaveButton={setShowSaveButton}
                />
              </Grid>
              <Grid
                container
                xs={12}
                mt={2}
                style={{
                  float: "right",
                }}
              >
                {showSaveButton && (
                  <Grid xs={12} sm={12} align="right">
                    {isBtnDisbl ? (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        onClick={handleUpdateInvoice}
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        onClick={handleUpdateInvoice}
                        variant="contained"
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Update
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleCancel}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
