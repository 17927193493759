/** @format */

import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  Grid,
  Snackbar,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useParams } from "react-router-dom";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import ProgressBar from "../../UI/ProgressBar";
import Paper from "@mui/material/Paper";
import { getLineItemsGroupsName, getVariationsByJoblineitemId } from "./variation.helper";
import { Tooltip, Zoom } from "@mui/material";
import {
  getAllVariations,
  getAllVariationsById,
  getVariationById,
  updateVariation,
} from "./variation.helper";
import VariationLineItemsTable from "./VariationLineItemsTable";
import EditIcon from "@mui/icons-material/Edit";
import ErrorStyles from "../../UI/ErrorStyles";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import CommonInfo from "../../info/CommonInfo";
import infoArray from "../../info/commonInfo.json";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import DateCreator from "../../UI/DateCreator";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import AddIcon from "@mui/icons-material/Add";
import DeleteAlertPop from "./DeleteAlertPop";
import { checkProperties } from "../../UI/GlobalApi";
import {
  getJobLineItemsByJobId,
  getJobLineItemsByJobIdForAutoselect,
  getJobLineItemsGroupByJobIdForAutoselect,
} from "../actual_costings/helpers.actual.costings";
import { VARIATION_WRITE, VARIATION_DELETE } from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import VariationDrawer from "../Variation/VariationDrawer";
import { getGst, getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import { styled, lighten, darken } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled("ul")({
  padding: 0,
});
function Variation({ callFrom, lineItemId }) {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const { jobId } = useParams();
  // console.log("JobId in Variations:", jobId);
  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [variationList, setVariationList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [drawerLoading, setDrawerLoading] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [responsive, setResponsive] = useState("standard");
  const [print, setPrint] = useState(true);
  const [groupsInfo, setGroupsInfo] = useState([]);
  const [roundLoader, setRoundLoader] = useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [variationData, setVariationData] = useState({
    variationId: "",
    description: "",
    details: "",
    status: "",
    statusDate: "",
    variationLineItems: [],
  });
  const [statusDate, setStatusDate] = useState("");
  const [selectableRows, setSelectableRows] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [showSaveButton, setShowSaveButton] = useState(true);

  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const [lineItemsForAutoselect, setLineItemsForAutoselect] = React.useState([]);
  const [lineItemsGroupsForAutoselect, setLineItemsGroupsForAutoselect] = React.useState([]);
  const [dummyRows, setDummyRows] = React.useState([]);
  const [expiryDate, setExpiryDate] = useState();
  const [dummyTempData, setDummyTempData] = React.useState([]);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState();
  const handleDelete = (variationId) => {
    setDeleteItemId(variationId);
    console.log("cncle");
    setConfirmDelete(true);
    setIsEdit(false);
  };

  let purchaseItemcount = 1;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCancel = () => {
    setState({ ...state, ["right"]: false });
    setIsEdit(false);
  };

  // *****************************DATA-TABLE-Column*********************************

  const columns = [
    {
      name: "variationNo",
      label: "Variation No.",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        setCellProps: () => ({
          style: { minWidth: "600px", maxWidth: "600px" },
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              className="link"
              target="_self"
              path="/$"
              component={`value`}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                handleOpenDrawer(
                  variationList[tableMeta.rowIndex].variationId,
                  variationList[tableMeta.rowIndex].jobId
                );
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "client",
      label: "Client Name",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return value?.name;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "totalVariationAmount",
      label: "Total Amount",
      options: {
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "center",
        }),
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <span>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}
          </span>
        ),
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        style: { minWidth: "50px", maxWidth: "50px" },
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === "DRAFT") {
            return <Chip size="small" label="Draft" />;
          } else if (value === "REJECTED") {
            return <Chip size="small" label="Rejected" color="error" />;
          } else if (value === "ACCEPTED") {
            return <Chip size="small" label="Accepted" color="primary" />;
          } else if (value === "SENT") {
            return <Chip size="small" label="Sent" color="warning" />;
          }
        },
      },
    },
    {
      name: "variationId",
      label: "	Actions",
      options: {
        //style: { minWidth: "50px", maxWidth: "50px" },
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "5px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (variationId, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT VARIATION" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  aria-label="edit-variation"
                  onClick={(e) => {
                    handleOpenDrawer(
                      variationList[tableMeta.rowIndex].variationId,
                      variationList[tableMeta.rowIndex].jobId
                    );
                  }}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="DELETE VARIATION" placement="top" TransitionComponent={Zoom} arrow>
                <Button size="small" color="secondary" onClick={(e) => handleDelete(variationId)}>
                  <DeleteIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(VARIATION_WRITE) ? true : false,
      },
    },
  ];

  // *****************************DATA-TABLE-Options*********************************

  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsOnClick: true,
    responsive,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    onTableChange: (action, state) => {},
    onRowsDelete: (rowsDeleted, newData) => {},
  };

  const handleOpenDrawer = (variationId, jobId) => {
    // setJobId(jobId);
    getJobLineItemsGroupByJobIdForAutoselect(jobId, setLineItemsGroupsForAutoselect);
    getJobLineItemsByJobIdForAutoselect(setLineItemsForAutoselect, jobId, setDummyRows);
    getVariationById(variationId, setVariationData, setDrawerLoading, setStatusDate);
    setState({ ...state, ["right"]: true });
  };

  const handleVariationSubmit = (e) => {
    let finalData = { ...variationData, statusDate: statusDate };
    let valid = true;
    let errMsg = checkProperties(finalData);

    if (
      (finalData.status === "Accepted" || finalData.status === "Rejected") &&
      finalData.statusDate === ""
    ) {
      errMsg = errMsg + "Status Date, ";
    }
    if (errMsg !== "") valid = false;

    if (valid === true) {
      updateVariation(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setState,
        variationList,
        setVariationList
      );
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // *****************************************************************************

  useEffect(() => {
    if (jobId) {
      if (callFrom === "jobLineItem") {
        getVariationsByJoblineitemId(setVariationList, setLoading, jobId, lineItemId);
      } else {
        getAllVariationsById(setVariationList, setLoading, jobId);
      }
    } else {
      getAllVariations(setVariationList, setLoading);
    }
  }, [wantToCallGet]);

  // useEffect(() => {
  //   getJobLineItemsByJobId(
  //     setDummyRows,
  //     setDummyTempData,
  //     setDummyLoad,
  //     jobId,
  //     setLineItemsForAutoselect
  //   );
  // }, []);

  useEffect(() => {
    statusDate.$d &&
      setStatusDate(
        statusDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      );
  }, [statusDate]);

  // *****************************CREATE VARIATION DRAWER*********************************
  const [newDrawerOpen, setNewDrawerOpen] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [variationObj, setVariationObj] = useState([]);
  const [variationObject, setVariationObject] = useState([]);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("DRAFT", "SENT", "ACCEPTED", "REJECTED");
  const [isEdit, setIsEdit] = useState(false);
  const [userConfigs, setUserConfigs] = useState({});
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const [groupObject, setGroupObject] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    /* i copied object using JSON method bcz  i have to copy object without any object refrence */
    setVariationObject(JSON.parse(JSON.stringify(variationObj)));
    console.log("useEffect", variationObj);
  }, [variationObj]);

  useEffect(() => {
    getUserConfigsTypes(setUserConfigs);
    getUserConfigsUom(setUserConfigsuom, setDummyLoad);
  }, []);
  useEffect(() => {
    console.log("LINE iTEM USEEFFECT");
    getJobLineItemsGroupByJobIdForAutoselect(jobId, setLineItemsGroupsForAutoselect);
    getJobLineItemsByJobIdForAutoselect(setLineItemsForAutoselect, jobId, setDataLoaded);
    getLineItemsGroupsName(jobId, setGroupsInfo, setGroupObject);
  }, []);
  const openVariance = () => {
    /* storing Variation button name when we clicked on create Variation button */

    setVariationObj(variationObj);
    setNewDrawerOpen({ ...newDrawerOpen, ["right"]: true });
  };

  const handleLineItemAdd = () => {
    // setDisableSave(false);
    // let lastBeforeRowLineId;
    // if (variationObject.length === 0) {
    //   lastBeforeRowLineId = 0;
    // } else {
    //   lastBeforeRowLineId = variationObject[variationObject.length - 1].itemCode;
    // }
    setIsEdit(true);
    setVariationObject([
      ...variationObject,
      {
        jobLineItemId: null,
        itemId: null,
        itemCode: "",
        itemName: "",
        itemGroupCode: "",
        itemGroupName: "",
        itemDescription: "",
        itemCategory: "",
        optionId: null,
        optionName: null,
        type: "",
        qty: 1,
        uom: "",
        unitCost: 0,
        markupPercentage: 0,
        gst: true,
      },
    ]);
  };

  function handleChangeGroupObject(e, index, newValue) {
    console.log("idddndex", newValue);
    if (newValue) {
      console.log("value", newValue);
      var list = JSON.parse(JSON.stringify(variationObject));
      list[index]["itemGroupName"] = newValue.groupName;

      list[index]["itemGroupCode"] = newValue.groupCode;
      //var listGroup = [...groupObject];
      //listGroup[index].groupName = newValue;
      setVariationObject(list);
      console.log("groupObjectcc", groupObject);
    }
  }

  const handleVariationCancal = () => {
    setVariationObject(variationObj); /* Reset our old data after cancel edit/add operation  */

    setIsEdit(false);
  };
  /* ---------------------------------------------------------------- */

  var total = 0;
  var markuptotalcost = 0;
  var t = variationObject.filter((orderItem) => {
    var totalCost = orderItem.qty * orderItem.unitCost;
    if (orderItem.markupPercentage != 0) {
      var markup = (totalCost * orderItem.markupPercentage) / 100;
    } else {
      var markup = 0;
    }
    markuptotalcost = markup + totalCost;
    total = total + markuptotalcost;
    parseInt(total.toFixed(2));
  });
  var gstTotal = 0;
  var t = variationObject.filter((orderItem) => {
    var totalCost = orderItem.qty * orderItem.unitCost;
    var markup = 0;
    if (orderItem.markupPercentage != 0) {
      markup = (totalCost * orderItem.markupPercentage) / 100;
    }
    markuptotalcost = markup + totalCost;
    var rowGST = 0;
    if (orderItem.gst != 0) {
      var rowGST = (markuptotalcost * orderItem.gst) / 100;
    }
    parseInt(rowGST.toFixed(2));
    gstTotal = gstTotal + rowGST;
    parseInt(gstTotal.toFixed(2));
  });
  /* ---------------------------------------------------------------- */
  const [valuex, setValuex] = React.useState([]);

  var newItem, newInputItem;
  const AutocompleteGroup = (props) => {
    const { index, orderItem } = props;
    const filter = createFilterOptions();
    //var newItem;
    // const existingGroupValue = groupObject.find(
    //   (group) => group.groupName === orderItem.itemGroupName
    // );

    // if (existingGroupValue) {
    //   groupsInfo.filter((newVal, Grouplistindex) => {
    //     if (newVal.groupName === orderItem.itemGroupName) {
    //       newItem = Grouplistindex;
    //     }
    //   });
    // } else {
    //   if (orderItem.itemGroupName) {
    //     console.log("orderItem.itemGroupName", orderItem.itemGroupName);

    //     var listGroup = [...groupObject];

    //     var newGroup = {
    //       groupCode: listGroup.length + 1,
    //       groupName: orderItem.itemGroupNames,
    //     };
    //     newItem = listGroup.length + 1;
    //     listGroup.push(newGroup);
    //     setGroupsInfo(listGroup);
    //     console.log("newGroup", listGroup);
    //   }
    // }

    return (
      <Autocomplete
        freeSolo
        size="small"
        value={valuex[index]}
        onChange={(event, newValue) => {
          var selectListGroup = [...valuex];

          if (typeof newValue === "string") {
            selectListGroup[index] = {
              title: newValue,
            };
            setValuex(selectListGroup);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input

            var listGroup = [...groupObject];
            var newGroup = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            selectListGroup[index] = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            setValuex(selectListGroup);
            handleChangeGroupObject(event, index, newGroup);
          } else {
            selectListGroup[index] = newValue;
            setValuex(selectListGroup);
            handleChangeGroupObject(event, index, newValue);
          }
          console.log("newValuevvvv", valuex);
        }}
        // onChange={(event, newValue) => {
        //   handleChangeGroupObject(event, index, newValue);
        // }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.groupName);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={(option) => {
          console.log("option", option);
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.groupName;
        }}
        //getOptionLabel={(option) => option.groupName}
        options={groupObject.map((option) => option)}
        renderInput={(params) => <TextField {...params} />}
      />
    );
  };

  // ----------------------------Calculations-------------------------------------------------

  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;
  variationObject.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (isNaN(mult)) mult = 0;
    finalSubTotal = finalSubTotal + mult;
    itemTax = (mult * getGst(e.gst)) / 100;
    if (isNaN(itemTax)) itemTax = 0;
    finalTax = finalTax + parseInt(itemTax);
    finalTax = finalTax + (finalTax * parseInt(e.markupPercentage)) / 100;
    finalTotal = finalSubTotal + finalTax;
    finalTotal = finalTotal + (finalTotal * parseInt(e.markupPercentage)) / 100;
  });

  // ----------------------------Calculations-Ends-------------------------------------------------
  const handleAutoselectLineItem = (i, row, newValue) => {
    var list = JSON.parse(JSON.stringify(variationObject));
    if (typeof newValue === "string") {
      list[i] = {
        ...list[i],
        itemDescription: newValue,
      };
      setVariationObject(list);
    } else if (typeof newValue === "object") {
      list[i] = {
        ...list[i],
        jobLineItemId: newValue?.lineItemId,
        itemName: newValue?.itemName,
        itemDescription: newValue?.itemDescription,
        type: newValue?.type,
        uom: newValue?.uom,
        unitCost: newValue?.estimatedUnitCost,
        qty: newValue?.qty,
        gst: newValue?.gst,
        itemGroupName: newValue?.itemGroupName,
        itemGroupCode: newValue?.itemGroupCode,
        itemCode: newValue?.itemCode,
      };
      setVariationObject(list);
    }
  };

  const handleAutoselectLineItemName = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemName: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.qty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemCode: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.qty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemGroup = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: "",
          itemGroupName: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemGroupCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue,
          itemGroupName: "",
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setVariationObject(list);
      }
    }
  };

  const toggleNewDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setNewDrawerOpen({ ...newDrawerOpen, [anchor]: open });
  };

  // ----------------------------------------------------------------------------------------------

  return (
    <div>
      {roundLoader && <SimpleBackdrop />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: "80%",
              },
            }}
          >
            <div className={classes.Drawerroot}>
              {drawerLoading ? (
                <>
                  <br />
                  <ProgressBar />
                </>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      component="h5"
                      color="primary"
                      mb={1}
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Update Variation
                    </Typography>

                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      value={variationData.description}
                      required
                      size="small"
                      label="Description"
                      onChange={(e) =>
                        setVariationData({
                          ...variationData,
                          description: e.target.value,
                        })
                      }
                      className={classes.textField}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setVariationData({
                          ...variationData,
                          status: newValue,
                          statusDate: "",
                        });
                        setStatusDate("");
                      }}
                      defaultValue={variationData.status}
                      options={["DRAFT", "SENT", "ACCEPTED", "REJECTED"]}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Status" size="small" required />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextareaAutosize
                      name="Details"
                      variant="outlined"
                      minRows={3}
                      maxRows={3}
                      aria-label="maximum height"
                      placeholder="Details"
                      value={variationData.details}
                      style={{
                        width: 1490,
                        backgroundColor: `${
                          localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                        }`,
                      }}
                      onChange={(e) =>
                        setVariationData({
                          ...variationData,
                          details: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  {(variationData.status === "ACCEPTED" || variationData.status === "REJECTED") && (
                    <Grid item xs={12} sm={4}>
                      <Stack sx={{ width: "100%" }}>
                        <DateCreator
                          setExpiryDate={setStatusDate}
                          expiryDate={statusDate}
                          label={"Status Date"}
                        />
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <VariationLineItemsTable
                      variationData={variationData}
                      setValue={setVariationData}
                      jobId={jobId}
                      lineItemsForAutoselect={lineItemsForAutoselect}
                      lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                      setShowSaveButton={setShowSaveButton}
                    />
                  </Grid>
                  {showSaveButton && (
                    <Grid item xs={12} align="right">
                      <Button
                        color="primary"
                        size="small"
                        type="button"
                        variant="contained"
                        onClick={(e) => handleVariationSubmit(e)}
                        style={{
                          marginLeft: "10px",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        Update
                      </Button>

                      <Button
                        type="button"
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        style={{
                          marginLeft: "10px",
                          fontSize: "13px",
                          fontWeight: "600",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </div>
          </Drawer>

          <br />
          <Grid>
            {loading ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Grid item xs={1} sx={{ pb: 1 }} align="right">
                    {hasValidAccess(VARIATION_WRITE) && !(callFrom === "jobLineItem") && (
                      <Tooltip title="CREATE VARIANT" placement="top" TransitionComponent={Zoom} arrow>
                        <Button aria-label="add" size="small" onClick={openVariance}>
                          <AddIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                </div>
                {variationList.length === 0 ? (
                  <EmptyEstimatesDialog
                    heading="Empty Variations"
                    msg="You don't have any variations here yet.
            Click the 'Actual Costing'  above to get started, or choose from a template"
                  />
                ) : (
                  <div className={classes.container}>
                    <MUIDataTable
                      title={"Variations"}
                      data={variationList}
                      columns={columns}
                      options={options}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Grid>

          <br />
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
        {!callFrom === "jobLineItem" && (
          <Grid item xs={12}>
            <CommonInfo title={infoArray.variations.title} msg={infoArray.variations.msg} />
          </Grid>
        )}
      </Grid>
      <VariationDrawer
        purchaseItemcount={purchaseItemcount}
        variationObj={variationObject}
        setVariationObj={setVariationObject}
        setLoading={setLoading}
        expiryDate={expiryDate}
        setExpiryDate={setExpiryDate}
        setVariation={setVariationList}
        toggleDrawer={toggleDrawer}
        state={newDrawerOpen}
        setState={setNewDrawerOpen}
        jobId={jobId}
        setEdit={setIsEdit}
        userConfigs={userConfigs}
        userConfigsuom={userConfigsuom}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarMSG={setSnackbarMSG}
        setSnackbarSeverity={setSnackbarSeverity}
      />
      <DeleteAlertPop
        deleteItemId={deleteItemId}
        wantToCallGet={wantToCallGet}
        setWantToCallGet={setWantToCallGet}
        setConfirmDelete={setConfirmDelete}
        confirmDelete={confirmDelete}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarMSG={setSnackbarMSG}
        setSnackbarSeverity={setSnackbarSeverity}
        setRoundLoader={setRoundLoader}
      />
    </div>
  );
}

export default Variation;
