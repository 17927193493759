/** @format */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { makeStyles } from "@mui/styles";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Box, Button, Checkbox, Snackbar, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import { getUserConfigsTypes, updateConfig } from "./settings.helpers";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { SETTING_DELETE, SETTING_WRITE } from "../UI/GlobalVariables";
import { OAuthCtx } from "../../oauth/OAuthContext";
import ProgressBar from "../UI/ProgressBar";
import { Tooltip, Zoom } from "@mui/material";

const useStyles = makeStyles({
  table: {
    minWidth: 150,
  },
  oneRow: {
    borderBottom: "none",
    width: "10vw",
  },
  rowDark: {
    color: "#fff",
    fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
});

function TypesCompo() {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const classes = useStyles();
  const [isEdit, setEdit] = React.useState(false);

  const [userConfigs, setUserConfigs] = useState({});
  // const [arr, setArr] = React.useState([]);

  const [inputList, setInputList] = useState([]);
  const [draft, setDraft] = useState([]);
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    getUserConfigsTypes(setUserConfigs, setInputList, setTempList); //get all configs
  }, []);
  console.log("userConfigs types-- ", userConfigs);
  // console.log("userConfigs arr-- ",arr)

  /* ------------------------------------------------------------- */
  const handleEdit = () => {
    setDraft(inputList);
    setEdit(true);
  };
  /* ---------------------------------------------------------------- */
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleDeleteTypes = (e, value) => {
    const newData = inputList.filter((ele) => {
      // if (ele.value !== value) {
      return ele.value !== value;
      // }
    });
    console.log("newData", newData);
    setInputList(newData);
    setSnackbarSeverity("success");
    setSnackbarMSG("Types deleted successfully!");
    updateConfig("TYPES", newData, setOpenSnackbar);
  };

  const handleCheckbox = (e, i, row) => {
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = [...inputList];
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };
  console.log("inputList ", inputList);
  /* ---------------------------------------------------------------- */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(inputList));
    list[index][name] = value;
    setInputList(list);
  };

  /* ---------------------------------------------------------------- */
  const handleAdd = () => {
    setEdit(true);
    setInputList([...inputList, { value: "", enabled: false }]);
  };

  /* ---------------------------------------------------------------- */
  //logic to save data and put api

  const handleSave = () => {
    var tempList1 = inputList.filter((ele) => {
      return ele.value !== "";
    });

    setInputList(tempList1);
    setSnackbarSeverity("success");
    setSnackbarMSG("Types added successfully!");
    updateConfig("TYPES", tempList1, setOpenSnackbar);
    setEdit(false);
  };

  /* ------------------------------------------------------------- */
  const handleCancel = () => {
    console.log("tempList", tempList);
    setInputList(tempList);
    setEdit(false);
  };
  /* ------------------------------------------------------------- */

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={0} justifyContent="center" direction="column">
        {hasValidAccess(SETTING_WRITE) && (
          <Grid item align="right">
            <Box component="span" p={1}>
              {isEdit ? (
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                  <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                    <AddIcon onClick={handleAdd} fontSize="small" />
                    ADD
                  </Button>
                  <Button variant="contained" color="secondary" onClick={handleCancel} size="small">
                    <CloseIcon fontSize="small" />
                    CANCEL
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSave} size="small">
                    <CheckCircleOutlineIcon fontSize="small" />
                    SAVE
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                  <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                    <AddIcon onClick={handleAdd} fontSize="small" />
                    ADD
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleEdit} size="small">
                    <EditIcon fontSize="small" />
                    EDIT
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Grid>
        )}
        <Grid item align="center">
          {inputList.length === 0 ? (
            <ProgressBar />
          ) : (
            <Box component="span" p={1}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width="25%">
                        <strong>Types</strong>
                      </TableCell>
                      <TableCell align="center" width="3%">
                        Enabled
                      </TableCell>
                      {hasValidAccess(SETTING_DELETE) && (
                        <TableCell align="center" width="10%">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inputList.map((el, i) => {
                      return (
                        <TableRow>
                          {isEdit ? (
                            <>
                              <TableCell align="center">
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={el.value}
                                  name="value"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {el.enabled === true ? (
                                  <Checkbox
                                    size="small"
                                    name="enabled"
                                    value={true}
                                    checked
                                    onChange={(e) => handleCheckbox(e, i, el)}
                                    // onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    name="enabled"
                                    value={false}
                                    onChange={(e) => handleCheckbox(e, i, el)}
                                    // defaultChecked
                                    // onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align="left">{el.value}</TableCell>
                              <TableCell align="center">
                                {el.enabled === true ? (
                                  <Checkbox
                                    size="small"
                                    name="enabled"
                                    disabled={true}
                                    value={true}
                                    checked
                                    onChange={(e) => handleCheckbox(e, i, el)}
                                    // onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    name="enabled"
                                    disabled={true}
                                    value={false}
                                    onChange={(e) => handleCheckbox(e, i, el)}
                                    // defaultChecked
                                    // onChange={(e) => handleChangeGST(e, i, row)}
                                    // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                              </TableCell>
                            </>
                          )}

                          {hasValidAccess(SETTING_DELETE) && (
                            <TableCell align="center">
                              <Tooltip
                                title="DELETE TYPES"
                                placement="top"
                                TransitionComponent={Zoom}
                                arrow
                              >
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                >
                                  <Button
                                    size="small"
                                    color="secondary"
                                    onClick={(e) => handleDeleteTypes(e, el.value)}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </Button>
                                </ButtonGroup>
                              </Tooltip>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Grid>
      </Grid>

      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
export default TypesCompo;
