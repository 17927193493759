/** @format */

import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { secondaryListItems } from "./listItems";
import { NavigatorStyles } from "./NavigatorStyles";
import Switch from "@mui/material/Switch";
import logo from "../../../src/images/buildxap_logo.svg";
import DocumentMeta from "react-document-meta";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import SettingsApplications from "@mui/icons-material/SettingsApplications";
import SettingsNestedDrawer from "./SettingsNestedDrawer";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import GroupsIcon from "@mui/icons-material/Groups";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import TimelineIcon from "@mui/icons-material/Timeline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { InputAdornment, Tooltip, Zoom } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { OAuthCtx } from "../../oauth/OAuthContext";
import {
  BUSINESS_ACCOUNT_READ,
  BUSINESS_ACCOUNT_WRITE,
  CLIENT_READ,
  CONTACT_READ,
  COST_CENTRE_READ,
  ESTIMATE_READ,
  JOB_PLAN_READ,
  JOB_READ,
  QUOTE_READ,
  SETTING_READ,
  USER_AND_ROLE_READ,
  VARIATION_READ,
} from "../UI/GlobalVariables";
import ProgressBar from "../UI/ProgressBar";
import { Controller, useForm } from "react-hook-form";
import { getBusinessAccount, updateBusinessAccount } from "../UserAccount/businessAccount.helper";
import { Button, TextField } from "@mui/material";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SimpleBackdrop from "../UI/SimpleBackdrop";

const NavigatorSideBar = ({
  component: Component,
  toggleDark,
  settoggleDark,
  selectedIndex,
  setSelectedIndex,
  ...rest
}) => {
  const { hasValidAccess } = useContext(OAuthCtx);
  const [subDrawerOpen, setSubDrawerOpen] = React.useState(false);
  const [bussinessDrawerOpen, setBusinessDrawerOpen] = React.useState(false);
  const [bussinessDrawerLoading, setBusinessDrawerLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const [childSettingOpen, setChildSettingOpen] = React.useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleClose = (e) => {
    setState({ ...state, ["right"]: false });
  };
  const handleModeChange = () => {
    localStorage.setItem("dark-mode", !toggleDark);
    settoggleDark(!toggleDark);
  };
  useEffect(() => {
    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to

      settoggleDark(JSON.parse(localStorage.getItem("dark-mode")) || false);
    });
  }, []);

  const classes = NavigatorStyles();
  const [roundLoader, setRoundLoader] = useState(false);
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setSubDrawerOpen(false);
    setChildSettingOpen(false);
  };

  // -----------------------------functions-for-bussiness-acc-drawer---------------------------------
  const handleBusinessAccountDrawerOpen = () => {
    getBusinessAccount(reset, setBusinessDrawerLoading);
    commonToggle(true);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleBussinessAccountDetailsSubmit = (data) => {
    console.log("data", data);
    updateBusinessAccount(
      data,
      setState,
      setRoundLoader,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity
    );
  };

  const commonToggle = (open) => {
    setState({ ...state, ["right"]: open });
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  // -------------------------------------------------------------------------------------------

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [estimate, setEstimate] = useState([]);
  const meta = {
    title: rest.title + " - BuildXAP",
    description: "Construction app BuildXAP",
    canonical: "http://example.com/path/to/page",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "app, construction app, project manage",
      },
    },
  };
  const listCommonStyle = {
    textDecoration: "none",
    color: "inherit",
  };
  const iconBorder = {
    borderWidth: 1,
    borderStyle: "solid",
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  console.log("Busss read", hasValidAccess(BUSINESS_ACCOUNT_READ));
  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* NavigationBar and SideBar combined here */}
        <AppBar
          color="inherit"
          className={clsx(classes.appBar, open && classes.appBarShift)}
          style={{ boxShadow: "none" }}
        >
          <Toolbar className={classes.toolbar}>
            {open ? (
              <>
                <IconButton
                  style={iconBorder}
                  color="primary"
                  size="small"
                  aria-label="open drawer"
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <span style={{ marginRight: 15 }}></span>
              </>
            ) : (
              <IconButton
                style={iconBorder}
                color="primary"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {rest.title}
            </Typography>
            <FormControlLabel
              control={
                <Tooltip
                  title={toggleDark ? "TURN OFF DARK MODE" : "TURN ON DARK MODE"}
                  placement="top"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <MaterialUISwitch />
                </Tooltip>
              }
              label="Dark Mode"
              checked={toggleDark}
              onChange={handleModeChange}
              name="toggleDark"
            />
            <div
              className="header_icon"
              style={{
                display: "inline-flex",
                columnGap: 6,
              }}
            >
              {/* <Tooltip title="VIEW NOTIFICATIONS " placement="top" TransitionComponent={Zoom} arrow>
                <IconButton
                  style={iconBorder}
                  color="primary"
                  size="small"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  <NotificationsNoneIcon style={{ fontSize: 25 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="VIEW EMAIL " placement="top" TransitionComponent={Zoom} arrow>
                <IconButton
                  style={iconBorder}
                  color="primary"
                  size="small"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  <EmailIcon style={{ fontSize: 25 }} />
                </IconButton>
              </Tooltip> */}

              {hasValidAccess(BUSINESS_ACCOUNT_READ) && (
                <Tooltip
                  title="VIEW ACCOUNT DETAILS "
                  placement="top"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <IconButton
                    style={iconBorder}
                    color="primary"
                    size="small"
                    aria-label="open drawer"
                    onClick={handleBusinessAccountDrawerOpen}
                  >
                    <AccountCircleIcon style={{ fontSize: 25 }} />
                  </IconButton>
                </Tooltip>
              )}

              {/* <List>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gridColumnGap: "13px",
                                }}>
                                <ListItemIcon>
                                    <Switch
                                        checked={toggleDark}
                                        onChange={handleModeChange}
                                        name="toggleDark"
                                        inputProps={{
                                            "aria-label":
                                                "checkbox with default color",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Switch to Dark Mode" />
                            </div>
                        </List> */}
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          style={{ zIndex: 999 }}
          open={open}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <List disablePadding={true}>
              <ListItem
                className={classes.appBarSpacer}
                sx={{
                  borderBottom: "2px solid",
                  borderColor: toggleDark ? "#919eab52" : "rgba(0, 0, 0, 0.06)",
                }}
              >
                <Link
                  to="/"
                  style={{
                    display: "flex",
                  }}
                >
                  <img width="130" src={logo} alt="Buildxap logo" />
                </Link>
              </ListItem>
              {/* <Link to="/" style={listCommonStyle}>
                <ListItem
                  button
                  selected={selectedIndex === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </Link> */}

              {/* <Link to="/timeline" style={listCommonStyle}>
                <ListItem
                  button
                  selected={selectedIndex === 9}
                  onClick={(event) => handleListItemClick(event, 9)}
                >
                  <ListItemIcon>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Scheduling" />
                </ListItem>
              </Link> */}
              {/* <Link to="/dailylogs" style={listCommonStyle}>
                <ListItem
                  button
                  selected={selectedIndex === 16}
                  onClick={(event) => handleListItemClick(event, 16)}
                >
                  <ListItemIcon>
                    <RssFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Daily Logs" />
                </ListItem>
              </Link> */}
              {hasValidAccess(ESTIMATE_READ) && (
                <Link to="/estimates" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Estimates" />
                  </ListItem>
                </Link>
              )}
              {/* {hasValidAccess(QUOTE_READ) && (
                <Link to="/quoterequests" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemIcon>
                      <LocalLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quotes" />
                  </ListItem>
                </Link>
              )} */}
              {hasValidAccess(JOB_READ) && (
                <Link to="/jobs" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                  >
                    <ListItemIcon>
                      <WorkOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Jobs" />
                  </ListItem>
                </Link>
              )}
              {hasValidAccess(JOB_PLAN_READ) && (
                <Link to="/JobPlans" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Job Plans" />
                  </ListItem>
                </Link>
              )}
              {/* {hasValidAccess(VARIATION_READ) && (
                <Link to="/variations" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 13}
                    onClick={(event) => handleListItemClick(event, 13)}
                  >
                    <ListItemIcon>
                      <AutoAwesomeMotionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Variations" />
                  </ListItem>
                </Link>
              )} */}
              {hasValidAccess(CLIENT_READ) && (
                <Link to="/clients" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                  </ListItem>
                </Link>
              )}
              {hasValidAccess(CONTACT_READ) && (
                <Link to="/contacts" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                  >
                    <ListItemIcon>
                      <ContactsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contacts" />
                  </ListItem>
                </Link>
              )}
              {hasValidAccess(COST_CENTRE_READ) && (
                <Link to="/cost-centre" style={listCommonStyle}>
                  <ListItem
                    button
                    selected={selectedIndex === 14}
                    onClick={(event) => handleListItemClick(event, 14)}
                  >
                    <ListItemIcon>
                      <LocalAtmIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cost Centre" />
                  </ListItem>
                </Link>
              )}
              <Link to="/catalogue" style={listCommonStyle}>
                <ListItem
                  button
                  selected={selectedIndex === 15}
                  onClick={(event) => handleListItemClick(event, 15)}
                >
                  <ListItemIcon>
                    <MenuBookIcon />
                  </ListItemIcon>
                  <ListItemText primary="Catalogue" />
                </ListItem>
              </Link>

              {/* <Link to="/building/types" style={listCommonStyle}>
                            <ListItem
                                button
                                selected={selectedIndex === 13}
                                onClick={(event) => handleListItemClick(event, 13)}>
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Building Types" />
                            </ListItem>
                        </Link> */}
            </List>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "auto",
              }}
            >
              <Divider />

              {hasValidAccess(USER_AND_ROLE_READ) && (
                <>
                  <Link to="/users" style={listCommonStyle}>
                    <ListItem
                      button
                      selected={selectedIndex === 7}
                      onClick={(event) => handleListItemClick(event, 7)}
                    >
                      <ListItemIcon>
                        <GroupAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  </Link>
                  <Link to="/roles" style={listCommonStyle}>
                    <ListItem
                      button
                      selected={selectedIndex === 10}
                      onClick={(event) => handleListItemClick(event, 10)}
                    >
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Roles" />
                    </ListItem>
                  </Link>
                </>
              )}
              {hasValidAccess(SETTING_READ) && (
                <ListItem
                  button
                  onClick={() => {
                    handleDrawerOpen();
                    setSubDrawerOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <SettingsApplications />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              )}
              {subDrawerOpen && (
                <SettingsNestedDrawer
                  subDrawerOpen={subDrawerOpen}
                  setSubDrawerOpen={setSubDrawerOpen}
                  setChildSettingOpen={setChildSettingOpen}
                />
              )}
              <Divider />
              {/* <List>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gridColumnGap: "13px",
                                }}>
                                <ListItemIcon>
                                    <Switch
                                        checked={toggleDark}
                                        onChange={handleModeChange}
                                        name="toggleDark"
                                        inputProps={{
                                            "aria-label":
                                                "checkbox with default color",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Switch to Dark Mode" />
                            </div>
                        </List>
                        <Divider /> */}
              <List>{secondaryListItems}</List>
            </div>
          </div>
        </Drawer>

        {/* Business Account Drawer below */}
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              width: "30%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {roundLoader && <SimpleBackdrop />}
            {bussinessDrawerLoading ? (
              <>
                <br />
                <ProgressBar />
              </>
            ) : (
              <form onSubmit={handleSubmit(handleBussinessAccountDetailsSubmit)}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      component="h4"
                      mb={1}
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Account Details
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="businessName"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Business Name"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="contactName"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Contact Name"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="contactEmail"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Contact Email"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="contactPhoneNumber"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Contact Number"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                          rules={{
                            required: false,
                            minLength: 9,
                            maxLength: 9,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                          }}
                          onChange={(e) => {
                            const formattedNumber = formatPhoneNumber(e.target.value);
                            field.onChange(formattedNumber);
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="cityOrTown"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="City/Town"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Address"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="State"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="postcode"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Postcode"
                          type="number"
                          variant="outlined"
                          className={classes.textField}
                          size="small"
                        />
                      )}
                    />
                    {errors.postcode && "Postcode required"}
                  </Grid>

                  <Grid item xs={12} align="right">
                    {hasValidAccess(BUSINESS_ACCOUNT_WRITE) ? (
                      <>
                        <Button type="submit" size="small" color="primary" variant="contained">
                          Update
                        </Button>
                        <Button
                          type="button"
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={(e) => handleClose(e)}
                          style={{ marginLeft: "10px" }}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="button"
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={(e) => handleClose(e)}
                        style={{ marginLeft: "15px" }}
                      >
                        Close
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </Drawer>

        {/* HomeContent body here */}
        <Container
          className={childSettingOpen ? classes.contentForSetting : classes.content}
          sx={{
            overflowX: "auto",
          }}
          maxWidth="false"
          p={20}
        >
          <Box my={10}>
            <Grid container spacing={3}>
              {/* Estimate Home */}
              <Grid item xs={12} md={12} lg={12}>
                {/* <Paper className={classes.paper}> */}
                <Box>
                  <Component />
                </Box>
                {/* </Paper> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Grid item xs={12}>
          {openCustSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openCustSnackbar}
              setOpenSnackbar={setOpenCustSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default NavigatorSideBar;
