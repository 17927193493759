/** @format */

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import apis from "../../utils/apis";
import { Link } from "react-router-dom";
import SimpleBackdrop from "../UI/SimpleBackdrop";

function ShortClientContactForm({
  setContactWantToGet,
  chooseOne,
  setWantToCallGet,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
}) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const submitNewClient = () => {
    let obj = "";
    if (chooseOne === "contact") {
      obj = { contactName: name, emailAddress: email };
      console.log("contact 2", obj);
      createContacts(
        obj,
        setContactWantToGet,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader
      );
      setOpenDialog(false);
    } else if (chooseOne === "client") {
      obj = { name: name, emailAddress: email };
      createClient(
        obj,
        setWantToCallGet,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader,
        setOpenDialog
      );
      setOpenDialog(false);
      setWantToCallGet(false);
    }
  };

  return (
    <>
      <a color="primary" size="small" onClick={handleDialogOpen}>
        + Add New Client
      </a>
      {roundLoader && <SimpleBackdrop />}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Typography variant="h6" color="primary">
            New Client
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            size="small"
            label="Client Name"
            type="name"
            fullWidth
            variant="standard"
            margin="none"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            margin="dense"
            id="email"
            size="small"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </DialogContent>

        <Grid item xs={12} sm={12} align="right" style={{ margin: "10px" }}>
          <Button
            color="primary"
            size="small"
            onClick={submitNewClient}
            variant="contained"
            style={{
              marginRight: "10px",
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            Submit
          </Button>
          <Button
            onClick={handleDialogClose}
            size="small"
            color="secondary"
            variant="contained"
            style={{
              marginRight: "10px",
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Dialog>
    </>
  );
}

export default ShortClientContactForm;

const createContacts = (
  obj,
  setContactWantToGet,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/contact",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setContactWantToGet(true);
      if (res.data.message === "SUCCESS") {
        setSnackbarSeverity("success");
        setSnackbarMSG("Contact Added SuccessFully");
        setOpenSnackbar(true);
        setRoundLoader(false);
        console.log("contact Submited successFully>>>");
      } else {
        setRoundLoader(false);
        setContactWantToGet(true);
        setSnackbarSeverity("error");
        setSnackbarMSG("Contact creation failed");
        setOpenSnackbar(true);
        console.log("contact Submited failed>>>");
      }
    });
};

export const createClient = (
  obj,
  setWantToCallGet,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader,
  setOpenDialog
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/client",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setWantToCallGet(true);
      if (res.status === 200) {
        setSnackbarSeverity("success");
        setSnackbarMSG("Client Added SuccessFully");
        setOpenSnackbar(true);
        setRoundLoader(false);
        setOpenDialog(false);
        console.log("clients data posted successFully>>>");
      } else {
        setRoundLoader(false);
        setSnackbarSeverity("error");
        setSnackbarMSG(res.data.errorMessage || "Client creation failed");
        setOpenSnackbar(true);
        setOpenDialog(true);

        console.log("clients posting failed>>>");
      }
    });
};
