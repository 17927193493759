/** @format */
import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import { createJobLineItems, updateJobLineItems } from "./helpers.actual.costings";
import ActualCostingBody from "./ActualCostingBody";
import { Tooltip, Zoom } from "@mui/material";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_DELETE,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
  JOB_WRITE,
} from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";

export default function GroupsRow({
  row,
  setLineItems,
  setRows,
  rows,
  estimateID,
  category,
  lineItems,
  id,
  loadSave,
  setLoadSave,
  handlePurchesOrder,
  isEdit,
  setEdit,
  disableSave,
  setDisableSave,
  tempData,
  index,
  openAllRows,
  setOpenAllRows,
  userConfigs,
  userConfigsuom,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  jobStatus,
  newlyAdded,
  setRoundLoader,
}) {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const useRowStyles = makeStyles({
    tableInnerContent: {
      paddingTop: "10px",
    },
  });
  const [open, setOpen] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [estTotal, setEstTotal] = React.useState(0);
  const [actTotal, setActTotal] = React.useState(0);
  const [varianceTotal, setVarianceTotal] = React.useState(0);
  const [deleteGroup, setDeleteGroup] = React.useState();
  const [tempRowsData, setTempRowsData] = useState([]);

  const classes = useRowStyles();
  /* ---------------------------------------------------------------------- */
  var { jobId } = useParams();

  const handleDelete = () => {
    let filteredData = rows.filter((el) => {
      if (el.groupCode != row.groupCode && el.groupName != row.groupName) {
        return el;
      }
    });
    setRows(filteredData);
    updateJobLineItems(
      filteredData,
      jobId,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRows,
      setRoundLoader
    );
    setShowConfirm(false);
  };

  /* ---------------------------------------------------------------------- */
  const handleOpenCostinRow = () => {
    if (openAllRows[index]) {
      setOpenAllRows({ ...openAllRows, [index]: false });
    } else {
      setActualLineItem(row.items);
      setOpenAllRows({ ...openAllRows, [index]: true });
    }
  };
  useEffect(() => {
    if (openAllRows[index]) {
      setActualLineItem(row.items);
    }
  }, [openAllRows]);

  /* ---------------------------------------------------------------------- */

  const handleConfirm = () => {
    setShowConfirm(true);
  };
  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };
  const [actualLineItem, setActualLineItem] = React.useState([]);

  const handleCollapseRow = (e, row) => {
    // setOpenedItemRow(row);
    setActualLineItem(row.items);
    // setIndex(index);
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  let rowEditable = true;
  row.items.filter((obj, index) => {
    if (obj.editable === false) {
      rowEditable = false;
    }
  });

  // ------------------------------------------------------
  const handleInputChange = (e, index) => {
    const list = JSON.parse(JSON.stringify(rows));
    list[index][e.target.name] = e.target.value;
    setRows(list);
  };
  // ------------------------------------------------------
  const handleGroupSave = () => {
    console.log("SAVECLICK");
    setEditGroup(false);
    createJobLineItems(
      setRows,
      rows,
      setRoundLoader,
      jobId,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity
    );
  };
  // ------------------------------------------------------

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        sx={{
          display: "block",
          cursor: "pointer",
        }}
        // onClick={handleOpenCostinRow}
        hover
      >
        <div onClick={(e) => handleCollapseRow(e, row)}>
          <TableCell
            width="2%"
            align="left"
            style={{
              border: "none",
              padding: "0px",
              paddingLeft: "10px",
              paddingTop: "5px",
            }}
          >
            <IconButton aria-label="expand row" size="small" onClick={handleOpenCostinRow}>
              {openAllRows[index] ? (
                <Tooltip title="COLLAPSE GROUP" placement="top" TransitionComponent={Zoom} arrow>
                  <KeyboardArrowUpIcon />
                </Tooltip>
              ) : (
                <Tooltip title="EXPAND GROUP" placement="top" TransitionComponent={Zoom} arrow>
                  <KeyboardArrowDownIcon />
                </Tooltip>
              )}
            </IconButton>
          </TableCell>
          {editGroup ? (
            <>
              <TableCell
                width="5.3%"
                align="center"
                component="th"
                scope="row"
                style={{ border: "none" }}
              >
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  value={row.groupCode}
                  name="groupCode"
                  onChange={(e) => handleInputChange(e, index)}
                />
              </TableCell>
              <TableCell
                width="65%"
                align="center"
                component="th"
                scope="row"
                style={{ border: "none" }}
              >
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  value={row.groupName}
                  name="groupName"
                  onChange={(e) => handleInputChange(e, index)}
                />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell
                width="5.3%"
                align="center"
                component="th"
                scope="row"
                style={{ border: "none" }}
              >
                {row.groupCode}
              </TableCell>
              <TableCell width="65%" align="left" component="th" scope="row" style={{ border: "none" }}>
                {row.groupName}
              </TableCell>
            </>
          )}

          <TableCell align="center" style={{ border: "none" }} width="10%">
            {row.items.length} items
          </TableCell>

          {(hasValidAccess(JOB_DELETE) || hasValidAccess(JOB_WRITE)) && (
            <TableCell width="8.7%" align="center">
              {/* {rowEditable ? ( */}
              {!editGroup ? (
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Tooltip title="DELETE COSTING" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={handleConfirm}
                      disabled={jobStatus !== "DRAFT"}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Groups" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTempRowsData(rows);
                        setEditGroup(true);
                      }}
                      disabled={jobStatus !== "DRAFT"}
                    >
                      <CreateIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              ) : (
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Tooltip title="CANCEL" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRows(tempRowsData);
                        setEditGroup(false);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="SAVE" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleGroupSave()}
                      size="small"
                    >
                      <SaveIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              )}
              {/* ) : (
                <IconButton disabled>
                  <DeleteIcon style={{ cursor: "pointer" }} />
                </IconButton>
              )} */}
            </TableCell>
          )}
        </div>
      </TableRow>
      <TableRow
        p={1}
        sx={{
          display: "block",
        }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{
            display: "block",
          }}
        >
          <Collapse in={openAllRows[index]} timeout="auto" unmountOnExit align="right">
            <Grid container justifyContent={"flex-end"}>
              <TableRow>
                <TableCell variant="head" align="right">
                  Estimated:{" "}
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={estTotal.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head" align="right">
                  Actual (Ex):{" "}
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={actTotal.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell variant="head" align="right">
                  Variance (Ex):{" "}
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={varianceTotal.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell>
              </TableRow>
            </Grid>
            {/* <Grid item xs={12} align="right">
              <Box
                sx={{
                  width: "300px",
                }}
              >
                <Table
                  size="small"
                  sx={{
                    borderBottom: "none",
                  }}
                >
                  <TableRow>
                    <TableCell variant="head">Estimated: </TableCell>
                    <TableCell align="right">
                      Variance (Ex):
                      <NumberFormat
                        value={estTotal.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Actual (Ex):</TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={actTotal.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Variance (Ex):</TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={varianceTotal.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
            </Grid> */}
            {/* <Grid
              style={{ display: "flex", justifyContent: "flex-end" }}
              container
            >
              <Typography variant="h6" style={{ color: "#5a8c24" }}>
                Estimated :
                <NumberFormat
                  value={estTotal.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "flex-end" }} container>
              <Typography variant="h6" style={{ color: "#5a8c24" }}>
                Actual (Ex) :
                <NumberFormat
                  value={actTotal.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "flex-end" }} container>
              <Typography variant="h6" style={{ color: "#5a8c24" }}>
                Variance (Ex) :
                <NumberFormat
                  value={varianceTotal.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Grid> */}

            <ActualCostingBody
              actualLineItem={actualLineItem}
              setActualLineItem={setActualLineItem}
              setLineItems={setLineItems}
              setRows={setRows}
              rows={rows}
              estimateID={estimateID}
              category={category}
              lineItems={lineItems}
              id={id}
              row={row}
              loadSave={loadSave}
              setLoadSave={setLoadSave}
              handlePurchesOrder={handlePurchesOrder}
              isEdit={isEdit}
              setEdit={setEdit}
              disableSave={disableSave}
              setDisableSave={setDisableSave}
              tempData={tempData}
              setEstTotal={setEstTotal}
              setActTotal={setActTotal}
              setVarianceTotal={setVarianceTotal}
              userConfigs={userConfigs}
              userConfigsuom={userConfigsuom}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              jobStatus={jobStatus}
              newlyAdded={newlyAdded}
              setRoundLoader={setRoundLoader}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
