/** @format */

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import ProgressBar from "../../UI/ProgressBar";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import Grid from "@mui/material/Grid";
import { createJobLineItems, getJobLineItemsByJobId } from "./helpers.actual.costings";
import CreateCosting from "./CreateCosting";
import SaveCostingButton from "../../estimates/EstimateCosting/SaveCostingButton";
import ActualCostingRequest from "./ActualCostingRequest";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import FooterTotal from "./FooterTotal";
import GroupsRow from "./GroupsRow";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function ActualCosting({ userConfigs, userConfigsuom, jobStatus }) {
  const [openCreate, setOpenCreate] = useState(false);
  const [category, setCategory] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [id, setId] = useState(rows.length);
  const [load, setLoad] = useState(false);
  const [openAllRows, setOpenAllRows] = useState([]);
  const [roundLoader, setRoundLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [loadSave, setLoadSave] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [isEdit, setEdit] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [finalEstTotal, setFinalEstTotal] = React.useState(0);
  const [finalActTotal, setFinalActTotal] = React.useState(0);
  const [finalVarianceTotal, setFinalVarianceTotal] = React.useState(0);
  const [lineItemsForAutoselect, setLineItemsForAutoselect] = React.useState([]);
  const [expandRows, setExpandRows] = useState(true);
  const [newlyAdded, setNewlyAdded] = useState({});

  const history = useHistory();
  let estimateID = 0;
  var { jobId } = useParams();
  // alert(jobId)
  const [tempData, setTempData] = React.useState([]);
  /* ---------------------------------------------------------------------- */
  useEffect(() => {
    getJobLineItemsByJobId(
      setRows,
      setTempData,
      setLoad,
      jobId,
      setLineItemsForAutoselect,
      setOpenAllRows
    );
    setCheckedJobLineItems([]);
    // setId(rows.length);
  }, []);
  /* ---------------------------------------------------------------------- */
  var tempOpenRows = [];
  const handleOpenAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = true;
    }
    setOpenAllRows(tempOpenRows);
  };
  const handleCloseAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = false;
    }
    //setCreateQuote("");

    setOpenAllRows(tempOpenRows);
  };

  /* ---------------------------------------------------------------------- */

  const handleSave = () => {
    // if (rows.length === 0) {
    //   var lastBeforeRowSeqId = 0;
    // } else {
    //   var lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
    // }
    setId(id + 1);
    let arrOfObj = {
      groupCode: groupCode,
      groupName: category,
      items: lineItems,
    };
    let arr = [...rows, arrOfObj];
    console.log("jobid", jobId);
    console.log("arr", arr);
    createJobLineItems(
      setRows,
      arr,
      setLoadSave,
      jobId,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity
    );
    setDisable(true);
    setCategory("");
    setOpenCreate(!openCreate);
  };

  const handleSaveOuter = () => {
    createJobLineItems(
      setRows,
      rows,
      setLoadSave,
      jobId,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity
    );
    setEdit(false);
    setDisableSave(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const [checkedJobLineItems, setCheckedJobLineItems] = useState([]);
  /* This function call after selected item for added item in purchase,work order, and variation Drawer a */
  const handlePurchesOrder = (e, row, index) => {
    let copyItem = false;
    let jobLineItems = [];
    let filteredData = [];
    copyItem = checkedJobLineItems.some((item) => {
      return row.lineItemId === item.jobLineItemId;
    });

    if (e.target.checked) {
      if (copyItem === true) {
        setSnackbarMSG("Item Already Added");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      } else {
        jobLineItems = [...checkedJobLineItems];
        jobLineItems.push({
          jobLineItemId: row.lineItemId,
          itemCode: row.itemCode,
          itemName: row.itemName,
          itemGroupCode: row.itemGroupCode,
          itemGroupName: row.itemGroupName,
          itemDescription: row.itemDescription,
          type: row.type,
          qty: row.estimatedQty,
          uom: row.uom,
          unitCost: row.estimatedUnitCost,
          gst: row.gst,
        });
        setCheckedJobLineItems(jobLineItems);
      }
    } else {
      filteredData = checkedJobLineItems.filter((ele) => {
        return ele.jobLineItemId !== row.lineItemId;
      });
      console.log("filteredData::", filteredData);
      setCheckedJobLineItems(filteredData);
    }
  };

  return (
    <React.Fragment>
      {load ? ( //if rows are empty then progress bar is shown
        <ProgressBar />
      ) : (
        <Grid container>
          {loadSave && <SimpleBackdrop />}
          <Grid item xs={12}>
            <Box my={1}>
              <CreateCosting
                category={category}
                setCategory={setCategory}
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                textFieldMsg="Actual Catagory"
                // rows={rows}
                // setRows={setRows}
                jobId={jobId}
                // setId={setId}
                // lineItems={lineItems}
                // estimateID={estimateID}
                handleSave={handleSave}
                // load={loadSave}
                handleOpenAllRows={handleOpenAllRows}
                handleCloseAllRows={handleCloseAllRows}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                disable={disable}
                setDisable={setDisable}
                jobStatus={jobStatus}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box m={1} py={2}>
                {roundLoader && <SimpleBackdrop />}
                <TableContainer>
                  <Table
                    size="small"
                    sx={{
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <TableBody
                      sx={{
                        display: "block",
                        width: "100%",
                      }}
                    >
                      {rows.length === 0 ? (
                        <EmptyEstimatesDialog
                          heading="Empty Actual Costings "
                          msg="You don't have any costings here yet.
                         Click the 'ADD' button above to add your costings entries"
                        />
                      ) : (
                        rows.map((row, index) => (
                          <GroupsRow
                            row={row}
                            setLineItems={setLineItems}
                            setRows={setRows}
                            rows={rows}
                            estimateID={estimateID}
                            category={category}
                            lineItems={lineItems}
                            id={id}
                            loadSave={loadSave}
                            setLoadSave={setLoadSave}
                            handlePurchesOrder={handlePurchesOrder}
                            isEdit={isEdit}
                            setEdit={setEdit}
                            disableSave={disableSave}
                            setDisableSave={setDisableSave}
                            tempData={tempData}
                            setFinalEstTotal={setFinalEstTotal}
                            setFinalActTotal={setFinalActTotal}
                            setFinalVarianceTotal={setFinalVarianceTotal}
                            userConfigs={userConfigs}
                            userConfigsuom={userConfigsuom}
                            openAllRows={openAllRows}
                            index={index}
                            setOpenAllRows={setOpenAllRows}
                            setOpenSnackbar={setOpenSnackbar}
                            setSnackbarMSG={setSnackbarMSG}
                            setSnackbarSeverity={setSnackbarSeverity}
                            jobStatus={jobStatus}
                            newlyAdded={newlyAdded}
                            setRoundLoader={setRoundLoader}
                          />
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Footer*/}
              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
              <FooterTotal rows={rows} />
            </Paper>
          </Grid>

          <Grid item xs={12} /* style={{ position: "sticky", bottom: "-10px" }} */>
            <Box m={1} py={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  position: "sticky",
                }}
              >
                <div>
                  {checkedJobLineItems.length != 0 && (
                    <ActualCostingRequest
                      checkedJobLineItems={checkedJobLineItems}
                      // checkedOrderSecondData={checkedOrderSecondData}
                      is_purchaseOrder={true}
                      jobId={jobId}
                      setCheckedJobLineItems={setCheckedJobLineItems}
                      setEdit={setEdit}
                      userConfigs={userConfigs}
                      userConfigsuom={userConfigsuom}
                      setOpenSnackbar={setOpenSnackbar}
                      setSnackbarMSG={setSnackbarMSG}
                      setSnackbarSeverity={setSnackbarSeverity}
                      lineItemsForAutoselect={lineItemsForAutoselect}
                      setNewlyAdded={setNewlyAdded}
                    />
                  )}
                </div>
                <div>
                  {disableSave ? <></> : <SaveCostingButton handleSaveOuter={handleSaveOuter} />}
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
