/** @format */

import apis from "../../utils/apis";

export const getClients = (setData, setLoad, clientId) => {
  apis
    .GET({
      endpoint: "/clients",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.clientNo - b.clientNo;
      });
      if (clientId) {
        let filteredData = res.data.filter((el) => {
          return el.clientId === clientId;
        });
        setData(filteredData);
      } else {
        setData(res.data);
      }
      console.log("rendering clients...");
      console.log(res.data);

      setLoad(false);
    });
};

export const getClientsForAutocomplete = (setClients) => {
  apis
    .GET({
      endpoint: "/clients",
    })
    .then((res) => {
      setClients(res.data);
    });
};

export const createClient = (
  obj,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  setData,
  clientData
) => {
  setRoundLoader(true);
  const oldData = [...clientData];
  apis
    .POST({
      endpoint: "/client",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        oldData.push(res.data);
        setData(oldData);
        setSnackbarMSG("Client created successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage || "Client Creation failed !");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        console.log("clients posting failed>>>");
      }
    });
};

export const updateClientbyId = (
  clientId,
  obj,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  setClientObject,
  setData,
  clientData,
  setJobsArr,
  jobsArr,
  setEstimates,
  estimates
) => {
  setRoundLoader(true);
  let oldData = [...clientData];
  apis
    .PUT({
      endpoint: `/client/${clientId}`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data) {
        oldData?.map((item, i) => {
          if (item.clientId === clientId) {
            oldData[i] = res.data;
          }
        });
        jobsArr?.map((item, i) => {
          if (item?.client?.clientId === clientId) {
            jobsArr[i].client = res.data;
          }
        });
        estimates?.map((item, i) => {
          if (item?.client?.clientId === clientId) {
            estimates[i].client = res.data;
          }
        });

        if (jobsArr != undefined) {
          setJobsArr(jobsArr);
        }
        if (estimates != undefined) {
          setEstimates(jobsArr);
        }

        setData(oldData);
        setClientObject(res.data);
        setSnackbarMSG("Client updated successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setState({ ["right"]: false });
      } else {
        setRoundLoader(false);
        setSnackbarMSG("Client Update Failed");
        setSnackbarSeverity("error");
        setOpenCustSnackbar(true);
      }
    });
};

export const deleteClientbyID = (
  clientId,
  setData,
  data,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  // setOpenSnackbar(false);

  apis
    .DELETE({
      endpoint: `/client/${clientId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        var newData = data.filter((row) => {
          if (row.clientId != clientId) {
            return row;
          }
        });
        setData(newData);

        setSnackbarMSG("Client deleted successfully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      } else {
        setData(data);
        setSnackbarMSG("Client Deletion Failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
