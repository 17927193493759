/** @format */

import React, { useRef, useEffect, useState } from "react";
import WebViewer, { Core } from "@pdftron/webviewer";
import {
  Dialog,
  DialogContent,
  Select,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { annotationChangedApi } from "./takeoff.helpers";
import Calculator from "../../UI/calculator/Calculator";
//import { Calculate } from "../../UI/Calculator";
//import { ReactCalculator } from "simple-react-calculator";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Webviewer = ({
  estimateId,
  autoSelectPlanName,
  setInstance,
  setDistanceValue,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallUseEffect,
  setNewMeasurmentUpdated,
  setDeletedMeasurement,
}) => {
  const [callUseEffect, setCallUseEffect] = React.useState(false);
  const [importOrExport, setImportOrExport] = useState();
  const [annotationManager, setAnnotationManager] = React.useState();
  const [openCalculator, setOpenCalculator] = React.useState(false);
  const viewer = useRef(null);

  // if using a class, equivalent of componentDidMount
  useEffect(() => {
    WebViewer(
      {
        fullAPI: true,
        path: "/webviewer/lib",
        initialDoc: autoSelectPlanName.documentUrl,
        enableMeasurement: true,
      },
      viewer.current
    ).then((instance) => {
      setInstance(instance);
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      documentViewer.addEventListener("documentLoaded", () => {
        console.log("documentViewer", documentViewer);
        // need to draw the annotation otherwise it won't show up until the page is refreshed

        // // base64 to string //decode
        setAnnotationManager(annotationManager);
        //setImportOrExport("import");
        //setCallUseEffect(true);
      });

      // later save the annotation data as transaction command for every change
      annotationManager.addEventListener(
        "annotationChanged",
        (annotations, action, { imported }) => {
          // If the event is triggered by importing then it can be ignored
          // This will happen when importing the initial annotations
          // from the server or individual changes from other users
          if (imported) return;
          //setImportOrExport("export");
          //setCallUseEffect(true);
        }
      );

      annotationManager.addEventListener(
        "annotationChanged",
        (annotations, action) => {
          if (action === "add") {
            !isNaN(
              parseFloat(annotations[0].getContents().replace(/,/g, ""))
            ) &&
              setNewMeasurmentUpdated({
                measurment: parseFloat(
                  annotations[0].getContents().replace(/,/g, "")
                ),
              });

            console.log(
              "added annotations:",
              parseFloat(annotations[0].getContents().replace(/,/g, ""))
            );
          } else if (action === "delete") {
            setDeletedMeasurement(
              parseFloat(annotations[0].getContents().replace(/,/g, ""))
            );
          }
        }
      );

      annotationManager.addEventListener(
        "annotationSelected",
        (annotations, action) => {
          // If the event is triggered by importing then it can be ignored
          // This will happen when importing the initial annotations
          // from the server or individual changes from other users

          console.log(
            parseFloat(annotations[0].getContents().replace(/,/g, ""))
          );
          console.log(action);
        }
      );

      instance.UI.addEventListener(instance.UI.Events.VIEWER_LOADED, () => {
        // Add customization here
        documentViewer.setMargin(20);
        documentViewer.addEventListener("fitModeUpdated", (fitMode) => {
          console.log("fit mode changed");
        });
        instance.UI.disableElements(["ribbons"]);
        // instance.UI.disableElements(["toolbarGroup-Shapes"]);
        // instance.UI.disableElements(["toolbarGroup-Edit"]);
        // instance.UI.disableElements(["toolbarGroup-Insert"]);
        // instance.UI.disableElement("Comments");
        // instance.UI.setHeaderItems((header) => {
        //   const items = header.getItems().slice(10, );
        //   // slice(9, -3);
        //   console.log("items", items);
        //   header.update(items);
        // });
        // instance.UI.setTheme("dark");
        instance.UI.setToolbarGroup("toolbarGroup-Measure");
        instance.UI.openElements(["notesPanel"]);
        instance.UI.setHeaderItems(function (header) {
          var myCustomButton = {
            type: "actionButton",
            img: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calculator" viewBox="0 0 16 16">
            <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
            <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
          </svg>`,
            title: "Calculator",
            onClick: function () {
              setOpenCalculator(true);
              console.log("button call");
            },
          };

          header.push(myCustomButton);
        });
      });
    });
  }, []);
  /* ----------------------------export  & import annotation--------------------------------------------- */
  useEffect(() => {
    console.log("planobj 1", autoSelectPlanName);
    if (callUseEffect) {
      /* -----import---- */
      if (importOrExport === "import") {
        if (autoSelectPlanName.annotations != null) {
          let base64ToString = Buffer.from(
            autoSelectPlanName.annotations,
            "base64"
          ).toString();
          // base64ToString = JSON.parse(base64ToString);
          annotationManager
            .importAnnotations(base64ToString)
            .then((annotations) => {
              annotations.forEach((a) => {
                annotationManager.redrawAnnotation(a);
              });
            });
        }
        /* -----export---- */
      } else if (importOrExport === "export") {
        annotationManager
          .exportAnnotations({ links: false, widgets: false })
          .then((xfdfString) => {
            console.log(xfdfString);

            let planObj = {
              planName: autoSelectPlanName.planName,
              annotations: Buffer.from(xfdfString).toString("base64"),
            };
            console.log("planObj effect", planObj);

            annotationChangedApi(
              estimateId,
              planObj,
              setOpenCustSnackbar,
              setSnackbarMSG,
              setSnackbarSeverity,
              setWantToCallUseEffect
            );
          });
      }

      setCallUseEffect(false);
    }
  }, [callUseEffect]);

  /* --------------------------------------------------------------------------------- */

  //for drawer
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    setOpenCalculator(false);
  };
  return (
    <div className="WebViewer">
      {fullScreen ? (
        <div
          className="webviewer"
          ref={viewer}
          style={{ height: "1084px" }}
        ></div>
      ) : (
        <div
          className="webviewer"
          ref={viewer}
          style={{ height: "620px" }}
        ></div>
      )}

      {openCalculator && (
        <Dialog
          fullScreen={fullScreen}
          open={openCalculator}
          onClose={handleClose}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <DialogContent>
            <Calculator />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Webviewer;
