/** @format */

import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Collapse } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import axios from "axios";
import { saveAs } from "file-saver";
import { getJobByJobId } from "../jobs.helper";
import { getJobLineItemsByJobId, getJobLineItemsByJobIdForPdf } from "./helpers.actual.costings";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
  JOB_WRITE,
} from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
    padding: 10,
  },
});

export default function CreateCosting({
  category,
  setCategory,
  groupCode,
  setGroupCode,
  textFieldMsg,
  handleSave,
  openCreate,
  setOpenCreate,
  disable,
  setDisable,
  rows,
  jobId,
  handleOpenAllRows,
  handleCloseAllRows,
  jobStatus,
}) {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [details, setDetails] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  // const [pdfData, setPdfData] = React.useState({
  //   jobId: jobId,
  //   data: rows,
  //   tabType: "actual-costing",
  //   details: details,
  // });

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleAdd = () => {
    setOpenCreate(!openCreate);
  };
  const handleClose = () => {
    setDisable(true);
    setOpenCreate(!openCreate);
  };
  const handleChangeInput = (value) => {
    setCategory(value);
    setDisable(false);
  };

  const handleSendPdf = () => {
    var pdfData = {
      data: tempPdfData,
      tabType: "actual-costing",
      details: details,
    };
    console.log("Actual  Rows:....", rows);
    console.log("PDF DATA Rows:....", pdfData);
    console.log("tempPdfData:....", tempPdfData);

    axios
      .post("/create-pdf", pdfData)
      .then(() => axios.get("fetch-pdf", { responseType: "blob" }))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "newPdf.pdf");
      });
  };
  const [tempPdfData, setTempPdfData] = React.useState([]);

  useEffect(() => {
    // let estimateID = history.location.estimateID;
    //getJobLineItemsByJobIdForPdf(setTempPdfData, jobId);
    //getJobByJobId(setDetails, jobId, setLoad); //get job by id
  }, []);

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <ButtonGroup disableElevation>
            <Button variant="contained" onClick={handleOpenAllRows} size="small" color="primary">
              EXPAND
            </Button>
            <Button variant="contained" onClick={handleCloseAllRows} size="small" color="secondary">
              COLLAPSE
            </Button>
          </ButtonGroup>

          {/*   <Typography color="#000" variant="h6" gutterBottom>
            Actual Costings
          </Typography> */}
        </Grid>
        <Grid item xs={6} align="right">
          {hasValidAccess(JOB_WRITE) && (
            <ButtonGroup disableElevation>
              {openCreate ? (
                <Button variant="contained" size="small" color="secondary" onClick={handleClose}>
                  <CloseIcon onClick={handleClose} />
                  Close
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAdd}
                  disabled={jobStatus !== "DRAFT"}
                >
                  <AddIcon onClick={handleAdd} />
                  ADD
                </Button>
              )}
              {disable ? (
                <Button
                  disabled
                  variant="contained"
                  size="small"
                  align="right"
                  onClick={handleSave}
                  className={classes.button}
                >
                  <CheckCircleOutlineIcon onClick={handleSave} />
                  SAVE
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  align="right"
                  onClick={handleSave}
                  className={classes.button}
                >
                  <CheckCircleOutlineIcon onClick={handleSave} />
                  SAVE
                </Button>
              )}
            </ButtonGroup>
          )}
          <Collapse in={openCreate} timeout="auto" unmountOnExit>
            <div
              className={clsx(classes.fullList)}
              role="presentation"
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}
            >
              <Grid
                container
                mt={1}
                spacing={2}
                direction="column"
                alignContent={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <TextField
                    size="small"
                    required
                    id="groupCode"
                    name="groupCode"
                    value={groupCode}
                    variant="outlined"
                    onChange={(e) => {
                      setGroupCode(e.target.value);
                    }}
                    label={"Group Code"}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    required
                    id="name"
                    name="name"
                    value={category}
                    variant="outlined"
                    onChange={(e) => {
                      handleChangeInput(e.target.value);
                    }}
                    label={"Group Name"}
                  />
                </Grid>
              </Grid>
            </div>
          </Collapse>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
