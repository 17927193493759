import React , { useState }from 'react';
import { makeStyles , ThemeProvider } from "@mui/styles";
import {blueGrey } from "@mui/material/colors";

import FormQuoteResponse from './FormQuoteResponse';
const primaryColorDark = blueGrey[900];
const primaryColorLight = "#0FA1DC";
const primaryColorDarkmode = "#042E3F";
const newblueGreyHover = blueGrey[800];
const myTheme = makeStyles ({
  //theme settings
  palette: {
    type: "dark",
    primary: {
      main: primaryColorLight,
      contrastText:"#000",
      dark:newblueGreyHover,
    },
  },
});
export default function QuoteSent(){
  return(
    // <ThemeProvider theme={myTheme}>
     <FormQuoteResponse/>
    //  </ThemeProvider>
  );
}