/** @format */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteJobPlabyID } from "../jobPlans/jobplans.helpers";

export default function AlertPopJobPlans({
  confirmDelete,
  setConfirmDelete,
  deleteId,
  setJobPlansArr,
  setOpenSnackbar,
  JobPlansArr,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };

  const handleYes = () => {
    console.log("del id ", deleteId);
    deleteJobPlabyID(
      deleteId,
      setOpenSnackbar,
      JobPlansArr,
      setJobPlansArr,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader
    );
    setConfirmDelete(false);
  };

  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
