/** @format */

import apis from "../../utils/apis";

export const getAvailableConfigs = (setAllConfigs, setArr) => {
  // setLoad(true);
  apis
    .GET({
      endpoint: "/config/MENU_ITEMS",
    })
    .then((res) => {
      setAllConfigs(res.data);
      setArr(res.data.config);
      console.log("rendering configs...");
      console.log(res.data);
      // setLoad(false);
    });
};
export const getUserConfigsTypes = (setUserConfigs, setArr, setTempList) => {
  // setLoad(true);
  apis
    .GET({
      endpoint: "/config/MENU_ITEMS/TYPES",
    })
    .then((res) => {
      setUserConfigs(res.data);
      // setArr(res.data.config);
      // setTempList(JSON.parse(JSON.stringify(res.data.config)));
      setArr(JSON.parse(JSON.stringify(res.data.config)));
      setTempList(res.data.config);
      console.log("res.data.config:..", res.data.config);
      console.log("res.data:...", res.data);
      // setLoad(false);
    });
};
export const getUserConfigsUom = (setUserConfigs, setArr, setTempList) => {
  // setLoad(true);
  apis
    .GET({
      endpoint: "/config/MENU_ITEMS/UOM",
    })
    .then((res) => {
      setUserConfigs(res.data);
      setArr(JSON.parse(JSON.stringify(res.data.config)));
      setTempList(res.data.config);
      console.log("rendering configs...");
      console.log(res.data);
      // setLoad(false);
    });
};

export const updateConfig = (code, inputList, setOpenSnackbar) => {
  apis
    .PUT({
      endpoint: "/user-config",
      payload: { key: "MENU_ITEMS", code: code, config: inputList },
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setOpenSnackbar(true);

        console.log("data posting success >>>", inputList);
      } else {
        console.log("clients posting failed>>>");
      }
    });
};
