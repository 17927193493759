/** @format */

//Temporary Drawer and Addressform deleted
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import useStyles from "../../UI/useStyles";
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { createEstimates, getJobPlan } from "../estimates.helpers";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { getClients } from "../estimates.helpers";
import ShortClientContactForm from "../../UI/ShortClientContactForm";
import { Divider } from "@mui/material";
import ErrorStyles from "../../UI/ErrorStyles";

export default function CreateEstimate({
  setFirstSnackbar,
  reset,
  Controller,
  errors,
  control,
  handleSubmit,
  calledFrom,
  jobPlanData,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
}) {
  const ErrorClasses = ErrorStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [customer, setCustomer] = useState([]);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  // var jobPlanId=jobPlanData.jobPlanId;
  console.log("job plan data --------", jobPlanData);

  const history = useHistory();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (wantToCallGet) {
      console.log("useEffect client");
      getClients(setCustomer); //get client name
      setWantToCallGet(false);
    }
  }, [wantToCallGet]);

  const submitEstimateDetails = (data) => {
    let clientId = data.client.clientId;
    var jobPlanId = "";
    if (calledFrom === "CallFromEstimate") {
      jobPlanId = data.jobPlanId;
    } else {
      jobPlanId = jobPlanData.jobPlanId;
    }

    let description = data.description;
    console.log("jobPlanData", jobPlanData);

    delete data["client"];
    data = {
      ...data,
      description,
      clientId,
      jobPlanId,
    };

    console.log("name", data);
    createEstimates(data, history, setLoading, setOpenCustSnackbar, setSnackbarMSG, setSnackbarSeverity);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
    let row = {};
    reset(row);
  };
  const [jobPlanObject, setJobPlanObject] = useState([]);
  function handleCreateNew() {
    setState({ ...state, ["right"]: true });
    getClients(setCustomer); //get client name
    getJobPlan(setJobPlanObject);
  }

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <Button
            onClick={handleCreateNew}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<AddCircleSharpIcon />}
          >
            Create Estimate
          </Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            PaperProps={{
              sx: {
                width: 400,
              },
            }}
            onClose={toggleDrawer("left", false)}
          >
            <div
              style={{ width: "100%" }}
              className={clsx(classes.createEstimateDrawer)}
              class="full"
              role="presentation"
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}
            >
              <form onSubmit={handleSubmit(submitEstimateDetails)}>
                <Grid container spacing={3} p={2}>
                  <Grid item xs={12}>
                    <Box component="div">
                      {" "}
                      <Typography
                        component="h4"
                        color="primary"
                        mb={2}
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        New Estimate
                      </Typography>
                      <Divider />
                    </Box>
                    <Box component="div" my={2}>
                      <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label=" Description "
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.description?.type === "required" && "First name is required"}
                      </p>
                    </Box>
                    <Box component="div" my={2}>
                      <Controller
                        name="client"
                        control={control}
                        rules={{ required: true }}
                        className="input"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            onChange={(event, newValue) => {
                              field.onChange(newValue);
                            }}
                            value={field.value}
                            // value={orderData.contact}
                            options={customer}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" size="small" label="Client" />
                            )}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.client?.type === "required" && "Client name is required"}
                      </p>
                    </Box>
                    <Box component="div" my={2} align="right">
                      <ShortClientContactForm
                        setWantToCallGet={setWantToCallGet}
                        setFirstSnackbar={setFirstSnackbar}
                        chooseOne={"client"}
                        setOpenSnackbar={setOpenCustSnackbar}
                        setSnackbarMSG={setSnackbarMSG}
                        setSnackbarSeverity={setSnackbarSeverity}
                      />
                    </Box>
                    {calledFrom === "CallFromEstimate" ? (
                      <Box component="div" my={2}>
                        <Controller
                          name="jobPlanId"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onChange={(event, newValue) => {
                                console.log("newValue", newValue);

                                field.onChange(newValue.jobPlanId);
                              }}
                              value={field.value}
                              // value={orderData.contact}
                              options={jobPlanObject}
                              getOptionLabel={(option) => option.jobPlanName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  label="Job Plan"
                                />
                              )}
                            />
                          )}
                        />
                        <p className={ErrorClasses.errorMsg}>
                          {errors.jobPlanId && "Building Type required"}
                        </p>
                      </Box>
                    ) : (
                      <Box component="div" my={2}>
                        <Controller
                          name="jobPlanId"
                          control={control}
                          // value={jobPlanData.jobPlanName}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              size="small"
                              value={jobPlanData.jobPlanName}
                              label="Job Plan Name"
                              InputProps={{ readOnly: "true" }}
                              className={classes.textField}
                              fullWidth
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Box component="div" my={2}>
                      <Stack direction="row" spacing={1} justifyContent={"end"}>
                        <Button type="submit" size="small" variant="contained">
                          Save
                        </Button>
                        <Button
                          onClick={toggleDrawer("right", false)}
                          variant="contained"
                          size="small"
                          color="secondary"
                        >
                          Close
                        </Button>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
}
