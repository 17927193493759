/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams, Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip, Zoom, Stack, Paper, Select, Drawer, Grid, Table, TableHead } from "@mui/material";
import Divider from "@mui/material/Divider";
import MomentDate from "../../UI/MomentDate";
import { CKEditor } from "ckeditor4-react";
import FormControl from "@mui/material/FormControl";
import MUIDataTable from "mui-datatables";
import AlertPopOutgoingInvoice from "../OutgoingInvoices/AlertPopOutgoingInvoice";
import { padding } from "@mui/system";
import Box from "@mui/material/Box";
import NumberFormat from "react-number-format";
import InputLabel from "@mui/material/InputLabel";
import { Delete, Receipt } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { useForm } from "react-hook-form";
import { Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ProgressBar from "../../UI/ProgressBar";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InvoicesByReceipt from "../OutgoingInvoices/ReceiptByInvoices";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import {
  getOutgoingInvoice,
  createOutgoingInvoice,
  updateInvoiceByID,
  createInvoiceReceipts,
} from "./OutgoingInvoice.helpers";
import moment from "moment";
import { checkProperties } from "../../UI/GlobalApi";

export default function OutgoingInvoice(props) {
  const { type } = props;
  const useStyles = makeStyles((theme) => ({
    Drawerroot: {
      // width: 1000,
      padding: 16,
    },
    textField: {
      width: "100%",
    },
  }));
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  var { jobId } = useParams();
  /* Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleEmptySubmitForm = () => {
    commonToggle(true);
    setIsBtnDisbl(true);
    let row = {};
    setInvoicedata({
      ...invoicesData,
      jobId: jobId,
      description: "",
      status: "DRAFT",
      claimAmount: 0,
      comments: "",
    });
  };
  /* useState for DataTable */
  const [outgoingInvoice, setOutGoingInvoice] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [selectableRows, setSelectableRows] = useState(false);
  const [status, setStatus] = useState(["DRAFT", "PENDING", "INVOICED", "RECEIVED"]);
  const [invoicesData, setInvoicedata] = useState({
    description: "",
    status: "DRAFT",
    claimAmount: 0,
    jobId: "",
  });
  /* -------------------------------------------------------------------------------------------- */
  const classes = useStyles();
  //const [statusbar, setStatusbar] = React.useState("Pending");
  const [isSaveClicked, setisSaveClicked] = useState(true);
  const [dueDate, setDueDate] = useState();
  const [invoicedate, setInvoicedate] = useState(
    new Date()
      .toLocaleString("en-us", { year: "numeric", month: "2-digit", day: "2-digit" })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  );
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [wantToCallGe1t, setWantToCallGet1] = useState(true);
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [isBtnDisbl, setIsBtnDisbl] = useState(true);
  const [invitationmessage, setInvitationMessage] = useState();
  const [roundLoader, setRoundLoader] = useState(false);
  const [data, setData] = useState();
  const [editInvoiceDrawerLoading, setEditInvoiceDrawerLoading] = useState(false);
  const [receiptData, setReceiptData] = useState({
    jobId: jobId,
    referenceNo: "",
    receiptDate: "",
    receiptTotal: "",
    notes: "",
  });

  /* -------------------------------------------------------------------------------------------- */
  useEffect(() => {
    if (invoicesData.status === "RECEIVED") {
      toggleReceivedDrawer("right", true);
      setReceivedDrawer({ ...receivedDrawer, ["right"]: true });
    }
  }, [invoicesData]);
  /* -------------------------------------------------------------------------------------------- */
  const [shareDrawerState, setshareDrawerState] = React.useState({
    right: false,
  });
  /* -------------------------------------------------------------------------------------------- */
  const toggleShareDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setshareDrawerState({ ...shareDrawerState, [anchor]: open });
  };
  /* -------------------------------------------------------------------------------------------- */
  const [receivedDrawer, setReceivedDrawer] = React.useState({
    right: false,
  });
  /* -------------------------------------------------------------------------------------------- */
  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  //   if (event.target.value === "Received") {
  //     toggleReceivedDrawer("right", true);
  //     setReceivedDrawer({ ...receivedDrawer, ["right"]: true });
  //     console.log(receivedDrawer, "receivedDrawer");
  //   }
  // };
  /* -------------------------------------------------------------------------------------------- */
  const toggleReceivedDrawer = (anchor, open) => (event) => {
    console.log(toggleReceivedDrawer);
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setReceivedDrawer({ ...receivedDrawer, [anchor]: open });
  };
  /* -------------------------------------------------------------------------------------------- */
  const closeFunction = () => {
    setReceivedDrawer({ ...receivedDrawer, ["right"]: false });
    console.log("receivedDrawer", receivedDrawer);
    setisSaveClicked(true);
  };
  /* -------------------------------------------------------------------------------------------- */
  const handleMailUpdate = (arrayIndex) => {
    setisSaveClicked(false);
    setshareDrawerState({ ...shareDrawerState, ["right"]: true });
  };

  /* -------------------------------------------------------------------------------------------- */
  const updateInvoiceDetails = () => {
    let valid = true;
    let data;

    if (invoicesData.status === "INVOICED") {
      data = {
        ...invoicesData,
        invoicedate: moment(invoicedate.$d).format("YYYY-MM-DD"),
        dueDate: moment(dueDate.$d).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        ...invoicesData,
        invoicedate: null,
        dueDate: null,
      };
    }
    let errMsg = checkProperties(data);
    if (errMsg !== "") valid = false;

    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenCustSnackbar(true);
      commonToggle(true);
    } else {
      updateInvoiceByID(
        data,
        setWantToCallGet,
        wantToCallGet,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setEditInvoiceDrawerLoading
      ); //API call For Update
      commonToggle(false);
    }
  };

  //////////////  Delete outgoingInvoice ////////////////////
  const [delID, setdelID] = useState();
  const handleDelete = (rowsDeleted, newData) => {
    var removedOutgoingInvoiceId;
    rowsDeleted.data.map((row, i) => {
      removedOutgoingInvoiceId = outgoingInvoice[row.index].invoiceId;
    });
    console.log("removedOutgoingInvoiceId", removedOutgoingInvoiceId);
    setdelID(removedOutgoingInvoiceId);
    console.log("id in outgoinginvoice.js", newData);
    setWantToCallGet1(true);
    setConfirmDelete(true);
  };
  const handleOpenDrawer = (arrayIndex) => {
    /* this function used for open toggle */
    commonToggle(true);
    setIsBtnDisbl(false);
    /* reset is method of useForm it is used for returning data to form */
    //reset(outgoingInvoice[arrayIndex]);
  };
  var today = new Date();
  var now = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const [date, setDate] = useState(now);

  /*************** Submit Outgoing Invoice **************** */
  const submitInvoices = () => {
    let valid = true;
    let data;

    if (invoicesData.status === "INVOICED") {
      data = {
        ...invoicesData,
        invoicedate: moment(invoicedate.$d).format("YYYY-MM-DD"),
        dueDate: moment(dueDate.$d).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        ...invoicesData,
        invoicedate: null,
        dueDate: null,
      };
    }
    let errMsg = checkProperties(data);
    if (errMsg !== "") valid = false;
    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenCustSnackbar(true);
      commonToggle(true);
    } else {
      createOutgoingInvoice(
        data,
        setWantToCallGet,
        wantToCallGet,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader
      ); //API call For Submit
      commonToggle(false);
    }
  };
  /*************** Submit Outgoing Invoice Receipts **************** */
  const handleNewReceiptSave = () => {
    let valid = true;
    let errMsg = checkProperties(receiptData);
    if (errMsg !== "") valid = false;

    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenCustSnackbar(true);
    } else {
      createInvoiceReceipts(
        receiptData,
        setWantToCallGet,
        wantToCallGet,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setReceivedDrawer
      ); //API call For Submit
      commonToggle(false);
    }
  };
  /* -------------------------------------------------------------------------------------------- */
  useEffect(() => {
    getOutgoingInvoice(setOutGoingInvoice, jobId, setLoading);
  }, [wantToCallGet]);
  /* -------------------------------------------------------------------------------------------- */
  const handleOpenRecieptDrawer = (invoiceId, jobId) => {
    setReceiptData({
      ...receiptData,
      invoiceId: invoiceId,
      jobId: jobId,
      referenceNo: "",
      receiptDate: "",
      receiptTotal: "",
      notes: "",
    });
    //toggleReceivedDrawer("right", true);
    setReceivedDrawer({ ...receivedDrawer, ["right"]: true });
  };
  /* -------------------------------------------------------------------------------------------- */
  //Invoice
  const columns = [
    {
      name: "invoiceNo",
      label: "Code",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        setCellProps: () => ({
          style: { minWidth: "280px", maxWidth: "280px" },
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              className="link"
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                handleOpenDrawer(outgoingInvoice[tableMeta.rowIndex].invoiceId);

                setInvoicedata({
                  ...invoicesData,
                  jobId: outgoingInvoice[tableMeta.rowIndex].jobId,
                  description: outgoingInvoice[tableMeta.rowIndex].description,
                  status: outgoingInvoice[tableMeta.rowIndex].status,
                  claimAmount: outgoingInvoice[tableMeta.rowIndex].claimAmount,
                  comments: outgoingInvoice[tableMeta.rowIndex].comments,
                  invoiceId: outgoingInvoice[tableMeta.rowIndex].invoiceId,
                  invoicedate: outgoingInvoice[tableMeta.rowIndex].invoicedate,
                });
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "invoiceDate",
      label: "InvoiceDate",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "dueDate",
      label: "DueDate",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "center",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
        customBodyRender: (value) => {
          if (value === "DRAFT") {
            return <Chip size="small" label="Draft" />;
          } else if (value === "PENDING") {
            return <Chip size="small" label="Pending" color="warning" />;
          } else if (value === "RECEIVED") {
            return <Chip size="small" label="Received" color="primary" />;
          } else if (value === "INVOICED") {
            return <Chip size="small" label="Invoiced" color="info" />;
          }
        },
      },
    },

    {
      name: "claimAmount",
      label: "ClaimAmount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <span>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}
          </span>
        ),
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "center",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },

    {
      name: "comments",
      label: "Comments",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "280px", maxWidth: "280px" },
          align: "center",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },

    {
      name: "jobId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT INVOICES" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleOpenDrawer(outgoingInvoice[tableMeta.rowIndex].invoiceId);
                    setInvoicedata({
                      ...invoicesData,
                      jobId: outgoingInvoice[tableMeta.rowIndex].jobId,
                      description: outgoingInvoice[tableMeta.rowIndex].description,
                      status: outgoingInvoice[tableMeta.rowIndex].status,
                      claimAmount: outgoingInvoice[tableMeta.rowIndex].claimAmount,
                      comments: outgoingInvoice[tableMeta.rowIndex].comments,
                      invoiceId: outgoingInvoice[tableMeta.rowIndex].invoiceId,
                      invoicedate: outgoingInvoice[tableMeta.rowIndex].invoicedate,
                    });
                  }}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Create Invoice/Reciept" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    handleOpenRecieptDrawer(outgoingInvoice[tableMeta.rowIndex].invoiceId, value);
                  }}
                >
                  <ReceiptIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Share Email" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  style={{
                    padding: "2px 2px",
                  }}
                  onClick={() => handleMailUpdate(tableMeta.rowIndex)}
                >
                  <ReplyIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  // ----------------------------Calculations-------------------------------------------------
  const gsttotal = (invoicesData.claimAmount * 10) / 100;
  const ClaimAmount = invoicesData.claimAmount;
  const InvoiceTotal = parseInt(ClaimAmount) + parseInt(gsttotal);
  // ----------------------------Calculations-Ends---------------------------------------------
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: selectableRows,
    selectableRows: false,
    selectableRowsOnClick: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "single",
    selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    //   console.log("rorowData", rowData);
    // },
    onTableChange: (action, state) => {
      if (action === "rowsdelID") {
      }
      // console.log(action);
      // console.dir(state);
    },
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   console.log(rowsSelectedData, allRows, rowsSelected, "data", data[rowsSelected]);
    //   // this.setState({ rowsSelected: rowsSelected });
    // },
    onRowsDelete: (rowsdelID, newData) => {
      handleDelete(rowsdelID, newData);
    },
    renderExpandableRow: (rowData, rowMeta) => {
      console.log("rowMeta", rowMeta);

      return (
        <InvoicesByReceipt
          //invoiceId={invoicesData[rowMeta.rowIndex].invoiceId}
          outGoingInvoiceId={outgoingInvoice[rowMeta.rowIndex].invoiceId}
          setOpenCustSnackbar={setOpenCustSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          type={props.whichInvoices}
        />
      );
    },
  };

  /* we can call this commonToggle function for open and close toggleDrawer*/
  function commonToggle(open) {
    setState({ ...state, ["right"]: open });
  }
  return (
    <React.Fragment>
      <Grid container mt={1}>
        <Grid item xs={12}>
          {loading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <React.Fragment>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Grid item xs={12} mb={1} align="right">
                  <ButtonGroup variant="text" aria-label="text primary button group">
                    <Tooltip title="ADD INVOICE" placement="top" TransitionComponent={Zoom} arrow>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={(e) => handleEmptySubmitForm(e)}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                    <Button aria-label="Setting">
                      <SettingsRoundedIcon />
                    </Button>
                  </ButtonGroup>
                </Grid>
              </div>
              {outgoingInvoice?.length === 0 ? (
                <EmptyEstimatesDialog
                  heading="Empty Outgoing Invoices"
                  msg="You don't have any Invoices here yet.
            Click the 'Add Invoice'  above to get started, or choose from a template"
                />
              ) : (
                <>
                  <Grid rowSpacing={2}>
                    <Grid item xs={12}>
                      <MUIDataTable
                        title={"Invoices"}
                        data={outgoingInvoice}
                        columns={columns}
                        options={options}
                      />
                      <AlertPopOutgoingInvoice
                        confirmDelete={confirmDelete}
                        setConfirmDelete={setConfirmDelete}
                        delID={delID}
                        setOutGoingInvoice={setOutGoingInvoice}
                        outgoingInvoice={outgoingInvoice}
                        setOpenCustSnackbar={setOpenCustSnackbar}
                        setSnackbarMSG={setSnackbarMSG}
                        setSnackbarSeverity={setSnackbarSeverity}
                        jobId={jobId}
                        setWantToCallGet={setWantToCallGet}
                        wantToCallGet={wantToCallGet}
                      />
                      {openCustSnackbar && (
                        <CustomizedSnackbars
                          openSnackbar={openCustSnackbar}
                          setOpenSnackbar={setOpenCustSnackbar}
                          snackbarMSG={snackbarMSG}
                          severity={snackbarSeverity}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Grid>
      <Drawer
        anchor={"right"}
        open={shareDrawerState["right"]}
        onClose={toggleShareDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <div>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                mb={2}
                style={{
                  fontSize: "20px",
                }}
              >
                Email : -
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div style={{ position: "absolute" }}>
                <lable>To:</lable>
              </div>
              <div style={{ position: "relative" }}>
                <TextField
                  variant="standard"
                  sx={{ minWidth: "100%", paddingLeft: "30px" }}
                  inputProps={{
                    style: { fontSize: 12 },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ position: "absolute" }}>
                <lable>CC:</lable>
              </div>
              <div style={{ position: "relative" }}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ minWidth: "100%", paddingLeft: "30px" }}
                  inputProps={{
                    style: { fontSize: 16 },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="Subject"
                label="Subject"
                variant="outlined"
                type="text"
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: "100%" }}>
                <FormControl>
                  <lable>Message</lable>
                  <br />
                  {
                    <CKEditor
                      initData={outgoingInvoice.message}
                      sonChange={(e) => setInvitationMessage(e.editor.getData())}
                    />
                  }
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} align="right">
              <>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  variant="contained"
                  style={{ marginLeft: "10px" }}
                  onClick={() => submitInvoices()}
                >
                  Save & Share
                </Button>
                <Button
                  color="primary"
                  size="small"
                  type="submit"
                  variant="contained"
                  //onClick={() => submitInvoices()}
                  style={{ marginLeft: "10px" }}
                >
                  Save & close
                </Button>
              </>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.button}
                style={{ marginLeft: "10px" }}
                onClick={toggleShareDrawer("right", false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
      {roundLoader && <SimpleBackdrop />}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "80%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          {editInvoiceDrawerLoading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isBtnDisbl ? (
                  <Typography
                    color="primary"
                    variant="h4"
                    mb={2}
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    Invoice :-
                  </Typography>
                ) : (
                  <Typography
                    variant="h4"
                    color="primary"
                    mb={2}
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    Invoice Details
                  </Typography>
                )}
                <Divider />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  value={invoicesData.description}
                  required
                  size="small"
                  label="Description"
                  onChange={(e) => setInvoicedata({ ...invoicesData, description: e.target.value })}
                  className={classes.textField}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setInvoicedata({ ...invoicesData, status: newValue });
                  }}
                  value={invoicesData.status}
                  options={status}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="status" size="small" required />
                  )}
                />
              </Grid>
              {invoicesData.status === "INVOICED" && (
                <>
                  <Grid item xs={6}>
                    <Stack sx={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          id="invoiceDate"
                          fullWidth
                          size="small"
                          label="Invoice date"
                          inputVariant="outlined"
                          onChange={(newvalue) => setInvoicedate(newvalue)}
                          value={invoicedate}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack sx={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          id="dueDate"
                          fullWidth
                          size="small"
                          label="Due on"
                          inputVariant="outlined"
                          onChange={(newvalue) => setDueDate(newvalue)}
                          value={dueDate}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => <TextField size="small" {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography variant="h5">Contract Amount</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={invoicesData.claimAmount}
                  required
                  size="small"
                  label="Claim Amount"
                  type="number"
                  variant="outlined"
                  onChange={(e) => setInvoicedata({ ...invoicesData, claimAmount: e.target.value })}
                  className={classes.textField}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  value={invoicesData.comments}
                  size="small"
                  label="Comments (visible to customer)"
                  variant="outlined"
                  onChange={(e) => setInvoicedata({ ...invoicesData, comments: e.target.value })}
                  className={classes.textField}
                />
              </Grid>

              <Grid item xs={12} align="right">
                <Box
                  sx={{
                    width: "300px",
                  }}
                >
                  <Table sx={{ border: "none" }}>
                    <TableRow>
                      <TableCell variant="head"> {"Sub Total:"}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={invoicesData.claimAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell variant="head">
                        {"GST:"}
                        {
                          <NumberFormat
                            value={10}
                            displayType={"text"}
                            thousandSeparator={false}
                            suffix={"%"}
                          />
                        }
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={gsttotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </Box>
                {invoicesData.status === "INVOICED" && (
                  <Grid item xs={12} my={1}>
                    <Grid
                      item
                      xs={12}
                      my={2}
                      style={{ display: "inline-block", width: "100%", overflow: "auto" }}
                    >
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ minWidth: 600 }}>
                                <strong>PAYMENTS</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        <Table>
                          <TableBody align={"center"}>
                            {outgoingInvoice.map((row, i) => {
                              console.log("outgoingInvoice", outgoingInvoice);
                              return (
                                <TableRow>
                                  <TableCell> </TableCell>
                                  <TableCell width="30%">This is purchase order description 1</TableCell>
                                  <TableCell> 16-Jun-2021</TableCell>
                                  <TableCell align="right"> -$450.00</TableCell>
                                  <TableCell>₹ 0.00</TableCell>
                                  <TableCell>₹ 0.00</TableCell>
                                  <TableCell>₹ 0.00</TableCell>
                                  <TableCell>
                                    <ButtonGroup
                                      variant="contained"
                                      aria-label="outlined primary button group"
                                    // key={quoteItem}
                                    //onClick={(e) => handleConfirm(index)}
                                    >
                                      <Button size="small" className="mr10" color="secondary">
                                        <Delete fontSize="small" />
                                      </Button>
                                    </ButtonGroup>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                )}
                <Box
                  sx={{
                    width: "300px",
                  }}
                >
                  <Table>
                    {/* <TableRow>
                    <TableCell variant="head">{"Total :"} </TableCell>
                    <TableCell align="right">
                      $0.00
                      <NumberFormat
                        // value={finalSubTotal.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow> */}
                    <TableRow>
                      <TableCell variant="head"> {"Invoice Total (Incl) :"}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={InvoiceTotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>
                    {/* <TableRow>
                    <TableCell variant="head">{"Balance :"} </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={invoicesData.claimAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow> */}
                  </Table>
                </Box>
              </Grid>

              <Grid item xs={12} align="right" my={-2}>
                {isBtnDisbl ? (
                  <>
                    <Button
                      color="primary"
                      size="small"
                      type="button"
                      variant="contained"
                      onClick={() => submitInvoices()}
                    >
                      Save & Send
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      type="submit"
                      variant="contained"
                      onClick={() => submitInvoices()}
                      style={{ marginLeft: "15px" }}
                    >
                      Save & close
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    size="small"
                    color="primary"
                    onClick={updateInvoiceDetails}
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
                <Button
                  type="button"
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={toggleDrawer("right", false)}
                  style={{ marginLeft: "15px" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Drawer>
      {roundLoader && <SimpleBackdrop />}
      <Drawer
        anchor={"right"}
        open={receivedDrawer["right"]}
        onClose={toggleReceivedDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                variant="h4"
                mb={2}
                style={{
                  fontSize: "20px",
                }}
              >
                NEW RECEIPT :-
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={"Reference Code"}
                variant="outlined"
                size="small"
                className={classes.textField}
                type="text"
                value={receiptData.referenceNo}
                name="reference No"
                onChange={(e) =>
                  setReceiptData({
                    ...receiptData,
                    referenceNo: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={"Receipt Total"}
                variant="outlined"
                size="small"
                className={classes.textField}
                type="text"
                value={receiptData.receiptTotal}
                name="receiptTotal"
                onChange={(e) =>
                  setReceiptData({
                    ...receiptData,
                    receiptTotal: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ position: "absolute" }}>
                <lable>Date Received</lable>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    id="start-date"
                    fullWidth
                    size="small"
                    inputVariant="outlined"
                    onChange={(newvalue) =>
                      setReceiptData({
                        ...receiptData,
                        receiptDate: newvalue.$d
                          .toLocaleString("en-us", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
                      })
                    }
                    value={receiptData.receiptDate}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label={"Notes"}
                variant="outlined"
                size="small"
                className={classes.textField}
                type="text"
                value={receiptData.notes}
                name="notes"
                onChange={(e) =>
                  setReceiptData({
                    ...receiptData,
                    notes: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} align="right" my={-2}>
              <>
                <Button
                  variant="contained"
                  color="primary"
                  align="right"
                  size="small"
                  onClick={handleNewReceiptSave}
                >
                  Save
                </Button>
              </>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                align="right"
                onClick={closeFunction}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </React.Fragment>
  );
}
