/** @format */

import { uuid } from "uuidv4";
import apis from "../../utils/apis";

export const getCatalogue = (setCatalogueData, setLoad, catalogueId) => {
  apis
    .GET({
      endpoint: "/catalogues",
    })
    .then((res) => {
      if (catalogueId) {
        let filteredData = res.data.filter((el) => {
          return el.catalogueId === catalogueId;
        });
        setCatalogueData(filteredData);
      } else {
        if (res.data) {
          res.data.sort(function (a, b) {
            return a.catalogueId - b.catalogueId;
          });
        }
        setCatalogueData(res.data);
      }
      setLoad(false);
    });
};

// -------------------------------------------------------------------------------
export const isCatalogueEditable = (catalogueId, setEditableCatalogue) => {
  apis
    .GET({
      endpoint: "/catalogues",
    })
    .then((res) => {
      if (catalogueId) {
        let filteredData = res.data.filter((el) => {
          return el.catalogueId === catalogueId;
        });
        setEditableCatalogue(filteredData[0].editable);
      }
    });
};
// -------------------------------------------------------------------------------
export const createCatalogue = (
  obj,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setRoundLoader,
  setState
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: `/catalogue`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("Catalogue created successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
      } else {
        setSnackbarMSG("Catalogue creation failed !");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    })
    .catch((err) => console.log("No data "));
};

// -------------------------------------------------------------------------------
export const createCatalogueFromJobplan = (
  obj,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setIsCatalogueCreated,
  setNewCatalogueId
) => {
  apis
    .POST({
      endpoint: `/catalogue`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setNewCatalogueId(res.data.value);
        setSnackbarMSG("Catalogue created successfully, Add items to catalogue");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setIsCatalogueCreated(true);
      } else {
        setSnackbarMSG("Catalogue creation failed !");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setIsCatalogueCreated(false);
      }
    })
    .catch((err) => console.log("No data "));
};

// -------------------------------------------------------------------------------

export const deleteCatalogueByID = (
  deleteId,
  deleteContactId,
  setOpenSnackbar,
  catalogueData,
  setCatalogueData,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/catalogue/${deleteContactId}/${deleteId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        // setDelLoading(false);
        var newData = catalogueData.filter((catalogue) => {
          if (catalogue.catalogueId != deleteId) {
            return catalogue;
          }
        });
        setCatalogueData(newData);
        setSnackbarMSG("Catalogue deleted successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("Catalogue data delete successFully>>>");
      } else {
        setCatalogueData(catalogueData);
        setSnackbarMSG("Catalogue deletion unsuccessfull!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
        console.log("Catalogue deleting failed>>>");
      }
    });
};

// -------------------------------------------------------------------------------

export const getCatalogueItemsByCatalogueId = (
  setGroups,
  catalogueId,
  contactId,
  setLoad,
  setTempGroups,
  setOpenAllRows
) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/catalogue/${contactId}/${catalogueId}/items`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setGroups(res?.data);
      setTempGroups(JSON.parse(JSON.stringify(res.data)));
      // setTempRows(res.data);
      setLoad(false);
      console.log("building rows ", res.data);
    })
    .catch((err) => console.log("No building data "));
};

// -------------------------------------------------------------------------------
export const updateCatalogueItemsByCatalogueId = (
  catalogueId,
  contactId,
  setGroups,
  arr,
  setRoundLoader,
  setOpenSnackbar,
  setTempGroups
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/catalogue/${contactId}/${catalogueId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res?.data?.message == "SUCCESS") {
        setGroups(arr);
        setTempGroups(JSON?.parse(JSON?.stringify(arr)));
        setOpenSnackbar(true);
        setRoundLoader(false);
        console.log("building data posting success >>>", arr);
      } else {
        setRoundLoader(false);
        console.log("building posting failed>>>");
      }
    });
};

// -------------------------------------------------------------------------------

export const getAllCatalogueItems = (setCatalogueItems) => {
  apis
    .GET({
      endpoint: "/catalogue/items",
    })
    .then((res) => {
      if (res.status === 200) {
        setCatalogueItems(res.data);
      } else {
        setCatalogueItems([]);
      }
    });
};
