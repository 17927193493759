/** @format */

import apis from "../../utils/apis";

export const getRoles = (setUser, setLoad) => {
  apis
    .GET({
      endpoint: "/user-roles",
    })
    .then((res) => {
      // res.data.sort(function (a, b) {
      //   return a.contactNo - b.contactNo;
      // });

      setUser(res.data);
      console.log(res.data);
      setLoad(false);
    });
};
export const getPermission = (setPermission) => {
  apis
    .GET({
      endpoint: "/config/permissions",
    })
    .then((res) => {
      // res.data.sort(function (a, b) {
      //   return a.contactNo - b.contactNo;
      // });
      setPermission(res.data);
      console.log(res.data);
    });
};
export const createRole = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallGet,
  wantToCallGet,
  commonToggle,
  setLoading
) => {
  setLoading(true);
  apis
    .POST({
      endpoint: "/user-role",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setOpenSnackbar(true);
        setSnackbarMSG("Role created successFully");
        setSnackbarSeverity("success");
        commonToggle(false);
      } else {
        setOpenSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
      }
      setLoading(false);
    });
};
export const updateRole = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallGet,
  wantToCallGet,
  setLoading,
  commonToggle
) => {
  setLoading(true);
  apis
    .PUT({
      endpoint: "/user-role",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setSnackbarMSG("Role updated successfully");
        setSnackbarSeverity("success");
        setLoading(false);
        commonToggle(false);
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setLoading(false);
        commonToggle(false);
        setOpenSnackbar(true);
      }
    });
};
export const deleteUserRole = (
  deleteRole,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  roles,
  setRoles,
  setLoading
) => {
  setLoading(true);
  apis
    .POST({
      endpoint: `/delete-user-role`,
      payload: deleteRole,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        var newData = roles.filter((element) => {
          if (element.role != deleteRole.role) {
            return element;
          }
        });
        setRoles(newData);
        setSnackbarMSG("Role deleted successFully");
        setSnackbarSeverity("success");
        setLoading(false);
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setLoading(false);
        setOpenSnackbar(true);
      }
    });
};
function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
