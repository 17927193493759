/** @format */

import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonGroup,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import NumberFormat from "react-number-format";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { styled, lighten, darken } from "@mui/system";
import { checkProperties, getGst } from "../../UI/GlobalApi";
import { getLineItemsGroupsName } from "../Variation/variation.helper";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
// import ErrorStyles from "../../UI/ErrorStyles";
// import { useForm, Controller } from "react-hook-form";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));
export default function NewOrderLineItemsTable({
  newOrderData,
  setNewOrderData,
  userConfigs,
  userConfigsuom,
  lineItemsForAutoselect,
  lineItemsGroupsForAutoselect,
  setShowSaveButton,
  jobId,
}) {
  const classes = useStyles();
  //const ErrorClasses = ErrorStyles();
  const [rows, setRows] = useState(
    newOrderData.orderLineItems && JSON.parse(JSON.stringify(newOrderData.orderLineItems))
  );
  const [tempRows, setTempRows] = useState(
    newOrderData.orderLineItems && JSON.parse(JSON.stringify(newOrderData.orderLineItems))
  );
  const [isEdit, setIsEdit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const filter = createFilterOptions();

  /* -------------------------------------------------------------------------- */

  const handleAdd = () => {
    let lastBeforeRowLineId;
    if (rows.length === 0) {
      lastBeforeRowLineId = 0;
    } else {
      lastBeforeRowLineId = rows[rows.length - 1].itemCode;
    }
    setShowSaveButton(false);
    setIsEdit(true);
    setRows([
      ...rows,
      {
        jobLineItemId: "",
        itemCode: "",
        itemName: "",
        itemDescription: "",
        itemGroupCode: "",
        itemGroupName: "",
        type: "",
        qty: 1,
        unitCost: 0,
        gst: true,
        uom: "",
      },
    ]);
  };
  // --------------------------------------------------------------------------------------
  const handlesLineItemSave = () => {
    let valid = true;
    let errMsg = checkProperties(rows);
    if (errMsg !== "") valid = false;
    if (valid == true) {
      setShowSaveButton(true);
      setIsEdit(false);
      setNewOrderData({ ...newOrderData, orderLineItems: rows });
      setTempRows(JSON.parse(JSON.stringify(rows)));
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // --------------------------------------------------------------------------------------
  const handleEdit = () => {
    //setTempRows(JSON.parse(JSON.stringify(rows)));
    setTempRows(rows);
    setIsEdit(true);
    setShowSaveButton(false);
  };
  // --------------------------------------------------------------------------------------
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(rows));
    if (name === "unitCost" || name === "qty") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }

    setRows(list);
  };
  // --------------------------------------------------------------------------------------
  const handleCancel = () => {
    console.log("tempRows in cancel:", tempRows);
    setRows(tempRows); /* Reset our old data after cancel edit/add operation  */
    setIsEdit(false);
    setShowSaveButton(true);
  };
  // --------------------------------------------------------------------------------------
  const handleChangeGST = (e, i) => {
    // if (!e.target.checked) {
    //   const list = JSON.parse(JSON.stringify(rows));
    //   list[i][e.target.name] = false;
    //   setRows(list);
    // } else {
    //   const list = JSON.parse(JSON.stringify(rows));
    //   list[i][e.target.name] = true;
    //   setRows(list);
    // }
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(rows));
      list[i][e.target.name] = value;
      setRows(list);
    }
  };
  // --------------------------------------------------------------------------------------
  const handleClearLineItem = (row) => {
    let filterList = rows.filter((el) => {
      return row.itemCode != el.itemCode;
    });
    let count = 1;
    var test = filterList.filter(function (el) {
      return (row.itemCode = count++);
    });
    setRows(test);
  };
  // --------------------------------------------------------------------------------------
  const getLineItemTotalCost = (e) => {
    let mult;
    let itemTax;
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (e.gst == "10") {
      itemTax = (mult * getGst(e.gst)) / 100;
    } else {
      itemTax = 0;
    }
    if (isNaN(mult + itemTax)) return 0;
    return mult + itemTax;
  };
  // ----------------------------Calculations-------------------------------------------------
  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;

  rows.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (isNaN(mult)) mult = 0;
    finalSubTotal = finalSubTotal + mult;
    itemTax = (mult * getGst(e.gst)) / 100;
    if (isNaN(itemTax)) itemTax = 0;
    finalTax = finalTax + parseInt(itemTax);
    finalTotal = finalSubTotal + finalTax;
  });
  // ----------------------------Function-for-lineItem-autocomplete-------------------------------------------------
  const handleAutoselectLineItem = (i, row, newValue) => {
    const list = JSON.parse(JSON.stringify(rows));
    if (typeof newValue === "string") {
      list[i] = {
        ...list[i],
        itemDescription: newValue,
      };
      setRows(list);
    } else if (typeof newValue === "object") {
      list[i] = {
        ...list[i],
        jobLineItemId: newValue?.lineItemId,
        itemName: newValue?.itemName,
        itemDescription: newValue?.itemDescription,
        type: newValue?.type,
        uom: newValue?.uom,
        unitCost: newValue?.estimatedUnitCost,
        qty: newValue?.estimatedQty,
        gst: newValue?.gst,
        itemGroupName: newValue?.itemGroupName,
        itemGroupCode: newValue?.itemGroupCode,
        itemCode: newValue?.itemCode,
      };
      setRows(list);
    }
  };

  const handleAutoselectLineItemName = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemName: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.estimatedQty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setRows(list);
      }
    }
  };

  const handleAutoselectLineItemCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemCode: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.estimatedQty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setRows(list);
      }
    }
  };

  const handleAutoselectLineItemGroup = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: "",
          itemGroupName: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setRows(list);
      }
    }
  };

  const handleAutoselectLineItemGroupCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue,
          itemGroupName: "",
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setRows(list);
      }
    }
  };
  // ----------------------------------------------------------------------------------------------
  function handleChangeGroupObject(e, i, newValue) {
    console.log("idddndex", newValue);
    if (newValue) {
      console.log("value", newValue);
      var list = JSON.parse(JSON.stringify(rows));
      list[i]["itemDescription"] = newValue.itemDescription;
      setRows(list);
    }
  }
  // ----------------------------------------------------------------------------------------------
  function handleChangeItemGroup(e, index, newValue) {
    console.log("idddndex", newValue);
    if (newValue) {
      console.log("value", newValue);
      var list = JSON.parse(JSON.stringify(rows));
      list[index]["itemGroupName"] = newValue.itemGroupName;
      setRows(list);
    }
  }
  // ----------------------------------------------------------------------------------------------
  const [valuex, setValuex] = React.useState([]);
  const AutocompleteGroup = (props) => {
    const { i, orderItem } = props;
    const filter = createFilterOptions();

    return (
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        size="small"
        value={valuex[i]}
        onChange={(event, newValue) => {
          var selectListGroup = [...valuex];
          if (typeof newValue === "string") {
            selectListGroup[i] = {
              title: newValue,
            };
            setValuex(selectListGroup);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            var listGroup = [...rows];
            var newGroup = {
              groupCode: listGroup.length + 1,
              itemDescription: newValue.inputValue,
            };
            selectListGroup[i] = {
              groupCode: listGroup.length + 1,
              itemDescription: newValue.inputValue,
            };
            setValuex(selectListGroup);
            handleChangeGroupObject(event, i, newGroup);
          } else {
            selectListGroup[i] = newValue;
            setValuex(selectListGroup);
            handleChangeGroupObject(event, i, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.itemDescription);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={lineItemsForAutoselect}
        getOptionLabel={(option) => {
          console.log("option", option);
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.itemDescription;
        }}
        //getOptionLabel={(option) => option.groupName}
        groupBy={(option) => option.itemGroupName}
        renderInput={(params) => <TextField {...params} />}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    );
  };

  // ----------------------------------------------------------------------------------------------
  const [value, setValue] = React.useState([]);
  const AutocompleteGroups = (props) => {
    const { i, orderItem } = props;
    const filter = createFilterOptions();

    return (
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        size="small"
        value={value[i]}
        onChange={(event, newValue) => {
          var selectListGroup = [...value];
          if (typeof newValue === "string") {
            selectListGroup[i] = {
              title: newValue,
            };
            setValue(selectListGroup);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            var listGroup = [...rows];
            var newGroup = {
              groupCode: listGroup.length + 1,
              itemGroupName: newValue.inputValue,
            };
            selectListGroup[i] = {
              groupCode: listGroup.length + 1,
              itemGroupName: newValue.inputValue,
            };
            setValue(selectListGroup);
            handleChangeItemGroup(event, i, newGroup);
          } else {
            selectListGroup[i] = newValue;
            setValue(selectListGroup);
            handleChangeItemGroup(event, i, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.itemGroupName);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={lineItemsForAutoselect}
        getOptionLabel={(option) => {
          console.log("option", option);
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.itemGroupName;
        }}
        //getOptionLabel={(option) => option.groupName}
        groupBy={(option) => option.itemGroupName}
        renderInput={(params) => <TextField {...params} />}
        renderGroup={(params) => (
          <li>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    );
  };
  // ----------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {isEdit ? (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            align="right"
            onClick={handleCancel}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </Button>
          <Button variant="contained" color="primary" onClick={handlesLineItemSave} size="small">
            <SaveIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" align="right" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button variant="contained" size="small" color="primary" align="right" onClick={handleEdit}>
            <EditIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      )}
      {rows.length < 1 ? (
        <EmptyEstimatesDialog
          heading="Empty Line Items"
          msg="Please add some order items by click on add icon abouv"
        />
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "50px" }}>Code</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                <TableCell sx={{ width: 1 / 2, minWidth: 300 }}>Description</TableCell>
                <TableCell align={"center"}>Group Code</TableCell>{" "}
                <TableCell align={"center"}>Group Name</TableCell>
                <TableCell align="center" sx={{ minWidth: 180 }}>
                  Type
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 100 }}>
                  Qty
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 95 }}>
                  Unit Cost(Ex)
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 115 }}>
                  UOM
                </TableCell>
                {!isEdit && <TableCell align="right">Total Cost(Ex)</TableCell>}
                <TableCell align="right">GST</TableCell>
                {isEdit && <TableCell align="right">Action</TableCell>}
              </TableRow>
            </TableHead>

            {isEdit ? (
              <TableBody align={"center"}>
                {rows.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        <Autocomplete
                          id="grouped-demo"
                          value={row.itemCode}
                          options={lineItemsForAutoselect}
                          groupBy={(option) => option.itemGroupName}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            else if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            else return `${option.itemCode}: ${option.itemName}`;
                          }}
                          onChange={(event, newValue) => {
                            handleAutoselectLineItemCode(i, row, newValue);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.itemCode);
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue);
                            }
                            return filtered;
                          }}
                          fullWidth
                          size="small"
                          renderInput={(params) => <TextField {...params} />}
                          renderGroup={(params) => (
                            <li>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          id="grouped-demo"
                          value={row.itemName}
                          options={lineItemsForAutoselect}
                          groupBy={(option) => option.itemGroupName}
                          getOptionLabel={(option) => {


                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            else if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            else return `${option.itemCode}: ${option.itemName}`;
                          }}
                          onChange={(event, newValue) => {
                            handleAutoselectLineItemName(i, row, newValue);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.itemName);
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue);
                            }
                            return filtered;
                          }}
                          fullWidth
                          size="small"
                          renderInput={(params) => <TextField {...params} />}
                          renderGroup={(params) => (
                            <li>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Autocomplete
                          id="grouped-demo"
                          value={row.itemDescription}
                          options={lineItemsForAutoselect}
                          groupBy={(option) => option.itemGroupName}
                          getOptionLabel={(option) => {


                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            else if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            else return `${option.itemCode}: ${option.itemDescription}`;
                          }}
                          onChange={(event, newValue) => {
                            handleAutoselectLineItem(i, row, newValue);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option.itemDescription
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue);
                            }
                            return filtered;
                          }}
                          fullWidth
                          size="small"
                          renderInput={(params) => <TextField {...params} />}
                          renderGroup={(params) => (
                            <li>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          minWidth: 80,
                          "& input": {
                            textAlign: "right",
                          },
                        }}
                      >
                        <Autocomplete
                          id="grouped-demo"
                          value={row.itemGroupCode}
                          options={lineItemsGroupsForAutoselect}
                          // groupBy={(option) => option.itemGroupName}
                          getOptionLabel={(option) => {

                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            else if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            else return option.groupCode;
                          }}
                          onChange={(event, newValue) => {
                            handleAutoselectLineItemGroupCode(i, row, newValue);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.groupCode);
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue);
                            }
                            return filtered;
                          }}
                          fullWidth
                          size="small"
                          renderInput={(params) => <TextField {...params} />}
                          renderGroup={(params) => (
                            <li>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          minWidth: 80,
                          "& input": {
                            textAlign: "right",
                          },
                        }}
                      >
                        <Autocomplete
                          id="grouped-demo"
                          value={row.itemGroupName}
                          options={lineItemsGroupsForAutoselect}
                          getOptionLabel={(option) => {

                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            else if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            else return option.groupName;
                          }}
                          onChange={(event, newValue) => {
                            handleAutoselectLineItemGroup(i, row, newValue);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.groupName);
                            if (inputValue !== "" && !isExisting) {
                              filtered.push(inputValue);
                            }
                            return filtered;
                          }}
                          fullWidth
                          size="small"
                          renderInput={(params) => <TextField {...params} />}
                          renderGroup={(params) => (
                            <li>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />
                      </TableCell>
                      <TableCell align="center" className="muitable_cell_title_type">
                        <Select
                          className={classes.textField}
                          size="small"
                          fullWidth
                          name="type"
                          value={row.type}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          {userConfigs.config?.length != 0 &&
                            userConfigs.config?.map(
                              (el) =>
                                el.enabled === true && <MenuItem value={el.value}>{el.value}</MenuItem>
                            )}
                        </Select>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          "& input": {
                            textAlign: "right",
                          },
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          type="number"
                          value={row.qty}
                          name="qty"
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          minWidth: 100,
                          "& input": {
                            textAlign: "right",
                          },
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          type="number"
                          value={row.unitCost}
                          name="unitCost"
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </TableCell>
                      <TableCell align="center" className="muitable_cell_title_type">
                        <Select
                          className={classes.textField}
                          size="small"
                          fullWidth
                          name="uom"
                          value={row.uom}
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          {userConfigsuom.config?.length != 0 &&
                            userConfigsuom.config?.map(
                              (el) =>
                                el.enabled === true && <MenuItem value={el.value}>{el.value}</MenuItem>
                            )}
                        </Select>
                      </TableCell>
                      <TableCell align="center">
                        {row.gst === true ? (
                          <Checkbox
                            size="small"
                            value={10}
                            name="gst"
                            defaultChecked
                            onChange={(e) => handleChangeGST(e, i, row)}
                          />
                        ) : (
                          <Checkbox
                            size="small"
                            name="gst"
                            value={0}
                            onChange={(e) => handleChangeGST(e, i, row)}
                          />
                        )}
                      </TableCell>

                      <TableCell>
                        <ButtonGroup variant="contained" size="small">
                          <Button
                            size="small"
                            color="secondary"
                            className="mr10"
                            key={row.itemId}
                            onClick={(e) => handleClearLineItem(row)}
                          >
                            <ClearIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell>{row.itemCode}</TableCell>
                      <TableCell>{row.itemName} </TableCell>
                      <TableCell>{row.itemDescription} </TableCell>
                      <TableCell align="center"> {row.itemGroupCode}</TableCell>
                      <TableCell align="center"> {row.itemGroupName}</TableCell>
                      <TableCell align="center"> {row.type}</TableCell>
                      <TableCell align="right">{row.qty}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={(row.unitCost * 1).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                      <TableCell align="center">{row.uom}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={getLineItemTotalCost(row).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={(getGst(row.gst) * 1).toFixed(1)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}

      <Grid
        container
        spacing={0}
        mt={2}
        mb={1}
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item xs={4}>
          <Table
            size="small"
            sx={{
              borderBottom: "none",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="head">
                  {"Sub Total:"}
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalSubTotal * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell align="left" variant="head">
                  Delivery/Freight:
                  {
                    <NumberFormat
                     value={"10"}
                      displayType={"text"}
                      thousandSeparator={false}
                      suffix={"%"}
                    />
                  }
                </TableCell>
                <TableCell align="right">
                  {
                    <TextField
                      variant="outlined"
                      size="small"
                      sx={{ width: "30%", height: "20px" }}
                      type="text"
                      value={delivery}
                      name="delivery"
                      onChange={(e) => {
                        var delivery;
                        var finalDelivery;
                        setDelivery(e.target.value);
                        delivery = (parseInt(e.target.value) * 10) / 100;
                        console.log("delivery Before:", delivery);
                        if (isNaN(delivery)) delivery = 0;
                        console.log("delivery after:", delivery);

                        finalDelivery = parseInt(e.target.value) + delivery;
                        console.log("finalDelivery Before:", finalDelivery);

                        if (isNaN(finalDelivery)) finalDelivery = 0;
                        console.log("finalDelivery after:", finalDelivery);

                        finalTotal = finalTotal + finalDelivery;
                        console.log("finalTotal :", finalTotal);
                      }}
                    />
                  }
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell align="left" variant="head">
                  {"GST:"}
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalTax * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" variant="head">
                  <b>Order Total:</b>
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalTotal * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
