/** @format */

import apis from "../../../utils/apis";

export const getSpecificationsByJobPlanId = (
  setRows,
  jobPlanId,
  setLoad,
  setTempRows,
  setOpenAllRows
) => {
  setLoad(true);
  console.log("jobPlanId", jobPlanId);
  apis
    .GET({
      endpoint: `/job-plan/${jobPlanId}/specifications`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      setLoad(false);

      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setRows(res.data);
      setTempRows(JSON.parse(JSON.stringify(res.data)));

      console.log("After Sort specifications res.data ", res.data);
    })
    .catch((err) => console.log("No specifications data "));
};

export const getSpecificationsByEstimateId = (
  setRows,
  estimateId,
  setLoad,
  setTempRows,
  setOpenAllRows
) => {
  setLoad(true);

  apis
    .GET({
      endpoint: `/estimate-specifications/${estimateId}`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      setLoad(false);

      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setRows(res.data);
      setTempRows(JSON.parse(JSON.stringify(res.data)));

      console.log("After Sort specifications res.data ", res.data);
    })
    .catch((err) => console.log("No specifications data "));
};

export const updateSpecificationsByJobPlanId = (
  jobPlanId,
  arr,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setOpen,
  setTempRows,
  setRoundLoader
) => {
  console.log("len in api ", arr);
  setOpen(false);
  setRoundLoader(true);
  // setWantToCallGetJobPlan(true);
  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/specifications`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setTempRows(JSON.parse(JSON.stringify(arr)));
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
        setOpen(true);
        setRoundLoader(false);

        console.log("specifications data posting success >>>", arr);
      } else {
        console.log("specifications data posting failed>>>");
      }
    });
};

export const updateSpecificationsByEstimateId = (
  estimateId,
  arr,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setOpen,
  setTempRows,
  setRoundLoader
) => {
  console.log("len in api ", arr);
  setOpen(false);
  setRoundLoader(true);
  // setWantToCallGetJobPlan(true);
  apis
    .PUT({
      endpoint: `/estimate-specifications/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setTempRows(JSON.parse(JSON.stringify(arr)));
        if (wantToCallGetJobPlan) {
          setWantToCallGetJobPlan(false);
        } else {
          setWantToCallGetJobPlan(true);
        }
        setOpen(true);
        setRoundLoader(false);

        console.log("specifications data posting success >>>", arr);
      } else {
        console.log("specifications data posting failed>>>");
      }
    });
};

// Specifications Group Update
export const updateSpecificationsGroupByJobPlanId = (
  setRows,
  jobPlanId,
  arr,
  setOpenSnackbar,
  setTempRows,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader
) => {
  setRoundLoader(true);

  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/specifications`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setRows(arr);
        setTempRows(JSON.parse(JSON.stringify(arr)));
        setRoundLoader(false);
        setSnackbarMSG("Group Added Successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        console.log("specifications data posting success >>>", arr);
      } else {
        console.log("specifications data posting failed>>>");
        setRoundLoader(false);
        setSnackbarMSG("Group Adding Failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    });
};

export const updateSpecificationsGroupByEstimateId = (
  setRows,
  estimateId,
  arr,
  setOpenSnackbar,
  setTempRows,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader
) => {
  setRoundLoader(true);

  apis
    .PUT({
      endpoint: `/estimate-specifications/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setRows(arr);
        setTempRows(JSON.parse(JSON.stringify(arr)));
        setRoundLoader(false);
        setSnackbarMSG("Group Added Successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        console.log("specifications data posting success >>>", arr);
      } else {
        console.log("specifications data posting failed>>>");
        setRoundLoader(false);
        setSnackbarMSG("Group Adding Failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    });
};
export const deleteSpecificationsGroupByEstimateId = (
  setRows,
  estimateId,
  arr,
  setOpenSnackbar,
  setTempRows,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader
) => {
  setRoundLoader(true);

  apis
    .PUT({
      endpoint: `/estimate-specifications/${estimateId}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setRows(arr);
        setTempRows(JSON.parse(JSON.stringify(arr)));
        setRoundLoader(false);
        setSnackbarMSG("Group Deleted Successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        console.log("specifications data posting success >>>", arr);
      } else {
        console.log("specifications data posting failed>>>");
        setRoundLoader(false);
        setSnackbarMSG("Group Adding Failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    });
};
/* copy of update api for remove loader */
export const updateSpecificationsByJobPlanIdCopy = (
  jobPlanId,
  rows,
  setTempRows,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/job-plan/${jobPlanId}/specifications`,
      payload: rows,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        // if (arr[arr.length - 1].items.length != 0) {
        //   setDataPostSuccess(true);
        // }
        setTempRows(JSON.parse(JSON.stringify(rows)));
        setRoundLoader(false);
        setSnackbarMSG("Items Updated Successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        console.log("building data posting success >>>", rows);
      } else {
        setRoundLoader(false);
        setSnackbarMSG("Items Updation Failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        console.log("building posting failed>>>");
      }
    });
};
export const updateSpecificationsByEstimateIdCopy = (
  estimateId,
  rows,
  setTempRows,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate-specifications/${estimateId}`,
      payload: rows,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        // if (arr[arr.length - 1].items.length != 0) {
        //   setDataPostSuccess(true);
        // }
        setTempRows(JSON.parse(JSON.stringify(rows)));
        setRoundLoader(false);
        setSnackbarMSG("Items Updated Successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        console.log("building data posting success >>>", rows);
      } else {
        setRoundLoader(false);
        setSnackbarMSG("Items Updation Failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        console.log("building posting failed>>>");
      }
    });
};

export const generatePdf = (
  entityObj,
  estimateId,
  setPdfLink,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setIsLinkGenerated,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .GET({
      endpoint: `/estimate-specifications/export/${estimateId}`,
    })
    .then((res) => {
      console.log("EXPORT:", res);
      setRoundLoader(false);
      if (res.data.message == "SUCCESS") {
        setPdfLink(res.data.value);
        setIsLinkGenerated(true);
        setSnackbarMSG("Pdf Link generated, click on Download.");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Pdf Link generation failed");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    });
};
