/** @format */

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ProgressBar from "../UI/ProgressBar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import { CKEditor } from 'ckeditor4-react';
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import { createRole, getPermission, getRoles, updateRole } from "./Roles.helper";
import { Box, Snackbar } from "@mui/material";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SimpleBackdrop from "../UI/SimpleBackdrop";
// import AlertPopContact from './AlertPopContact';
import Alert from "@mui/material/Alert";
import { useForm, Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { Tooltip, Zoom } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import DeleteAlertPop from "./DeleteAlertPop";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { OAuthCtx } from "../../oauth/OAuthContext";
import ErrorStyles from "../UI/ErrorStyles";
import { USER_AND_ROLE_DELETE, USER_AND_ROLE_WRITE } from "../UI/GlobalVariables";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  box: {
    display: "flex",
  },

  // button: { padding: "7px", paddingLeft: "9px" },
}));

export default function Roles() {
  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const { hasValidAccess } = React.useContext(OAuthCtx);

  /* DataTable */
  const columns = [
    {
      name: "role",
      label: "Role",
      options: {
        setCellProps: () => ({ style: { minWidth: "500px", maxWidth: "500px" } }),
        filter: true,
        sort: true,
      },
    },
    {
      name: "permissions",
      label: "Permissions",
      options: {
        setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),

        customBodyRender: (value, tableMeta) => {
          return (
            <Stack direction="row" spacing={1} display="block">
              {value.map((obj) => (
                <>
                  <Chip label={obj.action} />
                  {/* <Chip label={obj.action} variant='outlined' /> */}
                </>
              ))}
            </Stack>
          );
        },
      },
    },

    {
      name: "role",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        //setCellProps: () => ({ align: "right" }),
        customBodyRender: (role, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              {hasValidAccess(USER_AND_ROLE_WRITE) && (
                <>
                  <Tooltip title="EDIT ROLE" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleUpdate(tableMeta.rowIndex)}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="DELETE ROLE" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="secondary"
                      value={role}
                      onClick={(e) => handleDelete(role)}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </>
              )}
            </ButtonGroup>
          );
        },
      },
    },
  ];
  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);

  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,

    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsOnClick: false,
    // selectableRows: "single",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    //   console.log("rorowData", rowData);
    // },
    onTableChange: (action, state) => {
      if (action === "rowsdelID") {
      }
      // console.log(action);
      // console.dir(state);
    },

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   console.log(rowsSelectedData, allRows, rowsSelected, "data", data[rowsSelected]);
    //   // this.setState({ rowsSelected: rowsSelected });
    // },
  };
  const [permission, setPermission] = useState([]);

  const [load, setLoad] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const [loading, setLoading] = useState(false);
  const [isBtnDisbl, setIsBtnDisbl] = useState(true);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [searchData, setSearchData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  /* -------------------------------------------------------------------------- */

  const [tempPermissionsObject, setTempPermissionsObject] = React.useState([]);

  const handleUpdate = (arrayIndex) => {
    /* this function used for open toggle */
    commonToggle(true);
    setIsBtnDisbl(false);
    let temp = permission.config.map((el) => {
      return { action: el.code, resources: ["*"] };
    });
    setTempPermissionsObject(temp);
    /* reset is method of useForm it is used for returning data to form */
    reset(roles[arrayIndex]);
  };

  /* -------------------------------------------------------------------------- */

  /* we can call this commonToggle function for open and close toggleDrawer*/
  function commonToggle(open) {
    setState({ ...state, ["right"]: open });
  }
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getRoles(setRoles, setLoad);
    getPermission(setPermission);
    if (hasValidAccess(USER_AND_ROLE_DELETE)) {
      setSelectableRows("single");
    }
  }, [wantToCallGet]);

  /* -------------------------------------------------------------------------- */

  const [deleteRole, setDeleteRole] = useState();

  const handleDelete = (role) => {
    setDeleteRole({ role });
    setConfirmDelete(true);
  };
  /* ----------------------Submit Contacts Details------------------------- */
  const submitContactDetails = (formData) => {
    // console.log('role', formData);
    let tempPermissions = [];
    tempPermissions = formData.permissions.map((el) => {
      return {
        action: el,
        resources: ["*"],
      };
    });
    let roleObject = {
      role: formData.role,
      permissions: tempPermissions,
    };

    createRole(
      roleObject,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setWantToCallGet,
      wantToCallGet,
      commonToggle,
      setLoading
    ); //API call For Submit
  };
  /* -------------------------------------------------------------------------- */

  const updtaeContactDetails = () => {
    let userDetail = getValues();

    updateRole(
      userDetail,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setWantToCallGet,
      wantToCallGet,
      setLoading,
      commonToggle
    ); //API call For Update
  };

  /* -------------------------------------------------------------------------- */
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  /* -------------------------------------------------------------------------- */

  const handleEmptySubmitForm = () => {
    commonToggle(true);
    setIsBtnDisbl(true);
    let row = {};
    reset(row);
  };
  /* -------------------------------------------------------------------------- */

  /* Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <React.Fragment key={"right"}>
      {loading && <SimpleBackdrop />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: "30%",
              },
            }}
          >
            <div className={classes.Drawerroot}>
              <form onSubmit={handleSubmit(submitContactDetails)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isBtnDisbl ? (
                      <Typography
                        align="left"
                        color="primary"
                        variant="h5"
                        mb={1}
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        New Role
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        color="primary"
                        align="left"
                        mb={1}
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Update Role
                      </Typography>
                    )}
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    {isBtnDisbl ? (
                      <>
                        {" "}
                        <Controller
                          name="role"
                          control={control}
                          rules={{ required: true }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Role"
                              size="small"
                              variant="outlined"
                              className={classes.textField}
                            />
                          )}
                        />
                        <span className={ErrorClasses.errorMsg}>
                          {errors.role && "role is required"}
                        </span>
                      </>
                    ) : (
                      <Controller
                        name="role"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Role"
                            disabled
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isBtnDisbl ? (
                      <Controller
                        name="permissions"
                        id="tags-standard"
                        control={control}
                        rules={{ required: true }}
                        className="input"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            onChange={(event, newValue) => {
                              console.log("newValue", newValue);
                              field.onChange(newValue);
                            }}
                            value={field.value}
                            options={permission.config.map((option) => option.code)}
                            // getOptionLabel={(option) => option.code}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label="Permission"
                              />
                            )}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        name="permissions"
                        id="tags-standard"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            onChange={(event, newValue) => {
                              console.log("newValue", newValue);
                              field.onChange(newValue);
                            }}
                            value={field.value}
                            options={tempPermissionsObject}
                            getOptionLabel={(option) => option.action}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label="Permission"
                              />
                            )}
                          />
                        )}
                      />
                    )}
                    <p className={ErrorClasses.errorMsg}>
                      {errors.permissions?.type === "required" && "permissions is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12} align="right">
                    {isBtnDisbl ? (
                      <Button color="primary" size="small" type="submit" variant="contained">
                        save
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        onClick={updtaeContactDetails}
                        variant="contained"
                      >
                        Update
                      </Button>
                    )}
                    <Button
                      type="button"
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={toggleDrawer("right", false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Drawer>

          {hasValidAccess(USER_AND_ROLE_WRITE) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="small"
                onClick={(e) => handleEmptySubmitForm(e)}
                startIcon={<AddCircleSharpIcon />}
              >
                New Role
              </Button>
            </div>
          )}
          <div style={{ marginTop: "19px" }}>
            {load ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {roles.length === 0 ? ( //if contacts are empty then empty msg is shown
                  <EmptyEstimatesDialog
                    heading="Empty roles"
                    msg="You don't have any roles here yet.
Click the 'NEW CLIENT' button above to get started, or choose from a template"
                  />
                ) : (
                  <MUIDataTable data={roles} columns={columns} options={options} />
                )}
              </React.Fragment>
            )}
          </div>

          <DeleteAlertPop
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMSG={setSnackbarMSG}
            setSnackbarSeverity={setSnackbarSeverity}
            deleteRole={deleteRole}
            setRoles={setRoles}
            roles={roles}
            setLoading={setLoading}
          />
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
