/** @format */

import apis from "../../utils/apis";

// Sample estimate id : 1421cc85-da21-4326-b449-a5f404093cc9
export const getContacts = (setContacts, setLoad) => {
  apis
    .GET({
      endpoint: "/contacts",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.contactNo - b.contactNo;
      });

      setContacts(res.data);
      console.log(res.data);
      setLoad(false);
    });
};

export const createContacts = (
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/contact",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("Contact created successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};
export const createContactsShortcut = (
  obj,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setContacts,
  contacts,
  recipientsEmail,
  setRecipientsEmail
) => {
  setRoundLoader(true);
  let oldData = [...recipientsEmail];
  apis
    .POST({
      endpoint: "/contact",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      console.log("RESULT::", res);
      if (res.status === 200) {
        oldData.map((item, i) => {
          if (typeof item === "string" && item === res.data.emailAddress) oldData[i] = item;
        });
        setRecipientsEmail(oldData);
        setContacts([...contacts, res.data]);
        setSnackbarMSG("Contact created successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      } else if (res.status === 400) {
        setSnackbarMSG("Email Already Exist");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};
export const createContactFromAutocomplete = (
  formData,
  contacts,
  setContacts,
  recipientsEmail,
  setRecipientsEmail,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setContactDrawerState
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/contact",
      payload: formData,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        let tempContacts = contacts;
        tempContacts.push(res.data);
        setContacts(tempContacts);
        setSnackbarMSG("Contact created successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setContactDrawerState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
export const updateContactByID = (
  contactId,
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState
) => {
  setRoundLoader(true);
  // setOpenSnackbar(false);
  apis
    .PUT({
      endpoint: `/contact/${contactId}`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data) {
        setSnackbarMSG("Contact updated successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setState({ ["right"]: false });
      } else {
        setSnackbarMSG("Contact updation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

export const updateContactFromAutocomplete = (
  formData,
  contacts,
  setContacts,
  recipientsEmail,
  setRecipientsEmail,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  callFrom
) => {
  setRoundLoader(true);
  // setOpenSnackbar(false);
  apis
    .PUT({
      endpoint: `/contact/${formData.contactId}`,
      payload: formData,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data) {
        let tempContacts = contacts;
        tempContacts.map((contact, i) => {
          if (contact.contactId === formData.contactId) {
            tempContacts[i] = res.data;
          }
        });
        setContacts(tempContacts);
        if (callFrom === "editQuote") {
          let tempRecipients = recipientsEmail;
          tempRecipients?.map((contact, i) => {
            if (contact.contactId === formData.contactId) {
              tempRecipients[i] = res.data;
            }
          });
          setRecipientsEmail(tempRecipients);
        }

        // if (typeof tempRecipients === "object") {
        //   setRecipientsEmail(res.data);
        // } else {
        //   tempRecipients?.map((contact, i) => {
        //     if (contact.contactId === formData.contactId) {
        //       tempRecipients[i] = res.data;
        //     }
        //   });
        //   setRecipientsEmail(tempRecipients);
        // }

        setSnackbarMSG("Contact updated successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setState({ ["right"]: false });
      } else {
        setSnackbarMSG("Contact updation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};
export const deleteContactByID = (
  contactId,
  contacts,
  setContacts,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/contact/${contactId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        // setDelLoading(false);
        var newData = contacts.filter((contact) => {
          if (contact.contactId != contactId) {
            return contact;
          }
        });
        setContacts(newData);

        setSnackbarMSG("Contact deleted successFully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      } else {
        setContacts(contacts);
        setSnackbarMSG("Contact deletion failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
