/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import {
  generatePdf,
  getSpecificationsByEstimateId,
  getSpecificationsByJobPlanId,
  updateSpecificationsByEstimateId,
  updateSpecificationsByJobPlanId,
  updateSpecificationsGroupByEstimateId,
  updateSpecificationsGroupByJobPlanId,
} from "./specifications.helper";
import Button from "@mui/material/Button";
import ProgressBar from "../../UI/ProgressBar";
import { ButtonGroup, Dialog, DialogActions, DialogContent, Paper } from "@mui/material";
import { Snackbar } from "@mui/material";
import CreateCosting from "../../estimates/EstimateCosting/CreateCosting";
import SpecificationsGroupRow from "./SpecificationsGroupRow";
import Grid from "@mui/material/Grid";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { CSVBoxButton } from "@csvbox/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";
import { Tooltip, Zoom } from "@mui/material";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { DialogTitle } from "@mui/material";
import { Divider } from "@mui/material";
export default function Specifications(props) {
  const { type, editableJobplan, estimateStatus } = props;
  var { jobPlanId, estimateId } = useParams();
  const useRowStyles = makeStyles({
    root: {
      paddingTop: "10px",
    },
  });
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [items, setItems] = useState([]);
  const [loadSave, setLoadSave] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [openAllRows, setOpenAllRows] = useState([]);
  const [createQuote, setCreateQuote] = useState([]);
  const [tempRows, setTempRows] = React.useState([]);
  const [isEdit, setEdit] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [isDataPostSuccess, setDataPostSuccess] = React.useState(false);
  const [openedItemRow, setOpenedItemRow] = React.useState({});
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  const [id, setId] = useState(rows.length);
  const [importedData, setImportedData] = useState([]);
  const [isImported, setIsImported] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [includesPrice, setIncludesPrice] = React.useState(false);
  const [isLinkGenerated, setIsLinkGenerated] = React.useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  const [entityObj, setEntityObj] = React.useState({
    entity: "purchase-order",
  });
  const [pdfLink, setPdfLink] = React.useState("");
  console.log("priceIncluded:", includesPrice);
  var tempOpenRows = [];
  const handleOpenAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = true;
    }
    setOpenAllRows(tempOpenRows);
  };
  const handleCloseAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = false;
    }
    setCreateQuote("");

    setOpenAllRows(tempOpenRows);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSaveOuter = () => {
    console.log("tempRows-------", tempRows);
    console.log("rows-------", rows);
    if (type == "jobPlan") {
      updateSpecificationsByJobPlanId(
        jobPlanId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpen,
        setTempRows
      );
    }

    setEdit(false);
    setDisableSave(true);
    handleCloseAllRows();
  };

  const handleCancelOuter = () => {
    if (type == "jobPlan") {
      getSpecificationsByJobPlanId(setRows, jobPlanId, setLoad, setTempRows);
    }

    setDisableSave(true);
    setOpen(false);
    setEdit(false);
    handleCloseAllRows();
  };

  const handleSave = () => {
    console.log("handleSavehandleSave");
    setOpenCreate(false);
    // if (rows.length === 0) {
    //   var lastBeforeRowSeqId = 0;
    // } else {
    //   var lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
    // }
    // setId(id + 1);

    let arrOfObj = {
      groupCode: groupCode,
      groupName: groupName,
      includesPrice: includesPrice,
      items: items,
    };
    let arr = [...rows, arrOfObj];

    if (type == "jobPlan") {
      updateSpecificationsGroupByJobPlanId(
        setRows,
        jobPlanId,
        arr,
        setOpenSnackbar,
        setTempRows,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader
      );
    } else {
      updateSpecificationsGroupByEstimateId(
        setRows,
        estimateId,
        arr,
        setOpenSnackbar,
        setTempRows,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader
      );
    }
    setIncludesPrice(false);
  };

  useEffect(() => {
    if (type == "jobPlan") {
      getSpecificationsByJobPlanId(setRows, jobPlanId, setLoad, setTempRows, setOpenAllRows);
    } else {
      getSpecificationsByEstimateId(setRows, estimateId, setLoad, setTempRows, setOpenAllRows);
    }
  }, [wantToCallGetJobPlan]);

  // useEffect(() => {
  //   console.log("normal");
  //   setLoad(false);
  //   if (type == "jobPlan") {
  //     getSpecificationsByJobPlanId(setRows, jobPlanId, setLoad, setTempRows, setOpenAllRows);
  //   } else {
  //     getSpecificationsByEstimateId(setRows, estimateId, setLoad, setTempRows, setOpenAllRows);
  //   }
  // }, []);

  const classes = useRowStyles();
  // --------------------------UseEffect-For-Import-Data----------------------------------------------
  useEffect(() => {
    if (importedData.length > 0) {
      var lastItemId;
      var lastBeforeRowSeqId;

      if (rows.length === 0) {
        lastBeforeRowSeqId = 0;
      } else {
        lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
      }

      let newLineItemsWithGrp = importedData
        .filter(function (item) {
          var index = rows.findIndex((g) => g.groupName == item["item group"]);
          if (index >= 0) {
            //return item if it is matching group
            return item;
          } else {
            //If group is not matched then create new group with empty items
            rows.push({
              groupCode: ++lastBeforeRowSeqId,
              groupName: item["item group"],
              items: [],
            });
            return item;
          }
        })
        .map(function (item) {
          //This will add imported item in matching group
          var index = rows.findIndex((g) => g.groupName == item["item group"]);
          if (rows[index].items.length === 0) {
            lastItemId = 0;
          } else {
            lastItemId = rows[index].items[rows[index].items.length - 1].itemCode;
          }
          rows[index].items.push({
            itemId: null,
            itemCode: ++lastItemId,
            itemGroupCode: rows[index].groupCode,
            itemGroupName: item["item group"],
            itemDescription: item["item description"],
          });
        });
      newLineItemsWithGrp = [];
      setIsImported(true);
    }
  }, [importedData]);
  // ------------------------------------------------------------------------
  const handleImportedDataSave = () => {
    setIsImported(false);
    setSnackbarMSG("Imported Data Saved Successfully");
    setSnackbarSeverity("success");
    if (type == "jobPlan") {
      updateSpecificationsByJobPlanId(
        jobPlanId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setTempRows
      );
    } else {
      updateSpecificationsByEstimateId(
        estimateId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setTempRows
      );
    }
  };
  // ------------------------------------------------------------------------
  const handleImportedDataDiscard = () => {
    setRows(JSON.parse(JSON.stringify(tempRows)));
    setIsImported(false);
    setImportedData([]);
  };
  // ------------------------------------------------------------------------
  console.log("pdfLink:", pdfLink);
  return (
    <>
      {isLinkGenerated && (
        <Dialog
          open={isLinkGenerated}
          onClose={() => setIsLinkGenerated(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle align="center" id="alert-dialog-title">
            {"Download File"}
          </DialogTitle>

          <DialogActions>
            <ButtonGroup variant="contained">
              <Button
                variant="contained"
                size="small"
                color="primary"
                href={pdfLink}
                onClick={() => setIsLinkGenerated(false)}
                className={classes.button}
              >
                Download PDF
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => setIsLinkGenerated(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      )}
      {roundLoader && <SimpleBackdrop />}
      {load ? ( //if rows are empty then progress bar is shown
        <ProgressBar />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box my={1}>
              <CreateCosting
                type={type}
                category={groupName}
                setCategory={setGroupName}
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                rows={rows}
                setRows={setRows}
                lineItems={items}
                jobTypeID={jobPlanId}
                handleSave={handleSave}
                load={loadSave}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                disable={disable}
                setDisable={setDisable}
                textFieldMsg="Add Group"
                handleOpenAllRows={handleOpenAllRows}
                handleCloseAllRows={handleCloseAllRows}
                setOpenSnackbar={setOpenSnackbar}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setIncludesPrice={setIncludesPrice}
                costingUseIn={"specifications"}
                editable={editableJobplan}
                estimateStatus={estimateStatus}
              />
              {/* {loadSave && <SimpleBackdrop />} */}
            </Box>
          </Grid>
          <Grid container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <ButtonGroup variant="contained" aria-label="contained primary button group">
                <CSVBoxButton
                  licenseKey="mWnJSBJ484CtLBpi72QCusJcmCTF4v"
                  user={{
                    user_id: "default123",
                  }}
                  onImport={(result, data) => {
                    if (result) {
                      setImportedData(JSON.parse(JSON.stringify(data.rows)));
                      setSnackbarMSG("Data Imported Succesfully");
                      setSnackbarSeverity("success");
                      setOpenSnackbar(true);
                      console.log("In ImportButton");
                    } else {
                      console.log("fail");
                    }
                  }}
                  render={(launch) => {
                    return type === "jobPlan" ? (
                      <Button
                        data-csvbox
                        onClick={launch}
                        variant="contained"
                        size="small"
                        color="primary"
                        align="left"
                        className={classes.button}
                        disabled={!editableJobplan}
                      >
                        Import
                      </Button>
                    ) : (
                      <Button
                        data-csvbox
                        onClick={launch}
                        variant="contained"
                        size="small"
                        color="primary"
                        align="left"
                        className={classes.button}
                        disabled={isImported || estimateStatus !== "DRAFT"}
                      >
                        Import
                      </Button>
                    );
                  }}
                >
                  Import
                </CSVBoxButton>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() =>
                    generatePdf(
                      entityObj,
                      estimateId,
                      setPdfLink,
                      setOpenSnackbar,
                      setSnackbarSeverity,
                      setSnackbarMSG,
                      setIsLinkGenerated,
                      setRoundLoader
                    )
                  }
                  className={classes.button}
                  disabled={editableJobplan ? false : true}
                >
                  Export
                </Button>
                {isImported && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleImportedDataSave}
                      className={classes.button}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleImportedDataDiscard}
                      className={classes.button}
                    >
                      Discard
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box m={1} py={2}>
                <TableContainer>
                  <Table size="small" aria-label="collapsible table">
                    <TableBody>
                      {loadSave ? (
                        <ProgressBar />
                      ) : rows.length < 1 ? (
                        <EmptyEstimatesDialog
                          heading="Add some category with line items"
                          msg="Add some category with line items. You can add using the side Add button present on the right side the screen"
                        />
                      ) : (
                        rows?.map((row, index) => (
                          <SpecificationsGroupRow
                            row={row}
                            setItems={setItems}
                            setRows={setRows}
                            rows={rows}
                            jobPlanId={jobPlanId}
                            estimateId={estimateId}
                            loadSave={loadSave}
                            setLoadSave={setLoadSave}
                            isEdit={isEdit}
                            setEdit={setEdit}
                            setDisableSave={setDisableSave}
                            tempRows={tempRows}
                            setTempRows={setTempRows}
                            setDataPostSuccess={setDataPostSuccess}
                            setOpenedItemRow={setOpenedItemRow}
                            openAllRows={openAllRows}
                            setOpenAllRows={setOpenAllRows}
                            index={index}
                            setOpenSnackbar={setOpenSnackbar}
                            setSnackbarMSG={setSnackbarMSG}
                            setSnackbarSeverity={setSnackbarSeverity}
                            type={type}
                            editableJobplan={editableJobplan}
                            setRoundLoader={setRoundLoader}
                            estimateStatus={estimateStatus}
                          />
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
            </Paper>
          </Grid>
          {!disableSave && (
            <>
              <Button variant="contained" color="primary" align="right" onClick={handleSaveOuter}>
                Save
              </Button>
              <Button variant="contained" color="secondary" align="right" onClick={handleCancelOuter}>
                Cancel
              </Button>
            </>
          )}
        </Grid>
      )}
    </>
  );
}
