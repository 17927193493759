/** @format */

import React, { useEffect } from "react";
import { DatePicker } from "@mui/lab";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function DateCreator({ expiryDate, setExpiryDate, label }) {
  /* Here you can extend the date as you wish from the current date */
  let date = new Date();
  let increasedDate = date.setDate(date.getDate() + 7);
  /* ********************************************************** */

  useEffect(() => {
    if (expiryDate === undefined) {
      setExpiryDate(new Date(increasedDate));
    }
  }, []);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          id="date"
          fullWidth
          size="small"
          label={label}
          inputVariant="outlined"
          onChange={(newvalue) => setExpiryDate(newvalue)}
          disablePast
          value={expiryDate}
          inputFormat="DD/MM/YYYY"
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
    </>
  );
}

export default DateCreator;
