/** @format */

import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";

class DesEstimates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seriesSpark1: [
        {
          data: [31, 40, 28, 51, 42, 109, 100],
        },
      ],
      optionsSpark1: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#217A55"],
        },
        colors: ["#CCF9CE"],
        fill: {
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.6,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [20, 100, 100],
          },
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        title: {
          text: "Properties",
          offsetX: 8,
          offsetY: 8,
          style: {
            fontSize: "22px",
            fontWeight: "600",
            color: "currentColor",
          },
        },
        subtitle: {
          text: `$135,965`,
          offsetX: 8,
          offsetY: 45,
          style: {
            fontSize: "16px",
            fontWeight: "600",
            color: "#b9b9b9",
          },
        },
        tooltip: {
          theme: "dark",
          followCursor: true,
        },
      },

      seriesSpark2: [
        {
          data: [50, 40, 300, 40, 42, 252, 312],
        },
      ],
      optionsSpark2: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#2A51B5"],
        },
        colors: ["#D4F1FF"],
        fill: {
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.6,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [20, 100, 100],
          },
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        title: {
          text: "City",
          offsetX: 8,
          offsetY: 8,
          style: {
            fontSize: "22px",
            fontWeight: "600",
            color: "currentColor",
          },
        },
        subtitle: {
          text: `$135,965`,
          offsetX: 8,
          offsetY: 45,
          style: {
            fontSize: "16px",
            fontWeight: "600",
            color: "#b9b9b9",
          },
        },
        tooltip: {
          theme: "dark",
          followCursor: true,
        },
      },

      seriesSpark3: [
        {
          data: [10, 80, 60, 120, 154, 130, 200, 150],
        },
      ],
      optionsSpark3: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#76500A"],
        },
        colors: ["#FDF7CE"],
        fill: {
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.6,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [20, 100, 100],
          },
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        title: {
          text: "Online Visits",
          offsetX: 8,
          offsetY: 8,
          style: {
            fontSize: "22px",
            fontWeight: "600",

            color: "currentColor",
          },
        },
        subtitle: {
          text: `$135,965`,
          offsetX: 8,
          offsetY: 45,
          style: {
            fontSize: "16px",
            fontWeight: "600",
            color: "#b9b9b9",
          },
        },
        tooltip: {
          theme: "dark",
          followCursor: true,
        },
      },

      seriesSpark4: [
        {
          data: [35, 170, 151, 124, 215, 214, 150, 180],
        },
      ],
      optionsSpark4: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          curve: "smooth",
          colors: ["#B02B39"],
        },
        colors: ["#FDE8DA"],
        fill: {
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.6,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [20, 100, 100],
          },
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        title: {
          text: "Online Queries",
          offsetX: 8,
          offsetY: 8,
          style: {
            fontSize: "22px",
            fontWeight: "600",

            color: "currentColor",
          },
        },
        subtitle: {
          text: `$135,965`,
          offsetX: 8,
          offsetY: 45,
          style: {
            fontSize: "16px",
            fontWeight: "600",
            color: "#b9b9b9",
          },
        },
        tooltip: {
          theme: "dark",
          followCursor: true,
        },
      },
    };
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
          <div className="chart_box">
            <Chart
              options={this.state.optionsSpark1}
              series={this.state.seriesSpark1}
              type="area"
              height={200}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
          <div className="chart_box">
            <Chart
              options={this.state.optionsSpark2}
              series={this.state.seriesSpark2}
              type="area"
              height={200}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
          <div className="chart_box">
            <Chart
              options={this.state.optionsSpark3}
              series={this.state.seriesSpark3}
              type="area"
              height={200}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
          <div className="chart_box">
            <Chart
              options={this.state.optionsSpark4}
              series={this.state.seriesSpark4}
              type="area"
              height={200}
            />
          </div>
        </Grid>
      </>
    );
  }
}

export default DesEstimates;
