/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getClients } from "./clients.helpers";
import ClientForm from "./ClientForm";
import AlertPopClient from "./AlertPopClient";
import { Grid, Snackbar } from "@mui/material";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import { useForm, Controller } from "react-hook-form";
import { Tooltip, Zoom } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { CLIENT_DELETE, CLIENT_WRITE } from "../UI/GlobalVariables";
import { useParams } from "react-router-dom";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";

const useStyles = makeStyles(() => ({
  // Drawerroot: {
  //   width: 600,
  //   padding: 16,
  // },
  // textField: {
  //   width: "100%",
  // },
  // button: { padding: "7px", paddingRight: "9px", paddingLeft: "9px" },
}));

export default function Client() {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const { clientId } = useParams();
  const classes = useStyles();
  const [deleteId, setDeleteId] = useState([]);
  const [isSaveClicked, setisSaveClicked] = useState(true);
  const [showPostelAddress, setShowPostelAddress] = useState(true);
  const [load, setLoad] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const formatPhoneNumber = (phoneNumber) => {
    return `+61 ${phoneNumber}`;
  };
  const formatMobileNumber = (mobileNumber) => {
    return `+61 ${mobileNumber}`;
  };
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  /* DataTable */
  const columns = [
    {
      name: "clientNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        setCellProps: () => ({
          style: {
            wordBreak: "break-all",
            overflowWrap: "break-word",
          },
        }),
        filter: true,
        sort: true,
      },
    },
    {
      name: "clientType",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "emailAddress",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return <div>{formatPhoneNumber(value)}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            const formattedMobileNumber = formatMobileNumber(value);
            return <div>{formattedMobileNumber}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cityOrTown",
      label: "City",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "clientId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT CLIENT" placement="top" TransitionComponent={Zoom} arrow>
                <Button size="small" color="primary" onClick={() => handleUpdate(tableMeta.rowIndex)}>
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="DELETE CLIENT" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={value}
                  onClick={(e) => handleDelete(value)}
                >
                  <DeleteIcon value={value} fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(CLIENT_WRITE) ? true : false,
      },
    },
  ];

  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  //   setSelectableRows("single");
  // }
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    // selectableRows,
    // selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsHideCheckboxes: true,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    onTableChange: (action, state) => {},
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    // },
  };
  useEffect(() => {
    getClients(setData, setLoad, clientId);
    if (hasValidAccess(CLIENT_DELETE)) {
      setSelectableRows("single");
    }
    console.log("get client calling");
  }, [wantToCallGet]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleOpenDrawer = () => {
    setState({ ...state, ["right"]: true });
    setisSaveClicked(true);
    setShowPostelAddress(true);
    reset({});
  };
  const handleDelete = (clientId) => {
    if (hasValidAccess(CLIENT_DELETE)) {
      setDeleteId(clientId);
      setConfirmDelete(true);
    }
  };
  const handleUpdate = (arrayIndex) => {
    setisSaveClicked(false);
    setShowPostelAddress(true);
    setState({ ...state, ["right"]: true });
    reset(data[arrayIndex]);
  };

  return (
    <div>
      {" "}
      {roundLoader && <SimpleBackdrop />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {delLoading && <SimpleBackdrop />}
          {hasValidAccess(CLIENT_WRITE) && (
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<AddCircleSharpIcon />}
                  onClick={handleOpenDrawer}
                >
                  New Client
                </Button>
              </div>
              <ClientForm
                toggleDrawer={toggleDrawer}
                state={state}
                setState={setState}
                isSaveClicked={isSaveClicked}
                setisSaveClicked={setisSaveClicked}
                handleSubmit={handleSubmit}
                Controller={Controller}
                control={control}
                getValues={getValues}
                errors={errors}
                wantToCallGet={wantToCallGet}
                setWantToCallGet={setWantToCallGet}
                setOpenCustSnackbar={setOpenCustSnackbar}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setData={setData}
                clientData={data}
                showPostelAddress={showPostelAddress}
                setShowPostelAddress={setShowPostelAddress}
              />
            </>
          )}
          <div style={{ marginTop: "15px" }}>
            {load ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {data.length === 0 ? ( //if estimates are empty then empty msg is shown
                  <EmptyEstimatesDialog
                    heading="Empty Clients"
                    msg="You don't have any clients here yet.
  Click the 'NEW CLIENT' button above to create new client"
                  />
                ) : (
                  <MUIDataTable data={data} columns={columns} options={options} />
                )}
                <AlertPopClient
                  confirmDelete={confirmDelete}
                  setConfirmDelete={setConfirmDelete}
                  deleteId={deleteId}
                  setData={setData}
                  data={data}
                  setOpenCustSnackbar={setOpenCustSnackbar}
                  setSnackbarMSG={setSnackbarMSG}
                  setSnackbarSeverity={setSnackbarSeverity}
                  setRoundLoader={setRoundLoader}
                />
                {openCustSnackbar && (
                  <CustomizedSnackbars
                    openSnackbar={openCustSnackbar}
                    setOpenSnackbar={setOpenCustSnackbar}
                    snackbarMSG={snackbarMSG}
                    severity={snackbarSeverity}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <CommonInfo title={infoArray.clients.title} msg={infoArray.clients.msg} />
        </Grid>
      </Grid>
    </div>
  );
}
