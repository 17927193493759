/** @format */

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Drawer,
  Grid,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  TableContainer,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { checkProperties } from "../../UI/GlobalApi";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { getContacts } from "../../contacts/contacts.helpers";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import AddIcon from "@mui/icons-material/Add";
import PercentIcon from "@mui/icons-material/Percent";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import {
  createCatalogueFromJobplan,
  updateCatalogueItemsByCatalogueId,
} from "../../catalogue/catalogue.helper";
import { getGst } from "../../UI/GlobalApi";

const useStyles = makeStyles({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
});

export default function CreateCatalogueFromJobplanItem({
  checkedJobPlanLineItems,
  userConfigs,
  userConfigsuom,
  allCatalogue,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState(["Create", "Add Items"]);
  const [catalogueName, setCatalogueName] = React.useState("");
  const [catalogueDescription, setCatalogueDescription] = React.useState("");
  const [contactObj, setContactObj] = React.useState({ contactName: "" });
  const [contacts, setContacts] = React.useState([]);
  const [dummyLoad, setDummyLoad] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [newCatalogueId, setNewCatalogueId] = React.useState("");
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [isCatalogueCreated, setIsCatalogueCreated] = React.useState(false);
  const [groupName, setGroupName] = React.useState("");
  const [groupCode, setGroupCode] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [inputList, setInputList] = React.useState([]);
  const [groupItems, setGroupItems] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [userConfigsTypes, setUserConfigsTypes] = React.useState({});
  const [userConfigsUom, setUserConfigsUom] = React.useState({});
  const [content, setContent] = React.useState("existing");
  const [catalogueObj, setCatalogueObj] = React.useState({});
  const [clickOnLineItemSave, setClickOnLineItemSave] = React.useState(false);

  /* ---------------------------Functions-For-Search-Catalogue-Drawer------------------------------------- */
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleOpenCreateCatalogueDrawer = () => {
    setState({ ...state, ["right"]: true });
  };

  const handleCloseCreateCatalogueDrawer = () => {
    setState({ ...state, ["right"]: false });
    setActiveStep(0);
  };

  const handleAddItems = () => {
    setInputList(JSON.parse(JSON.stringify(inputList)));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleAddItemsExisting = () => {
    let tempArray = checkedJobPlanLineItems.map((item) => {
      return { ...item, itemGroupName: groupName, itemCategory: category };
    });
    setInputList(tempArray);
    setGroupItems(tempArray);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /* ---------------------------------------------------------------- */
  const getStepContent = (step) => {
    if (step === 0) {
      return (
        <>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Link
                onClick={() => {
                  setContent("existing");
                }}
                path="/$"
                component={`existing`}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  color="primary"
                  variant="h4"
                  ml={2}
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Select Existing
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={6}>
              <Link
                onClick={() => {
                  setContent("new");
                }}
                path="/$"
                component={`new`}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  color="primary"
                  variant="h4"
                  textAlign="right"
                  mb={2}
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Create New
                </Typography>
              </Link>
            </Grid>
          </Grid>
          {content === "existing" ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  options={allCatalogue}
                  disableClearable
                  size="small"
                  min-width="120"
                  id="itemDescription"
                  onChange={(event, newValue) => {
                    setCatalogueObj(newValue);
                  }}
                  getOptionLabel={(option) => option.catalogueName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="itemDescription"
                      placeholder="Select"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="groupName"
                  name="groupName"
                  variant="outlined"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                  label="Item's Group"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="category"
                  name="category"
                  variant="outlined"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="Item's Category"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} justifyContent="space-between" align="right" my={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAddItemsExisting}
                >
                  Add Items
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: "15px" }}
                  onClick={handleCloseCreateCatalogueDrawer}
                >
                  Close
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="catalogueName"
                  name="catalogueName"
                  variant="outlined"
                  value={catalogueName}
                  onChange={(e) => {
                    setCatalogueName(e.target.value);
                  }}
                  label="Catalogue Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="catalogueDescription"
                  name="catalogueDescription"
                  variant="outlined"
                  value={catalogueDescription}
                  onChange={(e) => {
                    setCatalogueDescription(e.target.value);
                  }}
                  label="Catalogue Description"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  options={contacts}
                  value={contactObj}
                  onChange={(event, newValue) => {
                    setContactObj(newValue);
                  }}
                  getOptionLabel={(option) => option.contactName}
                  renderInput={(params) => (
                    <TextField size="small" {...params} variant="outlined" label="Supplier" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="groupName"
                  name="groupName"
                  variant="outlined"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                  label="Item's Group"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  required
                  id="category"
                  name="category"
                  variant="outlined"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  label="Item's Category"
                  fullWidth
                />
              </Grid>
              {/* <Grid container xs={9}>
            {openSnackbar && (
              <CustomizedSnackbars
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                snackbarMSG={snackbarMSG}
                severity={snackbarSeverity}
              />
            )}
          </Grid> */}

              <Grid item xs={12} justifyContent="space-evenly" align="right" my={2}>
                {isCatalogueCreated ? (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleCloseCreateCatalogueDrawer}
                    >
                      Close
                    </Button>
                    <Button size="small" variant="contained" color="primary" onClick={handleAddItems}>
                      Add Items
                    </Button>
                  </>
                ) : (
                  <>
                    <Button size="small" variant="contained" color="primary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleCloseCreateCatalogueDrawer}
                      style={{ marginLeft: "15px" }}
                    >
                      Close
                    </Button>
                  </>
                )}
              </Grid>
            </>
          )}
        </>
      );
    } else if (step === 1) {
      return (
        <>

          <Grid item xs={12}>
            {inputList.length !== 0 ? (
              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
                align="right"
                style={{ display: "block", marginBottom: "10px" }}
              >
                {isEdit ? (
                  <ButtonGroup
                    variant="contained"
                    aria-label="contained primary button group"
                    align="right"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                      <AddIcon fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      align="right"
                      onClick={handleCancel}
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlesLineItemSave}
                      size="small"
                    >
                      <SaveIcon fontSize="small" />
                    </Button>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup
                    variant="contained"
                    aria-label="contained primary button group"
                    align="right"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                      <AddIcon fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      align="right"
                      onClick={handleEdit}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                  </ButtonGroup>
                )}
              </ButtonGroup>
            ) : (
              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
                align="right"
                style={{ display: "block", marginBottom: "10px" }}
              >
                <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              overflow: "auto",
            }}
          >
            <Table size="small" aria-label="a dense table">
              <TableBody style={{ boxShadow: "none" }}>
                <Box margin={0.5}>
                  {inputList.length === 0 ? (
                    <EmptyEstimatesDialog
                      heading="Add some Catalogue lineitems in this category"
                      msg="Add some lineitems here to Save this Catalogue type. You can add using the side button present on the right side the screen"
                    />
                  ) : (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650, borderBottom: "none" }}
                        size="small"
                        aria-label="a dense table"
                        id="estimationCosting"
                        className={" estimationCosting"}
                      >
                        <TableHead>
                          <TableRow>
                            {!isEdit && <TableCell width="20px"></TableCell>}
                            <TableCell sx={{ minWidth: "20px" }}>Code</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                            <TableCell sx={{ minWidth: 300 }}>Description</TableCell>
                            <TableCell align="center" sx={{ minWidth: 180 }}>
                              Type
                            </TableCell>
                            <TableCell align="right" sx={{ minWidth: 95 }}>
                              QTY
                            </TableCell>
                            <TableCell align="center" sx={{ minWidth: 115 }}>
                              UOM
                            </TableCell>
                            <TableCell align="right" sx={{ minWidth: 95 }}>
                              Unit Cost
                            </TableCell>
                            <TableCell align="right">Markup</TableCell>
                            <TableCell align="center">Variance</TableCell>
                            <TableCell align="center">GST</TableCell>
                            {isEdit && <TableCell align="center">Actions</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inputList.map((row, i) => {
                            return (
                              <React.Fragment>
                                {isEdit ? (
                                  <React.Fragment>
                                    <TableRow key={row.itemId}>
                                      <TableCell>{row.itemCode}</TableCell>
                                      <TableCell>
                                        <TextField
                                          variant="outlined"
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          value={row.itemName}
                                          name="itemName"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <TextField
                                          variant="outlined"
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          value={row.itemDescription}
                                          name="itemDescription"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </TableCell>

                                      <TableCell align="center">
                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="type"
                                          value={row.type}
                                          onChange={(e) => handleInputChange(e, i)}
                                        >
                                          {userConfigsTypes.config?.length != 0 &&
                                            userConfigsTypes.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                          },
                                        }}
                                      >
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          width="20px"
                                          size="small"
                                          type="number"
                                          value={row.qty}
                                          name="qty"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="uom"
                                          value={row.uom}
                                          onChange={(e) => handleInputChange(e, i)}
                                        >
                                          {userConfigsUom.config?.length != 0 &&
                                            userConfigsUom.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                          },
                                        }}
                                      >
                                        <TextField
                                          variant="outlined"
                                          size="small"
                                          className={classes.textField}
                                          type="number"
                                          value={row.unitCost}
                                          name="unitCost"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                            minWidth: 50,
                                          },
                                        }}
                                      >
                                        <TextField
                                          variant="outlined"
                                          size="small"
                                          className={classes.textField}
                                          type="number"
                                          value={row.markupPercentage}
                                          name="markupPercentage"
                                          onChange={(e) => handleInputChange(e, i)}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <PercentIcon
                                                  style={{
                                                    fontSize: "15px",
                                                    margin: "-10px",
                                                    color: "3A3B3C",
                                                    border: "redius",
                                                  }}
                                                />
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                          },
                                        }}
                                      >
                                        <TextField
                                          variant="outlined"
                                          size="small"
                                          className={classes.textField}
                                          type="number"
                                          value={row.variancePercentage}
                                          name="variancePercentage"
                                          onChange={(e) => handleInputChange(e, i)}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.gst === true ? (
                                          <Checkbox
                                            size="small"
                                            name="gst"
                                            defaultChecked
                                            onChange={(e) => handleChangeGST(e, i)}
                                          />
                                        ) : (
                                          <Checkbox
                                            size="small"
                                            name="gst"
                                            defaultChecked={false}
                                            onChange={(e) => handleChangeGST(e, i)}
                                          />
                                        )}
                                        {/* <Checkbox
                                        size="small"
                                        name="gst"
                                        defaultChecked
                                        onChange={(e) => handleChangeGST(e, i)}
                                      /> */}
                                      </TableCell>

                                      {isEdit && (
                                        <>
                                          {inputList.length > 1 && (
                                            <TableCell align="center">
                                              <ButtonGroup variant="contained" size="small">
                                                <Button
                                                  size="small"
                                                  className="mr10"
                                                  color="secondary"
                                                  key={row.itemId}
                                                  onClick={(e) => clearLineItem(row)}
                                                >
                                                  <ClearIcon fontSize="small" />
                                                </Button>
                                              </ButtonGroup>
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                    </TableRow>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <TableRow key={row.itemId}>
                                      <TableCell>
                                        <></>
                                      </TableCell>
                                      <TableCell align="center">{row.itemCode}</TableCell>
                                      <TableCell>{row.itemName}</TableCell>
                                      <TableCell>{row.itemDescription} </TableCell>
                                      <TableCell align="center">{row.type}</TableCell>
                                      <TableCell align="right">{row.qty}</TableCell>
                                      <TableCell align="center">{row.uom}</TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          value={(row.unitCost * 1).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        {" "}
                                        <NumberFormat
                                          value={(row.markupPercentage * 1).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          suffix="%"
                                        />{" "}
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          value={(row.variancePercentage * 1).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <NumberFormat
                                          value={(getGst(row.gst) * 1).toFixed(1)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          suffix="%"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </TableBody>
            </Table>
          </Grid>
          {showSaveButton && (
            <Grid item xs={12} alignContent={"flex-end"} align="right" my={2}>
              <Button size="small" variant="contained" color="primary" onClick={handleFinalSave}>
                Save Items
              </Button>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                style={{ marginLeft: "10px" }}
                onClick={handleCloseCreateCatalogueDrawer}
              >
                Close
              </Button>
            </Grid>
          )}
        </>
      );
    }
  };
  // -----------------------------------------------------------------------------------------
  const handleAdd = () => {
    var lastBeforeRowLineId;
    setIsEdit(true);
    if (inputList.length === 0) {
      lastBeforeRowLineId = 0;
    } else {
      lastBeforeRowLineId = inputList[inputList.length - 1].itemCode;
    }
    var newElement = {
      itemId: null,
      itemCode: ++lastBeforeRowLineId,
      itemName: "",
      itemGroupCode: groupCode,
      itemGroupName: groupName,
      itemDescription: "",
      itemCategory: category,
      type: "",
      qty: 1,
      uom: "",
      unitCost: 0,
      markupPercentage: 0,
      variancePercentage: 0,
      gst: true,
    };
    console.log("id", newElement);
    setInputList([...inputList, newElement]);
    setShowSaveButton(false);
  };
  // -----------------------------------------------------------------------------------------

  const handleCancel = () => {
    setInputList(groupItems); /* Reset our old data after cancel edit/add operation  */
    setGroupItems(groupItems);
    setIsEdit(false);
    setShowSaveButton(true);
  };

  // -----------------------------------------------------------------------------------------
  function updateValuesInRows(finalGroupsItems) {
    setFinalData([{ groupCode: groupCode, groupName: groupName, items: finalGroupsItems }]);
  }

  const handlesLineItemSave = () => {
    var finalGroupsItems = inputList.filter((el) => {
      if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
        return el;
      }
    });
    let valid = true;
    inputList.map((el) => {
      if (
        el.unitCost == "" ||
        el.type == "" ||
        el.qty == "" ||
        el.itemDescription == "" ||
        el.markupPercentage == "" ||
        el.variance == "" ||
        el.itemName == ""
      ) {
        valid = false;
      }
    });

    if (valid == true) {
      setGroupItems(finalGroupsItems);
      updateValuesInRows(finalGroupsItems);
      setIsEdit(false);
      setShowSaveButton(true);
      setClickOnLineItemSave(true);
    } else {
      setSnackbarMSG("Line items fileds can not be empty!");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  //--------------------------------------------------------------------------------------
  const handleFinalSave = () => {
    setSnackbarMSG("Items saved successfully!");
    setSnackbarSeverity("success");

    if (clickOnLineItemSave) {
      if (content === "existing") {
        updateCatalogueItemsByCatalogueId(
          catalogueObj.catalogueId,
          catalogueObj.contact.contactId,
          setFinalData,
          finalData,
          setOpenSnackbar
        );
      } else if (content === "new") {
        updateCatalogueItemsByCatalogueId(
          newCatalogueId,
          contactObj.contactId,
          setFinalData,
          finalData,
          setOpenSnackbar
        );
      }
    } else {
      var finalGroupsItems = inputList.filter((el) => {
        if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
          return el;
        }
      });
      let data = [{ groupCode: groupCode, groupName: groupName, items: finalGroupsItems }];

      if (content === "existing") {
        updateCatalogueItemsByCatalogueId(
          catalogueObj.catalogueId,
          catalogueObj.contact.contactId,
          setFinalData,
          data,
          setOpenSnackbar
        );
      } else if (content === "new") {
        updateCatalogueItemsByCatalogueId(
          newCatalogueId,
          contactObj.contactId,
          setFinalData,
          data,
          setOpenSnackbar
        );
      }
    }
  };
  //   --------------------------------------------------------------------------------------

  const handleEdit = () => {
    setIsEdit(true);
    setShowSaveButton(false);
  };
  // -----------------------------------------------------------------------------------------

  const handleSave = () => {
    let obj = {
      contactId: contactObj.contactId,
      catalogueName: catalogueName,
      catalogueDescription: catalogueDescription,
    };
    createCatalogueFromJobplan(
      obj,
      setSnackbarSeverity,
      setSnackbarMSG,
      setOpenSnackbar,
      setIsCatalogueCreated,
      setNewCatalogueId
    );

    let tempArray = checkedJobPlanLineItems.map((item) => {
      return { ...item, itemGroupName: groupName, itemCategory: category };
    });
    setInputList(tempArray);
    setGroupItems(tempArray);
  };
  console.log("inpulist:", inputList);
  // -----------------------------------------------------------------------------------------
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    var list = JSON.parse(JSON.stringify(inputList));
    list[index][name] = value;
    if (
      name === "unitCost" ||
      name === "qty" ||
      name === "markupPercentage" ||
      name === "variancePercentage"
    ) {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    setInputList(list);


  };

  //   --------------------------------------------------------------------------------------
  const handleChangeGST = (e, i) => {
    console.log("e.target.checked:", e.target.checked);
    console.log("e.target.name:", e.target.name);
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(inputList));
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };

  /* ---------------------------------------------------------------- */
  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setInputList(deletedData);
  };

  function filterInputListForClearAndDeleteLineItem(deleteId) {
    let filterList = inputList.filter((el) => {
      return deleteId.itemCode != el.itemCode;
    });

    let count = 1;
    var test = filterList.filter(function (el) {
      el.itemCode = count++;
      return el;
    });
    return test;
  }

  /* ---------------------------------------------------------------- */
  useEffect(() => {
    getContacts(setContacts, setDummyLoad);
    setGroupCode(Math.floor(1000 + Math.random() * 9000));
    getUserConfigsTypes(setUserConfigsTypes, setDummyLoad);
    getUserConfigsUom(setUserConfigsUom, setDummyLoad);
  }, []);
  // -----------------------------------------------------------------------------------------

  return (
    <>
      <div style={{ color: "gray", position: "fixed", bottom: 10 }}>
        <Button
          name="createCatalogue"
          variant="contained"
          color="secondary"
          align="right"
          onClick={handleOpenCreateCatalogueDrawer}
        >
          Add to Catalogue ({checkedJobPlanLineItems.length} items)
        </Button>
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("left", false)}
              PaperProps={{
                sx: {
                  width: "80%",
                },
              }}
            >
              <div className={classes.Drawerroot}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      component="h5"
                      color="primary"
                      mb={1}
                      style={{
                        fontSize: "20px",

                      }}
                    >
                      Add To Catalogue :
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Stepper activeStep={activeStep}  >
                      {steps.map((label, index) => {
                        return (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Grid>
                  {getStepContent(activeStep)}
                  <Grid container xs={9}>
                    {openSnackbar && (
                      <CustomizedSnackbars
                        openSnackbar={openSnackbar}
                        setOpenSnackbar={setOpenSnackbar}
                        snackbarMSG={snackbarMSG}
                        severity={snackbarSeverity}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
