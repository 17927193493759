/** @format */
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {
  deleteSelectedQuoteLineItemForEstimate,
  getQuoteResponseByQuoteId,
  getRespoDataFromAPI,
  reminderForQuote,
  selectQuoteForEstimate,
  selectQuoteLineItemForEstimate,
} from "../helpers.quoteRequestAPI";
import ProgressBar from "../../UI/ProgressBar";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import MomentDate from "../../UI/MomentDate";
import { QUOTE_WRITE } from "../../UI/GlobalVariables";
import { Chip, Tooltip, Zoom } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NumberFormat from "react-number-format";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export function QuoteResponseCompo({
  quoteId,
  getResByQuoteIdAndContactID,
  getQuoteResponseByQuoteId,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  refreshData,
  setData,
  isCameFromNavigation,
  estimateStatus,
}) {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [wantToCallUseEffect, setWantToCallUseEffect] = useState(true);
  useEffect(() => {
    getQuoteResponseByQuoteId(quoteId, setPosts, setLoading, setData);
  }, [wantToCallUseEffect, refreshData]);
  console.log("posts", posts);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ backgroundColor: "rgb(106 106 106 / 5%)" }} p={0} colSpan={12}>
          <Box p={1}>
            {loading ? (
              <>
                <Typography variant="h6" gutterBottom component="div">
                  Response
                </Typography>
                <ProgressBar />
              </>
            ) : (
              <>
                {posts.length === 0 ? (
                  <>
                    <EmptyEstimatesDialog
                      heading="Empty Response"
                      msg="You don't have any Response here yet.
                    Please create "
                    />
                  </>
                ) : (
                  <>
                    <Typography variant="h6" gutterBottom component="div">
                      Response
                    </Typography>

                    <Table
                      sx={{
                        borderBottom: "none",
                      }}
                      size="small"
                      aria-label="purchases"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: "200px", maxWidth: "200px" }}>
                            <strong>Contact name</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: "210px", maxWidth: "210px" }}>
                            <strong>Email</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: "130px", maxWidth: "130px" }}>
                            <strong>Expiry Date</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: "150px", maxWidth: "150px" }}>
                            <strong>Send Date</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Status</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: "130px", maxWidth: "130px" }}>
                            <strong>Response Date</strong>
                          </TableCell>

                          <TableCell align="right" sx={{ minWidth: "130px", maxWidth: "130px" }}>
                            <strong>Total Amount</strong>
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: "210px", maxWidth: "210px" }}>
                            <strong> Actions</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {posts.map((quoteResponseItem) => (
                          <>
                            <TableRow>
                              <TableCell>{quoteResponseItem.contactName}</TableCell>
                              <TableCell>{quoteResponseItem.email}</TableCell>
                              <TableCell>
                                <MomentDate getDate={quoteResponseItem.expiryDate} />
                              </TableCell>
                              <TableCell>
                                <MomentDate getDate={quoteResponseItem.sentDate} />
                              </TableCell>
                              <TableCell>
                                {quoteResponseItem.status === "OPENED" && (
                                  <strong>
                                    <Chip size="small" label="Opened" color="info" />
                                  </strong>
                                )}
                                {quoteResponseItem.status === "NOTIFIED" && (
                                  <strong>
                                    <Chip size="small" label="Notified" color="info" />
                                  </strong>
                                )}
                                {quoteResponseItem.status === "DRAFT" && (
                                  <Chip size="small" label="Draft" />
                                )}
                                {/* {quoteResponseItem.status === "REJECTED" && (
                                  <Chip size="small" label="Rejected" color="warning" />
                                )}
                                {quoteResponseItem.status === "ACCEPTED" && (
                                  <Chip size="small" label="Accepted" color="success" />
                                )} */}
                                {quoteResponseItem.status === "QUOTED" && (
                                  <Chip size="small" label="Quoted" />
                                )}
                                {quoteResponseItem.status === "SELECTED" && (
                                  <Chip
                                    size="small"
                                    label="Selected"
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "#2e7d32",
                                    }}
                                  />
                                )}

                                {quoteResponseItem.status === "PARTIALLY_SELECTED" && (
                                  <Chip size="small" label="Part-Selected" color="warning" />
                                )}
                              </TableCell>
                              <TableCell>
                                {quoteResponseItem.responseDate ? (
                                  <MomentDate getDate={quoteResponseItem.responseDate} />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(quoteResponseItem.totalAmount * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <ButtonGroup
                                  size="small"
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                >
                                  {/* -------------------View Quote Response Line item------------------- */}

                                  <Button
                                    color="primary"
                                    onClick={getResByQuoteIdAndContactID(quoteResponseItem)}
                                  >
                                    <Tooltip
                                      title="VIEW"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <VisibilityIcon />
                                    </Tooltip>
                                  </Button>
                                  {/* -------------------Reminder Button------------------- */}
                                  <Button
                                    color="primary"
                                    disabled={
                                      quoteResponseItem.status === "Notified" ||
                                      !quoteResponseItem.responseDate
                                        ? false
                                        : true
                                    }
                                    size="small"
                                    onClick={() =>
                                      reminderForQuote(
                                        quoteResponseItem,
                                        setOpenCustSnackbar,
                                        setSnackbarMSG,
                                        setSnackbarSeverity
                                      )
                                    }
                                  >
                                    <Tooltip
                                      title="REMEINDER"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <AccessAlarmIcon />
                                    </Tooltip>
                                  </Button>

                                  {/* -------------------Select Quote For Estimate ------------------- */}

                                  <Tooltip
                                    title="Estimate"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        selectQuoteForEstimate(
                                          quoteResponseItem,
                                          setOpenCustSnackbar,
                                          setSnackbarMSG,
                                          setSnackbarSeverity,
                                          setWantToCallUseEffect
                                        )
                                      }
                                      disabled={
                                        quoteResponseItem.status === "SELECTED" ||
                                        !quoteResponseItem.responseDate ||
                                        estimateStatus !== "DRAFT"
                                          ? true
                                          : false
                                      }
                                    >
                                      <CheckCircleIcon />
                                    </Button>
                                  </Tooltip>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                    <br />
                  </>
                )}
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
