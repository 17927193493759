/** @format */

import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function DateCreatorForForm({ control, Controller, expiryDate, setExpiryDate, setValue, dateKey }) {
  /* Here you can extend the date as you wish from the current date */
  // let date = new Date();
  // let increasedDate = date.setDate(date.getDate() + 7);
  /* ********************************************************** */
  /* 
  useEffect(() => {
    console.log("expiryData", expiryDate);
    if (expiryDate === undefined) {
      console.log("expiryData", expiryDate);
      let date = new Date(increasedDate).toISOString().slice(0, 10);
      setValue(`${dateKey}`, date);
    }
  }, []);
  function handleInput(newValue) {
    let date = new Date(newValue).toISOString().slice(0, 10);
    setValue(`${dateKey}`, date);
  } */
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        id="date"
        fullWidth
        size="small"
        label="Due Date"
        inputVariant="outlined"
        onChange={(newvalue) => setExpiryDate(newvalue)}
        disablePast
        value={expiryDate}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => <TextField size="small" {...params} />}
      />
    </LocalizationProvider>
  );
}

export default DateCreatorForForm;
