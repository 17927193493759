/** @format */

import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography } from "@mui/material";
import ChildSettingCommon from "../SettingChildDrawer/ChildSettingCommon";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    padding: theme.spacing(0, 3, 0, 1),
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  childSubDrawerPaper: {
    marginLeft: drawerWidth,
    marginTop: "35px",
    width: drawerWidth,
  },
}));

export default function NestedDrawer({ subDrawerOpen, setSubDrawerOpen, setChildSettingOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedSetting, setselectedSetting] = React.useState(0);
  const toggleDrawer = (anchor, open, i) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setselectedSetting(i + 1);
    open ? setChildSettingOpen(true) : setChildSettingOpen(false);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={subDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ height: "50px" }}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={() => {
                setSubDrawerOpen(false);
                setChildSettingOpen(false);
              }}
            >
              {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography variant="h6" style={{ color: "#5a8c24" }}>
              Settings Menu
            </Typography>
          </div>
        </div>
        <Divider />
        <List>
          {[
            "Configuration",
            // "Quotes Setting",
            // "Job Setting",
            // "Client Setting",
            // "Contact Setting",
            // "Building Types",
          ].map((text, index) => (
            <React.Fragment key={"left"}>
              <ListItem button key={text} onClick={toggleDrawer("left", true, index)}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.childSubDrawerPaper,
        }}
        style={{ zIndex: "20" }}
      >
        <div style={{ marginTop: "25px" }}>
          <CloseIcon
            style={{
              marginLeft: "195px",
              color: "#5A8C24",
              backgroundColor: "#f4fbf0",
              border: "1px solid #5A8C24",
              borderRadius: "100%",
              padding: "4px",
            }}
            onClick={toggleDrawer("left", false, selectedSetting)}
            fontSize="large"
          />
          <ChildSettingCommon index={selectedSetting} toggleDrawer={toggleDrawer} />
        </div>
      </Drawer>
    </div>
  );
}
