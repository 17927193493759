/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, InputAdornment } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Title from "../../UI/Title";
import { getJobByJobId } from "../jobs.helper";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ErrorStyles from "../../UI/ErrorStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import ListItemText from "@mui/material/ListItemText";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useForm, Controller } from "react-hook-form";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import Autocomplete from "@mui/material/Autocomplete";
import MomentDate from "../../UI/MomentDate";
import ProgressBar from "../../UI/ProgressBar";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link, useHistory, useParams } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { updateClientbyId, getClientsForAutocomplete } from "../../clients/clients.helpers";
import { updateJobDetails } from "../jobs.helper";
import { Row } from "rsuite";

const useStyles = makeStyles((theme) => ({
  cardtitle: {
    padding: theme.spacing(2),
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));

export default function JobDetails({ jobId, setJobStatus }) {
  const history = useHistory();
  const classes = useStyles();
  const { clientId } = useParams();
  const ErrorClasses = ErrorStyles();
  const [draft, setDraft] = useState([]);
  const [load, setLoad] = useState(false);
  const [row, setRow] = React.useState([]);
  const [isEdit, setEdit] = useState(false);
  const [clientData, setData] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [showPostelAddress, setShowPostelAddress] = useState(true);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [roundLoader, setRoundLoader] = useState(false);
  const [status, setStatus] = useState(["DRAFT", "PENDING", "IN_PROGRESS", "COMPLETE", "CANCELLED"]);

  /* ---------------------------------------------------------------- */
  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleClose = (e) => {
    setState({ ...state, ["right"]: false });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    right: false,
  });

  /* ---------------------------------------------------------------- */
  useEffect(() => {
    // let estimateID = history.location.estimateID;
    getJobByJobId(setRow, jobId, setLoad, setJobStatus); //get job by id
    getClientsForAutocomplete(setData);
  }, []);

  useEffect(() => {
    row && setSelectedClients(row.client);
  }, [row]);

  /* ---------------------------------------------------------------- */
  const handleSave = () => {
    let obj = {
      description: row?.description,
      clientId: selectedClients?.clientId,
      expectedStartDate: row?.expectedStartDate,
      status: row?.status,
    };
    console.log("FINAL::", obj);
    updateJobDetails(
      jobId,
      obj,
      setRoundLoader,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setJobStatus
    );
    setEdit(false);
  };
  /* ---------------------------------------------------------------- */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRow({ ...row, [name]: value });
  };
  /* ---------------------------------------------------------------- */
  const handleEdit = () => {
    setDraft(row);
    setEdit(true);
  };
  /* ---------------------------------------------------------------- */
  const handleCancel = () => {
    setRow(row);
    setEdit(false);
  };
  /* ---------------------------------------------------------------- */
  const handleUpdateSubmit = (e) => {
    let clientDetails = getValues();
    let clientId = clientDetails.clientId;
    console.log(clientDetails, "clientDetails12");
    if (showPostelAddress) {
      clientDetails.Postalmobile = clientDetails?.mobile;
      clientDetails.postalAddress = clientDetails?.address;
      clientDetails.postalCityOrTown = clientDetails?.cityOrTown;
      clientDetails.postalState = clientDetails?.state;
      clientDetails.postalPostcode = clientDetails?.postcode;
      clientDetails.postalPhone = clientDetails?.phone;
    }
    /*deleting clientId of this object  */
    delete clientDetails["clientId"];

    updateClientbyId(
      clientId,
      clientDetails,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setState,
      setSelectedClients,
      setData,
      clientData
    ); //API call For Update
    setShowPostelAddress(true);
  };
  const handleSamePostalAddress = () => {
    let ClientDetails = getValues();
    ClientDetails.Postalmobile = ClientDetails?.mobile;
    ClientDetails.postalAddress = ClientDetails?.address;
    ClientDetails.postalCityOrTown = ClientDetails?.cityOrTown;
    ClientDetails.postalState = ClientDetails?.state;
    ClientDetails.postalPostcode = ClientDetails?.postcode;
    ClientDetails.postalPhone = ClientDetails?.phone;
    setSelectedClients(ClientDetails);

    console.log(ClientDetails, "ClientDetails");
    setShowPostelAddress(!showPostelAddress);
  };

  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  /* ---------------------------------------------------------------- */
  function formatMobileNumber(number) {
    // Remove all non-numeric characters from the input
    const numericOnly = number.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 4 and format accordingly
    if (numericOnly.length <= 4) {
      return numericOnly;
    } else if (numericOnly.length <= 7) {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4)}`;
    } else {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4, 7)}-${numericOnly.slice(7, 10)}`;
    }
  }

  return (
    <React.Fragment>
      {roundLoader && <SimpleBackdrop />}
      {row.length === 0 ? ( //if rows are empty then progress bar is shown
        <ProgressBar />
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={0}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Title title="Job Details" />
                  {isEdit ? (
                    <ButtonGroup
                      variant="contained"
                      aria-label="contained primary button group"
                      sx={{ mr: 1 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleCancel}
                        className={classes.button}
                      >
                        <CloseIcon fontSize="small" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        align="right"
                        onClick={handleSave}
                        className={classes.button}
                      >
                        <DoneIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup
                      variant="contained"
                      aria-label="contained primary button group"
                      sx={{ mr: 1 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleEdit}
                        className={classes.button}
                      >
                        <CreateIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
                <Divider />
                {jobId ? (
                  <React.Fragment>
                    <List component="nav" aria-label="mailbox folders">
                      <Grid style={{ display: "flex" }}>
                        <Grid item xs={4}>
                          <ListItem button>
                            <strong>Job No : </strong>
                            <ListItemText primary={row.jobNo} />
                          </ListItem>
                        </Grid>
                        <Grid item xs={4}>
                          {isEdit ? (
                            <ListItem button>
                              <strong>Description : </strong>
                              <TextField
                                sx={{ width: 270, ml: "5px" }}
                                variant="outlined"
                                size="small"
                                label="Description"
                                value={row.description}
                                name="description"
                                onChange={(e) => handleInputChange(e)}
                              />
                            </ListItem>
                          ) : (
                            <ListItem button>
                              <strong>Description :</strong>
                              {/* {row.estimateId!==null():()} */}

                              <ListItemText sx={{ width: 270, ml: "8px" }} primary={row.description} />
                            </ListItem>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {isEdit ? (
                            <ListItem button>
                              <strong>Status :</strong>
                              <Autocomplete
                                onChange={(event, newValue) => {
                                  setRow({ ...row, status: newValue });
                                }}
                                value={row.status}
                                options={status}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: 300, ml: "5px" }}
                                    variant="outlined"
                                    label="Status"
                                    size="small"
                                    required
                                  />
                                )}
                              />
                            </ListItem>
                          ) : (
                            <ListItem button>
                              <strong>Status :</strong>
                              <ListItemText primary={row.status} />
                            </ListItem>
                          )}
                        </Grid>
                      </Grid>
                      <Grid style={{ display: "flex" }}>
                        <Grid item xs={4}>
                          {isEdit ? (
                            <ListItem button>
                              <strong>Customer Name :</strong>
                              <Autocomplete
                                clearIcon={
                                  <ClearIcon
                                    fontSize="small"
                                    onClick={() => setSelectedClients({ clientId: "" })}
                                  />
                                }
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setSelectedClients(newValue);
                                  }
                                }}
                                defaultValue={selectedClients}
                                options={clientData}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: 300, ml: "5px" }}
                                    variant="outlined"
                                    label="Customer Name"
                                    size="small"
                                    required
                                  />
                                )}
                              />
                            </ListItem>
                          ) : (
                            <ListItem button>
                              <strong>Customer Name :</strong>
                              <Link
                                className="link"
                                target="_self"
                                path="/$"
                                component={selectedClients?.name?.replace(/\s/g, "")}
                                onClick={(e) => {
                                  reset(selectedClients);
                                  setState({ right: true });
                                }}
                              >
                                {selectedClients?.name}
                              </Link>
                            </ListItem>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          {isEdit ? (
                            <ListItem button>
                              <strong>Expected Start Date :</strong>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  id="expectedStartDate"
                                  fullWidth
                                  size="small"
                                  label="Expected Start Date"
                                  inputVariant="outlined"
                                  onChange={(newvalue) =>
                                    setRow({
                                      ...row,
                                      expectedStartDate: newvalue.$d
                                        .toLocaleString("en-us", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })
                                        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
                                    })
                                  }
                                  value={row.expectedStartDate}
                                  inputFormat="DD/MM/YYYY"
                                  renderInput={(params) => (
                                    <TextField size="small" sx={{ width: 300, ml: "5px" }} {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </ListItem>
                          ) : (
                            <ListItem button>
                              <strong>Expected Start Date :</strong>
                              <MomentDate getDate={row.expectedStartDate} />
                            </ListItem>
                          )}
                        </Grid>
                      </Grid>
                    </List>
                  </React.Fragment>
                ) : (
                  <Alert severity="warning">Please select JobDetails.</Alert>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginTop: "5px", marginRight: "18px" }}>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("left", false)}
              PaperProps={{
                sx: {
                  width: "30%",
                },
              }}
            >
              <div className={classes.Drawerroot}>
                {roundLoader && <SimpleBackdrop />}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary" mb={1} style={{ fontSize: "20px" }}>
                      Update Client
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Client Name"
                          size="small"
                          className={classes.textField}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.name?.type === "required" && "First name is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12} my={-2}>
                    <InputLabel id="labelClientGroup">Client Type</InputLabel>
                    <Controller
                      name="clientType"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <Select {...field} className={classes.textField} size="small">
                          <MenuItem value="Archived">Archived</MenuItem>
                          <MenuItem value="Business">Business</MenuItem>
                          <MenuItem value="Lead">Lead</MenuItem>
                          <MenuItem value="Person">Person</MenuItem>
                          <MenuItem value="Customer">Customer</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.clientType && "Client Type required"}
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Address"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                        />
                      )}
                    />
                    {errors.address && "Address required"}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="cityOrTown"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="City / Town"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                        />
                      )}
                    />

                    {errors.cityOrTown && "City/Town required"}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="State"
                          size="small"
                          variant="outlined"
                          className={classes.textField}
                        />
                      )}
                    />
                    {errors.stateClient && "State required"}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="postcode"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Postcode"
                          type="number"
                          size="small"
                          variant="outlined"
                          className={classes.textField}
                        />
                      )}
                    />
                    {errors.postcode && "Postcode required"}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Phone"
                          size="small"
                          variant="outlined"
                          className={classes.textField}
                          rules={{
                            required: false,
                            minLength: 9,
                            maxLength: 9,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                          }}
                          onChange={(e) => {
                            const formattedNumber = formatPhoneNumber(e.target.value);
                            field.onChange(formattedNumber);
                          }}
                          value={field.value}
                        />
                      )}
                    />
                    {errors.phone && "Phone required"}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="mobile"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Mobile"
                          variant="outlined"
                          size="small"
                          rules={{
                            required: false,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                          }}
                          onChange={(e) => {
                            const formattedNumber = formatMobileNumber(e.target.value);
                            field.onChange(formattedNumber);
                          }}
                          className={classes.textField}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.mobile?.type === "required" && "Mobile Number required"}
                      {errors.mobile?.type === "minLength" && "Please enter valid Mobile number"}
                      {errors.mobile?.type === "maxLength" && "Please enter valid Mobile number"}
                    </p>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={handleSamePostalAddress}
                          defaultChecked
                          value={showPostelAddress}
                        />
                      }
                      label="Postal Address Same As Address"
                      size="small"
                    />
                  </Grid>
                  {!showPostelAddress && (
                    <>
                      <Grid item xs={12}>
                        <Controller
                          name="postalAddress"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal Address"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                            />
                          )}
                        />
                        {errors.address && "postalAddress required"}
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="postalCityOrTown"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal City / Town"
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                            />
                          )}
                        />

                        {errors.postalCityOrTown && "City/Town required"}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="postalState"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal State"
                              size="small"
                              variant="outlined"
                              className={classes.textField}
                            />
                          )}
                        />
                        {errors.postalState && "postalState required"}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="postalPostcode"
                          control={control}
                          rules={{
                            required: false,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal Post code"
                              type="number"
                              size="small"
                              variant="outlined"
                              className={classes.textField}
                            />
                          )}
                        />
                        {errors.postalPostcode && "PostalPostcode required"}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="postalPhone"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal Phone"
                              size="small"
                              variant="outlined"
                              className={classes.textField}
                              rules={{
                                required: false,
                                minLength: 9,
                                maxLength: 9,
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                              }}
                              onChange={(e) => {
                                const formattedNumber = formatPhoneNumber(e.target.value);
                                field.onChange(formattedNumber);
                              }}
                            />
                          )}
                        />
                        {errors.postalPhone && "postalPhone required"}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="Postalmobile"
                          control={control}
                          rules={{ required: false }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Postal Mobile"
                              variant="outlined"
                              size="small"
                              rules={{
                                required: false,
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                              }}
                              onChange={(e) => {
                                const formattedNumber = formatMobileNumber(e.target.value);
                                field.onChange(formattedNumber);
                              }}
                              className={classes.textField}
                            />
                          )}
                        />
                        <p className={ErrorClasses.errorMsg}>
                          {errors.Postalmobile?.type === "required" && "Postalmobile Number required"}
                          {errors.Postalmobile?.type === "minLength" &&
                            "Please enter valid Mobile number"}
                          {errors.Postalmobile?.type === "maxLength" &&
                            "Please enter valid Mobile number"}
                        </p>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      name="emailAddress"
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Email"
                          size="small"
                          onChange={field.onChange}
                          variant="outlined"
                          className={classes.textField}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.emailAddress?.type === "required" && "Email required"}
                      {errors.emailAddress?.type === "pattern" && "Please enter a valid email"}
                    </p>
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      type="button"
                      size="small"
                      color="primary"
                      onClick={handleUpdateSubmit}
                      variant="contained"
                    >
                      Update
                    </Button>

                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.button}
                      style={{ marginLeft: "8px" }}
                      onClick={(e) => handleClose(e)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </div>
        </div>
      </React.Fragment>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
