/** @format */

import { AppBar, Container, Grid, ListItemText, Toolbar } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
function FooterTotal({ rows }) {
  var finalEstTotal = 0;
  var finalActTotal = 0;
  var finalVariance = 0;
  //calculation for est total act total and var total
  var inputList = rows.filter((grp) => {
    grp.items.filter((e) => {
      var estTotal = e.estimatedQty * e.estimatedUnitCost;
      finalEstTotal = finalEstTotal + estTotal;
      var actTotal = e.actualQty * e.actualUnitCost;
      finalActTotal = finalActTotal + actTotal;
      var fvar = e.estimatedUnitCost * e.estimatedQty - e.actualUnitCost * e.actualQty;
      finalVariance = finalVariance + fvar;
    });
  });

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, maxWidth: "30%" }}
      style={{ backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"}` }}
    >
      <Container style={{ boxShadow: "none" }}>
        <Toolbar style={{ boxShadow: "none" }}>
          <ListItemText
            primary="Estimated (Ex)"
            secondary={
              <NumberFormat
                class="white"
                value={finalEstTotal.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
          <ListItemText
            primary="Actual (Ex)"
            secondary={
              <NumberFormat
                class="white"
                value={finalActTotal.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
          <ListItemText
            primary="Variance (Ex)"
            secondary={
              <NumberFormat
                class="white"
                value={finalVariance.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            }
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default FooterTotal;
