/** @format */

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AlertPopEstimate from "./AlertPopEstimate";
import { getEstimates } from "./estimates.helpers";
import CreateEstimate from "./NewEstimate/CreateEstimate";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import Drawer from "@mui/material/Drawer";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import {
  Chip,
  Grid,
  Snackbar,
  Typography,
  Divider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, Controller } from "react-hook-form";
import { Tooltip, Zoom } from "@mui/material";
import ErrorStyles from "../UI/ErrorStyles";
import TextField from "@mui/material/TextField";
import MUIDataTable from "mui-datatables";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { ESTIMATE_DELETE, ESTIMATE_READ, ESTIMATE_WRITE, IS_ADMIN } from "../UI/GlobalVariables";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import MomentDate from "../UI/MomentDate";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { updateClientbyId, getClientsForAutocomplete } from "../clients/clients.helpers";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
  },
  oneRow: {
    // borderBottom: "none",
    // width: "10vw",
  },
  rowDark: {
    // color: "#fff",
    // fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    // cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
  cardtitle: {
    padding: theme.spacing(2),
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));
export default function Estimates() {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [clientData, setData] = useState([]);
  /* DataTable */
  const columns = [
    {
      name: "estimateNo",
      label: "Code",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        setCellProps: () => ({
          style: { minWidth: "400px", maxWidth: "400px" },
        }),
        filter: true,
        sort: true,
      },
    },
    {
      name: "jobPlanName",
      label: "Job Plan",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              className="link"
              target="_self"
              to={`/job-plan/${estimates[tableMeta.rowIndex].jobPlanId}/items`}
              style={{
                textDecoration: "underline",
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === "DRAFT") {
            return <Chip size="small" label="Draft" />;
          } else if (value === "REJECTED") {
            return <Chip size="small" label="Rejected" color="secondary" />;
          } else if (value === "ACCEPTED") {
            return <Chip size="small" label="Accepted" color="primary" />;
          } else if (value === "WAITING") {
            return <Chip size="small" label="Waiting" color="warning" />;
          } else if (value === "CANCELLED") {
            return <Chip size="small" label="Cancelled" color="secondary" />;
          }
        },
      },
    },
    {
      name: "client",
      label: "Client",
      options: {
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "50px" } }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            let clientObj = {
              name: value.name,
              clientType: value.clientType,
              clientId: value.clientId,
              address: value.address,
              cityOrTown: value.cityOrTown,
              state: value.state,
              postcode: value.postcode,
              phone: value.phone,
              mobile: value.mobile,
              postalAddress: value.postalAddress,
              postalCityOrTown: value.postalCityOrTown,
              postalState: value.postalState,
              postalPostcode: value.postalPostcode,
              Postalmobile: value.Postalmobile,
              postalPhone: value.postalPhone,
              emailAddress: value.emailAddress,
            };
            return (
              <Link
                className="link"
                component={`${(clientObj?.name).replace(/\s/g, "")}`}
                pathname={`/${(clientObj?.name).replace(/\s/g, "")}`}
                target="_self"
                onClick={(e) => {
                  reset(clientObj);
                  setClientState({ right: true });
                }}
                style={{
                  textDecoration: "underline",
                }}
              >
                {clientObj.name}
              </Link>
            );
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "createdDateTime",
      label: "Created Date",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
      },
    },
    {
      name: "estimateId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained" size="small">
              {estimates[tableMeta.rowIndex].status === "DRAFT" ? (
                <Tooltip title="VIEW ESTIMATE DETAILS" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="primary"
                    target="_self"
                    onClick={() => history.push(`/estimate/${value}/costing`)}
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="VIEW ESTIMATE COSTING" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="primary"
                    target="_self"
                    onClick={() => history.push(`/estimate/${value}/costing`)}
                  >
                    <VisibilityIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="DELETE ESTIMATE" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={value}
                  onClick={(e) => handleDelete(value)}
                >
                  <DeleteIcon value={value} fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(ESTIMATE_WRITE) ? true : false,
      },
    },
  ];

  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [estimates, setEstimates] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [load, setLoad] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const [roundLoader, setRoundLoader] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [firstSnackbar, setFirstSnackbar] = React.useState(false);
  const [selectableRows, setSelectableRows] = useState(false);
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [showPostelAddress, setShowPostelAddress] = useState(true);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  /* ---------------------------------------------------------------- */

  const handleClose = (e) => {
    setClientState({ ...clientState, ["right"]: false });
  };
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [clientState, setClientState] = React.useState({
    right: false,
  });
  const toggleDrawerClient = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setClientState({ ...clientState, [anchor]: open });
  };
  const handleUpdateSubmit = (e) => {
    let clientDetails = getValues();
    let clientId = clientDetails.clientId;
    console.log(clientDetails, "clientDetails12");
    if (showPostelAddress) {
      clientDetails.Postalmobile = clientDetails?.mobile;
      clientDetails.postalAddress = clientDetails?.address;
      clientDetails.postalCityOrTown = clientDetails?.cityOrTown;
      clientDetails.postalState = clientDetails?.state;
      clientDetails.postalPostcode = clientDetails?.postcode;
      clientDetails.postalPhone = clientDetails?.phone;
    }
    /*deleting clientId of this object  */
    delete clientDetails["clientId"];
    updateClientbyId(
      clientId,
      clientDetails,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setClientState,
      setClientDetails,
      setData,
      clientData,
      setEstimates,
      estimates
    );
    setShowPostelAddress(true);
  };

  const handleSamePostalAddress = () => {
    let ClientDetails = getValues();
    ClientDetails.Postalmobile = ClientDetails?.mobile;
    ClientDetails.postalAddress = ClientDetails?.address;
    ClientDetails.postalCityOrTown = ClientDetails?.cityOrTown;
    ClientDetails.postalState = ClientDetails?.state;
    ClientDetails.postalPostcode = ClientDetails?.postcode;
    ClientDetails.postalPhone = ClientDetails?.phone;
    setClientDetails(ClientDetails);

    console.log(ClientDetails, "ClientDetails");
    setShowPostelAddress(!showPostelAddress);
  };

  /* ---------------------------------------------------------------- */

  var calledFrom = "CallFromEstimate";

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  //   setFirstSnackbar(false);
  // };
  var today = new Date();
  var now = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const [date, setDate] = useState(now);
  const history = useHistory();
  var jobPlanName = " ";
  /* useState for DataTable */

  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);

  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRows: false,
    selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    //   console.log("rorowData", rowData);
    // },
    onTableChange: (action, state) => {
      if (action === "rowsdelID") {
      }
      // console.log(action);
      // console.dir(state);
    },

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   console.log(rowsSelectedData, allRows, rowsSelected, "data", data[rowsSelected]);
    //   // this.setState({ rowsSelected: rowsSelected });
    // },
    // onRowsDelete: (rowsDeleted, newData) => {
    //   handleDelete(rowsDeleted, newData);
    // },
  };
  useEffect(() => {
    if (hasValidAccess(ESTIMATE_DELETE)) {
      setSelectableRows("single");
    }
    getEstimates(setEstimates, setLoad); //get estimates
    let data = getValues();
    // setClientDetails(data);
  }, []);

  useEffect(() => {
    getClientsForAutocomplete(setData);
    // getClients(setCustomer, setLoad); //get client name
  }, [wantToCallGet]);

  const [deleteId, setDeleteId] = useState();

  const handleDelete = (estimateId) => {
    setDeleteId(estimateId);
    setConfirmDelete(true);
  };
  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  function formatMobileNumber(number) {
    // Remove all non-numeric characters from the input
    const numericOnly = number.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 4 and format accordingly
    if (numericOnly.length <= 4) {
      return numericOnly;
    } else if (numericOnly.length <= 7) {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4)}`;
    } else {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4, 7)}-${numericOnly.slice(7, 10)}`;
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {delLoading && <SimpleBackdrop />}

          {hasValidAccess(ESTIMATE_WRITE) && (
            <CreateEstimate
              setFirstSnackbar={setFirstSnackbar}
              reset={reset}
              Controller={Controller}
              errors={errors}
              control={control}
              handleSubmit={handleSubmit}
              calledFrom={calledFrom}
              jobPlanData={""}
              setWantToCallGet={setWantToCallGet}
              setOpenCustSnackbar={setOpenCustSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
          <br />
          {load ? (
            <ProgressBar />
          ) : (
            <React.Fragment>
              {estimates.length === 0 ? ( //if estimates are empty then empty msg is shown
                <EmptyEstimatesDialog
                  heading="Empty Estimates"
                  msg="You don't have any estimates here yet.
Click the 'CREATE NEW' button above to get started, or choose from a template"
                />
              ) : (
                <MUIDataTable data={estimates} columns={columns} options={options} />
              )}
            </React.Fragment>
          )}
          <AlertPopEstimate
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            deleteId={deleteId}
            setEstimates={setEstimates}
            setOpenSnackbar={setOpenCustSnackbar}
            estimates={estimates}
            setWantToCallGet={setWantToCallGet}
            setSnackbarMSG={setSnackbarMSG}
            setSnackbarSeverity={setSnackbarSeverity}
          />
          {openCustSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openCustSnackbar}
              setOpenSnackbar={setOpenCustSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ marginTop: "5px", marginRight: "18px" }}>
              <Drawer
                anchor={"right"}
                open={clientState["right"]}
                onClose={toggleDrawerClient("left", false)}
                PaperProps={{
                  sx: {
                    width: "30%",
                  },
                }}
              >
                <div className={classes.Drawerroot}>
                  {roundLoader && <SimpleBackdrop />}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" color="primary" mb={1} style={{ fontSize: "20px" }}>
                        Update Client
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Client Name"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.name?.type === "required" && "First name is required"}
                      </p>
                    </Grid>
                    <Grid item xs={12} my={-2}>
                      <InputLabel id="labelClientGroup">Client Type</InputLabel>
                      <Controller
                        name="clientType"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <Select {...field} className={classes.textField} size="small">
                            <MenuItem value="Archived">Archived</MenuItem>
                            <MenuItem value="Business">Business</MenuItem>
                            <MenuItem value="Lead">Lead</MenuItem>
                            <MenuItem value="Person">Person</MenuItem>
                            <MenuItem value="Customer">Customer</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.clientType && "Client Type required"}
                    </Grid>
                    <Grid item xs={12} mt={1.5}>
                      <Controller
                        name="address"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Address"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.address && "Address required"}
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="cityOrTown"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="City / Town"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />

                      {errors.cityOrTown && "City/Town required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="state"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="State"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.stateClient && "State required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postcode"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postcode"
                            type="number"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.postcode && "Postcode required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Phone"
                            size="small"
                            variant="outlined"
                            rules={{
                              required: false,
                              minLength: 9,
                              maxLength: 9,
                            }}
                            className={classes.textField}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatPhoneNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            value={field.value}
                          />
                        )}
                      />
                      {errors.phone && "Phone required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Mobile"
                            variant="outlined"
                            size="small"
                            rules={{
                              required: false,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatMobileNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.mobile?.type === "required" && "Mobile Number required"}
                        {errors.mobile?.type === "minLength" && "Please enter valid Mobile number"}
                        {errors.mobile?.type === "maxLength" && "Please enter valid Mobile number"}
                      </p>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: 0 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={handleSamePostalAddress}
                            defaultChecked
                            value={showPostelAddress}
                          />
                        }
                        label="Postal Address Same As Address"
                        size="small"
                      />
                    </Grid>
                    {!showPostelAddress && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name="postalAddress"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Address"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.address && "postalAddress required"}
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="postalCityOrTown"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal City / Town"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                              />
                            )}
                          />

                          {errors.postalCityOrTown && "City/Town required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalState"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal State"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.postalState && "postalState required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalPostcode"
                            control={control}
                            rules={{
                              required: false,
                            }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Post code"
                                type="number"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.postalPostcode && "PostalPostcode required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalPhone"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Phone"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                                rules={{
                                  required: false,
                                  minLength: 9,
                                  maxLength: 9,
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                                }}
                                onChange={(e) => {
                                  const formattedNumber = formatPhoneNumber(e.target.value);
                                  field.onChange(formattedNumber);
                                }}
                              />
                            )}
                          />
                          {errors.postalPhone && "postalPhone required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="Postalmobile"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Mobile"
                                variant="outlined"
                                size="small"
                                rules={{
                                  required: false,
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                                }}
                                onChange={(e) => {
                                  const formattedNumber = formatMobileNumber(e.target.value);
                                  field.onChange(formattedNumber);
                                }}
                                className={classes.textField}
                              />
                            )}
                          />
                          <p className={ErrorClasses.errorMsg}>
                            {errors.Postalmobile?.type === "required" && "Postalmobile Number required"}
                            {errors.Postalmobile?.type === "minLength" &&
                              "Please enter valid Mobile number"}
                            {errors.Postalmobile?.type === "maxLength" &&
                              "Please enter valid Mobile number"}
                          </p>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Controller
                        name="emailAddress"
                        control={control}
                        rules={{
                          required: true,
                          pattern:
                            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Email"
                            size="small"
                            onChange={field.onChange}
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.emailAddress?.type === "required" && "Email required"}
                        {errors.emailAddress?.type === "pattern" && "Please enter a valid email"}
                      </p>
                    </Grid>
                    <Grid item xs={12} align="right">
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        onClick={handleUpdateSubmit}
                        variant="contained"
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        style={{ marginLeft: "8px" }}
                        onClick={(e) => handleClose(e)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Drawer>
            </div>
          </div>
        </React.Fragment>
        <Grid item xs={12}>
          <CommonInfo title={infoArray.estimates.title} msg={infoArray.estimates.msg} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
