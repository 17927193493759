import React, { useEffect, useContext } from 'react';
import { OAuthCtx } from "./OAuthContext";
import * as helpers from './OAuthcallback.helpers';
import Loading from '../components/UI/Loading';
import { setupAuthInterceptor } from "../utils/apis";


export default function OAuthcallback() {
    const { updateAuthState,  updatePermissions, getAuthState, redirectUrl} = useContext(OAuthCtx);
    setupAuthInterceptor(getAuthState, redirectUrl);
    const params = new URLSearchParams(window.location.search);
	const code = params.get('code');

    useEffect(() => {
        console.log("rendering callback")
        helpers.processCallbackParams({code, updateAuthState, updatePermissions});
    }, [code, updateAuthState]);


	return <Loading/>;
}