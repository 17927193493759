/** @format */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteVariationsByVariationsId } from "./variation.helper";

export default function DeleteAlertPop({
  confirmDelete,
  setConfirmDelete,
  deleteItemId,
  wantToCallGet,
  setWantToCallGet,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };

  const handleYes = () => {
    // let deletedVariationObj = variationObj.filter((element) => {
    //   return element.itemId != deleteItemId;
    // });
    // console.log("cncle");
    // setVariationObject(deletedVariationObj);
    // // setVariationObj(deletedVariationObj);
    // setConfirmDelete(false);
    deleteVariationsByVariationsId(
      deleteItemId,
      wantToCallGet,
      setWantToCallGet,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader
    );
    setConfirmDelete(false);
  };

  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
