/** @format */

import apis from "../../utils/apis";

export const getBusinessAccount = (setAccount, setLoad) => {
  setLoad(true);
  apis
    .GET({
      endpoint: "/business-account",
    })
    .then((res) => {
      setAccount(res.data);
      setLoad(false);
    });
};

export const updateBusinessAccount = (
  accountData,
  setState,
  setRoundLoader,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: "/business-account",
      payload: accountData,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("Account Details Updated Successfully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setState({ ["right"]: false });
      } else {
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Account Details Updation Failed");
        setSnackbarSeverity("error");
      }
    });
};
