import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
  },
}));
export default function Title({ title }) {
  return (
    <Typography
      style={{ padding: "10px" }}
      component="h2"
      variant="h6"
      gutterBottom
    >
      {title}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
