/** @format */

//Temporary Drawer and Addressform deleted
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import useStyles from "../UI/useStyles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { createCostCentres } from "./costcenter.helpers";
import { Autocomplete, Divider } from "@mui/material";
import { checkProperties } from "../UI/GlobalApi";
import { useForm, Controller } from "react-hook-form";
import errorStyles from "../UI/ErrorStyles";

export default function CreateCostCentre({
  costCentreName,
  costCentreDescription,
  setCostCentreName,
  setCostCentreDescription,
  costCentres,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  stateForCreateCostCentre,
  setStateForCreateCostCentre,
  defaultObjForAutocomplete,
  setDefaultObjForAutocomplete,
  setRoundLoader,
}) {
  const ErrorClasses = errorStyles();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  function handleCreateNew() {
    setCostCentreName("");
    setCostCentreDescription("");
    setDefaultObjForAutocomplete();
    reset({});
    setStateForCreateCostCentre({
      ...stateForCreateCostCentre,
      ["right"]: true,
    });
  }

  const submitHandleSave = (data) => {
    var obj = {
      costCentreName: data?.costCentreName,
      costCentreDescription: data?.costCentreDescription,
      copyFromCostCentreId: defaultObjForAutocomplete?.costCentreId,
    };

    createCostCentres(
      obj,
      setWantToCallGet,
      wantToCallGet,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setStateForCreateCostCentre,
      stateForCreateCostCentre,
      setRoundLoader
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setStateForCreateCostCentre({
      ...stateForCreateCostCentre,
      [anchor]: open,
    });
  };
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginBottom: "15px" }}>
          <Button
            onClick={handleCreateNew}
            variant="contained"
            size="small"
            color="primary"
            className={classes.button}
            startIcon={<AddCircleSharpIcon />}
          >
            Create New
          </Button>
          <Drawer
            anchor={"right"}
            open={stateForCreateCostCentre["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: 400,
              },
            }}
          >
            <div
              className={clsx(classes.createEstimateDrawer)}
              role="presentation"
              class="full"
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}
            >
              <form onSubmit={handleSubmit(submitHandleSave)}>
                <Grid container spacing={5} p={2}>
                  <Grid item xs={12} sm={12} mb={-3}>
                    <Box component="div">
                      <Typography
                        color="primary"
                        variant="h4  "
                        gutterBottom
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        New Cost Centre
                        <Divider />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} mb={-2}>
                    <Controller
                      name="costCentreName"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="costCentreName"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          label="Cost Centre Name"
                          fullWidth
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.costCentreName?.type === "required" && "Cost Centre Name  is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12} mb={-2}>
                    <Controller
                      name="costCentreDescription"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="costCentreDescription"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                          label="Cost Centre Description"
                          fullWidth
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.costCentreDescription?.type === "required" &&
                        "Cost Centre Description  is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12} mb={-2}>
                    {console.log("auto cor cost", defaultObjForAutocomplete)}
                    <Autocomplete
                      options={costCentres}
                      defaultValue={defaultObjForAutocomplete}
                      onChange={(event, newValue) => {
                        setDefaultObjForAutocomplete(newValue);
                      }}
                      getOptionLabel={(option) => option.costCentreName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="type"
                          name="type"
                          size="small"
                          variant="outlined"
                          label="Cost Centre"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} align="right">
                    <Button
                      color="primary"
                      size="small"
                      type="submit"
                      variant="contained"
                      style={{ marginRight: "10px" }}
                    >
                      save
                    </Button>
                    <Button
                      onClick={toggleDrawer("right", false)}
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.button}
                      // startIcon={<ClearIcon />}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
}
