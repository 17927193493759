/** @format */

import React from "react";
import Button from "@mui/material/Button";
import { ButtonGroup } from "@mui/material";

function SaveCostingButton({ handleSaveOuter }) {
  return (
    <ButtonGroup variant="contained" aria-label="contained primary button group">
      <Button variant="contained" size="small" color="secondary" align="right" onClick={handleSaveOuter}>
        Save
      </Button>
    </ButtonGroup>
  );
}

export default SaveCostingButton;
