/** @format */

import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip, Zoom } from "@mui/material";
import { createCostings } from "../../estimates/EstimateCosting/estimate.costings";
import NumberFormat from "react-number-format";
import {
  updateJobPlanGroupByEstimateId,
  updateJobPlanGroupByJobPlanId,
  updateJobPlanItemByEstimateJobPlanId,
  updateJobPlanItemByJobPlanId,
} from "./jobplan.helpers";
import JobPlanTable from "./JobPlanTable";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
} from "../../UI/GlobalVariables";

export default function Row({
  row,
  setItems,
  setRows,
  rows,
  jobPlanId,
  estimateId,
  loadSave,
  setLoadSave,
  isEdit,
  setEdit,
  setDisableSave,
  setTempRows,
  setOpenedItemRow,
  openAllRows,
  setOpenAllRows,
  index,
  props,
  handleAddQuote,
  handleAddToPlans,
  setCreateQuote,
  userConfigs,
  userConfigsuom,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  catalogueItems,
  setCatalogueItems,
  handleAddToCatalogue,
  setTempGroups,
  setWantToCallGetJobPlan,
  handleChange,
  setRoundLoader,
  setOpen,
  setDataPostSuccess,
  calledFrom,
  editableJobplan,
  estimateStatus,
}) {
  const useRowStyles = makeStyles({
    tableInnerContent: {
      paddingTop: "10px",
    },
  });
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const classes = useRowStyles();
  const [editGroup, setEditGroup] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [tempRowsData, setTempRowsData] = useState([]);
  const [dummy, setDummy] = useState([]);
  const [jobPlanObject, setJobPlanObject] = useState(row.items);

  const handleDelete = () => {
    console.log("handleDelete");
    var filterRows = rows.filter(function (el) {
      if (el.groupCode === row.groupCode) {
        var newItems = el.items.filter(function (itm) {
          return (itm.itemId = null);
        });
        el.items.length = 0;
        setItems(newItems);
      }
      return el.groupCode != row.groupCode;
    });

    let count = 1;
    var finalJobPlanObj = filterRows.filter(function (el) {
      el.groupCode = count++;
      return el;
    });
    if (props.type === "estimate") {
      console.log("if estimate", rows);

      updateJobPlanGroupByEstimateId(
        setRows,
        estimateId,
        finalJobPlanObj,
        setSnackbarSeverity,
        setSnackbarMSG,
        setOpenSnackbar,
        setTempGroups,
        setRoundLoader
      );
    } else if (props.type === "jobPlan") {
      console.log(" else jobPlan");

      setRows(finalJobPlanObj);
      updateJobPlanGroupByJobPlanId(
        setRows,
        jobPlanId,
        finalJobPlanObj,
        setSnackbarSeverity,
        setSnackbarMSG,
        setOpenSnackbar,
        setTempGroups,
        setRoundLoader
      );
    }
    setConfirmDelete(false);
    setShowConfirm(false);
  };
  const handleConfirm = () => {
    setShowConfirm(true);
  };
  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };

  useEffect(() => {
    row.items.sort(function (a, b) {
      return a.itemCode - b.itemCode;
    });

    // setInputList(tablerows);
  }, [row]);

  useEffect(() => {
    if (openAllRows[index]) {
      setJobPlanObject(row.items);
    }
  }, [openAllRows]);

  const handleOpenCostinRow = () => {
    if (openAllRows[index]) {
      setOpenAllRows({ ...openAllRows, [index]: false });
    } else {
      setJobPlanObject(row.items);
      setOpenAllRows({ ...openAllRows, [index]: true });
    }
  };
  const getRowToatal = (items) => {
    var total = 0;
    var mult = 0;
    var markup = 0;
    var gst = 0;
    //calculation for est total act total and var total
    items.filter((e) => {
      mult = parseInt(e.unitCost) * parseInt(e.qty);
      markup = (mult * parseInt(e.markupPercentage)) / 100;
      gst = ((markup + mult) * 10) / 100;
      total = total + gst + markup + mult;
    });

    return total;
  };

  // ------------------------------------------------------
  const handleGroupSave = () => {
    console.log("SAVECLICK:", rows);
    if (props.type === "estimate") {
      console.log("if estimate", rows);

      createCostings(
        setRows,
        estimateId,
        rows,
        setDataLoaded,
        setDummy,
        setDummy,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity
      );
    } else if (props.type === "jobPlan") {
      console.log(" else jobPlan");

      setRows(rows);
      updateJobPlanGroupByJobPlanId(
        setRows,
        jobPlanId,
        rows,
        setSnackbarSeverity,
        setSnackbarMSG,
        setOpenSnackbar,
        setTempGroups,
        setRoundLoader
      );
    }
    setEditGroup(false);
  };
  // ------------------------------------------------------
  let rowEditable = true;
  row.items.filter((obj, index) => {
    if (obj.editable === false) {
      rowEditable = false;
    }
  });
  // ------------------------------------------------------
  const handleInputChange = (e, index) => {
    const list = JSON.parse(JSON.stringify(rows));
    list[index][e.target.name] = e.target.value;
    setRows(list);
  };
  // ------------------------------------------------------
  return (
    <React.Fragment>
      <TableRow
        sx={{
          display: "block",
          width: 1 / 1,
          cursor: "pointer",
        }}
        // onClick={handleOpenCostinRow}
        hover
      >
        <TableCell sx={{ minWidth: 20 }} align="left" component="th">
          <IconButton aria-label="expand row" size="small" onClick={handleOpenCostinRow}>
            {openAllRows[index] ? (
              <Tooltip title="CLOSE LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowUpIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="VIEW LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowDownIcon fontSize="small" />
              </Tooltip>
            )}
          </IconButton>
        </TableCell>
        {editGroup ? (
          <>
            <TableCell width="5.3%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupCode}
                name="groupCode"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
            <TableCell width="65%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupName}
                name="groupName"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell sx={{ minWidth: 30 }} align="center">
              {row.groupCode}
            </TableCell>
            <TableCell sx={{ width: "100%", minWidth: 300 }} align="left" component="th">
              {row.groupName}
            </TableCell>
          </>
        )}

        <TableCell sx={{ minWidth: 70 }} align="center">
          {row.items.length} items
        </TableCell>
        <TableCell sx={{ minWidth: 100 }} align="right">
          <NumberFormat
            value={getRowToatal(row.items).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </TableCell>

        {(hasValidAccess(ESTIMATE_WRITE) ||
          hasValidAccess(JOB_PLAN_WRITE) ||
          hasValidAccess(JOB_PLAN_DELETE) ||
          hasValidAccess(ESTIMATE_DELETE)) && (
            <TableCell sx={{ minWidth: 100 }} align="right">
              {rowEditable ? (
                !editGroup ? (
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Tooltip title="DELETE GROUP" placement="top" TransitionComponent={Zoom} arrow>
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button
                            size="small"
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            onClick={handleConfirm}
                          >
                            <DeleteIcon fontSize="small" />
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            onClick={handleConfirm}
                            disabled
                          >
                            <DeleteIcon fontSize="small" />
                          </Button>
                        )
                      ) : (
                        <Button
                          size="small"
                          color="secondary"
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirm}
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      )}
                    </Tooltip>
                    <Tooltip title="Edit Groups" placement="top" TransitionComponent={Zoom} arrow>
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button
                            size="small"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setTempRowsData(rows);
                              setEditGroup(true);
                            }}
                          >
                            <CreateIcon fontSize="small" />
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setTempRowsData(rows);
                              setEditGroup(true);
                            }}
                            disabled
                          >
                            <CreateIcon fontSize="small" />
                          </Button>
                        )
                      ) : (
                        <Button
                          size="small"
                          color="primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setTempRowsData(rows);
                            setEditGroup(true);
                          }}
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <CreateIcon fontSize="small" />
                        </Button>
                      )}
                    </Tooltip>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Tooltip title="CANCEL" placement="top" TransitionComponent={Zoom} arrow>
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button
                            size="small"
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setRows(tempRowsData);
                              setEditGroup(false);
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            color="secondary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setRows(tempRowsData);
                              setEditGroup(false);
                            }}
                            disabled
                          >
                            <ClearIcon fontSize="small" />
                          </Button>
                        )
                      ) : (
                        <Button
                          size="small"
                          color="secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRows(tempRowsData);
                            setEditGroup(false);
                          }}
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <ClearIcon fontSize="small" />
                        </Button>
                      )}
                    </Tooltip>
                    <Tooltip title="SAVE" placement="top" TransitionComponent={Zoom} arrow>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleGroupSave()}
                        size="small"
                      >
                        <SaveIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                )
              ) : (
                <IconButton disabled>
                  <DeleteIcon style={{ cursor: "pointer" }} />
                </IconButton>
              )}
            </TableCell>
          )}
      </TableRow>
      <TableRow
        p={1}
        sx={{
          display: "block",
          backgroundColor: "rgb(106 106 106 / 5%)",
        }}
      >
        <TableCell
          component={"div"}
          sx={{
            display: "block",
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse in={openAllRows[index]} timeout="auto" unmountOnExit>
            <JobPlanTable
              handleChange={handleChange}
              rows={rows}
              setRows={setRows}
              groupName={row.groupName}
              groupCode={row.groupCode}
              jobPlanId={jobPlanId}
              estimateId={estimateId}
              dataLoaded={dataLoaded}
              isEdit={isEdit}
              setEdit={setEdit}
              setDisableSave={setDisableSave}
              jobPlanObject={jobPlanObject}
              setJobPlanObject={setJobPlanObject}
              setTempRows={setTempRows}
              setOpenedItemRow={setOpenedItemRow}
              props={props}
              handleAddQuote={handleAddQuote}
              setCreateQuote={setCreateQuote}
              hasValidAccess={hasValidAccess}
              userConfigs={userConfigs}
              userConfigsuom={userConfigsuom}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              setOpenSnackbar={setOpenSnackbar}
              catalogueItems={catalogueItems}
              setCatalogueItems={setCatalogueItems}
              handleAddToCatalogue={handleAddToCatalogue}
              handleAddToPlans={handleAddToPlans}
              setTempGroups={setTempGroups}
              setWantToCallGetJobPlan={setWantToCallGetJobPlan}
              setRoundLoader={setRoundLoader}
              calledFrom={calledFrom}
              editableJobplan={editableJobplan}
              estimateStatus={estimateStatus}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
