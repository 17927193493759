/** @format */

import apis from "../../utils/apis";

/** @format */
export const createFolder = (
  id,
  data,
  setLoad,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setCreateFolderDrawer,
  setRefreshFiles,
  callFrom
) => {
  setLoad(true);
  let url = callFrom === "job" ? `/file/create-folder/job/${id}` : `/file/create-folder/estimate/${id}`;
  apis
    .POST({
      endpoint: url,
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      console.log("RESULT:", res);
      if (res.data.message === "SUCCESS") {
        setRefreshFiles(true);
        setSnackbarMSG("Folder Created Successfully");
        setSnackbarSeverity("success");
        setLoad(false);
        setCreateFolderDrawer(false);
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG("Folder Creation failed");
        setSnackbarSeverity("error");
        setLoad(false);
        setOpenCustSnackbar(true);
      }
    });
};

export const listFiles = (id, path, setLoad, setFileList, callFrom) => {
  let url = callFrom === "job" ? `/file/list-files/job/${id}` : `/file/list-files/estimate/${id}`;
  apis
    .POST({
      endpoint: url,
      payload: { path },
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setFileList(res.data);
      setLoad(false);

      console.log(res);
    });
};

export const getFile = (
  id,
  data,
  setRoundLoader,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setFileUrl,
  setOpenWebViewer,
  callFrom
) => {
  setRoundLoader(true);
  let url = callFrom === "job" ? `/file/get-file/job/${id}` : `/file/get-file/estimate/${id}`;

  apis
    .POST({
      endpoint: url,
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setRoundLoader(false);
        setFileUrl(res.data.value);
        setOpenWebViewer(true);
        // binaryToBase64(res.data.value, setBase64File);
      } else {
        setSnackbarMSG("File Not Found");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
      console.log("GETFILE:", res);
      setRoundLoader(false);
    });
};

export const uploadFile = (
  id,
  data,
  setRoundLoader,
  setRefreshFiles,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setFileUploadDrawer,
  callFrom
) => {
  setRoundLoader(true);
  let url = callFrom === "job" ? `/file/upload/job/${id}` : `/file/upload/estimate/${id}`;

  apis
    .PUT({
      endpoint: url,
      payload: { name: data.name, path: data.path },
      options: {
        type: "json",
      },
    })
    .then((res) => {
      console.log("res:", res);
      console.log("res.data", res.data);
      uploadOnLink(
        res.data.value,
        data.fileData,
        setRoundLoader,
        setRefreshFiles,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setFileUploadDrawer
      );
    });
};

export const deleteFile = (
  id,
  data,
  setRoundLoader,
  setRefreshFiles,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setOpenDeleteDialog,
  callFrom
) => {
  console.log("DELETE Payload:", data);
  setOpenDeleteDialog(false);
  setRoundLoader(true);
  let url = callFrom === "job" ? `/file/delete-file/job/${id}` : `/file/delete-file/estimate/${id}`;

  apis
    .POST({
      endpoint: url,
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setRefreshFiles(true);
        setSnackbarMSG("File Deleted Successfully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG("File Delation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

// -------------------------UPLOAD-FILEs-OnLink-------------
const uploadOnLink = (
  fileUploadUrl,
  fileData,
  setRoundLoader,
  setRefreshFiles,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setFileUploadDrawer
) => {
  apis
    .S3_DOC_PUT({
      endpoint: fileUploadUrl,
      payload: fileData,
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("upload Success", res);
        setRefreshFiles(true);
        setRoundLoader(false);
        setSnackbarMSG("File Uploaded Successfully");
        setSnackbarSeverity("success");
        setFileUploadDrawer(false);
        setOpenCustSnackbar(true);
      } else {
        setRoundLoader(false);
        setSnackbarMSG("File Uploaded Successfully");
        setSnackbarSeverity("success");
        setOpenCustSnackbar(true);
      }
    });
};
