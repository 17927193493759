import apis from '../utils/apis';

import {
	getTargetPath
} from '../utils/storage';

export const getToken = ({ code }) => {
		const form = new URLSearchParams();
		form.append('grant_type', 'authorization_code');
		form.append('code', code);
		form.append('redirect_uri', process.env.REACT_APP_OAUTH_CALLBACK_URL);
		form.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID);
		form.append('scope', process.env.REACT_APP_OAUTH_SCOPE)
		return apis.POST({
			endpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT,
			payload: form,
			options: { type: 'form', fullUrl: true},
		});
};

export const getUserProfile = () => {
return apis.GET({
        endpoint: `/user-account`
    })
}



export const processCallbackParams = async ({ code, updateAuthState, updatePermissions }) => {

	const res = await getToken({
		code
	});
	if (apis.isResponseOK(res)) {
		updateAuthState({
			isLoggedIn: true,
			id_token: res.data.id_token,
			access_token: res.data.access_token,
			expire_by: new Date().getTime() + (res.data.expires_in * 1000)
		})
		const res_profile = await getUserProfile();

		if(apis.isResponseOK(res_profile)) {
			updatePermissions(res_profile.data.permissions)
		}
		window.location.replace(getTargetPath());
	} else {
		console.log(res);
	}
	
};


