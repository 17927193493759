/** @format */
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { getGst } from "../../UI/GlobalApi";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import NumberFormat from "react-number-format";
import ClearIcon from "@mui/icons-material/Clear";
import ButtonGroup from "@mui/material/ButtonGroup";
import DialogTitle from "@mui/material/DialogTitle";
import PercentIcon from "@mui/icons-material/Percent";
import { styled, lighten, darken } from "@mui/system";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { getLineItemsGroupsName } from "./variation.helper";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import DialogContentText from "@mui/material/DialogContentText";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { getJobLineItemsByJobId } from "../actual_costings/helpers.actual.costings";
import { checkProperties, getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: 650,
  },
  rowDark: {
    // color:'#fff',
    // backgroundColor:'#0FA1DC',
    fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    cursor: "pointer",
  },
});
function VariationLineItemsTable({
  variationData,
  setValue,
  jobId,
  lineItemsForAutoselect,
  lineItemsGroupsForAutoselect,
  setShowSaveButton,
}) {
  const filter = createFilterOptions();
  var newItem;
  const [rows, setRows] = useState(
    variationData.variationLineItems && JSON.parse(JSON.stringify(variationData.variationLineItems))
  );
  const [draftItems, setDraftItems] = useState(
    variationData.variationLineItems && JSON.parse(JSON.stringify(variationData.variationLineItems))
  );

  const [deleteIndex, setDeleteIndex] = useState();
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userConfigs, setUserConfigs] = useState({});
  const [dummyLoad, setDummyLoad] = useState(false);
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const [groupsInfo, setGroupsInfo] = useState([]);
  const [groupObject, setGroupObject] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  // const [lineItemsForAutoselect, setLineItemsForAutoselect] = React.useState([]);
  const [dummyRows, setDummyRows] = React.useState([]);
  const [dummyTempData, setDummyTempData] = React.useState([]);
  useEffect(() => {
    /* This is useForm method to use store value inside form object */
    setValue({ ...variationData, variationLineItems: rows });
  }, [rows]);

  useEffect(() => {
    getUserConfigsTypes(setUserConfigs);
    getUserConfigsUom(setUserConfigsuom, setDummyLoad);
    getLineItemsGroupsName(jobId, setGroupsInfo, setGroupObject);
    // getJobLineItemsByJobId(
    //   setDummyRows,
    //   setDummyTempData,
    //   setDummyLoad,
    //   jobId,
    //   setLineItemsForAutoselect
    // );
  }, []);



  const handleConfirm = (i) => {

    setDeleteIndex(i);
    setShowConfirm(true);
  };

  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(deleteIndex, 1);
    setRows(list);
    setConfirmDelete(false);
    setShowConfirm(false);
  };
  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };
  const handleAdd = () => {
    // let lastBeforeRowLineId;
    // if (rows.length === 0) {
    //   lastBeforeRowLineId = 0;
    // } else {
    //   lastBeforeRowLineId = rows[rows.length - 1].itemCode;
    // }
    setIsEdit(true);
    setShowSaveButton(false);

    setRows([
      ...rows,
      {
        jobLineItemId: null,
        itemId: null,
        itemCode: "",
        itemName: "",
        itemGroupCode: "",
        itemGroupName: "",
        itemDescription: "",
        itemCategory: "",
        optionId: null,
        optionName: null,
        type: "",
        qty: 1,
        uom: "",
        unitCost: 0,

        gst: true,
      },
    ]);
  };
  const handleCancel = () => {
    setRows(draftItems); /* Reset our old data after cancel edit/add operation  */
    setIsEdit(false);
    setShowSaveButton(true);
  };

  const handlesLineItemSave = () => {
    let valid = true;
    let errMsg = checkProperties(rows);
    if (errMsg !== "") valid = false;

    if (valid == true) {
      setDraftItems(JSON.parse(JSON.stringify(rows)));
      setIsEdit(false);
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
    setShowSaveButton(true);
  };

  const handleEdit = () => {
    setDraftItems(rows);
    setIsEdit(true);
    setShowSaveButton(false);
  };
  const handleVarInputChange = (e, index) => {
    const { name, value } = e.target;

    // setValue(inputValue);
    var list = JSON.parse(JSON.stringify(rows));
    list[index][name] = value;
    if (name === "unitCost" || name === "qty") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    setRows(list);


  };
  const handleChangeGST = (e, i) => {
    // if (!e.target.checked) {
    //   const list = JSON.parse(JSON.stringify(rows));
    //   list[i][e.target.name] = "0";
    //   setRows(list);
    // } else {
    //   const list = JSON.parse(JSON.stringify(rows));
    //   list[i][e.target.name] = "10";
    //   setRows(list);
    // }

    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(rows));
      list[i][e.target.name] = value;
      setRows(list);
    }
  };
  //  function handleChangeGroupObject(e, index) {
  //   const { name, value } = e.target;
  //   var list = JSON.parse(JSON.stringify(rows));
  //   list[index]["itemGroupName"] = value.groupName;
  //   list[index]["itemGroupCode"] = value.groupCode;
  //   var listGroup = [...groupObject];
  //   listGroup[index] = value;
  //   setRows(list);
  // }

  function handleChangeGroupObject(e, index, newValue) {

    if (newValue) {

      var list = JSON.parse(JSON.stringify(rows));
      list[index]["itemGroupName"] = newValue.groupName;

      list[index]["itemGroupCode"] = newValue.groupCode;
      //var listGroup = [...groupObject];
      //listGroup[index].groupName = newValue;
      setRows(list);

    }
  }

  const clearLineItem = (row) => {
    let filterList = rows.filter((el) => {
      return row.itemCode != el.itemCode;
    });
    let count = 1;
    var test = filterList.filter(function (el) {
      return (row.itemCode = count++);
    });
    setRows(test);
  };
  const [values, setValues] = React.useState([]);
  const AutocompleteGroup = (props) => {
    const { index, orderItem } = props;
    const filter = createFilterOptions();

    return (
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        value={values[index]}
        onChange={(event, newValue) => {
          var selectListGroup = [...values];
          if (typeof newValue === "string") {
            selectListGroup[index] = {
              title: newValue,
            };
            setValues(selectListGroup);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input

            var listGroup = [...groupObject];
            var newGroup = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            selectListGroup[index] = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            setValues(selectListGroup);
            handleChangeGroupObject(event, index, newGroup);
          } else {
            selectListGroup[index] = newValue;
            setValues(selectListGroup);
            handleChangeGroupObject(event, index, newValue);
          }

        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.groupName);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={(option) => {

          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.groupName;
        }}
        //getOptionLabel={(option) => option.groupName}
        options={groupObject?.map((option) => option)}
        renderInput={(params) => <TextField {...params} />}
      />
    );
  };
  // ----------------------------Calculations-------------------------------------------------
  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;
  rows.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (isNaN(mult)) mult = 0;
    finalSubTotal = finalSubTotal + mult;
    itemTax = (mult * getGst(e.gst)) / 100;
    if (isNaN(itemTax)) itemTax = 0;
    finalTax = finalTax + parseInt(itemTax);
    finalTotal = finalSubTotal + finalTax;
  });
  // ----------------------------Calculations-Ends-------------------------------------------------
  const handleAutoselectLineItemName = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemName: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.estimatedQty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupName: newValue.itemGroupName,
          itemCode: newValue.itemCode,
        };
        setRows(list);
      }
    }
  };
  const handleAutoselectLineItem = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemDescription: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.estimatedQty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setRows(list);
      }
    }
  };
  const handleAutoselectLineItemCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemCode: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          jobLineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.estimatedUnitCost,
          qty: newValue.estimatedQty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemCode: newValue.itemCode,
        };
        setRows(list);
      }
    }
  };

  const handleAutoselectLineItemGroup = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: "",
          itemGroupName: newValue,
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setRows(list);
      }
    }
  };

  const handleAutoselectLineItemGroupCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(rows));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue,
          itemGroupName: "",
        };
        setRows(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setRows(list);
      }
    }
  };
  // ----------------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      {isEdit ? (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            align="right"
            onClick={handleCancel}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </Button>
          <Button variant="contained" color="primary" onClick={handlesLineItemSave} size="small">
            <SaveIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button variant="contained" size="small" color="primary" align="right" onClick={handleEdit}>
            <EditIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      )}
      {rows.length < 1 ? (
        <EmptyEstimatesDialog
          heading="Empty Line Items"
          msg="Please add some order items by click on add icon abouv"
        />
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "20px" }}>
                  <strong>Code</strong>
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <strong>Name</strong>
                </TableCell>
                <TableCell sx={{ width: 1 / 2, minWidth: 300 }}>
                  <strong>Description</strong>
                </TableCell>
                <TableCell align={"center"}>Group Code</TableCell>
                <TableCell align={"center"}>Group Name</TableCell>
                <TableCell align="center" sx={{ minWidth: 155 }}>
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 75 }}>
                  <strong>Qty</strong>
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 75 }}>
                  Unit Cost(Ex)
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 100 }}>
                  <strong>UOM</strong>
                </TableCell>
                {!isEdit && <TableCell align="right">Total Cost</TableCell>}
                <TableCell align="right">GST</TableCell>
                {/* <TableCell align="right" sx={{ minWidth: 85 }}>
                  Markup
                </TableCell> */}
                <TableCell align="right">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            {isEdit ? (
              <TableBody align={"center"}>
                {rows?.map((orderItem, index) => (
                  <TableRow>
                    <TableCell align="center">
                      <Autocomplete
                        id="grouped-demo"
                        value={orderItem.itemCode}
                        options={lineItemsForAutoselect}
                        groupBy={(option) => option.itemGroupName}
                        getOptionLabel={(option) => {


                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          else if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          else return `${option.itemCode}: ${option.itemName}`;
                        }}
                        onChange={(event, newValue) => {
                          handleAutoselectLineItemCode(index, orderItem, newValue);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.itemCode);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                        fullWidth
                        size="small"
                        renderInput={(params) => <TextField {...params} />}
                        renderGroup={(params) => (
                          <li>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                      />
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 100 }}>
                      <Autocomplete
                        id="grouped-demo"
                        value={orderItem.itemName}
                        options={lineItemsForAutoselect}
                        groupBy={(option) => option.itemGroupName}
                        getOptionLabel={(option) => {


                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          else if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          else return `${option.itemCode}: ${option.itemName}`;
                        }}
                        onChange={(event, newValue) => {
                          handleAutoselectLineItemName(index, orderItem, newValue);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.itemName);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                        fullWidth
                        size="small"
                        renderInput={(params) => <TextField {...params} />}
                        renderGroup={(params) => (
                          <li>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Autocomplete
                        id="grouped-demo"
                        value={orderItem.itemDescription}
                        options={lineItemsForAutoselect}
                        groupBy={(option) => option.itemGroupName}
                        getOptionLabel={(option) => {


                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          else if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          else return `${option.itemCode}: ${option.itemDescription}`;
                        }}
                        onChange={(event, newValue) => {
                          handleAutoselectLineItem(index, orderItem, newValue);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some(
                            (option) => inputValue === option.itemDescription
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                        fullWidth
                        size="small"
                        renderInput={(params) => <TextField {...params} />}
                        renderGroup={(params) => (
                          <li>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        minWidth: 80,
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    >
                      <Autocomplete
                        id="grouped-demo"
                        value={orderItem.itemGroupCode}
                        options={lineItemsGroupsForAutoselect}
                        // groupBy={(option) => option.itemGroupName}
                        getOptionLabel={(option) => {


                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          else if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          else return option.groupCode;
                        }}
                        onChange={(event, newValue) => {
                          handleAutoselectLineItemGroupCode(index, orderItem, newValue);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.groupCode);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                        fullWidth
                        size="small"
                        renderInput={(params) => <TextField {...params} />}
                        renderGroup={(params) => (
                          <li>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                      />
                    </TableCell>
                    <TableCell sx={{ minWidth: 115 }}>
                      <Autocomplete
                        id="grouped-demo"
                        value={orderItem.itemGroupName}
                        options={lineItemsGroupsForAutoselect}
                        groupBy={(option) => option.itemGroupName}
                        getOptionLabel={(option) => {


                          // Value selected with enter, right from the input
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          else if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          else return option.groupName;
                        }}
                        onChange={(event, newValue) => {
                          handleAutoselectLineItemGroup(index, orderItem, newValue);
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.groupName);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                        fullWidth
                        size="small"
                        renderInput={(params) => <TextField {...params} />}
                        renderGroup={(params) => (
                          <li>
                            <GroupHeader>{params.group}</GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                          </li>
                        )}
                      />
                    </TableCell>
                    <TableCell align="center" className="muitable_cell_title_type">
                      <Select
                        className={classes.textField}
                        size="small"
                        fullWidth
                        name="type"
                        value={orderItem.type}
                        onChange={(e) => handleVarInputChange(e, index)}
                      >
                        {userConfigs.config?.length != 0 &&
                          userConfigs.config?.map(
                            (el) =>
                              el.enabled === true && <MenuItem value={el.value}>{el.value}</MenuItem>
                          )}
                      </Select>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        type="number"
                        value={orderItem.qty}
                        name="qty"
                        onChange={(e) => handleVarInputChange(e, index)}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        type="number"
                        value={orderItem.unitCost}
                        name="unitCost"
                        onChange={(e) => handleVarInputChange(e, index)}
                      />
                    </TableCell>
                    <TableCell align="center" className="muitable_cell_title_uom">
                      <Select
                        className={classes.textField}
                        size="small"
                        fullWidth
                        name="uom"
                        value={orderItem.uom}
                        onChange={(e) => handleVarInputChange(e, index)}
                      >
                        {userConfigsuom.config?.length != 0 &&
                          userConfigsuom.config?.map(
                            (el) =>
                              el.enabled === true && <MenuItem value={el.value}>{el.value}</MenuItem>
                          )}
                      </Select>
                    </TableCell>
                    <TableCell align="center">
                      {orderItem.gst === true ? (
                        <Checkbox
                          size="small"
                          value={10}
                          name="gst"
                          defaultChecked
                          onChange={(e) => handleChangeGST(e, index)}
                        />
                      ) : (
                        <Checkbox
                          size="small"
                          name="gst"
                          value={0}
                          onChange={(e) => handleChangeGST(e, index)}
                        />
                      )}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      sx={{
                        "& input": {
                          textAlign: "right",
                        },
                      }}
                    >
                      <TextField
                        id="right"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        type="number"
                        value={orderItem.markupPercentage}
                        name="markupPercentage"
                        onChange={(e) => handleVarInputChange(e, index)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PercentIcon
                                style={{
                                  fontSize: "15px",
                                  margin: "-10px",
                                  color: "3A3B3C",
                                  border: "redius",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell> */}
                    <TableCell>
                      <ButtonGroup variant="contained" size="small">
                        <Button
                          size="small"
                          color="secondary"
                          className="mr10"
                          key={orderItem.itemId}
                          onClick={(e) => clearLineItem(orderItem)}
                        >
                          <ClearIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {rows?.map((orderItem, i) => (
                  <TableRow>
                    <TableCell align="center">{orderItem.itemCode}</TableCell>
                    <TableCell>{orderItem.itemName}</TableCell>
                    <TableCell>{orderItem.itemDescription}</TableCell>
                    <TableCell align="center">{orderItem.itemGroupCode}</TableCell>
                    <TableCell align="center">{orderItem.itemGroupName}</TableCell>
                    <TableCell align="center">{orderItem.type}</TableCell>
                    <TableCell align="right">{orderItem.qty}</TableCell> {/* orderItem.qty */}
                    <TableCell align="right">
                      <NumberFormat
                        value={(orderItem.unitCost * 1).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                    <TableCell align="center">{orderItem.uom}</TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={(orderItem.qty * orderItem.unitCost).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                    {/* total Cost */}
                    <TableCell align="right">
                      <NumberFormat
                        value={(getGst(orderItem.gst) * 1).toFixed(1)}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix="%"
                      />
                    </TableCell>
                    {/* <TableCell align="right">
                      {" "}
                      <NumberFormat
                        value={(orderItem.markupPercentage * 1).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix="%"
                      />{" "}
                    </TableCell> */}
                    <TableCell>
                      <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button
                          size="small"
                          className="mr10"
                          color="secondary"
                          key={orderItem.itemId}
                          onClick={(e) => handleConfirm(i)}
                        >
                          <Delete fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      <Grid
        container
        spacing={0}
        mt={2}
        mb={1}
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid item xs={4}>
          <Table
            size="small"
            sx={{
              borderBottom: "none",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell align="left" variant="head">
                  {"Sub Total:"}
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalSubTotal * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" variant="head">
                  {"GST:"}
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalTax * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" variant="head">
                  {"Variation Total : "}
                </TableCell>
                <TableCell align="right">
                  {
                    <NumberFormat
                      value={(finalTotal * 1).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
      <Dialog
        open={showConfirm}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleRemoveClick(e)} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default VariationLineItemsTable;
