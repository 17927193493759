/** @format */

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { getJobplans } from "./jobplans.helpers";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import ProgressBar from "../UI/ProgressBar";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import { Box, Grid, Snackbar } from "@mui/material";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import AlertPopJobPlans from "./AlertPopJobPlans";
import CreateNewJobPlanDialog from "../cost-center/job-plan/CreateNewJobPlanDialog";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { Tooltip, Zoom } from "@mui/material";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { JOB_PLAN_WRITE, JOB_PLAN_DELETE } from "../UI/GlobalVariables";
import VisibilityIcon from "@mui/icons-material/Visibility";
const useStyles = makeStyles({
  table: {
    // minWidth: 250,
  },
  oneRow: {
    // borderBottom: "none",
    // width: "10vw",
  },
  rowDark: {
    // color: "#fff",
    // fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    // cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
});
export default function Jobplans() {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const columns = [
    {
      name: "jobPlanNo",
      label: "Code",
      options: {
        filter: true,

        sort: true,
        display: true,
        style: { minWidth: "50px", maxWidth: "50px" },
      },
    },
    {
      name: "jobPlanName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        style: { minWidth: "50px", maxWidth: "50px" },
      },
    },
    {
      name: "jobPlanDescription",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "700px", maxWidth: "700px" },
        }),
      },
    },
    {
      name: "costCentreDescription",
      label: "Cost Center",
      options: {
        filter: true,
        sort: true,
        style: { minWidth: "50px", maxWidth: "50px" },
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              className="link"
              target="_self"
              to={`/cost-centre/${JobPlansArr[tableMeta.rowIndex].costCentreId}/items`}
              style={{
                textDecoration: "none",
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "jobPlanId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (jobPlanId, tableMeta) => {
          return JobPlansArr[tableMeta.rowIndex].editable ? (
            <ButtonGroup variant="contained" size="small">
              <Tooltip title="EDIT JOB PLAN" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  target="_self"
                  //to={`/job-plan/${jobPlanId}/items`}
                  onClick={() => history.push(`/job-plan/${jobPlanId}/items`)}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>

              <Tooltip title="COPY JOB PLAN" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  style={{
                    border: "1px solid #1976d2",
                    backgroundColor: "#EDF5FD",
                  }}
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    handleCopyFromJobPlan(tableMeta.rowIndex);
                  }}
                >
                  <FileCopyIcon fontSize="small" style={{ color: "#1976d2" }} />
                </Button>
              </Tooltip>
              <Tooltip title="DELETE JOBPLAN" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={jobPlanId}
                  onClick={(e) => handleDelete(jobPlanId)}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained" size="small">
              <Tooltip title="VIEW JOB PLAN" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  target="_self"
                  //to={`/job-plan/${jobPlanId}/items`}
                  onClick={() => history.push(`/job-plan/${jobPlanId}/items`)}
                >
                  <VisibilityIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(JOB_PLAN_WRITE) ? true : false,
      },
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [JobPlansArr, setJobPlansArr] = useState([]);
  const [delLoading, setDelLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [load, setLoad] = useState(false);
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  const [defaultObjForAutocomplete, setDefaultObjForAutocomplete] = useState(null);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [roundLoader, setRoundLoader] = useState("");
  const [buttonPressFrom, setButtonPressFrom] = useState("");
  const [state, setState] = React.useState({
    right: false,
  });

  const handleCopyFromJobPlan = (rowIndex) => {
    setButtonPressFrom("copyFromJobplan");
    console.log(rowIndex);
    setDefaultObjForAutocomplete(JobPlansArr[rowIndex]);
    setState({ ...state, ["right"]: true });
  };
  /* ------------------------------------------------------------- */

  useEffect(() => {
    // if (wantToCallGetJobPlan) {
    if (hasValidAccess(JOB_PLAN_DELETE)) {
      setSelectableRows("single");
    }
    getJobplans(setJobPlansArr, setLoad); //get estimates
    // }
    // getClients(setCustomer, setLoad); //get client name
  }, [wantToCallGetJobPlan]);
  /* ------------------------------------------------------------- */
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);
  /* ------------------------------------------------------------- */

  /* useState for DataTable */
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsOnClick: false,
    // selectableRows: "single",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    // },
    onTableChange: (action, state) => {},
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {

    // },
    // onRowsDelete: (rowsDeleted, newData) => {
    //   handleDelete(rowsDeleted, newData);
    // },
  };

  /* ------------------------------------------------------------- */

  const [deleteId, setDeleteId] = useState([]);
  const handleOpenCreateNewJobPlanDialog = (e) => {
    setButtonPressFrom("newPlanFromJobplan");
    setState({ ...state, ["right"]: true });
    setDefaultObjForAutocomplete(null);
  };
  /* ------------------------------------------------------------- */

  const handleDelete = (jobPlanId) => {
    setDeleteId(jobPlanId);
    setConfirmDelete(true);
  };
  /* ------------------------------------------------------------- */

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  /* ------------------------------------------------------------- */
  const [newCostCentreId, setNewCostCentrID] = useState();
  const [loadResponse, setLoadResponse] = useState(false);
  /* ------------------------------------------------------------- */

  return (
    <div>
      {roundLoader && <SimpleBackdrop />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {delLoading && <SimpleBackdrop />}
          {state.right === true && (
            <CreateNewJobPlanDialog
              costCentreId={newCostCentreId}
              setLoadResponse={setLoadResponse}
              setWantToCallGetJobPlan={setWantToCallGetJobPlan}
              wantToCallGetJobPlan={wantToCallGetJobPlan}
              state={state}
              setState={setState}
              buttonCameFrom={"jobPlan"}
              defaultObjForAutocomplete={defaultObjForAutocomplete}
              setDefaultObjForAutocomplete={setDefaultObjForAutocomplete}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              setOpenSnackbar={setOpenSnackbar}
              buttonPressFrom={buttonPressFrom}
            />
          )}
          <Grid container justifyContent={"flex-end"}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.button}
              onClick={(e) => handleOpenCreateNewJobPlanDialog(e)}
            >
              New Job Plan
            </Button>
          </Grid>
          <br />
          {load ? (
            <ProgressBar />
          ) : (
            <React.Fragment>
              {JobPlansArr.length === 0 ? ( //if estimates are empty then empty msg is shown
                <EmptyEstimatesDialog
                  heading="Empty Job Plans"
                  msg="You don't have any JobPlans here yet.
                              Please create New Job Plan"
                />
              ) : (
                <Grid>
                  <MUIDataTable data={JobPlansArr} columns={columns} options={options} />
                </Grid>
              )}

              <AlertPopJobPlans
                confirmDelete={confirmDelete}
                setConfirmDelete={setConfirmDelete}
                deleteId={deleteId}
                setOpenSnackbar={setOpenSnackbar}
                setJobPlansArr={setJobPlansArr}
                JobPlansArr={JobPlansArr}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setRoundLoader={setRoundLoader}
              />

              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <CommonInfo title={infoArray.jobs.title} msg={infoArray.jobs.msg} />
        </Grid>
      </Grid>
    </div>
  );
}
