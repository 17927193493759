/** @format */

import apis from "../../../utils/apis";

export const getCostingsbyEstimateID = (
  setRows,
  estimateID,
  setLoad,
  setTempRows
) => {
  // alert(estimateID)
  // if (!estimateID) {
  //   window.location.replace('/estimate/nikita');
  // }
  setLoad(true);
  apis
    .GET({
      endpoint: `/estimate/line-items/${estimateID}`,
    })
    .then((res) => {
      setLoad(false);

      res.data.sort(function (a, b) {
        return a.categorySequenceId - b.categorySequenceId;
      });

      setRows(res.data);
      setTempRows(res.data);

      console.log("costing rows ", res.data);
    })
    .catch((err) => console.log("No costings data "));
};

export const createCostings = (
  setRows,
  estimateID,
  arr,
  setDataLoaded,
  setOpen,
  setDataPostSuccess,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  console.log("len in api ", arr);
  setOpen(false);
  setDataLoaded(true);
  apis
    .PUT({
      endpoint: `/estimate/line-items/${estimateID}`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setDataLoaded(false);
      setOpen(true);
      setRows(arr);
      setSnackbarMSG("Costing Updated successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      if (res.data.message == "SUCCESS") {
        if (arr[arr.length - 1].lineItems.length != 0) {
          setDataPostSuccess(true);
        }
        console.log("data posting success >>>", arr);
      } else {
        console.log("clients posting failed>>>");
      }
    });
};

export const getEstimateLineItemsList = (
  setItemList,
  estimateId,
  setLineItemLoad
) => {
  setLineItemLoad(true);
  apis
    .GET({
      endpoint: `/estimate/line-items/list/${estimateId}`,
    })
    .then((res) => {
      setItemList(res.data);
      setLineItemLoad(false);
    })
    .catch((err) => console.log("No building data "));
};
