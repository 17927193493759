/** @format */

import apis from "../../utils/apis";

export const getUserConfigsTypes = (setUserConfigs, setLoad) => {
  //setLoad(true);
  apis
    .GET({
      endpoint: "/config/MENU_ITEMS/TYPES",
    })
    .then((res) => {
      if (res) {
        setUserConfigs(res.data);
      } else {
        setUserConfigs({});
      }

      //setLoad(false);
    });
};

export const getUserConfigsUom = (setUserConfigsuom, setLoad) => {
  setLoad(true);
  apis
    .GET({
      endpoint: "/config/MENU_ITEMS/UOM",
    })
    .then((res) => {
      if (res) {
        setUserConfigsuom(res.data);
      } else {
        setUserConfigsuom({});
      }

      setLoad(false);
    });
};

// ----------------------Data-validation--------------------------------------------------
export const checkProperties = (obj) => {
  let temp = "";
  let keys = "";
  for (var key in obj) {
    if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
      if (Array.isArray(obj[key])) {
        obj[key].length < 1 ? (keys = keys + "LineItems,") : (temp = checkProperties(obj[key]));
      } else {
        temp = checkProperties(obj[key]);
        if (temp !== "") keys = keys + temp;
      }
    } else if (obj[key] == null || obj[key] == "" || obj[key] == "0") {
      if (
        key != "itemCategory" &&
        key != "itemGroupCode" &&
        key != "jobLineItemId" &&
        key != "send" &&
        key != "message" &&
        key != "comment" &&
        key != "statusDate" &&
        key != "variationLineItemId" &&
        key != "jobLineItemId" &&
        key != "itemId" &&
        key != "optionId" &&
        key != "optionName" &&
        key != "details" &&
        key != "markupPercentage" &&
        key != "gst" &&
        key != "copyFromJobPlanId" &&
        key != "copyFromCostCentreId" &&
        key != "notes" &&
        key != "comments" &&
        key != "referenceQuotes" &&
        key != "referenceCatalogue" &&
        key != "termsAndConditionsTemplateId"
      )
        keys = keys + `${key},`;
    }
  }
  return keys;
};
// ----------------------------------------------------------------------------------------
export const getGst = (input) => {
  if (input === true) {
    return 10;
  } else {
    return 0;
  }
};
// ----------------------------------------------------------------------------------------
export const checkDate = (expiryDate) => {
  // var current = new Date();
  // var diff = 0;
  // var days = 1000 * 60 * 60 * 24;
  // let expry = new Date(expiryDate);
  // diff = expry.getTime() - current.getTime();
  // let deadLineDays = Math.ceil(diff / days);
  // console.log("deadLineDays", deadLineDays);
  // return deadLineDays;
  const currentDate = new Date();
  const expirationDate = new Date(expiryDate);

  // Compare the current date with the expiration date
  return currentDate > expirationDate;
};
// ----------------------------------------------------------------------------------------
export const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const year = date.getFullYear();

  // Pad the day and month with leading zeroes if needed
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return `${formattedDay}/${formattedMonth}/${year}`;
};
