/** @format */

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import { updateCatalogueItemsByCatalogueId } from "./catalogue.helper";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import CreateIcon from "@mui/icons-material/Create";
import NumberFormat from "react-number-format";
import { Tooltip, Zoom } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { getGst } from "../UI/GlobalApi";
import PercentIcon from "@mui/icons-material/Percent";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
export default function GroupItemsTable({
  rows,
  groupName,
  groupCode,
  catalogueId,
  contactId,
  loadSave,
  groupItems,
  setGroupItems,
  setRows,
  userConfigsTypes,
  userConfigsUom,
  setLoadSave,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setTempGroups,
  editableCatalogue,
}) {
  console.log("editableCatalogue Gro Table:", editableCatalogue);

  const classes = useStyles();
  const [inputList, setInputList] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [itemRowToDelete, setItemRowToDelete] = React.useState();

  //   --------------------------------------------------------------------------------------
  const handleAdd = () => {
    setEdit(true);
    //following code is to generate itemCode automatically
    if (inputList.length === 0) {
      var lastBeforeRowLineId = 0;
    } else {
      var lastBeforeRowLineId = inputList[inputList.length - 1].itemCode;
    }
    // --------------------------------------------------------
    var newElement = {
      itemId: null,
      itemCode: "",
      itemName: "",
      itemGroupCode: groupCode,
      itemGroupName: groupName,
      itemDescription: "",
      itemCategory: groupName,
      type: "",
      qty: 1,
      uom: "",
      unitCost: 0,
      markupPercentage: 0,
      variancePercentage: 0,
      gst: true,
    };
    console.log("id", newElement);
    setInputList([...inputList, newElement]);
  };
  //   --------------------------------------------------------------------------------------
  const handleCancel = () => {
    setInputList(groupItems); /* Reset our old data after cancel edit/add operation  */
    setGroupItems(groupItems);
    setEdit(false);
  };
  //   --------------------------------------------------------------------------------------
  const handleEdit = (i) => {
    setEdit(true);
  };
  //   --------------------------------------------------------------------------------------

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(inputList));
    if (name === "unitCost" || name === "qty" || name === "markupPercentage" || name === "variancePercentage") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    setInputList(list);
  };

  //   --------------------------------------------------------------------------------------
  const handleChangeGST = (e, i) => {
    console.log("e.target.checked:", e.target.checked);
    console.log("e.target.name:", e.target.name);
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(inputList));
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };

  /* ---------------------------------------------------------------- */

  function updateValuesInRows(finalGroupsItems) {
    rows.filter((item) => {
      finalGroupsItems.filter((el) => {
        if (item.groupCode === el.itemGroupCode && item.groupName === el.itemGroupName) {
          item.items = finalGroupsItems.map((item) => {
            return {
              ...item,
              unitCost: parseFloat(item.unitCost),
              qty: parseFloat(item.qty),
              variancePercentage: parseFloat(item.variancePercentage),
              markupPercentage: parseFloat(item.markupPercentage),
            };
          });
        }
      });
    });
    updateCatalogueItemsByCatalogueId(
      catalogueId,
      contactId,
      setRows,
      rows,
      setOpenSnackbar,
      setTempGroups
    );
  }

  const handlesLineItemSave = () => {
    var finalGroupsItems = inputList.filter((el) => {
      if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
        return el;
      }
    });
    console.log("finalGroupsItems:", finalGroupsItems);
    setSnackbarMSG("Items saved successfully!");
    setSnackbarSeverity("success");
    setGroupItems(finalGroupsItems);
    updateValuesInRows(finalGroupsItems);
    setEdit(false);
  };
  //   --------------------------------------------------------------------------------------

  useEffect(() => {
    setInputList(JSON.parse(JSON.stringify(groupItems)));
  }, [groupItems]);
  //   --------------------------------------------------------------------------------------
  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setInputList(deletedData);
  };

  function filterInputListForClearAndDeleteLineItem(deleteId) {
    let filterList = inputList.filter((el) => {
      return deleteId.itemCode != el.itemCode;
    });

    let count = 1;
    var test = filterList.filter(function (el) {
      el.itemCode = count++;
      return el;
    });
    return test;
  }

  /* ---------------------------------------------------------------- */

  const handleConfirm = (row) => {
    console.log("setItemRowToDelete ", row);
    setItemRowToDelete(row);
    setShowConfirm(true);
  };
  const handleNo = () => {
    setShowConfirm(false);
  };
  /* ---------------------------------------------------------------- */
  const deleteItemNonEditMode = () => {
    setSnackbarMSG("Item deleted successfully!");
    setSnackbarSeverity("success");
    let deletedData = filterInputListForClearAndDeleteLineItem(itemRowToDelete);
    updateValuesInRows(deletedData);
    setGroupItems(deletedData); //it is for permenetly store data after api call
    setShowConfirm(false);
  };
  /* ---------------------------------------------------------------- */

  return (
    <Box>
      <Table size="small" aria-label="a dense table">
        {loadSave && <SimpleBackdrop />}
        <TableBody style={{ boxShadow: "none" }}>
          <Box margin={0.5}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box component="span" p={1}>
                    {inputList.length !== 0 ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        {isEdit ? (
                          <ButtonGroup variant="contained" aria-label="contained primary button group">
                            <Button
                              disabled={!editableCatalogue}
                              variant="contained"
                              color="primary"
                              onClick={handleAdd}
                              size="small"
                            >
                              <AddIcon fontSize="small" />
                              ADD
                            </Button>
                            <Button
                              disabled={!editableCatalogue}
                              variant="contained"
                              color="secondary"
                              align="right"
                              onClick={handleCancel}
                              size="small"
                            >
                              <ClearIcon fontSize="small" />
                              Cancel
                            </Button>
                            <Button
                              disabled={!editableCatalogue}
                              variant="contained"
                              color="primary"
                              onClick={handlesLineItemSave}
                              size="small"
                            >
                              <CheckCircleOutlineIcon fontSize="small" />
                              SAVE
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ButtonGroup variant="contained" aria-label="contained primary button group">
                            <Button
                              disabled={!editableCatalogue}
                              variant="contained"
                              color="primary"
                              onClick={handleAdd}
                              size="small"
                            >
                              <AddIcon fontSize="small" />
                              ADD
                            </Button>
                            <Button
                              disabled={!editableCatalogue}
                              variant="contained"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                              size="small"
                            >
                              <CreateIcon fontSize="small" />
                              EDIT
                            </Button>
                          </ButtonGroup>
                        )}
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                          <AddIcon fontSize="small" />
                          ADD
                        </Button>
                      </ButtonGroup>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            {inputList.length === 0 ? (
              <EmptyEstimatesDialog
                heading="Add some Catalogue lineitems in this category"
                msg="Add some lineitems here to Save this Catalogue type. You can add using the side button present on the right side the screen"
              />
            ) : (
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
                id="estimationCosting"
                className={" estimationCosting"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: "10px" }} align="center">
                      Code
                    </TableCell>
                    <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                    <TableCell sx={{ minWidth: 400 }}>Description</TableCell>
                    <TableCell align="center" sx={{ minWidth: 200 }}>
                      Type
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: 75 }}>
                      QTY
                    </TableCell>
                    <TableCell align="center" sx={{ minWidth: 115 }}>
                      UOM
                    </TableCell>
                    <TableCell align="right" sx={{ minWidth: 75 }}>
                      Unit Cost
                    </TableCell>
                    <TableCell align="right">Markup</TableCell>
                    <TableCell align="right">Variance</TableCell>
                    <TableCell align="right">GST</TableCell>
                    {inputList.length > 1 && <TableCell align="center">Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputList.map((row, i) => {
                    return (
                      <React.Fragment>
                        {isEdit ? (
                          <React.Fragment>
                            <TableRow key={row.itemId}>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.itemCode}
                                  name="itemCode"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.itemName}
                                  name="itemName"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.itemDescription}
                                  name="itemDescription"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  name="type"
                                  value={row.type}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  {userConfigsTypes.config?.length != 0 &&
                                    userConfigsTypes.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  width="20px"
                                  size="small"
                                  type="number"
                                  value={row.qty}
                                  name="qty"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  name="uom"
                                  value={row.uom}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  {userConfigsUom.config?.length != 0 &&
                                    userConfigsUom.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  step={1.0}
                                  value={row.unitCost}
                                  name="unitCost"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  type="number"
                                  value={row.markupPercentage}
                                  name="markupPercentage"
                                  onChange={(e) => handleInputChange(e, i)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <PercentIcon
                                          style={{
                                            fontSize: "15px",
                                            margin: "-10px",
                                            color: "3A3B3C",
                                            border: "redius",
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={row.variancePercentage}
                                  name="variancePercentage"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {row.gst === true ? (
                                  <Checkbox
                                    size="small"
                                    value={10}
                                    name="gst"
                                    defaultChecked
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    name="gst"
                                    value={0}
                                    // defaultChecked
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                              </TableCell>
                              {isEdit && (
                                <>
                                  {inputList.length > 1 && (
                                    <TableCell align="center">
                                      <ButtonGroup variant="contained" size="small">
                                        <Button
                                          size="small"
                                          className="mr10"
                                          color="secondary"
                                          key={row.itemId}
                                          onClick={(e) => clearLineItem(row)}
                                        >
                                          <ClearIcon fontSize="small" />
                                        </Button>
                                      </ButtonGroup>
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </TableRow>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableRow key={row.itemId}>
                              <TableCell align="center">{row.itemCode}</TableCell>
                              <TableCell>{row.itemName}</TableCell>
                              <TableCell>{row.itemDescription} </TableCell>
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell align="right">{row.qty}</TableCell>
                              <TableCell align="center">{row.uom}</TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(row.unitCost * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(row.markupPercentage * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(row.variancePercentage * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>

                              <TableCell align="right">
                                <NumberFormat
                                  value={(getGst(row.gst) * 1).toFixed(1)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />
                              </TableCell>
                              {inputList.length > 1 && (
                                <TableCell align="center">
                                  <ButtonGroup
                                    variant="contained"
                                    aria-label="outlined primary button group"
                                  >
                                    <Tooltip
                                      title="DELETE LINE ITEM"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <Button
                                        disabled={!editableCatalogue}
                                        size="small"
                                        className="mr10"
                                        color="secondary"
                                        key={row.itemId}
                                        onClick={(e) => handleConfirm(row)}
                                      >
                                        <Delete fontSize="small" />
                                      </Button>
                                    </Tooltip>
                                  </ButtonGroup>
                                </TableCell>
                              )}
                            </TableRow>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Box>
        </TableBody>
      </Table>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteItemNonEditMode} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Box>
  );
}
