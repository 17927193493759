/** @format */

// import React,{useState} from 'react';
import React, { useState, useEffect } from "react";
import CreateIcon from "@mui/icons-material/Create";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import { createTakeoff, getTakeoffsByEstimatesbyID } from "./takeoff.helpers";
import Alert from "@mui/material/Alert";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: 650,
  },
});
function TakeoffBody({ rows, setRows, estimateID }) {
  const [newRowsAfterDel, setnewRowsAfterDel] = React.useState([]);
  const [isEdit, setEdit] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [dummydel, setDummydel] = React.useState(false);

  console.log("Rows in Takeof Body:", rows);

  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCloseDel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDummydel(false);
  };
  const handleAdd = () => {
    setRows([...rows, { id: rows.length + 1, description: "", measurement: 0, uom: "" }]);
    setEdit(true);
  };
  const handleEdit = (i) => {
    setEdit(!isEdit);
  };
  const handleCancel = (i) => {
    setEdit(!isEdit);
    getTakeoffsByEstimatesbyID(setRows, setLoad, estimateID);
  };
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    createTakeoff(rows, setLoad, estimateID, setOpen);
    setDisable(true);
    // setOpen(true);
  };
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };
  const handleConfirm = (row, i) => {
    setShowConfirm(true);
    var listfilter = rows.filter(function (el) {
      return el.id != row.id && el.id != null;
    });
    setnewRowsAfterDel(listfilter);
  };
  const handleRemoveClick = (row, i) => {
    setRows(newRowsAfterDel);
    // setOpen(false);
    createTakeoff(newRowsAfterDel, setLoad, estimateID, setDummydel);
    setOpen(false);
    setConfirmDelete(false);
    setShowConfirm(false);
    setnewRowsAfterDel([]);
  };

  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
    setnewRowsAfterDel([]);
  };

  return (
    <React.Fragment>
      {load && <SimpleBackdrop />}
      <Paper
        className={classes.paper}
        elevation={0}
        style={{
          height: "auto !important",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} component="span" mb={1}>
          <ButtonGroup variant="contained" size="medium" aria-label="contained primary button group">
            <Button color="primary" size="small" onClick={handleAdd}>
              <AddIcon onClick={handleAdd} />
              ADD
            </Button>

            {isEdit ? (
              <React.Fragment>
                {rows.length !== 0 && (
                  <>
                    {disable ? (
                      <Button disabled align="right" onClick={handleSave}>
                        <DoneIcon />
                        SAVE
                      </Button>
                    ) : (
                      <Button align="right" color="secondary" onClick={handleSave}>
                        <DoneIcon />
                        SAVE
                      </Button>
                    )}
                    <Button color="secondary" align="right" onClick={handleCancel}>
                      Cancel
                      <ClearIcon fontSize="small" />
                    </Button>
                  </>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {rows.length !== 0 && (
                  <Button align="right" onClick={handleEdit}>
                    <CreateIcon fontSize="small" />
                    EDIT
                  </Button>
                )}
              </React.Fragment>
            )}
          </ButtonGroup>
        </Box>

        <Divider />
        {rows.length < 1 ? (
          <EmptyEstimatesDialog
            heading="Empty Takeoffs"
            msg="You don't have any takeoffs here yet.
Click the 'ADD' button above to create new takeoff"
          />
        ) : (
          <TableContainer component={Paper}>
            <Table
              size="small"
              className={classes.table}
              // size="small"
              // aria-label="a dense table"
            >
              <TableHead borderBottom={1}>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Measurement</TableCell>
                  <TableCell>UOM</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <React.Fragment>
                      <TableRow>
                        {isEdit ? (
                          <React.Fragment>
                            <TableCell>
                              <TextField
                                variant="outlined"
                                className={classes.textField}
                                size="small"
                                fullWidth
                                value={row.description}
                                name="description"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                                fullWidth
                                value={row.measurement}
                                name="measurement"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            <TableCell>
                              <Select
                                className={classes.textField}
                                size="small"
                                fullWidth
                                style={{
                                  width: "80px",
                                }}
                                name="uom"
                                value={row.uom}
                                onChange={(e) => handleInputChange(e, i)}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="m3">m3</MenuItem>
                                <MenuItem value="m2">m2</MenuItem>
                                <MenuItem value="ea">ea</MenuItem>
                                <MenuItem value="lm">lm</MenuItem>
                              </Select>
                            </TableCell>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.measurement}</TableCell>
                            <TableCell>{row.uom}</TableCell>
                          </React.Fragment>
                        )}
                        <TableCell>
                          {isEdit ? (
                            <Button className="mr10" onClick={() => handleConfirm(row, i)}>
                              <DeleteOutlineIcon />
                            </Button>
                          ) : (
                            <Button className="mr10" onClick={() => handleConfirm(row, i)}>
                              <DeleteOutlineIcon />
                            </Button>
                          )}
                        </TableCell>
                        {showConfirm && (
                          <div>
                            <Dialog
                              open={showConfirm}
                              onClose={handleNo}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure to delete
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleRemoveClick(row, i)}
                                  color="primary"
                                  autoFocus
                                >
                                  Yes
                                </Button>
                                <Button onClick={handleNo} color="primary" autoFocus>
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        )}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} className={classes.snackbar}>
        <Alert onClose={handleClose} severity="success" variant="filled">
          Takeoffs saved successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={dummydel}
        autoHideDuration={2000}
        onClose={handleCloseDel}
        className={classes.snackbar}
      >
        <Alert onClose={handleClose} severity="error" variant="filled">
          Takeoff deleted successfully!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default TakeoffBody;
