/** @format */

import {
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { TableBody } from "material-ui-core";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { set } from "date-fns";

function TemplateLineItemsTable({ newTemplateData, setNewTemplateData, setShowSaveButton }) {
  const [isEdit, setIsEdit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [templateItems, setTemplateItems] = React.useState([...newTemplateData?.termsAndConditions]);
  const [draftItems, setDraftItems] = React.useState([]);

  const handleAdd = () => {
    var list = JSON.parse(JSON.stringify(templateItems));
    list.push("");
    setTemplateItems(list);
    setIsEdit(true);
    setShowSaveButton(false);
  };
  // --------------------------------------------------------------------------------------
  const handleCancel = () => {
    setTemplateItems(draftItems); /* Reset our old data after cancel edit/add operation  */
    setIsEdit(false);
    setShowSaveButton(true);
  };
  // --------------------------------------------------------------------------------------
  const handlesLineItemSave = () => {
    setNewTemplateData({
      ...newTemplateData,
      termsAndConditions: templateItems,
    });
    setDraftItems(JSON.parse(JSON.stringify(templateItems)));
    setIsEdit(false);
    setShowSaveButton(true);
  };

  // --------------------------------------------------------------------------------------
  const handleEdit = () => {
    setDraftItems(JSON.parse(JSON.stringify(templateItems)));
    setIsEdit(true);
    setShowSaveButton(false);
  };
  // --------------------------------------------------------------------------------------
  const handleInputChange = (e, index) => {
    console.log("INDEX::", index);
    const { name, value } = e.target;
    console.log("value::", value);

    var list = JSON.parse(JSON.stringify(templateItems));
    list[index] = value;
    console.log("list::", list);
    setTemplateItems(list);
  };
  // --------------------------------------------------------------------------------------
  const handleClearLineItem = (index) => {
    let filterList = templateItems.filter((el, i) => {
      return index != i;
    });
    setTemplateItems(filterList);
  };
  // --------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {isEdit ? (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            align="right"
            onClick={handleCancel}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </Button>
          <Button variant="contained" color="primary" onClick={handlesLineItemSave} size="small">
            <SaveIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      ) : (
        <ButtonGroup
          variant="contained"
          aria-label="contained primary button group"
          align="right"
          style={{ display: "block", marginBottom: "10px" }}
        >
          <Button variant="contained" color="primary" align="right" onClick={handleAdd} size="small">
            <AddIcon fontSize="small" />
          </Button>
          <Button variant="contained" size="small" color="primary" align="right" onClick={handleEdit}>
            <EditIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      )}
      {templateItems.length < 1 ? (
        <EmptyEstimatesDialog
          heading="Empty Terms & Conditions"
          msg="To add Terms & Conditions click on add icon abouv"
        />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "20px" }}>No.</TableCell>
                <TableCell sx={{ minWidth: 200 }} align="left">
                  Name
                </TableCell>
                {isEdit && <TableCell align="center">Action</TableCell>}
              </TableRow>
            </TableHead>

            {isEdit ? (
              <TableBody align={"center"}>
                {templateItems.map((item, i) => {
                  return (
                    <TableRow>
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          "& input": {
                            textAlign: "left",
                            minWidth: 200,
                          },
                        }}
                      >
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="medium"
                          type="text"
                          value={templateItems[i]}
                          key={i}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ButtonGroup variant="contained" size="small">
                          <Button
                            size="small"
                            color="secondary"
                            className="mr10"
                            onClick={(e) => handleClearLineItem(i)}
                          >
                            <ClearIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                {templateItems.map((row, i) => {
                  return (
                    <TableRow>
                      <TableCell>{i + 1} </TableCell>
                      <TableCell>{templateItems[i]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}

      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}

export default TemplateLineItemsTable;
