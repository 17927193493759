/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getClients } from "./clients.helpers";
import ClientForm from "./ClientForm";
import AlertPopClient from "./AlertPopClient";
import { Grid, Snackbar } from "@mui/material";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import EditIcon from "@mui/icons-material/Edit";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import { useForm, Controller } from "react-hook-form";
import { Tooltip, Zoom } from "@mui/material";

/* '''''''''''''' */
import MUIDataTable from "mui-datatables";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { CLIENT_DELETE, CLIENT_WRITE } from "../UI/GlobalVariables";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  // Drawerroot: {
  //   width: 600,
  //   padding: 16,
  // },
  // textField: {
  //   width: "100%",
  // },
  // button: { padding: "7px", paddingRight: "9px", paddingLeft: "9px" },
}));

export default function ClientDetails() {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const { emailAddress } = useParams();
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSaveClicked, setisSaveClicked] = useState(true);
  const [load, setLoad] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  /* DataTable */
  const columns = [
    {
      name: "clientNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "clientType",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "emailAddress",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cityOrTown",
      label: "City",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "clientId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              {hasValidAccess(CLIENT_WRITE) && (
                <Tooltip title="Edit" placement="top" TransitionComponent={Zoom} arrow>
                  <Button size="small" color="primary" onClick={() => handleUpdate(tableMeta.rowIndex)}>
                    <EditIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}
            </ButtonGroup>
          );
        },
      },
    },
  ];

  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);

  //   setSelectableRows("single");
  // }
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,

    // selectableRows,
    // selectableRowsOnClick: false,
    selectableRows: selectableRows,
    // selectableRowsHideCheckboxes: true,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },

    onTableChange: (action, state) => {},

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {

    // },
    onRowsDelete: (rowsDeleted, newData) => {
      handleDelete(rowsDeleted, newData);
    },
  };
  useEffect(() => {
    getClients(setData, setLoad, emailAddress);
    if (hasValidAccess(CLIENT_DELETE)) {
      setSelectableRows("single");
    }
    console.log("get client calling");
  }, [wantToCallGet]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleOpenDrawer = () => {
    setState({ ...state, ["right"]: true });
    setisSaveClicked(true);
    reset({});
  };
  const [deleteId, setDeleteId] = useState([]);
  const handleDelete = (rowsDeleted, newData) => {
    if (hasValidAccess(CLIENT_DELETE)) {
      setConfirmDelete(true);
      var removedClientId;
      rowsDeleted.data.map((row, i) => {
        removedClientId = data[row.index].clientId;
      });
      setDeleteId(removedClientId);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleUpdate = (arrayIndex) => {
    setisSaveClicked(false);
    setState({ ...state, ["right"]: true });
    reset(data[arrayIndex]);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {delLoading && <SimpleBackdrop />}
          {/* {hasValidAccess(CLIENT_WRITE) && (
            <>
               <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<AddCircleSharpIcon />}
                  onClick={handleOpenDrawer}>
                  New Client
                </Button>
              </div>
           <ClientForm
                toggleDrawer={toggleDrawer}
                state={state}
                setState={setState}
                isSaveClicked={isSaveClicked}
                setisSaveClicked={setisSaveClicked}
                handleSubmit={handleSubmit}
                Controller={Controller}
                control={control}
                getValues={getValues}
                errors={errors}
                wantToCallGet={wantToCallGet}
                setWantToCallGet={setWantToCallGet}
              />
            </>
          )} */}

          <div style={{ marginTop: "19px" }}>
            {load ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {data.length === 0 ? ( //if estimates are empty then empty msg is shown
                  <EmptyEstimatesDialog
                    heading="Empty Clients"
                    msg="You don't have any clients here yet.
  Click the 'NEW CLIENT' button above to create new client"
                  />
                ) : (
                  <MUIDataTable data={data} columns={columns} options={options} />
                )}
                <AlertPopClient
                  confirmDelete={confirmDelete}
                  setConfirmDelete={setConfirmDelete}
                  deleteId={deleteId}
                  setData={setData}
                  setOpenSnackbar={setOpenSnackbar}
                  data={data}
                />
              </React.Fragment>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <CommonInfo title={infoArray.clients.title} msg={infoArray.clients.msg} />
        </Grid>
      </Grid>
    </div>
  );
}
