/** @format */

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import DeleteIcon from "@mui/icons-material/Delete";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import ProgressBar from "../../UI/ProgressBar";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import EditIcon from "@mui/icons-material/Edit";
import CreateJobDrawer from "../CreateJobDrawer";
import TextField from "@mui/material/TextField";
import { deleteJob, getJobs } from "../jobs.helper";
import { Tooltip, Zoom } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useForm, Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import Select from "@mui/material/Select";
import ErrorStyles from "../../UI/ErrorStyles";
import Divider from "@mui/material/Divider";
import DeleteAlertPop from "./DeleteAlertPop";
import CommonInfo from "../../info/CommonInfo";
import infoArray from "../../info/commonInfo.json";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import { JOB_DELETE, JOB_WRITE } from "../../UI/GlobalVariables";
import MomentDate from "../../UI/MomentDate";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { getEstimates } from "../../estimates/estimates.helpers";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { updateClientbyId, getClients } from "../../clients/clients.helpers";
const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 250,
  },
  oneRow: {
    // borderBottom: "none",
    // width: "10vw",
  },
  rowDark: {
    // color: "#fff",
    // fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    // cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
  cardtitle: {
    padding: theme.spacing(2),
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));
export default function JobsPage() {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [clientData, setData] = useState([]);
  const methos = useForm({
    defaultValues: {
      suggestedObject: {},
    },
    criteriaMode: "all",
  });
  const columns = [
    {
      name: "jobNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          console.log("tableMeta", tableMeta);
          console.log("jobsArr", jobsArr[tableMeta.rowIndex]);
          console.log("value", value);

          console.log("jobsArr[tableMeta.rowIndex].estimateId", jobsArr[tableMeta.rowIndex].estimateId);
          if (searchEstimateById(jobsArr[tableMeta.rowIndex].estimateId)) {
            return (
              <Link
                className="link"
                target="_self"
                to={`/estimate/${jobsArr[tableMeta.rowIndex].estimateId}/costing`}
                style={{
                  textDecoration: "underline",
                }}
              >
                {value}
              </Link>
            );
          } else {
            return (
              <Link
                className="link"
                target="_self"
                style={{
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setOpenCustSnackbar(true);
                  setSnackbarMSG("Estimate Not Found");
                  setSnackbarSeverity("error");
                }}
              >
                {value}
              </Link>
            );
          }
          // return (
          //   <Link
          //     className="link"
          //     target="_self"
          //     to={`/estimate/${jobsArr[tableMeta.rowIndex].estimateId}/details`}
          //     style={{
          //       textDecoration: "underline",
          //     }}
          //   >
          //     {value}
          //   </Link>
          // );
        },
      },
    },
    {
      name: "client",
      label: "Client",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            let clientObj = {
              name: value?.name,
              clientType: value?.clientType,
              clientId: value?.clientId,
              address: value?.address,
              cityOrTown: value?.cityOrTown,
              state: value?.state,
              postcode: value?.postcode,
              phone: value?.phone,
              mobile: value?.mobile,
              postalAddress: value?.postalAddress,
              postalCityOrTown: value?.postalCityOrTown,
              postalState: value?.postalState,
              postalPostcode: value?.postalPostcode,
              Postalmobile: value?.Postalmobile,
              postalPhone: value?.postalPhone,
              emailAddress: value?.emailAddress,
            };
            return (
              <Link
                className="link"
                component={`${(clientObj?.name).replace(/\s/g, "")}`}
                pathname={`/${(clientObj?.name).replace(/\s/g, "")}`}
                target="_self"
                onClick={(e) => {
                  reset(clientObj);
                  setClientState({ right: true });
                }}
                style={{
                  textDecoration: "underline",
                  wordBreak: "break-all",
                  cursor: "pointer",
                  overflowWrap: "break-word",
                }}
              >
                {clientObj?.name}
              </Link>
            );
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "jobPlanName",
      label: "Job Plan",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "expectedStartDate",
      label: "Expected Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
      },
    },
    {
      name: "modifiedDateTime",
      label: "Current Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
      },
    },
    {
      name: "jobId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained" size="small">
              {jobsArr[tableMeta.rowIndex].status === "DRAFT" ? (
                <Tooltip title="VIEW JOB DETAILS" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="primary"
                    target="_self"
                    onClick={() => history.push(`/job/${value}/Costings`)}
                  >
                    <EditIcon fontSize="small" />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="VIEW JOB" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="primary"
                    target="_self"
                    onClick={() => history.push(`/job/${value}/Costings`)}
                  >
                    <VisibilityIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="DELETE JOB" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={value}
                  onClick={(e) => handleDelete(value)}
                >
                  <DeleteIcon value={value} fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(JOB_WRITE) ? true : false,
      },
    },
  ];

  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [jobsArr, setJobsArr] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [roundLoader, setRoundLoader] = useState(false);
  const [showPostelAddress, setShowPostelAddress] = useState(true);
  const [estimates, setEstimates] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [load, setLoad] = useState(false);
  const [dummyload, setDummyLoad] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  /* ---------------------------------------------------------------- */

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleClose = (e) => {
    setClientState({ ...clientState, ["right"]: false });
  };
  const [clientState, setClientState] = React.useState({
    right: false,
  });
  const toggleDrawerClient = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setClientState({ ...clientState, [anchor]: open });
  };
  const handleUpdateSubmit = (e) => {
    let clientDetails = getValues();
    let clientId = clientDetails.clientId;
    console.log(clientDetails, "clientDetails12");
    if (showPostelAddress) {
      clientDetails.Postalmobile = clientDetails?.mobile;
      clientDetails.postalAddress = clientDetails?.address;
      clientDetails.postalCityOrTown = clientDetails?.cityOrTown;
      clientDetails.postalState = clientDetails?.state;
      clientDetails.postalPostcode = clientDetails?.postcode;
      clientDetails.postalPhone = clientDetails?.phone;
    }
    /*deleting clientId of this object  */
    delete clientDetails["clientId"];
    updateClientbyId(
      clientId,
      clientDetails,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setClientState,
      setClientDetails,
      setData,
      clientData,
      setJobsArr,
      jobsArr
    );
    setShowPostelAddress(true);
  };
  const handleSamePostalAddress = () => {
    let ClientDetails = getValues();
    ClientDetails.Postalmobile = ClientDetails?.mobile;
    ClientDetails.postalAddress = ClientDetails?.address;
    ClientDetails.postalCityOrTown = ClientDetails?.cityOrTown;
    ClientDetails.postalState = ClientDetails?.state;
    ClientDetails.postalPostcode = ClientDetails?.postcode;
    ClientDetails.postalPhone = ClientDetails?.phone;
    setClientDetails(ClientDetails);

    console.log(ClientDetails, "ClientDetails");
    setShowPostelAddress(!showPostelAddress);
  };

  /* ---------------------------------------------------------------- */
  const [state, setState] = React.useState({
    right: true,
  });
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  // };
  var today = new Date();
  var now = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const [date, setDate] = useState(now);
  const history = useHistory();

  const searchEstimateById = (id) => {
    let flag;
    flag = estimates.findIndex((ele) => {
      return ele.estimateId === id;
    });
    if (flag === -1) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    getEstimates(setEstimates, setDummyLoad); //get estimates
    getJobs(setJobsArr, setLoad); //get estimates
    getClients(setData, setDummyLoad, setDummyLoad);

    // getClients(setCustomer, setLoad); //get client name
    if (hasValidAccess(JOB_DELETE)) {
      setSelectableRows("single");
    }
  }, []);
  /* useState for DataTable */

  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);

  /* useState for DataTable */
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsOnClick: false,
    // selectableRows: "single",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    // },
    onTableChange: (action, state) => {},

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {

    // },
    // onRowsDelete: (rowsdelID, newData) => {
    //   handleDelete(rowsdelID, newData);
    // },
  };

  const [deleteId, setDeleteId] = useState([]);
  const [newData, setNewData] = useState();

  const handleDelete = (jobId) => {
    setDeleteId(jobId);
    setConfirmDelete(true);
  };

  const handleLocation = (id) => {
    let location = {
      pathname: `/job/${id}`,
      // search: `?estimateId=${id}`,
      jobId: id,
    };
    history.push(location);
  };
  const handleDeleteIcon = (e, row) => {
    e.preventDefault();
    setConfirmDelete(true);
    console.log(row);
    // setDelId(row.estimateId);
  };
  const toggleDrawer = (open) => (event) => {
    setState({ right: open });
  };
  const [isJobCreated, setJobCreated] = useState(false);
  const [row, setRow] = React.useState([]);
  const [quote, setQuote] = useState(0);
  const [open, setOpen] = React.useState(true);
  const [createJobOpenDrawer, setCreateJobOpenDrawer] = useState(false);
  const [openCustSnackbar, setOpenCustSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  // const handleCreateJob = () => {
  //   setState({ right: open });
  //   setCreateJobOpenDrawer(true);
  //   let newObj = {
  //     description: row.description,
  //     job_type: row.jobType,
  //     customer_name: "",
  //     expected_start_date: undefined,
  //   };

  //   reset(newObj);
  // };

  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  function formatMobileNumber(number) {
    // Remove all non-numeric characters from the input
    const numericOnly = number.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 4 and format accordingly
    if (numericOnly.length <= 4) {
      return numericOnly;
    } else if (numericOnly.length <= 7) {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4)}`;
    } else {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4, 7)}-${numericOnly.slice(7, 10)}`;
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {delLoading && <SimpleBackdrop />}
          {/* <React.Fragment>
                {loading && <SimpleBackdrop />}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                        <Button
                            onClick={handleCreateJob}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<AddCircleSharpIcon />}>
                            Create New
                        </Button>
                        {createJobOpenDrawer && (
                            <CreateJobDrawer
                                state={state}
                                setState={setState}
                                handleSubmit={handleSubmit}
                                Controller={Controller}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                isDisabled={false}
                                quote={quote}
                                setJobCreated={setJobCreated}
                                setCreateJobOpenDrawer={setCreateJobOpenDrawer}
                                toggleDrawer={toggleDrawer}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment> */}
          <br />
          {load ? (
            <ProgressBar />
          ) : (
            <React.Fragment>
              {jobsArr.length === 0 ? ( //if estimates are empty then empty msg is shown
                <EmptyEstimatesDialog
                  heading="Empty Jobs"
                  msg="You don't have any estimates here yet.
Click the 'CREATE NEW' button above to get started, or choose from a template"
                />
              ) : (
                <MUIDataTable data={jobsArr} columns={columns} options={options} />
              )}
              {confirmDelete && (
                <Dialog
                  open={confirmDelete}
                  onClose={() => setConfirmDelete(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure to delete
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setConfirmDelete(false);
                        deleteJob(
                          deleteId,
                          jobsArr,
                          setJobsArr,
                          setDelLoading,
                          setOpenCustSnackbar,
                          setSnackbarMSG,
                          setSnackbarSeverity
                        );
                      }}
                      color="primary"
                      autoFocus
                    >
                      Yes
                    </Button>
                    <Button onClick={() => setConfirmDelete(false)} color="primary" autoFocus>
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </React.Fragment>
          )}{" "}
        </Grid>

        <Grid item xs={12}>
          <CommonInfo title={infoArray.jobs.title} msg={infoArray.jobs.msg} />
        </Grid>
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ marginTop: "5px", marginRight: "18px" }}>
              <Drawer
                anchor={"right"}
                open={clientState["right"]}
                onClose={toggleDrawerClient("left", false)}
                PaperProps={{
                  sx: {
                    width: "30%",
                  },
                }}
              >
                <div className={classes.Drawerroot}>
                  {roundLoader && <SimpleBackdrop />}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" color="primary" mb={1} style={{ fontSize: "20px" }}>
                        Update Client
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            label="Client Name"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.name?.type === "required" && "First name is required"}
                      </p>
                    </Grid>
                    <Grid item xs={12} my={-2}>
                      <InputLabel id="labelClientGroup">Client Type</InputLabel>
                      <Controller
                        name="clientType"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <Select {...field} className={classes.textField} size="small">
                            <MenuItem value="Archived">Archived</MenuItem>
                            <MenuItem value="Business">Business</MenuItem>
                            <MenuItem value="Lead">Lead</MenuItem>
                            <MenuItem value="Person">Person</MenuItem>
                            <MenuItem value="Customer">Customer</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.clientType && "Client Type required"}
                    </Grid>
                    <Grid item xs={12} mt={1.5}>
                      <Controller
                        name="address"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Address"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.address && "Address required"}
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="cityOrTown"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="City / Town"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />

                      {errors.cityOrTown && "City/Town required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="state"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="State"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.stateClient && "State required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postcode"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postcode"
                            type="number"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.postcode && "Postcode required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Phone"
                            size="small"
                            variant="outlined"
                            rules={{
                              required: false,
                              minLength: 9,
                              maxLength: 9,
                            }}
                            className={classes.textField}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatPhoneNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            value={field.value}
                          />
                        )}
                      />
                      {errors.phone && "Phone required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Mobile"
                            variant="outlined"
                            size="small"
                            rules={{
                              required: false,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatMobileNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.mobile?.type === "required" && "Mobile Number required"}
                        {errors.mobile?.type === "minLength" && "Please enter valid Mobile number"}
                        {errors.mobile?.type === "maxLength" && "Please enter valid Mobile number"}
                      </p>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: 0 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={handleSamePostalAddress}
                            defaultChecked
                            value={showPostelAddress}
                          />
                        }
                        label="Postal Address Same As Address"
                        size="small"
                      />
                    </Grid>
                    {!showPostelAddress && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name="postalAddress"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Address"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.address && "postalAddress required"}
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="postalCityOrTown"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal City / Town"
                                variant="outlined"
                                size="small"
                                className={classes.textField}
                              />
                            )}
                          />

                          {errors.postalCityOrTown && "City/Town required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalState"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal State"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.postalState && "postalState required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalPostcode"
                            control={control}
                            rules={{
                              required: false,
                            }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Post code"
                                type="number"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                              />
                            )}
                          />
                          {errors.postalPostcode && "PostalPostcode required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="postalPhone"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Phone"
                                size="small"
                                variant="outlined"
                                className={classes.textField}
                                rules={{
                                  required: false,
                                  minLength: 9,
                                  maxLength: 9,
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                                }}
                                onChange={(e) => {
                                  const formattedNumber = formatPhoneNumber(e.target.value);
                                  field.onChange(formattedNumber);
                                }}
                              />
                            )}
                          />
                          {errors.postalPhone && "postalPhone required"}
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name="Postalmobile"
                            control={control}
                            rules={{ required: false }}
                            className="input"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Postal Mobile"
                                variant="outlined"
                                size="small"
                                rules={{
                                  required: false,
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                                }}
                                onChange={(e) => {
                                  const formattedNumber = formatMobileNumber(e.target.value);
                                  field.onChange(formattedNumber);
                                }}
                                className={classes.textField}
                              />
                            )}
                          />
                          <p className={ErrorClasses.errorMsg}>
                            {errors.Postalmobile?.type === "required" && "Postalmobile Number required"}
                            {errors.Postalmobile?.type === "minLength" &&
                              "Please enter valid Mobile number"}
                            {errors.Postalmobile?.type === "maxLength" &&
                              "Please enter valid Mobile number"}
                          </p>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Controller
                        name="emailAddress"
                        control={control}
                        rules={{
                          required: true,
                          pattern:
                            /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Email"
                            size="small"
                            onChange={field.onChange}
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.emailAddress?.type === "required" && "Email required"}
                        {errors.emailAddress?.type === "pattern" && "Please enter a valid email"}
                      </p>
                    </Grid>
                    <Grid item xs={12} align="right">
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        onClick={handleUpdateSubmit}
                        variant="contained"
                      >
                        Update
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        style={{ marginLeft: "8px" }}
                        onClick={(e) => handleClose(e)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Drawer>
            </div>
          </div>
        </React.Fragment>
        {openCustSnackbar && (
          <CustomizedSnackbars
            openSnackbar={openCustSnackbar}
            setOpenSnackbar={setOpenCustSnackbar}
            snackbarMSG={snackbarMSG}
            severity={snackbarSeverity}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}
