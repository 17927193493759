/** @format */

export const dateFormat = "DD/MMM/YYYY";
export const currency = "₹";
/* ----------------PERMISSION------------------------ */
export const IS_ADMIN = "admin";
export const ESTIMATE_READ = "estimate:read";
export const ESTIMATE_WRITE = "estimate:write";
export const ESTIMATE_DELETE = "estimate:delete";
export const JOB_READ = "job:read";
export const JOB_WRITE = "job:write";
export const JOB_DELETE = "job:delete";
export const JOB_PLAN_READ = "job-plan:read";
export const JOB_PLAN_WRITE = "job-plan:write";
export const JOB_PLAN_DELETE = "job-plan:delete";
export const QUOTE_READ = "quote:read";
export const QUOTE_WRITE = "quote:write";
export const QUOTE_DELETE = "quote:delete";
export const CLIENT_READ = "client:read";
export const CLIENT_WRITE = "client:write";
export const CLIENT_DELETE = "client:delete";
export const CONTACT_READ = "business-contact:read";
export const CONTACT_WRITE = "contact:write";
export const CONTACT_DELETE = "contact:delete";
export const COST_CENTRE_READ = "cost-centre:read";
export const COST_CENTRE_WRITE = "cost-centre:write";
export const COST_CENTRE_DELETE = "cost-centre:delete";
export const PURCHASE_ORDER_READ = "purchase-order:read";
export const PURCHASE_ORDER_WRITE = "purchase-order:write";
export const PURCHASE_ORDER_DELETE = "purchase-order:delete";
export const WORK_ORDER_READ = "work-order:read";
export const WORK_ORDER_WRITE = "work-order:write";
export const WORK_ORDER_DELETE = "work-order:delete";
export const VARIATION_READ = "variation:read";
export const VARIATION_WRITE = "variation:write";
export const VARIATION_DELETE = "variation:delete";
export const USER_AND_ROLE_READ = "user-and-role:read";
export const USER_AND_ROLE_WRITE = "user-and-role:write";
export const USER_AND_ROLE_DELETE = "user-and-role:delete";
export const SETTING_READ = "settings:read";
export const SETTING_WRITE = "settings:write";
export const SETTING_DELETE = "settings:delete";
export const BUSINESS_ACCOUNT_READ = "business-account:read";
export const BUSINESS_ACCOUNT_WRITE = "business-account:write";
export const CATALOGUE_READ = "catalogue:read";
export const CATALOGUE_WRITE = "catalogue:write";
export const CATALOGUE_DELETE = "catalogue:delete";
