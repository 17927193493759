/** @format */

import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CostCentreLineItemTable from "./CostCentreLineItemTable";
import { updateItemsByCostCentreId } from "../cost-center/costcenter.helpers";
import { COST_CENTRE_DELETE, COST_CENTRE_WRITE } from "../UI/GlobalVariables";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { Tooltip, Zoom } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
export default function Row({
  row,
  setItems,
  setRows,
  rows,
  costCentreId,
  index,
  loadSave,
  setLoadSave,
  isEdit,
  setEdit,
  setDisableSave,
  setDataPostSuccess,
  openAllRows,
  setOpenAllRows,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setTempGroups,
  editableCostCenter,
  setRoundLoader,
}) {
  const useRowStyles = makeStyles({
    tableInnerContent: {
      paddingTop: "10px",
    },
  });
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(false);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [tempRowsData, setTempRowsData] = useState([]);
  const [dummy, setDummy] = useState([]);
  const classes = useRowStyles();

  /* ------------------------------------------------------------------- */

  const handleDelete = () => {
    var filterRows = rows.filter((el) => {
      if (el.groupCode != row.groupCode && el.groupName != row.groupName) {
        return el;
      }
    });

    let count = 1;
    var test = filterRows.filter(function (el) {
      el.groupCode = count++;
      return el;
    });
    updateItemsByCostCentreId(
      setRows,
      costCentreId,
      test,
      //setLoadSave,
      setDataPostSuccess,
      setOpenSnackbar,
      setSnackbarSeverity,
      setSnackbarMSG,
      setTempGroups,
      setRoundLoader
    );
    setShowConfirm(false);
  };
  /* ------------------------------------------------------------------- */

  const handleConfirm = () => {
    setShowConfirm(true);
  };
  const handleNo = () => {
    setShowConfirm(false);
  };

  /* ------------------------------------------------------------------- */

  useEffect(() => {
    row.items.sort(function (a, b) {
      return a.itemCode - b.itemCode;
    });
  }, [row]);
  const [costCentreLineItemArray, setCostCentreLineItemArray] = useState([]);

  /* --------------------For Expand and Collapse button-------------------------------- */
  useEffect(() => {
    if (openAllRows[index]) {
      setCostCentreLineItemArray(row.items);
    }
  }, [openAllRows]);

  const handleOpenCostinRow = () => {
    if (openAllRows[index]) {
      console.log("if cost centre handle row", openAllRows[index]);
      setOpenAllRows({ ...openAllRows, [index]: false });
    } else {
      console.log(" else cost centre handle row", openAllRows[index]);

      setCostCentreLineItemArray(row.items);
      setOpenAllRows({ ...openAllRows, [index]: true });
    }
  };
  /* ------------------------------------------------------------------- */
  const handleGroupSave = () => {
    console.log("SAVECLICK");
    setEditGroup(false);
    updateItemsByCostCentreId(
      setRows,
      costCentreId,
      rows,
      //setLoadSave,
      setDataPostSuccess,
      setOpenSnackbar,
      setSnackbarSeverity,
      setSnackbarMSG,
      setTempGroups,
      setRoundLoader
    );
  };
  // ------------------------------------------------------
  let rowEditable = true;
  row.items.filter((obj, index) => {
    if (obj.editable === false) {
      rowEditable = false;
    }
  });
  // ------------------------------------------------------
  const handleInputChange = (e, index) => {
    const list = JSON.parse(JSON.stringify(rows));
    list[index][e.target.name] = e.target.value;
    setRows(list);
  };
  /* ------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={{ cursor: "pointer" }}
        // onClick={handleOpenCostinRow}
        hover
      >
        <div>
          <TableCell
            width="2%"
            align="left"
            style={{
              border: "none",
              padding: "0px",
              paddingLeft: "10px",
              paddingTop: "5px",
            }}
          >
            <IconButton aria-label="expand row" size="small" onClick={handleOpenCostinRow}>
              {openAllRows[index] ? (
                <Tooltip title="CLOSE MORE LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                  <KeyboardArrowUpIcon />
                </Tooltip>
              ) : (
                <Tooltip title="VIEW MORE LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                  <KeyboardArrowDownIcon />
                </Tooltip>
              )}
            </IconButton>
          </TableCell>

          {editGroup ? (
            <>
              <TableCell
                width="5.3%"
                align="center"
                component="th"
                scope="row"
                style={{ border: "none" }}
              >
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  value={row.groupCode}
                  name="groupCode"
                  onChange={(e) => handleInputChange(e, index)}
                />
              </TableCell>
              <TableCell
                width="65%"
                align="center"
                component="th"
                scope="row"
                style={{ border: "none" }}
              >
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  size="small"
                  value={row.groupName}
                  name="groupName"
                  onChange={(e) => handleInputChange(e, index)}
                />
              </TableCell>
            </>
          ) : (
            <>
              <TableCell width="3%" align="center" component="th" scope="row" style={{ border: "none" }}>
                {row.groupCode}
              </TableCell>
              <TableCell width="65%" align="left" component="th" scope="row" style={{ border: "none" }}>
                {row.groupName}
              </TableCell>
            </>
          )}

          <TableCell align="center" style={{ border: "none" }} width="10%">
            {row.items.length} items
          </TableCell>
          {/* <TableCell align='left' style={{ border: "none" }}>
            $ {quoteTotalRows}
          </TableCell> */}
        </div>
        {hasValidAccess(COST_CENTRE_WRITE) && (
          <TableCell align="left" style={{ border: "none" }} width="7.6%">
            {rowEditable ? (
              !editGroup ? (
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Tooltip title="DELETE GROUP" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      disabled={!editableCostCenter}
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={handleConfirm}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Groups" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      disabled={!editableCostCenter}
                      size="small"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTempRowsData(rows);
                        setEditGroup(true);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              ) : (
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Tooltip title="CANCEL" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRows(tempRowsData);
                        setEditGroup(false);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="SAVE" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleGroupSave()}
                      size="small"
                    >
                      <SaveIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              )
            ) : (
              <IconButton disabled>
                <DeleteIcon style={{ cursor: "pointer" }} />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow p={1}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAllRows[index]} timeout="auto" unmountOnExit>
            <CostCentreLineItemTable
              costCentreLineItemArray={costCentreLineItemArray}
              setCostCentreLineItemArray={setCostCentreLineItemArray}
              tablerows={row.items}
              setItems={setItems}
              setRows={setRows}
              rows={rows}
              costCentreId={costCentreId}
              row={row}
              loadSave={loadSave}
              isEdit={isEdit}
              setEdit={setEdit}
              setDisableSave={setDisableSave}
              myrow={row}
              setTempGroups={setTempGroups}
              editableCostCenter={editableCostCenter}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
