/** @format */

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ProgressBar from "../UI/ProgressBar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import { CKEditor } from 'ckeditor4-react';
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import { getContacts, createContacts, updateContactByID } from "./contacts.helpers";
import { Box, Snackbar } from "@mui/material";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import AlertPopContact from "./AlertPopContact";
import { useForm, Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { Tooltip, Zoom } from "@mui/material";
import { OAuthCtx } from "../../oauth/OAuthContext";
import ErrorStyles from "../UI/ErrorStyles";
import { CONTACT_WRITE, CONTACT_DELETE } from "../UI/GlobalVariables";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import CreateContactDrawer from "./CreateContactDrawer";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  box: {
    display: "flex",
  },

  // button: { padding: "7px", paddingLeft: "9px" },
}));

export default function Contacts() {
  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [showPostelAddress, setShowPostelAddress] = useState(true);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [roundLoader, setRoundLoader] = useState(false);
  const formatPhoneNumber = (phoneNumber) => {
    return `+61 ${phoneNumber}`;
  };
  const formatMobileNumber = (mobileNumber) => {
    return `+61 ${mobileNumber}`;
  };
  /* DataTable */
  const columns = [
    {
      name: "contactNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "contactName",
      label: "Contact Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "businessName",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "businessType",
      label: "Business Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return <div>{value}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "workPhone",
      label: "Work Phone",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return <div>{formatPhoneNumber(value)}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            const formattedMobileNumber = formatMobileNumber(value);
            return <div>{formattedMobileNumber}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },
    {
      name: "emailAddress",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "contactId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return contacts[tableMeta.rowIndex].editable ? (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT CONTACT" placement="top" TransitionComponent={Zoom} arrow>
                <Button size="small" color="primary" onClick={() => handleUpdate(tableMeta.rowIndex)}>
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="DELETE CONTACT" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={value}
                  onClick={(e) => handleDelete(value)}
                >
                  <DeleteIcon value={value} fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained">
              <Button color="primary" size="small" onClick={() => handleUpdate(tableMeta.rowIndex)}>
                <Tooltip
                  title="View Contact Information"
                  placement="top"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <VisibilityIcon fontSize="small" />
                </Tooltip>
              </Button>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(CONTACT_WRITE) ? true : false,
      },
    },
  ];
  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);

  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsOnClick: false,
    // selectableRows: "single",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    //   console.log("rorowData", rowData);
    // },
    onTableChange: (action, state) => {
      if (action === "rowsdelID") {
      }
      // console.log(action);
      // console.dir(state);
    },

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   console.log(rowsSelectedData, allRows, rowsSelected, "data", data[rowsSelected]);
    //   // this.setState({ rowsSelected: rowsSelected });
    // },
  };
  const [load, setLoad] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isBtnDisbl, setIsBtnDisbl] = useState(true);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [contactData, setContactData] = useState({ editable: true });
  const [recipientsEmail, setRecipientsEmail] = useState();

  const handleUpdate = (arrayIndex) => {
    setContactData(contacts[arrayIndex]);
    setState({ right: true });
    setShowPostelAddress(true);
    /* reset is method of useForm it is used for returning data to form */
  };
  const handleOpenDrawer = () => {
    setContactData({ editable: true });
    setState({ right: true });
    setShowPostelAddress(true);
  };

  /* we can call this commonToggle function for open and close toggleDrawer*/
  function commonToggle(open) {
    setState({ ...state, ["right"]: open });
  }

  useEffect(() => {
    if (hasValidAccess(CONTACT_DELETE)) {
      setSelectableRows("single");
    }
    getContacts(setContacts, setLoad);
  }, [wantToCallGet]);

  //////////////  Delete Contacts ////////////////////

  const [delID, setdelID] = useState();

  const handleDelete = (contactId) => {
    setdelID(contactId);
    setConfirmDelete(true);
  };

  /*************** Submit Contacts Details **************** */
  const submitContactDetails = (formData) => {
    createContacts(
      formData,
      setWantToCallGet,
      wantToCallGet,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setState
    ); //API call For Submit
  };
  const updtaeContactDetails = () => {
    let contactDetail = getValues();
    let contactId = contactDetail.contactId;
    /*deleting contactId of this object  */
    delete contactDetail["contactId"];
    console.log(contactDetail);
    updateContactByID(
      contactId,
      contactDetail,
      setWantToCallGet,
      wantToCallGet,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setState
    ); //API call For Update
    setWantToCallGet(true);

    commonToggle(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleEmptySubmitForm = () => {
    commonToggle(true);
    setIsBtnDisbl(true);
    let row = {};
    reset(row);
  };

  /* Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <React.Fragment key={"right"}>
      {roundLoader && <SimpleBackdrop />}
      {state.right && (
        <CreateContactDrawer
          contactData={contactData}
          setContactData={setContactData}
          contacts={contacts}
          setContacts={setContacts}
          recipientsEmail={recipientsEmail}
          setRecipientsEmail={setRecipientsEmail}
          setOpenSnackbar={setOpenCustSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          setRoundLoader={setRoundLoader}
          contactDrawerState={state}
          setContactDrawerState={setState}
          callFrom={"contact"}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {hasValidAccess(CONTACT_WRITE) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="small"
                onClick={handleOpenDrawer}
                startIcon={<AddCircleSharpIcon />}
              >
                New CONTACT
              </Button>
            </div>
          )}
          <div style={{ marginTop: "15px" }}>
            {load ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {contacts.length === 0 ? ( //if contacts are empty then empty msg is shown
                  <EmptyEstimatesDialog
                    heading="Empty Contacts"
                    msg="You don't have any contacts here yet.
Click the 'NEW CLIENT' button above to get started, or choose from a template"
                  />
                ) : (
                  <MUIDataTable data={contacts} columns={columns} options={options} />
                )}
              </React.Fragment>
            )}
          </div>
          <AlertPopContact
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            delID={delID}
            setContacts={setContacts}
            contacts={contacts}
            setOpenCustSnackbar={setOpenCustSnackbar}
            setSnackbarMSG={setSnackbarMSG}
            setSnackbarSeverity={setSnackbarSeverity}
            setRoundLoader={setRoundLoader}
          />
          {openCustSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openCustSnackbar}
              setOpenSnackbar={setOpenCustSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <CommonInfo title={infoArray.contacts.title} msg={infoArray.contacts.msg} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
