/** @format */

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from "ckeditor4-react";
import Table from "@mui/material/Table";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import ButtonGroup from "@mui/material/ButtonGroup";
import Alert from "@mui/material/Alert";
import {
  getQuoteRequest,
  getQuoteDataAPI,
  updatePurchaseOrder,
  getJobLineItemsByJobIdForAutoselect,
  getJobLineItemsGroupByJobIdForAutoselect,
} from "./helpers.actual.costings";
import { createPurchaseOrder, createVariation, createWorkOrder } from "../orders/Order.helper";
import ProgressBar from "../../UI/ProgressBar";
import { getContacts } from "../../quotes/helpers.quoteRequestAPI";
import DateCreator from "../../UI/DateCreator";
import ShortClientContactForm from "../../UI/ShortClientContactForm";
import VariationDrawer from "../Variation/VariationDrawer";
import { PURCHASE_ORDER_WRITE, VARIATION_WRITE, WORK_ORDER_WRITE } from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import NewOrderLineItemsTable from "../orders/NewOrderLineItemsTable";
import { Divider, TextareaAutosize } from "@mui/material";
import { StyledDropZone } from "../../estimates/TakeoffPlans/StyledDropZone";
import ClearIcon from "@mui/icons-material/Clear";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { checkProperties } from "../../UI/GlobalApi";
import ErrorStyles from "../../UI/ErrorStyles";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import CreateContactDrawer from "../../contacts/CreateContactDrawer";
import { getJobByJobId } from "../jobs.helper";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TemplateLineItemsTable from "../orders/TemplateLineItemsTable";
import {
  createNewTemplate,
  EditTemplate,
  DeleteTemplate,
  getTermsAndCondtions,
  getTermsAndCondtionsById,
} from "../orders/Terms.helper";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  button: {
    marginRight: "5px",
  },
}));

export default function ActualCostingRequest({
  checkedJobLineItems,
  is_purchaseOrder,
  jobId,
  setCheckedJobLineItems,
  setEdit,
  userConfigs,
  userConfigsuom,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  lineItemsForAutoselect,
  setNewlyAdded,
}) {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({ contactId: "" });
  const [secondLoader, setSecondLoader] = useState(true);
  const [firstLoader, setFirstLoader] = useState(true);
  const [name, setName] = useState();
  const [contactWantToGet, setContactWantToGet] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [jobLineItemsForAutoselect, setJobLineItemsForAutoselect] = useState();
  const [lineItemsGroupsForAutoselect, setLineItemsGroupsForAutoselect] = React.useState([]);
  const [contactObj, setContactObj] = React.useState();
  const [jobData, setJobData] = React.useState();
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [termsAndCondtions, setTermsAndCondtions] = React.useState([]);
  const [newTemplateState, setNewTemplateState] = React.useState(false);
  const [newTemplateData, setNewTemplateData] = React.useState({
    templateName: "",
    termsAndConditions: [],
  });
  const [editemplateState, setEditTemplateState] = React.useState(false);
  const [editTemplateData, setEditTemplateData] = React.useState({
    templateName: "",
    termsAndConditions: [],
  });
  const [templateDrawerLoading, setTemplateDrawerLoading] = useState(false);

  // useEffect(() => {
  //   getQuoteRequest(setquotes);
  // }, []);

  useEffect(() => {
    if (contactWantToGet) {
      getContacts(setContacts);
      setContactWantToGet(false);
    }
  }, [contactWantToGet]);

  // useEffect(() => {
  //   setSelectedContact(contacts[0]);
  // }, [contacts]);

  useEffect(() => {
    selectedContact &&
      setNewOrderData({
        ...newOrderData,
        contactId: selectedContact.contactId,
      });
  }, [selectedContact]);

  const classes = useStyles();
  const ErrorClasses = ErrorStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  /* -------------------------------------------------------------------------- */

  /* Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  /* -------------------------------------------------------------------------- */

  const methods = useForm({ criteriaMode: "all" });

  const [expiryDate, setExpiryDate] = useState();
  // --------------------------------------------------------
  const [newOrderData, setNewOrderData] = useState(
    name === "purchaseOrder"
      ? {
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          send: false,
          comment: "",
          status: "DRAFT",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: checkedJobLineItems,
        }
      : {
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          completedDate: null,
          send: false,
          comment: "",
          status: "DRAFT",
          workAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: checkedJobLineItems,
        }
  );

  const [completedDate, setCompletedDate] = useState(
    new Date()
      .toLocaleString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  );
  const [newOrderDate, setNewOrderDate] = useState(new Date());
  const [file, setFile] = useState();
  const [status, setStatus] = useState(["DRAFT", "SENT", "RECEIVED", "CANCELLED", "PARTRECEIVED"]);
  const [workOrdeStatusList, setworkOrdeStatusList] = useState([
    "DRAFT",
    "SENT",
    "COMPLETED",
    "CANCELLED",
  ]);
  const [contactDrawerState, setContactDrawerState] = React.useState({
    right: false,
  });
  const [contactData, setContactData] = useState({ editable: true });

  // --------------------------------------------------------

  /* useState for work orders */
  const [isVarianceBtnDisabled, setIsVarianceBtnDisabled] = useState(false);
  let purchaseItemcount = 1;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  useEffect(() => {}, [jobData]);

  /* for template*/
  // --------------------------------------------------------------------------------------
  const handleTemplateSave = () => {
    createNewTemplate(
      newTemplateData,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setNewTemplateState,
      setRoundLoader,
      termsAndCondtions,
      setTermsAndCondtions
    );
  };
  const handleEditTemplateSave = () => {
    EditTemplate(
      editTemplateData,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setNewTemplateState,
      setRoundLoader,
      termsAndCondtions,
      setTermsAndCondtions,
      setEditTemplateState
    );
  };
  const handleTemplateDelete = () => {
    DeleteTemplate(
      setOpenSnackbar,
      setSnackbarMSG,
      setRoundLoader,
      setEditTemplateState,
      setSnackbarSeverity,
      selectedTemplate?.templateId,
      getTermsAndCondtions,
      setTermsAndCondtions
    );
  };
  // --------------------------------------------------------------------------------------

  const handleEditTemplate = (option) => {
    // setTemplateDrawerLoading(true);
    // getTermsAndCondtionsById(
    //   option.templateId,
    //   setNewTemplateData,
    //   setTemplateDrawerLoading
    // );
    setEditTemplateState(true);
    setEditTemplateData(option);
  };

  useEffect(() => {
    getTermsAndCondtions(setTermsAndCondtions);
  }, []);
  // --------------------------------------------------------------------------------------

  /* for purchase order */
  const openNewPOrder = (e) => {
    reset({
      streetAddress: jobData?.client?.address,
      suburbOrTown: jobData?.client?.cityOrTown,
      state: jobData?.client?.state,
      postcode: jobData?.client?.postcode,
    });
    getJobLineItemsByJobIdForAutoselect(setJobLineItemsForAutoselect, jobId, setDataLoaded);
    getJobLineItemsGroupByJobIdForAutoselect(jobId, setLineItemsGroupsForAutoselect);
    /* storing purchase Order button name when we clicked on Purchase order button */
    setName(e.currentTarget.name);
    setIsVarianceBtnDisabled(true);
    setSecondLoader(false);
    setState({ ...state, ["right"]: true });
  };

  /* for work order */
  const openNewWorkOrder = (e) => {
    reset({
      streetAddress: jobData?.client?.address,
      suburbOrTown: jobData?.client?.cityOrTown,
      state: jobData?.client?.state,
      postcode: jobData?.client?.postcode,
    });
    getJobLineItemsByJobIdForAutoselect(setJobLineItemsForAutoselect, jobId, setDataLoaded);

    /* storing Work Order button name when we clicked on WorkOrder button */
    setName(e.currentTarget.name);

    setIsVarianceBtnDisabled(true);

    setSecondLoader(false);
    setState({ ...state, ["right"]: true });
  };

  /* for Variation */
  const [variationObj, setVariationObj] = useState([]);

  const openVariance = (e) => {
    /* storing Variation button name when we clicked on create Variation button */
    setName(e.currentTarget.name);

    setIsVarianceBtnDisabled(false);

    setSecondLoader(false);
    console.log("tempCheckedOrder ", checkedJobLineItems);
    setVariationObj(checkedJobLineItems);
    setState({ ...state, ["right"]: true });
  };

  useEffect(() => {
    getContacts(setContacts);
    getJobByJobId(setJobData, jobId, setDummyLoad, undefined); //get job by id
  }, []);

  const closeFunction = () => {
    setState({ ...state, ["right"]: false });
    setNewOrderData(
      name === "purchaseOrder"
        ? {
            jobId: jobId,
            description: "",
            message: "",
            contactId: "",
            requiredBy: new Date()
              .toLocaleString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
            send: false,
            comment: "",
            status: "DRAFT",
            deliveryAddress: {
              streetAddress: jobData?.client?.address,
              suburbOrTown: jobData?.client?.cityOrTown,
              state: jobData?.client?.state,
              postcode: jobData?.client?.postcode,
            },
            orderLineItems: [],
          }
        : {
            jobId: jobId,
            description: "",
            message: "",
            contactId: "",
            requiredBy: new Date()
              .toLocaleString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
            completedDate: null,
            send: false,
            comment: "",
            status: "DRAFT",
            workAddress: {
              streetAddress: jobData?.client?.address,
              suburbOrTown: jobData?.client?.cityOrTown,
              state: jobData?.client?.state,
              postcode: jobData?.client?.postcode,
            },
            orderLineItems: [],
          }
    );
  };
  // ----------------------------------------------------------------------------
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonName = event.nativeEvent.submitter.name;
    const formData = new FormData(event.target);

    let termsAndConditionsTemplateId = null;
    if (selectedTemplate?.hasOwnProperty("templateId")) {
      termsAndConditionsTemplateId = selectedTemplate?.templateId;
    } else if (selectedTemplate?.hasOwnProperty("termsAndConditionsTemplateId")) {
      termsAndConditionsTemplateId = selectedTemplate?.termsAndConditionsTemplateId;
    }

    // Convert FormData to an object
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // Now you have the form data as an object
    if (name === "purchaseOrder") {
      let finalData = {
        termsAndConditionsTemplateId,
        send: buttonName === "save" ? false : true,
        status: formDataObject.status,
        jobId: jobId,
        orderLineItems: newOrderData.orderLineItems,
        requiredBy: newOrderData.requiredBy,
        description: formDataObject.description,
        contactId: selectedContact?.contactId,
        comment: formDataObject.comment,
        deliveryAddress: {
          streetAddress: formDataObject.streetAddress,
          suburbOrTown: formDataObject.suburbOrTown,
          state: formDataObject.state,
          postcode: formDataObject.postcode,
        },
      };
      createPurchaseOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        setNewlyAdded
      );
    } else {
      let finalData = {
        termsAndConditionsTemplateId,
        send: buttonName === "save" ? false : true,
        status: formDataObject.status,
        jobId: jobId,
        orderLineItems: newOrderData.orderLineItems,
        requiredBy: newOrderData.requiredBy,
        completedDate: completedDate,
        description: formDataObject.description,
        contactId: selectedContact?.contactId,
        comment: formDataObject.comment,
        workAddress: {
          streetAddress: formDataObject.streetAddress,
          suburbOrTown: formDataObject.suburbOrTown,
          state: formDataObject.state,
          postcode: formDataObject.postcode,
        },
      };
      createWorkOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        setNewlyAdded
      );
    }

    // buttonName="save"?():();
  };
  // ----------------------------------------------------------------------------

  const submitNewOrderSave = (data) => {
    console.log("newOrderData", newOrderData);
    let finalData = {
      ...newOrderData,
      description: data?.description,
      contactId: data.Contacts.contactId,
      deliveryAddress: {
        streetAddress: data.streetAddress,
        suburbOrTown: data.suburbOrTown,
        state: data.state,
        postcode: data.state,
      },
    };

    if (name === "workOrder") {
      createWorkOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        setNewlyAdded
      );
    } else if (name === "purchaseOrder") {
      createPurchaseOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        data,
        setNewlyAdded
      );
    }
  };
  // ----------------------------------------------------------------------------
  const handleNewOrderSaveAndSend = () => {
    let finalData = {
      ...newOrderData,
      contactId: selectedContact.contactId,
      send: true,
    };

    if (name === "workOrder") {
      createWorkOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        setNewlyAdded
      );
    } else if (name === "purchaseOrder") {
      createPurchaseOrder(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setState,
        setRoundLoader,
        setNewOrderData,
        jobId,
        setNewlyAdded
      );
    }
  };
  // --------------------------------------------------------------------------------------
  const handleNewOrderInputChange = (e) => {
    const { name, value } = e.target;
    setNewOrderData({ ...newOrderData, [name]: value });
  };

  // ----------------------------------------------------------------------------
  useEffect(() => {
    newOrderDate.$d &&
      setNewOrderData({
        ...newOrderData,
        requiredBy: newOrderDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
  }, [newOrderDate]);

  useEffect(() => {
    completedDate.$d &&
      setNewOrderData({
        ...newOrderData,
        completedDate: completedDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
  }, [completedDate]);

  // ----------------------------------------------------------------------------

  return (
    <React.Fragment key={"right"}>
      {isVarianceBtnDisabled ? (
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              width: "80%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            <form onSubmit={handleFormSubmit}>
              {roundLoader && <SimpleBackdrop />}
              {dataLoaded ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      {name == "workOrder" ? "New Work Order" : "New Purchase Order"}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          label="Description"
                          size="small"
                          className={classes.textField}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.description?.type === "required" && "Description is required"}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setNewOrderData({ ...newOrderData, status: newValue });
                      }}
                      value={newOrderData.status}
                      options={name === "purchaseOrder" ? status : workOrdeStatusList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Status"
                          size="small"
                          name="status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Controller
                      name="Contacts"
                      id="tags-standard"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(event, newValue) => {
                            setSelectedContact(newValue);
                          }}
                          options={contacts}
                          sx={{ width: "95%" }}
                          getOptionLabel={(option) => option.contactName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label="Contacts"
                              name="contact"
                            />
                          )}
                        />
                      )}
                    />
                    <Tooltip title="Add New Contact" placement="top" TransitionComponent={Zoom} arrow>
                      <Button
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          setContactDrawerState({ right: true });
                          setContactData({ editable: true });
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                    <p className={ErrorClasses.errorMsg}>
                      {errors.Contacts?.type === "required" && "Contacts is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    <Typography variant="strong" gutterBottom>
                      {name === "purchaseOrder" ? "Delivery To :" : "Work Address :"}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={4}>
                    {name === "purchaseOrder" ? (
                      <>
                        <Controller
                          name="streetAddress"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={"Street Address"}
                              variant="outlined"
                              size="small"
                              //onChange={(e) => setValue(e.target.value)}
                              className={classes.textField}
                              type="text"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <Controller
                        name="streetAddress"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={"Street Address"}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            type="text"
                          />
                        )}
                      />
                    )}
                    <p className={ErrorClasses.errorMsg}>
                      {errors.streetAddress?.type === "required" && "Street Address is required"}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    {name === "purchaseOrder" ? (
                      <>
                        <Controller
                          name="suburbOrTown"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={"Suburb/Town"}
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              type="text"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <Controller
                        name="suburbOrTown"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={"Suburb/Town"}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            type="text"
                          />
                        )}
                      />
                    )}
                    <p className={ErrorClasses.errorMsg}>
                      {errors.suburbOrTown?.type === "required" && "Suburb Or Town is required"}
                    </p>
                  </Grid>

                  <Grid item xs={4}>
                    {name === "purchaseOrder" ? (
                      <>
                        <Controller
                          name="state"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={"State"}
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              type="text"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <Controller
                        name="state"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={"State"}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            type="text"
                          />
                        )}
                      />
                    )}
                    <p className={ErrorClasses.errorMsg}>
                      {errors.state?.type === "required" && "State is required"}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    {name === "purchaseOrder" ? (
                      <>
                        <Controller
                          name="postcode"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={"Postcode"}
                              variant="outlined"
                              size="small"
                              className={classes.textField}
                              type="number"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <Controller
                        name="postcode"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={"Postcode"}
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            type="number"
                          />
                        )}
                      />
                    )}
                    <p className={ErrorClasses.errorMsg}>
                      {errors.postcode?.type === "required" && "Postcode is required"}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack sx={{ width: "100%" }}>
                      <DateCreator
                        expiryDate={newOrderDate}
                        setExpiryDate={setNewOrderDate}
                        label={"Required By"}
                      />
                    </Stack>
                  </Grid>
                  {name === "workOrder" && newOrderData.status === "COMPLETED" && (
                    <Grid item xs={4}>
                      <DateCreator
                        expiryDate={completedDate}
                        setExpiryDate={setCompletedDate}
                        label={"Completed Date"}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      name="comment"
                      variant="outlined"
                      minRows={3}
                      maxRows={3}
                      aria-label="maximum height"
                      placeholder="Comment"
                      value={newOrderData.comment}
                      style={{
                        width: 1485,
                        backgroundColor: `${
                          localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                        }`,
                      }}
                      onChange={(e) =>
                        setNewOrderData({
                          ...newOrderData,
                          comment: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Autocomplete
                      clearIcon={
                        <ClearIcon
                          fontSize="small"
                          onClick={() =>
                            setSelectedTemplate({
                              templateId: null,
                              templateName: "",
                            })
                          }
                        />
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedTemplate(newValue);
                        }
                      }}
                      options={termsAndCondtions}
                      sx={{ width: "95%" }}
                      autoHighlight
                      renderOption={(props, option) => {
                        if (option.editable === true) {
                          return (
                            <div>
                              <Grid container spacing={1} {...props}>
                                <Grid item>{option.templateNo}</Grid>
                                <Grid item>{option.templateName}</Grid>
                                <Grid item>
                                  <Link
                                    to={"#"}
                                    onClick={() => {
                                      setEditTemplateState(true);
                                      setEditTemplateData(option);
                                    }}
                                  >
                                    <EditIcon color="warning" fontSize="small" />
                                  </Link>
                                </Grid>
                              </Grid>
                            </div>
                          );
                        } else {
                          return `${option?.templateNo}:${option?.templateName}`;
                        }
                      }}
                      getOptionLabel={(option) => option?.templateName}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Template" size="small" />
                      )}
                    />
                    <Tooltip title="Add New Template" placement="top" TransitionComponent={Zoom} arrow>
                      <Button
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          setNewTemplateState(true);
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <NewOrderLineItemsTable
                      newOrderData={newOrderData}
                      setNewOrderData={setNewOrderData}
                      userConfigs={userConfigs}
                      userConfigsuom={userConfigsuom}
                      lineItemsForAutoselect={jobLineItemsForAutoselect}
                      lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                      jobId={jobId}
                      setShowSaveButton={setShowSaveButton}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                <h5>DOCUMENTS:</h5>
                <StyledDropZone setPlanFile={setFile} />
                <Divider />
              </Grid> */}
                  {showSaveButton && (
                    <Grid container justifyContent="flex-end" mt={1}>
                      <Grid item xs={12} align="right">
                        <Button
                          name="save"
                          color="primary"
                          size="small"
                          type="submit"
                          variant="contained"
                        >
                          save
                        </Button>
                        <Button
                          name="saveandsend"
                          variant="contained"
                          color="primary"
                          align="right"
                          type="submit"
                          size="small"
                          style={{ marginLeft: "10px" }}
                          // onClick={handleNewOrderSaveAndSend}
                        >
                          Save & Send
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          align="right"
                          onClick={closeFunction}
                          style={{ marginLeft: "10px" }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <ProgressBar />
              )}
            </form>
          </div>
        </Drawer>
      ) : (
        <>
          {variationObj.length !== 0 && (
            <VariationDrawer
              purchaseItemcount={purchaseItemcount}
              variationObj={variationObj}
              setVariationObj={setVariationObj}
              expiryDate={expiryDate}
              setExpiryDate={setExpiryDate}
              toggleDrawer={toggleDrawer}
              state={state}
              setState={setState}
              jobId={jobId}
              setCheckedJobLineItems={setCheckedJobLineItems}
              setEdit={setEdit}
              userConfigs={userConfigs}
              userConfigsuom={userConfigsuom}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
        </>
      )}
      {newTemplateState && (
        <Drawer
          anchor={"right"}
          open={newTemplateState}
          // onClose={setNewTemplateState(false)}
          PaperProps={{
            sx: {
              width: "40%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {templateDrawerLoading ? (
              <>
                <br />
                <ProgressBar />
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    New Template
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={"Template Name"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newTemplateData.templateName}
                    name="templateName"
                    onChange={(e) =>
                      setNewTemplateData({
                        ...newTemplateData,
                        templateName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TemplateLineItemsTable
                    newTemplateData={newTemplateData}
                    setNewTemplateData={setNewTemplateData}
                    setShowSaveButton={setShowSaveButton}
                  />
                </Grid>
                {showSaveButton && (
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      size="small"
                      onClick={() => handleTemplateSave()}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => setNewTemplateState(false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Drawer>
      )}
      {editemplateState && (
        <Drawer
          anchor={"right"}
          open={editemplateState}
          // onClose={setNewTemplateState(false)}
          PaperProps={{
            sx: {
              width: "40%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {templateDrawerLoading ? (
              <>
                <br />
                <ProgressBar />
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    Edit Template
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    label={"Template Name"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={editTemplateData?.templateName}
                    name="templateName"
                    onChange={(e) =>
                      setEditTemplateData({
                        ...editTemplateData,
                        templateName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TemplateLineItemsTable
                    newTemplateData={editTemplateData}
                    setNewTemplateData={setEditTemplateData}
                    setShowSaveButton={setShowSaveButton}
                  />
                </Grid>
                {showSaveButton && (
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      size="small"
                      onClick={() => handleEditTemplateSave()}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => setEditTemplateState(false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => handleTemplateDelete()}
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Drawer>
      )}
      {contactDrawerState.right && (
        <CreateContactDrawer
          contactData={contactData}
          setContactData={setContactData}
          contacts={contacts}
          setContacts={setContacts}
          recipientsEmail={contactObj}
          setRecipientsEmail={setContactObj}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          setRoundLoader={setRoundLoader}
          contactDrawerState={contactDrawerState}
          setContactDrawerState={setContactDrawerState}
          callFrom={"catalogue"}
        />
      )}

      {is_purchaseOrder ? (
        <React.Fragment>
          {checkedJobLineItems.length > 0 && (
            <React.Fragment>
              <div style={{ color: "gray", position: "fixed", bottom: 10 }}>
                {hasValidAccess(PURCHASE_ORDER_WRITE) && (
                  <Button
                    name="purchaseOrder"
                    variant="contained"
                    color="secondary"
                    size="small"
                    align="right"
                    onClick={(e) => openNewPOrder(e)}
                  >
                    Add Purchase Order ({checkedJobLineItems.length} items)
                  </Button>
                )}
                {hasValidAccess(WORK_ORDER_WRITE) && (
                  <Button
                    name="workOrder"
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => openNewWorkOrder(e)}
                  >
                    Add Work Order ({checkedJobLineItems.length} items)
                  </Button>
                )}
                {hasValidAccess(VARIATION_WRITE) && (
                  <Button
                    name="variance"
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => openVariance(e)}
                  >
                    Create Variation ({checkedJobLineItems.length} items)
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {firstLoader ? (
            <ProgressBar />
          ) : (
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong> </strong>
                    </TableCell>
                    <TableCell>
                      <strong>Code</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Title</strong>
                    </TableCell>
                    <TableCell width="40%">
                      <strong>Estimate</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Date Released</strong>
                    </TableCell>
                    <TableCell>
                      <strong>States</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Deadline</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Responses</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {getQuoteData.map((row, i) => (
                    // <QuoteResponse key={row.name} row={row} getQuoteDataById={getQuoteDataById} setWantToCallGet={setWantToCallGet} />
                  ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
