/** @format */

import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, Switch, Route, BrowserRouter, matchPath } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { AppBar } from "@mui/material";
import { useForm } from "react-hook-form";
import ItemsMainRow from "../cost-center/ItemsMainRow";

const drawerWidth = 240;
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", //chgd from flex-end to this
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    // overflow: 'auto',
    flexDirection: "column",
    backgroundColor: "#ccc",
  },
  fixedHeight: {
    height: "auto",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function CostCentreNav(props) {
  // console.log("His-> ",window.location);
  var { estimateId, costCentreId } = useParams();
  const [state, setState] = React.useState({
    right: true,
  });
  const [value, setVal] = React.useState(0);
  // alert(estId)
  const handleChange = (event, newValue) => {
    setVal(newValue);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (open) => (event) => {
    setState({ right: open });
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const location = useLocation();
  const routes = [
    {
      exact: true,
      key: "items",
      path: "/cost-centre/:costCentreId/items",
    },
  ];
  // const currentRoute = routes.find(route => matchPath(location.pathname, route));
  // useEffect(() => {
  //   setVal(currentRoute.path);
  // }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Box>
          <AppBar
            className="innerHeader_Nav"
            color="inherit"
            position="static"
            style={{
              border: "none",
              boxShadow: "none",
            }}
          >
            <Tabs
              noWrap
              className={classes.title}
              value={value}
              // value={'/estimate/:estimateId/details'}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="Items"
                value={"/cost-centre/:costCentreId/items"}
                component={Link}
                to={"/cost-centre/" + costCentreId + "/items"}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
        </Box>
        {/* <div style={{ padding: "3px" }}>&nbsp;</div> */}
        <Switch>
          {/* Start costing */}
          <Route
            path={"/cost-centre/:costCentreId/items"}
            render={() => (
              <div>
                <ItemsMainRow />
              </div>
            )}
          />
          {/* END costing */}
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}
