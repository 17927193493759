/** @format */

import {
  AppBar,
  Autocomplete,
  Button,
  Dialog,
  Drawer,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
} from "@mui/material";
import React from "react";
import Webviewer from "./Webviewer";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
// import { Calculate } from "../../UI/Calculator";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Box } from "@mui/system";
import { Height, Padding } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function WebDrawer({
  estimateId,
  autoSelectPlanName,
  setAutoSelectPlanName,
  setDistanceValue,
  distanceValue,
  openPlanDialog,
  setInstance,
  instance,
  setOpenPlanDialog,
  handleSaveMeasure,
  planObject,
  setWantToCallUseEffect,
  setSnackbarMSG,
  setOpenSnackbar,
  setSnackbarSeverity,
}) {
  const handleDialogClose = () => {
    setOpenPlanDialog(false);
  };

  // const handleClickOpen = () => {
  //   setOpenCalculator(true);
  //   console.log("distance1122", distanceValue);
  // };
  return (
    <div>
      {" "}
      <Dialog
        fullScreen
        open={openPlanDialog}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            paddingX: "20px !important",
            paddingTop: "10px",
            paddingBottom: "20px",
            backgroundColor: "#f3f4f6",
          }}
        >
          <Box sx={{ textAlign: "right", pb: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
              sx={{
                backgroundColor: "#fddfdd",
                border: "1px solid #c12e23",
                color: "#c12e23",
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* <Toolbar sx={{ backgroundColor: "deepskyblue" }}>
            <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
              <CloseIcon />
            </IconButton> */}
          <Grid sx={{ background: "#fff", borderRadius: "10px" }}>
            <Grid container sx={{ p: 2 }} alignItems="center">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Autocomplete
                  options={planObject}
                  value={autoSelectPlanName}
                  disableClearable
                  size="small"
                  id="planName"
                  onChange={(event, newValue) => {
                    instance.UI.loadDocument(newValue.documentUrl);
                    setAutoSelectPlanName(newValue);
                  }}
                  getOptionLabel={(option) => option.planName}
                  renderInput={(params) => <TextField {...params} variant="outlined" name="planName" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <TextField
                    variant="outlined"
                    // color=""
                    size="small"
                    value={distanceValue}
                    name="distanceValue"
                    onChange={(e) => setDistanceValue(e.target.value)}
                  />
                  <Button
                    autoFocus
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSaveMeasure}
                  >
                    save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </AppBar>

        {/* <AppBar sx={{ position: "relative", paddingRight: "-10px", margin: "0" }}>
          <Toolbar sx={{ backgroundColor: "deepskyblue" }}> */}
        {/* <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
              <CloseIcon />
            </IconButton> */}
        {/* <CalculateIcon fontSize="small" onClick={handleClickOpen} /> */}

        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                variant="outlined"
                // color=""
                size="small"
                value={distanceValue}
                name="distanceValue"
                onChange={(e) => setDistanceValue(e.target.value)}
              />
            </div>

            <Button
              autoFocus
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              onClick={handleSaveMeasure}
            >
              Save
            </Button>
          </Toolbar>
          */}
        <Webviewer
          estimateId={estimateId}
          autoSelectPlanName={autoSelectPlanName}
          setInstance={setInstance}
          setDistanceValue={setDistanceValue}
          setSnackbarMSG={setSnackbarMSG}
          setOpenCustSnackbar={setOpenSnackbar}
          setSnackbarSeverity={setSnackbarSeverity}
          setWantToCallUseEffect={setWantToCallUseEffect}
        />
      </Dialog>
    </div>
  );
}

export default WebDrawer;
