/** @format */

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteEstimatebyID } from "./estimates.helpers";

export default function AlertPopEstimate({
  confirmDelete,
  setConfirmDelete,
  deleteId,
  setEstimates,
  setOpenSnackbar,
  estimates,
  setSnackbarSeverity,
  setSnackbarMSG,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };
  // const [wantToSetEstimateAgain, setWantToSetEstimateAgain] = useState(false);
  // useEffect(() => {
  //   if (wantToSetEstimateAgain) {
  //     getEstimates();
  //     setWantToSetEstimateAgain(false);
  //   }
  // }, [wantToSetEstimateAgain]);
  const handleYes = () => {
    console.log("del id ", deleteId);
    // deleteId.map((id) => {
    deleteEstimatebyID(deleteId, setOpenSnackbar, setSnackbarSeverity, setSnackbarMSG);
    var data = estimates.filter((element) => {
      if (deleteId != element.estimateId) {
        return element;
      }
    });
    console.log("tmepdata ", estimates);

    // });
    setEstimates(data);
    setConfirmDelete(false);
  };

  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
