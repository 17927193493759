/** @format */

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  darken,
  lighten,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { TableCell } from "@mui/material";
import { TextField } from "@mui/material";
import { getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import { getEstimateLineItemsList } from "../EstimateCosting/estimate.costings";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NumberFormat from "react-number-format";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/styles";
import {
  getJobPlanItemByEstimateJobPlanId,
  updateJobPlanItemByEstimateJobPlanId,
} from "../../cost-center/job-plan/jobplan.helpers";
import { es } from "date-fns/locale";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const LineItemsTableForPlans = ({
  lineItemsList,
  finalMeasurement,
  estimateLineItems,
  setRoundLoader,
  setSnackbarMSG,
  setSnackbarSeverity,
  setOpenCustSnackbar,
  checkedJobPlanLineItemsForPlans,
  callFrom,
}) => {
  var { estimateId } = useParams();
  const filter = createFilterOptions();
  const [lineItems, setLineItems] = useState([]);
  const [draftItems, setDraftItems] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [userConfigs, setUserConfigs] = React.useState({});
  const [userConfigsuom, setUserConfigsuom] = React.useState({});
  const [dummyLoad, setDummyLoad] = useState(true); //its a only for dummy

  console.log("estimateLineItems:", estimateLineItems);

  const handleAdd = () => {
    setEdit(true);
    var newElement = {
      lineItemId: null,
      itemCode: "",
      itemName: "",
      type: "",
      qty: 1,
      uom: "",
      height: 1,
      wastagePercentage: 0,
      total: 0,
    };
    setLineItems([...lineItems, newElement]);
  };

  // ------------------------------------------------------

  const handleAddToCosting = () => {
    setRoundLoader(true);
    let estLineItems = JSON.parse(JSON.stringify(estimateLineItems));
    lineItems.map((item) => {
      estLineItems.map((groups, grpIndex) => {
        groups.items.map((estLineItem, itemIndex) => {
          if (item.lineItemId === estLineItem.lineItemId) {
            console.log("ID matched");
            estLineItem.qty = item.total;
            estLineItem.type = item.type;
            estLineItem.uom = item.uom;
          }
        });
      });
    });
    updateJobPlanItemByEstimateJobPlanId(
      setDummyLoad,
      estimateId,
      estLineItems,
      setDummyLoad,
      setOpenCustSnackbar,
      setDummyLoad,
      setSnackbarSeverity,
      setSnackbarMSG,
      setDummyLoad,
      setRoundLoader
    );
  };
  const handlesLineItemSave = () => {
    setEdit(false);
  };

  /* ---------------------------------------------------------------- */
  const handleCancel = () => {
    setLineItems(JSON.parse(JSON.stringify(draftItems)));
    setEdit(false);
  };
  /* ---------------------------------------------------------------- */
  const handleEdit = (i) => {
    setDraftItems(JSON.parse(JSON.stringify(lineItems)));
    setEdit(true);
  };
  /* ---------------------------------------------------------------- */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    var list = [...lineItems];
    if (name === "qty" || name === "wastagePercentage" || name === "height") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }

    } else {
      list[index][name] = value;
    }
    setLineItems(list);


  };

  /* ------------------------------------------------- */
  useEffect(() => {
    getUserConfigsTypes(setUserConfigs); //get all configs Types
    getUserConfigsUom(setUserConfigsuom, setDummyLoad); //get all configs uom
    if (callFrom === "takeOffBtn") {
      let list = [];
      checkedJobPlanLineItemsForPlans.map((item) => {
        list.push({
          lineItemId: item.lineItemId,
          itemName: item.itemName,
          itemCode: item.itemCode,
          type: item.type,
          uom: item.uom,
          total: 0,
          qty: 1,
          height: 1,
          wastagePercentage: 0,
        });
      });
      console.log("LIST Useeffect:", list);
      setLineItems(list);
    }
  }, []);
  /* ------------------------------------------------- */
  // -----------------Code For Calculations----------------
  var list = [...lineItems];
  list.map((item, i) => {
    let mult = list[i].qty * list[i].height * finalMeasurement;
    let wastage = (mult * list[i].wastagePercentage) / 100;
    list[i]["total"] = mult + wastage;
  });

  /* ------------------------------------------------- */
  const clearLineItem = (i) => {
    let sortedItems = lineItems.filter((item, index) => {
      return index !== i;
    });
    setLineItems(sortedItems);
  };

  /* ---------------------------------------------------------------- */
  const handleAutoselectLineItemName = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(lineItems));
      if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          lineItemId: newValue.lineItemId,
          itemName: newValue.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          itemCode: newValue.itemCode,
        };
        setLineItems(list);
      }
    }
  };
  /* ---------------------------------------------------------------- */

  return (
    <div style={{ minWidth: 275, margin: 7 }}>
      <Box py={2}>
        <Grid item xs={12} align="right">
          <Box py={1}>
            {lineItems.length !== 0 ? (
              <ButtonGroup variant="contained" aria-label="contained primary button group">
                {isEdit ? (
                  <ButtonGroup variant="contained" aria-label="contained primary button group">
                    <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                      <AddIcon fontSize="small" />
                      ADD
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      align="right"
                      onClick={handleCancel}
                      size="small"
                    >
                      <ClearIcon fontSize="small" />
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlesLineItemSave}
                      size="small"
                    >
                      <CheckCircleOutlineIcon fontSize="small" />
                      SAVE
                    </Button>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup variant="contained" aria-label="contained primary button group">
                    {lineItems.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddToCosting}
                        size="small"
                      >
                        <SaveIcon fontSize="small" />
                        Accept
                      </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                      <AddIcon fontSize="small" />
                      ADD
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      onClick={handleEdit}
                      size="small"
                    >
                      <CreateIcon fontSize="small" />
                      EDIT
                    </Button>
                  </ButtonGroup>
                )}
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="contained" aria-label="contained primary button group">
                <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                  <AddIcon onClick={handleAdd} fontSize="small" />
                  ADD
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </Grid>
        {lineItems.length === 0 ? (
          <EmptyEstimatesDialog
            heading="Add some lineitems in this table"
            msg="Add some lineitems here to Save this cost centre type. You can add using the side button present on the right side the screen"
          />
        ) : (
          <div>
            <Table
              sx={{
                borderBottom: "none",
                justifyContent: "center",
                overflowX: "auto",
              }}
              size="small"
              aria-label="a dense table"
              id="estimationCosting"
              className={" estimationCosting"}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: "20px" }}>
                    Code
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>Name</TableCell>

                  <TableCell align="center" sx={{ minWidth: 185 }}>
                    Type
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 80 }}>
                    QTY
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 125 }}>
                    UOM
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 80 }}>
                    Height
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 80 }}>
                    Waste %
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 80 }}>
                    Total
                  </TableCell>
                  {isEdit && <TableCell align="right">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((row, i) => {
                  return (
                    <React.Fragment>
                      {isEdit ? (
                        <React.Fragment>
                          <TableRow key={row.itemId}>
                            <TableCell>
                              <TableCell align="center">{row.itemCode}</TableCell>
                            </TableCell>
                            <TableCell>
                              <Autocomplete
                                id="grouped-demo"
                                value={row.itemName}
                                options={lineItemsList}
                                groupBy={(option) => option.itemGroupName}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  else if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  else return `${option.itemCode}: ${option.itemName}`;
                                }}
                                onChange={(event, newValue) => {
                                  handleAutoselectLineItemName(i, row, newValue);
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some(
                                    (option) => inputValue === option.itemName
                                  );
                                  if (inputValue !== "" && !isExisting) {
                                    filtered.push(inputValue);
                                  }
                                  return filtered;
                                }}
                                fullWidth
                                size="small"
                                renderInput={(params) => <TextField {...params} />}
                                renderGroup={(params) => (
                                  <li>
                                    <GroupHeader>{params.group}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                  </li>
                                )}
                              />
                            </TableCell>

                            <TableCell align="center">
                              <Select
                                size="small"
                                fullWidth
                                name="type"
                                value={row.type}
                                onChange={(e) => handleInputChange(e, i)}
                              >
                                {userConfigs.config?.length != 0 &&
                                  userConfigs.config?.map(
                                    (el) =>
                                      el.enabled === true && (
                                        <MenuItem value={el.value}>{el.value}</MenuItem>
                                      )
                                  )}
                              </Select>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                id="qty-width"
                                variant="outlined"
                                fullWidth
                                width="10px"
                                size="small"
                                type="number"
                                value={row.qty}
                                name="qty"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            <TableCell align="center" id="uom">
                              <Select
                                size="small"
                                fullWidth
                                name="uom"
                                value={row.uom}
                                onChange={(e) => handleInputChange(e, i)}
                              >
                                {userConfigsuom.config?.length != 0 &&
                                  userConfigsuom.config?.map(
                                    (el) =>
                                      el.enabled === true && (
                                        <MenuItem value={el.value}>{el.value}</MenuItem>
                                      )
                                  )}
                              </Select>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                id="qty-width"
                                variant="outlined"
                                fullWidth
                                width="10px"
                                size="small"
                                type="number"
                                value={row.height}
                                name="height"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                id="qty-width"
                                variant="outlined"
                                fullWidth
                                width="10px"
                                size="small"
                                type="number"
                                value={row.wastagePercentage}
                                name="wastagePercentage"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                id="qty-width"
                                variant="outlined"
                                fullWidth
                                width="10px"
                                size="small"
                                type="number"
                                value={row.total}
                                name="total"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                            {isEdit && lineItems.length > 1 && (
                              <TableCell>
                                <ButtonGroup variant="contained" size="small">
                                  <Button
                                    color="secondary"
                                    key={row.itemId}
                                    onClick={(e) => clearLineItem(i)}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </Button>
                                </ButtonGroup>
                              </TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableRow key={row.lineItemId} hover>
                            <TableCell align="center">{row.itemCode}</TableCell>
                            <TableCell>{row.itemName}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">{row.qty}</TableCell>
                            <TableCell align="center">{row.uom}</TableCell>
                            <TableCell align="center">{row.height}</TableCell>
                            <TableCell align="center">{row.wastagePercentage}</TableCell>
                            <TableCell align="center">
                              <NumberFormat
                                value={row.total.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </Box>
    </div>
  );
};

export default LineItemsTableForPlans;
