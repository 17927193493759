/** @format */

import { AppBar, Button, Container, Drawer, Grid, ListItemText, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Quoterequests from "../../quotes/quoterequests/Quoterequests";
import NumberFormat from "react-number-format";
import { getGst } from "../../UI/GlobalApi";

function HeaderTotal({
  quote,
  setQuote,
  createQuote,
  type,
  handleSaveOuter,
  handleCancelOuter,
  disableSave,
  rows,
  setRows,
  setCreateQuote,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setWantToCallGetJobPlan,
  wantToCallGetJobPlan,
  setOpenErrDialog,
  setErrorCode,
  setErrorMsg,
  setValue,
  checkedJobPlanLineItemsForPlans,
  setPlanDrawerOpen,
}) {
  //calculation for est total act total and var total
  var subtotal = 0;
  var markup = 0;
  var TotalGst = 0;
  if (rows.length != 0) {
    var inputList = rows.filter((grp) => {
      grp.items.filter((e) => {
        var estTotal = e.unitCost * e.qty;
        subtotal = subtotal + estTotal;
        //for markup calculation
        if (e.markupPercentage != 0) {
          var markupRow = (estTotal * e.markupPercentage) / 100;
        } else {
          var markupRow = 0;
        }
        markup = markup + markupRow;
        //for individual row markup cost
        var RowMarkupTotalCost = markupRow + estTotal;
        var rowGST = 0;
        if (getGst(e.gst) != 0) {
          var rowGST = (RowMarkupTotalCost * getGst(e.gst)) / 100;
        }
        TotalGst = TotalGst + rowGST;
      });
    });
  }

  // var lineItemSum = 0;
  // var lineItems = rows.map((item) => {
  //   var len = item.lineItems.length;
  //   lineItemSum = lineItemSum + len;
  // });
  // var markupSum = 0;
  // var m = rows.map((item) => {
  //   var len = item.lineItems.map((m) => {
  //     markupSum += parseInt(m.markupPercentage);
  //   });
  // });

  //imp
  // var quoteTotalFinal = 0;
  // var quoteTotal = 0;
  // var m = rows.map((item) => {
  //   var len = item.lineItems.map((m) => {
  //     var excludingGST = parseInt(m.unitCost) + parseInt(m.qty);
  //     var gstAmount = excludingGST * (parseInt(m.gst) / 100);
  //     quoteTotal = excludingGST + gstAmount;
  //     quoteTotalFinal = parseFloat(quoteTotalFinal + quoteTotal).toFixed(2);
  //     setQuote(quoteTotalFinal);
  //   });
  // });
  console.log("HEADER TOTAL CALLED==>");
  return (
    <Grid>
      <div style={{ color: "gray", position: "fixed", bottom: 5, display: "flex" }}>
        <ListItemText>
          {type === "estimate" && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "sticky",
                left: "96px",
              }}
            >
              {createQuote.length != 0 && (
                <Quoterequests
                  createQuote={createQuote}
                  is_estimateQuote={true}
                  setCreateQuote={setCreateQuote}
                  setSnackbarMSG={setSnackbarMSG}
                  setSnackbarSeverity={setSnackbarSeverity}
                  setOpenSnackbar={setOpenSnackbar}
                  wantToCallGetJobPlan={wantToCallGetJobPlan}
                  setWantToCallGetJobPlan={setWantToCallGetJobPlan}
                  setOpenErrDialog={setOpenErrDialog}
                  setErrorCode={setErrorCode}
                  setErrorMsg={setErrorMsg}
                  setValue={setValue}
                />
              )}
            </Grid>
          )}
        </ListItemText>
        <ListItemText>
          <Grid sx={{ ml: 2 }}>
            {checkedJobPlanLineItemsForPlans.length > 0 && (
              <Button
                name="createCatalogue"
                variant="contained"
                color="secondary"
                align="right"
                onClick={() => {
                  setPlanDrawerOpen(true);
                }}
              >
                Add to TakeOffTool
              </Button>
            )}
          </Grid>
        </ListItemText>
      </div>
      <Grid item xs={5}>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0, zIndex: "100", maxWidth: "30%" }}
          style={{
            backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"}`,
          }}
        >
          <Container style={{ boxShadow: "none" }}>
            <Toolbar style={{ boxShadow: "none" }}>
              <ListItemText
                primary="Sub Total"
                secondary={
                  <NumberFormat
                    value={subtotal.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              ></ListItemText>
              <ListItemText
                primary="Markup"
                secondary={
                  <NumberFormat
                    value={markup.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              />
              <ListItemText
                primary="GST"
                secondary={
                  <NumberFormat
                    value={TotalGst.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              />
              <ListItemText
                primary="Quote Total"
                secondary={
                  <NumberFormat
                    value={(subtotal + markup + TotalGst).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
              />
              {disableSave ? (
                <></>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    align="right"
                    onClick={handleSaveOuter}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    align="right"
                    onClick={handleCancelOuter}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </Grid>
    </Grid>
  );
}

export default HeaderTotal;
