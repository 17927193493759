  import React from 'react';
  import { makeStyles } from '@mui/styles';
  import Card from '@mui/material/Card';
  import CardContent from '@mui/material/CardContent';
  import Typography from '@mui/material/Typography';
  
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      display:'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 14,
      paddingTop : 12
    },
  });
  
  export default function EmptyEstimatesDialog({heading,msg}) {
    const classes = useStyles();  
    return (
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" align="center">
            {heading}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          {msg}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  