/** @format */

import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { getAvailableConfigs } from "../EstimatesSettings/settings.helpers";

const listCommonStyle = {
  textDecoration: "none",
  color: "inherit",
};

const ChildSettingCommon = ({ index, toggleDrawer }) => {
  const [allConfigs, setAllConfigs] = useState();
  const [arr, setArr] = React.useState([]);
  const [codes, setCodes] = React.useState([]);

  var newArray = arr.filter((el) => {
    if (codes.length < allConfigs.config.length) {
      codes.push(el.code);
    }
  });
  console.log(codes);
  const [selectedSetting, setselectedSetting] = React.useState(0);

  useEffect(() => {
    getAvailableConfigs(setAllConfigs, setArr); //get all configs
  }, []);
  console.log("config types-- ", allConfigs);

  //   const [name, setName] = React.useState("demo");
  // const handleNameChange=(e,text)=>{
  // setName(text.code);
  // console.log("name",name)
  // }
  // if (index === 1) {
  return (
    <List>
      {codes.map((text) => (
        <Link to={`/estimatessetting/${text}`} style={listCommonStyle}>
          <ListItem button key={text}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={text} onClick={toggleDrawer("left", false, selectedSetting)} />
          </ListItem>
        </Link>
      ))}
    </List>
  );
  // } else if (index === 2) {
  //   return <h1>Quetes Setting</h1>;
  // } else if (index === 3) {
  //   return <h1>Job Setting</h1>;
  // } else if (index === 4) {
  //   return <h1>Client Setting</h1>;
  // } else if (index === 5) {
  //   return <h1>Contact Setting</h1>;
  // } else if (index === 6) {
  //   return <h4>Building Types Setting</h4>;
  // }
  // return <h1>{index}</h1>;
};

export default ChildSettingCommon;
