/** @format */

import apis from "../../utils/apis";

export const getUser = (setUser, setLoad) => {
  apis
    .GET({
      endpoint: "/user-accounts",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.contactNo - b.contactNo;
      });

      setUser(res.data);
      console.log(res.data);
      setLoad(false);
    });
};
export const getRolesForUser = (setUser) => {
  apis
    .GET({
      endpoint: "/user-roles",
    })
    .then((res) => {
      // res.data.sort(function (a, b) {
      //   return a.contactNo - b.contactNo;
      // });

      setUser(res.data);
      console.log(res.data);
    });
};
export const createUser = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallGet,
  wantToCallGet,
  commonToggle,
  setLoading
) => {
  setLoading(true);
  apis
    .POST({
      endpoint: "/user-account",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setOpenSnackbar(true);
        setSnackbarMSG("User created successFully");
        setSnackbarSeverity("success");
        commonToggle(false);
      } else {
        setOpenSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
      }
      setLoading(false);
    });
};
export const updateUser = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallGet,
  wantToCallGet,
  setLoading,
  commonToggle
) => {
  setLoading(true);
  apis
    .PUT({
      endpoint: "/user-account",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("User updated successfully");
        setSnackbarSeverity("success");
        setLoading(false);
        commonToggle(false);
        setOpenSnackbar(true);

        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setLoading(false);
        commonToggle(false);
        setOpenSnackbar(true);
      }
    });
};
export const deleteUser = (
  deleteUserName,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  userData,
  serUserData,
  setLoading
) => {
  setLoading(true);
  apis
    .POST({
      endpoint: "/delete-user-account",
      payload: deleteUserName,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("User deleted successFully");
        setSnackbarSeverity("success");
        var newData = userData.filter((element) => {
          if (element.username != deleteUserName.username) {
            return element;
          }
        });
        serUserData(newData);
        setLoading(false);
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setLoading(false);
        setOpenSnackbar(true);
      }
    });
};
function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
