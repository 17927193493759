import React, { useEffect, useContext, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { OAuthCtx } from "../../oauth/OAuthContext";
import { setupAuthInterceptor } from "../../utils/apis";
import { saveTargetPath } from "../../utils/storage";
import Loading from '../UI/Loading';

const ProtectedRoute = ({ component: Component,path, ...rest }) => {
    const location = useLocation();
    const[isLoggedIn, setLoggedIn] = useState(false);
    const {getAuthState, redirectUrl} = useContext(OAuthCtx);
    setupAuthInterceptor(getAuthState, redirectUrl);

    useEffect(() => {
        saveTargetPath(location.pathname)
        setLoggedIn(getAuthState().isLoggedIn);
    });

    if(isLoggedIn) {
        return <Route exact path={path} {...rest} render={
            props => <Component {...rest} {...props} />
            } />
    } else {
        return <Loading/>
    }
}

export default ProtectedRoute;