/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Radio from "@mui/material/Radio";
import { InputAdornment, Paper, Tooltip, Zoom } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TableContainer,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { v4 as uuid } from "uuid";
import {
  getEstimateJobPlanItem,
  getJobPlanItem,
  tempEstimate,
  updateJobPlanItemByEstimateJobPlanId,
  updateJobPlanItemByJobId,
  updateJobPlanItemByJobPlanId,
  updateJobPlanItemsByEstimateId,
} from "./jobplan.helpers";
import { JOB_PLAN_DELETE, JOB_PLAN_WRITE } from "../../UI/GlobalVariables";

import Delete from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import NumberFormat from "react-number-format";
import { Autocomplete } from "@mui/material";

import { fn } from "moment";
import MUIDataTable from "mui-datatables";
import { getGst } from "../../UI/GlobalApi";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    Drawerroot: {
      width: "100%",
      padding: 16,
    },
  },
});
export default function NewJobPlanPrice({
  rows,
  inputList,
  itemRow,
  setDisableSave,
  priceOption,
  setPriceOption,
  jobPlanId,
  setInputList,
  props,
  estimateId,
  hasValidAccess,
  userConfigs,
  userConfigsuom,
  catalogueItems,
  setRoundLoader,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setWantToCallGetJobPlan,
  editableJobplan,
  estimateStatus
}) {
  const classes = useStyles();
  const [priceOptionArr, setPriceOptionArr] = React.useState([]);
  const [isEdit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [indexForAutocomplete, setIndexForAutocomplete] = React.useState();
  const [state, setState] = React.useState({
    right: false,
  });
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [responsive, setResponsive] = useState("standard");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [print, setPrint] = useState(false);
  const [dummyRows, setDummyRows] = useState();
  const [dummyTempGroups, setDummyTempGroups] = useState();

  /* ---------------------------------------------------------------- */

  const handleEdit = (i) => {
    ////setCallGetJobPlan(true);

    setDisableSave(true);
    setEdit(true);
  };

  useEffect(() => {
    /* i copied object using JSON method bcz  i have to copy object without any object refrence */
    setPriceOptionArr(JSON.parse(JSON.stringify(priceOption)));
    console.log("useEffect", priceOption);
  }, [priceOption]);

  /* ---------------------------------------------------------------- */
  function callUpdateApi() {
    if (props.type === "estimate") {
      console.log("if estimate", estimateId);
      tempEstimate(
        estimateId,
        rows,
        setDummyRows,
        setRoundLoader,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setWantToCallGetJobPlan
      );
    } else if (props.type === "jobPlan") {
      console.log(" else jobPlan");
      updateJobPlanItemByJobId(
        jobPlanId,
        rows,
        setDummyTempGroups,
        setRoundLoader,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG);
    }
  }

  /* ---------------------------------------------------------------- */
  const handleCancelIcon = () => {
    setPriceOptionArr(priceOption);
    setPriceOption(priceOption);
    setDisableSave(true);
    setEdit(false);
  };

  /* ---------------------------------------------------------------- */

  // const handleCancel = () => {
  //   setEdit(false);
  //   setDisableSave(true);
  //   var listfilter = priceOptionArr.filter(function (el) {
  //     return el.optionId != null;
  //   });
  //   console.log("listfilter", listfilter);
  //   setPriceOptionArr(listfilter);
  // };

  /* ---------------------------------------------------------------- */

  const handleAdd = () => {
    setDisableSave(true);
    const unique_id = uuid();
    setEdit(true);
    var newElement = {
      optionId: unique_id,
      optionName: "",
      itemDescription: "",
      type: "",
      qty: 1,
      uom: "",
      unitCost: 0,
      markupPercentage: 0,
      gst: true,
      included: true,
    };
    setPriceOptionArr([...priceOptionArr, newElement]);
  };

  const [checked, setChecked] = React.useState(itemRow.optionId);

  /* ---------------------------------------------------------------- */
  const assignData = (itm, el) => {
    itm.optionId = el.optionId;
    itm.optionName = el.optionName;
    itm.included = el.included;
    itm.type = el.type;
    itm.qty = el.qty;
    itm.uom = el.uom;
    itm.unitCost = el.unitCost;
    itm.markupPercentage = el.markupPercentage;
    itm.gst = el.gst;
    itm.itemDescription = el.itemDescription;
  };
  /* ---------------------------------------------------------------- */

  const handleCheckbox = (e, row) => {
    setChecked(e.target.value);
    console.log("itemRow", itemRow);

    var checkeData;
    rows.filter((item) => {
      item.items.filter((itemSecond) => {
        if (itemRow.itemId === itemSecond.itemId) {
          checkeData = inputList.filter(function (item) {
            if (item.itemId === itemRow.itemId) {
              assignData(item, row);
            }
            return item;
          });
          item.items = checkeData;
        }
      });
    });
    setInputList(checkeData); // set new updated value to inputList
    setDisableSave(false);
  };

  /* ---------------------------------------------------------------- */
  const handleChangeIncluded = (e, i, row) => {
    if (!e.target.checked) {
      newIncludedValue(false);
    } else {
      newIncludedValue(true);
    }
    function newIncludedValue(value) {
      const list = JSON.parse(JSON.stringify(priceOptionArr));
      list[i][e.target.name] = value;
      setPriceOptionArr(list);
    }
  };
  /* ---------------------------------------------------------------- */

  const handleChangeGST = (e, i, row) => {
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(priceOptionArr));
      list[i][e.target.name] = value;
      setPriceOptionArr(list);
    }
    console.log("before ", row.gst);
  };

  /* ---------------------------------------------------------------- */
  const handleInputChange = (e, index, row) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(priceOptionArr));
    list[index][name] = value;
    setPriceOptionArr(list);
  };
  /* ---------------------------------------------------------------- */
  function updateValuesInRows(finalJobLineObj) {
    console.log("priceOptionArr: ", finalJobLineObj);
    rows.filter((item) => {
      item.items.filter((itemSecond) => {
        if (itemRow.itemId === itemSecond.itemId) {
          finalJobLineObj.filter((el) => {
            if (checked === el.optionId) {
              assignData(itemSecond, el);
              console.log("item.items", item.items);
              console.log("checked", checked);
            }
          });
          itemSecond.priceOptions = finalJobLineObj;
          setInputList(item.items); // set new updated value to inputList
        }
      });
    });
    console.log("rows", rows);
    setPriceOption(finalJobLineObj);
  }
  /* ---------------------------------------------------------------- */

  const handleSaveInPriceTable = () => {
    var finalJobLineObj = priceOptionArr.filter((el) => {
      if (el.optionName != "" && el.optionId != "") {
        return el;
      }
    });
    updateValuesInRows(finalJobLineObj);
    callUpdateApi();
    setEdit(false);
    setDisableSave(true);
  };

  /* ---------------------------------------------------------------- */
  function filterInputListForClearAndDeleteLineItem(row) {
    let listfilter = priceOptionArr.filter((el) => {
      return el.optionId != row.optionId;
    });

    return listfilter;
  }
  /* ---------------------------------------------------------------- */

  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setPriceOptionArr(deletedData);
    setDisableSave(false);
  };

  /* ---------------------------------------------------------------- */
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [priceOptionToDelete, setPriceOptionToDelete] = React.useState();

  const handleConfirm = (row) => {
    console.log("setItemRowToDelete ", row);
    setPriceOptionToDelete(row);
    setShowConfirm(true);
  };

  const handleNo = () => {
    setShowConfirm(false);
  };

  /* ---------------------------------------------------------------- */

  const deleteItemNonEditMode = () => {
    let deletedData = filterInputListForClearAndDeleteLineItem(priceOptionToDelete);
    setPriceOptionArr(deletedData);
    updateValuesInRows(deletedData);
    callUpdateApi();
    setShowConfirm(false);
  };
  /* ----------------------Function-For-Drawer------------------------------------------ */
  const handleOpenSearchCatalogueDrawer = (e, i) => {
    setIndexForAutocomplete(i);
    setState({ ...state, ["right"]: true });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleInputAutocomplete = (index, newValue) => {
    console.log("New Value:", newValue);
    const list = JSON.parse(JSON.stringify(priceOptionArr));
    list[index] = {
      optionId: uuid(),
      optionName: "",
      itemDescription: newValue.itemDescription,
      type: newValue.type,
      qty: newValue.qty,
      uom: newValue.uom,
      unitCost: newValue.unitCost,
      markupPercentage: newValue.markupPercentage,
      gst: newValue.gst,
      included: true,
    };
    console.log("List:", list);

    setPriceOptionArr(list);
  };
  // ---------------------------------COLUMS-----------------------------------
  const columns = [
    {
      name: "catalogueName",
      label: "Catalogue Name",
      options: {
        filter: true,

        sort: true,
        display: true,
      },
    },
    {
      name: "itemDescription",
      label: "Item Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "markupPercentage",
      label: "Markup",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },
    {
      name: "variancePercentage",
      label: "Variance",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },
    {
      name: "gst",
      label: "GST",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },
    {
      name: "unitCost",
      label: "Unit Cost",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "center",
        }),
      },
    },
    {
      name: "itemId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (itemId, tableMeta) => {
          return (
            <ButtonGroup variant="contained" size="small">
              <Button
                size="small"
                color="primary"
                target="_self"
                onClick={() => {
                  handleSelectCatalogueItem(tableMeta.rowIndex);
                }}
              >
                <CheckCircleIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  // -----------------------------OPTIONS---------------------------------------
  const options = {
    viewColumns: false,
    tableBodyHeight,
    tableBodyMaxHeight,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: "single",
    download: false,
    searchOpen: true,
    filter: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  /* ---------------------------------------------------------------- */
  const handleSelectCatalogueItem = (itemIndex) => {
    const selectedItem = catalogueItems[itemIndex];
    console.log("selectedItem:", selectedItem);
    const list = JSON.parse(JSON.stringify(priceOptionArr));
    list[indexForAutocomplete] = {
      ...list[indexForAutocomplete],
      itemDescription: selectedItem.itemDescription,
      unitCost: selectedItem.unitCost,
      markupPercentage: selectedItem.markupPercentage,
      gst: selectedItem.gst,
    };
    setPriceOptionArr(list);
    setState({ ...state, ["right"]: false });
  };
  /* ---------------------------------------------------------------- */
  return (
    <>
      <Box>
        <React.Fragment>
          {priceOptionArr.length === 0 ? (
            <>
              <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                <AddIcon onClick={handleAdd} fontSize="small" />
                ADD
              </Button>
              <EmptyEstimatesDialog heading="no price option available" msg="add price options" />
            </>
          ) : (
            <TableContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {hasValidAccess(JOB_PLAN_WRITE) && (
                  <Box component="span" p={1}>
                    {!isEdit ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        {props.type === "jobPlan" ? (
                          editableJobplan ? (
                            <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                              <AddIcon fontSize="small" />
                              ADD
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleAdd}
                              size="small"
                              disabled
                            >
                              <AddIcon fontSize="small" />
                              ADD
                            </Button>
                          )
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            size="small"
                            disabled={estimateStatus !== "DRAFT" ? true : false}
                          >
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>
                        )}
                        {props.type === "jobPlan" ? (
                          editableJobplan ? (
                            <Button
                              variant="contained"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                              size="small"
                            >
                              <CreateIcon fontSize="small" />
                              EDIT
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                              size="small"
                              disabled
                            >
                              <CreateIcon fontSize="small" />
                              EDIT
                            </Button>
                          )
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            align="right"
                            onClick={handleEdit}
                            size="small"
                            disabled={estimateStatus !== "DRAFT" ? true : false}
                          >
                            <CreateIcon fontSize="small" />
                            EDIT
                          </Button>

                        )}
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button variant="contained" onClick={handleAdd} size="small" color="primary">
                          <AddIcon onClick={handleAdd} fontSize="small" />
                          ADD
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleCancelIcon}
                          size="small"
                          color="secondary"
                        >
                          <ClearIcon onClick={handleCancelIcon} fontSize="small" />
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSaveInPriceTable}
                          align="right"
                          size="small"
                        >
                          <CheckCircleOutlineIcon onClick={handleSaveInPriceTable} fontSize="small" />
                          Save
                        </Button>
                      </ButtonGroup>
                    )}
                  </Box>
                )}
              </div>
              <Table
                sx={{ minWidth: 650, borderBottom: "none" }}
                size="small"
                className={"estimate-option_table"}
              >
                <TableHead>
                  <TableRow>
                    {!isEdit && <TableCell align="center"></TableCell>}
                    <TableCell>Option</TableCell>
                    <TableCell width={"20%"}>Description</TableCell>

                    <TableCell align="right">QTY</TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 180 }}
                      className="muitable_cell_title_type"
                    >
                      Type
                    </TableCell>
                    <TableCell align="right">Unit Cost</TableCell>
                    <TableCell align="center" sx={{ minWidth: 120 }} className="muitable_cell_title_uom">
                      UOM
                    </TableCell>
                    <TableCell align="right">Markup</TableCell>
                    <TableCell align="right">GST</TableCell>
                    <TableCell align="center">Included</TableCell>
                    {hasValidAccess(JOB_PLAN_WRITE) && (
                      <> {priceOptionArr.length > 1 && <TableCell align="center">Actions</TableCell>}</>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {priceOptionArr.map((row, i) => {
                    return (
                      <React.Fragment>
                        {isEdit ? (
                          <React.Fragment>
                            <TableRow key={row.optionId}>
                              <TableCell>
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.optionName}
                                  name="optionName"
                                  onChange={(e) => handleInputChange(e, i, row)}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  className={classes.textField}
                                  value={row.itemDescription}
                                  name="itemDescription"
                                  onChange={(e) => handleInputChange(e, i, row)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <SearchIcon
                                          size="small"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => handleOpenSearchCatalogueDrawer(e, i)}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>

                              <TableCell
                                align="center"
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={row.qty}
                                  name="qty"
                                  onChange={(e) => handleInputChange(e, i, row)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  name="type"
                                  fullWidth
                                  value={row.type}
                                  onChange={(e) => handleInputChange(e, i, row)}
                                >
                                  {userConfigs.config?.length != 0 &&
                                    userConfigs.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={row.unitCost}
                                  name="unitCost"
                                  onChange={(e) => handleInputChange(e, i, row)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  name="uom"
                                  value={row.uom}
                                  onChange={(e) => handleInputChange(e, i, row)}
                                >
                                  {userConfigsuom.config?.length != 0 &&
                                    userConfigsuom.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={row.markupPercentage}
                                  name="markupPercentage"
                                  onChange={(e) => handleInputChange(e, i, row)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {row.gst === true ? (
                                  <Checkbox
                                    size="small"
                                    value={10}
                                    checked
                                    name="gst"
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    value={0}
                                    name="gst"
                                    // defaultChecked
                                    onChange={(e) => handleChangeGST(e, i, row)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {row.included ? (
                                  <Checkbox
                                    size="small"
                                    value={true}
                                    checked
                                    name="included"
                                    onChange={(e) => handleChangeIncluded(e, i, row)}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    value={false}
                                    name="included"
                                    onChange={(e) => handleChangeIncluded(e, i, row)}
                                  />
                                )}
                              </TableCell>
                              {hasValidAccess(JOB_PLAN_WRITE) && (
                                <>
                                  {isEdit && (
                                    <>
                                      {checked != row.optionId && (
                                        <TableCell align="center">
                                          <ButtonGroup variant="contained" size="small">
                                            <Button
                                              size="small"
                                              color="secondary"
                                              className="mr10"
                                              key={row.optionId}
                                              onClick={(e) => clearLineItem(row)}
                                            >
                                              <ClearIcon fontSize="small" />
                                            </Button>
                                          </ButtonGroup>
                                        </TableCell>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </TableRow>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableRow key={row.optionId}>
                              <TableCell align="center">
                                <Radio
                                  size="small"
                                  name="priceOptionCheck"
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={checked === row.optionId}
                                  value={row.optionId}
                                  onChange={(e) => {
                                    handleCheckbox(e, row);
                                  }}
                                />
                                {/* )} */}
                              </TableCell>
                              <TableCell>{row.optionName}</TableCell>
                              <TableCell>{row.itemDescription}</TableCell>
                              <TableCell align="right">{row.qty} </TableCell>
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(row.unitCost * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>
                              <TableCell align="center">{row.uom}</TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(row.markupPercentage * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />
                              </TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(getGst(row.gst) * 1).toFixed(1)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {row.included ? (
                                  <Checkbox
                                    size="small"
                                    value={true}
                                    checked
                                    name="included"
                                    onChange={(e) => handleChangeIncluded(e, i, row)}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    value={false}
                                    name="included"
                                    onChange={(e) => handleChangeIncluded(e, i, row)}
                                  />
                                )}
                              </TableCell>{" "}
                              {hasValidAccess(JOB_PLAN_WRITE) && (
                                <>
                                  {checked != row.optionId && (
                                    <TableCell align="center">
                                      <ButtonGroup
                                        variant="contained"
                                        aria-label="outlined primary button group"
                                      >
                                        <Tooltip
                                          title="DELETE OPTION"
                                          placement="top"
                                          TransitionComponent={Zoom}
                                          arrow
                                        >
                                          <Button
                                            color="secondary"
                                            align="right"
                                            onClick={(e) => handleConfirm(row)}
                                            size="small"
                                          >
                                            <Delete fontSize="small" />
                                          </Button>
                                        </Tooltip>
                                      </ButtonGroup>
                                    </TableCell>
                                  )}
                                </>
                              )}
                            </TableRow>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </React.Fragment>
        {showConfirm && (
          <div>
            <Dialog
              open={showConfirm}
              onClose={handleNo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteItemNonEditMode} color="primary" autoFocus>
                  Yes
                </Button>
                <Button onClick={handleNo} color="primary" autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Box>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontSize: "20px",
                }}
              >
                Select Catalogue Item:
              </Typography>
              <Divider />
            </Grid>
            {/* <Grid item xs={12}>
              <Autocomplete
                options={catalogueItems}
                disableClearable
                size="small"
                min-width="120"
                id="itemDescription"
                onChange={(event, newValue) => {
                  handleInputAutocomplete(indexForAutocomplete, newValue);
                }}
                getOptionLabel={(option) => option.itemDescription}
                renderOption={(option) => (
                  <>
                    {option.catalogueName}:{option.itemDescription}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="itemDescription"
                    placeholder="Select"
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Paper style={{ width: "100%", overflow: "hidden" }}>
                <MUIDataTable data={catalogueItems} columns={columns} options={options} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
