/** @format */

import { makeStyles } from "@mui/styles";

const errorStyles = makeStyles((theme) => ({
  errorMsg: {
    marginBottom: 0,
    color: "red",
  },
}));

export default errorStyles;
