/** @format */

import { useState } from "react";
import apis from "../../../utils/apis";

export const getTakeoffsByEstimatesbyID = (setRow, setLoad, estimateID) => {
  //   if (!estimateID) {
  //     window.location.replace("/estimate/");
  //   }
  //   setLoad(true);
  //   apis
  //     .GET({
  //       endpoint: `/estimate/takeoffs/${estimateID}`,
  //     })
  //     .then((res) => {
  //       setLoad(false);
  //       // if (res.data.message === "SUCCESS") {
  //       //   setRow(res.data);
  //       // } else {
  //       //   setRow([]);
  //       // }
  //     });
};

export const createTakeoff = (arr, setLoad, estimateID, setOpen) => {
  //   setLoad(true);
  //   setOpen(false);
  //   apis
  //     .PUT({
  //       endpoint: `/estimate/takeoffs/${estimateID}`,
  //       payload: arr,
  //       options: {
  //         type: "json",
  //       },
  //     })
  //     .then((res) => {
  //       setLoad(false);
  //       setOpen(true);
  //       // console.log("data posted successFully>>>",arr)
  //       //  console.log("His-> ",history);
  //     });
};

/*------------------------ get plans--------------------------------- */

export const getFileByUSingUrl = (
  estimateId,
  instance,
  setPlanObject,
  autoSelectPlanName,
  setAutoSelectPlanName,
  setLoad,
  planName
) => {
  apis
    .GET({
      endpoint: `/estimate/documents/${estimateId}`,
    })
    .then((res) => {
      setLoad(true);
      if (res.status === 200) {
        if (res.data.length === 1 || autoSelectPlanName === null) {
          setAutoSelectPlanName(res.data[0]);
        } else {
          /* set same new defualt value in autoSelect for getting annotation */
          if (planName) {
            res.data.map((el) => {
              if (el.planName === planName) {


                setAutoSelectPlanName(el);
                if (instance) {
                  instance.UI.loadDocument(el.documentUrl);
                }
              }
            });
          } else {
            res.data.map((el) => {
              if (el.planName === autoSelectPlanName.planName) {

                setAutoSelectPlanName(el);
                if (instance) {
                  instance.UI.loadDocument(el.documentUrl);
                }
              }
            });
          }
        }
        setPlanObject(res.data);
        setLoad(false);
      }
    });
};

/*------------------------ Create Plan--------------------------------- */

export const createPlan = (
  estimateId,
  data,
  uploadDocumentFile,
  setWantToCallUseEffect,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  state
) => {
  setWantToCallUseEffect(true);
  setRoundLoader(true);
  apis
    .POST({
      endpoint: `/estimate/document/${estimateId}`,
      payload: { planName: data.planName },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {

        setSnackbarMSG("Plan Created Successfully");
        /* this function is used for upload document using plan name url */
        uploadDocumentFile(
          res.data.value,
          data.file,
          setWantToCallUseEffect,
          setOpenCustSnackbar,
          setSnackbarSeverity,
          setRoundLoader,
          setState,
          state
        );
      } else {

        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        setRoundLoader(false);
      }
    });
};
/*------------------------ Update Plan--------------------------------- */

export const updatePlan = (
  estimateId,
  data,
  uploadDocumentFile,
  setWantToCallUseEffect,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  state
) => {
  setWantToCallUseEffect(true);
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/document/${estimateId}`,
      payload: { planName: data.planName },
    })
    .then((res) => {


      if (res.status === 200) {
        setSnackbarMSG("Plan Updated Successfully ");
        uploadDocumentFile(
          res.data.value,
          data.file,
          setWantToCallUseEffect,
          setOpenCustSnackbar,
          setSnackbarSeverity,
          setRoundLoader,
          setState,
          state
        );
      } else {
        setOpenCustSnackbar(true);
        setSnackbarSeverity("error");
        setRoundLoader(false);
      }
    });
};

/* ---------------------Upload Document  ------------------------- */

export const uploadDocumentFile = (
  fileUploadUrl,
  fileData,
  setWantToCallUseEffect,
  setOpenCustSnackbar,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  state
) => {
  apis
    .S3_DOC_PUT({
      endpoint: fileUploadUrl,
      payload: fileData,
    })
    .then((res) => {
      if (res.status === 200) {


        setWantToCallUseEffect(false);
        /* snack msg set in create and update function */
        setOpenCustSnackbar(true);
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ...state, ["right"]: false });
      }
    });
};

/*------------------------ export annotation--------------------------------- */

export const annotationChangedApi = (
  estimateId,
  planObj,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallUseEffect
) => {
  //setWantToCallUseEffect(true);
  apis
    .PUT({
      endpoint: `/estimate/document-annotations/${estimateId}`,
      payload: planObj,
      options: {
        type: "json",
      },
    })
    .then((res) => {

      if (res.data.message === "SUCCESS") {
        setOpenCustSnackbar(true);
        setSnackbarMSG("saved");
        setSnackbarSeverity("success");
        //setWantToCallUseEffect(false);

      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.message);
        setSnackbarSeverity("error");
      }
    });
};

/*------------------------ delete plan--------------------------------- */
export const deletePlan = (
  estimateId,
  autoSelectPlanName,
  setAutoSelectPlanName,
  planObject,
  wantToCallUseEffect,
  setWantToCallUseEffect,
  setRoundLoader,
  setSnackbarMSG,
  setOpenCustSnackbar,
  setSnackbarSeverity
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/delete-document/${estimateId}`,
      payload: { planName: autoSelectPlanName.planName },
      options: {
        type: "json",
      },
    })
    .then((res) => {

      if (res.data.message === "SUCCESS") {
        if (planObject.length != 1) {
          let finalData = planObject.filter((el) => {
            if (el.planName != autoSelectPlanName.planName) {
              return el;
            }
          });


          setAutoSelectPlanName(finalData[0]);
        }
        if (wantToCallUseEffect) {
          setWantToCallUseEffect(false);
        } else {
          setWantToCallUseEffect(true);
        }
        setSnackbarMSG("Plan Deleted Successfully");
        setSnackbarSeverity("success");
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG(res.data.message);
        setSnackbarSeverity("error");
        setOpenCustSnackbar(true);
      }
      setRoundLoader(false);
    });
};
