/** @format */

import apis from "../../utils/apis";

export const createJob = (
  obj,
  setOpenSnackbar,
  setCreateJobOpenDrawer,
  history,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  setOpenSnackbar(false);
  apis
    .POST({
      endpoint: "/job",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        setCreateJobOpenDrawer(false);
        setSnackbarMSG("Job created successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setRoundLoader(false);
        console.log("jobs data posted successFully>>>");
        console.log("found Job id : ", res.data.value);
        let location = {
          jobId: res.data.value,
          pathname: `/job/${res.data.value}/Costings`,
        };
        history.push(location);
        window.location.replace(`/job/${res.data.value}/Costings`);
      } else {
        setSnackbarMSG(res.data.errorMessage || "Job Creation failed !");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setRoundLoader(false);
        setCreateJobOpenDrawer(false);
        console.log("jobs posting failed>>>");
      }
    });
};

export const getJobs = (setJobArr, setLoad) => {
  setLoad(true);
  apis
    .GET({
      endpoint: "/jobs",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.jobNo - b.jobNo;
      });
      console.log("rendering estimate...");
      console.log(res.data);
      setJobArr(res.data);
      setLoad(false);
    });
};

export const getJobByJobId = (setData, jobId, setLoad, setJobStatus) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/job/${jobId}`,
    })
    .then((res) => {
      setData(res.data);
      setLoad(false);
      setJobStatus(res.data.status);
    })
    .catch((err) => console.log("No costings data "));
};

export const deleteJob = (
  jobId,
  jobsArr,
  setJobsArr,
  setRoundLoad,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  setRoundLoad(true);
  apis
    .DELETE({
      endpoint: `/job/${jobId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        var newData = jobsArr.filter((job) => {
          if (job.jobId != jobId) {
            return job;
          }
        });
        setJobsArr(newData);
        console.log("JOB Deleted:", res);
        setSnackbarMSG("Job Deleted Succesfully");
        setSnackbarSeverity("success");
        setRoundLoad(false);
        setOpenSnackbar(true);
      } else {
        setJobsArr(jobsArr);
        setSnackbarMSG("Job Deletion Failed");
        setSnackbarSeverity("error");
        setRoundLoad(false);
        setOpenSnackbar(true);
      }
    });
};
/* ************ Job Details **********************/
export const updateJobDetails = (
  jobId,
  obj,
  setRoundLoader,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setJobStatus
  // setEstimateStatus
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/job/${jobId}`,

      payload: obj,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data.message == "SUCCESS") {
        //setEstimateStatus(obj.status);
        setJobStatus(obj.status);
        setSnackbarMSG("Job Details Update successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Job Details Update failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
