/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Chip, Tooltip, Zoom, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import OrderTable from "./OrderTable";
import {
  getPurchaseOrder,
  getWorkOrder,
  getWorkOrderById,
  updateWorkOrder,
  getPurchaseOrderById,
  updatePurchaseOrder,
  getPurchaseOrderDataForPdf,
  getWorkOrderDataForPdf,
  createNewPurchaseOrder,
  createNewWorkOrder,
  getPurchaseOrderByJobLineItemId,
  getPurchaseOrderByJobId,
  getPurchaseOrdersByJobId,
  getWorkOrdersByJobId,
} from "./Order.helper";
import ProgressBar from "../../UI/ProgressBar";
import { Link, useParams } from "react-router-dom";
import { getContacts } from "../../quotes/helpers.quoteRequestAPI";
import { Snackbar } from "@mui/material";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { useForm, Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import ErrorStyles from "../../UI/ErrorStyles";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DateCreator from "../../UI/DateCreator";
import InvoiceLineItemsTable from "./InvoiceLineItemsTable";
import NewOrderLineItemsTable from "./NewOrderLineItemsTable";
import { checkProperties, getGst, getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import { StyledDropZone } from "../../estimates/TakeoffPlans/StyledDropZone";
import { set } from "date-fns";
import MomentDate from "../../UI/MomentDate";
import InvoicesByOrder from "./InvoicesByOrder";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOrderAlertPop from "./DeleteOrderAlertPop";
import { createInvoice } from "./Invoice.helper";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import {
  getJobLineItemsByJobId,
  getJobLineItemsByJobIdForAutoselect,
  getJobLineItemsGroupByJobIdForAutoselect,
} from "../actual_costings/helpers.actual.costings";
import { WORK_ORDER_WRITE, PURCHASE_ORDER_WRITE } from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import CreateContactDrawer from "../../contacts/CreateContactDrawer";
import { getJobByJobId } from "../jobs.helper";
import {
  createNewTemplate,
  EditTemplate,
  DeleteTemplate,
  getTermsAndCondtions,
  getTermsAndCondtionsById,
} from "./Terms.helper";
import TemplateLineItemsTable from "./TemplateLineItemsTable";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function Order(props) {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  var { jobId } = useParams();
  const { type, newlyAdded } = props;
  const useStyles = makeStyles((theme) => ({
    Drawerroot: {
      width: "100%",
      padding: 16,
    },
    textField: {
      width: "100%",
    },
  }));

  useEffect(() => {
    if (newlyAdded?.addedType === props.whichOrder) {
      let oldData = [...orderList];
      oldData.push(newlyAdded.item);
      setOrderList(oldData);
    }
  }, [newlyAdded]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  const [invoiceState, setInvoiceState] = React.useState({
    right: false,
  });
  const [newOrderState, setNewOrderState] = React.useState({
    right: false,
  });
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [defaultValueTemplate, setDefaultValueTemplate] = useState(0);
  const [newTemplateState, setNewTemplateState] = React.useState(false);
  const [editemplateState, setEditTemplateState] = React.useState(false);
  const [newTemplateData, setNewTemplateData] = React.useState({
    templateName: "",
    termsAndConditions: [],
  });
  /* DataTable */
  const columns = [
    {
      name: "orderNo",
      label: "Order No.",
      options: {
        filter: true,
        sort: true,
        display: true,
        // setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "600px", maxWidth: "650px" },
        }),
      },
    },
    {
      name: "contact",
      label: "Supplier",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <div>{value.contactName}</div>;
          } else {
            return <div>{""}</div>;
          }
        },
      },
    },
    {
      name: "requiredBy",
      label: "Required By",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return <MomentDate getDate={value} />;
        },
      },
    },
    {
      name: "totalOrderAmount",
      label: "Total Amount",
      options: {
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "center",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <span>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}
          </span>
        ),
      },
    },
    {
      name: "totalInvoicedAmount",
      label: "Invoiced Amount",
      options: {
        setCellProps: () => ({
          style: { minWidth: "50px", maxWidth: "50px" },
          align: "center",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        filter: true,
        sort: true,
        display: props.whichOrder === "purchaseOrder" ? true : false,
        customBodyRender: (value) => (
          <span>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)}
          </span>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        style: { minWidth: "50px", maxWidth: "50px" },
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === "DRAFT") {
            return <Chip size="small" label="Draft" />;
          } else if (value === "COMPLETED") {
            return <Chip size="small" label="Completed" color="primary" />;
          } else if (value === "CANCELLED") {
            return <Chip size="small" label="Cancelled" color="error" />;
          } else if (value === "SENT") {
            return <Chip size="small" label="Sent" color="warning" />;
          } else if (value === "RECIEVED") {
            return <Chip size="small" label="Recieved" color="primary" />;
          } else if (value === "PART RECIEVED") {
            return <Chip size="small" label="Part Recieved" color="warning" />;
          }
        },
      },
    },
    {
      name: "description",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup>
              <Tooltip title="Edit Order" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  onClick={(e) => {
                    handleOpenDrawer(orderList[tableMeta.rowIndex].orderId);
                  }}
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              {props.whichOrder === "purchaseOrder" && (
                <Tooltip title="Invoice/Reciept" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    onClick={(e) => {
                      handleOpenInvoiceDrawer(orderList[tableMeta.rowIndex].orderId);
                    }}
                    color="primary"
                    size="small"
                    variant="contained"
                  >
                    <ReceiptIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Delete Order" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    setConfirmDelete(true);
                    setOrderIdToDelete(orderList[tableMeta.rowIndex].orderId);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(PURCHASE_ORDER_WRITE) || hasValidAccess(WORK_ORDER_WRITE) ? true : false,
      },
    },
  ];
  /* useState for DataTable */
  const ErrorClasses = ErrorStyles();
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowData, setRowData] = useState({});
  const [pdfData, SetPdfData] = useState({});
  const [userConfigs, setUserConfigs] = useState({});
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const [dummyLoad, setDummyLoad] = useState(false);
  const options = {
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsOnClick: true,
    responsive,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRows: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    expandableRows:
      props.whichOrder === "purchaseOrder" || props.whichOrder === "lineItemOrders" ? true : false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },

    onTableChange: (action, state) => { },

    onRowsDelete: (rowsDeleted, newData) => {
      // handleDelete(rowsDeleted, newData);
    },
    // onRowClick: (rowData, rowMeta) => {
    //   console.log("rowData:", rowData);
    //   console.log("rowMeta:", rowMeta);
    //   handleOpenDrawer(orderList[rowMeta.rowIndex].orderId);
    // },
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <InvoicesByOrder
          orderId={orderList[rowMeta.rowIndex].orderId}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          type={"purchaseOrder"}
          setRoundLoader={setRoundLoader}
          userConfigs={userConfigs}
          userConfigsuom={userConfigsuom}
          lineItemsForAutoselect={lineItemsForAutoselect}
          lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
        />
      );
    },
  };
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderList, setOrderList] = useState([]);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [orderData, setOrderData] = useState({
    orderId: "",
    description: "",
    message: "",
    contact: { contactName: "" },
    requiredBy: "",
    send: "",
    orderLineItems: [],
  });
  const [invoiceData, setInvoiceData] = useState({
    orderId: "",
    referenceNo: "",
    invoiceDate: new Date()
      .toLocaleString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
    notes: "",
    invoiceLineItems: [],
    status: "RECIEVED",
    totalAmount: 0,
  });
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({ contactId: "" });
  const [selectedTemplate, setSelectedTemplate] = useState({
    templateId: null,
    templateName: "",
  });
  const [editTemplateData, setEditTemplateData] = React.useState({
    templateName: "",
    termsAndConditions: [],
  });
  const [loading, setLoading] = useState(false);
  const [templateDrawerLoading, setTemplateDrawerLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [orderIdToDelete, setOrderIdToDelete] = useState("");
  const [roundLoader, setRoundLoader] = useState(false);

  // -------------------------------states-for-new-order-----------------------------------------
  const [newOrderData, setNewOrderData] = useState(
    props.whichOrder === "purchaseOrder"
      ? {
        jobId: jobId,
        description: "",
        message: "",
        contactId: "",
        requiredBy: new Date()
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
        send: false,
        comment: "",
        status: "DRAFT",
        deliveryAddress: {
          streetAddress: "",
          suburbOrTown: "",
          state: "",
          postcode: "",
        },
        orderLineItems: [],
      }
      : {
        jobId: jobId,
        description: "",
        message: "",
        contactId: "",
        requiredBy: new Date()
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
        completedDate: null,
        send: false,
        comment: "",
        status: "DRAFT",
        workAddress: {
          streetAddress: "",
          suburbOrTown: "",
          state: "",
          postcode: "",
        },
        orderLineItems: [],
      }
  );

  const [updateOrderData, setUpdateOrderData] = useState({});
  const [status, setStatus] = useState(
    props.whichOrder === "purchaseOrder"
      ? ["DRAFT", "SENT", "RECIEVED", "PART RECIEVED", "CANCELLED"]
      : ["DRAFT", "SENT", "COMPLETED", "CANCELLED"]
  );
  const [invoiceStatus, setInvoiceStatus] = useState(["RECIEVED", "PAID", "CANCELLED"]);
  const [newOrderDate, setNewOrderDate] = useState(new Date());
  const [completedDate, setCompletedDate] = useState("");
  const [file, setFile] = useState();
  const [lineItemsForAutoselect, setLineItemsForAutoselect] = React.useState([]);
  const [lineItemsGroupsForAutoselect, setLineItemsGroupsForAutoselect] = React.useState([]);
  const [dummyRows, setDummyRows] = React.useState([]);
  const [termsAndCondtions, setTermsAndCondtions] = React.useState([]);
  const [jobData, setJobData] = React.useState();

  // --------------------------------------------------------------------------------------------
  const [contactDrawerState, setContactDrawerState] = React.useState({
    right: false,
  });
  const [contactData, setContactData] = useState({ editable: true });
  const [contactObj, setContactObj] = React.useState();
  // --------------------------------------------------------------------------------------------
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCancel = () => {
    setState({ ...state, ["right"]: false });
    setInvoiceState({ ...invoiceState, ["right"]: false });
    setNewOrderState({ ...newOrderState, ["right"]: false });
    setNewOrderData(
      props.whichOrder === "purchaseOrder"
        ? {
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          send: false,
          comment: "",
          status: "DRAFT",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        }
        : {
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          completedDate: null,
          send: false,
          comment: "",
          status: "DRAFT",
          workAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        }
    );

    setInvoiceData({
      orderId: "",
      referenceNo: "",
      invoiceDate: new Date()
        .toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      notes: "",
      invoiceLineItems: [],
      status: "RECIEVED",
    });
  };

  useEffect(() => {
    if (props.callFrom === "lineItemClick" && props.whichOrder === "purchaseOrder") {
      getPurchaseOrderByJobLineItemId(jobId, setOrderList, setDataLoaded, props.lineItemId);
    } else if (props.whichOrder === "purchaseOrder") {
      getPurchaseOrdersByJobId(jobId, setOrderList, setDataLoaded);
    } else if (props.whichOrder === "workOrder") {
      getWorkOrdersByJobId(jobId, setOrderList, setDataLoaded);
    }
  }, [wantToCallGet]);

  useEffect(() => {
    getContacts(setContacts);
    getUserConfigsTypes(setUserConfigs);
    getUserConfigsUom(setUserConfigsuom, setDummyLoad);
    getJobLineItemsByJobIdForAutoselect(setLineItemsForAutoselect, jobId, setDummyRows);
    getJobLineItemsGroupByJobIdForAutoselect(jobId, setLineItemsGroupsForAutoselect);
    getJobByJobId(setJobData, jobId, setDummyLoad, undefined); //get job by id
    getTermsAndCondtions(setTermsAndCondtions);
  }, []);

  useEffect(() => {
    newOrderDate.$d &&
      setNewOrderData({
        ...newOrderData,
        requiredBy: newOrderDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
  }, [newOrderDate]);

  useEffect(() => {
    completedDate !== null &&
      typeof completedDate === "object" &&
      setNewOrderData({
        ...newOrderData,
        completedDate: completedDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
  }, [completedDate]);

  useEffect(() => {
    if (invoiceDate.$d) {
      setInvoiceData({
        ...invoiceData,
        invoiceDate: invoiceDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
      });
    }
  }, [invoiceDate]);

  // useEffect(() => {
  //   setSelectedContact(contacts[0]);
  // }, [contacts]);
  useEffect(() => {
    selectedContact &&
      setNewOrderData({
        ...newOrderData,
        contactId: selectedContact?.contactId,
      });
  }, [selectedContact]);
  useEffect(() => {
    if (selectedTemplate?.hasOwnProperty("templateId")) {
      setNewOrderData({
        ...newOrderData,
        termsAndConditionsTemplateId: selectedTemplate?.templateId,
      });
    }
    // } else if (selectedTemplate?.hasOwnProperty("termsAndConditionsTemplateId")) {
    //   setNewOrderData({
    //     ...newOrderData,
    //     termsAndConditionsTemplateId: selectedTemplate?.termsAndConditionsTemplateId,
    //   });
    // }
  }, [selectedTemplate]);

  // console.log("dataLoaded", dataLoaded);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const toggleInvoiceDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setInvoiceState({ ...invoiceState, [anchor]: open });
  };
  const toggleNewOrderDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setNewOrderState({ ...newOrderState, [anchor]: open });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenDrawer = (orderId) => {
    if (props.whichOrder === "purchaseOrder") {
      getPurchaseOrderById(
        orderId,
        setNewOrderData,
        setLoading,
        setSelectedContact,
        setNewOrderDate,
        setSelectedTemplate,
        termsAndCondtions,
        setDefaultValueTemplate
      );
    } else if (props.whichOrder === "workOrder") {
      getWorkOrderById(
        orderId,
        setNewOrderData,
        setLoading,
        setSelectedContact,
        setNewOrderDate,
        setCompletedDate,
        setSelectedTemplate,
        termsAndCondtions,
        setDefaultValueTemplate
      );
    }
    setState({ ...state, ["right"]: true });
  };
  const handleOpenInvoiceDrawer = (orderId) => {
    setInvoiceData({ ...invoiceData, orderId: orderId });
    if (props.whichOrder === "purchaseOrder") {
      getPurchaseOrderById(orderId, setOrderData, setLoading, setSelectedContact, setNewOrderDate);
    } else if (props.whichOrder === "workOrder") {
      getWorkOrderById(
        orderId,
        setOrderData,
        setLoading,
        setSelectedContact,
        setNewOrderDate,
        setCompletedDate
      );
    }
    setInvoiceState({ ...invoiceState, ["right"]: true });
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  // ******************************handleSendPdf*******************************************

  const handleSendPdf = (orderId) => {
    getContacts(setContacts);
    if (props.whichOrder === "purchaseOrder") {
      getPurchaseOrderDataForPdf(orderId, props.whichOrder);
    } else if (props.whichOrder === "workOrder") {
      getWorkOrderDataForPdf(orderId, props.whichOrder);
    }
  };

  // --------------------------------------------------------------------------------------

  const handleInvoiceInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleNewOrderInputChange = (e) => {
    const { name, value } = e.target;
    setNewOrderData({ ...newOrderData, [name]: value });
  };

  const handleUpdateOrderInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateOrderData({ ...updateOrderData, [name]: value });
  };

  // --------------------------------------------------------------------------------------
  const handleOpenNewOrderDrawer = () => {
    props.whichOrder === "workOrder"
      ? setNewOrderData({
        ...newOrderData,
        workAddress: {
          streetAddress: jobData?.client?.address,
          suburbOrTown: jobData?.client?.cityOrTown,
          state: jobData?.client?.state,
          postcode: jobData?.client?.postcode,
        },
      })
      : setNewOrderData({
        ...newOrderData,
        deliveryAddress: {
          streetAddress: jobData?.client?.address,
          suburbOrTown: jobData?.client?.cityOrTown,
          state: jobData?.client?.state,
          postcode: jobData?.client?.postcode,
        },
      });
    setNewOrderState({ ...newOrderState, ["right"]: true });
    setSelectedTemplate({ templateId: null, templateName: "" });

  };
  // --------------------------------------------------------------------------------------

  const handleNewOrderSave = () => {
    let finalData = {
      ...newOrderData,
    };

    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;
    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      if (props.whichOrder === "workOrder") {
        createNewWorkOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setNewOrderState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      } else if (props.whichOrder === "purchaseOrder") {
        createNewPurchaseOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setNewOrderState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      }
    }
  };
  // --------------------------------------------------------------------------------------
  const handleNewOrderSaveAndSend = () => {
    let finalData = {
      ...newOrderData,
      contactId: selectedContact.contactId,
      send: true,
    };
    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;

    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      if (props.whichOrder === "workOrder") {
        createNewWorkOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setNewOrderState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      } else if (props.whichOrder === "purchaseOrder") {
        createNewPurchaseOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setNewOrderState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      }
    }
  };
  // -----------------------------------------------------------------------------------------

  const handleUpdateOrderSave = () => {
    let finalData = {
      ...newOrderData,
    };
    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;
    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      if (props.whichOrder === "workOrder") {
        updateWorkOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      } else if (props.whichOrder === "purchaseOrder") {
        updatePurchaseOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      }
    }
  };
  // -----------------------------------------------------------------------------------------
  const handleUpdateOrderSaveAndSend = () => {
    let finalData = {
      ...newOrderData,
      contactId: selectedContact.contactId,
      send: true,
    };
    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;

    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      if (props.whichOrder === "workOrder") {
        updateWorkOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      } else if (props.whichOrder === "purchaseOrder") {
        updatePurchaseOrder(
          finalData,
          setOpenSnackbar,
          setSnackbarMSG,
          setSnackbarSeverity,
          setState,
          setRoundLoader,
          setNewOrderData,
          setSelectedContact,
          jobId,
          orderList,
          setOrderList
        );
      }
    }
  };
  // --------------------------------------------------------------------------------------
  const handleNewInvoiceSave = () => {
    let finalData = { ...invoiceData, totalAmount: getInvoiceTotal() };
    let valid = true;
    let errMsg = checkProperties(finalData);
    if (errMsg !== "") valid = false;
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      createInvoice(
        finalData,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        props.whichOrder,
        setRoundLoader,
        setInvoiceState,
        setInvoiceData
      );
    }
  };
  // --------------------------------------------------------------------------------------
  const getInvoiceTotal = () => {
    var finalSubTotal = 0;
    var finalTax = 0;
    var finalTotal = 0;
    var itemTax = 0;
    var mult = 0;
    invoiceData.invoiceLineItems.filter((e) => {
      mult = parseInt(e.unitCost) * parseInt(e.qty);
      if (isNaN(mult)) mult = 0;
      finalSubTotal = finalSubTotal + mult;
      itemTax = (mult * getGst(e.gst)) / 100;
      if (isNaN(itemTax)) itemTax = 0;
      finalTax = finalTax + parseInt(itemTax);
      finalTotal = finalSubTotal + finalTax;
    });
    return finalTotal;
  };
  // --------------------------------------------------------------------------------------
  const handleTemplateSave = () => {
    createNewTemplate(
      newTemplateData,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setNewTemplateState,
      setRoundLoader,
      termsAndCondtions,
      setTermsAndCondtions
    );
  };
  const handleEditTemplateSave = () => {
    EditTemplate(
      editTemplateData,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setNewTemplateState,
      setRoundLoader,
      termsAndCondtions,
      setTermsAndCondtions,
      setEditTemplateState
    );
  };
  const handleTemplateDelete = () => {
    DeleteTemplate(
      setOpenSnackbar,
      setSnackbarMSG,
      setRoundLoader,
      setEditTemplateState,
      setSnackbarSeverity,
      selectedTemplate?.templateId,
      getTermsAndCondtions,
      setTermsAndCondtions
    );
    setSelectedTemplate({ templateId: null, templateName: "" });
  };
  // --------------------------------------------------------------------------------------

  const handleEditTemplate = (option) => {
    setTemplateDrawerLoading(true);
    getTermsAndCondtionsById(option.templateId, setNewTemplateData, setTemplateDrawerLoading);
    setEditTemplateState(true);
  };
  // --------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      {roundLoader && <SimpleBackdrop />}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "80%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          {loading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {props.whichOrder === "purchaseOrder" ? (
                  <Typography variant="h5" color="primary">
                    Purchase Order Details : {newOrderData.orderNo}
                  </Typography>
                ) : (
                  <Typography variant="h5" color="primary">
                    Work Order Details : {newOrderData.orderNo}
                  </Typography>
                )}

                <Divider />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  required
                  label={"Description"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={newOrderData.description}
                  name="description"
                  onChange={(e) => handleNewOrderInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  required
                  onChange={(event, newValue) => {
                    setNewOrderData({ ...newOrderData, status: newValue });
                  }}
                  value={newOrderData.status}
                  options={status}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Status" size="small" required />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Autocomplete
                  clearIcon={
                    <ClearIcon fontSize="small" onClick={() => setSelectedContact({ contactId: "" })} />
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedContact(newValue);
                    }
                  }}
                  defaultValue={selectedContact}
                  options={contacts}
                  sx={{ width: "95%" }}
                  getOptionLabel={(option) => option.contactName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Contact" size="small" required />
                  )}
                />
                <Tooltip title="Add New Contact" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      setContactDrawerState({ right: true });
                      setContactData({ editable: true });
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Typography variant="strong" gutterBottom>
                  {props.whichOrder === "purchaseOrder" ? "Delivery To:" : "Work Address:"}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Street Address"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.streetAddress}
                    name="streetAddress"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          streetAddress: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Work Address"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.streetAddress}
                    name="streetAddress"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.deliveryAddress,
                          streetAddress: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Suburb/Town"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.suburbOrTown}
                    name="suburbOrTown"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          suburbOrTown: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Suburb/Town"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.suburbOrTown}
                    name="suburbOrTown"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          suburbOrTown: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>

              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"State"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData.deliveryAddress.state}
                    name="state"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          state: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"State"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.state}
                    name="state"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          state: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Postcode"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.postcode}
                    name="postcode"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          postcode: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Postcode"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.postcode}
                    name="postcode"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          postcode: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Stack sx={{ width: "100%" }}>
                  <DateCreator
                    expiryDate={newOrderDate}
                    setExpiryDate={setNewOrderDate}
                    label={"Required By"}
                  />
                </Stack>
              </Grid>
              {props.whichOrder === "workOrder" && newOrderData.status === "COMPLETED" && (
                <Grid item xs={4}>
                  <Stack sx={{ width: "100%" }}>
                    <DateCreator
                      expiryDate={completedDate}
                      setExpiryDate={setCompletedDate}
                      label={"Completed Date"}
                    />
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  name="comment"
                  variant="outlined"
                  minRows={3}
                  maxRows={3}
                  aria-label="maximum height"
                  placeholder="Comment"
                  value={newOrderData.comment}
                  style={{
                    width: 1485,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                  onChange={(e) =>
                    setNewOrderData({
                      ...newOrderData,
                      comment: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Autocomplete
                  // value={selectedTemplate}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() =>
                        setSelectedTemplate({
                          templateId: null,
                          templateName: "",
                        })
                      }
                    />
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedTemplate(newValue);
                    }
                  }}
                  defaultValue={selectedTemplate}
                  options={termsAndCondtions}
                  sx={{ width: "95%" }}
                  autoHighlight
                  renderOption={(props, option) => {
                    if (option.editable === true) {
                      return (
                        <div>
                          <Grid container spacing={1} {...props}>
                            <Grid item>{option?.templateNo}</Grid>
                            <Grid item>{option?.templateName}</Grid>
                            <Grid item>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  setEditTemplateState(true);
                                  setEditTemplateData(option);
                                }}
                              >
                                <EditIcon color="warning" fontSize="small" />
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    } else {
                      return `${option?.templateNo}:${option?.templateName}`;
                    }
                  }}
                  getOptionLabel={(option) => option?.templateName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Template" size="small" />
                  )}
                />
                <Tooltip title="Add New Template" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      setNewTemplateState(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <OrderTable
                  updateOrderData={newOrderData}
                  setUpdateOrderData={setNewOrderData}
                  userConfigs={userConfigs}
                  userConfigsuom={userConfigsuom}
                  lineItemsForAutoselect={lineItemsForAutoselect}
                  lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                  setShowSaveButton={setShowSaveButton}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <h5>DOCUMENTS:</h5>
                <StyledDropZone setPlanFile={setFile} />
                <Divider />
              </Grid> */}
              {showSaveButton && (
                <Grid item xs={12} align="right">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleUpdateOrderSave}
                  >
                    Update
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleUpdateOrderSaveAndSend}
                    style={{ marginLeft: "10px" }}
                  >
                    Update & Send
                  </Button>
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    onClick={handleCancel}
                    color="secondary"
                    style={{ marginLeft: "10px" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={invoiceState["right"]}
        onClose={toggleInvoiceDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "80%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          {loading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Invoice/Reciept {orderData.contact && orderData.contact.contactName}:
                  {orderData.orderNo}-{orderData.description}
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label={"Reference Number"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={invoiceData.referenceNo}
                  name="referenceNo"
                  onChange={(e) => handleInvoiceInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  required
                  onChange={(event, newValue) => {
                    setInvoiceData({ ...invoiceData, status: newValue });
                  }}
                  value={invoiceData.status}
                  options={invoiceStatus}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Status" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack sx={{ width: "100%" }}>
                  <DateCreator
                    expiryDate={invoiceDate}
                    setExpiryDate={setInvoiceDate}
                    label={"Invoice Date"}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <TextareaAutosize
                  name="notes"
                  variant="outlined"
                  minRows={4}
                  aria-label="maximum height"
                  placeholder="Notes"
                  value={invoiceData.notes}
                  style={{
                    width: 1490,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                  onChange={(e) => handleInvoiceInputChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <InvoiceLineItemsTable
                  invoiceData={invoiceData}
                  setInvoiceData={setInvoiceData}
                  orderData={orderData}
                  setOrderData={setOrderData}
                  userConfigs={userConfigs}
                  userConfigsuom={userConfigsuom}
                  lineItemsForAutoselect={lineItemsForAutoselect}
                  lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                  setShowSaveButton={setShowSaveButton}
                />
              </Grid>
              {showSaveButton && (
                <Grid container justifyContent="flex-end" mt={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    align="right"
                    size="small"
                    onClick={handleNewInvoiceSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    align="right"
                    onClick={handleCancel}
                    style={{ marginLeft: "8px" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </Drawer>
      {contactDrawerState.right && (
        <CreateContactDrawer
          contactData={contactData}
          setContactData={setContactData}
          contacts={contacts}
          setContacts={setContacts}
          recipientsEmail={contactObj}
          setRecipientsEmail={setContactObj}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          setRoundLoader={setRoundLoader}
          contactDrawerState={contactDrawerState}
          setContactDrawerState={setContactDrawerState}
          callFrom={"catalogue"}
        />
      )}
      {newOrderState && (
        <Drawer
          anchor={"right"}
          open={newOrderState["right"]}
          onClose={toggleNewOrderDrawer("left", false)}
          PaperProps={{
            sx: {
              width: "80%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            <Grid container spacing={3}>
              <Grid item style={{ textAlign: "left" }} xs={12}>
                <Typography variant="h5" color="primary">
                  {props.whichOrder === "purchaseOrder" ? "New Purchase Order" : "New Work Order"}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  label={"Description"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={newOrderData.description}
                  name="description"
                  onChange={(e) => handleNewOrderInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  required
                  onChange={(event, newValue) => {
                    setNewOrderData({ ...newOrderData, status: newValue });
                  }}
                  value={newOrderData.status}
                  options={status}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Status" size="small" required />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Autocomplete
                  clearIcon={
                    <ClearIcon fontSize="small" onClick={() => setSelectedContact({ contactId: "" })} />
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedContact(newValue);
                    }
                  }}
                  options={contacts}
                  sx={{ width: "95%" }}
                  getOptionLabel={(option) => option.contactName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Contact" size="small" required />
                  )}
                />
                <Tooltip title="Add New Contact" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      setContactDrawerState({ right: true });
                      setContactData({ editable: true });
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                {" "}
                <Typography variant="strong" gutterBottom>
                  {props.whichOrder === "purchaseOrder" ? "Delivery To :" : "Work Address :"}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Street Address"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.streetAddress}
                    name="streetAddress"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          streetAddress: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Street Address"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.streetAddress}
                    name="streetAddress"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.deliveryAddress,
                          streetAddress: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Suburb/Town"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.suburbOrTown}
                    name="suburbOrTown"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          suburbOrTown: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Suburb/Town"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.suburbOrTown}
                    name="suburbOrTown"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          suburbOrTown: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>

              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"State"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.state}
                    name="state"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          state: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"State"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.state}
                    name="state"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          state: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {props.whichOrder === "purchaseOrder" ? (
                  <TextField
                    required
                    label={"Postcode"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.deliveryAddress?.postcode}
                    name="postcode"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        deliveryAddress: {
                          ...newOrderData.deliveryAddress,
                          postcode: e.target.value,
                        },
                      })
                    }
                  />
                ) : (
                  <TextField
                    required
                    label={"Postcode"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData?.workAddress?.postcode}
                    name="postcode"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        workAddress: {
                          ...newOrderData.workAddress,
                          postcode: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Stack sx={{ width: "100%" }}>
                  <DateCreator
                    expiryDate={newOrderDate}
                    setExpiryDate={setNewOrderDate}
                    label={"Required By"}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                {(newOrderData.status === "RECIEVED" || newOrderData.status === "PART RECIEVED") && (
                  <TextField
                    required
                    label={"Invoice Reference Number"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newOrderData.referenceNo}
                    name="referenceNo"
                    onChange={(e) =>
                      setNewOrderData({
                        ...newOrderData,
                        referenceNo: e.target.value,
                      })
                    }
                  />
                )}
              </Grid>
              {/* {props.whichOrder === "workOrder" && (
              <Grid item xs={4}>
                <DateCreator
                  expiryDate={completedDate}
                  setExpiryDate={setCompletedDate}
                  label={"Completed Date"}
                />
              </Grid>
            )} */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  name="comment"
                  variant="outlined"
                  minRows={3}
                  maxRows={3}
                  aria-label="maximum height"
                  placeholder="Comment"
                  value={newOrderData.comment}
                  style={{
                    width: 1485,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                  onChange={(e) =>
                    setNewOrderData({
                      ...newOrderData,
                      comment: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Autocomplete
                  value={selectedTemplate}
                  clearIcon={
                    <ClearIcon
                      fontSize="small"
                      onClick={() =>
                        setSelectedTemplate({
                          templateId: null,
                          templateName: "",
                        })
                      }
                    />
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedTemplate(newValue);
                    }
                  }}
                  options={termsAndCondtions}
                  sx={{ width: "95%" }}
                  autoHighlight
                  renderOption={(props, option) => {
                    if (option.editable === true) {
                      return (
                        <div>
                          <Grid container spacing={1} {...props}>
                            <Grid item>{option?.templateNo}</Grid>
                            <Grid item>{option?.templateName}</Grid>
                            <Grid item>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  setEditTemplateState(true);
                                  setEditTemplateData(option);
                                }}
                              >
                                <EditIcon color="warning" fontSize="small" />
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    } else {
                      return `${option?.templateNo}:${option?.templateName}`;
                    }
                  }}
                  getOptionLabel={(option) => option?.templateName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Template" size="small" />
                  )}
                />
                <Tooltip title="Add New Template" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      setNewTemplateState(true);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <NewOrderLineItemsTable
                  newOrderData={newOrderData}
                  setNewOrderData={setNewOrderData}
                  userConfigs={userConfigs}
                  userConfigsuom={userConfigsuom}
                  lineItemsForAutoselect={lineItemsForAutoselect}
                  lineItemsGroupsForAutoselect={lineItemsGroupsForAutoselect}
                  setShowSaveButton={setShowSaveButton}
                  jobId={jobId}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <h5>DOCUMENTS:</h5>
              <StyledDropZone setPlanFile={setFile} />
              <Divider />
            </Grid> */}
              {showSaveButton && (
                <Grid container justifyContent="flex-end" mt={1}>
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      size="small"
                      onClick={handleNewOrderSave}
                    >
                      Save
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleNewOrderSaveAndSend}
                      style={{ marginLeft: "10px" }}
                    >
                      Save & Send
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={handleCancel}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </Drawer>
      )}
      {newTemplateState && (
        <Drawer
          anchor={"right"}
          open={newTemplateState}
          // onClose={setNewTemplateState(false)}
          PaperProps={{
            sx: {
              width: "40%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {templateDrawerLoading ? (
              <>
                <br />
                <ProgressBar />
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    New Template
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={"Template Name"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={newTemplateData.templateName}
                    name="templateName"
                    onChange={(e) =>
                      setNewTemplateData({
                        ...newTemplateData,
                        templateName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TemplateLineItemsTable
                    newTemplateData={newTemplateData}
                    setNewTemplateData={setNewTemplateData}
                    setShowSaveButton={setShowSaveButton}
                  />
                </Grid>
                {showSaveButton && (
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      size="small"
                      onClick={() => handleTemplateSave()}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => setNewTemplateState(false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Drawer>
      )}
      {editemplateState && (
        <Drawer
          anchor={"right"}
          open={editemplateState}
          // onClose={setNewTemplateState(false)}
          PaperProps={{
            sx: {
              width: "40%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {templateDrawerLoading ? (
              <>
                <br />
                <ProgressBar />
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" color="primary">
                    Edit Template
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={"Template Name"}
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                    type="text"
                    value={editTemplateData?.templateName}
                    name="templateName"
                    onChange={(e) =>
                      setEditTemplateData({
                        ...editTemplateData,
                        templateName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TemplateLineItemsTable
                    newTemplateData={editTemplateData}
                    setNewTemplateData={setEditTemplateData}
                    setShowSaveButton={setShowSaveButton}
                  />
                </Grid>
                {showSaveButton && (
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      align="right"
                      size="small"
                      onClick={() => handleEditTemplateSave()}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => setEditTemplateState(false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      align="right"
                      onClick={() => handleTemplateDelete()}
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </Drawer>
      )}
      <Grid>
        {dataLoaded == false ? (
          <ProgressBar />
        ) : (
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid item xs={1} mb={1} align="right">
                {hasValidAccess(PURCHASE_ORDER_WRITE) && hasValidAccess(WORK_ORDER_WRITE) && (
                  <Tooltip
                    title={
                      props.whichOrder === "purchaseOrder"
                        ? "Create Purchase Order"
                        : "Create Work Order"
                    }
                    placement="top"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <Button onClick={handleOpenNewOrderDrawer} aria-label="add" size="small">
                      <AddIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </div>

            {orderList.length === 0 ? (
              <EmptyEstimatesDialog
                heading="Empty Orders"
                msg="You don't have any work orders here yet.
            Click the 'Actual Costing'  above to get started, or choose from a template"
              />
            ) : (
              <div className={classes.container}>
                <MUIDataTable
                  title={props.whichOrder === "purchaseOrder" ? "Purchase Orders" : "Work Orders"}
                  data={orderList}
                  columns={columns}
                  options={options}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </Grid>
      {confirmDelete && (
        <DeleteOrderAlertPop
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          orderId={orderIdToDelete}
          wantToCallGet={wantToCallGet}
          setWantToCallGet={setWantToCallGet}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          callFrom={props.whichOrder}
        />
      )}

      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
