import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";

class DesEstimates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: "Team A",
                    type: "column",
                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                    name: "Team B",
                    type: "area",
                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
            ],
            options: {
                chart: {
                    stacked: false,
                    parentHeightOffset: 0,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    foreColor: "currentColor",
                },
                stroke: {
                    width: [0, 3],
                    curve: "smooth",
                    colors: ["#2EAA57"],
                },
                plotOptions: {
                    bar: {
                        columnWidth: "20%",
                        borderRadius: 8,
                    },
                },

                legend: {
                    position: "top",
                    horizontalAlign: "right",
                },

                colors: ["#2EAA57", "#FAE826"],

                fill: {
                    type: ["solid", "gradient"],

                    gradient: {
                        shade: "light",
                        type: "vertical",
                        shadeIntensity: 0.6,
                        opacityFrom: 0.7,
                        opacityTo: 0,
                        stops: [20, 100, 100],
                    },
                },

                labels: [
                    "01/01/2003",
                    "02/01/2003",
                    "03/01/2003",
                    "04/01/2003",
                    "05/01/2003",
                    "06/01/2003",
                    "07/01/2003",
                    "08/01/2003",
                    "09/01/2003",
                    "10/01/2003",
                    "11/01/2003",
                ],
                markers: {
                    size: 0,
                },
                xaxis: {
                    type: "datetime",
                    strokeDashArray: 5,
                },
                grid: {
                    strokeDashArray: 3,
                },

                tooltip: {
                    theme: "dark",
                    followCursor: true,
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + " points";
                            }
                            return y;
                        },
                    },
                },
            },
        };
    }

    render() {
        return (
            <>
                <div className="growth_left_part chart_box">
                    <Typography variant="h5" gutterBottom>
                        Total Growth
                    </Typography>
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="line"
                        height={500}
                    />
                </div>
            </>
        );
    }
}

export default DesEstimates;
