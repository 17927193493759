/** @format */

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
//import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { getSentQuote, createSentQuote } from "../helpers.quoteRequestAPI";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { styled } from "@mui/system";
import {
  AppBar,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextareaAutosize,
  Toolbar,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Checkbox } from "@mui/material";
import { getGst } from "../../UI/GlobalApi";
import CircularProgress from "@mui/material/CircularProgress";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

// ---------------------------------Decode-HTML---------------------------------------
const decodeHTMLEntities = (str) => {
  var element = document.createElement("div");
  if (str && typeof str === "string") {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = "";
  }

  return str;
};

var decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  function decodeHTMLEntities(str) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeHTMLEntities;
})();
// ----------------------------------------------------------------------------------------

export default function ResponsiveGrid() {
  // export default function FormQuoteResponse() {
  const classes = [];
  const [Quotesresponse, setSentQuotes] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [quoteNo, setQuoteNo] = useState("");
  const [title, setTitle] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [message, setMessage] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [quoteLineItems, setQuoteLineItems] = useState([]);
  const [quoteResarr, setquoteResarr] = useState([]);
  const [quoteLineItemId, setquoteLineItemId] = useState("");
  const [estimateLineItemId, setestimateLineItemId] = useState("");
  const [unicost, setunicost] = useState("");
  const [gst, setgst] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [responsive, setResponsive] = useState("vertical");
  const [print, setPrint] = useState(false);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [selectableRows, setSelectableRows] = useState(false);
  const [ccEmailAddresses, setCcTo] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(true);
  const [roundLoader, setRoundLoader] = React.useState(false);
  const [contact, setContact] = useState({
    contactName: "Steve Smith",
    address: "49 Carlisle Street",
    cityOrTown: "Kelvin View",
    postcode: "3666",
    mobile: "+61 491 570 156",
  });
  const [businessAddress, setBusinessAddress] = React.useState(null);
  const [deliveryAddress, setDeliveryAddress] = React.useState(null);
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  // ***********************STATES-For-Stepper*****************************
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState(["Details", "Submit"]);

  // ***********************Functions-For-Stepper*****************************
  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    if (step === 0) {
      return (
        <>
          <Item>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 12 }}>
              <Grid item xs={12}>
                <h6>
                  You've been invited to quote on "{title}". Please review the information below and then
                  let us know if you're interested...
                </h6>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Card variant="outlined">
                  <CardHeader sx={{ pb: "0px" }} title="Location:" />
                  <CardContent>
                    {deliveryAddress !== null &&
                      deliveryAddress.address !== "" &&
                      deliveryAddress.cityOrTown !== "" &&
                      deliveryAddress.postcode !== "" &&
                      deliveryAddress.state !== "" ? (
                      <Typography component="address" variant="address">
                        {deliveryAddress.address},
                        <br />
                        {deliveryAddress.cityOrTown},
                        <br />
                        {deliveryAddress.state}-{deliveryAddress.postcode}
                        <br />
                      </Typography>
                    ) : (
                      <div>Please contact builder.</div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={8} md={4}>
                <Card variant="outlined" p={2} style={{ backgroundColor: !isEdit && "#FFAB91" }}>
                  <CardHeader sx={{ pb: "0px" }} title="Deadline Date:" />
                  <CardContent>
                    <Typography component="span" variant="span">
                      {expiryDate}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} sm={8} md={4}>
                <Card variant="outlined" p={2}>
                  <CardHeader sx={{ pb: "0px" }} title="Builder Details:" />
                  <CardContent>
                    {businessAddress !== null &&
                      businessAddress.businessName &&
                      businessAddress.address &&
                      businessAddress.cityOrTown &&
                      businessAddress.state &&
                      businessAddress.postcode ? (
                      <Typography component="span" variant="span">
                        {Quotesresponse.businessName},
                        <br />
                        {businessAddress.address},
                        <br />
                        {businessAddress.cityOrTown},
                        <br />
                        {businessAddress.state}-{businessAddress.postcode}
                        <br />
                      </Typography>
                    ) : (
                      <div>Details not available</div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={8} sm={6} md={8}>
                <h6 class="width" align="left">
                  Job Details:
                </h6>
                {/* <textarea class="textarea" ng-model="text2" placeholder="Type here"></textarea> */}
                <Card variant="outlined">
                  <CardContent>
                    {decodeEntities(Quotesresponse.message)}
                    {/* {Quotesresponse.message} */}
                    {/* <p className="work"></p> */}
                  </CardContent>
                </Card>

                <p
                  style={{
                    clear: "both",
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  This job contains {quoteLineItems.length} items that require a cost from you. They will
                  be shown on the next step if you choose to quote.
                </p>
              </Grid>
              <Grid item xs={12} sx={{ pt: 1 }}>
                <>
                  <Grid item xs={12} sm={6} md={12}>
                    <h6>Please quote on the following items:</h6>
                  </Grid>

                  <Grid item xs={12} sm={4} md={12} sx={{ pt: 1 }}>
                    {quoteLineItems && (
                      <MUIDataTable data={quoteLineItems} columns={columns} options={options} />
                    )}
                  </Grid>
                </>
              </Grid>
            </Grid>
            {/* <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <h3 class="width" align="left">
                  Files & Attachments
                </h3>
                <div class="level-item  has-text-left _is-hidden-mobile">
                  <img src="public\folder.png"></img>
                  2-Elevation Front&nbsp;
                </div>
              </Grid>
            </Grid> */}
          </Item>
        </>
      );
    } else if (step === 1) {
      return (
        <>
          <Item>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <h6>Add any extra information below such notes or payment terms:</h6>
              </Grid>

              <Grid item>
                <label>
                  <strong>CC To:</strong>
                </label>{" "}
                <br></br>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  value={ccEmailAddresses}
                  onChange={(e) => handleCcToChange(e)}
                  name="ccEmailAddresses"
                  placeholder="comma separated mails"
                  disabled={!isEdit}
                  style={{ width: 300 }}
                />
              </Grid>
              <Grid item>
                <label>
                  <strong>Notes:</strong>
                </label>
                <br></br>
                <TextareaAutosize
                  variant="outlined"
                  minRows={4}
                  type="text"
                  value={notes}
                  onChange={(e) => handleNotesChange(e)}
                  name="notes"
                  placeholder="Enter your payment terms here"
                  disabled={!isEdit}
                  style={{
                    width: 300,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                />
              </Grid>
            </Grid>
          </Item>
        </>
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = () => {
    let obj = {
      ccEmailAddresses,
      notes,
      quoteResponseLineItems: quoteLineItems,
    };
    console.log("OBJ::", obj);
    isEdit &&
      createSentQuote(
        obj,
        quoteId,
        token,
        setRoundLoader,
        setActiveStep,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity
      );
  };

  const handleCcToChange = (e) => {
    setCcTo(e.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  //*************************COLOMNS****************************** */
  const columns = [
    {
      name: "No.",
      label: "Code",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "qty",
      label: "Quantity",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uom",
      label: "UOM",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unitCost",
      label: "Unit Cost",
      options: {
        filter: true,

        sort: false,

        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={value}
              // onChange={(e) => {setunicost(e.target.value)}}
              onChange={(e) => handleChange(e, tableMeta.rowIndex)}
              name="unitCost"
              disabled={!isEdit}
            />
          );
        },
      },
    },
    {
      name: "gst",
      label: "GST",
      options: {
        filter: true,

        sort: false,

        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              size="small"
              name="gst"
              defaultChecked={value === true ? true : false}
              onChange={(e) => handleChangeGST(e, tableMeta.rowIndex)}
            />
          );
        },
      },
    },
  ];

  //*************************Options****************************** */
  const options = {
    viewColumns: false,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    pagination: false,
    download: false,
    filter: false,
    search: false,

    // selectableRows,
    // selectableRowsOnClick: false,
    selectableRows: selectableRows,
    // selectableRowsHideCheckboxes: true,
    // textLabels: {
    //   pagination: false,
    // },

    onTableChange: (action, state) => { },

    onRowsDelete: (rowsDeleted, newData) => {
      //handleDelete(rowsDeleted, newData);
    },
  };

  //******************************************************* */

  // (e) => {setunicost({ [index]: e.target.value }
  // console.log("quoteLine item ",quoteLineItems)
  var history = useHistory();

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...quoteLineItems];

    if (name === "unitCost" || name === "qty" || name === "markupPercentage") {
      list[index][name] = parseInt(value);
    } else {
      list[index][name] = value;
    }
    setQuoteLineItems(list);
  };
  const handleChangeGST = (e, index) => {
    if (!e.target.checked) {
      const { name } = e.target;
      const list = [...quoteLineItems];

      list[index][name] = false;
      setQuoteLineItems(list);
    } else {
      const { name } = e.target;
      const list = [...quoteLineItems];

      list[index][name] = true;
      setQuoteLineItems(list);
    }
  };

  const params = new URLSearchParams(window.location.search);
  const quoteIdnew = params.get("quoteId");
  const token = params.get("token");

  useEffect(() => {
    getSentQuote(
      setSentQuotes,
      setTitle,
      setQuoteId,
      setQuoteNo,
      setMessage,
      setExpiryDate,
      setQuoteLineItems,
      quoteIdnew,
      token,
      setLoading,
      setIsEdit,
      setBusinessAddress,
      setDeliveryAddress,
      setNotes,
      setCcTo
    );

    console.log("IsEdit:", isEdit);
  }, []);
  console.log("expiryDate::", expiryDate);

  // **********************Calculations**************************
  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;
  var uc = 0;
  var qty = 0;
  quoteLineItems.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    // console.log("mult:", mult);
    finalSubTotal = finalSubTotal + mult;
    // console.log("finalSubTotal:", finalSubTotal);
    itemTax = (mult * getGst(e.gst)) / 100;
    // console.log("itemTax:", itemTax);
    finalTax = finalTax + itemTax;
    // console.log("finalTax:", finalTax);
    finalTotal = finalSubTotal + finalTax;
    // console.log("finalTotal:", finalTotal);
  });
  // **********************Calculations-Ends**************************

  return (
    <>
      {roundLoader && <SimpleBackdrop />}
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography>
              <img src="https://dev.app.buildxap.com.au/static/media/buildxap_logo.d72f0062.svg"></img>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}></Box>

            {/* <Box sx={{ flexGrow: 0 }}>
              <Button onClick={handleReset}>
                <RefreshIcon />
                Message
              </Button>
              <Button onClick={handleReset}>
                <RefreshIcon />
                Quating
              </Button>
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Grid container>
        {/* offset grid with column you want to offset */}
        <Grid item lg={2} md={2} sm={2} />
        <Grid item lg={8} md={8} sm={8} mt={5}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>

        <Grid item lg={2} md={2} sm={2} />
      </Grid>

      <div>
        {activeStep === steps.length ? (
          <Grid container direction={"column"} alignItems="center" spacing={3}>
            <Grid item>
              <Typography variant={"h4"}>Your Quote is Submitted, Thanks !</Typography>
            </Grid>
            <Grid item>
              <Button onClick={handleReset}>
                <RefreshIcon />
                REOPEN QUOTE
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            {/* offset grid with column you want to offset */}
            <Grid item lg={2} md={2} sm={2} />
            <Grid item lg={8} md={8} sm={8}>
              {getStepContent(activeStep)}
            </Grid>

            <Grid item lg={2} md={2} sm={2} />
          </Grid>
        )}
      </div>

      <Grid container mb={2}>
        {activeStep !== 2 && (
          <Grid container>
            <Grid item lg={2} md={2} sm={2} />
            <Grid item lg={8} md={8} sm={8} mt={5}>
              <Grid container>
                <Grid item lg={6} md={4} sm={12} align="left">
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item lg={6} md={4} sm={12} align="right">
                  {activeStep === steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={activeStep === steps.length && true}
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={2} md={2} sm={2} />
          </Grid>
        )}

        {(activeStep === 0 || activeStep === 1) && (
          <Grid container>
            <Grid item lg={2} md={2} sm={2} />
            <Grid item lg={8} md={8} sm={8} mt={5} align="right">
              <Item>
                <p class="font">Sub Total: ${finalSubTotal}</p>
                <p class="font">Tax: ${finalTax}</p>
                <hr align="center"></hr>
                <p class="font">
                  <b>Total: </b>${finalTotal}
                </p>
              </Item>
            </Grid>
            <Grid item lg={2} md={2} sm={2} />
          </Grid>
        )}
      </Grid>
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </>
  );
}
