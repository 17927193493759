/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateIcon from "@mui/icons-material/Create";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  Checkbox,
  Collapse,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  InputAdornment,
  Paper,
  Divider,
  Popover,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import NewJobPlanPrice from "./NewJobPlanPrice";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Tooltip, Zoom } from "@mui/material";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import SearchIcon from "@mui/icons-material/Search";
import PercentIcon from "@mui/icons-material/Percent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
} from "../../UI/GlobalVariables";
import { v4 as uuid } from "uuid";
import { getEstimateJobPlanItem, tempEstimate, updateJobPlanItemByJobId } from "./jobplan.helpers";
import { el } from "date-fns/locale";
import { Autocomplete } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { getContacts, getQuotesByEstimateLineItemId } from "../../quotes/helpers.quoteRequestAPI";
import CalculateIcon from "@mui/icons-material/Calculate";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import WebDrawer from "../../estimates/TakeoffPlans/WebDrawer";
import { getFileByUSingUrl } from "../../estimates/TakeoffPlans/takeoff.helpers";
import { padding } from "@mui/system";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import QuoteResponse from "../../quotes/quoteresponse/QuoteResponse";
import ProgressBar from "../../UI/ProgressBar";
import { getQuoteDataAPI } from "../../quotes/helpers.quoteRequestAPI";
import EditQuoteDrawer from "../../quotes/quoterequests/EditQuoteDrawer";
import Catalogue from "../../catalogue/Catalogue";
import { getGst, checkProperties } from "../../UI/GlobalApi";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
});
export default function JobPlanTable({
  rows,
  setRows,
  groupName,
  groupCode,
  jobPlanId,
  estimateId,
  dataLoaded,
  setDisableSave,
  jobPlanObject,
  setJobPlanObject,
  setTempRows,
  setOpenedItemRow,
  props,
  handleAddQuote,
  setCreateQuote,
  createQuote,
  hasValidAccess,
  userConfigs,
  userConfigsuom,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  catalogueItems,
  setCatalogueItems,
  handleAddToCatalogue,
  handleAddToPlans,
  setTempGroups,
  setWantToCallGetJobPlan,
  handleChange,
  setRoundLoader,
  calledFrom,
  editableJobplan,
  estimateStatus,
}) {
  const classes = useStyles();
  const [inputList, setInputList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openItems, setOpenItems] = React.useState(false);
  const [isEdit, setEdit] = useState(false);
  const [optionName, setOptionName] = React.useState("STANDARD");
  const [unitCost, setUnitCost] = React.useState();
  const [uom, setUom] = React.useState();
  const [markupPercentage, setMarkupPercentage] = React.useState();
  const [type, setType] = React.useState();
  const [gst, setGst] = React.useState();
  const [qty, setQty] = React.useState();
  const [state, setState] = React.useState({
    right: false,
  });
  const [quoteDrawerState, setQuoteDrawerState] = React.useState({
    right: false,
  });
  const [indexForAutocomplete, setIndexForAutocomplete] = React.useState();
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [responsive, setResponsive] = useState("standard");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [print, setPrint] = useState(false);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [autoSelectPlanName, setAutoSelectPlanName] = useState(null);
  const [distanceValue, setDistanceValue] = useState();
  const [planObject, setPlanObject] = React.useState([]);
  const [instance, setInstance] = useState();
  const [wantToCallUseEffect, setWantToCallUseEffect] = useState(true);
  const [load, setLoad] = React.useState(false);
  const [indexOfCurrentRow, setIndexOfCurrentRow] = useState();
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedJobPlan, setIsCheckedJobPlan] = useState([]);
  const [showLinkIcon, setShowLinkIcon] = useState();
  let checked = false;
  /* just for dummy */
  const [planName, setPlanName] = useState("");
  const [popOverData, setPopOverData] = useState({
    type: "a",
    uom: "lm",
    qty: 1,
    markUpPop: 2,
  });

  const handleSaveMeasure = () => {
    var list = JSON.parse(JSON.stringify(inputList));
    list[indexOfCurrentRow]["qty"] = distanceValue;
    setInputList(list);
    setOpenPlanDialog(false);
  };

  const handleClickOpen = (e, i) => {
    setIndexOfCurrentRow(i);
    setOpenPlanDialog(true);
  };

  useEffect(() => {
    if (!calledFrom === "jobPlan")
      getFileByUSingUrl(
        estimateId,
        instance,
        setPlanObject,
        autoSelectPlanName,
        setAutoSelectPlanName,
        setLoad,
        planName
      );
  }, [wantToCallUseEffect]);

  useEffect(() => {
    /* i copied object using JSON method bcz  i have to copy object without any object refrence */

    // for (var i of jobPlanObject) {
    //   isChecked.push({ id: i.lineItemId, checked: false });
    // }
    for (var i of jobPlanObject) {
      isCheckedJobPlan.push({ id: i.lineItemId, checked: false });
    }
    setInputList(JSON.parse(JSON.stringify(jobPlanObject)));
    // setShowLinkIcon(JSON.parse(JSON.stringify(jobPlanObject[0].unitCost)));

  }, []);

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };
  const handleEdit = (i) => {
    setCreateQuote("");
    setInputList(inputList);
    // setCallGetJobPlan(true);
    setEdit(true);
  };

  /* ---------------------------------------------------------------- */
  // var total = 0;
  // //calculation for est total act total and var total
  // inputList.filter((e) => {
  //   var demoTotal = e.unitCost * e.qty;
  //   total = total + demoTotal;
  //   //console.log(total);
  // });
  // // end calculations

  /* ---------------------------------------------------------------- */
  let finalArray = [];
  const onChange = (arr, id) => {
    // if(!finalArray.length){
    //   finalArray.push({arr,id})
    // }
    // else{
    //   let exists = false;
    //   finalArray.map((item,index)=>{
    //     if(item.id === id){
    //       if(arr.length !==0){
    //         finalArray[index] = {arr,id}
    //       }
    //       else{
    //         finalArray.splice(index, 1)
    //       }
    //       exists = true;
    //     }
    //   })
    //   if(!exists){
    //     finalArray.push({arr,id})
    //   }
    // }
  };

  const unique_id = uuid();

  /* ---------------------------------------------------------------- */

  const handleAdd = () => {
    console.log("Add Called");
    setEdit(true);
    // setCallGetJobPlan(true);

    //This code is to automatically genrate itemcode
    //     if (inputList.length === 0) {
    //       var lastBeforeRowLineId = 0;
    //     } else {
    //       var lastBeforeRowLineId = inputList[inputList.length - 1].itemCode;
    //     }
    // -------------------------------------------------------------

    var newElement = {
      lineItemId: null,
      jobLineItemId: null,
      itemId: null,
      itemCode: "",
      itemName: "",
      itemGroupCode: groupCode,
      itemGroupName: groupName,
      itemDescription: "",
      itemCategory: "",
      optionId: unique_id,
      optionName: "STANDARD",
      type: "",
      qty: 1,
      uom: "",
      unitCost: 0,
      markupPercentage: 0,
      gst: true,
      included: true,
      referenceQuotes: false,
      referenceCatalogue: false,
      priceOptions: [
        {
          optionId: unique_id,
          optionName: "STANDARD",
          itemDescription: "",
          type: "",
          qty: 1,
          uom: "",
          unitCost: 0,
          markupPercentage: 0,
          gst: true,
          included: true,
        },
      ],
    };
    console.log("id", newElement);
    setInputList([...inputList, newElement]);
    setCreateQuote("");
  };

  /* ---------------------------------------------------------------- */
  const assignData = (itm, el) => {
    itm.optionId = el.optionId;
    itm.optionName = el.optionName;
    itm.included = el.included;
    itm.type = el.type;
    itm.qty = el.qty;
    itm.uom = el.uom;
    itm.unitCost = el.unitCost;
    itm.markupPercentage = el.markupPercentage;
    itm.gst = el.gst;
    itm.itemDescription = el.itemDescription;
  };
  /* ---------------------------------------------------------------- */

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    var list = [...inputList];
    if (name == "unitCost") {
      list[index]["referenceCatalogue"] = false;
      list[index]["referenceQuotes"] = false;
    }
    if (name === "unitCost" || name === "qty" || name === "markupPercentage") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    // list[index][name] = value;
    setInputList(list);


  };

  /* ------------------------Common function------------------------- */
  function filterInputListForClearAndDeleteLineItem(deleteId) {
    let filterList = inputList.filter((el) => {
      return deleteId.itemCode != el.itemCode;
    });
    let count = 1;
    var test = filterList.filter(function (el) {
      el.itemCode = count++;
      return el;
    });
    return test;
  }
  /* ---------------------------------------------------------------- */

  const deleteItemNonEditMode = () => {
    let deletedData = filterInputListForClearAndDeleteLineItem(itemRowToDelete);
    updateValuesInRows(deletedData);
    setJobPlanObject(deletedData); //it is for permenetly store data after api call
    setShowConfirm(false);
  };

  /* -------------------------------------------------------- */
  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setInputList(deletedData);
    setDisableSave(true);
  };

  /* ---------------------------------------------------------------- */

  const handleCancel = () => {
    setInputList(jobPlanObject); /* Reset our old data after cancel edit/add operation  */
    setJobPlanObject(jobPlanObject);
    setEdit(false);
    setDisableSave(true);
  };

  /* -------------------------------------------------------- */
  // const [callGetEstimatePlan, setcallGetEstimatePlan] = useState(false);

  // useEffect(() => {
  //   if (callGetEstimatePlan) {
  //     if (props.type === "estimate") {
  //       getEstimateJobPlanItem(setTempRows, estimateId);
  //     }

  //     setcallGetEstimatePlan(false);
  //   }
  // }, [callGetEstimatePlan]);

  /* ------------------------Total-Pop-Up-Code------------------------- */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenTotalPopUp = (event, row) => {
    setPopOverData(row);
    setAnchorEl(event.currentTarget);
    console.log("ROW in handleOpenTotalPopUp:", row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopUp = Boolean(anchorEl);
  const id = openPopUp ? "simple-popover" : undefined;

  /* ------------------------Total-PopUp-Calculations------------------------------------------- */
  var estCostPop = popOverData.unitCost * popOverData.qty;
  var markUpPop = (estCostPop * popOverData.markupPercentage) / 100;
  var subTotalPop = estCostPop + markUpPop;
  var gstPop = (subTotalPop * getGst(popOverData.gst)) / 100;
  var quoteTotalPop = subTotalPop + gstPop;

  /* ------------------------------------------------------------------- */

  /* ------------------------Common function------------------------- */
  function updateValuesInRows(finalJobLineObj) {
    console.log("FinalJobObj:", finalJobLineObj);
    rows.filter((item) => {
      finalJobLineObj.filter((el) => {
        if (item.groupCode === el.itemGroupCode && item.groupName === el.itemGroupName) {
          item.items = finalJobLineObj.map((item) => {
            return {
              ...item,
              unitCost: parseFloat(item.unitCost),
              qty: parseFloat(item.qty),
              markupPercentage: parseFloat(item.markupPercentage),
            };
          });
        }
      });
    });
    if (props.type === "estimate") {
      console.log("if estimate");
      tempEstimate(
        estimateId,
        rows,
        setRows,
        setRoundLoader,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    } else if (props.type === "jobPlan") {
      console.log("else job");
      updateJobPlanItemByJobId(
        jobPlanId,
        rows,
        setTempGroups,
        setRoundLoader,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG
      );
    }
  }

  /* -------------------------------------------------------- */
  const handlesLineItemSave = () => {
    console.log("inputList:", inputList);
    var finalJobLineObj = inputList.filter((el) => {
      if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
        el.priceOptions.filter((element) => {
          if (element.optionId === el.optionId) {
            assignData(element, el);
          }
        });
        return el;
      }
    });

    /* this logic for Se4lected Quote and Selected Catalogues */

    finalJobLineObj.map((editedItem, i) => {
      console.log("editedItem.catalogueItemId", editedItem.catalogueItemId);
      console.log("cccccccc -name", editedItem.itemName);
      console.log("cccccccc -cc", editedItem.catalogueId);
      if (editedItem.referenceCatalogue !== true) {
        editedItem.contactId = null;
        editedItem.catalogueId = null;
        editedItem.catalogueItemId = null;
        // editedItem.quoteId = null;
        // editedItem.quoteLineItemId = null;
      }
      if (editedItem.referenceQuotes !== true) {
        editedItem.quoteId = null;
        editedItem.quoteLineItemId = null;
      }
    });
    console.log("finalJobLineObj-jobPlanObject", jobPlanObject);
    console.log("finalJobLineObj", finalJobLineObj);
    setJobPlanObject(finalJobLineObj);

    updateValuesInRows(finalJobLineObj);
    setOpenItems({ [index]: false });
    setEdit(false);
    setDisableSave(true);
    setCreateQuote("");
  };

  /* ---------------------------------------------------------------- */
  const handleChangeGST = (e, i, row) => {
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(inputList));
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };

  /* ---------------------------------------------------------------- */
  const [priceOption, setPriceOption] = React.useState([]);
  const [index, setIndex] = React.useState();
  const [selectedOpenTable, setSelectedOpenTable] = React.useState();
  const [getQuoteData, setGetQuoteData] = useState([]);
  const [quoteLoader, setQuoteLoader] = useState(true);
  const [refreshQuote, setRefreshQuote] = useState(false);
  const [lineItemId, setLineItemId] = useState("");
  // -----------------------Ueffect-To-refresh-quotes-------------------
  useEffect(() => {
    if (refreshQuote) {
      getQuotesByEstimateLineItemId(estimateId, setGetQuoteData, setQuoteLoader, lineItemId);
      setRefreshQuote(true);
    }
  }, [refreshQuote]);
  // --------------------------------------------------------------------
  const handleOpenCostinRow = (index, row, tableName) => {
    if (tableName === "openOptionsTable") {
      setPriceOption(row.priceOptions);
    } else if (tableName === "openQouteOrCatalogueTable") {
      getQuotesByEstimateLineItemId(estimateId, setGetQuoteData, setQuoteLoader, row.lineItemId);
    }
    setOpenedItemRow(row);
    setSelectedOpenTable(tableName);

    console.log("setItemRowToDelete ", row.priceOptions);
    setIndex(index);
    if (openItems[index]) {
      setOpenItems({ [index]: false });
    } else {
      setOpenItems({ [index]: true });
    }
  };

  /* ---------------------------------------------------------------- */
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [itemRowToDelete, setItemRowToDelete] = React.useState();

  const handleConfirm = (row) => {
    console.log("setItemRowToDelete ", row);
    setItemRowToDelete(row);
    setShowConfirm(true);
  };
  const handleNo = () => {
    setShowConfirm(false);
  };

  /* ---------------------------Functions-For-Search-Catalogue-Drawer------------------------------------- */
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const toggleQuoteEditDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setQuoteDrawerState({ ...quoteDrawerState, [anchor]: open });
  };
  const handleOpenSearchCatalogueDrawer = (e, i) => {
    setIndexForAutocomplete(i);
    setState({ ...state, ["right"]: true });
  };
  // -----------------------------OPTIONS---------------------------------------
  const options = {
    viewColumns: false,
    tableBodyHeight,
    tableBodyMaxHeight,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: "single",
    download: false,
    searchOpen: true,
    filter: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
  };
  // ---------------------------------COLUMS-----------------------------------
  const columns = [
    {
      name: "catalogueName",
      label: "Catalogue Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "itemDescription",
      label: "Item Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "markupPercentage",
      label: "Markup",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "variancePercentage",
      label: "Variance",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "gst",
      label: "GST",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "unitCost",
      label: "Unit Cost",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "itemId",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (itemId, tableMeta) => {
          return (
            <ButtonGroup variant="contained" size="small">
              <Button
                size="small"
                color="primary"
                target="_self"
                onClick={() => {
                  handleSelectCatalogueItem(tableMeta.rowIndex);
                }}
              >
                <CheckCircleIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];

  /* ---------------------------------------------------------------- */
  const handleSelectCatalogueItem = (itemIndex) => {
    const selectedItem = catalogueItems[itemIndex];
    console.log("selectedItem:", selectedItem);
    const list = JSON.parse(JSON.stringify(inputList));
    list[indexForAutocomplete] = {
      ...list[indexForAutocomplete],
      itemGroupCode: groupCode,
      itemGroupName: groupName,
      itemCategory: groupName,
      itemDescription: selectedItem.itemDescription,
      unitCost: selectedItem.unitCost,
      markupPercentage: selectedItem.markupPercentage,
      gst: selectedItem.gst,
      catalogueId: selectedItem.catalogueId,
      catalogueItemId: selectedItem.itemId,
      contactId: selectedItem.contactId,
      referenceCatalogue: true,
    };
    setInputList(list);
    console.log("list:", list);

    setState({ ...state, ["right"]: false });
  };
  /* ---------------------------------------------------------------- */
  const [contacts, setContacts] = useState([]);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [getQuoteDataID, setGetQuoteDataID] = useState([]);
  const [localQuoteItemList, setLocalQuoteItemList] = useState([]);
  const [apiQuoteItemList, setApiQuoteItemList] = useState([]);
  const [secondLoader, setSecondLoader] = useState(true);

  const getQuoteDataById = (row) => (event) => {
    getContacts(setContacts);
    setQuoteDrawerState({ ...quoteDrawerState, ["right"]: true });
    // console.log("This is data from Parent Component to the Child Component.", row);
    // console.log(row.quoteId);
    // setLineItemError("");

    getQuoteDataAPI(
      row.quoteId,
      setGetQuoteDataID,
      setLocalQuoteItemList,
      setApiQuoteItemList,
      setSecondLoader
    ); //get quote
    // console.log("userConfigs", userConfigs);
    // setIsEdit(false);
  };
  /* ---------------------------------------------------------------- */

  return (
    <>
      {dataLoaded && <SimpleBackdrop />}
      <Box py={2}>
        <Grid item xs={12} align="right">
          <Box
            sx={{
              width: "300px",
            }}
          >
            <Table
              size="small"
              sx={{
                borderBottom: "none",
              }}
            >
              <TableRow>
                {/* <TableCell variant="head">Total(Ex) : </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    value={total.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </TableCell> */}
              </TableRow>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} align="right">
          {(hasValidAccess(ESTIMATE_WRITE) || hasValidAccess(JOB_PLAN_WRITE)) && (
            <Box py={1}>
              {inputList.length !== 0 ? (
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                  {isEdit ? (
                    <ButtonGroup variant="contained" aria-label="contained primary button group">
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            size="small"
                            disabled
                          >
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>
                        )
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                          size="small"
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <AddIcon fontSize="small" />
                          ADD
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        align="right"
                        onClick={handleCancel}
                        size="small"
                      >
                        <ClearIcon fontSize="small" />
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlesLineItemSave}
                        size="small"
                      >
                        <CheckCircleOutlineIcon fontSize="small" />
                        SAVE
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup variant="contained" aria-label="contained primary button group">
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            size="small"
                            disabled
                          >
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>
                        )
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdd}
                          size="small"
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <AddIcon fontSize="small" />
                          ADD
                        </Button>
                      )}
                      {props.type === "jobPlan" ? (
                        editableJobplan ? (
                          <Button
                            variant="contained"
                            color="primary"
                            align="right"
                            onClick={handleEdit}
                            size="small"
                          >
                            <CreateIcon fontSize="small" />
                            EDIT
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            align="right"
                            onClick={handleEdit}
                            size="small"
                            disabled
                          >
                            <CreateIcon fontSize="small" />
                            EDIT
                          </Button>
                        )
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          align="right"
                          onClick={handleEdit}
                          size="small"
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <CreateIcon fontSize="small" />
                          EDIT
                        </Button>
                      )}
                    </ButtonGroup>
                  )}
                </ButtonGroup>
              ) : (
                <ButtonGroup variant="contained" aria-label="contained primary button group">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                    size="small"
                  //disabled={!editableJobplan || estimateStatus !== "DRAFT" ? true : false}
                  >
                    <AddIcon onClick={handleAdd} fontSize="small" />
                    ADD
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          )}
        </Grid>
        {openPopUp && (
          <Popover
            id={id}
            open={openPopUp}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <div style={{ minWidth: 275, margin: 7 }}>
              <Table
                size="small"
                sx={{
                  borderBottom: "none",
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      Type
                    </TableCell>
                    <TableCell align="right">{popOverData?.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      Qty
                    </TableCell>
                    <TableCell align="right">
                      {popOverData?.qty} {popOverData?.uom}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      Unit Cost
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={popOverData?.unitCost?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      Estimate Cost
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={estCostPop?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      Markup
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={markUpPop?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      GST
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={gstPop?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left" variant="head">
                      Quote Total
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        value={quoteTotalPop?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Popover>
        )}

        {inputList.length === 0 ? (
          <EmptyEstimatesDialog
            heading="Add some cost centre lineitems in this category"
            msg="Add some lineitems here to Save this cost centre type. You can add using the side button present on the right side the screen"
          />
        ) : (
          <Table
            sx={{
              borderBottom: "none",
              display: "block",
              overflowX: "auto",
            }}
            size="small"
            aria-label="a dense table"
            id="estimationCosting"
            className={" estimationCosting"}
          >
            <TableHead>
              <TableRow>
                {/* {!isEdit && <TableCell sx={{ minWidth: "20px", padding: 0 }}></TableCell>} */}
                {/* This columne will show on job plan line Item */}
                {/* {!isEdit && props.type !== "estimate" && (
                  <TableCell sx={{ minWidth: "20px" }}></TableCell>
                )} */}
                {props.type !== "estimate" && !isEdit && <TableCell>Select</TableCell>}
                {props.type === "estimate" && (
                  <>{!isEdit && <TableCell sx={{ minWidth: 10 }}></TableCell>}</>
                )}
                <TableCell sx={{ minWidth: "20px" }}>Code</TableCell>
                <TableCell sx={{ minWidth: 200 }}>Name</TableCell>
                <TableCell sx={{ width: 1 / 2, minWidth: 300 }}>Description</TableCell>
                <TableCell align="center">Option</TableCell>
                <TableCell align="center" sx={{ minWidth: 185 }}>
                  Type
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 80 }}>
                  QTY
                </TableCell>
                <TableCell align="center" sx={{ minWidth: 125 }}>
                  UOM
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 75 }}>
                  Unit Cost
                </TableCell>
                <TableCell align="right">Total (Ex)</TableCell>
                {isEdit && (
                  <TableCell align="right" sx={{ minWidth: 65 }}>
                    Markup
                  </TableCell>
                )}
                {isEdit && <TableCell align="right">GST</TableCell>}
                {(hasValidAccess(ESTIMATE_WRITE) || hasValidAccess(JOB_PLAN_WRITE)) && (
                  <>{inputList.length > 1 && <TableCell align="right">Actions</TableCell>}</>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {inputList.map((row, i) => {
                return (
                  <React.Fragment>
                    {isEdit ? (
                      <React.Fragment>
                        <TableRow key={row.itemId}>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              className={classes.textField}
                              size="small"
                              fullWidth
                              value={row.itemCode}
                              name="itemCode"
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              className={classes.textField}
                              size="small"
                              fullWidth
                              value={row.itemName}
                              name="itemName"
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              className={classes.textField}
                              size="small"
                              fullWidth
                              value={row.itemDescription}
                              name="itemDescription"
                              onChange={(e) => handleInputChange(e, i)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <SearchIcon
                                      size="small"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => handleOpenSearchCatalogueDrawer(e, i)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>

                          {/* <TableCell align="center">
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.itemCategory}
                                  name="itemCategory"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell> */}
                          <TableCell align="center">{row.optionName}</TableCell>
                          <TableCell align="center">
                            <Select
                              className={classes.textField}
                              size="small"
                              fullWidth
                              name="type"
                              value={row.type}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              {userConfigs.config?.length != 0 &&
                                userConfigs.config?.map(
                                  (el) =>
                                    el.enabled === true && (
                                      <MenuItem value={el.value}>{el.value}</MenuItem>
                                    )
                                )}
                            </Select>
                          </TableCell>
                          <TableCell align="center">
                            <Grid
                              sx={{
                                display: "flex",
                                flexWrap: "unset",
                                "& input": {
                                  textAlign: "right",
                                },
                              }}
                              container
                              spacing={1}
                            >
                              <Box component={"span"} sx={{ display: "inline" }}>
                                <TextField
                                  id="qty-width"
                                  variant="outlined"
                                  fullWidth
                                  width="10px"
                                  size="small"
                                  type="number"
                                  value={row.qty}
                                  name="qty"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </Box>
                            </Grid>
                          </TableCell>
                          <TableCell align="center" id="uom">
                            <Select
                              className={classes.textField}
                              size="small"
                              fullWidth
                              name="uom"
                              value={row.uom}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              {userConfigsuom.config?.length != 0 &&
                                userConfigsuom.config?.map(
                                  (el) =>
                                    el.enabled === true && (
                                      <MenuItem value={el.value}>{el.value}</MenuItem>
                                    )
                                )}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Grid
                              sx={{
                                display: "flex",
                                flexWrap: "unset",
                                "& input": {
                                  textAlign: "right",
                                },
                              }}
                              container
                              spacing={1}
                            >
                              <Box component={"span"} sx={{ display: "inline" }}>
                                <TextField
                                  align=""
                                  id="unicost-width"
                                  variant="outlined"
                                  size="small"
                                  width="50px"
                                  className={classes.textField}
                                  value={row.unitCost}
                                  name="unitCost"
                                  type={"number"}
                                  step={1.0}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </Box>

                              {row.referenceQuotes && jobPlanObject[i]?.unitCost === row.unitCost && (
                                <Box component={"span"} sx={{ display: "flex" }}>
                                  <Tooltip
                                    title="LINKED TO QUOTE"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <IconButton aria-label="planMeasurement">
                                      <AttachFileIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}

                              {row.referenceQuotes === false &&
                                row.catalogueId !== null &&
                                jobPlanObject[i]?.unitCost === row.unitCost && (
                                  <Box component={"span"} sx={{ display: "flex" }}>
                                    <Tooltip
                                      title="LINKED TO CATALOGUE"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <IconButton aria-label="planMeasurement">
                                        <AttachFileIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                )}
                            </Grid>
                          </TableCell>

                          <TableCell align="right">
                            <NumberFormat
                              value={(row.unitCost * row.qty).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              "& input": {
                                textAlign: "right",
                              },
                            }}
                          >
                            <TextField
                              id="right"
                              variant="outlined"
                              align="center"
                              size="small"
                              className={classes.textField}
                              type="text"
                              value={row.markupPercentage}
                              name="markupPercentage"
                              onChange={(e) => handleInputChange(e, i)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PercentIcon
                                      style={{
                                        fontSize: "15px",
                                        margin: "-10px",
                                        color: "3A3B3C",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.gst === true ? (
                              <Checkbox
                                size="small"
                                value={10}
                                name="gst"
                                defaultChecked
                                onChange={(e) => handleChangeGST(e, i, row)}
                              // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                              />
                            ) : (
                              <Checkbox
                                size="small"
                                name="gst"
                                value={0}
                                // defaultChecked
                                onChange={(e) => handleChangeGST(e, i, row)}
                              // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                              />
                            )}
                          </TableCell>

                          {isEdit && inputList.length > 1 && (
                            <TableCell>
                              <ButtonGroup variant="contained" size="small">
                                <Button
                                  color="secondary"
                                  key={row.itemId}
                                  onClick={(e) => clearLineItem(row)}
                                >
                                  <ClearIcon fontSize="small" />
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableRow
                          key={row.lineItemId}
                          hover
                          selected={isCheckedJobPlan[i]?.checked ? true : false}
                        >
                          {/* {row.lineItemId != null && (
                                <TableCell padding="checkbox" align="center" width="5%">
                                  {row.quotes.length != 0 ? (
                                    <Checkbox disabled size='small' />
                                  ) : (
                                    <Checkbox size='small' value={row.lineItemId} onChange={(e) => handleAddQuote(e, row)} />
                                  )}
                                </TableCell>
                              )} */}
                          {/* <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={(e) => handleOpenCostinRow(i, row)}
                            >
                              {openItems[i] ? (
                                <Tooltip
                                  title="CLOSE MORE OPTIONS"
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <KeyboardArrowUpIcon fontSize="small" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="VIEW MORE OPTIONS"
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <KeyboardArrowDownIcon fontSize="small" />
                                </Tooltip>
                              )}
                            </IconButton>
                          </TableCell> */}
                          {/* <TableCell padding="checkbox" align="center"  >
                                  <Checkbox
                                    size="small"
                                    value={row.itemId}
                                    onChange={(e) =>
                                      handleAddToCatalogue(e, row)
                                      handleChecked(e.row)
                                    }
                                  />
                                </TableCell> */}
                          {/* This columne will show on job plan line Item */}
                          {props.type !== "estimate" && (
                            <TableCell>
                              <Checkbox
                                size="small"
                                value={row.lineItemId}
                                onChange={(e) => {
                                  handleAddToCatalogue(e, row);
                                  if (e.target.checked) {
                                    // setIsChecked([...isChecked, { id: row.lineItemId, checked: true }]);

                                    isCheckedJobPlan.filter((el) => {
                                      if (el.id === e.target.value) {
                                        return (el.checked = true);
                                      }
                                    });
                                  } else {
                                    isCheckedJobPlan.filter((el) => {
                                      if (el.id === e.target.value) {
                                        return (el.checked = false);
                                      }
                                    });
                                  }
                                  console.log("isCheckedJobPlan", isCheckedJobPlan);
                                  console.log("i");
                                }}
                              />
                            </TableCell>
                          )}
                          {props.type === "estimate" && (
                            <TableCell>
                              <Checkbox
                                size="small"
                                color="secondary"
                                value={row.lineItemId}
                                onChange={(e) => {
                                  handleAddQuote(e, row);

                                  handleAddToPlans(e, row);
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell align="center">{row.itemCode}</TableCell>

                          <TableCell>{row.itemName}</TableCell>
                          <TableCell>{row.itemDescription} </TableCell>
                          {/* <TableCell>{row.itemCategory}</TableCell> */}
                          <TableCell align="center">
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => handleOpenCostinRow(i, row, "openOptionsTable")}
                            >
                              {row.optionName}
                            </Link>
                          </TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                          <TableCell align="right">{row.qty}</TableCell>
                          <TableCell align="center">{row.uom}</TableCell>
                          <TableCell align="right">
                            {props.type === "estimate" ? (
                              ("catalogueId" in row && row.catalogueId !== null) ||
                                row.referenceQuotes !== false ? (
                                <div align="right">
                                  <Link
                                    component="button"
                                    variant="body2"
                                    underline="none"
                                    onClick={() =>
                                      handleOpenCostinRow(i, row, "openQouteOrCatalogueTable")
                                    }
                                  >
                                    <NumberFormat
                                      value={(row.unitCost * 1).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </Link>
                                </div>
                              ) : (
                                <NumberFormat
                                  value={(row.unitCost * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              )
                            ) : (
                              <NumberFormat
                                value={(row.unitCost * 1).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              component="button"
                              variant="body2"
                              underline="none"
                              aria-describedby={id}
                              onClick={(e) => handleOpenTotalPopUp(e, row)}
                            >
                              <NumberFormat
                                value={(row.unitCost * row.qty).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </Link>
                          </TableCell>
                          {/* <TableCell align="right">
                            <NumberFormat
                             
                              value={(row.markupPercentage * 1).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix="%"
                            />
                          </TableCell> */}
                          {/* <TableCell align="right">
                            <NumberFormat
                             
                              value={(getGst(row.gst) * 1).toFixed(1)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix="%"
                            />
                          </TableCell> */}
                          {(hasValidAccess(ESTIMATE_WRITE) || hasValidAccess(JOB_PLAN_WRITE)) && (
                            <>
                              {inputList.length > 1 && (
                                <TableCell align="center">
                                  <ButtonGroup
                                    variant="contained"
                                    aria-label="outlined primary button group"
                                  >
                                    <Tooltip
                                      title="DELETE LINE ITEM"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      {props.type === "jobPlan" ? (
                                        editableJobplan ? (
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            key={row.itemId}
                                            onClick={(e) => handleConfirm(row)}
                                          >
                                            <Delete fontSize="small" />
                                          </Button>
                                        ) : (
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            key={row.itemId}
                                            onClick={(e) => handleConfirm(row)}
                                            disabled
                                          >
                                            <Delete fontSize="small" />
                                          </Button>
                                        )
                                      ) : (
                                        <Button
                                          size="small"
                                          color="secondary"
                                          className="mr10"
                                          key={row.itemId}
                                          onClick={(e) => handleConfirm(row)}
                                          disabled={estimateStatus !== "DRAFT" ? true : false}
                                        >
                                          <Delete fontSize="small" />
                                        </Button>
                                      )}
                                    </Tooltip>
                                  </ButtonGroup>
                                </TableCell>
                              )}
                            </>
                          )}
                        </TableRow>
                        <TableRow className="estimation-option_row">
                          <TableCell colSpan={14} padding="none">
                            <Collapse in={openItems[i]} timeout="auto" unmountOnExit width="100%">
                              {selectedOpenTable === "openOptionsTable" && (
                                <NewJobPlanPrice
                                  rows={rows}
                                  inputList={inputList}
                                  itemRow={row}
                                  setDisableSave={setDisableSave}
                                  priceOption={priceOption}
                                  setPriceOption={setPriceOption}
                                  jobPlanId={jobPlanId}
                                  setInputList={setInputList}
                                  props={props}
                                  estimateId={estimateId}
                                  hasValidAccess={hasValidAccess}
                                  userConfigs={userConfigs}
                                  userConfigsuom={userConfigsuom}
                                  catalogueItems={catalogueItems}
                                  setRoundLoader={setRoundLoader}
                                  setSnackbarSeverity={setSnackbarSeverity}
                                  setSnackbarMSG={setSnackbarMSG}
                                  setOpenSnackbar={setOpenSnackbar}
                                  setWantToCallGetJobPlan={setWantToCallGetJobPlan}
                                  editableJobplan={editableJobplan}
                                  estimateStatus={estimateStatus}
                                />
                              )}
                              {selectedOpenTable === "openQouteOrCatalogueTable" &&
                                row.referenceQuotes === true && (
                                  <>
                                    {quoteLoader ? (
                                      <ProgressBar />
                                    ) : (
                                      <QuoteResponse
                                        row={getQuoteData}
                                        getQuoteDataById={getQuoteDataById}
                                        setWantToCallGet={setWantToCallGet}
                                        callFrom={"estimateLineItem"}
                                      />
                                    )}
                                  </>
                                )}
                              {selectedOpenTable === "openQouteOrCatalogueTable" &&
                                row.referenceQuotes === false && (
                                  <Catalogue
                                    callFrom={"estimateLineItem"}
                                    catalogueId={row.catalogueId}
                                  />
                                )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>

      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={deleteItemNonEditMode} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                style={{
                  fontSize: "20px",
                }}
              >
                Select Catalogue Item
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ width: "100%", overflow: "hidden" }}>
                <MUIDataTable data={catalogueItems} columns={columns} options={options} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Drawer>
      {openPlanDialog && planObject.length !== 0 && (
        <WebDrawer
          estimateId={estimateId}
          autoSelectPlanName={autoSelectPlanName}
          setAutoSelectPlanName={setAutoSelectPlanName}
          setDistanceValue={setDistanceValue}
          distanceValue={distanceValue}
          openPlanDialog={openPlanDialog}
          setInstance={setInstance}
          instance={instance}
          setOpenPlanDialog={setOpenPlanDialog}
          handleSaveMeasure={handleSaveMeasure}
          planObject={planObject}
          setWantToCallUseEffect={setWantToCallUseEffect}
          setSnackbarMSG={setSnackbarMSG}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarSeverity={setSnackbarSeverity}
        />
      )}
      {quoteDrawerState.right && (
        <EditQuoteDrawer
          state={quoteDrawerState}
          setState={setQuoteDrawerState}
          toggleDrawer={toggleQuoteEditDrawer}
          contacts={contacts}
          setContacts={setContacts}
          getQuoteDataID={getQuoteDataID}
          secondLoader={secondLoader}
          setSecondLoader={setSecondLoader}
          apiQuoteItemList={apiQuoteItemList}
          setApiQuoteItemList={setApiQuoteItemList}
          localQuoteItemList={localQuoteItemList}
          setLocalQuoteItemList={setLocalQuoteItemList}
          wantToCallGet={wantToCallGet}
          setWantToCallGet={setWantToCallGet}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarMSG={setSnackbarMSG}
          setOpenSnackbar={setOpenSnackbar}
          estimateData={{ estimateId }}
          setRefreshQuote={setRefreshQuote}
        />
      )}
    </>
  );
}
