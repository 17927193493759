/** @format */

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Divider, Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CKEditor } from "ckeditor4-react";
import Table from "@mui/material/Table";
import FormControl from "@mui/material/FormControl";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  createQuoteRequest,
  getEstimateLineItemList,
  updateQuoteRequest,
} from "../helpers.quoteRequestAPI";
import ProgressBar from "../../UI/ProgressBar";
import { Link, useHistory } from "react-router-dom";
import DateCreator from "../../UI/DateCreator";
import ShortClientContactForm from "../../UI/ShortClientContactForm";
import AddIcon from "@mui/icons-material/Add";
import { ButtonGroup, MenuItem, Select } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";
import { Delete } from "@mui/icons-material";
import { checkDate, checkProperties, getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { Controller, useForm } from "react-hook-form";
import {
  createContacts,
  createContactsShortcut,
  updateContactFromAutocomplete,
} from "../../contacts/contacts.helpers";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import ErrorStyles from "../../UI/ErrorStyles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreateContactDrawer from "../../contacts/CreateContactDrawer";
import SaveIcon from "@mui/icons-material/Save";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  button: {
    marginRight: "5px",
  },
  errorMsg: {
    marginBottom: 0,
    color: "red",
  },
}));

function EditQuoteDrawer({
  state,
  setState,
  quoteState,
  setQuoteState,
  toggleDrawer,
  newQuoteDrawer,
  contacts,
  setContacts,
  getQuoteDataID,
  secondLoader,
  setSecondLoader,
  apiQuoteItemList,
  setApiQuoteItemList,
  localQuoteItemList,
  setLocalQuoteItemList,
  wantToCallGet,
  setWantToCallGet,
  estimateData,
  setContactWantToGet,
  setCreateQuote,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenErrDialog,
  setErrorCode,
  setErrorMsg,
  setValue,
  setRefreshQuote,
  estmtLineItemList,
  tempEstmtLineItemList,
  setTempEstmtLineItemList,
}) {
  const filter = createFilterOptions();
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [title, setTitle] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [recipientsEmail, setRecipientsEmail] = useState();
  const [uploadfile, setUploadFile] = useState();
  const [scopeofwork, setScopeofWork] = useState();
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [invitationmessage, setInvitationMessage] = useState();
  const [responsesQuoteData, setResponsesQuoteData] = useState(false);
  // const [estmtLineItemList, setEstmtLineItemList] = useState([]);
  // const [tempEstmtLineItemList, setTempEstmtLineItemList] = useState([]);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [editQuoteDataID, setEditQuoteDataID] = useState();
  const [estimateDescription, setEstimateDescription] = useState();
  const [estimateObjId, setEstimateObjId] = useState();
  const [quoteNo, setQuoteNo] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [userConfigs, setUserConfigs] = useState({});
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const steps = ["Set Up", "Recipients"];
  const [roundLoader, setRoundLoader] = useState(false);
  const [contactDrawerState, setContactDrawerState] = React.useState({
    right: false,
  });
  const [editContact, setEditContact] = useState(false);
  const [contactData, setContactData] = useState({ editable: true });
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [newContactMail, setNewContactMail] = useState("");

  const handleConfirm = (index) => {
    setDeleteIndex(index);
    setShowConfirm(true);
  };
  const handleRemoveClick = (index) => {
    const list = [...localQuoteItemList];
    list.splice(deleteIndex, 1);
    setLocalQuoteItemList(list);
    setConfirmDelete(false);
    setShowConfirm(false);
  };
  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [lineItemError, setLineItemError] = React.useState("");
  console.log("userConfigs", userConfigs);
  console.log("userConfigsuom", userConfigsuom);
  var estimateId = estimateData.estimateId;
  useEffect(() => {
    if (getQuoteDataID.length != 0) {
      console.log("userConfigs", getQuoteDataID);
      // Update the document title using the browser API
      setEstimateObjId(getQuoteDataID.estimateId);
      setEstimateDescription(getQuoteDataID.estimateDescription);
      setTitle(getQuoteDataID.title);
      setInvitationMessage(getQuoteDataID.message);
      setExpiryDate(getQuoteDataID.expiryDate);
      setEditQuoteDataID(getQuoteDataID.quoteId);
      setQuoteNo(getQuoteDataID.quoteNo);
      setRecipientsEmail(getQuoteDataID.recipients);

      // getEstimateLineItemList(getQuoteDataID.estimateId, setTempEstmtLineItemList, setEstmtLineItemList);
    }
  }, [getQuoteDataID]);

  useEffect(() => {
    getUserConfigsTypes(setUserConfigs);
    getUserConfigsUom(setUserConfigsuom, setDummyLoad);
  }, []);

  const handleAdd = () => {
    setIsEdit(true);
    setShowSaveButton(false);
    setLocalQuoteItemList([
      ...localQuoteItemList,
      {
        description: "",
        estimateLineItemId: null,
        qty: 1,
        quoteLineItemId: null,
        type: "",
        uom: "",
      },
    ]);

    filterEstimateLineData();
  };
  function filterEstimateLineData() {
    const filterByID = new Set(localQuoteItemList?.map(({ estimateLineItemId }) => estimateLineItemId));
    const combined = tempEstmtLineItemList?.filter(
      ({ estimateLineItemId }) => !filterByID.has(estimateLineItemId)
    );

    setTempEstmtLineItemList(combined);
  }
  const handleNext = () => {
    if (checkDate(expiryDate) < 0) {
      setSnackbarMSG("Date has expired !");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // let count = localQuoteItemList.filter((el, index) => {
    //   if (el.qty > 0 && el.description && el.type && el.uom) {
    //     return el;
    //   }
    // });

    // if (count.length === localQuoteItemList.length) {
    //   let newSkipped = skipped;
    //   if (isStepSkipped(activeStep)) {
    //     newSkipped = new Set(newSkipped.values());
    //     newSkipped.delete(activeStep);
    //   }
    //   /* we are getting contact here bcz we want to call get contact api only when we click on Next button */

    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   setSkipped(newSkipped);
    // } else {
    //   setLineItemError("line item should not be empty");
    //   setIsEdit(true);
    //   setSecondLoader(false);
    // }
  };

  const closeFunction = () => {
    setState({ ...state, ["right"]: false });
    setActiveStep(0);
  };
  const handleClose = () => {
    setQuoteState({ ...quoteState, ["right"]: false });
  };
  const handleSubmitQuote = (e) => {
    let valid = true;
    let recipientsValid = true;
    let errMsg = "";
    e.preventDefault();
    let mailsent = e.currentTarget.dataset.mailsent;
    console.log("mailsent", e.currentTarget.dataset.mailsent);
    var contactIdArr =
      recipientsEmail &&
      recipientsEmail.map((res) => {
        if (res.status === "INCOMPLETE") recipientsValid = false;
        return res.contactId;
      });
    let quoteRequestarr;

    console.log("outside quoteRequestarr", quoteRequestarr);
    var date = new Date(expiryDate).toISOString().slice(0, 10);
    /*   Edit Quote  */
    // if (editQuoteDataID) {
    quoteRequestarr = {
      estimateDescription: estimateDescription,
      estimateId: estimateObjId,
      quoteId: editQuoteDataID,
      quoteNo: quoteNo,
      title: title,
      message: invitationmessage,
      recipients: contactIdArr,
      expiryDate: date,
      quoteLineItems: localQuoteItemList,
      sendToAllRecipients: "false",
    };

    if (mailsent == "true") {
      quoteRequestarr.sendToAllRecipients = "true";
    }

    console.log("..........testing..................");
    console.log("title", title);
    console.log("quoteRequestarr", quoteRequestarr);
    errMsg = checkProperties(quoteRequestarr);
    if (errMsg !== "") valid = false;
    if (valid == false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      updateQuoteRequest(
        quoteRequestarr,
        setResponsesQuoteData,
        setWantToCallGet,
        wantToCallGet,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader,
        setState,
        setRefreshQuote
      );
    }
    // } else {
    console.log("estimateid", estimateObjId, "description", estimateDescription);

    if (mailsent === "true") {
      quoteRequestarr.sendToAllRecipients = "true";
    }

    errMsg = checkProperties(quoteRequestarr);
    if (errMsg !== "") valid = false;

    // Add code
    // if (valid == false) {
    //   setSnackbarMSG(`${errMsg} can not be empty`);
    //   setSnackbarSeverity("error");
    //   setOpenSnackbar(true);
    // } else {
    //   createQuoteRequest(
    //     quoteRequestarr,
    //     setResponsesQuoteData,
    //     setOpenSnackbar,
    //     setSnackbarSeverity,
    //     setSnackbarMSG,
    //     setCreateQuote,
    //     wantToCallGetJobPlan,
    //     setWantToCallGetJobPlan,
    //     setRoundLoader,
    //     setState,
    //     setOpenErrDialog,
    //     setErrorCode,
    //     setErrorMsg,
    //     history,
    //     estimateId,
    //     setValue
    //   );
    // }
    // }
    setActiveStep(0);
  };
  const handleNewQuoteSubmit = (e) => {
    let valid = true;
    let recipientsValid = true;
    let errMsg = "";
    e.preventDefault();
    let mailsent = e.currentTarget.dataset.mailsent;
    var contactIdArr =
      recipientsEmail &&
      recipientsEmail.map((res) => {
        if (res.status === "INCOMPLETE") recipientsValid = false;
        return res.contactId;
      });
    let quoteRequestarr;

    var date = new Date(expiryDate).toISOString().slice(0, 10);
    quoteRequestarr = {
      estimateId: estimateData.estimateId,
      title: title,
      expiryDate: date,
      message: invitationmessage,
      recipients: contactIdArr,
      quoteLineItems: localQuoteItemList,
      sendToAllRecipients: "false",
    };
    if (mailsent == "true") {
      quoteRequestarr.sendToAllRecipients = "true";
    }

    errMsg = checkProperties(quoteRequestarr);
    if (errMsg !== "") valid = false;
    if (valid == false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else {
      createQuoteRequest(
        quoteRequestarr,
        setResponsesQuoteData,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setCreateQuote,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setRoundLoader,
        setQuoteState,
        setOpenErrDialog,
        setErrorCode,
        setErrorMsg,
        history,
        estimateId,
        setValue
      );
    }
  };
  /* -------------------------------------------------------- */
  const clearLineItem = (row) => {
    let filterList = localQuoteItemList.filter((el) => {
      return row.estimateLineItemId !== el.estimateLineItemId;
    });

    // let count = 1;
    // var test = filterList.filter(function (el) {
    //   return (el.itemCode = count++);
    // });
    console.log("filterList", filterList);
    setLocalQuoteItemList(filterList);
    setTempEstmtLineItemList(estmtLineItemList);
  };
  /* ---------------------------------------------------------------- */
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(localQuoteItemList));
    list[index][name] = value;
    if (name === "qty") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    setLocalQuoteItemList(list);


  };
  /* ---------------------------------------------------------------- */

  const handleEdit = (i) => {
    setIsEdit(true);
    filterEstimateLineData();
    setShowSaveButton(false);
  };
  /* ---------------------------------------------------------------- */

  const handlesLineItemSave = () => {
    let valid = true;
    let errMsg = checkProperties(localQuoteItemList);
    if (errMsg !== "") valid = false;

    // var finalVariationObj = localQuoteItemList.filter((el) => {
    //   if (el.qty != "" || el.description != "" || el.type != "" || el.uom != "") {
    //     return el;
    //   }
    // });
    if (valid == true) {
      setShowSaveButton(true);
      setLocalQuoteItemList(localQuoteItemList);
      setApiQuoteItemList(localQuoteItemList);
      setIsEdit(false);
      setLineItemError("");
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  /* ---------------------------------------------------------------- */

  const handleInputAutocomplete = (index, newValue) => {
    const list = JSON.parse(JSON.stringify(localQuoteItemList));
    list[index] = newValue;
    let filtered = tempEstmtLineItemList.filter((el) => {
      if (el.estimateLineItemId !== newValue.estimateLineItemId) {
        return el;
      }
    });
    setLocalQuoteItemList(list);
    setTempEstmtLineItemList(filtered);
  };
  /* ---------------------------------------------------------------- */
  const handleCancel = () => {
    setLocalQuoteItemList(apiQuoteItemList); /* Reset our old data after cancel edit/add operation  */
    setTempEstmtLineItemList(estmtLineItemList);
    setLineItemError("");
    setShowSaveButton(true);
    setIsEdit(false);
  };
  /* ---------------------------------------------------------------- */

  /* Contact Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  /*************** Submit Contacts Details **************** */
  const submitContactDetails = (formData) => {
    console.log("formData:", formData);
    if (editContact) {
      updateContactFromAutocomplete(
        formData,
        contacts,
        setContacts,
        recipientsEmail,
        setRecipientsEmail,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setContactDrawerState
      );
      setEditContact(false);
    } else {
      createContacts(
        formData,
        setDummyLoad,
        dummyLoad,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setContactDrawerState
      );
    }
  };
  // ----------------------------------------------
  const toggleContactDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setContactDrawerState({ ...contactDrawerState, [anchor]: open });
  };
  // ----------------------------------------------

  const handleEditContact = (contact) => {
    console.log("Contact:", contact);
    setContactData(contact);
    setContactDrawerState({ right: true });
  };
  /* ---------------------------------------------------------------- */
  console.log("recipientsEmail::", recipientsEmail);

  let quoteItemcount = 1;

  const handleCreateContact = (option) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidEmail = emailPattern.test(option);
    if (isValidEmail) {
      createContactsShortcut(
        { emailAddress: option },
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setContacts,
        contacts,
        recipientsEmail,
        setRecipientsEmail
      );
    } else {
      setSnackbarMSG("Email is not valid");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <React.Fragment>
      {quoteState === undefined || state["right"] ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginTop: "5px", marginRight: "18px" }}>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("left", false)}
              PaperProps={{
                sx: {
                  width: "70%",
                },
              }}
            >
              {roundLoader && <SimpleBackdrop />}
              <div className={classes.Drawerroot}>
                {secondLoader ? (
                  <>
                    <br />
                    <ProgressBar />
                  </>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box component="div">
                        <Typography
                          component="h5"
                          color="primary"
                          mb={1}
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Edit Quote Requests
                        </Typography>

                        <Divider />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        style={{ width: "100%" }}
                        value={title}
                        size="small"
                        label="Title"
                        id="quote_title"
                        name="quote_title"
                        onChange={(e) => setTitle(e.target.value)}
                        className={classes.textField}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Stack sx={{ width: "100%" }}>
                        <DateCreator
                          setExpiryDate={setExpiryDate}
                          expiryDate={expiryDate}
                          label="ExpiryDate"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Autocomplete
                        multiple
                        id="Recipients_Email"
                        value={recipientsEmail}
                        options={contacts}
                        // renderOption={renderOption}
                        getOptionLabel={(option) => {
                          console.log("optionType::", typeof option, "::option=", option);
                          if (typeof option === "string") {
                            return (
                              <div>
                                <Grid container spacing={1}>
                                  <Grid item>{option}</Grid>
                                  <Grid item>
                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        handleCreateContact(option);
                                        // setNewContactMail(option);
                                        // setOpenContactDialog(true);
                                      }}
                                    >
                                      <PersonAddIcon color="warning" fontSize="small" />
                                    </Link>
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          } else {
                            if (option.status === "COMPLETE") {
                              return <div>{option.emailAddress}</div>;
                            } else {
                              return (
                                <div>
                                  <Grid container spacing={1}>
                                    <Grid item>{option.emailAddress}</Grid>
                                    <Grid item>
                                      <Link to={"#"} onClick={() => handleEditContact(option)}>
                                        <EditIcon color="warning" fontSize="small" />
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </div>
                              );
                            }
                          }
                        }}
                        onChange={(event, newValue) => {
                          // console.log("NEWVALUE TYPE::", typeof newValue);
                          // if (typeof newValue === "object")
                          setRecipientsEmail(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Recipients"
                            placeholder="Add Recipients"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.itemCode);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                      />
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            setContactDrawerState({ right: true });
                            setContactData({ editable: true });
                          }}
                        >
                          + New Contact
                        </Button>
                      </Grid>
                    </Grid>
                    {/* <Grid
                    item
                    xs={3}
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <FormControl>
                      <Button variant="contained" component="label" color="primary" size="small">
                        <input type="file" onChange={(e) => setUploadFile(e.target.files[0])} />
                      </Button>
                    </FormControl>
                  </Grid> */}

                    {localQuoteItemList.length !== 0 ? (
                      <Grid item xs={12} my={-2} alignSelf={"flex-end"} mr={1} ml={1}>
                        {isEdit ? (
                          <ButtonGroup
                            variant="contained"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            aria-label="contained primary button group"
                          >
                            <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                              <AddIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              align="right"
                              onClick={handleCancel}
                              size="small"
                            >
                              <ClearIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handlesLineItemSave}
                              size="small"
                            >
                              <SaveIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ButtonGroup
                            variant="contained"
                            aria-label="contained primary button group"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                              <AddIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                              size="small"
                            >
                              <CreateIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <ButtonGroup
                          variant="contained"
                          style={{ display: "flex", justifyContent: "flex-end" }}
                          aria-label="contained primary button group"
                        >
                          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                            <AddIcon onClick={handleAdd} fontSize="small" />
                            ADD
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    minWidth: "20px",
                                  }}
                                  align="center"
                                >
                                  <strong>Code</strong>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    minWidth: "650px",
                                  }}
                                >
                                  <strong>Description</strong>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    minWidth: "150px",
                                  }}
                                >
                                  <strong>Type</strong>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    minWidth: "50px",
                                  }}
                                >
                                  <strong>Qty</strong>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    minWidth: "100px",
                                  }}
                                >
                                  <strong>UOM</strong>
                                </TableCell>
                                <TableCell align="right">
                                  <strong>Action</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {localQuoteItemList?.map((quoteItem, index) => (
                                <>
                                  {isEdit ? (
                                    <TableRow>
                                      <TableCell align="center">{quoteItemcount++}</TableCell>
                                      <TableCell width="55%">
                                        <Autocomplete
                                          options={tempEstmtLineItemList}
                                          defaultValue={quoteItem}
                                          disableClearable
                                          size="small"
                                          min-width="150"
                                          id="description"
                                          onChange={(event, newValue) => {
                                            handleInputAutocomplete(index, newValue);
                                          }}
                                          getOptionLabel={(option) => option?.description}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              name="description"
                                            />
                                          )}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {/* <TextField
                                    value={quoteItem.type}
                                    size="small"
                                    name="type"
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={classes.textField}
                                    variant="outlined"
                                  /> */}

                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="type"
                                          value={quoteItem.type}
                                          onChange={(e) => handleInputChange(e, index)}
                                        >
                                          {userConfigs.config?.length != 0 &&
                                            userConfigs.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                          },
                                        }}
                                      >
                                        <TextField
                                          id="muitable_cell_title_qlt"
                                          value={quoteItem.qty}
                                          fullWidth
                                          size="small"
                                          name="qty"
                                          type="number"
                                          onChange={(e) => handleInputChange(e, index)}
                                          className={classes.textField}
                                          variant="outlined"
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="uom"
                                          value={quoteItem.uom}
                                          onChange={(e) => handleInputChange(e, index)}
                                        >
                                          {userConfigsuom.config?.length != 0 &&
                                            userConfigsuom.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>

                                        {/* <TextField
                                    value={quoteItem.uom}
                                    size="small"
                                    name="uom"
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={classes.textField}
                                    variant="outlined"
                                  /> */}
                                      </TableCell>
                                      <TableCell align="right">
                                        <ButtonGroup variant="contained" size="small">
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            onClick={(e) => clearLineItem(quoteItem)}
                                          >
                                            <ClearIcon fontSize="small" />
                                          </Button>
                                        </ButtonGroup>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <TableRow>
                                      <TableCell align="center">{quoteItemcount++}</TableCell>
                                      <TableCell width="40%">{quoteItem.description}</TableCell>
                                      <TableCell align="center">{quoteItem.type}</TableCell>
                                      <TableCell align="right">{quoteItem.qty}</TableCell>
                                      <TableCell align="center">{quoteItem.uom}</TableCell>
                                      <TableCell align="right">
                                        <ButtonGroup
                                          variant="contained"
                                          aria-label="outlined primary button group"
                                          key={quoteItem}
                                          onClick={(e) => handleConfirm(index)}
                                        >
                                          <Button size="small" className="mr10" color="secondary">
                                            <Delete fontSize="small" />
                                          </Button>
                                        </ButtonGroup>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack sx={{ width: "100%" }}>
                        <FormControl>
                          <br />
                          {
                            <CKEditor
                              initData={getQuoteDataID.message}
                              onChange={(e) => setInvitationMessage(e.editor.getData())}
                            />
                          }
                        </FormControl>
                      </Stack>
                    </Grid>
                    {showSaveButton && (
                      <Grid item xs={12} align="right">
                        <Button
                          color="primary"
                          size="small"
                          type="button"
                          variant="contained"
                          onClick={handleSubmitQuote}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          type="button"
                          data-mailsent="true"
                          onClick={handleSubmitQuote}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Save and Send
                        </Button>
                        <Button
                          size="small"
                          color="secondary"
                          type="button"
                          variant="contained"
                          onClick={closeFunction}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
            </Drawer>

            {contactDrawerState.right && (
              <CreateContactDrawer
                contactData={contactData}
                setContactData={setContactData}
                contacts={contacts}
                setContacts={setContacts}
                recipientsEmail={recipientsEmail}
                setRecipientsEmail={setRecipientsEmail}
                setOpenSnackbar={setOpenSnackbar}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setRoundLoader={setRoundLoader}
                contactDrawerState={contactDrawerState}
                setContactDrawerState={setContactDrawerState}
                callFrom={"editQuote"}
              />
            )}
            {openContactDialog && (
              <Dialog
                open={openContactDialog}
                onClose={() => setOpenContactDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle align="center" id="alert-dialog-title">
                  Add Contact
                </DialogTitle>
                <DialogContent>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item xs={12} mt={1}>
                      <TextField
                        value={newContactMail}
                        variant="outlined"
                        size="small"
                        onChange={(e) => setNewContactMail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonGroup variant="contained">
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        setOpenContactDialog(false);
                      }}
                    >
                      Add
                    </Button>
                    <Button size="small" color="secondary" onClick={() => setOpenContactDialog(false)}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </DialogActions>
              </Dialog>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {quoteState !== undefined && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginTop: "5px", marginRight: "18px" }}>
            <Drawer
              anchor={"right"}
              open={quoteState["right"]}
              onClose={newQuoteDrawer("left", false)}
              PaperProps={{
                sx: {
                  width: "70%",
                },
              }}
            >
              {roundLoader && <SimpleBackdrop />}
              <div className={classes.Drawerroot}>
                {secondLoader ? (
                  <>
                    <br />
                    <ProgressBar />
                  </>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box component="div">
                        <Typography
                          component="h5"
                          color="primary"
                          mb={1}
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          New Quote Requests
                        </Typography>

                        <Divider />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        style={{ width: "100%" }}
                        value={title}
                        size="small"
                        label="Title"
                        id="quote_title"
                        name="quote_title"
                        onChange={(e) => setTitle(e.target.value)}
                        className={classes.textField}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Stack sx={{ width: "100%" }}>
                        <DateCreator
                          setExpiryDate={setExpiryDate}
                          expiryDate={expiryDate}
                          label="ExpiryDate"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Autocomplete
                        multiple
                        id="Recipients_Email"
                        value={recipientsEmail}
                        options={contacts}
                        // renderOption={renderOption}
                        getOptionLabel={(option) => {
                          console.log("optionType::", typeof option, "::option=", option);
                          if (typeof option === "string") {
                            return (
                              <div>
                                <Grid container spacing={1}>
                                  <Grid item>{option}</Grid>
                                  <Grid item>
                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        handleCreateContact(option);
                                        // setNewContactMail(option);
                                        // setOpenContactDialog(true);
                                      }}
                                    >
                                      <PersonAddIcon color="warning" fontSize="small" />
                                    </Link>
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          } else {
                            if (option.status === "COMPLETE") {
                              return <div>{option.emailAddress}</div>;
                            } else {
                              return (
                                <div>
                                  <Grid container spacing={1}>
                                    <Grid item>{option.emailAddress}</Grid>
                                    <Grid item>
                                      <Link to={"#"} onClick={() => handleEditContact(option)}>
                                        <EditIcon color="warning" fontSize="small" />
                                      </Link>
                                    </Grid>
                                  </Grid>
                                </div>
                              );
                            }
                          }
                        }}
                        onChange={(event, newValue) => {
                          // console.log("NEWVALUE TYPE::", typeof newValue);
                          // if (typeof newValue === "object")
                          setRecipientsEmail(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            label="Recipients"
                            placeholder="Add Recipients"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.itemCode);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        }}
                      />
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            setContactDrawerState({ right: true });
                            setContactData({ editable: true });
                          }}
                        >
                          + New Contact
                        </Button>
                      </Grid>
                    </Grid>
                    {localQuoteItemList.length !== 0 ? (
                      <Grid item xs={12} my={-2} alignSelf={"flex-end"} mr={1} ml={1}>
                        {isEdit ? (
                          <ButtonGroup
                            variant="contained"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            aria-label="contained primary button group"
                          >
                            <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                              <AddIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              align="right"
                              onClick={handleCancel}
                              size="small"
                            >
                              <ClearIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handlesLineItemSave}
                              size="small"
                            >
                              <SaveIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <ButtonGroup
                            variant="contained"
                            aria-label="contained primary button group"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                              <AddIcon fontSize="small" />
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                              size="small"
                            >
                              <CreateIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <ButtonGroup
                          variant="contained"
                          style={{ display: "flex", justifyContent: "flex-end" }}
                          aria-label="contained primary button group"
                        >
                          <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                            <AddIcon onClick={handleAdd} fontSize="small" />
                            ADD
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  sx={{
                                    minWidth: "20px",
                                  }}
                                  align="center"
                                >
                                  <strong>Code</strong>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    minWidth: "650px",
                                  }}
                                >
                                  <strong>Description</strong>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    minWidth: "150px",
                                  }}
                                >
                                  <strong>Type</strong>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    minWidth: "50px",
                                  }}
                                >
                                  <strong>Qty</strong>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    minWidth: "100px",
                                  }}
                                >
                                  <strong>UOM</strong>
                                </TableCell>
                                <TableCell align="right">
                                  <strong>Action</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {localQuoteItemList?.map((quoteItem, index) => (
                                <>
                                  {isEdit ? (
                                    <TableRow>
                                      <TableCell align="center">{quoteItemcount++}</TableCell>
                                      <TableCell width="55%">
                                        <Autocomplete
                                          options={tempEstmtLineItemList}
                                          defaultValue={quoteItem}
                                          disableClearable
                                          size="small"
                                          min-width="150"
                                          id="description"
                                          onChange={(event, newValue) => {
                                            handleInputAutocomplete(index, newValue);
                                          }}
                                          getOptionLabel={(option) => option?.description}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              name="description"
                                            />
                                          )}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {/* <TextField
                                    value={quoteItem.type}
                                    size="small"
                                    name="type"
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={classes.textField}
                                    variant="outlined"
                                  /> */}

                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="type"
                                          value={quoteItem.type}
                                          onChange={(e) => handleInputChange(e, index)}
                                        >
                                          {userConfigs.config?.length != 0 &&
                                            userConfigs.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          "& input": {
                                            textAlign: "right",
                                          },
                                        }}
                                      >
                                        <TextField
                                          id="muitable_cell_title_qlt"
                                          value={quoteItem.qty}
                                          fullWidth
                                          size="small"
                                          name="qty"
                                          type="number"
                                          onChange={(e) => handleInputChange(e, index)}
                                          className={classes.textField}
                                          variant="outlined"
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <Select
                                          className={classes.textField}
                                          size="small"
                                          fullWidth
                                          name="uom"
                                          value={quoteItem.uom}
                                          onChange={(e) => handleInputChange(e, index)}
                                        >
                                          {userConfigsuom.config?.length != 0 &&
                                            userConfigsuom.config?.map(
                                              (el) =>
                                                el.enabled === true && (
                                                  <MenuItem value={el.value}>{el.value}</MenuItem>
                                                )
                                            )}
                                        </Select>

                                        {/* <TextField
                                    value={quoteItem.uom}
                                    size="small"
                                    name="uom"
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={classes.textField}
                                    variant="outlined"
                                  /> */}
                                      </TableCell>
                                      <TableCell align="right">
                                        <ButtonGroup variant="contained" size="small">
                                          <Button
                                            size="small"
                                            color="secondary"
                                            className="mr10"
                                            onClick={(e) => clearLineItem(quoteItem)}
                                          >
                                            <ClearIcon fontSize="small" />
                                          </Button>
                                        </ButtonGroup>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <TableRow>
                                      <TableCell align="center">{quoteItemcount++}</TableCell>
                                      <TableCell width="40%">{quoteItem.description}</TableCell>
                                      <TableCell align="center">{quoteItem.type}</TableCell>
                                      <TableCell align="right">{quoteItem.qty}</TableCell>
                                      <TableCell align="center">{quoteItem.uom}</TableCell>
                                      <TableCell align="right">
                                        <ButtonGroup
                                          variant="contained"
                                          aria-label="outlined primary button group"
                                          key={quoteItem}
                                          onClick={(e) => handleConfirm(index)}
                                        >
                                          <Button size="small" className="mr10" color="secondary">
                                            <Delete fontSize="small" />
                                          </Button>
                                        </ButtonGroup>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack sx={{ width: "100%" }}>
                        <FormControl>
                          <br />
                          {
                            <CKEditor
                              initData={getQuoteDataID.message}
                              onChange={(e) => setInvitationMessage(e.editor.getData())}
                            />
                          }
                        </FormControl>
                      </Stack>
                    </Grid>

                    {showSaveButton && (
                      <Grid item xs={12} align="right">
                        <Button
                          color="primary"
                          size="small"
                          type="button"
                          variant="contained"
                          onClick={handleNewQuoteSubmit}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          type="button"
                          data-mailsent="true"
                          onClick={handleNewQuoteSubmit}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Save and Send
                        </Button>
                        <Button
                          size="small"
                          color="secondary"
                          type="button"
                          variant="contained"
                          onClick={handleClose}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
              {contactDrawerState.right && (
                <CreateContactDrawer
                  contactData={contactData}
                  setContactData={setContactData}
                  contacts={contacts}
                  setContacts={setContacts}
                  recipientsEmail={recipientsEmail}
                  setRecipientsEmail={setRecipientsEmail}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarMSG={setSnackbarMSG}
                  setSnackbarSeverity={setSnackbarSeverity}
                  setRoundLoader={setRoundLoader}
                  contactDrawerState={contactDrawerState}
                  setContactDrawerState={setContactDrawerState}
                  callFrom={"editQuote"}
                />
              )}
            </Drawer>

          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default EditQuoteDrawer;
