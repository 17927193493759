import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";

class DesEstimates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [44, 55, 13, 43],
            options: {
                chart: {
                    foreColor: "currentColor",
                },
                labels: ["Strategy", "Outsourcing", "Marketing", "Other"],
                dataLabels: {
                    enabled: false,
                },
                colors: ["#2eaa57", "#73c247", "#b4d734", "#fae826"],
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                },
                fill: {
                    type: "solid",
                },
                stroke: {
                    show: true,
                    colors: ["rgba(0 0 0 / 20%)"],
                },
            },
        };
    }

    render() {
        return (
            <>
                <div className="growth_right_part chart_box">
                    <Typography variant="h5" gutterBottom>
                        Cost Breakdown
                    </Typography>
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="pie"
                        height={"100%"}
                    />
                </div>
            </>
        );
    }
}

export default DesEstimates;
