/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import { createJob } from "./jobs.helper";
import Divider from "@mui/material/Divider";
import { getClients } from "../estimates/estimates.helpers";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ErrorStyles from "../UI/ErrorStyles";
import Autocomplete from "@mui/material/Autocomplete";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateCreatorForForm from "./orders/DateCreatorForForm";

const useStyles = makeStyles((theme) => ({
  cardtitle: {
    padding: theme.spacing(2),
  },
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));
export default function CreateJobDrawer({
  state,
  quote,
  handleSubmit,
  Controller,
  setValue,
  isDisabled,
  setJobCreated,
  setCreateJobOpenDrawer,
  toggleDrawer,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  estimateId,
}) {
  const history = useHistory();
  const ErrorClasses = ErrorStyles();
  const classes = useStyles();
  const [roundLoader, setRoundLoader] = useState(false);
  // const [description, setDescription] = useState(row.description);
  // const [type, setType] = useState(row.jobType);
  // const [customer, setCustomer] = useState([]);
  // const [rows, setRows] = useState([]);
  // const [customerName, setCustomerName] = useState();
  // const [load, setLoad] = useState(true);
  // const [openDialog, setOpenDialog] = React.useState(false);
  // const [name, setName] = useState();
  // const [email, setEmail] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [status, setStatus] = useState([
    "DRAFT",
    "PENDING",
    "IN_PROGRESS",
    "COMPLETE",
    "CANCELLED",
  ]);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedExpectedDate, setSelectedExpectedDate] = useState(
    new Date()
      .toLocaleString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  );

  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  // console.log("rows in create- ", row.customerName);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handleOrderSubmit = () => {
    // var userEnterDate = new Date(expiryDate).toISOString().slice(0, 10);
    // console.log("date", userEnterDate);
    // var jobObj = {
    //   estimate_id: row.estimateId,
    //   description: row.description,
    //   job_type: row.jobType,
    //   customer_name: customerName,
    //   expected_start_date: userEnterDate,
    // };
    let row = getValues();
    let obj = {
      estimateId: estimateId,
      description: row.description,
      expectedStartDate: selectedExpectedDate.$d
        ? selectedExpectedDate.$d
            .toLocaleString("en-us", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
        : selectedExpectedDate,
      status: selectedStatus.status,
    };
    // console.log("FINAL OBJ::", obj);
    createJob(
      obj,
      setOpenSnackbar,
      setCreateJobOpenDrawer,
      history,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader
    );
    setJobCreated(true);
    // console.log("obj", obj);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: "5px", marginRight: "18px" }}>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: 400,
              },
            }}
          >
            <div className={classes.Drawerroot}>
              {roundLoader && <SimpleBackdrop />}
              <form onSubmit={handleSubmit(handleOrderSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      color="primary"
                      mb={1}
                      style={{ fontSize: "20px" }}
                    >
                      New Job from Estimate
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true }}
                      label="Description"
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          label=" Description "
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.description?.type === "required" &&
                        "First name is required"}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack sx={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          id="expectedStartDate"
                          fullWidth
                          size="small"
                          label="Expected Start Date"
                          inputVariant="outlined"
                          onChange={(newvalue) =>
                            setSelectedExpectedDate(newvalue)
                          }
                          value={selectedExpectedDate}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      required
                      onChange={(event, newValue) => {
                        setSelectedStatus({
                          ...selectedStatus,
                          status: newValue,
                        });
                      }}
                      value={selectedStatus.status}
                      options={status}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Status"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                    >
                      SAVE
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={toggleDrawer(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      CANCEL
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Drawer>
        </div>
      </div>
    </React.Fragment>
  );
}
