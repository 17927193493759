/** @format */
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { Button, Grid, TextField, Divider } from "@mui/material";
import { createJobPlan } from "./jobplan.helpers";
import clsx from "clsx";
import useStyles from "../../UI/useStyles";
import { getCostCentres, getSingleCostCentre } from "../costcenter.helpers";
import { Autocomplete } from "@mui/material";
import { getJobplans } from "../../jobPlans/jobplans.helpers";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { checkProperties } from "../../UI/GlobalApi";
import { useForm, Controller } from "react-hook-form";
import errorStyles from "../../UI/ErrorStyles";

export default function CreateNewJobPlanDialog({
  costCentreId,
  setLoadResponse,
  setWantToCallGetJobPlan,
  wantToCallGetJobPlan,
  state,
  setState,
  buttonCameFrom,
  defaultObjForAutocomplete,
  setDefaultObjForAutocomplete,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  allCostCentres,
  costCentreSingleObj,
  buttonPressFrom,
}) {
  const ErrorClasses = errorStyles();
  const classes = useStyles();
  const [jobPlanName, setJobPlanName] = useState("");
  const [jobPlanDescription, setJobPlanDescription] = useState("");
  const handleDrawerClose = () => {
    setState({ ...state, ["right"]: false });
  };
  const [costCentres, setCostCentres] = useState([]);
  const [jobPlansArr, setJobPlansArr] = useState([]);
  const [load, setLoad] = useState(false);
  const [costCentreIdFromJobPlan, setCostCentreIdForJobPlan] = useState({
    costCentreId: null,
    costCentreNo: "",
    costCentreName: "",
    costCentreDescription: "",
    editable: "",
  });
  const [dummyArrayForJobPlan, setdummyArrayForJobPlan] = useState({
    costCentreId: "",
    costCentreNo: "",
    costCentreDescription: "",
    jobPlanId: "",
    jobPlanNo: "",
    jobPlanName: "",
    jobPlanDescription: "",
  });
  const [dummyArrayForCost, setdummyArrayForCost] = useState({
    costCentreId: null,
    costCentreNo: "",
    costCentreName: "",
    costCentreDescription: "",
    editable: "",
  });
  const [roundLoader, setRoundLoader] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (buttonCameFrom === "jobPlan") {
      getCostCentres(setCostCentres, setCostCentres, setLoad); //used setCostCentres with two parameter for duplicate purpose
    }

    console.log("CostCentres:", costCentres);
    getJobplans(setJobPlansArr, setLoad);
  }, []);
  const submitHandleSave = (data) => {
    var obj;
    if (buttonCameFrom === "costCentreNav" || buttonCameFrom === "costCenterHome") {
      obj = {
        costCentreId: costCentreSingleObj?.costCentreId,
        jobPlanName: data?.jobPlanName,
        jobPlanDescription: data?.jobPlanDescription,
        copyFromJobPlanId: defaultObjForAutocomplete?.jobPlanId,
      };
    } else {
      obj = {
        costCentreId: costCentreIdFromJobPlan?.costCentreId,
        jobPlanName: data?.jobPlanName,
        jobPlanDescription: data?.jobPlanDescription,
        copyFromJobPlanId: defaultObjForAutocomplete?.jobPlanId,
      };
    }
    console.log("obj saved ", obj);

    createJobPlan(
      obj,
      setLoadResponse,
      wantToCallGetJobPlan,
      setWantToCallGetJobPlan,
      setSnackbarSeverity,
      setSnackbarMSG,
      setOpenSnackbar,
      setRoundLoader,
      setState
    );
    setJobPlanName("");
    setJobPlanDescription("");
    setCostCentreIdForJobPlan();
    setDefaultObjForAutocomplete();
    // } else {
    //   setSnackbarMSG(`${errMsg} can not be empty`);
    //   setSnackbarSeverity("error");
    //   setOpenSnackbar(true);
    // }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <Drawer
      anchor={"right"}
      open={state["right"]}
      onClose={toggleDrawer("left", false)}
      PaperProps={{
        sx: {
          width: "30%",
        },
      }}
    >
      {roundLoader && <SimpleBackdrop />}
      <div className={clsx(classes.createEstimateDrawer)} role="presentation" class="full">
        <form onSubmit={handleSubmit(submitHandleSave)}>
          <Grid container spacing={3} p={1}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                marginTop: 10,
              }}
            >
              <Typography
                component="h4"
                color="primary"
                mb={1}
                style={{
                  fontSize: "20px",
                }}
              >
                Create New Job Plan
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="jobPlanName"
                control={control}
                rules={{ required: true }}
                className="input"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="jobPlanName"
                    size="small"
                    variant="outlined"
                    className={classes.textField}
                    label="Job Plan Name"
                    fullWidth
                  />
                )}
              />
              <p className={ErrorClasses.errorMsg}>
                {errors.jobPlanName?.type === "required" && "jobPlan Name  is required"}
              </p>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="jobPlanDescription"
                control={control}
                rules={{ required: true }}
                className="input"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="jobPlanDescription"
                    variant="outlined"
                    size="small"
                    // value={jobPlanDescription}
                    // onChange={(e) => {
                    //   setJobPlanDescription(e.target.value);
                    // }}
                    label="Job Plan Description"
                    fullWidth
                  />
                )}
              />
              <p className={ErrorClasses.errorMsg}>
                {errors.jobPlanDescription?.type === "required" && "jobPlan Description  is required"}
              </p>
            </Grid>

            <Grid item xs={12}>
              {buttonCameFrom === "jobPlan" && (
                <Autocomplete
                  options={costCentres}
                  value={costCentreIdFromJobPlan}
                  onChange={(event, newValue) => {
                    setCostCentreIdForJobPlan(newValue);
                    setDefaultObjForAutocomplete(dummyArrayForJobPlan);
                  }}
                  getOptionLabel={(option) => option.costCentreName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Cost Centre" size="small" />
                  )}
                />
              )}
              {buttonCameFrom === "costCentreNav" && (
                <TextField
                  value={costCentreSingleObj.costCentreName}
                  variant="outlined"
                  label="Cost Centre"
                  disabled
                  size="small"
                />
              )}
              {buttonCameFrom === "costCenterHome" && (
                <TextField
                  value={costCentreSingleObj.costCentreName}
                  variant="outlined"
                  label="Cost Center"
                  size="small"
                  disabled
                  fullWidth
                />
              )}
            </Grid>
            {buttonPressFrom !== "newPlanFromJobplan" && (
              <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                  options={jobPlansArr}
                  value={defaultObjForAutocomplete}
                  onChange={(event, newValue) => {
                    setDefaultObjForAutocomplete(newValue);
                    setCostCentreIdForJobPlan(dummyArrayForCost);
                  }}
                  getOptionLabel={(option) => option.jobPlanName}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="job Plans" size="small" />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} align="right">
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="contained"
                style={{ marginRight: "10px" }}
              >
                Save
              </Button>
              <Button
                onClick={handleDrawerClose}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Drawer>
  );
}
