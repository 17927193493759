/** @format */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deletePurchaseOrderByOrderId, deleteWorkOrderByOrderId } from "./Order.helper";

export default function DeleteOrderAlertPop({
  confirmDelete,
  setConfirmDelete,
  orderId,
  wantToCallGet,
  setWantToCallGet,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  callFrom,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };
  const handleYes = () => {
    if (callFrom === "purchaseOrder") {
      deletePurchaseOrderByOrderId(
        orderId,
        wantToCallGet,
        setWantToCallGet,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity
      );
    } else {
      deleteWorkOrderByOrderId(
        orderId,
        wantToCallGet,
        setWantToCallGet,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity
      );
    }
    setConfirmDelete(false);
  };
  const handleNo = () => {
    setConfirmDelete(false);
  };

  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
