/** @format */

import { Button, ButtonGroup, Divider, Drawer, Grid, TextField, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getContacts } from "../contacts/contacts.helpers";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import { createCatalogue, getCatalogue } from "./catalogue.helper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useStyles from "../UI/useStyles";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import clsx from "clsx";
import AlertPopCatalogue from "./AlertPopCatalogue";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { Tooltip, Zoom } from "@mui/material";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import { checkProperties } from "../UI/GlobalApi";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { CATALOGUE_READ, CATALOGUE_DELETE, CLIENT_WRITE, CATALOGUE_WRITE } from "../UI/GlobalVariables";
import CreateContactDrawer from "../contacts/CreateContactDrawer";

export default function Catalogue({ callFrom, catalogueId }) {
  const classes = useStyles();
  const history = useHistory();
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [catalogueData, setCatalogueData] = useState([]);
  const [load, setLoad] = useState(true);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [responsive, setResponsive] = useState("standard");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [print, setPrint] = useState(true);
  const [state, setState] = React.useState({
    right: false,
  });
  const [catalogueName, setCatalogueName] = React.useState("");
  const [catalogueDescription, setCatalogueDescription] = React.useState("");
  const [deleteContactId, setDeleteContactId] = React.useState("");
  const [contacts, setContacts] = React.useState([]);
  const [contactObj, setContactObj] = React.useState();
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [roundLoader, setRoundLoader] = useState(false);
  const [selectableRows, setSelectableRows] = useState("none");
  const [contactData, setContactData] = useState({ editable: true });
  const [contactDrawerState, setContactDrawerState] = React.useState({
    right: false,
  });
  // ---------------------------------COLUMS-----------------------------------
  const columns = [
    {
      name: "catalogueNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
        /* setCellProps: () => ({
          style: { minWidth: "800px", maxWidth: "800px" },
        }), */
      },
    },
    {
      name: "catalogueName",
      label: "Name",
      options: {
        setCellProps: () => ({ style: { minWidth: "250px", maxWidth: "250px" } }),
        filter: true,
        sort: true,
        display: true,
      },
    },

    {
      name: "contact",
      label: "Supplier",
      options: {
        setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
        filter: false,
        sort: false,
        empty: true,
        // setCellProps: () => ({
        //   style: { minWidth: "20px" },
        //   align: "center",
        // }),
        // setCellHeaderProps: () => ({
        //   align: "center",
        // }),

        customBodyRender: (contact, tableMeta) => {
          if (contact) {
            return <div>{contact.contactName}</div>;
          }
        },
      },
    },

    {
      name: "catalogueDescription",
      label: "Description",
      options: {
        setCellProps: () => ({
          style: { minWidth: "600px", maxWidth: "600px" },
        }),
        filter: true,
        sort: true,
      },
    },

    {
      name: "catalogueId",

      label: "Actions",

      options: {
        // setCellProps: () => ({ style: { float:"right"} }),
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (catalogueId, tableMeta) => {
          return catalogueData[tableMeta.rowIndex].editable ? (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT CATALOGUE " placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  target="_self"
                  onClick={() =>
                    history.push(
                      `/catalogue/${
                        catalogueData[tableMeta.rowIndex].contact
                          ? catalogueData[tableMeta.rowIndex].contact.contactId
                          : "not"
                      }/${catalogueId}/items`
                    )
                  }
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>

              <Tooltip title="DELETE CATALOGUE" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={catalogueId}
                  onClick={(e) =>
                    handleDelete(catalogueId, catalogueData[tableMeta.rowIndex].contact?.contactId)
                  }
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained">
              <Tooltip title="VIEW CATALOGUE " placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  target="_self"
                  onClick={() =>
                    history.push(
                      `/catalogue/${
                        catalogueData[tableMeta.rowIndex].contact
                          ? catalogueData[tableMeta.rowIndex].contact.contactId
                          : "not"
                      }/${catalogueId}/items/`
                    )
                  }
                >
                  <VisibilityIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: callFrom !== "estimateLineItem" && hasValidAccess(CATALOGUE_WRITE) ? true : false,
      },
    },
  ];

  // -----------------------------OPTIONS---------------------------------------
  const options = {
    viewColumns: true,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    search: callFrom !== "estimateLineItem" ? true : false,
    download: callFrom !== "estimateLineItem" ? true : false,
    print: callFrom !== "estimateLineItem" ? true : false,
    viewColumns: callFrom !== "estimateLineItem" ? true : false,
    filter: callFrom !== "estimateLineItem" ? true : false,
    selectableRowsHideCheckboxes: callFrom === "estimateLineItem" ? true : false,

    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    // },
    onTableChange: (action, state) => {},
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {

    // },
  };
  // ---------------------------Functions-For-Drawer-----------------------------------------
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleOpenCreateNewCatalogueDrawer = (e) => {
    setState({ ...state, ["right"]: true });
  };

  const handleSave = () => {
    let valid = true;
    let errMsg = "";

    let obj = {
      contactId: contactObj.contactId,
      catalogueName: catalogueName,
      catalogueDescription: catalogueDescription,
    };

    errMsg = checkProperties(obj);
    if (errMsg !== "") valid = false;
    if (valid == true) {
      createCatalogue(
        obj,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setSnackbarSeverity,
        setSnackbarMSG,
        setOpenSnackbar,
        setRoundLoader,
        setState
      );
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleDrawerClose = () => {
    setState({ ...state, ["right"]: false });
  };

  const handleDelete = (catalogueId, contactId) => {
    setDeleteId(catalogueId);
    setDeleteContactId(contactId);
    setConfirmDelete(true);
  };
  // ----------------------------------------------------------------------------------

  useEffect(() => {
    getContacts(setContacts, setDummyLoad);
  }, []);

  useEffect(() => {
    if (hasValidAccess(CATALOGUE_DELETE)) {
      setSelectableRows("single");
    }
    getCatalogue(setCatalogueData, setLoad, catalogueId);
  }, [wantToCallGetJobPlan]);
  // ----------------------------------------------------------------------------------

  return (
    <div>
      {roundLoader && <SimpleBackdrop />}
      {contactDrawerState.right && (
        <CreateContactDrawer
          contactData={contactData}
          setContactData={setContactData}
          contacts={contacts}
          setContacts={setContacts}
          recipientsEmail={contactObj}
          setRecipientsEmail={setContactObj}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          setRoundLoader={setRoundLoader}
          contactDrawerState={contactDrawerState}
          setContactDrawerState={setContactDrawerState}
          callFrom={"catalogue"}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent={"flex-end"} style={{ marginBottom: "15px" }}>
            {!catalogueId && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={(e) => handleOpenCreateNewCatalogueDrawer(e)}
                style={{ marginTop: "20px", marginRight: "10px" }}
              >
                New Catalogue
              </Button>
            )}
          </Grid>
          {load ? (
            <ProgressBar />
          ) : (
            <React.Fragment>
              {catalogueData.length === 0 ? ( //if estimates are empty then empty msg is shown
                <EmptyEstimatesDialog
                  heading="Empty Catalogue"
                  msg="You don't have any Catalogue here yet.
                              Please create Catalogue by clicking New Catalogue button"
                />
              ) : (
                <Grid item xs={12}>
                  <MUIDataTable data={catalogueData} columns={columns} options={options} />
                </Grid>
              )}

              <AlertPopCatalogue
                confirmDelete={confirmDelete}
                setConfirmDelete={setConfirmDelete}
                deleteId={deleteId}
                deleteContactId={deleteContactId}
                setOpenSnackbar={setOpenSnackbar}
                setCatalogueData={setCatalogueData}
                catalogueData={catalogueData}
                setSnackbarMSG={setSnackbarMSG}
                setSnackbarSeverity={setSnackbarSeverity}
                setRoundLoader={setRoundLoader}
              />

              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
            </React.Fragment>
          )}
        </Grid>
        {/* Drawer To Create new Catalogue */}
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              width: "30%",
            },
          }}
        >
          <div
            className={clsx(classes.createEstimateDrawer)}
            role="presentation"
            style={{ width: "100%" }}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginTop: 10,
                }}
                align="center"
              >
                <Typography
                  align="left"
                  mb={2}
                  variant="h4"
                  color="primary"
                  style={{ fontSize: "20px" }}
                >
                  Create New Catalogue
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="catalogueName"
                  name="catalogueName"
                  variant="outlined"
                  size="small"
                  value={catalogueName}
                  onChange={(e) => {
                    setCatalogueName(e.target.value);
                  }}
                  label="Catalogue Name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="catalogueDescription"
                  name="catalogueDescription"
                  variant="outlined"
                  size="small"
                  value={catalogueDescription}
                  onChange={(e) => {
                    setCatalogueDescription(e.target.value);
                  }}
                  label="Catalogue Description"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                  options={contacts}
                  value={contactObj}
                  onChange={(event, newValue) => {
                    setContactObj(newValue);
                  }}
                  getOptionLabel={(option) => option.emailAddress}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Supplier" size="small" />
                  )}
                />
              </Grid>
              <Grid item xs={12} align="right">
                <Button
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    setContactDrawerState({ right: true });
                    setContactData({ editable: true });
                  }}
                >
                  + New Supplier
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} align="right">
                <Button
                  type="submit"
                  size="small"
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button
                  onClick={handleDrawerClose}
                  variant="contained"
                  color="secondary"
                  size="small"
                  // startIcon={<ClearIcon />}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
        {!catalogueId && (
          <Grid item xs={12}>
            <CommonInfo title={infoArray.Catalogues.title} msg={infoArray.Catalogues.msg} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
