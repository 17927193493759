/** @format */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteClientbyID } from "./clients.helpers";

export default function AlertPopClient({
  confirmDelete,
  setConfirmDelete,
  deleteId,
  setData,
  data,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };
  const handleYes = () => {
    console.log("del id ", deleteId);
    deleteClientbyID(
      deleteId,
      setData,
      data,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader
    );
    setConfirmDelete(false);
  };
  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
