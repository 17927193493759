/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import CreateCosting from "../estimates/EstimateCosting/CreateCosting";
import ProgressBar from "../UI/ProgressBar";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import Grid from "@mui/material/Grid";
import SaveCostingButton from "../estimates/EstimateCosting/SaveCostingButton";
import {
  getItemsByCostCentreId,
  isCostCenterEditable,
  getSingleCostCentre,
  updateItemsByCostCentreId,
} from "./costcenter.helpers";
import Row from "./Row";
import CreateNewJobPlanDialog from "./job-plan/CreateNewJobPlanDialog";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { CSVBoxButton } from "@csvbox/react";
import { Button, ButtonGroup } from "@mui/material";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
const useStyles = makeStyles({
  fullList: {
    width: "auto",
    padding: 10,
  },
  button: {
    whiteSpace: "nowrap",
  },
});
export default function ItemsMainRow({ estimateData }) {
  const classes = useStyles();
  var { costCentreId } = useParams();
  var title = "Cost Centre Items";
  const [openCreate, setOpenCreate] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [items, setItems] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowNow, setRowNow] = React.useState([]);
  const [tempRows, setTempRows] = React.useState([]);
  const [id, setId] = useState(rows.length);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAllRows, setOpenAllRows] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [isEdit, setEdit] = React.useState(false);
  const [disableSave, setDisableSave] = React.useState(true);
  const [isDataPostSuccess, setDataPostSuccess] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const [loadResponse, setLoadResponse] = useState(false);
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  const [defaultObjForAutocomplete, setDefaultObjForAutocomplete] = useState([]);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [costCentreSingleObj, setCostCentreSingleObj] = React.useState([]);
  const [allCostCentres, setAllCostCentres] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const [tempGroups, setTempGroups] = useState([]);
  const [expandRows, setExpandRows] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [editableCostCenter, setEditableCostCenter] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);

  useEffect(() => {
    getItemsByCostCentreId(
      setRows,
      costCentreId,
      setLoadSave,
      setTempRows,
      setTempGroups,
      setOpenAllRows
    );
    getSingleCostCentre(setAllCostCentres, setCostCentreSingleObj, costCentreId);
    isCostCenterEditable(costCentreId, setEditableCostCenter);
  }, []);
  useEffect(() => {
    if (isDataPostSuccess) {
      getItemsByCostCentreId(
        setRows,
        costCentreId,
        setLoadSave,
        setTempRows,
        setTempGroups,
        setDummyLoad
      );
      setDataPostSuccess(false);
    }
  }, [isDataPostSuccess]);

  const handleSave = () => {
    //Code to generate group code automatically by sequence
    // if (rows.length === 0) {
    //   var lastBeforeRowSeqId = 0;
    // } else {
    //   var lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
    // }
    // setId(id + 1);
    // ----------------------------------------------------------------

    let arrOfObj = {
      groupCode: groupCode,
      groupName: groupName,
      items: items,
    };
    let arr = [...rows, arrOfObj];

    console.log("createCostiCentre", rows, "arr", arr);
    updateItemsByCostCentreId(
      setRows,
      costCentreId,
      arr,
      // setLoadSave,
      setDataPostSuccess,
      setOpenSnackbar,
      setSnackbarSeverity,
      setSnackbarMSG,
      setTempGroups,
      setRoundLoader,
    );
    setDisable(true);
    setGroupName("");
    setOpenCreate(!openCreate);
  };

  const handleSaveOuter = () => {
    var filterRows = rows.filter((item) => {
      if (item.groupCode === rowNow.groupCode) {
        let tempStoreData = tempRows.filter((el) => {
          if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
            return el;
          }
        });
        item.items = tempStoreData;
      }
    });
    updateItemsByCostCentreId(
      setRows,
      costCentreId,
      rows,
      // setLoadSave,
      setOpen,
      setDataPostSuccess,
      setTempGroups,
      setRoundLoader
    );
    setEdit(false);
    setDisableSave(true);
    console.log("HANDLE SAVE OUTER");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  var tempOpenRows = [];
  const handleOpenAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = true;
    }
    setOpenAllRows(tempOpenRows);
  };
  const handleCloseAllRows = () => {
    for (var i = 0; i < rows.length; i++) {
      tempOpenRows[i] = false;
    }
    setOpenAllRows(tempOpenRows);
  };

  // --------------------------UseEffect-For-Import-Data----------------------------------------------
  useEffect(() => {
    console.log("ImportedDataUeffe:", importedData);

    if (importedData.length > 0) {
      let result;
      console.log("Importing Data");

      // Below code is to generate itemId automatically
      // var lastItemId;
      // var lastBeforeRowSeqId;

      // if (rows.length === 0) {
      //   lastBeforeRowSeqId = 0;
      // } else {
      //   lastBeforeRowSeqId = rows[rows.length - 1].groupCode;
      // }

      let newLineItemsWithGrp = importedData
        .filter(function (item) {
          var index = rows.findIndex((g) => g.groupName == item["group_name"]);
          if (index >= 0) {
            //return item if it is matching group
            return item;
          } else {
            //If group is not matched then create new group with empty items
            rows.push({
              groupCode: item["group_code"],
              groupName: item["group_name"],
              items: [],
            });
            return item;
          }
        })
        .map(function (item) {
          //This will add imported item in matching group
          console.log("In Map");
          var index = rows.findIndex((g) => g.groupName == item["group_name"]);

          result = rows[index].items.findIndex((rowItem) => {
            return rowItem.itemCode === item["code"];
          });

          if (result !== -1) {
            rows[index].items[result] = {
              itemCode: item["code"],
              itemId: null,
              itemName: item["name"],
              itemGroupCode: rows[index].groupCode,
              itemGroupName: rows[index].groupName,
              itemDescription: item["description"],
              itemCategory: rows[index].groupName,
            };
          } else {
            rows[index].items.push({
              itemCode: item["code"],
              itemId: null,
              itemName: item["name"],
              itemGroupCode: rows[index].groupCode,
              itemGroupName: rows[index].groupName,
              itemDescription: item["description"],
              itemCategory: rows[index].groupName,
            });
          }
        });
      newLineItemsWithGrp = [];
      setIsImported(true);
    }
  }, [importedData]);
  // ------------------------------------------------------------------------
  const handleImportedDataSave = () => {
    updateItemsByCostCentreId(
      setRows,
      costCentreId,
      rows,
      //setLoadSave,
      setDataPostSuccess,
      setOpenSnackbar,
      setSnackbarSeverity,
      setSnackbarMSG,
      setTempGroups,
      setRoundLoader
    );
    setIsImported(false);
  };
  // -----------------------------------------------------------s-------------
  const handleImportedDataDiscard = () => {
    setRows(JSON.parse(JSON.stringify(tempGroups)));
    setIsImported(false);
    setImportedData([]);
  };
  // --------------------------CSV-Box-Code-new----------------------------------------------
  const csvCallBack = (result, data) => {
    if (result) {
      console.log("Sheet uploaded successfully");
      console.log(data.row_success + " rows uploaded");
    } else {
      console.log("There was some problem uploading the sheet");
    }
  };
  // let importer = new CSVBoxImporter("3f9KuXa5GHJOLIDnkuRgPmczmQXObU", {}, csvCallBack);
  // importer.setUser({ user_id: "default123" });
  // ------------------------------------------------------------------------
  // console.log("Temp Groups:", tempGroups);
  // console.log(" rows:", rows);

  return (
    <React.Fragment>
      {load ? ( //if rows are empty then progress bar is shown
        <ProgressBar />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box my={1}>
              <CreateCosting
                category={groupName}
                setCategory={setGroupName}
                groupCode={groupCode}
                setGroupCode={setGroupCode}
                rows={rows}
                setRows={setRows}
                id={id}
                setId={setId}
                lineItems={items}
                jobTypeID={costCentreId}
                handleSave={handleSave}
                load={loadSave}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                disable={disable}
                setDisable={setDisable}
                title={title}
                textFieldMsg={"Add Group"}
                handleOpenAllRows={handleOpenAllRows}
                handleCloseAllRows={handleCloseAllRows}
                setState={setState}
                state={state}
                setDefaultObjForAutocomplete={setDefaultObjForAutocomplete}
                defaultObjForAutocomplete={defaultObjForAutocomplete}
                buttonFrom={"cost-centre-nav"}
                editable={editableCostCenter}
              />
              {/* {loadSave && <SimpleBackdrop />} */}
            </Box>
          </Grid>
          <Grid container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <ButtonGroup>
                <CSVBoxButton
                  licenseKey="3f9KuXa5GHJOLIDnkuRgPmczmQXObU"
                  user={{
                    user_id: "default123",
                  }}
                  onImport={(result, data) => {
                    if (result) {
                      // setImportedData(data.rows);
                      setImportedData(JSON.parse(JSON.stringify(data.rows)));
                      setSnackbarMSG("Data Imported Succesfully");
                      setSnackbarSeverity("success");
                      setOpenSnackbar(true);
                      console.log("In ImportButton result:", result);
                    } else {
                      console.log("fail");
                    }
                  }}
                  render={(launch) => {
                    return (
                      <Button
                        data-csvbox
                        onClick={launch}
                        variant="contained"
                        size="small"
                        color="primary"
                        align="left"
                        className={classes.button}
                        disabled={isImported || !editableCostCenter}
                      >
                        Import
                      </Button>
                    );
                  }}
                >
                  Import
                </CSVBoxButton>
                {isImported && (
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleImportedDataSave}
                      className={classes.button}
                      disabled={!editableCostCenter}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleImportedDataDiscard}
                      className={classes.button}
                      disabled={!editableCostCenter}
                    >
                      Discard
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadSave ? (
              <ProgressBar />
            ) : (
              <Paper>
                <Box m={1} py={2}>
                  {roundLoader && <SimpleBackdrop />}
                  <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                      <TableBody>
                        <>
                          {rows.length === 0 ? (
                            <EmptyEstimatesDialog
                              heading="Empty items "
                              msg="You don't have any line items here yet.
  Click the 'ADD' button above to add your job type entries"
                            />
                          ) : (
                            rows.map((row, index) => (
                              <Row
                                key={row.groupCode}
                                row={row}
                                setItems={setItems}
                                setRows={setRows}
                                rows={rows}
                                costCentreId={costCentreId}
                                index={index}
                                loadSave={loadSave}
                                setLoadSave={setLoadSave}
                                isEdit={isEdit}
                                setEdit={setEdit}
                                disableSave={disableSave}
                                setDisableSave={setDisableSave}
                                setDataPostSuccess={setDataPostSuccess}
                                openAllRows={openAllRows}
                                setOpenAllRows={setOpenAllRows}
                                setOpenSnackbar={setOpenSnackbar}
                                setSnackbarMSG={setSnackbarMSG}
                                setSnackbarSeverity={setSnackbarSeverity}
                                setTempGroups={setTempGroups}
                                editableCostCenter={editableCostCenter}
                                setRoundLoader={setRoundLoader}
                              />
                            ))
                          )}
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                // className={classes.snackbar}
                >
                  <Alert onClose={handleClose} severity="success" variant="filled">
                    Item created successfully!
                  </Alert>
                </Snackbar>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} style={{ position: "sticky", bottom: "50px" }}>
            <Box m={1} py={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {disableSave ? <></> : <SaveCostingButton handleSaveOuter={handleSaveOuter} />}
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
      {state.right === true && (
        <CreateNewJobPlanDialog
          costCentreId={costCentreId}
          setLoadResponse={setLoadResponse}
          setWantToCallGetJobPlan={setWantToCallGetJobPlan}
          wantToCallGetJobPlan={wantToCallGetJobPlan}
          state={state}
          setState={setState}
          buttonCameFrom={"costCentreNav"}
          defaultObjForAutocomplete={defaultObjForAutocomplete}
          setDefaultObjForAutocomplete={setDefaultObjForAutocomplete}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          setOpenSnackbar={setOpenSnackbar}
          costCentreSingleObj={costCentreSingleObj}
          allCostCentres={allCostCentres}
        />
      )}
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
