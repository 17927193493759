/** @format */

import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip, Zoom } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import SpecificationsGroupItemsTable from "./SpecificationsGroupItemsTable";
import {
  updateSpecificationsByEstimateId,
  updateSpecificationsByJobPlanId,
  updateSpecificationsGroupByJobPlanId,
  deleteSpecificationsGroupByEstimateId,
} from "./specifications.helper";

export default function SpecificationsGroupRow({
  row,
  setItems,
  setRows,
  rows,
  jobPlanId,
  loadSave,
  setLoadSave,
  isEdit,
  setEdit,
  setDisableSave,
  tempRows,
  setTempRows,
  setDataPostSuccess,
  setOpenedItemRow,
  openAllRows,
  setOpenAllRows,
  index,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type,
  estimateId,
  editableJobplan,
  setRoundLoader,
  estimateStatus,
}) {
  const useRowStyles = makeStyles({
    tableInnerContent: {
      paddingTop: "10px",
    },
  });
  const classes = useRowStyles();
  const [jobPlanObject, setJobPlanObject] = useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(false);
  const [tempRowsData, setTempRowsData] = useState([]);
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  const [isImported, setIsImported] = useState(false);

  const handleOpenCostinRow = () => {
    if (openAllRows[index]) {
      setOpenAllRows({ ...openAllRows, [index]: false });
    } else {
      setJobPlanObject(row.items);
      setOpenAllRows({ ...openAllRows, [index]: true });
    }
  };

  const handleConfirm = () => {
    setShowConfirm(true);
  };

  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };

  const handleDelete = () => {
    console.log("handleDelete");
    var filterRows = rows.filter(function (el) {
      if (el.groupCode === row.groupCode) {
        var newItems = el.items.filter(function (itm) {
          return (itm.itemId = null);
        });
        el.items.length = 0;
        setItems(newItems);
      }
      return el.groupCode != row.groupCode;
    });

    let count = 1;
    var finalJobPlanObj = filterRows.filter(function (el) {
      el.groupCode = count++;
      return el;
    });
    if (type == "jobPlan") {
      updateSpecificationsGroupByJobPlanId(
        setRows,
        jobPlanId,
        finalJobPlanObj,
        setOpenSnackbar,
        setTempRows,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader
      );
    } else {
      deleteSpecificationsGroupByEstimateId(
        setRows,
        estimateId,
        finalJobPlanObj,
        setOpenSnackbar,
        setTempRows,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader
      );
      setSnackbarMSG("Groups Deleted Successfully");
    }
    setOpenSnackbar(true);
    setSnackbarSeverity("success");
    setConfirmDelete(false);
    setShowConfirm(false);
  };

  useEffect(() => {
    row.items.sort(function (a, b) {
      return a.itemCode - b.itemCode;
    });
  }, [row]);

  useEffect(() => {
    if (openAllRows[index]) {
      setJobPlanObject(row.items);
    }
  }, [openAllRows]);

  console.log("Row:", row);
  console.log("Row:", rows);

  /* ---------------------------------------------------------------------- */
  const handleInputChange = (e, index) => {
    const list = JSON.parse(JSON.stringify(rows));
    list[index][e.target.name] = e.target.value;
    setRows(list);
  };

  let rowEditable = true;
  row.items.filter((obj, index) => {
    if (obj.editable === false) {
      rowEditable = false;
    }
  });

  const handleGroupSave = () => {
    setIsImported(false);
    setEditGroup(false);
    setSnackbarMSG("Group Updated Successfully");
    setSnackbarSeverity("success");
    if (type == "jobPlan") {
      updateSpecificationsByJobPlanId(
        jobPlanId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setTempRows,
        setRoundLoader
      );
    } else {
      updateSpecificationsByEstimateId(
        estimateId,
        rows,
        wantToCallGetJobPlan,
        setWantToCallGetJobPlan,
        setOpenSnackbar,
        setTempRows,
        setRoundLoader
      );
    }
  };
  /* ---------------------------------------------------------------------- */

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        sx={{
          display: "block",
          cursor: "pointer",
        }}
        onClick={handleOpenCostinRow}
        hover
      >
        <TableCell width="2%" align="left" component="th">
          <IconButton aria-label="expand row" size="small" onClick={handleOpenCostinRow}>
            {openAllRows[index] ? (
              <Tooltip title="CLOSED MORE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowUpIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="VIEW MORE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowDownIcon fontSize="small" />
              </Tooltip>
            )}
          </IconButton>
        </TableCell>
        {editGroup ? (
          <>
            <TableCell width="5.3%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupCode}
                name="groupCode"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
            <TableCell width="65%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupName}
                name="groupName"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell width="5.3%" align="center" component="th" scope="row">
              {row.groupCode}
            </TableCell>
            <TableCell width="65%" align="left" component="th" scope="row">
              {row.groupName}
            </TableCell>
          </>
        )}

        <TableCell width="10%" align="center">
          {row.items.length} items
        </TableCell>

        <TableCell width="8.7%" align="center">
          {rowEditable ? (
            !editGroup ? (
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Tooltip title="DELETE CATEGORY" placement="top" TransitionComponent={Zoom} arrow>
                  {type === "jobPlan" ? (
                    editableJobplan ? (
                      <Button
                        size="small"
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={handleConfirm}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={handleConfirm}
                        disabled
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    )
                  ) : (
                    <Button
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={handleConfirm}
                      disabled={estimateStatus !== "DRAFT" ? true : false}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  )}
                </Tooltip>
                <Tooltip title="Edit Groups" placement="top" TransitionComponent={Zoom} arrow>
                  {type === "jobPlan" ? (
                    editableJobplan ? (
                      <Button
                        size="small"
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTempRowsData(rows);
                          setEditGroup(true);
                        }}
                      >
                        <CreateIcon fontSize="small" />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTempRowsData(rows);
                          setEditGroup(true);
                        }}
                        disabled
                      >
                        <CreateIcon fontSize="small" />
                      </Button>
                    )
                  ) : (
                    <Button
                      size="small"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTempRowsData(rows);
                        setEditGroup(true);
                      }}
                      disabled={estimateStatus !== "DRAFT" ? true : false}
                    >
                      <CreateIcon fontSize="small" />
                    </Button>
                  )}
                </Tooltip>
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Tooltip title="CANCEL" placement="top" TransitionComponent={Zoom} arrow>
                  {type === "jobPlan" ? (
                    editableJobplan ? (
                      <Button
                        size="small"
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setRows(tempRowsData);
                          setEditGroup(false);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setRows(tempRowsData);
                          setEditGroup(false);
                        }}
                        disabled
                      >
                        <ClearIcon fontSize="small" />
                      </Button>
                    )
                  ) : (
                    <Button
                      size="small"
                      color="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRows(tempRowsData);
                        setEditGroup(false);
                      }}
                      disabled={estimateStatus !== "DRAFT" ? true : false}
                    >
                      <ClearIcon fontSize="small" />
                    </Button>
                  )}
                </Tooltip>
                <Tooltip title="SAVE" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGroupSave()}
                    size="small"
                  >
                    <SaveIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            )
          ) : (
            <IconButton disabled>
              <DeleteIcon style={{ cursor: "pointer" }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow p={1}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAllRows[index]} timeout="auto" unmountOnExit>
            <SpecificationsGroupItemsTable
              rows={rows}
              groupName={row.groupName}
              groupCode={row.groupCode}
              jobPlanId={jobPlanId}
              estimateId={estimateId}
              loadSave={loadSave}
              isEdit={isEdit}
              setEdit={setEdit}
              setDisableSave={setDisableSave}
              jobPlanObject={jobPlanObject}
              setJobPlanObject={setJobPlanObject}
              tempRows={tempRows}
              setTempRows={setTempRows}
              setRows={setRows}
              setOpenedItemRow={setOpenedItemRow}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              type={type}
              includesPrice={row.includesPrice}
              editableJobplan={editableJobplan}
              setRoundLoader={setRoundLoader}
              estimateStatus={estimateStatus}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
