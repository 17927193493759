/** @format */

import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useRef } from "react";

function WebViewerForFile({ fileUrl, setOpenWebViewer }) {
  const viewer = useRef(null);

  useEffect(() => {
    console.log("FILEURL:", fileUrl);
    fileUrl &&
      WebViewer(
        {
          path: "/webviewer/lib",
          initialDoc: fileUrl,
        },
        viewer.current
      ).then((instance) => {
        const { documentViewer, annotationManager, Annotations } =
          instance.Core;
        documentViewer.addEventListener("documentLoaded", () => {
          const rectangleAnnot = new Annotations.RectangleAnnotation({
            PageNumber: 1,
            X: 100,
            Y: 150,
            Width: 200,
            Height: 50,
            Author: annotationManager.getCurrentUser(),
          });
          annotationManager.addAnnotation(rectangleAnnot);
          // need to draw the annotation otherwise it won't show up until the page is refreshed
          annotationManager.redrawAnnotation(rectangleAnnot);
        });
      });
  }, [fileUrl]);

  return (
    <Grid container direction={"column"}>
      <Grid item textAlign={"end"}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpenWebViewer(false)}
          size="small"
        >
          Close File
        </Button>
      </Grid>
      <Grid item>
        <div
          className="webviewer"
          ref={viewer}
          style={{ height: "1084px" }}
        ></div>
      </Grid>
    </Grid>
  );
}

export default WebViewerForFile;
