/** @format */

import React, { useState, useEffect } from "react";
import TakeoffBody from "./TakeoffBody";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { getTakeoffsByEstimatesbyID } from "./takeoff.helpers";
import ProgressBar from "../../UI/ProgressBar";
import Title from "../../UI/Title";

function Takeoff() {
  const [rows, setRows] = useState([]);
  const [load, setLoad] = useState(false);
  var { estimateId } = useParams();

  useEffect(() => {
    getTakeoffsByEstimatesbyID(setRows, setLoad, estimateId);
  }, []);
  // console.log("in takeoffs- ",rows);
  return (
    <div>
      {/* <Typography variant="h6" component="h6">Take Offs</Typography> */}
      <Title title="Take Offs" />
      {load ? <ProgressBar /> : <TakeoffBody rows={rows} setRows={setRows} estimateID={estimateId} />}
    </div>
  );
}

export default Takeoff;
