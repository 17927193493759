/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import ProgressBar from "../../UI/ProgressBar";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import MomentDate from "../../UI/MomentDate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { Tooltip, Zoom } from "@mui/material";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { Drawer, Grid, Table, TableHead, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  deleteInvoiceReceiptByID,
  getInvoiceReceipts,
  getReceipteByReceipteId,
  updateInvoiceReceiptID,
} from "./OutgoingInvoice.helpers";
import { checkProperties } from "../../UI/GlobalApi";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));

export default function InvoicesByReceipt({
  outGoingInvoiceId,
  openCustSnackbar,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  snackbarMSG,
  snackbarSeverity,
  type,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(true);
  const [invoiceReceipts, setInvoiceReceipts] = useState([]);
  const [editDrawerLoading, setEditDrawerLoading] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [receiptToDelete, setReceiptToDelete] = useState("");
  const [roundLoader, setRoundLoader] = useState(false);
  const [editReceiptDrawer, setEditReceiptDrawer] = React.useState({
    right: false,
  });
  var today = new Date();
  var now = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const [date, setDate] = useState(now);
  console.log("outGoingInvoiceId", outGoingInvoiceId);
  // -------------------------------------------------------------------------------
  const handleClose = () => {
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  const handleDeleteReceipt = (receiptId) => {
    setReceiptToDelete(receiptId);
    setConfirmDelete(true);
  };
  // --------------------------------------------------------------------------------------
  const handleOpenEditRecieptDrawer = (outgoingInvoiceReceiptId) => {
    getReceipteByReceipteId(
      outGoingInvoiceId,
      outgoingInvoiceReceiptId,
      setReceiptData,
      jobId,
      setEditDrawerLoading
    );

    setEditReceiptDrawer({ right: true });
  };
  // --------------------------------------------------------------------------------------
  const handleYes = () => {
    deleteInvoiceReceiptByID(
      receiptToDelete,
      outGoingInvoiceId,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      jobId,
      setWantToCallGet,
      wantToCallGet,
      invoiceReceipts,
      setInvoiceReceipts
    );
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  const handleUpdateReceipDetails = () => {
    let valid = true;
    let errMsg = checkProperties(receiptData);
    if (errMsg !== "") valid = false;

    // -------------------------------------------------
    if (valid === false) {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenCustSnackbar(true);
    } else {
      updateInvoiceReceiptID(
        receiptData,
        setWantToCallGet,
        wantToCallGet,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setEditReceiptDrawer
      );
    }
  };
  // --------------------------------------------------------------------------------------
  const handleNo = () => {
    setConfirmDelete(false);
  };
  // --------------------------------------------------------------------------------------
  var { jobId } = useParams();
  useEffect(() => {
    if (wantToCallGetJobPlan) {
      getInvoiceReceipts(setInvoiceReceipts, jobId, outGoingInvoiceId, setLoading);
      setWantToCallGetJobPlan(false);
    }
  }, [wantToCallGetJobPlan]);
  // --------------------------------------------------------------------------------------
  const toggleReceivedDrawer = (anchor, open) => (event) => {
    console.log(toggleReceivedDrawer);
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setEditReceiptDrawer({ ...editReceiptDrawer, [anchor]: open });
  };
  // --------------------------------------------------------------------------------------
  console.log("receiptData", receiptData);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Box sx={{ margin: 1 }}>
            {loading == true ? (
              <>
                <br />
                <Typography variant="h6" gutterBottom component="div">
                  Receipt
                </Typography>
                <ProgressBar />
                <br />
              </>
            ) : (
              <>
                {invoiceReceipts.length === 0 ? (
                  <>
                    <br />
                    <EmptyEstimatesDialog
                      heading="Empty Receipt"
                      msg="You don't have any Receipt in this invoices  yet.
            Click on 'Invoice' icon above table to create new Receipt"
                    />
                    <br />
                  </>
                ) : (
                  <>
                    <Typography variant="h6" gutterBottom component="div">
                      Receipt
                    </Typography>
                    <Table
                      sx={{
                        borderBottom: "none",
                      }}
                      size="small"
                      aria-label="purchases"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: "10px", maxWidth: "10px" }}>
                            <strong>Ref No.</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                            <strong>Receipt Date</strong>
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: "130px", maxWidth: "130px" }}>
                            <strong>Notes</strong>
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                            <strong>Receipt Total</strong>
                          </TableCell>

                          <TableCell align="right" sx={{ minWidth: "10px", maxWidth: "10px" }}>
                            <strong> Actions</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceReceipts.map((item) => (
                          <>
                            <TableRow>
                              <TableCell sx={{ minWidth: "10px", maxWidth: "10px" }}>
                                {item.referenceNo}
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                                <MomentDate getDate={item.receiptDate} />
                              </TableCell>
                              <TableCell align="left" sx={{ minWidth: "130px", maxWidth: "130px" }}>
                                {item.notes}
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: "40px", maxWidth: "40px" }}>
                                <NumberFormat
                                  value={(item.receiptTotal * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                                {}
                              </TableCell>

                              <TableCell align="right" sx={{ minWidth: "20px", maxWidth: "20px" }}>
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                >
                                  <Tooltip
                                    title="Edit Invoice/Reciept"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOpenEditRecieptDrawer(item.receiptId)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    title="Delete Invoice/Reciept"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      size="small"
                                      color="secondary"
                                      value={item.jobPlanId}
                                      onClick={(e) => handleDeleteReceipt(item.receiptId)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                      {/* <TableBody>
                        <>
                          <TableRow>
                              <TableCell> </TableCell>
                              <TableCell width="30%">This is purchase order description 1</TableCell>
                              <TableCell> 16-Jun-2021</TableCell>
                              <TableCell align="right"> -$450.00</TableCell>
                              <TableCell>₹ 0.00</TableCell>
                              <TableCell>₹ 0.00</TableCell>
                              <TableCell>₹ 0.00</TableCell>
                              <TableCell>
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                  // key={quoteItem}
                                  //onClick={(e) => handleConfirm(index)}
                                >
                                  <Button size="small" className="mr10" color="secondary">
                                    <Delete fontSize="small" />
                                  </Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                        </>
                      </TableBody> */}
                    </Table>
                  </>
                )}
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {roundLoader && <SimpleBackdrop />}
      <Drawer
        anchor={"right"}
        open={editReceiptDrawer["right"]}
        onClose={toggleReceivedDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        <div className={classes.Drawerroot}>
          {editDrawerLoading ? (
            <>
              <br />
              <ProgressBar />
            </>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color="primary"
                  mb={1}
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Receipt Details
                </Typography>

                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label={"Reference Code"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={receiptData.referenceNo}
                  name="reference No"
                  onChange={(e) =>
                    setReceiptData({
                      ...receiptData,
                      referenceNo: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label={"Receipt Total"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={receiptData.receiptTotal}
                  name="receiptTotal"
                  onChange={(e) =>
                    setReceiptData({
                      ...receiptData,
                      receiptTotal: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ position: "absolute" }}>
                  <lable>Date Received</lable>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Stack sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id="start-date"
                      fullWidth
                      size="small"
                      inputVariant="outlined"
                      onChange={(newvalue) =>
                        setReceiptData({
                          ...receiptData,
                          receiptDate: newvalue.$d
                            .toLocaleString("en-us", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
                        })
                      }
                      value={receiptData.receiptDate}
                      inputFormat="DD/MM/YYYY"
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  label={"Notes"}
                  variant="outlined"
                  size="small"
                  className={classes.textField}
                  type="text"
                  value={receiptData.notes}
                  name="notes"
                  onChange={(e) =>
                    setReceiptData({
                      ...receiptData,
                      notes: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} align="right" my={-2}>
                <Button
                  type="button"
                  size="small"
                  color="primary"
                  onClick={handleUpdateReceipDetails}
                  variant="contained"
                >
                  Update
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  align="right"
                  onClick={() => setEditReceiptDrawer({ right: false })}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Drawer>
      {confirmDelete && (
        <Dialog
          open={confirmDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleNo} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
