/** @format */

import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCatalogueItemsByCatalogueId,
  isCatalogueEditable,
  updateCatalogueItemsByCatalogueId,
} from "../catalogue/catalogue.helper";
import GroupRow from "../catalogue/GroupRow";
import { getUserConfigsTypes, getUserConfigsUom } from "../UI/GlobalApi";
import ProgressBar from "../UI/ProgressBar";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import clsx from "clsx";
import { CSVBoxButton } from "@csvbox/react";
import { af } from "date-fns/locale";
import apis from "../../utils/apis";
import SimpleBackdrop from "../UI/SimpleBackdrop";
const useStyles = makeStyles({
  fullList: {
    width: "auto",
    padding: 10,
  },
  button: {
    whiteSpace: "nowrap",
  },
});

export default function CatalogueNav() {
  const classes = useStyles();
  var { contactId } = useParams();
  var { catalogueId } = useParams();
  const [groups, setGroups] = React.useState([]);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");
  const [items, setItems] = useState([]);
  const [loadSave, setLoadSave] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [openAllRows, setOpenAllRows] = useState(false);
  const [userConfigsTypes, setUserConfigsTypes] = useState({});
  const [userConfigsUom, setUserConfigsUom] = useState({});
  const [isDataPostSuccess, setDataPostSuccess] = React.useState(false);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [tempGroups, setTempGroups] = useState([]);
  const [isImported, setIsImported] = useState(false);
  const [expandRows, setExpandRows] = useState(false);
  const [editableCatalogue, setEditableCatalogue] = useState(false);

  // ------------------------------------------------------------------------
  var tempOpenRows = [];
  const handleOpenAllRows = () => {
    for (var i = 0; i < groups.length; i++) {
      tempOpenRows[i] = true;
    }
    setOpenAllRows(tempOpenRows);
  };
  const handleCloseAllRows = () => {
    for (var i = 0; i < groups.length; i++) {
      tempOpenRows[i] = false;
    }
    setOpenAllRows(tempOpenRows);
  };

  const handleGroupAdd = () => {
    setOpenCreate(!openCreate);
    setDisable(false);
  };
  const handleGroupClose = () => {
    setDisable(true);
    setOpenCreate(!openCreate);
  };

  const handleGroupSave = () => {
    if (groups.length === 0) {
      var lastBeforeRowSeqId = 0;
    } else {
      var lastBeforeRowSeqId = groups[groups.length - 1].groupCode;
    }
    let arrOfObj = {
      groupCode: groupCode,
      groupName: groupName,
      items: items,
    };
    let arr = [...groups, arrOfObj];

    updateCatalogueItemsByCatalogueId(
      catalogueId,
      contactId,
      setGroups,
      arr,
      setRoundLoader,
      setOpenSnackbar,
      setTempGroups
    );
    setDisable(true);
    setGroupName("");
    setOpenCreate(!openCreate);
    setSnackbarMSG("Category Added Successfully!");
    setSnackbarSeverity("success");
  };
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (isDataPostSuccess) {
      getCatalogueItemsByCatalogueId(
        setGroups,
        catalogueId,
        contactId,
        setLoadSave,
        setTempGroups,
        setDummyLoad
      );
      setDataPostSuccess(false);
    }
  }, [isDataPostSuccess]);

  useEffect(() => {
    isCatalogueEditable(catalogueId, setEditableCatalogue);
    getUserConfigsTypes(setUserConfigsTypes);
    getUserConfigsUom(setUserConfigsUom, setDummyLoad);
    getCatalogueItemsByCatalogueId(
      setGroups,
      catalogueId,
      contactId,
      setLoadSave,
      setTempGroups,
      setOpenAllRows
    );
  }, []);
  // ------------------------------------------------------------------------
  const handleImportedDataSave = () => {
    updateCatalogueItemsByCatalogueId(
      catalogueId,
      contactId,
      setGroups,
      groups,
      setOpenSnackbar,
      setTempGroups
    );
    setIsImported(false);
    setSnackbarMSG("Imported Data Saved successfully!");
    setSnackbarSeverity("success");
  };
  // ------------------------------------------------------------------------
  const handleImportedDataDiscard = () => {
    setGroups(JSON.parse(JSON.stringify(tempGroups)));
    setIsImported(false);
  };
  // --------------------------UseEffect-For-Import-Data----------------------------------------------
  useEffect(() => {
    console.log("importedData:", importedData);
    if (importedData.length > 0) {
      let result;
      //below code is to automatically generate groupCode
      // var lastItemId;
      // var lastBeforeRowSeqId;
      // if (groups.length === 0) {
      //   lastBeforeRowSeqId = 0;
      // } else {
      //   lastBeforeRowSeqId = groups[groups.length - 1].groupCode;
      // }

      let newLineItemsWithGrp = importedData
        .filter(function (item) {
          var index = groups.findIndex((g) => g.groupName == item["group_name"]);
          if (index >= 0) {
            //return item if it is matching group
            return item;
          } else {
            //If group is not matched then create new group with empty items
            groups.push({
              groupCode: item["group_code"],
              groupName: item["group_name"],
              items: [],
            });

            return item;
          }
        })
        .map(function (item) {
          //This will add imported item in matching group
          var index = groups.findIndex((g) => g.groupName == item["group_name"]);

          //below code is to automatically generate itemCode
          // if (groups[index].items.length === 0) {
          //   lastItemId = 0;
          // } else {
          //   lastItemId = groups[index].items[groups[index].items.length - 1].itemCode;
          // }
          result = groups[index].items.findIndex((rowItem) => {
            return rowItem.itemCode === item["code"];
          });
          if (result !== -1) {
            groups[index].items[result] = {
              itemId: null,
              itemCode: item["code"],
              itemName: item["name"],
              itemGroupCode: groups[index].groupCode,
              itemGroupName: groups[index].groupName,
              itemDescription: item["description"],
              itemCategory: groups[index].groupName,
              type: item.type,
              qty: item.qty ? parseInt(item.qty) : 0,
              uom: item.uom,
              unitCost: parseFloat(item["unit_cost"]),
              markupPercentage: item.markup ? parseFloat(item.markup) : 0.0,
              variancePercentage: item.variance ? parseFloat(item.variance) : 0.0,
              gst: item.gst === "true" ? true : false,
            };
          } else {
            groups[index].items.push({
              itemId: null,
              itemCode: item["code"],
              itemName: item["name"],
              itemGroupCode: groups[index].groupCode,
              itemGroupName: groups[index].groupName,
              itemDescription: item["description"],
              itemCategory: groups[index].groupName,
              type: item.type,
              qty: item.qty ? parseInt(item.qty) : 0,
              uom: item.uom,
              unitCost: parseFloat(item["unit_cost"]),
              markupPercentage: item.markup ? parseFloat(item.markup) : 0.0,
              variancePercentage: item.variance ? parseFloat(item.variance) : 0.0,
              gst: item.gst === "true" ? true : false,
            });
          }
        });
      setIsImported(true);
      setImportedData([]);
    }
  }, [importedData]);
  // ------------------------------------------------------------------------
  return (
    <div>
      {" "}
      <Grid container>
        <Grid item xs={12}>
          <Box my={1}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={9}>
                <ButtonGroup disableElevation>
                  <Button variant="contained" onClick={handleOpenAllRows} size="small" color="primary">
                    EXPAND
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCloseAllRows}
                    size="small"
                    color="secondary"
                  >
                    COLLAPSE
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={3} align="right">
                <ButtonGroup disableElevation>
                  {openCreate ? (
                    <Button
                      disabled={!editableCatalogue}
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={handleGroupClose}
                      className={classes.button}
                    >
                      <CloseIcon />
                      Close
                    </Button>
                  ) : (
                    <Button
                      disabled={!editableCatalogue}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleGroupAdd}
                      className={classes.button}
                    >
                      <AddIcon />
                      ADD
                    </Button>
                  )}
                  {disable ? (
                    <Button
                      disabled
                      variant="contained"
                      size="small"
                      align="right"
                      onClick={handleGroupSave}
                      className={classes.button}
                    >
                      <CheckCircleOutlineIcon />
                      SAVE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      align="right"
                      onClick={handleGroupSave}
                      className={classes.button}
                    >
                      <CheckCircleOutlineIcon />
                      SAVE
                    </Button>
                  )}
                </ButtonGroup>

                <Collapse in={openCreate} timeout="auto" unmountOnExit>
                  <div className={clsx(classes.fullList)} role="presentation">
                    <Grid
                      container
                      mt={1}
                      spacing={2}
                      direction="rows"
                      alignContent={"flex-end"}
                      justifyContent={"flex-end"}
                    >
                      <Grid item>
                        <TextField
                          required
                          id="groupCode"
                          name="groupCode"
                          value={groupCode}
                          variant="outlined"
                          onChange={(e) => {
                            setGroupCode(e.target.value);
                          }}
                          label={"Group Code"}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          id="groupName"
                          name="groupName"
                          value={groupName}
                          variant="outlined"
                          onChange={(e) => {
                            setGroupName(e.target.value);
                          }}
                          label={"Group Name"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
                <Box mt={2}>
                  <ButtonGroup>
                    <CSVBoxButton
                      licenseKey="mWnJSBJ484CtLBpi72QCusJcmCTF4v"
                      user={{
                        user_id: "default123",
                      }}
                      onImport={(result, data) => {
                        if (result) {
                          setImportedData(JSON.parse(JSON.stringify(data.rows)));
                          setSnackbarMSG("Data Imported Succesfully");
                          setSnackbarSeverity("success");
                          setOpenSnackbar(true);
                          console.log("In ImportButton");
                        } else {
                          console.log("fail");
                        }
                      }}
                      render={(launch) => {
                        return (
                          <Button
                            data-csvbox
                            onClick={launch}
                            variant="contained"
                            size="small"
                            color="primary"
                            align="left"
                            className={classes.button}
                            disabled={isImported || !editableCatalogue}
                          >
                            Import
                          </Button>
                        );
                      }}
                    >
                      Import
                    </CSVBoxButton>
                    {isImported && (
                      <>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={handleImportedDataSave}
                          className={classes.button}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={handleImportedDataDiscard}
                          className={classes.button}
                        >
                          Discard
                        </Button>
                      </>
                    )}
                  </ButtonGroup>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {loadSave ? (
            <ProgressBar />
          ) : (
            <Paper>
              <Box m={1} py={2}>
                {roundLoader && <SimpleBackdrop />}
                <TableContainer>
                  <Table size="small" aria-label="collapsible table">
                    <TableBody>
                      <>
                        {groups.length === 0 ? (
                          <EmptyEstimatesDialog
                            heading="Empty Catagories "
                            msg="You don't have any categories here yet.
Click the 'ADD' button above to add your catalogue type entries"
                          />
                        ) : (
                          groups.map((row, index) => (
                            <GroupRow
                              key={row.groupCode}
                              row={row}
                              setItems={setItems}
                              setRows={setGroups}
                              rows={groups}
                              catalogueId={catalogueId}
                              contactId={contactId}
                              index={index}
                              loadSave={loadSave}
                              setLoadSave={setLoadSave}
                              isEdit={isEdit}
                              setEdit={setEdit}
                              openAllRows={openAllRows}
                              setOpenAllRows={setOpenAllRows}
                              userConfigsTypes={userConfigsTypes}
                              userConfigsUom={userConfigsUom}
                              setOpenSnackbar={setOpenSnackbar}
                              setSnackbarMSG={setSnackbarMSG}
                              setSnackbarSeverity={setSnackbarSeverity}
                              setTempGroups={setTempGroups}
                              editableCatalogue={editableCatalogue}
                            />
                          ))
                        )}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {openSnackbar && (
                <CustomizedSnackbars
                  openSnackbar={openSnackbar}
                  setOpenSnackbar={setOpenSnackbar}
                  snackbarMSG={snackbarMSG}
                  severity={snackbarSeverity}
                />
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
