/** @format */

import apis from "../../utils/apis";

export const getJobplans = (setJobPlansArr, setLoad) => {
  setLoad(true);
  apis
    .GET({
      endpoint: "/job-plans",
    })
    .then((res) => {
      if (res) {
        res.data.sort(function (a, b) {
          return a.jobPlanId - b.jobPlanId;
        });
        console.log("rendering estimate...");
        console.log(res.data);
        setJobPlansArr(res.data);
        setLoad(false);
      }
    });
};
// ----------------------Jobplan-Editable-Or-Not------------------------
export const isJobplanEditable = (jobPlanId, setEditableJobplan) => {
  apis
    .GET({
      endpoint: "/job-plans",
    })
    .then((res) => {
      if (jobPlanId) {
        let filteredData = res.data.filter((el) => {
          return el.jobPlanId === jobPlanId;
        });
        setEditableJobplan(filteredData[0].editable);
      }
    });
};
// -----------------------------------------------------
export const deleteJobPlabyID = (
  jobPlanId,
  setOpenSnackbar,
  JobPlansArr,
  setJobPlansArr,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/job-plan/${jobPlanId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        // setDelLoading(false);
        var newData = JobPlansArr.filter((jobplan) => {
          if (jobplan.jobPlanId != jobPlanId) {
            return jobplan;
          }
        });
        setJobPlansArr(newData);
        setSnackbarMSG("Job Plan deleted successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
      } else {
        setJobPlansArr(JobPlansArr);
        setSnackbarMSG("Job Plan deletion unsuccessfull!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
