/** @format */

import apis from "../../../utils/apis";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";

/* API for Add/Get/update Work Order  */
export const createWorkOrder = (
  PurchaseRequestArray,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  setNewOrderData,
  jobId,
  setNewlyAdded
) => {
  setRoundLoader(true);
  console.log("Create Function Called");
  apis
    .POST({
      endpoint: "/work-order",
      payload: PurchaseRequestArray,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        console.log("if res.data");
        setNewlyAdded({ addedType: "workOrder", item: res.data });
        console.log("setNewlyAdded");

        setSnackbarMSG("Work Order Created Successfully!");
        setSnackbarSeverity("success");
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          completedDate: null,
          send: false,
          comment: "",
          status: "DRAFT",
          workAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });
        console.log("abouv round");
        setRoundLoader(false);
        console.log("below round");
        setOpenSnackbar(true);
        setState({ ["right"]: false });
      } else {
        setSnackbarMSG("Work order creation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};

export const getWorkOrder = (setOrderList, setDataLoaded) => {
  apis
    .GET({
      endpoint: `/work-orders`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      // /* removed time into date */
      // res.data.map((data) => {
      //   data.requiredBy && (data.requiredBy = new Date(data.requiredBy).toISOString().slice(0, 10));
      // });
      console.log("getting-WorkOrder", res.data);
      console.log(res.data);
      setOrderList(res.data);
      setDataLoaded(true);
    })
    .catch((err) => console.log("No costings data "));
};
export const getWorkOrdersByJobId = (jobId, setOrderList, setDataLoaded) => {
  apis
    .GET({
      endpoint: `/work-orders/${jobId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      // /* removed time into date */
      // res.data.map((data) => {
      //   data.requiredBy && (data.requiredBy = new Date(data.requiredBy).toISOString().slice(0, 10));
      // });
      console.log("getting-WorkOrder", res.data);
      console.log(res.data);
      setOrderList(res.data);
      setDataLoaded(true);
    })
    .catch((err) => console.log("No costings data "));
};

export const getWorkOrderById = (
  orderId,
  setNewOrderData,
  setLoading,
  setSelectedContact,
  setNewOrderDate,
  setCompletedDate,
  setSelectedTemplate,
  termsAndCondtions,
  setDefaultValueTemplate
) => {
  setLoading(true);
  apis
    .GET({
      endpoint: `/work-order/${orderId}`,
    })
    .then((res) => {

      let obj = JSON.parse(JSON.stringify(res.data));
      delete obj.contact;
      setNewOrderData(obj);
      res.data.contact && setNewOrderData({ ...obj, contactId: res.data.contact.contactId });
      setSelectedContact(res.data.contact);
      setNewOrderDate(res.data.requiredBy);
      setCompletedDate(res.data.completedDate);
      // setDefaultValueTemplate(index);
      if (termsAndCondtions != undefined) {
        const selectedtemplate = termsAndCondtions?.filter(function (item) {
          return item?.templateId === res?.data?.termsAndConditionsTemplateId;
        });

        // function getId(item) {
        //   return item?.termsAndConditionsTemplateId === res?.data?.termsAndConditionsTemplateId;
        // }
        // const index = termsAndCondtions.findIndex(getId);
        // setDefaultValueTemplate(index);
        console.log(selectedtemplate, "selectedtemplate");
        setSelectedTemplate(selectedtemplate[0]);
      }

      setLoading(false);
    });
};

export const getPurchaseOrder = (setOrderList, setDataLoaded) => {
  apis
    .GET({
      endpoint: `/purchase-orders`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      /* removed time into date */
      // res.data.map((data) => {
      //   data.requiredBy && (data.requiredBy = new Date(data.requiredBy).toISOString().slice(0, 10));
      // });
      console.log("getting purchaseOrder.AAAAAAAAAAAAAAAAAAAAAAAA");
      console.log(res.data);
      setOrderList(res.data);
      setDataLoaded(true);
    })
    .catch((err) => console.log("No costings data "));
};
export const getPurchaseOrdersByJobId = (jobId, setOrderList, setDataLoaded) => {
  apis
    .GET({
      endpoint: `/purchase-orders/${jobId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.orderNo - b.orderNo;
      });

      /* removed time into date */
      // res.data.map((data) => {
      //   data.requiredBy && (data.requiredBy = new Date(data.requiredBy).toISOString().slice(0, 10));
      // });
      console.log("getting purchaseOrder.AAAAAAAAAAAAAAAAAAAAAAAA");
      console.log(res.data);
      setOrderList(res.data);
      setDataLoaded(true);
    })
    .catch((err) => console.log("No costings data "));
};
export const updateWorkOrder = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  setNewOrderData,
  setSelectedContact,
  jobId,
  orderList,
  setOrderList
) => {
  setRoundLoader(true);
  let oldData = [...orderList];

  apis
    .PUT({
      endpoint: `/work-order`,

      payload: obj,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data) {
        oldData?.map((item, i) => {
          if (item.orderId === obj.orderId) {
            oldData[i] = res.data;
          }
        });
        setOrderList(oldData);
        setSelectedContact({ contactId: "" });
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          send: false,
          comment: "",
          status: "Draft",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });
        setSnackbarMSG("Work order Update successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Work order Update failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    });
};

export const getPurchaseOrderById = (
  orderId,
  setNewOrderData,
  setLoading,
  setSelectedContact,
  setNewOrderDate,
  setSelectedTemplate,
  termsAndCondtions,
  setDefaultValueTemplate
) => {
  setLoading(true);
  apis
    .GET({
      endpoint: `/purchase-order/${orderId}`,
    })
    .then((res) => {
      let obj = JSON.parse(JSON.stringify(res.data));
      delete obj.contact;

      setNewOrderData(obj);
      res.data.contact && setNewOrderData({ ...obj, contactId: res.data.contact.contactId });
      setSelectedContact(res.data.contact);
      setNewOrderDate(res.data.requiredBy);
      if (termsAndCondtions != undefined) {
        const selectedtemplate = termsAndCondtions?.filter(function (item) {
          return item?.templateId === res?.data?.termsAndConditionsTemplateId;
        });

        // function getId(item) {
        //   return item?.termsAndConditionsTemplateId === res?.data?.termsAndConditionsTemplateId;
        // }
        // const index = termsAndCondtions.findIndex(getId);
        // setDefaultValueTemplate(index);
        console.log(selectedtemplate, "selectedtemplate");
        setSelectedTemplate(selectedtemplate[0]);
      }
      setLoading(false);
    });
};

export const getPurchaseOrderDataForPdf = (orderId, tabType) => {
  apis
    .GET({
      endpoint: `/purchase-order/${orderId}`,
    })
    .then((res) => {
      // if (res.data.message === "SUCCESS") {
      console.log("Data in Helper:", { data: res.data, tabType: tabType });
      axios
        .post("/create-pdf", { data: res.data, tabType: tabType })
        .then(() => axios.get(" fetch-pdf", { responseType: "blob" }))
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, "newPdf.pdf");
        });
    });
};

export const getWorkOrderDataForPdf = (orderId, tabType) => {
  apis
    .GET({
      endpoint: `/work-order/${orderId}`,
    })
    .then((res) => {
      // if (res.data.message === "SUCCESS") {
      console.log("Data in Helper:", { data: res.data, tabType: tabType });
      axios
        .post("/create-pdf", { data: res.data, tabType: tabType })
        .then(() => axios.get("fetch-pdf", { responseType: "blob" }))
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, "newPdf.pdf");
        });
    });
};

export const updatePurchaseOrder = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  setNewOrderData,
  setSelectedContact,
  jobId,
  orderList,
  setOrderList
) => {
  setRoundLoader(true);
  let oldData = [...orderList];
  apis
    .PUT({
      endpoint: `/purchase-order`,

      payload: obj,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data) {
        oldData?.map((item, i) => {
          if (item.orderId === obj.orderId) {
            oldData[i] = res.data;
          }
        });
        setOrderList(oldData);
        setSelectedContact({ contactId: "" });
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          send: false,
          comment: "",
          status: "Draft",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });

        setSnackbarMSG("Purchase Order Update successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Purchase Order Update failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    });
};
/* API for Add/Get/update Purchase Order  */
export const createPurchaseOrder = (
  PurchaseRequestArray,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  setNewOrderData,
  jobId,
  setNewlyAdded
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/purchase-order",
      payload: PurchaseRequestArray,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        setNewlyAdded({ addedType: "purchaseOrder", item: res.data });
        setSnackbarMSG("Purchse Order Created Successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          send: false,
          comment: "",
          status: "DRAFT",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Purchse Order Creation Failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
// ---------------------------------------------------------------------------------------
export const deletePurchaseOrderByOrderId = (
  orderId,
  wantToCallGet,
  setWantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  apis
    .DELETE({
      endpoint: `/purchase-order/${orderId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        wantToCallGetFunction(setWantToCallGet, wantToCallGet);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Purchase Order deleted successfully");
        setSnackbarSeverity("success");
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG("Purchase Deletion failed");
        setSnackbarSeverity("error");
      }
    });
};

export const deleteWorkOrderByOrderId = (
  workOrderId,
  wantToCallGet,
  setWantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  apis
    .DELETE({
      endpoint: `/work-order/${workOrderId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        wantToCallGetFunction(setWantToCallGet, wantToCallGet);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Work Order deleted successfully");
        setSnackbarSeverity("success");
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG("Work Deletion failed");
        setSnackbarSeverity("error");
      }
    });
};

// ---------------------------------------------------------------------------------------
export const createVariation = (
  PurchaseRequestArray,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader,
  setState,
  variationList,
  setVariationList,
  setVariation,
  jobId,
  setLoading
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/variation",
      payload: PurchaseRequestArray,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        if (variationList.length > 0) {
          let oldData = [...variationList];
          oldData.push(res.data);
          setVariationList(oldData);
        }

        apis
          .GET({
            endpoint: `/variations/${jobId}`,
          })
          .then((res) => {
            res.data.sort(function (a, b) {
              return a.variationNo - b.variationNo;
            });

            console.log("getting Variations:", res.data);
            setVariation(res.data);
          })
          .catch((err) => console.log("No Variation data "));
        setSnackbarMSG("Variation Created Successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Variation Creation Unsuccessfull!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};

// ----------------------------Create-Order-Without-selected-LineItems-----------------------------------------------------------
export const createNewPurchaseOrder = (
  newOrderData,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setNewOrderState,
  setRoundLoader,
  setNewOrderData,
  setSelectedContact,
  jobId,
  orderList,
  setOrderList
) => {
  setRoundLoader(true);
  let oldData = [...orderList];
  apis
    .POST({
      endpoint: "/purchase-order",
      payload: newOrderData,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        oldData.push(res.data);
        setOrderList(oldData);
        setSnackbarMSG("Order Created Successfully");
        setSnackbarSeverity("success");
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          completedDate: null,
          send: false,
          comment: "",
          status: "Draft",
          deliveryAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });
        setSelectedContact({ contactId: "" });
        setRoundLoader(false);
        setNewOrderState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Order Creation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    });
};

export const createNewWorkOrder = (
  newOrderData,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setNewOrderState,
  setRoundLoader,
  setNewOrderData,
  setSelectedContact,
  jobId,
  orderList,
  setOrderList
) => {
  setRoundLoader(true);
  let oldData = [...orderList];
  apis
    .POST({
      endpoint: "/work-order",
      payload: newOrderData,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        oldData.push(res.data);
        setOrderList(oldData);
        setSnackbarMSG("Order Created Successfully");
        setSnackbarSeverity("success");
        setNewOrderData({
          jobId: jobId,
          description: "",
          message: "",
          contactId: "",
          requiredBy: new Date()
            .toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          completedDate: null,
          send: false,
          comment: "",
          status: "Draft",
          workAddress: {
            streetAddress: "",
            suburbOrTown: "",
            state: "",
            postcode: "",
          },
          orderLineItems: [],
        });
        setSelectedContact({ contactId: "" });
        setRoundLoader(false);
        setNewOrderState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Order Creation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    });
};

/* ---------------------get purchase order by Job line item id------------------- */
export const getPurchaseOrderByJobLineItemId = (jobId, setOrderList, setDataLoaded, lineItemId) => {
  apis
    .GET({
      endpoint: `/purchase-orders/${jobId}/${lineItemId}`,
    })
    .then((res) => {
      console.log("res in quote-- ", res.data);
      if (res.status === 200) {
        var purchaseOrderAllData = res.data.sort((a, b) => {
          return b.purchaseNo - a.purchaseNo;
        });
        setOrderList(purchaseOrderAllData);
        setDataLoaded(true);
      }
    });
};
// -------------------------------------------------------------------------------------------------

/* we can call this function where we are using wantToCallGet for refresh
I defined this function bcz we are set true/false value two time into multiple funtion so
now we no need to set true/false value at two time, */
const wantToCallGetFunction = (setWantToCallGet, wantToCallGet) => {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
};
