/** @format */

import apis from "../../utils/apis";

//Sample estimate id : 1421cc85-da21-4326-b449-a5f404093cc9
export const getEstimates = (setEstimates, setLoad) => {
  setLoad(true);
  apis
    .GET({
      endpoint: "/estimates",
    })
    .then((res) => {
      if (res.data) {
        res.data.sort(function (a, b) {
          return a.estimateNo - b.estimateNo;
        });
        console.log("rendering estimate...");
        console.log(res.data);
        setEstimates(res.data);
        setLoad(false);
      } else {
        setLoad(true);
      }
    });
};

export const getEstimatesbyID = (id, setRow) => {
  apis
    .GET({
      endpoint: `/estimate/${id}`,
    })
    .then((res) => {
      // console.log("get estimate by id...");
      console.log("client", res.status);
      if (res.status == 200) {
        setRow(res.data);
      }
    });
};
export const getEstimatesbyIdForEstimateDetails = (
  id,
  setRow,
  clientObject,
  setLoad,
  setEstimateStatus
) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/estimate/${id}`,
    })
    .then((res) => {
      // console.log("get estimate by id...");
      console.log("client", res.status || null);
      if (res.status == 200) {
        let clientData = res.data.client;
        setEstimateStatus(res.data.status);
        if (clientData) {
          clientObject({
            clientId: clientData.clientId,
            name: clientData.name,
            clientType: clientData.clientType,
            address: clientData.address,
            cityOrTown: clientData.cityOrTown,
            state: clientData.state,
            postcode: clientData.postcode,
            phone: clientData.phone,
            mobile: clientData.mobile,
            postalAddress: clientData.postalAddress,
            postalCityOrTown: clientData.postalCityOrTown,
            postalState: clientData.postalState,
            postalPostcode: clientData.postalPostcode,
            Postalmobile: clientData.Postalmobile,
            postalPhone: clientData.postalPhone,
            emailAddress: clientData.emailAddress,
          });
        }

        setRow(res.data);
        setLoad(false);
      }
    });
};
export const deleteEstimatebyID = (EstimateId, setOpenSnackbar, setSnackbarSeverity, setSnackbarMSG) => {
  apis
    .DELETE({
      endpoint: `/estimate/${EstimateId}`,
    })
    .then((res) => {
      setSnackbarMSG("Estimate deleted successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      console.log("deleted Success");
    });
};

export const createEstimates = (
  data,
  history,
  setLoading,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  setLoading(true);
  apis
    .POST({
      endpoint: "/estimate",
      payload: data,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        let location = {
          estimateID: res.data.value,
          pathname: `/estimate/${res.data.value}/costing`,
        };
        history.push(location);
        //reload issue temp solution
        setLoading(false);
        console.log("clients data posted successFully>>>");
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("clients posting failed>>>");
      }
    });
};

/* ************  Create client **********************/

export const createClient = (obj, setWantToCallGet, setOpenSnackbar) => {
  apis
    .POST({
      endpoint: "/client",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      setWantToCallGet(true);
      setOpenSnackbar(true);
      if (res.data.message == "SUCCESS") {
        console.log("clients data posted successFully>>>");
      } else {
        console.log("clients posting failed>>>");
      }
    });
};

/* ************  get clients **********************/

export const getClients = (setCustomer) => {
  apis
    .GET({
      endpoint: "/clients",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.clientNo - b.clientNo;
      });
      console.log("rendering clients...");
      console.log(res.data);
      setCustomer(res.data);
    });
};

/* *******************get job plan*********************** */
export const getJobPlan = (setJobPlanObject) => {
  apis
    .GET({
      endpoint: "/job-plans",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.costCentreNo - b.costCentreNo;
      });
      console.log(res.data);
      setJobPlanObject(res.data);
      // setLoad(false);
    });
};

/* ************ Estimate Details **********************/
export const updateEstimateDetails = (
  estimateId,
  obj,
  setRoundLoader,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setEstimateStatus
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/estimate/${estimateId}`,

      payload: obj,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setEstimateStatus(obj.status);
        setSnackbarMSG("Estimate Details Update successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Estimate Details Update failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
