/** @format */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteCatalogueByID } from "./catalogue.helper";

export default function AlertPopCatalogue({
  confirmDelete,
  setConfirmDelete,
  deleteId,
  setCatalogueData,
  setOpenSnackbar,
  catalogueData,
  setSnackbarSeverity,
  setSnackbarMSG,
  deleteContactId,
  setRoundLoader,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };

  const handleYes = () => {
    console.log("del id ", deleteId);
    deleteCatalogueByID(
      deleteId,
      deleteContactId,
      setOpenSnackbar,
      catalogueData,
      setCatalogueData,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader
    );
    setConfirmDelete(false);
  };

  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
