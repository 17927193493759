/** @format */

import apis from "../../../utils/apis";

export const getTermsAndCondtions = (setTermsAndCondtions) => {
  apis
    .GET({
      endpoint: `/terms-and-conditions/templates`,
    })
    .then((res) => {
      const templatedata = res?.data;
      var i;
      // for (i = 0; i < templatedata.length; i++) {
      //   templatedata[i].templateId = templatedata[i]["templateId"];
      //   delete templatedata[i].templateId;
      // }
      setTermsAndCondtions(templatedata);
    })
    .catch((err) => console.log("No costings data "));
};

export const getTermsAndCondtionsById = (templateId, setTemplateData, setLoader) => {
  apis
    .GET({
      endpoint: `/terms-and-conditions/template/${templateId}`,
    })
    .then((res) => {
      if (res.data) {
        setTemplateData(res.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
    .catch((err) => console.log("No costings data "));
};
export const createNewTemplate = (
  templateData,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  termsAndCondtions,
  setTermsAndCondtions
) => {
  setRoundLoader(true);
  let oldData = [...termsAndCondtions];
  apis
    .POST({
      endpoint: "/terms-and-conditions/template",
      payload: templateData,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        oldData.push(res.data);
        setTermsAndCondtions(oldData);
        setSnackbarMSG("Template Added Successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenSnackbar(true);
        setState(false);
      } else {
        setSnackbarMSG("Work order creation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
export const EditTemplate = (
  templateData,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setState,
  setRoundLoader,
  termsAndCondtions,
  setTermsAndCondtions,
  setEditTemplateState
) => {
  setRoundLoader(true);
  let oldData = [...termsAndCondtions];
  apis
    .PUT({
      endpoint: "/terms-and-conditions/template",
      payload: templateData,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data) {
        const templateId = res.data?.templateId
        const templateIndex = oldData.findIndex(item => item.templateId === templateId)
        if (templateIndex != -1 && res.data !== undefined) {
          oldData[templateIndex] = res?.data
        }
        setTermsAndCondtions(oldData);
        setSnackbarMSG("Template Edited Successfully!");
        setSnackbarSeverity("success");
        setEditTemplateState(false);
        setRoundLoader(false);
        setOpenSnackbar(true);
        setState(false);
      } else {
        setSnackbarMSG("Work order creation failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};
export const DeleteTemplate = (
  setOpenSnackbar,
  setSnackbarMSG,
  setRoundLoader,
  setEditTemplateState,
  setSnackbarSeverity,
  templateId,
  getTermsAndCondtions,
  setTermsAndCondtions
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/terms-and-conditions/template/${templateId}`,
    })
    .then((res) => {
      if (res.data) {
        setOpenSnackbar(true);
        getTermsAndCondtions(setTermsAndCondtions);
        setSnackbarMSG("Template Deleted Successfully!");
        setRoundLoader(false);
        setSnackbarSeverity("success");

        setEditTemplateState(false);
      } else {
        setSnackbarMSG("template not deleted!");
        setRoundLoader(false);
        setSnackbarSeverity("error");
      }
    })
    .catch((err) => console.log("No costings data "));
};
