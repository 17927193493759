/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateIcon from "@mui/icons-material/Create";
import { useHistory, useParams } from "react-router-dom";
import { Button, MenuItem, Select, Snackbar, InputAdornment, Collapse } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NumberFormat from "react-number-format";
import Checkbox from "@mui/material/Checkbox";
import {
  getJobLineItemsByJobId,
  getlineItemsByJobId,
  updateJobLineItems,
} from "./helpers.actual.costings";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import { uuid } from "uuidv4";
import ProgressBar from "../../UI/ProgressBar";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_DELETE,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
  JOB_WRITE,
} from "../../UI/GlobalVariables";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteIcon from "@mui/icons-material/Delete";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import { getGst } from "../../UI/GlobalApi";
import Link from "@mui/material/Link";
import { getPurchaseOrder } from "../orders/Order.helper";
import Order from "../orders/Order";
import { getPurchaseOrderByJobLineItemId } from "../orders/Order.helper";
import Variation from "../Variation/Variation";
import { SettingsSystemDaydream } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
export default function ActualCostingBody({
  actualLineItem,
  rows,
  setRows,
  category,
  lineItems,
  id,
  row,
  loadSave,
  handlePurchesOrder,
  isEdit,
  setDisableSave,
  setEstTotal,
  setActTotal,
  setVarianceTotal,
  userConfigs,
  userConfigsuom,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setOpenedItemRow,
  jobId,
  jobStatus,
  newlyAdded,
  setRoundLoader,
}) {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const classes = useStyles();
  var { jobId } = useParams();
  const [inputList, setInputList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [selectedOpenTable, setSelectedOpenTable] = React.useState();
  const [lineItemId, setLineItemId] = useState();
  const [openItems, setOpenItems] = React.useState(false);

  //calculation for est total act total and var total
  var finalEstTotal = 0;
  var finalActTotal = 0;
  var finalVariance = 0;
  inputList.filter((e) => {
    var estTotal = e.estimatedQty * e.estimatedUnitCost;
    finalEstTotal = finalEstTotal + estTotal;
    var actTotal = e.actualQty * e.actualUnitCost;
    finalActTotal = finalActTotal + actTotal;
    var fvar = e.estimatedUnitCost * e.estimatedQty - e.actualUnitCost * e.actualQty;
    finalVariance = finalVariance + fvar;
  });
  setActTotal(finalActTotal);
  setEstTotal(finalEstTotal);
  setVarianceTotal(finalVariance);
  // end calculations
  // useEffect(() => {
  //   row.items.sort(function (a, b) {
  //     return a.lineItemSequenceId - b.lineItemSequenceId;
  //   });
  // }, [rows]);

  /* ---------------------------------------------------------------------- */

  useEffect(() => {
    for (var i of actualLineItem) {
      isChecked.push({ id: i.optionId, checked: false });
    }
    setInputList(JSON.parse(JSON.stringify(actualLineItem)));
  }, [actualLineItem]);

  /* ---------------------------------------------------------------------- */

  const handleOpenCostinRow = (row, index) => {
    setLineItemId(row.lineItemId);
    // setOpenedItemRow(row);
    if (row.referenceOrders && row.referenceWorkOrders) {
      setSelectedOpenTable("both");
    } else if (row.referenceOrders) {
      setSelectedOpenTable("purchaseOrder");
    } else if (row.referenceWorkOrders) {
      setSelectedOpenTable("workOrder");
    }
    if (openItems[index]) {
      setOpenItems({ [index]: false });
    } else {
      setOpenItems({ [index]: true });
    }
  };

  const handleOpenVariations = (row, index) => {
    setLineItemId(row.lineItemId);
    setSelectedOpenTable("variations");
    if (openItems[index]) {
      setOpenItems({ [index]: false });
    } else {
      setOpenItems({ [index]: true });
    }
  };

  /* ---------------------------------------------------------------------- */
  // const handleOpenDrawer = (orderId) => {
  //   if (props.whichOrder === "purchaseOrder") {
  //     getPurchaseOrderById(orderId, setNewOrderData, setLoading, setSelectedContact, setNewOrderDate);
  //   } else if (props.whichOrder === "workOrder") {
  //     getWorkOrderById(
  //       orderId,
  //       setNewOrderData,
  //       setLoading,
  //       setSelectedContact,
  //       setNewOrderDate,
  //       setCompletedDate
  //     );
  //   }
  //   setState({ ...state, ["right"]: true });
  // };

  /* ---------------------------------------------------------------------- */
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleEdit = (i) => {
    setIsEditMode(true);
  };

  /* ---------------------------------------------------------------------- */

  const handleCancel = (i) => {
    setInputList(actualLineItem); /* Reset our old data after cancel edit/add operation  */
    setIsEditMode(false);
    setAddMode(false);
    // setDisableSave(true);
  };
  const unique_id = uuid();

  /* ---------------------------------------------------------------------- */
  const [addMode, setAddMode] = useState(false);

  const handleAdd = () => {
    // setDisableSave(false);
    // if (inputList.length === 0) {
    //   var lastBeforeRowLineId = 0;
    // } else {
    //   var lastBeforeRowLineId = inputList[inputList.length - 1].itemCode;
    // }
    setIsEditMode(true);
    setInputList([
      ...inputList,
      {
        lineItemId: uuid(),
        estimateLineItemId: null,
        itemId: "",
        itemCode: "",
        itemName: "",
        itemGroupCode: row.groupCode,
        itemGroupName: row.groupName,
        itemDescription: "",
        itemCategory: "",
        optionId: unique_id,
        optionName: "STANDARD",
        type: null,
        estimatedQty: 0,
        orderedQty: 0,
        actualQty: 0,
        uom: null,
        estimatedUnitCost: 0,
        actualUnitCost: 0,
        markupPercentage: 0,
        gst: true,
        notes: null,
        included: true,
        editable: true,
      },
    ]);

    // lineItems.concat(inputList);
  };

  /* ------------------------Common function------------------------- */

  function updateValuesInRows(finalJobLineObj) {
    rows.filter((item) => {
      finalJobLineObj.filter((el) => {
        if (item.groupCode === el.itemGroupCode && item.groupName === el.itemGroupName) {
          item.items = finalJobLineObj;
        }
      });
    });
    console.log("rows", rows);
    console.log("finalJobLineObj", finalJobLineObj);
    updateJobLineItems(rows, jobId, setOpenSnackbar, setSnackbarMSG, setSnackbarSeverity, setRows, setRoundLoader);
  }
  /* -------------------------------------------------------- */

  const handlesLineItemSave = () => {
    var finalJobLineObj = inputList.filter((el) => {
      if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
        return el;
      }
    });
    setInputList(finalJobLineObj);
    updateValuesInRows(finalJobLineObj);
    setIsEditMode(false);
    // setDisableSave(true);
  };

  /* ---------------------------------------------------------------- */
  const [name, setName] = useState(actualLineItem.actualQty);
  const handleInputChange = (e, index) => {
    // setDisableSave(false);
    // setName(e.target.value);
    const { name, value } = e.target;

    const list = JSON.parse(JSON.stringify(inputList));
    if (
      name === "estimatedUnitCost" ||
      name === "actualUnitCost" ||
      name === "markupPercentage" ||
      name === "actualQty" ||
      name === "orderedQty" ||
      name === "estimatedQty"
    ) {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }

    setInputList(list);


  };
  /* ---------------------------------------------------------------- */
  const handleChangeIncluded = (e, i, row) => {
    if (!e.target.checked) {
      newIncludedValue(false);
    } else {
      newIncludedValue(true);
    }
    function newIncludedValue(value) {
      const list = JSON.parse(JSON.stringify(inputList));
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };
  /* ---------------------------------------------------------------- */
  const handleChangeGST = (e, i, row) => {
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(inputList));
      list[i][e.target.name] = value;
      setInputList(list);
    }
  };

  /* ---------------------------------------------------------------- */
  /* ------------------------Common function------------------------- */
  // function filterInputListForClearAndDeleteLineItem(deleteId) {
  //   let filterList = inputList.filter((el) => {
  //     return deleteId.itemCode != el.itemCode;
  //   });

  //   let count = 1;
  //   var test = filterList.filter(function (el) {
  //     el.itemCode = count++;
  //     return el;
  //   });
  //   return test;
  // }
  /* ---------------------------------------------------------------- */

  // const deleteItemNonEditMode = () => {
  //   let deletedData = filterInputListForClearAndDeleteLineItem(itemRowToDelete);
  //   updateValuesInRows(deletedData);
  //   setJobPlanObject(deletedData); //it is for permenetly store data after api call
  //   setShowConfirm(false);
  // };

  /* -------------------------------------------------------- */
  // const clearLineItem = (row) => {
  //   let deletedData = filterInputListForClearAndDeleteLineItem(row);
  //   setInputList(deletedData);
  //   setDisableSave(true);
  // };

  /* ---------------------------------------------------------------- */

  // const clearLineItem = (row) => {
  //   if (row.lineItemId === null) {
  //     var listfilter = inputList.filter(function (el) {
  //       return el.lineItemId != null;
  //     });
  //   } else {
  //     var listfilter = inputList.filter(function (el) {
  //       return el.lineItemId != row.lineItemId && el.lineItemId != null;
  //     });
  //   }
  //   let count = 1;
  //   var test = listfilter.filter(function (el) {
  //     el.lineItemSequenceId = count++;
  //     return el;
  //   });
  //   //  console.log("test- ",test);
  //   setInputList(test);
  //   rows.filter(function (element) {
  //     if (row.categorySequenceId === element.categorySequenceId) {
  //       element.lineItems = test;
  //       // console.log("in filter element ",element)
  //     }
  //   });
  //   // setDisableSave(false);
  // };
  /* ---------------------------------------------------------------- */
  const deleteLineItem = (row) => {
    console.log("INPUTLIST:", inputList);
    console.log("lineItemId:", lineItemId);

    let filterList = inputList.filter((el) => {
      return row.lineItemId != el.lineItemId;
    });
    console.log("filterList:", filterList);

    setInputList(filterList);
  };
  /* ---------------------------------------------------------------- */

  var estimateLineItemIdData = inputList.filter((el, index) => {
    return el.estimateLineItemId;
  });

  return (
    <Box
      sx={{
        overflowX: "auto",
      }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          borderBottom: "none",
          backgroundColor: "rgba(0, 0, 0, 0.03)",
        }}
      >
        {/* {loadSave && <SimpleBackdrop />} */}
        <TableBody style={{ boxShadow: "none" }}>
          <Box margin={1}>
            <div style={{ display: "flex  ", justifyContent: "space-between" }}>
              <div></div>
              <div>
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  className={classes.snackbar}
                >
                  <Alert onClose={handleClose} severity="success" variant="filled">
                    Costings saved successfully!
                  </Alert>
                </Snackbar>
                {hasValidAccess(JOB_WRITE) && (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box component="span" p={1}>
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        {/* <Button variant='contained' color='primary' onClick={handleAdd}>
                        <AddIcon onClick={handleAdd} />
                        ADD
                      </Button> */}
                        {isEditMode ? (
                          <React.Fragment>
                            {inputList.length !== 0 && (
                              <ButtonGroup
                                variant="contained"
                                aria-label="contained primary button group"
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleAdd}
                                  size="small"
                                >
                                  <AddIcon fontSize="small" />
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  align="right"
                                  onClick={handleCancel}
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handlesLineItemSave}
                                  size="small"
                                >
                                  <CheckCircleOutlineIcon fontSize="small" />
                                  SAVE
                                </Button>
                              </ButtonGroup>
                            )}
                          </React.Fragment>
                        ) : (
                          <>
                            {row.items.length === 0 ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAdd}
                                disabled={jobStatus !== "DRAFT"}
                                size="small"
                              >
                                <AddIcon fontSize="small" />
                                ADD
                              </Button>
                            ) : (
                              <ButtonGroup
                                variant="contained"
                                aria-label="contained primary button group"
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleAdd}
                                  disabled={jobStatus !== "DRAFT"}
                                  size="small"
                                >
                                  <AddIcon fontSize="small" />
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  align="right"
                                  onClick={handleEdit}
                                  disabled={jobStatus !== "DRAFT"}
                                  size="small"
                                >
                                  <CreateIcon fontSize="small" />
                                  EDIT
                                </Button>
                              </ButtonGroup>
                            )}
                          </>
                        )}
                      </ButtonGroup>
                    </Box>
                  </div>
                )}
              </div>
            </div>
            {inputList.length === 0 ? (
              <EmptyEstimatesDialog
                heading="Add some cost centre lineitems in this category"
                msg="Add some lineitems here to Save this cost centre type. You can add using the side button present on the right side the screen"
              />
            ) : (
              <Box sx={{}}>
                <Table
                  sx={{
                    borderBottom: "none",
                    display: "block",
                    overflowX: "auto",
                  }}
                  size="small"
                  aria-label="a dense table"
                  id="estimationCosting"
                  className={" estimationCosting"}
                >
                  <TableHead>
                    <TableRow>
                      {!isEdit && <TableCell sx={{ minWidth: 10 }}></TableCell>}
                      <TableCell align="center">Code</TableCell>
                      <TableCell width="20%">Name</TableCell>
                      <TableCell sx={{ minWidth: 300 }}>Description</TableCell>
                      {/* <TableCell align="center">Category</TableCell> */}
                      {/* <TableCell align="center">Option</TableCell> */}
                      <TableCell
                        className="muitable_cell_title_type"
                        align="center"
                        sx={{ minWidth: 180 }}
                      >
                        Type
                      </TableCell>
                      <TableCell className="" align="center" sx={{ minWidth: 115 }}>
                        UOM
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: 95 }}>
                        Est. Qty
                      </TableCell>
                      <TableCell align="center">Estimate UnitCost</TableCell>
                      <TableCell align="center" width="8%">
                        Est. Total
                      </TableCell>
                      {!isEditMode && <TableCell align="right">Ordered Qty</TableCell>}
                      {!isEditMode && (
                        <TableCell align="center" width="8%" sx={{ minWidth: 95 }}>
                          Actual Qty
                        </TableCell>
                      )}
                      {!isEditMode && (
                        <TableCell align="center" width="8%">
                          Actual UnitCost
                        </TableCell>
                      )}
                      {!isEditMode && (
                        <TableCell align="center" width="8%">
                          Actual Total
                        </TableCell>
                      )}
                      {isEditMode && <TableCell align="center">Markup</TableCell>}
                      {isEditMode && (
                        <TableCell align="center" sx={{ minWidth: 70 }}>
                          GST
                        </TableCell>
                      )}
                      {!isEditMode && <TableCell align="center">Variance</TableCell>}
                      <TableCell align="center">Notes</TableCell>
                      {/* <TableCell align="center">Included</TableCell> */}
                      {isEditMode && <TableCell align="center">Actions</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inputList.map((row, i) => {
                      return (
                        <React.Fragment>
                          {isEditMode ? (
                            <>
                              {row.editable ? (
                                <React.Fragment>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    key={row.lineItemId}
                                  >
                                    <TableCell></TableCell>
                                    <TableCell>
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        value={row.itemCode}
                                        name="itemCode"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    {/* <TableCell align='center'>{row.itemCode}</TableCell> */}
                                    <TableCell>
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        value={row.itemName}
                                        name="itemName"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    <TableCell width="40%">
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        size="small"
                                        value={row.itemDescription}
                                        name="itemDescription"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>

                                    {/* <TableCell align="center">{row.optionName}</TableCell> */}
                                    <TableCell align="center">
                                      <Select
                                        className={classes.textField}
                                        size="small"
                                        name="type"
                                        fullWidth
                                        value={row.type}
                                        onChange={(e) => handleInputChange(e, i, row)}
                                      >
                                        {userConfigs.config?.length != 0 &&
                                          userConfigs.config?.map(
                                            (el) =>
                                              el.enabled === true && (
                                                <MenuItem value={el.value}>{el.value}</MenuItem>
                                              )
                                          )}
                                      </Select>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Select
                                        className={classes.textField}
                                        size="small"
                                        fullWidth
                                        name="uom"
                                        value={row.uom}
                                        onChange={(e) => handleInputChange(e, i)}
                                      >
                                        {userConfigsuom.config?.length != 0 &&
                                          userConfigsuom.config?.map(
                                            (el) =>
                                              el.enabled === true && (
                                                <MenuItem value={el.value}>{el.value}</MenuItem>
                                              )
                                          )}
                                      </Select>
                                    </TableCell>

                                    <TableCell
                                      align="right"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.estimatedQty}
                                        name="estimatedQty"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.estimatedUnitCost}
                                        name="estimatedUnitCost"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>

                                    <TableCell align="right">
                                      <NumberFormat
                                        value={(row.estimatedUnitCost * row.estimatedQty).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </TableCell>
                                    {/* <TableCell align="right">{row.orderedQty} </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {row.actualQty}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {row.actualUnitCost}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.actualUnitCost * row.actualQty}
                                    </TableCell> */}
                                    {/* <TableCell
                                      align="right"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        align="right"
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.orderedQty}
                                        name="orderedQty"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell> */}
                                    {/* <TableCell
                                      align="right"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.actualQty}
                                        name="actualQty"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.actualUnitCost}
                                        name="actualUnitCost"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <NumberFormat
                                        value={(row.actualUnitCost * row.actualQty).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </TableCell> */}

                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                          minWidth: 50,
                                        },
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        type="number"
                                        value={row.markupPercentage}
                                        name="markupPercentage"
                                        onChange={(e) => handleInputChange(e, i)}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <PercentIcon
                                                style={{
                                                  fontSize: "15px",
                                                  margin: "-10px",
                                                  color: "3A3B3C",
                                                  border: "redius",
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                    // sx={{
                                    //   "& input": {
                                    //     textAlign: "right",
                                    //     minWidth: 30,
                                    //   },
                                    // }}
                                    >
                                      {row.gst === true ? (
                                        <Checkbox
                                          size="small"
                                          value={10}
                                          name="gst"
                                          defaultChecked
                                          onChange={(e) => handleChangeGST(e, i, row)}

                                        // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                        />
                                      ) : (
                                        <Checkbox
                                          size="small"
                                          name="gst"
                                          value={0}
                                          // defaultChecked
                                          onChange={(e) => handleChangeGST(e, i, row)}
                                        // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                        />
                                      )}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                      <NumberFormat
                                        value={(
                                          row.estimatedUnitCost * row.estimatedQty -
                                          row.actualUnitCost * row.actualQty
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </TableCell> */}
                                    <TableCell align="center">
                                      {" "}
                                      <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        size="small"
                                        value={row.notes}
                                        name="notes"
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <ButtonGroup variant="contained" size="small">
                                        <Button
                                          color="secondary"
                                          key={row.lineItemId}
                                          onClick={(e) => deleteLineItem(row)}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </Button>
                                      </ButtonGroup>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                      {row.included ? (
                                        <Checkbox
                                          size="small"
                                          value={true}
                                          checked
                                          name="included"
                                          onChange={(e) => handleChangeIncluded(e, i, row)}
                                        />
                                      ) : (
                                        <Checkbox
                                          size="small"
                                          value={false}
                                          name="included"
                                          onChange={(e) => handleChangeIncluded(e, i, row)}
                                        />
                                      )}
                                    </TableCell> */}
                                  </TableRow>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    key={row.lineItemId}
                                  >
                                    <TableCell></TableCell>
                                    <TableCell align="center">{row.itemCode}</TableCell>
                                    <TableCell>{row.itemName}</TableCell>
                                    <TableCell width="40%">{row.itemDescription} </TableCell>
                                    {/* <TableCell align="center"> {row.itemCategory}</TableCell> */}
                                    {/* <TableCell align="center">{row.optionName}</TableCell> */}
                                    <TableCell align="center"> {row.type}</TableCell>
                                    <TableCell align="center">{row.uom}</TableCell>
                                    <TableCell align="center">{row.estimatedQty}</TableCell>
                                    <TableCell align="right">{row.estimatedUnitCost}</TableCell>
                                    <TableCell align="right">
                                      {row.estimatedUnitCost * row.estimatedQty}
                                    </TableCell>
                                    {/* <TableCell align="right">{row.orderedQty} </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {row.actualQty}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        "& input": {
                                          textAlign: "right",
                                        },
                                      }}
                                    >
                                      {row.actualUnitCost}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row.actualUnitCost * row.actualQty}
                                    </TableCell> */}

                                    <TableCell align="center">{row.markupPercentage}</TableCell>
                                    <TableCell align="center">
                                      {row.gst === true ? (
                                        <Checkbox
                                          size="small"
                                          value={10}
                                          name="gst"
                                          defaultChecked
                                          disabled={jobStatus !== "DRAFT"}
                                        />
                                      ) : (
                                        <Checkbox
                                          size="small"
                                          name="gst"
                                          value={0}
                                          disabled={jobStatus !== "DRAFT"}
                                        />
                                      )}
                                    </TableCell>
                                    {/* <TableCell align="center">
                                      <NumberFormat
                                        value={(
                                          row.estimatedUnitCost * row.estimatedQty -
                                          row.actualUnitCost * row.actualQty
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                      />
                                    </TableCell> */}
                                    <TableCell align="center">{row.notes}</TableCell>
                                    <TableCell>
                                      <ButtonGroup variant="contained" size="small">
                                        <Button
                                          color="secondary"
                                          key={row.lineItemId}
                                          disabled={jobStatus !== "DRAFT"}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </Button>
                                      </ButtonGroup>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                      {row.included ? (
                                        <Checkbox
                                          size="small"
                                          value={true}
                                          checked
                                          disabled
                                          name="included"
                                          onChange={(e) => handleChangeIncluded(e, i, row)}
                                        />
                                      ) : (
                                        <Checkbox
                                          size="small"
                                          value={false}
                                          disabled
                                          name="included"
                                          onChange={(e) => handleChangeIncluded(e, i, row)}
                                        />
                                      )}
                                    </TableCell> */}
                                  </TableRow>
                                </React.Fragment>
                              )}
                            </>
                          ) : (
                            <React.Fragment>
                              <TableRow
                                key={row.optionId}
                                hover
                                selected={isChecked[i]?.checked}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell padding="checkbox" align="center">
                                  <Checkbox
                                    size="small"
                                    value={row.optionId}
                                    onChange={(e) => {
                                      handlePurchesOrder(e, row);
                                      if (e.target.checked) {
                                        // setIsChecked([...isChecked,{id:row.lineItemId, checked:true}])

                                        isChecked.filter((el) => {
                                          if (el.id === e.target.value) {
                                            return (el.checked = true);
                                          }
                                        });
                                      } else {
                                        isChecked.filter((el) => {
                                          if (el.id === e.target.value) {
                                            return (el.checked = false);
                                          }
                                        });
                                      }
                                      // console.log("isChecked", isChecked);
                                      // console.log("i");
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">{row.itemCode}</TableCell>
                                <TableCell>{row.itemName}</TableCell>
                                <TableCell width="40%">{row.itemDescription} </TableCell>
                                {/* <TableCell align="right">{row.optionName}</TableCell> */}
                                <TableCell align="center"> {row.type}</TableCell>
                                <TableCell align="center">{row.uom}</TableCell>
                                <TableCell align="center">
                                  {row.referenceVariations ? (
                                    <div align="center">
                                      <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        onClick={() => handleOpenVariations(row, i)}
                                      >
                                        {row.estimatedQty}
                                      </Link>
                                    </div>
                                  ) : (
                                    <div align="center">{row.estimatedQty}</div>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <NumberFormat
                                    value={(row.estimatedUnitCost * 1).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <NumberFormat
                                    value={(row.estimatedUnitCost * row.estimatedQty).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {row.referenceOrders || row.referenceWorkOrders ? (
                                    <div align="right">
                                      <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        onClick={() => handleOpenCostinRow(row, i)}
                                      >
                                        {(row.orderedQty * 1).toFixed(2)}
                                      </Link>
                                    </div>
                                  ) : (
                                    <div align="right"> {(row.orderedQty * 1).toFixed(2)}</div>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row.referenceOrders || row.referenceWorkOrders ? (
                                    <div align="right">
                                      <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        onClick={() => handleOpenCostinRow(row, i)}
                                      >
                                        {(row.actualQty * 1).toFixed(2)}
                                      </Link>
                                    </div>
                                  ) : (
                                    <div align="right"> {(row.actualQty * 1).toFixed(2)}</div>
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row.referenceOrders || row.referenceWorkOrders ? (
                                    <div align="right">
                                      <Link
                                        component="button"
                                        variant="body2"
                                        underline="none"
                                        onClick={() => handleOpenCostinRow(row, i)}
                                      >
                                        <NumberFormat
                                          value={(row.actualUnitCost * 1).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                        />
                                      </Link>
                                    </div>
                                  ) : (
                                    <NumberFormat
                                      value={(row.actualUnitCost * 1).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <NumberFormat
                                    value={(row.actualUnitCost * row.actualQty).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                </TableCell>
                                <TableCell align="centre">
                                  <div align="centre">
                                    <NumberFormat
                                      value={(
                                        row.estimatedUnitCost * row.estimatedQty -
                                        row.actualUnitCost * row.actualQty
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="right">{row.notes}</TableCell>
                                {/* <TableCell align="center">
                                  {row.included ? (
                                    <Checkbox
                                      size="small"
                                      value={true}
                                      checked
                                      disabled
                                      name="included"
                                    />
                                  ) : (
                                    <Checkbox size="small" value={false} disabled vnb name="included" />
                                  )}
                                </TableCell> */}
                              </TableRow>
                              <TableRow className="estimation-option_row">
                                <TableCell colSpan={20} padding="none">
                                  <Collapse in={openItems[i]} timeout="auto" unmountOnExit width="100%">
                                    {selectedOpenTable === "purchaseOrder" && (
                                      <Order
                                        whichOrder={selectedOpenTable}
                                        callFrom={"lineItemClick"}
                                        lineItemId={lineItemId}
                                        newlyAdded={newlyAdded}
                                      />
                                    )}
                                    {selectedOpenTable === "workOrder" && (
                                      <Order
                                        whichOrder={selectedOpenTable}
                                        callFrom={"lineItemClick"}
                                        lineItemId={lineItemId}
                                        newlyAdded={newlyAdded}
                                      />
                                    )}
                                    {selectedOpenTable === "both" && (
                                      <>
                                        <Order
                                          whichOrder={"purchaseOrder"}
                                          callFrom={"lineItemClick"}
                                          lineItemId={lineItemId}
                                          newlyAdded={newlyAdded}
                                        />
                                        <Order
                                          whichOrder={"workOrder"}
                                          callFrom={"lineItemClick"}
                                          lineItemId={lineItemId}
                                          newlyAdded={newlyAdded}
                                        />
                                      </>
                                    )}
                                    {selectedOpenTable === "variations" && (
                                      <Variation callFrom={"jobLineItem"} lineItemId={row.lineItemId} />
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </TableBody>
      </Table>
    </Box>
  );
}
