/** @format */

import React, { useEffect, useState } from "react";
import { Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import {
  deleteSelectedQuoteLineItemForEstimate,
  getQuoteResponseByQuoteId,
  getRespoDataFromAPI,
  selectQuoteLineItemForEstimate,
} from "../helpers.quoteRequestAPI";
import ProgressBar from "../../UI/ProgressBar";
import { Link } from "react-router-dom";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import MomentDate from "../../UI/MomentDate";
import MUIDataTable from "mui-datatables";
import CommonInfo from "../../info/CommonInfo";
import infoArray from "../../info/commonInfo.json";
import { QUOTE_WRITE } from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import { Chip, Tooltip, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NumberFormat from "react-number-format";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { QuoteResponseCompo } from "./QtRespoExpandCompo";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { getGst } from "../../UI/GlobalApi";

const boxCss = {
  display: "block",
  p: 1,
  m: 1,
  bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
  color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
  border: "1px solid",
  borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
  borderRadius: 2,
  fontSize: "0.875rem",
  fontWeight: "700",
};

export default function QuoteResponse(props) {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const useStyles = makeStyles((theme) => ({
    Drawerroot: {
      // width: 1000,
      padding: 16,
    },
    textField: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  /* DataTable */
  const columns = [
    {
      name: "quoteNo",
      label: "Code",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "90px", maxWidth: "90px" },
        }),
        setCellProps: () => ({ style: { minWidth: "90px", maxWidth: "90px" } }),
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "400px", maxWidth: "400px" },
        }),
        setCellProps: () => ({
          style: { minWidth: "400px", maxWidth: "400px" },
        }),
        filter: true,
        sort: true,
      },
    },
    {
      name: "estimateDescription",
      label: "Estimate",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "140px", maxWidth: "140px" },
        }),
        setCellProps: () => ({
          style: { minWidth: "140px", maxWidth: "140px" },
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Link
              className="link"
              target="_self"
              to={`/estimate/${row[tableMeta.rowIndex].estimateId}/costing`}
              style={{
                textDecoration: "underline",
              }}
              onClick={() => props.handleChange("", "/estimate/:estimateId/costing")}
            >
              {value}
            </Link>
          );
        },
        display: props.callFrom !== "estimateLineItem" ? true : false,
      },
    },
    {
      name: "sentDate",
      label: "Sent Date",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (value != null) {
            return <MomentDate getDate={value} />;
          }
        },
      },
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "130px" },
        }),
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (value === "expired") {
            return <Chip size="small" label="expired" color="secondary" />;
          } else {
            return <MomentDate getDate={value} />;
          }
        },
      },
    },
    {
      name: "status",
      label: "Status",

      options: {
        filter: true,
        sort: true,
        //setCellProps: () => ({ align: "center" }),
        customBodyRender: (value, tableMeta) => {
          if (value === "DRAFT") {
            return <Chip size="small" label="Draft" />;
          } else if (value === "REJECTED") {
            return <Chip size="small" label="Rejected" color="secondary" />;
          } else if (value === "ACCEPTED") {
            return <Chip size="small" label="Accepted" color="primary" />;
          } else if (value === "NOTIFIED") {
            return <Chip size="small" label="Rejected" style={{ backgroundColor: "#ff9800" }} />;
          } else if (value === "SENT") {
            return (
              <Chip size="small" label="Sent" style={{ color: "#fff", backgroundColor: "#2e7d32" }} />
            );
          }
        },
      },
    },

    {
      name: "responses",
      label: "Responses",
      options: {
        filter: false,
        sort: false,
        // setCellProps: () => ({ align: "center" }),
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <Chip size="small" label="Yes" color="success" sx={{ marginLeft: "20px" }} />;
          } else {
            return <Chip size="small" label="No" color="error" sx={{ marginLeft: "20px" }} />;
          }
        },
      },
    },
    {
      name: "quoteId",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              <Tooltip title="EDIT QUOTE" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  onClick={getQuoteDataById(
                    row[tableMeta?.rowIndex],
                    setOpenCustSnackbar,
                    setSnackbarMSG,
                    setSnackbarSeverity
                  )}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(QUOTE_WRITE) ? true : false,
      },
    },
  ];

  const { row, getQuoteDataById } = props;
  const [loaderForResposeForm, setLoaderForResposeForm] = useState(false);
  const [getQuoteResponse, setQuoteResponse] = React.useState([""]);
  const [getSupplierData, setSupplierData] = React.useState([""]);
  const [expiryDate, setExpiryDate] = useState("");
  const [stateResponse, setStateResponse] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  /* -------------------state for snackbar -------------*/
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [qouteId, setQuoteId] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [refreshViewDrawerData, setRefreshViewDrawerData] = useState(false);
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRows: "none",

    search: props.callFrom !== "estimateLineItem" ? true : false,
    download: props.callFrom !== "estimateLineItem" ? true : false,
    print: props.callFrom !== "estimateLineItem" ? true : false,
    viewColumns: props.callFrom !== "estimateLineItem" ? true : false,
    filter: props.callFrom !== "estimateLineItem" ? true : false,

    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },

    onTableChange: (action, state) => {},

    onRowsDelete: (rowsDeleted, newData) => {
      // handleDelete(rowsDeleted, newData);
    },
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    expandableRows: true,

    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <QuoteResponseCompo
          quoteId={row[rowMeta.rowIndex].quoteId}
          getResByQuoteIdAndContactID={getResByQuoteIdAndContactID}
          getQuoteResponseByQuoteId={getQuoteResponseByQuoteId}
          setOpenCustSnackbar={setOpenCustSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          refreshData={refreshData}
          setData={setData}
          isCameFromNavigation={props.isCameFromNavigation}
          estimateStatus={props.estimateStatus}
        />
      );
    },
  };
  // For Quote response
  const [quoteDataForEstimate, setQuoteDataForEstimate] = useState();
  const getResByQuoteIdAndContactID = (quoteResponseItemSelect) => (event) => {
    setLoaderForResposeForm(true);
    console.log("get_data ", quoteResponseItemSelect);
    setQuoteDataForEstimate(quoteResponseItemSelect);
    getRespoDataFromAPI(quoteResponseItemSelect, setSupplierData, setLoaderForResposeForm);

    setStateResponse({ ...stateResponse, ["right"]: true });
  };

  useEffect(() => {
    console.log("refreshViewDrawerData usefee");
    if (refreshViewDrawerData) {
      getRespoDataFromAPI(quoteDataForEstimate, setSupplierData, setLoaderForResposeForm);
    }
    setRefreshViewDrawerData(false);
    refreshData ? setRefreshData(false) : setRefreshData(true);
  }, [refreshViewDrawerData]);

  useEffect(() => {
    console.log("data usefee");
    data.filter((item) => {
      if (item.quoteId === quoteDataForEstimate?.quoteId) {
        setQuoteDataForEstimate(item);
      }
    });
  }, [data]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setStateResponse({ ...stateResponse, [anchor]: open });
  };

  const list = () => (
    <Box p={2}>
      {/* <Typography variant='h6' gutterBottom component='div'>
        Responses
      </Typography> */}
      {loaderForResposeForm ? (
        <div>
          <br />
          <ProgressBar />
        </div>
      ) : (
        <Grid spacing={3}>
          <Grid item xs={12} textAlign={"center"}>
            <Typography
              component="h4"
              color="primary"
              mb={2}
              style={{
                fontSize: "20px",
              }}
            >
              View Quote Response
            </Typography>

            <Divider />
          </Grid>

          <Grid
            // container
            // spacing={0}
            // mt={2}
            mb={1}
            // direction="column"
            // justifyContent="center"
            // alignItems="flex-end"
          >
            <Grid item xs={12} spacing={4}>
              <Table
                size="small"
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell align="left" sx={{ py: "15px" }}>
                      <b>Name:</b>
                    </TableCell>
                    <TableCell align="right" sx={{ py: "15px" }}>
                      {getSupplierData.contactName}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left" sx={{ py: "15px" }}>
                      <b>Business Name:</b>
                    </TableCell>
                    <TableCell align="right" sx={{ py: "15px" }}>
                      {getSupplierData.businessName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ py: "15px" }}>
                      <b> Email:</b>
                    </TableCell>
                    <TableCell align="right" sx={{ py: "15px" }}>
                      {getSupplierData.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ py: "15px" }}>
                      <b>Sent Date:</b>
                    </TableCell>
                    <TableCell align="right" sx={{ py: "15px" }}>
                      {getSupplierData.sentDate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" sx={{ py: "15px" }}>
                      <b>Total Amount:</b>
                    </TableCell>
                    <TableCell align="right" sx={{ py: "15px" }}>
                      <NumberFormat
                        value={(getSupplierData.totalAmount * 1).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              display: "block",
              overflowX: "auto",
            }}
          >
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="center">UOM</TableCell>
                    <TableCell align="right">Unit Cost</TableCell>
                    <TableCell align="center">GST</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getSupplierData.responseLineItems?.map((quoteLineItemsrow, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{quoteLineItemsrow.description}</TableCell>
                      <TableCell align="center">{quoteLineItemsrow.type}</TableCell>
                      <TableCell align="right">{quoteLineItemsrow.qty}</TableCell>
                      <TableCell align="center">{quoteLineItemsrow.uom}</TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={(quoteLineItemsrow.unitCost * 1).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={(getGst(quoteLineItemsrow.gst) * 1).toFixed(1)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix="%"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {quoteDataForEstimate.status === "PARTIALLY_SELECTED" ||
                        quoteDataForEstimate.status === "SELECTED" ? (
                          <Tooltip
                            title="Remove From Estimate"
                            placement="top"
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <ButtonGroup variant="contained" size="small">
                              <Button
                                color="secondary"
                                size="small"
                                onClick={() =>
                                  deleteSelectedQuoteLineItemForEstimate(
                                    quoteDataForEstimate,
                                    quoteLineItemsrow,
                                    setOpenCustSnackbar,
                                    setSnackbarMSG,
                                    setSnackbarSeverity,
                                    setRefreshData,
                                    setRefreshViewDrawerData
                                  )
                                }
                                // disabled={quoteResponseItem.status === "SELECTED" ? true : false}
                              >
                                <CancelIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Add In Estimate"
                            placement="top"
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <ButtonGroup variant="contained" size="small">
                              <Button
                                color="primary"
                                size="small"
                                onClick={() =>
                                  selectQuoteLineItemForEstimate(
                                    quoteDataForEstimate,
                                    quoteLineItemsrow,
                                    setOpenCustSnackbar,
                                    setSnackbarMSG,
                                    setSnackbarSeverity,
                                    setRefreshData,
                                    setRefreshViewDrawerData
                                  )
                                }
                                disabled={props.estimateStatus !== "DRAFT" ? true : false}
                              >
                                <CheckCircleIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              container
              spacing={0}
              mt={2}
              mb={1}
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item xs={4}>
                <Table
                  size="small"
                  sx={{
                    borderBottom: "none",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        {"Sub Total:"}
                      </TableCell>
                      <TableCell align="right">
                        {
                          <NumberFormat
                            value={(finalSubTotal * 1).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        {"GST:"}
                      </TableCell>
                      <TableCell align="right">
                        {
                          <NumberFormat
                            value={(finalTax * 1).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        <b>Total:</b>
                      </TableCell>
                      <TableCell align="right">
                        {
                          <NumberFormat
                            value={(finalTotal * 1).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} align="right" my={2}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.button}
              onClick={toggleDrawer("right", false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );

  // ----------------------------Calculations-For-QuoteResponce-View-drawer-------------------------------------------------
  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;
  getSupplierData.responseLineItems?.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (isNaN(mult)) mult = 0;
    finalSubTotal = finalSubTotal + mult;
    itemTax = (mult * getGst(e.gst)) / 100;
    if (isNaN(itemTax)) itemTax = 0;
    finalTax = finalTax + parseInt(itemTax);
    finalTotal = finalSubTotal + finalTax;
  });
  // ----------------------------Calculations-Ends-------------------------------------------------

  return (
    <React.Fragment>
      {/* here */}
      <Grid container spacing={3}>
        <Grid item xs={12} mt={5}>
          <Drawer
            anchor={"right"}
            open={stateResponse["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: "35%",
              },
            }}
          >
            {list()}
          </Drawer>
          {row.length === 0 ? (
            <EmptyEstimatesDialog
              heading="Empty Request For Quotes"
              msg="You don't have any Request For Quotes here yet.
                    Please create "
            />
          ) : (
            <MUIDataTable data={row} columns={columns} options={options} />
          )}
        </Grid>
        {props.callFrom === "quoteRequests" && (
          <Grid item xs={12}>
            <CommonInfo title={infoArray.quotes.title} msg={infoArray.quotes.msg} />
          </Grid>
        )}
      </Grid>
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}
