/** @format */

import * as React from "react";
import Card from "@mui/material//Card";
import CardActions from "@mui/material//CardActions";
import CardContent from "@mui/material//CardContent";
import Button from "@mui/material//Button";
import Typography from "@mui/material//Typography";
import { Chip, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function CommonInfo({ title, msg }) {
  const [isVisible, setIsVisible] = React.useState(true);
  if (isVisible) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={10} md={6} sm={6}>
          <Card sx={{ minWidth: 100 }}>
            <div style={{ paddingTop: "7px", paddingLeft: "7px" }}>
              <Chip icon={<InfoIcon />} label={title} />
            </div>
            <CardContent>
              <br />
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {msg}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                Close
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
}
