/** @format */

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ProgressBar from "../UI/ProgressBar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
// import { CKEditor } from 'ckeditor4-react';
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import { createUser, getRolesForUser, getUser, updateUser } from "./users.helper";
import { Box, Snackbar } from "@mui/material";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SimpleBackdrop from "../UI/SimpleBackdrop";
// import AlertPopContact from './AlertPopContact';
import { useForm, Controller } from "react-hook-form";
import MUIDataTable from "mui-datatables";
import { getRoles } from "../roles/Roles.helper";
import { Tooltip, Zoom, InputAdornment } from "@mui/material";
import { Autocomplete } from "@mui/material";
import DeleteAlertPop from "./DeleteAlertPop";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { USER_AND_ROLE_DELETE, USER_AND_ROLE_WRITE } from "../UI/GlobalVariables";
import ErrorStyles from "../UI/ErrorStyles";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  box: {
    display: "flex",
  },

  // button: { padding: "7px", paddingLeft: "9px" },
}));

export default function Contacts() {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const ErrorClasses = ErrorStyles();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  /* DataTable */
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "username",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "10px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value) => {
          if (value) {
            return <div>{formatPhone(value)}</div>;
          } else {
            return <div>Null</div>;
          }
        },
      },
    },

    {
      name: "username",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (username, tableMeta) => {
          return (
            <ButtonGroup variant="contained">
              {hasValidAccess(USER_AND_ROLE_WRITE) && (
                <>
                  <Tooltip title="EDIT USER" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleUpdate(tableMeta.rowIndex)}
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="DELETE USER" placement="top" TransitionComponent={Zoom} arrow>
                    <Button
                      size="small"
                      color="secondary"
                      value={username}
                      onClick={(e) => handleDelete(username)}
                    >
                      <DeleteIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                </>
              )}
            </ButtonGroup>
          );
        },
      },
    },
  ];
  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [selectableRows, setSelectableRows] = useState(false);
  const [roundLoader, setRoundLoader] = useState(false);
  const formatPhone = (phoneNumber) => {
    return `+61 ${phoneNumber}`;
  };
  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    // selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRowsOnClick: false,
    selectableRows: false,
    // selectableRowsOnClick: false,
    // selectableRows: "single",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    // onRowClick: (rowData) => {
    //   console.log("rorowData", rowData);
    // },
    onTableChange: (action, state) => {
      if (action === "rowsdelID") {
      }
      // console.log(action);
      // console.dir(state);
    },

    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   console.log(rowsSelectedData, allRows, rowsSelected, "data", data[rowsSelected]);
    //   // this.setState({ rowsSelected: rowsSelected });
    // },
  };
  const [load, setLoad] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();

  const [loading, setLoading] = useState(false);
  const [isBtnDisbl, setIsBtnDisbl] = useState(true);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSnackbar(false);
  };
  const handleUpdate = (arrayIndex) => {
    /* this function used for open toggle */
    commonToggle(true);
    setIsBtnDisbl(false);
    /* reset is method of useForm it is used for returning data to form */
    console.log(userData[arrayIndex]);
    reset(userData[arrayIndex]);
  };

  /* we can call this commonToggle function for open and close toggleDrawer*/
  function commonToggle(open) {
    setState({ ...state, ["right"]: open });
  }
  /*------------------------------------------------------------ */
  useEffect(() => {
    getUser(setUserData, setLoad);
    getRolesForUser(setRoles);
    if (hasValidAccess(USER_AND_ROLE_DELETE)) {
      setSelectableRows("single");
    }
  }, [wantToCallGet]);

  /* -----------------------Delete user -------------------------------- */

  const [deleteUser, setDeleteUser] = useState();

  const handleDelete = (username) => {
    setDeleteUser({ username });
    setConfirmDelete(true);
  };
  /* --------------------Submit user Details ------------------------------ */

  const submitContactDetails = (formData) => {
    console.log(formData);

    createUser(
      formData,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setWantToCallGet,
      wantToCallGet,
      commonToggle,
      setLoading
    ); //API call For Submit
  };

  /* -------------------------------------------------------------------------- */
  const updtaeContactDetails = () => {
    let userDetail = getValues();
    updateUser(
      userDetail,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setWantToCallGet,
      wantToCallGet,
      setLoading,
      commonToggle
    ); //API call For Update
  };
  /* -------------------------------------------------------------------------- */

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  /* -------------------------------------------------------------------------- */
  const handleEmptySubmitForm = () => {
    commonToggle(true);
    setIsBtnDisbl(true);
    let row = {};
    reset(row);
  };
  /* -------------------------------------------------------------------------- */

  /* Form Validation */
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  /* -------------------------------------------------------------------------- */
  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  /* -------------------------------------------------------------------------- */

  return (
    <React.Fragment key={"right"}>
      {loading && <SimpleBackdrop />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("left", false)}
            PaperProps={{
              sx: {
                width: "30%",
              },
            }}
          >
            <div className={classes.Drawerroot}>
              <form onSubmit={handleSubmit(submitContactDetails)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isBtnDisbl ? (
                      <Typography align="left" mb={1} color="primary" variant="h5">
                        New User
                      </Typography>
                    ) : (
                      <Typography variant="h5" mb={1} color="primary" align="left">
                        Update User
                      </Typography>
                    )}
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {isBtnDisbl ? (
                      <>
                        <Controller
                          name="username"
                          control={control}
                          rules={{
                            required: true,
                            pattern: /^[a-z0-9]{3,20}$/,
                          }}
                          className="input"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              label="User Name"
                              size="small"
                              className={classes.textField}
                            />
                          )}
                        />
                        <p className={ErrorClasses.errorMsg}>
                          {errors.username?.type === "required" && "UserName is required"}
                          {errors.username?.type === "pattern" && "please enter valid UserName"}
                        </p>
                      </>
                    ) : (
                      <Controller
                        name="username"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            disabled
                            size="small"
                            label="User Name"
                            className={classes.textField}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: false }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Name"
                          variant="outlined"
                          size="small"
                          className={classes.textField}
                        />
                      )}
                    />
                    {/* {errors.businessName && 'Business name is required'} */}
                  </Grid>
                  {isBtnDisbl && (
                    <Grid item xs={12}>
                      <Controller
                        name="temporaryPassword"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/,
                          minLength: 8,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="password"
                            size="small"
                            label="Temporary Password"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.temporaryPassword?.type === "required" &&
                          "Temporary Password is required"}
                        {errors.temporaryPassword?.type === "pattern" && "Please set valid password"}
                        {errors.temporaryPassword?.type === "minLength" &&
                          "Your password must be at least 8 characters"}
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Controller
                      name="roles"
                      id="tags-standard"
                      control={control}
                      rules={{ required: true }}
                      className="input"
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          onChange={(event, newValue) => {
                            console.log("onChange", newValue);
                            field.onChange(newValue);
                          }}
                          // value={field.value}
                          options={roles.map((option) => option.role)}
                          //getOptionLabel={(option) => option.role}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" size="small" label="Role" />
                          )}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>{errors.roles && "role required"}</p>
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: true,
                        minLength: 10,
                        maxLength: 12,
                      }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Phone Number"
                          size="small"
                          variant="outlined"
                          className={classes.textField}
                          rules={{
                            required: false,
                            minLength: 9,
                            maxLength: 9,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                          }}
                          onChange={(e) => {
                            const formattedNumber = formatPhoneNumber(e.target.value);
                            field.onChange(formattedNumber);
                          }}
                          value={field.value}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.phoneNumber?.type === "required" && "Phone Number required"}

                    </p>
                    {/* {errors.phoneNumber?.type === 'maxLength' && 'Please enter valid Mobile number'} */}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="phoneNumberVerified"
                      control={control}
                      rules={{ required: false }}
                      defaultValue={true}
                      // className='input'
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} onChange={field.onChange} checked={field.value} />
                          }
                          label="Phone Number Verified"
                        />
                      )}
                    />
                    {/* {errors.registeredForGst && ' Phone Number Verified required'} */}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      }}
                      className="input"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Email"
                          size="small"
                          onChange={field.onChange}
                          variant="outlined"
                          className={classes.textField}
                        />
                      )}
                    />
                    <p className={ErrorClasses.errorMsg}>
                      {errors.email?.type === "required" && "Email required"}
                      {errors.email?.type === "pattern" && "Please enter a valid email"}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="emailVerified"
                      control={control}
                      rules={{ required: false }}
                      defaultValue={true}
                      className="input"
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={field.onChange}
                              name="emailVerified"
                              checked={field.value}
                            />
                          }
                          label="Email Verified"
                        />
                      )}
                    />
                    {/* {errors.emailVerified && ' Email Verified required'} */}
                  </Grid>
                  <Grid item xs={12} align="right">
                    {isBtnDisbl ? (
                      <Button color="primary" size="small" type="submit" variant="contained">
                        save
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        size="small"
                        color="primary"
                        onClick={updtaeContactDetails}
                        variant="contained"
                      >
                        Update
                      </Button>
                    )}
                    <Button
                      type="button"
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={toggleDrawer("right", false)}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Drawer>

          {hasValidAccess(USER_AND_ROLE_WRITE) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="small"
                onClick={(e) => handleEmptySubmitForm(e)}
                startIcon={<AddCircleSharpIcon />}
              >
                New User
              </Button>
            </div>
          )}
          <div style={{ marginTop: "19px" }}>
            {load ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {userData.length === 0 ? ( //if contacts are empty then empty msg is shown
                  <EmptyEstimatesDialog
                    heading="Empty userData"
                    msg="You don't have any userData here yet.
Click the 'NEW CLIENT' button above to get started, or choose from a template"
                  />
                ) : (
                  <MUIDataTable data={userData} columns={columns} options={options} />
                )}
              </React.Fragment>
            )}
          </div>
          <DeleteAlertPop
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMSG={setSnackbarMSG}
            setSnackbarSeverity={setSnackbarSeverity}
            deleteUserName={deleteUser}
            setUserData={setUserData}
            userData={userData}
            setLoading={setLoading}
          />
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
