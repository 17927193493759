import React, { useState } from 'react';
import { 
	isLoggedIn,
	getAccessToken,
	getIdToken, 
	getExpireBy,
	setLoggedIn,
	setAccessToken,
	setIdToken, 
	setExpireBy,
	clearSession,
	setPermissions,
	getPermissions,
} from "../utils/storage";


const useAuthContext = () => {
	const[redirectUrl] = useState(process.env.REACT_APP_OAUTH_AUTHORIZE_ENDPOINT+'?client_id='+process.env.REACT_APP_OAUTH_CLIENT_ID+'&response_type=code&scope='+process.env.REACT_APP_OAUTH_SCOPE+'&redirect_uri=' + process.env.REACT_APP_OAUTH_CALLBACK_URL)
	const[logoutUrl] = useState(process.env.REACT_APP_OAUTH_LOGOUT_ENDPOINT+'?client_id='+process.env.REACT_APP_OAUTH_CLIENT_ID+'&response_type=code&scope='+process.env.REACT_APP_OAUTH_SCOPE+'&redirect_uri=' + process.env.REACT_APP_OAUTH_CALLBACK_URL)
	const getAuthState = () => {
		var authState = {
			isLoggedIn: isLoggedIn(),
			id_token: getIdToken(),
			access_token: getAccessToken(),
			expire_by: getExpireBy()
		}

		if(authState.isLoggedIn === false || new Date() > new Date(authState.expire_by)) {
			window.location.replace(redirectUrl);
		}
		return authState;
	}

	const logout = () => {
        clearSession();
        window.location.replace(logoutUrl);
    }

	const updateAuthState = (authState) => {
		setLoggedIn(authState.isLoggedIn);
		setAccessToken(authState.access_token);
		setIdToken(authState.id_token);
		setExpireBy(authState.expire_by);
	}

	const updatePermissions = (permissions) => {
		setPermissions(permissions);
	}

	const hasValidAccess = (requiredPermission) => {
		var split_permissions = getPermissions().split(",");
		if (split_permissions.indexOf(requiredPermission) !== -1 || split_permissions.indexOf('admin') !== -1) {
			return true;
		} else {
			return false;
		}
	}
	
	return {
		redirectUrl,
		getAuthState,
		updateAuthState,
		logout,
		updatePermissions,
		hasValidAccess
	};
};

export const OAuthCtx = React.createContext({});

export default function OAuthProvider({ children }) {
	return (
		<OAuthCtx.Provider value={useAuthContext()}>{children}</OAuthCtx.Provider>
	);
}

