/** @format */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCostCentres } from "./costcenter.helpers";
import CreateCostCentre from "./CreateCostCentre";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import ProgressBar from "../UI/ProgressBar";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import { Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import EditIcon from "@mui/icons-material/Edit";
import AlertDialogCostCentre from "./AlertDialogCostCentre";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Tooltip, Zoom } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OAuthCtx } from "../../oauth/OAuthContext";
import {
  COST_CENTRE_DELETE,
  COST_CENTRE_WRITE,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
} from "../UI/GlobalVariables";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import MUIDataTable from "mui-datatables";
import CostCentreExpandForJobPlans from "./CostCentreExpandForJobPlans";
import { deleteJobPlabyID, getJobPlanByCostCentreId } from "./job-plan/jobplan.helpers";

const useStyles = makeStyles({
  table: {
    // minWidth: 250,
  },
  oneRow: {
    // borderBottom: "none",
    // width: "10vw",
  },
  rowDark: {
    // color: "#fff",
    // fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    // cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
});
export default function CostCenterHome() {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  /* DataTable */
  const columns = [
    {
      name: "costCentreNo",
      label: "Code",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: 50 },
        }),
      },
    },
    {
      name: "costCentreName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: 200 },
        }),
      },
    },
    {
      name: "costCentreDescription",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { width: "100%", minWidth: 300 },
        }),
      },
    },

    {
      name: "costCentreId",
      label: "Action",

      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: { minWidth: "20px" },
          align: "right",
        }),
        setCellHeaderProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta) => {
          return costCentres[tableMeta.rowIndex].editable ? (
            <ButtonGroup variant="contained" aria-label="outlined primary button group" align="right">
              <Tooltip title="EDIT COST CENTER" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  // to={`/cost-centre/${value}/items`}
                  onClick={() => history.push(`/cost-centre/${value}/items`)}
                  size="small"
                  color="primary"
                  style={{ borderTopRightRadius: "none" }}
                  // newId={row.costCentreId}
                  // onClick={(e) => handleLocation(row.costCentreId)}
                  // value={row.costCentreId}>
                  // {" "}
                >
                  <EditIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="COPY COST CENTER" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  style={{
                    border: "1px solid #1976d2",
                    backgroundColor: "#EDF5FD",
                  }}
                  size="small"
                  onClick={(e) => {
                    handleCopyFromCostCentre(e, costCentres[tableMeta.rowIndex]);
                  }}
                >
                  <FileCopyIcon fontSize="small" style={{ color: "#1976d2" }} />
                </Button>
              </Tooltip>
              <Tooltip title="DELETE COST CENTER" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="secondary"
                  value={value}
                  onClick={(e) => handleDeleteIcon(e, value)}
                >
                  <DeleteIcon value={value} fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained" size="small">
              <Tooltip title="VIEW COST CENTER" placement="top" TransitionComponent={Zoom} arrow>
                <Button
                  size="small"
                  color="primary"
                  target="_self"
                  //to={`/job-plan/${jobPlanId}/items`}
                  onClick={() => history.push(`/cost-centre/${value}/items`)}
                >
                  <VisibilityIcon fontSize="small" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          );
        },
        display: hasValidAccess(COST_CENTRE_WRITE) ? true : false,
      },
    },
  ];

  const classes = useStyles();
  const [costCentres, setCostCentres] = useState([]);
  const [jobPlanResponse, setJobPlanResponse] = useState([]);
  const [costCentreName, setCostCentreName] = useState("");
  const [costCentreDescription, setCostCentreDescription] = useState("");
  const [id, setId] = useState();
  const [delID, setDelId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [load, setLoad] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [wantToCallGet, setWantToCallGet] = useState(false);
  const [loadResponse, setLoadResponse] = useState(false);
  const [defaultObjForAutocomplete, setDefaultObjForAutocomplete] = useState(null);
  /* useState for DataTable */
  const [data, setData] = useState([]);
  const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("auto");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [print, setPrint] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 15, 20]);
  const [qouteId, setQuoteId] = useState();
  const [roundLoader, setRoundLoader] = useState(false);

  const options = {
    viewColumns: viewColumnBtn,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRowsOnClick: true,
    responsive,
    print,
    rowsPerPageOptions,
    rowsPerPage,
    selectableRows: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    // selectableRowsHideCheckboxes: false,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF",
      },
    },
    onTableChange: (action, state) => {},
    onRowsDelete: (rowsDeleted, newData) => {
      // handleDelete(rowsDeleted, newData);
    },
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    expandableRows: true,

    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <CostCentreExpandForJobPlans
          costCentreId={costCentres[rowMeta.rowIndex].costCentreId}
          setOpenCustSnackbarCost={setOpenCustSnackbar}
          setSnackbarMSGCost={setSnackbarMSG}
          setSnackbarSeverityCost={setSnackbarSeverity}
        />
      );
    },
  };
  /* ------------------------For Toggle enable disable--------------------------- */
  const [stateForCreateCostCentre, setStateForCreateCostCentre] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  /*------------------------States for CustomizedSnackbars------------------------ */
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  /* ---------------------------------------------------------------- */
  var today = new Date();
  var now = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear();
  const [date, setDate] = useState(now);
  const [dataForCostAuto, setDataForCostAuto] = useState([]);
  const history = useHistory();
  /* ---------------------------------------------------------------- */

  useEffect(() => {
    getCostCentres(setCostCentres, setDataForCostAuto, setLoad); //get costCentres
  }, [wantToCallGet]);
  /* ---------------------------------------------------------------- */
  const [confirmDeleteNew, setConfirmDeleteNew] = useState(false);
  const [jobplanId, setJobPlanID] = useState();

  /* ---------------------------------------------------------------- */

  const handleDeleteIcon = (e, costCentreId) => {
    console.log("row", costCentreId);
    e.preventDefault();
    setConfirmDeleteNew(true);
    setDelId(costCentreId);
  };
  /* ---------------------------------------------------------------- */

  const [openLineItemRow, setOpenLineItemRow] = React.useState([]);
  const [newCostCentreId, setNewCostCentrID] = useState();
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(false);
  /* ---------------------------------------------------------------- */

  useEffect(() => {
    if (wantToCallGetJobPlan) {
      getJobPlanByCostCentreId(setJobPlanResponse, newCostCentreId, setLoadResponse);
      setWantToCallGetJobPlan(false);
    }
  }, [wantToCallGetJobPlan]);
  // /* ---------------------------------------------------------------- */

  // const handleOpenResponse = (row, index) => {
  //   if (openLineItemRow[index]) {
  //     setOpenLineItemRow({ [index]: false });
  //   } else {
  //     setOpenLineItemRow({ [index]: true });
  //     setNewCostCentrID(row.costCentreId);
  //     getJobPlanByCostCentreId(setJobPlanResponse, row.costCentreId, setLoadResponse);
  //   }
  // };

  /* ---------------------------------------------------------------- */

  const handleCopyFromCostCentre = (e, row) => {
    setCostCentreName("");
    setCostCentreDescription("");
    setDefaultObjForAutocomplete(row);
    setStateForCreateCostCentre({
      ...stateForCreateCostCentre,
      ["right"]: true,
    });
  };
  /* ---------------------------------------------------------------- */

  return (
    <React.Fragment>
      {roundLoader && <SimpleBackdrop />}
      {delLoading && <SimpleBackdrop />}
      {hasValidAccess(COST_CENTRE_WRITE) && (
        <CreateCostCentre
          costCentreName={costCentreName}
          costCentreDescription={costCentreDescription}
          setCostCentreName={setCostCentreName}
          setCostCentreDescription={setCostCentreDescription}
          costCentres={dataForCostAuto}
          setWantToCallGet={setWantToCallGet}
          wantToCallGet={wantToCallGet}
          setOpenCustSnackbar={setOpenCustSnackbar}
          setSnackbarMSG={setSnackbarMSG}
          setSnackbarSeverity={setSnackbarSeverity}
          stateForCreateCostCentre={stateForCreateCostCentre}
          setStateForCreateCostCentre={setStateForCreateCostCentre}
          defaultObjForAutocomplete={defaultObjForAutocomplete}
          setDefaultObjForAutocomplete={setDefaultObjForAutocomplete}
          setRoundLoader={setRoundLoader}
        />
      )}

      {load ? (
        <ProgressBar />
      ) : (
        <React.Fragment>
          {costCentres.length === 0 ? ( //if costCentres are empty then empty msg is shown
            <EmptyEstimatesDialog
              heading="Empty Cost Centres"
              msg="You don't have any cost centres here yet.
Click the 'CREATE NEW' button above to get started, or choose from a template"
            />
          ) : (
            <MUIDataTable data={costCentres} columns={columns} options={options} />
          )}
        </React.Fragment>
      )}
      {/* )} */}
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
      <AlertDialogCostCentre
        confirmDelete={confirmDeleteNew}
        setConfirmDelete={setConfirmDeleteNew}
        delID={delID}
        costCentres={costCentres}
        setCostCentres={setCostCentres}
        setDelLoading={setDelLoading}
        setWantToCallGet={setWantToCallGet}
        wantToCallGet={wantToCallGet}
        setOpenCustSnackbar={setOpenCustSnackbar}
        setSnackbarMSG={setSnackbarMSG}
        setSnackbarSeverity={setSnackbarSeverity}
        setRoundLoader={setRoundLoader}
      />
    </React.Fragment>
  );
}
