/** @format */

import React from "react";
// import CssBaseline from "@material/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Success from "../../../images/Success.gif";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";

function QuoteSentSuccessfully() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  return (
    <>
      <Container maxWidth="md" style={{ maxHeight: "100%" }} alig>
        <Box sx={{ width: "100%", height: "95%", paddingLeft: "5%" }}>
          <img src={Success} width="90%" height="95%" />
        </Box>
      </Container>
      <CustomizedSnackbars
        openSnackbar={true}
        setOpenSnackbar={setOpenSnackbar}
        msg="You have successfully sent the quotes"
        severity="success"
      />
    </>
  );
}

export default QuoteSentSuccessfully;
