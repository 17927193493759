/** @format */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteUser } from "./users.helper";

export default function AlertPopContact({
  confirmDelete,
  setConfirmDelete,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  deleteUserName,
  setUserData,
  userData,
  setLoading,
}) {
  const handleClose = () => {
    setConfirmDelete(false);
  };
  const handleYes = () => {
    deleteUser(
      deleteUserName,
      setOpenSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      userData,
      setUserData,
      setLoading
    );

    setConfirmDelete(false);
  };
  const handleNo = () => {
    setConfirmDelete(false);
  };
  return (
    <div>
      <Dialog
        open={confirmDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
