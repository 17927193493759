import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import { MuiThemeProvider, makeStyles } from "@mui/styles";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { registerLicense } from "@syncfusion/ej2-base";
// use default theme
const theme = makeStyles({
  palette: {
    secondary: {
      main: "#ccc",
    },
  },
});
registerLicense(
  "Mgo+DSMBaFt+QHJqVk1nQ1BBaV1CX2BZell1Qmldek4BCV5EYF5SRHBeR11nSnlXcEBkUHk=;Mgo+DSMBPh8sVXJ1S0R+X1pDaV5HQmFJfFBmQWlaeFR0cUU3HVdTRHRcQltiTH5XdUJnX3lednI=;ORg4AjUWIQA/Gnt2VFhiQlJPcUBDWHxLflF1VWdTfFl6cFBWESFaRnZdQV1lSHtTckZkXXlYcnZT;MjQyNDI1M0AzMjMxMmUzMDJlMzBaWndBN0x4L0UvY1R6RHcxTU0zdzJRdllRdzErSm5ySzFWc29DbEJveC9VPQ==;MjQyNDI1NEAzMjMxMmUzMDJlMzBXbGgrcGcrQThUTENFV3ZjWXFUZ1g2MmpJU3BMZUdqT0ZjenprdUU0TGRzPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfVhdXGZWfFN0RnNddV13flFDcC0sT3RfQF5jT39WdkFgXntYdn1XQA==;MjQyNDI1NkAzMjMxMmUzMDJlMzBJMUtTSllTTjZtZTl3ZndCR1R3RUtlVjFzYnlnblhMTkdBZEh3SmsxQTlRPQ==;MjQyNDI1N0AzMjMxMmUzMDJlMzBvVGthUUE2UFFHblhNRHlFN0s0clhTUXljZ2IyTHFoVlo0Z3FINlhhQmtvPQ==;Mgo+DSMBMAY9C3t2VFhiQlJPcUBDWHxLflF1VWdTfFl6cFBWESFaRnZdQV1lSHtTckZkXXlZdnJT;MjQyNDI1OUAzMjMxMmUzMDJlMzBQd0xGYW81NU9XbzJZcFBjREFMT3BZczl0NXlwdFE5ZUExL1lDZUlRcnJBPQ==;MjQyNDI2MEAzMjMxMmUzMDJlMzBYMjlncmRTWkFsc3U3azBSeTlWOTF4S1dubEF5bk5obHVoMExvenFVcm9RPQ==;MjQyNDI2MUAzMjMxMmUzMDJlMzBJMUtTSllTTjZtZTl3ZndCR1R3RUtlVjFzYnlnblhMTkdBZEh3SmsxQTlRPQ=="
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
