/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import {
  createQuoteRequest,
  getQuoteRequest,
  getQuoteDataAPI,
  updateQuoteRequest,
  getQuoteRequestEstimateId,
  getEstimateLineItemList,
} from "../helpers.quoteRequestAPI";
import QuoteResponse from "../quoteresponse/QuoteResponse";
import ProgressBar from "../../UI/ProgressBar";
import { getContacts } from "../helpers.quoteRequestAPI";
import { useLocation, useParams } from "react-router-dom";
import { getEstimatesbyID } from "../../estimates/estimates.helpers";
import CustomizedSnackbars from "../../UI/CustomizedSnackbars";
import { getUserConfigsTypes, getUserConfigsUom } from "../../UI/GlobalApi";
import EditQuoteDrawer from "./EditQuoteDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  button: {
    marginRight: "5px",
  },
}));

export default function Quoterequests(props) {
  var { estimateQuoteId } = useParams();
  var { estimateId } = useParams();
  var estimateQuoteId = estimateQuoteId;
  const [contacts, setContacts] = useState([]);
  const [secondLoader, setSecondLoader] = useState(true);
  const [firstLoader, setFirstLoader] = useState(true);
  const [wantToCallGet, setWantToCallGet] = useState(true);
  const [contactWantToGet, setContactWantToGet] = useState(false);
  const [firstSnackbar, setFirstSnackbar] = React.useState(false);
  const [estimateData, setEstimateData] = useState([]);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [userConfigs, setUserConfigs] = useState({});
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const [quoteState, setQuoteState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });
  useEffect(() => {
    if (contactWantToGet) {
      console.log("contact");
      getContacts(setContacts);
      setContactWantToGet(false);
    }
  }, [contactWantToGet]);
  /* ----------------------------------------------------------- */

  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    quote_title: "",
  });

  /* ---------------------------------------------------------------- */

  const [localQuoteItemList, setLocalQuoteItemList] = useState([]);
  const [apiQuoteItemList, setApiQuoteItemList] = useState([]);
  const [recipientsEmail, setRecipientsEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [getQuoteData, setGetQuoteData] = useState([]);
  const [getQuoteDataID, setGetQuoteDataID] = useState([]);
  const [estmtLineItemList, setEstmtLineItemList] = useState([]);
  const [tempEstmtLineItemList, setTempEstmtLineItemList] = useState([]);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refreshQuote, setRefreshQuote] = useState(false);

  /* ---------------------------------------------------------------- */

  const getQuoteDataById =
    (row, setOpenCustSnackbar, setSnackbarMSG, setSnackbarSeverity) => (event) => {
      getContacts(setContacts);
      setState({ ...state, ["right"]: true });
      console.log("This is data from Parent Component to the Child Component.", row);
      console.log(row.quoteId);
      setLineItemError("");
      getUserConfigsTypes(setUserConfigs); //get all configs Types
      getUserConfigsUom(setUserConfigsuom, setDummyLoad);
      getQuoteDataAPI(
        row.quoteId,
        setGetQuoteDataID,
        setLocalQuoteItemList,
        setApiQuoteItemList,
        setSecondLoader
      ); //get quote
      console.log("userConfigs", userConfigs);
      getEstimateLineItemList(estimateId, setTempEstmtLineItemList, setEstmtLineItemList);
      setIsEdit(false);
    };

  /* ---------------------------------------------------------------- */
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const newQuoteDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setQuoteState({ ...quoteState, [anchor]: open });
  };

  const openNewQuote = () => {
    setQuoteState({ ...quoteState, ["right"]: true });
    getEstimatesbyID(estimateId, setEstimateData);
    getContacts(setContacts);
    setSecondLoader(false);
    setRecipientsEmail([]);
    setLocalQuoteItemList(...[], props.createQuote);
    setApiQuoteItemList(...[], props.createQuote);
    getUserConfigsTypes(setUserConfigs);
    getUserConfigsUom(setUserConfigsuom, setDummyLoad);
    getEstimateLineItemList(estimateId, setTempEstmtLineItemList, setEstmtLineItemList);
  };

  const [lineItemError, setLineItemError] = React.useState("");

  var location = useLocation();
  useEffect(() => {
    if (props.isCameFromNavigation) {
      console.log("if props", props.isCameFromNavigation);
      getQuoteRequestEstimateId(estimateId, setGetQuoteData, setFirstLoader); //get quote
    } else if (location.pathname === "/quoterequests") {
      getQuoteRequest(setGetQuoteData, setFirstLoader); //get quote
    }
  }, [wantToCallGet]);

  //Auto open drawer when get id form URL
  useEffect(() => {
    if (estimateQuoteId && "estimateQuoteId") setState({ ...state, ["right"]: true });
    console.log("auto oepn drawr props");
    //getQuoteDataAPI(estimateQuoteId, setGetQuoteDataID, setSecondLoader);
  }, [estimateQuoteId]);
  useEffect(() => {
    if (estimateQuoteId && "estimateQuoteId") setQuoteState({ ...quoteState, ["right"]: true });
    console.log("auto oepn drawr props");
    //getQuoteDataAPI(estimateQuoteId, setGetQuoteDataID, setSecondLoader);
  }, [estimateQuoteId]);

  console.log("QuoteRequests  CALLED==>");

  return (
    <React.Fragment key={"right"}>
      <EditQuoteDrawer
        state={state}
        setState={setState}
        quoteState={quoteState}
        setQuoteState={setQuoteState}
        toggleDrawer={toggleDrawer}
        newQuoteDrawer={newQuoteDrawer}
        contacts={contacts}
        setContacts={setContacts}
        getQuoteDataID={getQuoteDataID}
        secondLoader={secondLoader}
        setSecondLoader={setSecondLoader}
        apiQuoteItemList={apiQuoteItemList}
        setApiQuoteItemList={setApiQuoteItemList}
        localQuoteItemList={localQuoteItemList}
        setLocalQuoteItemList={setLocalQuoteItemList}
        wantToCallGet={wantToCallGet}
        setWantToCallGet={setWantToCallGet}
        estimateData={estimateData}
        setContactWantToGet={setContactWantToGet}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarMSG={setSnackbarMSG}
        setCreateQuote={props.setCreateQuote}
        wantToCallGetJobPlan={props.wantToCallGetJobPlan}
        setWantToCallGetJobPlan={props.setWantToCallGetJobPlan}
        setOpenErrDialog={props.setOpenErrDialog}
        setErrorCode={props.setErrorCode}
        setErrorMsg={props.setErrorMsg}
        setValue={props.setValue}
        setRefreshQuote={setRefreshQuote}
        estmtLineItemList={estmtLineItemList}
        tempEstmtLineItemList={tempEstmtLineItemList}
        setTempEstmtLineItemList={setTempEstmtLineItemList}
      />
      {/*onChange={e => handleRemoveItemQuote(e, row)} */}
      {props.is_estimateQuote ? (
        <React.Fragment>
          {/* {console.log(props.createQuote.length + "vvvv")} */}
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}

          {props.createQuote.length > 0 ? (
            <React.Fragment>
              <Button variant="contained" color="secondary" align="right" onClick={openNewQuote}>
                New Quote Request
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {firstLoader ? (
            <ProgressBar />
          ) : (
            <QuoteResponse
              row={getQuoteData}
              getQuoteDataById={getQuoteDataById}
              setWantToCallGet={setWantToCallGet}
              callFrom={"quoteRequests"}
              isCameFromNavigation={props.isCameFromNavigation}
              estimateStatus={props.estimateStatus}
              handleChange={props.handleChange}
            />
          )}
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
