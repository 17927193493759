/** @format */

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
  ButtonGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { updateCatalogueItemsByCatalogueId } from "./catalogue.helper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupItemsTable from "./GroupItemsTable";

const useRowStyles = makeStyles({
  tableInnerContent: {
    paddingTop: "10px",
  },
});
export default function GroupRow({
  key,
  row,
  setRows,
  rows,
  catalogueId,
  contactId,
  index,
  loadSave,
  setLoadSave,
  isEdit,
  setEdit,
  openAllRows,
  setOpenAllRows,
  userConfigsTypes,
  userConfigsUom,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setTempGroups,
  editableCatalogue,
}) {
  console.log("editableCatalogue GroupRow:", editableCatalogue);
  const [groupItems, setGroupItems] = useState([]);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(false);
  const [tempRowsData, setTempRowsData] = useState([]);
  const [groups, setGroups] = React.useState([]);
  const [items, setItems] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [disable, setDisable] = React.useState(true);
  const [groupName, setGroupName] = useState("");
  const [groupCode, setGroupCode] = useState("");

  const classes = useRowStyles();
  // --------------------------------------------------------------------------

  const handleDelete = () => {
    console.log("handleDelete");
    var filterRows = rows.filter(function (el) {
      if (el.groupCode === row.groupCode) {
        var newItems = el.items.filter(function (itm) {
          return (itm.itemId = null);
        });
        el.items.length = 0;
        setItems(newItems);
      }
      return el.groupCode != row.groupCode;
    });

    let count = 1;
    var finalCatalogueItemsArr = filterRows.filter(function (el) {
      el.groupCode = count++;
      return el;
    });
    updateCatalogueItemsByCatalogueId(
      catalogueId,
      contactId,
      setRows,
      finalCatalogueItemsArr,
      setOpenSnackbar,
      setTempGroups
    );
    setConfirmDelete(false);
    setShowConfirm(false);
    setSnackbarMSG("Category deleted successfully!");
    setSnackbarSeverity("success");
  };

  const handleOpenGroupRow = () => {
    if (openAllRows[index]) {
      setOpenAllRows({ ...openAllRows, [index]: false });
    } else {
      setGroupItems(row.items);
      setOpenAllRows({ ...openAllRows, [index]: true });
    }
  };

  const handleConfirm = () => {
    setShowConfirm(true);
  };
  const handleNo = () => {
    setConfirmDelete(false);
    setShowConfirm(false);
  };

  useEffect(() => {
    row.items.sort(function (a, b) {
      return a.itemCode - b.itemCode;
    });

    // setInputList(tablerows);
  }, [row]);

  useEffect(() => {
    if (openAllRows[index]) {
      setGroupItems(row.items);
    }
  }, [openAllRows]);

  // --------------------------------------------------------------------------
  let rowEditable = true;
  row.items.filter((obj, index) => {
    if (obj.editable === false) {
      rowEditable = false;
    }
  });

  const handleInputChange = (e, index) => {
    const list = JSON.parse(JSON.stringify(rows));
    list[index][e.target.name] = e.target.value;
    setRows(list);
  };

  const handleGroupSave = () => {
    if (groups.length === 0) {
      var lastBeforeRowSeqId = 0;
    } else {
      var lastBeforeRowSeqId = groups[groups.length - 1].groupCode;
    }
    let arrOfObj = {
      groupCode: groupCode,
      groupName: groupName,
      items: items,
    };
    let arr = [...groups, arrOfObj];

    updateCatalogueItemsByCatalogueId(
      catalogueId,
      contactId,
      setGroups,
      arr,
      setOpenSnackbar,
      setTempGroups
    );
    setSnackbarMSG("Category Added Successfully!");
    setSnackbarSeverity("success");
    setEditGroup(false);
  };
  // ------------------------------------------------------

  return (
    <React.Fragment>
      <TableRow
        sx={{
          display: "block",
          cursor: "pointer",
        }}
        onClick={handleOpenGroupRow}
        hover
      >
        <TableCell width="2%" align="left" component="th">
          <IconButton aria-label="expand row" size="small" onClick={handleOpenGroupRow}>
            {openAllRows[index] ? (
              <Tooltip title="CLOSE MORE LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowUpIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="VIEW MORE LINE ITEMS" placement="top" TransitionComponent={Zoom} arrow>
                <KeyboardArrowDownIcon fontSize="small" />
              </Tooltip>
            )}
          </IconButton>
        </TableCell>
        {editGroup ? (
          <>
            <TableCell width="5.3%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupCode}
                name="groupCode"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
            <TableCell width="65%" align="center" component="th" scope="row" style={{ border: "none" }}>
              <TextField
                variant="outlined"
                className={classes.textField}
                size="small"
                value={row.groupName}
                name="groupName"
                onChange={(e) => handleInputChange(e, index)}
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell width="5.3%" align="center" component="th" scope="row">
              {row.groupCode}
            </TableCell>
            <TableCell width="65%" align="left" component="th" scope="row">
              {row.groupName}
            </TableCell>
          </>
        )}

        <TableCell width="10%" align="center">
          {row.items.length} items
        </TableCell>

        <TableCell width="8.7%" align="center">
          {rowEditable ? (
            !editGroup ? (
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Tooltip title="DELETE GROUP" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    disabled={!editableCatalogue}
                    size="small"
                    style={{ cursor: "pointer" }}
                    color="secondary"
                    onClick={handleConfirm}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="Edit Groups" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTempRowsData(rows);
                      setEditGroup(true);
                    }}
                  >
                    <CreateIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Tooltip title="CANCEL" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    size="small"
                    color="secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRows(tempRowsData);
                      setEditGroup(false);
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </Button>
                </Tooltip>
                <Tooltip title="SAVE" placement="top" TransitionComponent={Zoom} arrow>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGroupSave()}
                    size="small"
                  >
                    <SaveIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            )
          ) : (
            <IconButton disabled>
              <DeleteIcon style={{ cursor: "pointer" }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow p={1}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAllRows[index]} timeout="auto" unmountOnExit>
            <GroupItemsTable
              rows={rows}
              groupName={row.groupName}
              groupCode={row.groupCode}
              catalogueId={catalogueId}
              contactId={contactId}
              loadSave={loadSave}
              isEdit={isEdit}
              setEdit={setEdit}
              groupItems={groupItems}
              setGroupItems={setGroupItems}
              setRows={setRows}
              userConfigsTypes={userConfigsTypes}
              userConfigsUom={userConfigsUom}
              setLoadSave={setLoadSave}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              setTempGroups={setTempGroups}
              editableCatalogue={editableCatalogue}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      {showConfirm && (
        <div>
          <Dialog
            open={showConfirm}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
