/** @format */

import apis from "../../../utils/apis";

export const getLineItemsGroupsName = (jobId, setGroupsInfo, setGroupObject) => {
  apis
    .GET({
      endpoint: `/job/line-items/groups/${jobId}`,
    })
    .then((res) => {
      setGroupObject(res.data);
      setGroupsInfo(res.data);
    });
};

export const getAllVariations = (setVariationList, setLoading) => {
  console.log("getAllVariations API Called");

  apis
    .GET({
      endpoint: `/variations`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.variationNo - b.variationNo;
      });

      setVariationList(res.data);
      setLoading(false);
    })
    .catch((err) => console.log("No Variation data "));
};

export const getVariationById = (variationId, setVariationData, setLoading, setStatusDate) => {
  setLoading(true);
  apis
    .GET({
      endpoint: `/variation/${variationId}`,
    })
    .then((res) => {
      setVariationData({
        variationId: res.data.variationId,
        description: res.data.description,
        details: res.data.details,
        status: res.data.status,
        statusDate: res.data.statusDate,
        variationLineItems: res.data.variationLineItems,
      });
      res.data.statusDate && setStatusDate(res.data.statusDate);
      setLoading(false);
    });
};

export const updateVariation = (
  obj,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setState,
  variationList,
  setVariationList
) => {
  setRoundLoader(true);
  let oldData = [...variationList];

  apis
    .PUT({
      endpoint: `/variation`,

      payload: obj,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data) {
        oldData.map((item, i) => {
          if (item.variationId === obj.variationId) {
            oldData[i] = res.data;
          }
        });
        setVariationList(oldData);
        setSnackbarMSG("Variation Updated successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Variation Updation failed!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    });
};

export const getAllVariationsById = (setVariationList, setLoading, jobId) => {
  console.log("getAllVariations API Called");

  apis
    .GET({
      endpoint: `/variations/${jobId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.variationNo - b.variationNo;
      });

      console.log("getting Variations:", res.data);
      setVariationList(res.data);
      setLoading(false);
    })
    .catch((err) => console.log("No Variation data "));
};

export const getVariationsByJoblineitemId = (setVariationList, setLoading, jobId, itemId) => {
  setLoading(true);
  apis
    .GET({
      endpoint: `/variations/${jobId}/${itemId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.variationNo - b.variationNo;
      });
      setVariationList(res.data);
      setLoading(false);
    })
    .catch((err) => console.log("No Variation data "));
};

export const deleteVariationsByVariationsId = (
  variationId,
  wantToCallGet,
  setWantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/variation/${variationId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        wantToCallGetFunction(setWantToCallGet, wantToCallGet);
        setOpenCustSnackbar(true);
        setSnackbarMSG("variation deleted successfully");
        setSnackbarSeverity("success");
        setRoundLoader(false);
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG("variation Deletion failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
      }
    });
};

const wantToCallGetFunction = (setWantToCallGet, wantToCallGet) => {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
};
