/** @format */

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  useHistory,
  useParams,
  Link,
  Route,
  BrowserRouter,
  Switch,
  useLocation,
  matchPath,
} from "react-router-dom";
import JobDetails from "../jobs/job_details_component/JobDetails";
import { AppBar } from "@mui/material";
import PurchaseOrders from "../jobs/PurchaseOrders/PurchaseOrder";
import WorkOrders from "../jobs/WorkOrders/WorkOrder";
import OutgoingInvoices from "../jobs/OutgoingInvoices/OutgoingInvoice";
import { getEstimatesbyID } from "../estimates/estimates.helpers";
import ActualCosting from "../jobs/actual_costings/ActualCosting";
import Variation from "../jobs/Variation/Variation";
import { getUserConfigsTypes, getUserConfigsUom } from "../UI/GlobalApi";
import CommonInfo from "../info/CommonInfo";
import infoArray from "../info/commonInfo.json";
import { PURCHASE_ORDER_READ, VARIATION_READ, WORK_ORDER_READ } from "../UI/GlobalVariables";
import { OAuthCtx } from "../../oauth/OAuthContext";
import FileManager from "../files/FileManager";
const drawerWidth = 240;
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", //chgd from flex-end to this
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    // overflow: 'auto',
    flexDirection: "column",
    backgroundColor: "#ccc",
  },
  fixedHeight: {
    height: "auto",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function JobsNav(props) {
  var { jobId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [quote, setQuote] = useState(0);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [isJobCreated, setJobCreated] = useState(false);
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [createJobOpenDrawer, setCreateJobOpenDrawer] = useState(false);
  const [jobStatus, setJobStatus] = React.useState("");
  // console.log("info---------",infoArray.actualCosting);
  // console.log("His-> ",props);

  const [state, setState] = React.useState({
    right: true,
  });

  // console.log("quote in nav -> ",quote);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const toggleDrawer = (open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setState({ right: open });
  };
  const handleCreateJob = () => {
    setState({ right: open });
    setCreateJobOpenDrawer(true);
    console.log("opened");
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [estimate, setEstimate] = useState([]);
  const location = useLocation();
  const [dummyLoder, setDummyLoad] = useState(true);
  const [userConfigs, setUserConfigs] = useState({});
  const [userConfigsuom, setUserConfigsuom] = useState({});
  const routes = [
    {
      key: "details",
      exact: true,
      path: "/job/:jobId/details",
    },
    {
      exact: true,
      key: "Costings",
      path: "/job/:jobId/Costings",
    },
    {
      exact: true,
      key: "PurchaseOrders",
      path: "/job/:jobId/PurchaseOrders",
    },
    {
      exact: true,
      key: "WorkOrders",
      path: "/job/:jobId/WorkOrders",
    },
    {
      exact: true,
      key: "OutgoingInvoices",
      path: "/job/:jobId/OutgoingInvoices",
    },
    {
      exact: true,
      key: "Variation",
      path: "/job/:jobId/Variation",
    },
  ];

  const currentRoute = routes.find((route) => matchPath(location.pathname, route));

  useEffect(() => {
    setValue(currentRoute?.path);
    getUserConfigsTypes(setUserConfigs, setDummyLoad); //get all configs Types
    getUserConfigsUom(setUserConfigsuom, setDummyLoad); //get all configs Uom
  }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <JobDetails jobId={jobId} setJobStatus={setJobStatus} />
          </Grid>
          <Grid item xs={12}>
            <AppBar
              className="innerHeader_Nav"
              color="inherit"
              position="static"
              style={{
                border: "none",
                boxShadow: "none",
              }}
              my={2}
            >
              <Tabs
                noWrap
                className={classes.title}
                value={value}
                inkBarStyle={{ background: "blue" }}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Actual Costings"
                  value={"/job/:jobId/Costings"}
                  component={Link}
                  to={"/job/" + jobId + "/Costings"}
                  {...a11yProps(0)}
                />
                {hasValidAccess(PURCHASE_ORDER_READ) && (
                  <Tab
                    label="Purchase Orders"
                    value={"/job/:jobId/PurchaseOrders"}
                    component={Link}
                    to={"/job/" + jobId + "/PurchaseOrders"}
                    {...a11yProps(1)}
                  />
                )}
                {hasValidAccess(WORK_ORDER_READ) && (
                  <Tab
                    label="Work Orders"
                    value={"/job/:jobId/WorkOrders"}
                    component={Link}
                    to={"/job/" + jobId + "/WorkOrders"}
                    {...a11yProps(2)}
                  />
                )}

                {hasValidAccess(VARIATION_READ) && (
                  <Tab
                    label="Variation"
                    value={"/job/:jobId/Variation"}
                    component={Link}
                    to={"/job/" + jobId + "/Variation"}
                    {...a11yProps(3)}
                  />
                )}
                <Tab
                  label="Outgoing Invoices"
                  value={"/job/:jobId/OutgoingInvoices"}
                  component={Link}
                  to={"/job/" + jobId + "/OutgoingInvoices"}
                  {...a11yProps(4)}
                />
                <Tab
                  label="Files"
                  value={"/job/:jobId/files"}
                  component={Link}
                  to={"/job/" + jobId + "/files"}
                  {...a11yProps(5)}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>

        {/* <div style={{ padding: "3px" }}>&nbsp;</div> */}
        <Switch>
          {/* Start ActualCosting*/}
          <Route
            path={"/job/:jobId/Costings"}
            render={() => (
              <>
                <ActualCosting
                  userConfigs={userConfigs}
                  userConfigsuom={userConfigsuom}
                  jobStatus={jobStatus}
                />
                <CommonInfo title={infoArray.actualCosting.title} msg={infoArray.actualCosting.msg} />
              </>
            )}
          />
          {/* END ActualCosting*/}
          {/* Start PurchaseOrders */}
          <Route
            path={"/job/:jobId/PurchaseOrders"}
            render={() => (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PurchaseOrders />
                  </Grid>
                  <Grid item xs={12}>
                    <CommonInfo
                      title={infoArray.purchaseOrders.title}
                      msg={infoArray.purchaseOrders.msg}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          />
          {/* END PurchaseOrders*/}

          {/* Start Work Orders */}
          <Route
            path={"/job/:jobId/WorkOrders"}
            render={() => (
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <WorkOrders />
                  </Grid>
                  <Grid item xs={12}>
                    <CommonInfo title={infoArray.workOrders.title} msg={infoArray.workOrders.msg} />
                  </Grid>
                </Grid>
              </div>
            )}
          />
          {/* END Work Orders */}

          {/* Start Outgoing Invoices*/}
          <Route path={"/job/:jobId/OutgoingInvoices"} render={() => <OutgoingInvoices />} />
          {/* END Outgoing Invoices*/}

          {/* Start Variation*/}
          <Route path={"/job/:jobId/Variation"} render={() => <Variation callFrom={"jobs"} />} />
          {/* END Variation*/}
          <Route
            path={"/job/:jobId/files"}
            render={() => (
              <div>
                <FileManager callFrom={"job"} />
              </div>
            )}
          />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}
