
import React from 'react';
import { CircularProgress } from '@mui/material';

export default function Loading() {
  
  return (
    <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
        <CircularProgress />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
    </div>
  );
}