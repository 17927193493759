/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import ButtonGroup from "@mui/material/ButtonGroup";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import { getCostCentreItems, updateCostCentreItemById } from "./costcenter.helpers";
import { COST_CENTRE_DELETE, COST_CENTRE_WRITE } from "../UI/GlobalVariables";
import { OAuthCtx } from "../../oauth/OAuthContext";
import { Tooltip, Zoom } from "@mui/material";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";

const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});
export default function CostCentreLineItemTable({
  costCentreLineItemArray,
  setCostCentreLineItemArray,
  rows,
  costCentreId,
  row,
  loadSave,
  setDisableSave,
  myrow,
  setTempGroups,
  editableCostCenter,
}) {
  const classes = useStyles();
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [isEdit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openItems, setOpenItems] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [costCentreLineItemsObject, setCostCentreLineItemsObject] = useState([]);
  const [itemRowToDelete, setItemRowToDelete] = React.useState();
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [roundLoader, setRoundLoader] = useState(false);

  /* ---------------------------------------------------------------- */

  useEffect(() => {
    setCostCentreLineItemsObject(JSON.parse(JSON.stringify(costCentreLineItemArray)));
  }, [costCentreLineItemArray]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleEdit = (i) => {
    setEdit(true);
  };

  const handleConfirm = (row) => {
    console.log("setItemRowToDelete ", row);
    setItemRowToDelete(row);
    setShowConfirm(true);
  };
  const handleNo = () => {
    setShowConfirm(false);
  };
  /* ---------------------------------------------------------------- */

  const handleCancel = () => {
    setCostCentreLineItemsObject(costCentreLineItemArray);
    setEdit(false);
    setDisableSave(true);
  };
  /* ------------------------Common function------------------------- */
  function filterInputListForClearAndDeleteLineItem(deleteId) {
    console.log("deletedId", deleteId);
    let filterList = costCentreLineItemsObject.filter((el) => {
      return deleteId.itemCode != el.itemCode;
    });

    let count = 1;
    var test = filterList.filter(function (el) {
      el.itemCode = count++;
      return el;
    });
    return test;
  }
  /* ---------------------------------------------------------------- */

  const deleteItemNonEditMode = () => {
    let deletedData = filterInputListForClearAndDeleteLineItem(itemRowToDelete);
    updateValuesInRows(deletedData);
    setCostCentreLineItemsObject(deletedData);
    setShowConfirm(false);
    console.log("first");
    // setDisableSave(true);
  };
  /* -------------------------------------------------------- */
  const clearLineItem = (row) => {
    let deletedData = filterInputListForClearAndDeleteLineItem(row);
    setCostCentreLineItemsObject(deletedData);

    // setDisableSave(true);
  };

  /* ---------------------------------------------------------------- */

  const handleAdd = () => {
    if (costCentreLineItemsObject.length === 0) {
      var lastBeforeRowLineId = 0;
    } else {
      var lastBeforeRowLineId = costCentreLineItemsObject[costCentreLineItemsObject.length - 1].itemCode;
    }
    var newElement = {
      itemCode: "",
      itemId: null,
      itemName: "",
      itemGroupCode: row.groupCode,
      itemGroupName: row.groupName,
      itemDescription: "",
      itemCategory: "",
    };
    setCostCentreLineItemsObject([...costCentreLineItemsObject, newElement]);
    setEdit(true);
    // setDisableSave(false);
  };

  /* ---------------------------------------------------------------- */

  const handleInputChange = (e, index) => {
    // setDisableSave(false);
    const { name, value } = e.target;
    const list = JSON.parse(JSON.stringify(costCentreLineItemsObject));
    list[index][name] = value;

    console.log("list", list);
    setCostCentreLineItemsObject(list);
    // setRowNow(row);
  };

  /* ---------------------------------------------------------------- */

  const handleOpenCostinRow = () => {
    setOpenItems(!openItems);
  };

  /* ------------------------Common function------------------------- */

  function updateValuesInRows(finalJobLineObj) {
    rows.filter((item) => {
      finalJobLineObj.filter((el) => {
        if (item.groupCode === el.itemGroupCode && item.groupName === el.itemGroupName) {
          item.items = finalJobLineObj;
        }
      });
    });
    console.log("rows", rows);
    console.log("finalJobLineObj", finalJobLineObj);

    updateCostCentreItemById(
      costCentreId,
      rows,
      setSnackbarSeverity,
      setSnackbarMSG,
      setOpenSnackbar,
      setTempGroups,
      setRoundLoader,

    );
  }
  /* ------------------------------------------------------------------ */

  const handlesLineItemSave = () => {
    var finalJobLineObj = costCentreLineItemsObject.filter((el) => {
      if (el.itemCategory != "" || el.itemDescription != "" || el.itemName != "") {
        return el;
      }
    });
    setCostCentreLineItemsObject(finalJobLineObj);
    updateValuesInRows(finalJobLineObj);
    setEdit(false);

    // setIsEditMode(false);
    // setDisableSave(true);
  };
  /* ---------------------------------------------------------------- */

  return (
    <>
      <Box>
        {roundLoader && <SimpleBackdrop />}
        <Table size="small" aria-label="a dense table">
          {loadSave && <SimpleBackdrop />}
          <TableBody style={{ boxShadow: "none" }}>
            <Box margin={1}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div></div>
                <div>
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    className={classes.snackbar}
                  >
                    <Alert onClose={handleClose} severity="success" variant="filled">
                      Costings saved successfully!
                    </Alert>
                  </Snackbar>
                  {hasValidAccess(COST_CENTRE_WRITE) && (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Box component="span" p={1}>
                        <ButtonGroup variant="contained" aria-label="contained primary button group">
                          <Button
                            disabled={!editableCostCenter}
                            variant="contained"
                            color="primary"
                            onClick={handleAdd}
                            size="small"
                          >
                            <AddIcon fontSize="small" />
                            ADD
                          </Button>

                          {isEdit && costCentreLineItemsObject.length !== 0 && (
                            <>
                              <Button
                                disabled={!editableCostCenter}
                                size="small"
                                color="secondary"
                                variant="contained"
                                align="right"
                                onClick={handleCancel}
                              >
                                <ClearIcon fontSize="small" />
                                Cancel
                              </Button>
                              <Button
                                disabled={!editableCostCenter}
                                variant="contained"
                                color="primary"
                                onClick={handlesLineItemSave}
                                size="small"
                              >
                                <CheckCircleOutlineIcon fontSize="small" />
                                SAVE
                              </Button>
                            </>
                          )}
                          {!isEdit && costCentreLineItemsObject.length !== 0 && (
                            <Button
                              disabled={!editableCostCenter}
                              size="small"
                              color="primary"
                              align="right"
                              onClick={handleEdit}
                            >
                              <CreateIcon fontSize="small" /> EDIT
                            </Button>
                          )}
                        </ButtonGroup>
                      </Box>
                    </div>
                  )}
                </div>
              </div>
              {costCentreLineItemsObject.length === 0 ? (
                <EmptyEstimatesDialog
                  heading="Add some cost centre lineitems in this category"
                  msg="Add some lineitems here to Save this cost centre type. You can add using the side button present on the right side the screen"
                />
              ) : (
                <Table
                  sx={{ minWidth: 650, borderBottom: "none" }}
                  size="small"
                  aria-label="a dense table"
                  id="estimationCosting"
                  className={" estimationCosting"}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%">Code</TableCell>
                      <TableCell width="10%">Name</TableCell>
                      <TableCell width="50%">Description</TableCell>
                      {/* <TableCell width="10%" align="center">
                      Category
                    </TableCell> */}
                      {hasValidAccess(COST_CENTRE_DELETE) && (
                        <>
                          {costCentreLineItemsObject.length > 1 && (
                            <TableCell width="5%" align="center">
                              Actions
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {costCentreLineItemsObject.map((row, i) => {
                      return (
                        <React.Fragment>
                          {isEdit ? (
                            <React.Fragment>
                              <TableRow key={row.itemId}>
                                <TableCell>
                                  <TextField
                                    variant="outlined"
                                    className={classes.textField}
                                    size="small"
                                    fullWidth
                                    value={row.itemCode}
                                    name="itemCode"
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    variant="outlined"
                                    className={classes.textField}
                                    size="small"
                                    fullWidth
                                    value={row.itemName}
                                    name="itemName"
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </TableCell>
                                <TableCell width="40%">
                                  <TextField
                                    variant="outlined"
                                    className={classes.textField}
                                    size="small"
                                    fullWidth
                                    value={row.itemDescription}
                                    name="itemDescription"
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                </TableCell>
                                {/* <TableCell align="center">
                                <TextField
                                  variant="outlined"
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  value={row.itemCategory}
                                  name="itemCategory"
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </TableCell> */}
                                {hasValidAccess(COST_CENTRE_WRITE) && (
                                  <>
                                    {isEdit && (
                                      <>
                                        {costCentreLineItemsObject.length > 1 && (
                                          <TableCell align="center">
                                            <ButtonGroup variant="contained" size="small">
                                              <Button
                                                size="small"
                                                color="secondary"
                                                className="mr10"
                                                key={row.itemId}
                                                onClick={(e) => clearLineItem(row)}
                                              >
                                                <ClearIcon fontSize="small" />
                                              </Button>
                                            </ButtonGroup>
                                          </TableCell>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <TableRow key={row.itemId}>
                                <TableCell>{row.itemCode}</TableCell>
                                <TableCell>{row.itemName} </TableCell>
                                <TableCell width="40%">{row.itemDescription} </TableCell>
                                {/* <TableCell align="center">{row.itemCategory}</TableCell> */}
                                {/* {isEdit && ( */}
                                {hasValidAccess(COST_CENTRE_WRITE) && (
                                  <>
                                    {costCentreLineItemsObject.length > 1 && (
                                      <TableCell align="center">
                                        <ButtonGroup
                                          variant="contained"
                                          aria-label="outlined primary button group"
                                        >
                                          <Tooltip
                                            title="DELETE LINE ITEMS"
                                            placement="top"
                                            TransitionComponent={Zoom}
                                            arrow
                                          >
                                            <Button
                                              disabled={!editableCostCenter}
                                              size="small"
                                              className="mr10"
                                              color="secondary"
                                              key={row.itemId}
                                              onClick={(e) => handleConfirm(row)}
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </Button>
                                          </Tooltip>
                                        </ButtonGroup>
                                      </TableCell>
                                    )}
                                  </>
                                )}

                                {/* )} */}
                              </TableRow>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </Box>
          </TableBody>
        </Table>
        {showConfirm && (
          <div>
            <Dialog
              open={showConfirm}
              onClose={handleNo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={deleteItemNonEditMode} color="primary" autoFocus>
                  Yes
                </Button>
                <Button onClick={handleNo} color="primary" autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Box>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </>
  );
}
