import React, { useCallback, useEffect } from "react";
import GSTC from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js";
import { Plugin as TimelinePointer } from "gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js";
import { Plugin as Selection } from "gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js";
import { Plugin as ItemResizing } from "gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js";
import { Plugin as ItemMovement } from "gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js";
import { Plugin as CalendarScroll } from "gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js";
import { Plugin as HighlightWeekends } from "gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js";
import { Plugin as ProgressBar } from "gantt-schedule-timeline-calendar/dist/plugins/progress-bar.esm.min.js";
import { Plugin as TimeBookmarks } from "gantt-schedule-timeline-calendar/dist/plugins/time-bookmarks.esm.min.js";
import { Plugin as DependencyLines } from "gantt-schedule-timeline-calendar/dist/plugins/dependency-lines.esm.min.js";
import { Plugin as ExportImage } from "gantt-schedule-timeline-calendar/dist/plugins/export-image.esm.min.js";
import { Plugin as ExportPDF } from "gantt-schedule-timeline-calendar/dist/plugins/export-pdf.esm.min.js";
import "../../css/timeLine.scss";
// import faces from "../../images/faces";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { AppBar } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

let gstc, state;

const iterations = 100;
const GSTCID = GSTC.api.GSTCID;
const addDays = 30;

function getRandomFaceImage() {
  const faces = require(`../../images/faces/face-${Math.floor(
    Math.random() * 50 + 1
  )}.jpg`).default;
  return `${faces}`;
}

const colors = [
  "#E74C3C",
  "#DA3C78",
  "#7E349D",
  "#0077C0",
  "#07ABA0",
  "#0EAC51",
  "#F1892D",
];
function getRandomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

const startDate = GSTC.api.date("2020-02-01");
const startTime = startDate.valueOf();
const endDate = GSTC.api.date("2020-03-31").endOf("day");

/**
 * @type {import("../../dist/gstc").Rows}
 */
const rows = {};
for (let i = 0; i < iterations; i++) {
  const withParent = i > 0 && i % 2 === 0;
  const id = GSTCID(String(i));
  rows[id] = {
    id,
    label: `John Doe ${i}`,
    parentId: withParent ? GSTCID(String(i - 1)) : undefined,
    expanded: false,
    vacations: [],
    img: getRandomFaceImage(),
    progress: Math.floor(Math.random() * 100),
  };
}

rows[GSTCID("3")].vacations = [
  startDate.add(5, "days").startOf("day").format("YYYY-MM-DD"),
  startDate.add(6, "days").startOf("day").format("YYYY-MM-DD"),
];

rows[GSTCID("11")].label = "NESTED TREE HERE";
rows[GSTCID("12")].parentId = GSTCID("11");
rows[GSTCID("13")].parentId = GSTCID("12");
rows[GSTCID("14")].parentId = GSTCID("13");

rows[GSTCID("7")].birthday = startDate
  .add(3, "day")
  .startOf("day")
  .format("YYYY-MM-DD");

const items = {};
for (let i = 0; i < iterations; i++) {
  let rowId = GSTCID(i.toString());
  let id = GSTCID(i.toString());
  let startDayjs = GSTC.api
    .date(startTime)
    .startOf("day")
    .add(Math.floor(Math.random() * addDays), "day");
  let end = startDayjs
    .clone()
    .add(Math.floor(Math.random() * 20) + 4, "day")
    .endOf("day")
    .valueOf();
  if (end > endDate.valueOf()) end = endDate.valueOf();
  items[id] = {
    id,
    label: `John Doe ${i}`,
    progress: Math.round(Math.random() * 100),
    style: { background: getRandomColor() },
    time: {
      start: startDayjs.startOf("day").valueOf(),
      end,
    },
    rowId,
    img: getRandomFaceImage(),
    description: "Lorem ipsum dolor sit amet",
  };
}

items[GSTCID("0")].linkedWith = [GSTCID("1")];
items[GSTCID("0")].label = "Task 0 linked with 1";
items[GSTCID("0")].type = "task";
items[GSTCID("1")].label = "Task 1 linked with 0";
items[GSTCID("1")].type = "task";
items[GSTCID("1")].time = { ...items[GSTCID("0")].time };

items[GSTCID("0")].style = { background: colors[3] };
items[GSTCID("1")].style = { background: colors[3] };

items[GSTCID("3")].dependant = [GSTCID("5")];
items[GSTCID("3")].label = "Grab and move me into vacation area";
items[GSTCID("3")].time.start = GSTC.api
  .date(startTime)
  .add(4, "day")
  .startOf("day")
  .add(5, "day")
  .valueOf();
items[GSTCID("3")].time.end = GSTC.api
  .date(items[GSTCID("3")].time.start)
  .endOf("day")
  .add(5, "day")
  .valueOf();

items[GSTCID("5")].time.start = GSTC.api
  .date(items[GSTCID("3")].time.end)
  .startOf("day")
  .add(5, "day")
  .valueOf();
items[GSTCID("5")].time.end = GSTC.api
  .date(items[GSTCID("5")].time.start)
  .endOf("day")
  .add(2, "day")
  .valueOf();
items[GSTCID("5")].dependant = [GSTCID("7"), GSTCID("9")];

items[GSTCID("7")].time.start = GSTC.api
  .date(items[GSTCID("5")].time.end)
  .startOf("day")
  .add(3, "day")
  .valueOf();
items[GSTCID("7")].time.end = GSTC.api
  .date(items[GSTCID("7")].time.start)
  .endOf("day")
  .add(2, "day")
  .valueOf();
items[GSTCID("9")].time.start = GSTC.api
  .date(items[GSTCID("5")].time.end)
  .startOf("day")
  .add(2, "day")
  .valueOf();
items[GSTCID("9")].time.end = GSTC.api
  .date(items[GSTCID("9")].time.start)
  .endOf("day")
  .add(3, "day")
  .valueOf();

const columns = {
  data: {
    [GSTCID("id")]: {
      id: GSTCID("id"),
      data: ({ row }) => GSTC.api.sourceID(row.id),
      width: 80,
      sortable: ({ row }) => Number(GSTC.api.sourceID(row.id)),
      header: {
        content: "ID",
      },
    },
    [GSTCID("label")]: {
      id: GSTCID("label"),
      data: "label",
      sortable: "label",
      expander: true,
      isHTML: false,
      width: 335,
      header: {
        content: "Label",
      },
    },
    [GSTCID("progress")]: {
      id: GSTCID("progress"),
      data({ row, vido }) {
        return vido.html`<div style="text-align:center">${row.progress}</div>`;
      },
      width: 80,
      sortable: "progress",
      header: {
        content: "Progress",
      },
    },
  },
};

const bookmarks = {
  now: {
    time: GSTC.api.date().valueOf(),
    label: "Now",
    style: {
      background: "#3498DB",
      fontWeight: "bold",
    },
  },
};

for (let i = 0; i < 5; i++) {
  const id = `Bookmark ${i}`;
  bookmarks[id] = {
    time: GSTC.api
      .date()
      .add(Math.round(Math.random() * addDays), "day")
      .startOf("day")
      .valueOf(),
    label: id,
  };
}

function itemSlot(vido, props) {
  const { html, onChange, update } = vido;

  let imageSrc = "";
  let description = "";
  onChange((newProps) => {
    props = newProps;
    if (!props || !props.item) return;
    imageSrc = props.item.img;
    description = props.item.description;
    update();
  });

  return (content) =>
    html`<div
        class="item-image"
        style="background:url(${imageSrc}),transparent;flex-shrink:0;border-radius:100%;width:34px;height:34px;vertical-align: middle;background-size: 100%;margin: 4px 11px 0px 0px;"
      ></div>
      <div class="item-text">
        <div class="item-label">${content}</div>
        <div
          class="item-description"
          style="font-size:11px;margin-top:2px;color:#fffffff0;line-height:1em;"
        >
          ${description}
        </div>
      </div>`;
}

function rowSlot(vido, props) {
  const { html, onChange, update, api } = vido;

  let img = "";
  onChange((newProps) => {
    props = newProps;
    if (!props || !props.row) return;
    img = props.row.img;
    update();
  });

  return (content) => {
    if (!props || !props.column) return content;
    return api.sourceID(props.column.id) === "label"
      ? html`<div class="row-content-wrapper" style="display:flex">
          <div class="row-content" style="flex-grow:1;">${content}</div>
          <div
            class="row-image"
            style="background:url(${img}),transparent;border-radius:100%;width:34px;height:34px;vertical-align: middle;background-size: 100%;margin: 11px 11px 0px 0px;"
          ></div>
        </div>`
      : content;
  };
}

function onCellCreateVacation({ time, row, vido, content }) {
  if (row.vacations.includes(time.leftGlobalDate.format("YYYY-MM-DD"))) {
    return vido.html`<div title="🏖️ VACATION" style="height:100%"><div style="font-size:11px;background:#A0A0A0;color:white;">Vacation</div></div>${content}`;
  }
  return content;
}

function onCellCreateBirthday({ time, row, vido, content }) {
  if (row.birthday === time.leftGlobalDate.format("YYYY-MM-DD")) {
    return vido.html`${content}<div title="🎁 BIRTHDAY" style="height:100%;font-size:18px;"><div style="font-size:11px;background:#F9B32F;color:white;margin-bottom:10px;">🎁 Birthday</div></div>`;
  }
  return content;
}

const itemMovementOptions = {
  events: {
    onMove({ items }) {
      for (let i = 0, len = items.after.length; i < len; i++) {
        const item = items.after[i];
        const row = gstc.api.getRow(item.rowId);
        for (const vacation of row.vacations) {
          const vacationStart = gstc.api.time
            .date(vacation)
            .startOf("day")
            .valueOf();
          const vacationEnd = gstc.api.time
            .date(vacation)
            .endOf("day")
            .valueOf();
          // item start time inside vacation
          if (
            item.time.start >= vacationStart &&
            item.time.start <= vacationEnd
          ) {
            return items.before;
          }
          // item end time inside vacation
          if (item.time.end >= vacationStart && item.time.end <= vacationEnd) {
            return items.before;
          }
          // vacation is between item start and end
          if (
            item.time.start <= vacationStart &&
            item.time.end >= vacationEnd
          ) {
            return items.before;
          }
          // item start and end time is inside vacation
          if (
            item.time.start >= vacationStart &&
            item.time.end <= vacationEnd
          ) {
            return items.before;
          }
        }
      }
      return items.after;
    },
  },
};

const itemResizeOptions = {
  events: {
    onResize({ items }) {
      for (const item of items.after) {
        const row = gstc.api.getRow(item.rowId);
        for (const vacation of row.vacations) {
          const vacationStart = gstc.api.time
            .date(vacation)
            .startOf("day")
            .valueOf();
          const vacationEnd = gstc.api.time
            .date(vacation)
            .endOf("day")
            .valueOf();
          // item start time inside vacation
          if (
            item.time.start >= vacationStart &&
            item.time.start <= vacationEnd
          ) {
            return items.before;
          }
          // item end time inside vacation
          if (item.time.end >= vacationStart && item.time.end <= vacationEnd) {
            return items.before;
          }
          // vacation is between item start and end
          if (
            item.time.start <= vacationStart &&
            item.time.end >= vacationEnd
          ) {
            return items.before;
          }
          // item start and end time is inside vacation
          if (
            item.time.start >= vacationStart &&
            item.time.end <= vacationEnd
          ) {
            return items.before;
          }
        }
      }
      return items.after;
    },
  },
};

function initializeGSTC(element) {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Config }
   */

  const innerHeight = "8";

  const config = {
    //debug: true,
    licenseKey:
      "====BEGIN LICENSE KEY====\nsDsSrgmoHtIUPLQDUvIAf11TKsxV+vHAv0pS5N0lbKl+i4tpSx8vUzLJIPLd8jhuNgoKueBA0+2Qu6y8BOG1+8aFADoTqyU7yLtPD2patuHrR/io+kbD+YAuKC5FtcxRWTUQn0RapqrEmTsR3gp1P6TBm761RLAFYJ2IiFiMg1SUUVAWyjKibZJ7g1P8BTOpZQUR0MisyzwAGkW8NMs+lm50GsF5DNIaZ2rzWmEXCj0ryr45b1Iqjc6tfu2q3AzdB8F/YCLjmZOV/HwlU7TeJraJPMg/RUbULSO+owGIm/xpQI/c6BzW2uOiqAAELYITS4XmOYyg/jxfxwzfx3kmTw==||U2FsdGVkX1+4qezYROn1xBpEsRjDyMS6j3QJWQDzLn9+CRgNqq6WO4LfnKnDsdDdZXULckg5MJNP04wtDzdn1RfQB6rSrGdaOAXAoYOsqgE=\nP8GMYFtwEd5kawKjFb3Hf+KF+OY6h3tVf4KMvH92QV2Mq9VmTB0+/AUtj84bslHhrROYbJRI9TCxfV2Y0Bs9RwU5xZQ12FatYh4AeJTSyravI9D08GKGspI23Q+03n/xcmjMem5POf5tm2BnDb93hU93XTfyXc3S/IWiFNtkPlG7+Qn6rwItW1u6vAy9rzyoN9QFUMTudQt36EPftByr7DKLszuhED1uUPdHdfDfSTeGbOk077NPdU+pSFMo4uYnWEn2li7aXTrux/f93merIayMHHUXtKOJO7/9JplxU+CPaCmdDZ7F4wOVCEZp5AVNMYf26cRg0tEFV4R0NgtSVw==\n====END LICENSE KEY====",
    innerHeight: innerHeight,
    autoInnerHeight: true,
    plugins: [
      HighlightWeekends(),
      TimelinePointer(), // timeline pointer must go first before selection, resizing and movement
      Selection(),
      ItemResizing(itemResizeOptions), // resizing must fo before movement
      ItemMovement(itemMovementOptions),
      CalendarScroll(),
      ProgressBar(),
      TimeBookmarks({
        bookmarks,
      }),
      DependencyLines({
        onLine: [
          (line) => {
            line.type =
              GSTC.api.sourceID(line.fromItem.id) === "3" ? "smooth" : "square";
            return line;
          },
        ],
      }),
      ExportImage(),
      ExportPDF(),
    ],
    list: {
      row: {
        height: 68,
      },
      rows,
      columns,
    },
    chart: {
      time: {
        from: startDate.valueOf(),
        to: endDate.valueOf(),
      },
      item: {
        height: 50,
        gap: {
          top: 14,
          //bottom: 0,
        },
      },
      items,
      grid: {
        cell: {
          onCreate: [onCellCreateVacation, onCellCreateBirthday],
        },
      },
    },
    scroll: {
      vertical: { precise: true },
      horizontal: { precise: true },
    },
    slots: {
      "chart-timeline-items-row-item": { content: [itemSlot] },
      "list-column-row": { content: [rowSlot] },
    },
  };

  state = GSTC.api.stateFromConfig(config);

  gstc = GSTC({
    element,
    state,
  });

  // TOOLBOX BUTTONS

  // Select first two cells
  function selectCells() {
    const api = gstc.api;
    const allCells = api.getGridCells();
    api.plugins.Selection.selectCells([allCells[0].id, allCells[1].id]);
    console.log(api.plugins.Selection.getSelection());
  }

  // scroll to first item
  function scrollToFirstItem() {
    const api = gstc.api;
    const firstItem = gstc.state.get(`config.chart.items.${api.GSTCID("1")}`);
    api.scrollToTime(firstItem.time.start, false);
    // GSTC.api.stateFromConfig(config);
  }

  function makeSelectedItemsDependent() {
    const ITEM = "chart-timeline-items-row-item";
    const selectedItems = gstc.api.plugins.Selection.getSelected()[ITEM];
    console.log("selected items", selectedItems);
    selectedItems.forEach((item, index, all) => {
      if (index + 1 < all.length) {
        state.update(`config.chart.items.${item.id}.dependant`, [
          all[index + 1].id,
        ]);
      }
    });
  }

  function oneMonth() {
    state.update("config.chart.time", (time) => {
      time.calculatedZoomMode = true;
      time.from = startDate.startOf("month").valueOf();
      time.to = startDate.endOf("month").valueOf();
      console.log("to", gstc.api.time.date(time.to).format("YYYY-MM-DD"));
      return time;
    });
  }

  function downloadImage() {
    gstc.api.plugins.ExportImage.download();
  }

  function downloadPdf() {
    gstc.api.plugins.ExportPDF.download("timeline.pdf");
  }

  const Tablist = () => {
    return (
      <BrowserRouter>
        <Box>
          <AppBar
            className="innerHeader_Nav"
            color="inherit"
            position="static"
            style={{
              border: "none",
              boxShadow: "none",
            }}
          >
            <Tabs
              noWrap
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="Select first cells"
                onClick={() => selectCells()}
              ></Tab>
              <Tab
                label="Scroll to first item"
                onClick={() => scrollToFirstItem()}
              />
              <Tab label="Download image" onClick={() => downloadImage()} />
              <Tab label="Download PDF" onClick={() => downloadPdf()} />
              <Tab label="Show one month" onClick={() => oneMonth()} />
            </Tabs>
          </AppBar>
        </Box>
      </BrowserRouter>
    );
  };

  ReactDOM.render(<Tablist />, document.getElementById("toolbox"));
}

function TimeLine() {
  const callback = useCallback((element) => {
    if (element) initializeGSTC(element);
  }, []);

  function updateFirstRow() {
    state.update(`config.list.rows.${GSTC.api.GSTCID("0")}`, (row) => {
      row.label = "Changed dynamically";
      return row;
    });
  }

  function changeZoomLevel() {
    state.update("config.chart.time.zoom", 21);
  }

  return (
    <div className="App">
      <div id="toolbox"></div>
      <div id="gstc" className="gstc-wrapper" ref={callback}></div>
    </div>
  );
}

export default TimeLine;
