/** @format */

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Checkbox, Collapse, Box } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { ESTIMATE_WRITE, IS_ADMIN } from "../../UI/GlobalVariables";
import { OAuthCtx } from "../../../oauth/OAuthContext";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
    padding: 10,
  },
  button: {
    whiteSpace: "nowrap",
  },
});

export default function CreateCosting({
  category,
  setCategory,
  id,
  handleSave,
  openCreate,
  setOpenCreate,
  disable,
  setDisable,
  textFieldMsg,
  handleOpenAllRows,
  handleCloseAllRows,
  setState,
  state,
  setDefaultObjForAutocomplete,
  buttonFrom,
  setIncludesPrice,
  costingUseIn,
  groupCode,
  setGroupCode,
  editable,
  estimateStatus,
  type,
}) {
  const { hasValidAccess } = React.useContext(OAuthCtx);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleAdd = () => {
    setGroupCode("");
    setCategory("");
    setOpenCreate(!openCreate);
    setDisable(false);
  };
  const handleClose = () => {
    setDisable(true);
    setOpenCreate(!openCreate);
  };
  const handleChangeInput = (value) => {
    setCategory(value);
    setDisable(false);
  };
  const handlePriceIncluded = (e) => {
    e.target.checked ? setIncludesPrice(true) : setIncludesPrice(false);
  };
  const handleOpenCreateNewJobPlanDialog = (e) => {
    setState({ ...state, ["right"]: true });
    setDefaultObjForAutocomplete([""]);
  };
  //Costings
  const isCosting = window.location.pathname.split('/')

  return (
    <React.Fragment>
      <div>
        {" "}
        <Grid container>
          <Grid item xs={12}>
            <Box my={1}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={9}>
                  <ButtonGroup disableElevation>
                    <Button variant="contained" onClick={handleOpenAllRows} size="small" color="primary">
                      EXPAND
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleCloseAllRows}
                      size="small"
                      color="secondary"
                    >
                      COLLAPSE
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={3} align="right">
                  {hasValidAccess(ESTIMATE_WRITE) && (
                    <ButtonGroup disableElevation>
                      {openCreate ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={handleClose}
                          className={classes.button}
                        >
                          <CloseIcon onClick={handleAdd} />
                          Close
                        </Button>
                      ) : type === "jobPlan" ? (
                        editable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleAdd}
                            className={classes.button}
                          >
                            <AddIcon onClick={handleAdd} />
                            ADD

                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleAdd}
                            className={classes.button}
                            disabled
                          >
                            <AddIcon onClick={handleAdd} />
                            ADD
                          </Button>
                        )
                      ) : (
                        (isCosting[1] === "cost-centre" ? <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleAdd}
                          className={classes.button}

                        >
                          <AddIcon onClick={handleAdd} />
                          ADD
                        </Button> : <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleAdd}
                          className={classes.button}
                          disabled={estimateStatus !== "DRAFT" ? true : false}
                        >
                          <AddIcon onClick={handleAdd} />
                          ADD
                        </Button>)
                      )}
                      {disable ? (
                        <Button
                          disabled
                          variant="contained"
                          size="small"
                          align="right"
                          onClick={handleSave}
                          className={classes.button}
                        >
                          <CheckCircleOutlineIcon onClick={handleSave} />
                          SAVE
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          align="right"
                          onClick={handleSave}
                          className={classes.button}
                        >
                          <CheckCircleOutlineIcon onClick={handleSave} />
                          SAVE
                        </Button>
                      )}
                      {/* {buttonFrom === "cost-centre-nav" && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.button}
                  onClick={(e) => handleOpenCreateNewJobPlanDialog(e)}
                >
                  New Job Plan
                </Button>
              )} */}
                    </ButtonGroup>
                  )}
                  <Collapse in={openCreate} timeout="auto" unmountOnExit>
                    <div className={clsx(classes.fullList)} role="presentation">
                      <Grid
                        container
                        mt={1}
                        spacing={2}
                        direction="rows"
                        alignContent={"flex-end"}
                        justifyContent={"flex-end"}
                      >
                        <Grid item>
                          <TextField
                            size="small"
                            required
                            id="groupCode"
                            name="groupCode"
                            value={groupCode}
                            variant="outlined"
                            onChange={(e) => {
                              setGroupCode(e.target.value);
                            }}
                            label={"Group Code"}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            size="small"
                            required
                            id="name"
                            name="name"
                            value={category}
                            variant="outlined"
                            onChange={(e) => {
                              handleChangeInput(e.target.value);
                            }}
                            label={"Group Name"}
                          />
                        </Grid>
                      </Grid>
                      {costingUseIn === "specifications" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              name="priceIncluded"
                              onChange={(e) => handlePriceIncluded(e)}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            />
                          }
                          label="Price Included"
                          labelPlacement="start"
                        />
                      )}
                    </div>
                  </Collapse>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
