/** @format */
import React, { useEffect, useState } from "react";
import { OAuthCtx } from "../../oauth/OAuthContext";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import CreateNewJobPlanDialog from "./job-plan/CreateNewJobPlanDialog";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import ProgressBar from "../UI/ProgressBar";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip, Zoom } from "@mui/material";
import { TableCell } from "@mui/material";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import { deleteJobPlabyID, getJobPlanByCostCentreId } from "./job-plan/jobplan.helpers";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import { getSingleCostCentre } from "./costcenter.helpers";
import { useHistory } from "react-router-dom";
import {
  COST_CENTRE_DELETE,
  COST_CENTRE_WRITE,
  JOB_PLAN_DELETE,
  JOB_PLAN_WRITE,
} from "../UI/GlobalVariables";

const useStyles = makeStyles({
  table: {
    // minWidth: 250,
  },
  oneRow: {
    // borderBottom: "none",
    // width: "10vw",
  },
  rowDark: {
    // color: "#fff",
    // fontWeight: "bold",
    // borderBottom: "none",
    // width: "10vw",
    // cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "#cfecf8",
    },
  },
});

function CostCentreExpandForJobPlans({
  costCentreId,
  setOpenCustSnackbarCost,
  setSnackbarMSGCost,
  setSnackbarSeverityCost,
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [jobPDefaultObjForAutocomplete, setJobPDefaultObjForAutocomplete] = useState(null);
  const [loadResponse, setLoadResponse] = useState(false);
  const [wantToCallGetJobPlan, setWantToCallGetJobPlan] = useState(true);
  const [jobplanId, setJobPlanID] = useState();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [jobPlanResponse, setJobPlanResponse] = useState([]);
  const [confirmDeleteNew, setConfirmDeleteNew] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  //const [snackbarMessage, setSnackbarMessage] = useState("Cost Centres deleted successfully!");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [costCentreSingleObj, setCostCentreSingleObj] = React.useState([]);
  const [allCostCentres, setAllCostCentres] = useState([]);
  const [buttonPressFrom, setButtonPressFrom] = useState("");

  const [dummyArrayForJobPlan, setdummyArrayForJobPlan] = useState({
    costCentreId: "",
    costCentreNo: "",
    costCentreDescription: "",
    jobPlanId: "",
    jobPlanNo: "",
    jobPlanName: "",
    jobPlanDescription: "",
  });

  /* ---------------------------------------------------------------- */

  const handleNo = () => {
    setConfirmDeleteNew(false);
    setShowConfirm(false);
  };
  /* ---------------------------------------------------------------- */
  const handleOpenCreateNewJobPlanDialog = (e) => {
    setButtonPressFrom("newPlanFromJobplan");

    setJobPDefaultObjForAutocomplete(dummyArrayForJobPlan);
    setState({ ...state, ["right"]: true });
  };
  useEffect(() => {
    console.log("contactId", costCentreId);
    if (wantToCallGetJobPlan) {
      getJobPlanByCostCentreId(setJobPlanResponse, costCentreId, setLoadResponse);
      setWantToCallGetJobPlan(false);
    }
  }, [wantToCallGetJobPlan]);
  /* ---------------------------------------------------------------- */
  const handleCopyFromJobPlan = (e, row) => {
    console.log(row);
    setButtonPressFrom("copyFromJobplan");
    setJobPDefaultObjForAutocomplete(row);
    setState({ ...state, ["right"]: true });
  };
  /* ---------------------------------------------------------------- */
  const handleDeleteJobPlan = (e, item) => {
    deleteJobPlabyID(jobplanId, setDelLoading, setOpenSnackbar, setSnackbarMSG, setSnackbarSeverity);
    const newList = jobPlanResponse.filter((el) => el.jobPlanId !== jobplanId);
    setJobPlanResponse(newList);
    // setSnackbarMessage("Job Plan deleted successfully!");
    setConfirmDeleteNew(false);
    setShowConfirm(false);
  };
  const handleConfirm = (e, item) => {
    e.preventDefault();
    console.log("e ", item);
    setJobPlanID(item.jobPlanId);
    setShowConfirm(true);
  };
  /* ---------------------------------------------------------------- */

  useEffect(() => {
    getSingleCostCentre(setAllCostCentres, setCostCentreSingleObj, costCentreId);
  }, []);

  /* ---------------------------------------------------------------- */
  return (
    <>
      <TableRow className="expandRow">
        <TableCell
          sx={{
            backgroundColor: "rgb(106 106 106 / 5%)",
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Box p={1}>
            {hasValidAccess(JOB_PLAN_WRITE) && state.right === true && (
              <CreateNewJobPlanDialog
                costCentreId={costCentreId}
                setLoadResponse={setLoadResponse}
                setWantToCallGetJobPlan={setWantToCallGetJobPlan}
                wantToCallGetJobPlan={wantToCallGetJobPlan}
                state={state}
                setState={setState}
                buttonCameFrom={"costCenterHome"}
                defaultObjForAutocomplete={jobPDefaultObjForAutocomplete}
                setDefaultObjForAutocomplete={setJobPDefaultObjForAutocomplete}
                costCentreSingleObj={costCentreSingleObj}
                setSnackbarSeverity={setSnackbarSeverityCost}
                setSnackbarMSG={setSnackbarMSGCost}
                setOpenSnackbar={setOpenCustSnackbarCost}
                buttonPressFrom={buttonPressFrom}
              />
            )}
            {hasValidAccess(JOB_PLAN_WRITE) && (
              <Box py={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={(e) => handleOpenCreateNewJobPlanDialog(e)}
                >
                  New Job Plan
                </Button>
              </Box>
            )}
            {loadResponse ? (
              <ProgressBar />
            ) : (
              <React.Fragment>
                {jobPlanResponse.length === 0 ? (
                  <>
                    <EmptyEstimatesDialog
                      heading="Empty Job Plans"
                      msg="You don't have any JobPlans here yet.
                                                  Please create New Job Plan"
                    />
                  </>
                ) : (
                  <>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 50 }}>
                            <strong>Code</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: 200 }}>
                            <strong>Name</strong>
                          </TableCell>
                          <TableCell sx={{ width: "100%", minWidth: 300 }}>
                            <strong>Description</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: 200 }} align="right" padding="none">
                            <strong> Actions</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobPlanResponse.map((item) => (
                          <>
                            <TableRow>
                              <TableCell>{item.jobPlanNo}</TableCell>
                              <TableCell>{item.jobPlanName}</TableCell>
                              <TableCell>{item.jobPlanDescription}</TableCell>
                              <TableCell align="right">
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                >
                                  <Tooltip
                                    title="EDIT JOB PLAN"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      color="primary"
                                      size="small"
                                      onClick={() => history.push(`/job-plan/${item.jobPlanId}/items`)}
                                      // to={`/job-plan/${item.jobPlanId}/items`}
                                    >
                                      <EditIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    title="COPY JOB PLAN"
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Button
                                      style={{
                                        border: "1px solid #1976d2",
                                        backgroundColor: "#EDF5FD",
                                      }}
                                      size="small"
                                      variant="contained"
                                      onClick={(e) => {
                                        handleCopyFromJobPlan(e, item);
                                      }}
                                    >
                                      <FileCopyIcon
                                        fontSize="small"
                                        color="action"
                                        style={{ color: "#1976d2" }}
                                      />
                                    </Button>
                                  </Tooltip>
                                  {hasValidAccess(JOB_PLAN_DELETE) && (
                                    <Tooltip
                                      title="DELETE JOB PLAN"
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <Button
                                        size="small"
                                        color="secondary"
                                        value={item.jobPlanId}
                                        onClick={(e) => handleConfirm(e, item)}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          value={item.jobPlanId}
                                          onClick={(e) => handleConfirm(e, item)}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </ButtonGroup>
                                {showConfirm && (
                                  <>
                                    <Dialog
                                      open={showConfirm}
                                      onClose={handleNo}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        {"Confirm Delete"}
                                      </DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          Are you sure to delete
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={(e) => handleDeleteJobPlan(e, item)}
                                          color="primary"
                                          autoFocus
                                        >
                                          Yes
                                        </Button>
                                        <Button onClick={handleNo}>No</Button>
                                      </DialogActions>
                                    </Dialog>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </React.Fragment>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {openSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </>
  );
}

export default CostCentreExpandForJobPlans;
