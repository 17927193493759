/** @format */

import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { createContactFromAutocomplete, updateContactFromAutocomplete } from "./contacts.helpers";
import errorStyles from "../UI/ErrorStyles";
import { ButtonGroup, MenuItem, Select } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
  box: {
    display: "flex",
  },
}));

function CreateContactDrawer({
  contactData,
  contacts,
  setContacts,
  recipientsEmail,
  setRecipientsEmail,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setContactDrawerState,
  contactDrawerState,
  setContactData,
  callFrom,
}) {
  const ErrorClasses = errorStyles();
  const classes = useStyles();
  const [showPostelAddress, setShowPostelAddress] = useState(true);

  /*--------------------Submit Contacts Details ------------------*/
  const submitContactDetails = (formData) => {
    if (formData.workPhone) {
      clearErrors(["homePhone", "mobile"]);
    } else if (formData.homePhone) {
      clearErrors(["workPhone", "mobile"]);
    } else if (formData.mobile) {
      clearErrors(["workPhone", "homePhone"]);
    }
    if (showPostelAddress) {
      formData.Postalmobile = formData?.mobile;
      formData.postalAddress = formData?.address;
      formData.postalCityOrTown = formData?.cityOrTown;
      formData.postalState = formData?.state;
      formData.postalPostcode = formData?.postcode;
      formData.postalWorkPhone = formData?.workPhone;
    }

    if (contactData?.contactId) {
      updateContactFromAutocomplete(
        formData,
        contacts,
        setContacts,
        recipientsEmail,
        setRecipientsEmail,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setContactDrawerState,
        callFrom
      );
    } else {
      createContactFromAutocomplete(
        formData,
        contacts,
        setContacts,
        recipientsEmail,
        setRecipientsEmail,
        setOpenSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setContactDrawerState
      );
    }
    setShowPostelAddress(true);
  };
  //   -------------------------------------------------------------------
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({ mode: "onChange" });

  // const handleSubmit =(e)=>{
  //     if(workPhone.length===0||homePhone.length===0||mobile.length===0){
  //       isSameDateError
  //     }
  // }
  //   -------------------------------------------------------------------
  useEffect(() => {
    contactData ? reset(contactData) : reset({});
    return () => {
      // Cleanup
      console.log("CLEANUP");
      setContactData(null);
    };
  }, []);

  const handleSamePostalAddress = () => {
    let ContactDetails = getValues();
    ContactDetails.Postalmobile = ContactDetails?.mobile;
    ContactDetails.postalAddress = ContactDetails?.address;
    ContactDetails.postalCityOrTown = ContactDetails?.cityOrTown;
    ContactDetails.postalState = ContactDetails?.state;
    ContactDetails.postalPostcode = ContactDetails?.postcode;
    ContactDetails.postalWorkPhone = ContactDetails?.workPhone;
    setContactData(ContactDetails);

    setShowPostelAddress(!showPostelAddress);
  };

  function formatMobileNumber(number) {
    // Remove all non-numeric characters from the input
    const numericOnly = number.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 4 and format accordingly
    if (numericOnly.length <= 4) {
      return numericOnly;
    } else if (numericOnly.length <= 7) {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4)}`;
    } else {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4, 7)}-${numericOnly.slice(7, 10)}`;
    }
  }
  const formatWorkPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  //   -------------------------------------------------------------------

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Drawer
          anchor={"right"}
          open={contactDrawerState.right}
          PaperProps={{
            sx: {
              width: "30%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            <form onSubmit={handleSubmit(submitContactDetails)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    color="primary"
                    mb={1}
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="contactName"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        size="small"
                        label="Contact Name"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.contactName?.type === "required" && "First name is required"}
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="businessName"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Business Name"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.businessName && "Business name is required"}{" "}
                  </p>
                </Grid>
                <Grid item xs={12} my={-2}>
                  <InputLabel id="labelContactGroup">Business Type</InputLabel>
                  <Controller
                    name="businessType"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={classes.textField}
                        size="small"
                        disabled={contactData?.editable ? false : true}
                      >
                        <MenuItem value="Contractors">Contractors</MenuItem>
                        <MenuItem value="Employee">Employee</MenuItem>
                        <MenuItem value="Supplier">Suppliers</MenuItem>
                      </Select>
                    )}
                  />

                  <p className={ErrorClasses.errorMsg}>
                    {errors.businessType && "Business Type required"}{" "}
                  </p>
                </Grid>
                <Grid item xs={6} mt={1.5}>
                  <Controller
                    name="businessNumber"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Business Number"
                        variant="outlined"
                        type="number"
                        size="small"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.businessNumber && "Business Number required"}{" "}
                  </p>
                </Grid>
                <Grid item xs={6} mt={1.5}>
                  <Controller
                    name="registeredForGst"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={field.onChange}
                            checked={field.value}
                            disabled={contactData?.editable ? false : true}
                          />
                        }
                        label="Registered for GST"
                        size="small"
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.registeredForGst && " GST required"} </p>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Address"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.address && "Address required"} </p>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="cityOrTown"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="City / Town"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.cityOrTown && "City/Town required"} </p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="State"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.state && "State required"} </p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="postcode"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Postcode"
                        type="number"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.postcode && "Postcode required"} </p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="mobile"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mobile"
                        variant="outlined"
                        size="small"
                        rules={{
                          required: false,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                        }}
                        onChange={(e) => {
                          const formattedNumber = formatMobileNumber(e.target.value);
                          field.onChange(formattedNumber);
                        }}
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.mobile?.type === "required" && "Mobile Number required"}
                    {errors.mobile?.type === "minLength" && "Please enter valid Mobile number"}
                    {errors.mobile?.type === "maxLength" && "Please enter valid Mobile number"}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="workPhone"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Work Phone"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        rules={{
                          required: false,
                          minLength: 9,
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                        }}
                        onChange={(e) => {
                          const formattedNumber = formatWorkPhoneNumber(e.target.value);
                          field.onChange(formattedNumber);
                        }}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>{errors.workPhone && "Work Phone required"} </p>
                </Grid>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={handleSamePostalAddress}
                        defaultChecked
                        value={showPostelAddress}
                        disabled={contactData?.editable ? false : true}
                      />
                    }
                    label="Postal Address Same As Address"
                    size="small"
                  />
                </Grid>
                {!showPostelAddress && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="postalAddress"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Address"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />
                      {errors.address && "postalAddress required"}
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="postalCityOrTown"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal City / Town"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />

                      {errors.postalCityOrTown && "City/Town required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalState"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal State"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />
                      {errors.postalState && "postalState required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalPostcode"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Post code"
                            type="number"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />
                      {errors.postalPostcode && "PostalPostcode required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="Postalmobile"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Mobile"
                            variant="outlined"
                            size="small"
                            rules={{
                              required: false,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatMobileNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            className={classes.textField}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.Postalmobile?.type === "required" && "Postalmobile Number required"}
                        {errors.Postalmobile?.type === "minLength" && "Please enter valid Mobile number"}
                        {errors.Postalmobile?.type === "maxLength" && "Please enter valid Mobile number"}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalWorkPhone"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="postal Work Phone"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                            rules={{
                              required: false,
                              minLength: 9,
                              maxLength: 9,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatWorkPhoneNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            disabled={contactData?.editable ? false : true}
                          />
                        )}
                      />
                      {errors.postalPhone && "postalPhone required"}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="emailAddress"
                    control={control}
                    rules={{
                      required: true,
                      pattern:
                        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        size="small"
                        onChange={field.onChange}
                        variant="outlined"
                        className={classes.textField}
                        disabled={contactData?.editable ? false : true}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.emailAddress?.type === "required" && "Email required"}
                    {errors.emailAddress?.type === "pattern" && "Please enter a valid email"}
                  </p>
                </Grid>

                <Grid item xs={12} align="right">
                  {contactData?.contactId ? (
                    <Button
                      size="small"
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={contactData?.editable ? false : true}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="small"
                      type="submit"
                      variant="contained"
                      disabled={contactData?.editable ? false : true}
                    >
                      save
                    </Button>
                  )}
                  <Button
                    type="button"
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setContactDrawerState({ right: false });
                      setContactData({ editable: true });
                    }}
                    style={{ marginLeft: "15px" }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Drawer>
      </Grid>
    </Grid>
  );
}

export default CreateContactDrawer;
