/** @format */

import apis from "../../utils/apis";

//Sample cost center id : 04787120-2804-4b6e-a230-38eac8751222
export const getCostCentres = (setCostCentres, setDataForCostAuto, setLoad) => {
  apis
    .GET({
      endpoint: "/cost-centres",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.costCentreNo - b.costCentreNo;
      });
      let filteredData = res.data.filter((item) => {
        return item.editable != false;
      });
      setCostCentres(filteredData);
      setDataForCostAuto(res.data);
      setLoad(false);
    });
};
export const isCostCenterEditable = (costCentreId, setEditableCostCenter) => {
  apis
    .GET({
      endpoint: "/cost-centres",
    })
    .then((res) => {
      if (costCentreId) {
        let filteredData = res.data.filter((el) => {
          return el.costCentreId === costCentreId;
        });
        setEditableCostCenter(filteredData[0].editable);
      }
    });
};

export const getSingleCostCentre = (setCostCentres, setCostCentreIdForJobPlan, costCentreId) => {
  apis
    .GET({
      endpoint: "/cost-centres",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.costCentreNo - b.costCentreNo;
      });
      let filteredData = res.data.filter((item) => {
        return item.editable != false;
      });
      let singleCostCentre = filteredData.filter((ele) => {
        return ele.costCentreId === costCentreId;
      })[0];
      console.log("singleCostCentre:", singleCostCentre);
      setCostCentres(filteredData);
      setCostCentreIdForJobPlan(singleCostCentre);
    });
};

export const createCostCentres = (
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setStateForCreateCostCentre,
  stateForCreateCostCentre,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/cost-centre",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      console.log("res cost centre", res);
      if (res.status === 200) {
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setSnackbarMSG("Cost Center created successFully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setStateForCreateCostCentre({ ...stateForCreateCostCentre, ["right"]: false });
      } else {
        setSnackbarMSG("Cost Cntre creation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

export const deleteCostCentrebyID = (
  costCentreId,
  wantToCallGet,
  setWantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .DELETE({
      endpoint: `/cost-centre/${costCentreId}`,
    })
    .then((res) => {
      console.log("deleted Success");
      if (res.data.message === "SUCCESS") {
        setSnackbarMSG("Cost Center deleted successFully!");
        setSnackbarSeverity("success");
        WantCallUSeEffect(wantToCallGet, setWantToCallGet);
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      } else {
        setSnackbarMSG("Cost Centre delation failed");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenCustSnackbar(true);
      }
    });
};

//get building lineitems
export const getItemsByCostCentreId = (
  setRows,
  costCentreId,
  setLoad,
  setTempRows,
  setTempGroups,
  setOpenAllRows
) => {
  setLoad(true);
  apis
    .GET({
      endpoint: `/cost-centre/${costCentreId}/items`,
    })
    .then((res) => {
      var tempOpenRows = [];
      for (var i = 0; i < res.data.length; i++) {
        tempOpenRows[i] = true;
      }
      setOpenAllRows(tempOpenRows);
      setLoad(false);

      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setRows(res.data);
      setTempRows(res.data);
      setTempGroups(JSON.parse(JSON.stringify(res.data)));
      console.log("building rows ", res.data);
    })
    .catch((err) => console.log("No building data "));
};

export const updateItemsByCostCentreId = (
  setRows,
  costCentreId,
  arr,
  //setLoad,
  setDataPostSuccess,
  setOpen,
  setSnackbarSeverity,
  setSnackbarMSG,
  setTempGroups,
  setRoundLoader,
) => {
  setOpen(false);
  // setLoad(true);
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/cost-centre/${costCentreId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      //setLoad(false);

      console.log("data 2", res.data);
      if (res.data.message == "SUCCESS") {
        // if (arr[arr.length - 1].items.length != 0) {
        //   setDataPostSuccess(true);
        // }
        setRows(arr);
        setRoundLoader(false);
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        setSnackbarMSG("Groups Updated Succesfully");
        setSnackbarSeverity("success");
        setOpen(true);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarMSG("Groups Update failed");
        setSnackbarSeverity("error");
        setOpen(true);
        setRoundLoader(false);
        console.log("building posting failed>>>");
      }
    });
};
/* want to getOldData for cancel button */
export const getCostCentreItems = (setRows, costCentreId) => {
  apis
    .GET({
      endpoint: `/cost-centre/${costCentreId}/items`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.groupCode - b.groupCode;
      });

      setRows(res.data);

      console.log("building rows ", res.data);
    })
    .catch((err) => console.log("No building data "));
};

/* this is for call useEffect*/
function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
export const updateCostCentreItemById = (
  costCentreId,
  arr,
  setSnackbarSeverity,
  setSnackbarMSG,
  setOpenSnackbar,
  setTempGroups,
  setRoundLoader,
) => {
  console.log("len in api ", arr);

  console.log("data 2", arr);
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/cost-centre/${costCentreId}/items`,
      payload: arr,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      console.log("data 2", res.data);
      if (res.data.message == "SUCCESS") {
        setTempGroups(JSON.parse(JSON.stringify(arr)));
        setSnackbarSeverity("success");
        setSnackbarMSG("Items Updated Succesfully!");
        setOpenSnackbar(true);
        setRoundLoader(false);
        console.log("building data posting success >>>", arr);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMSG("Items Update failed");
        setOpenSnackbar(true);
        setRoundLoader(false);
        console.log("building posting failed>>>");
      }
    });
};
