import React, { useEffect, useContext } from "react";
import { OAuthCtx } from "./OAuthContext";

import Loading from "../components/UI/Loading";

export default function OAuthlogout() {
  const { logout } = useContext(OAuthCtx);

  useEffect(() => {
    logout();
  }, []);

  return <Loading />;
}
