/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createFolder, deleteFile, getFile, listFiles, uploadFile } from "./files.helpers";
import MUIDataTable from "mui-datatables";
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { StyledDropZone } from "../estimates/TakeoffPlans/StyledDropZone";
import SimpleBackdrop from "../UI/SimpleBackdrop";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import ProgressBar from "../UI/ProgressBar";
import EmptyEstimatesDialog from "../UI/EmptyEstimatesDialog";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { DialogTitle } from "@mui/material";
import { DialogContentText } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FolderIcon from "@mui/icons-material/Folder";
import WebViewer from "@pdftron/webviewer";
import WebViewerForFile from "./WebViewerForFile";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   Drawerroot: {
//     width: "100%",
//     padding: 16,
//   },
//   textField: {
//     width: "100%",
//   },
//   box: {
//     display: "flex",
//   },
// }));

function FileManager({ callFrom }) {
  // const classes = useStyles();
  // const viewer = useRef(null);
  var { jobId, estimateId } = useParams();
  const id = jobId ? jobId : estimateId;
  const [roundLoader, setRoundLoader] = useState(false);
  const [fileUploadDrawer, setFileUploadDrawer] = useState(false);
  const [fileData, setFileData] = useState();
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [folderName, setFolderName] = useState("");
  const [openCustSnackbar, setOpenCustSnackbar] = React.useState(false);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [createFolderDrawer, setCreateFolderDrawer] = React.useState(false);
  const [load, setLoad] = useState(true);
  const [fileList, setFileList] = React.useState([]);
  const [refreshFiles, setRefreshFiles] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [filePathArray, setFilePathArray] = React.useState([]);
  const [fileUrl, setFileUrl] = React.useState("");
  const [openWebViewer, setOpenWebViewer] = React.useState(false);

  const linkCss = {
    textDecoration: "none",
    cursor: "pointer",
    color: "#5A8C24",
  };
  var payload = { name: "pds1.pdf", path: "pds1" };
  useEffect(() => {
    listFiles(id, "", setLoad, setFileList, callFrom);
  }, []);
  console.log("ID:", id);
  useEffect(() => {
    if (refreshFiles) {
      listFiles(id, filePath, setLoad, setFileList, callFrom);
      setRefreshFiles(false);
    }
  }, [refreshFiles]);

  const openFolder = (path, folderName) => {
    var matched = false;
    var newPathArray = [];
    var newPath = "";
    setRoundLoader(true);
    const splitArray = path.split("/");
    splitArray.filter((string) => {
      if (estimateId === string) {
        matched = true;
      }

      if (matched && string !== "") {
        // newPath = newPath + "/" + string;
        newPathArray.push(string);
      }
    });
    newPathArray.shift();
    setFilePathArray(newPathArray);
    newPathArray.filter((item, i) => {
      if (newPathArray.length == ++i) {
        newPath = newPath + item;
      } else {
        newPath = newPath + item + "/";
      }
    });
    setFilePath(newPath);
    console.log("newPath:", newPath);
    listFiles(id, newPath, setRoundLoader, setFileList, callFrom);
  };

  const openFolderFromNavigation = (name) => {
    setRoundLoader(true);
    var newPath = "";
    var filterArray = [];
    filePathArray.some((string) => {
      if (string === name) {
        filterArray.push(string);
        return true;
      } else {
        filterArray.push(string);
      }
    });
    setFilePathArray(filterArray);
    filterArray.filter((item, i) => {
      if (filterArray.length == ++i) {
        newPath = newPath + item;
      } else {
        newPath = newPath + item + "/";
      }
    });
    setFilePath(newPath);
    console.log("PATHPATH:", newPath);
    listFiles(id, newPath, setRoundLoader, setFileList, callFrom);
  };

  const handleDelete = () => {
    deleteFile(
      id,
      { name: fileName, path: filePath },
      setRoundLoader,
      setRefreshFiles,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setOpenDeleteDialog,
      callFrom
    );
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta) => {
          return fileList[tableMeta.rowIndex].folder ? (
            <Link style={linkCss} onClick={() => openFolder(fileList[tableMeta.rowIndex].key, value)}>
              <FolderIcon /> {value}
            </Link>
          ) : (
            <Link
              style={linkCss}
              onClick={() =>
                getFile(
                  id,
                  { name: value, path: filePath },
                  setRoundLoader,
                  setOpenCustSnackbar,
                  setSnackbarMSG,
                  setSnackbarSeverity,
                  setFileUrl,
                  setOpenWebViewer,
                  callFrom
                )
              }
            >
              <TextSnippetIcon /> {value}
            </Link>
          );
        },
      },
    },
    {
      name: "folder",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: { minWidth: "10px", maxWidth: "10px" },
        }),
        setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "10px" } }),

        customBodyRender: (value, tableMeta) => {
          return value ? <div>Folder</div> : <div>File</div>;
        },
      },
    },

    {
      name: "size",
      label: "Size",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: { minWidth: "10px", maxWidth: "10px" },
        }),
        setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "10px" } }),
      },
    },
    {
      name: "name",
      label: "Action",
      options: {
        setCellHeaderProps: () => ({
          style: { minWidth: "10px", maxWidth: "10px" },
        }),
        setCellProps: () => ({ style: { minWidth: "10px", maxWidth: "10px" } }),
        customBodyRender: (name, tableMeta) => {
          return (
            <Tooltip title="DELETE" placement="top" TransitionComponent={Zoom} arrow>
              <Button
                size="small"
                color="secondary"
                onClick={(e) => {
                  setFileName(name);
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Tooltip>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
  };

  // useEffect(() => {
  //   console.log("FILEURL:", fileUrl);
  //   fileUrl &&
  //     WebViewer(
  //       {
  //         path: "/webviewer/lib",
  //         initialDoc: fileUrl,
  //       },
  //       viewer.current
  //     ).then((instance) => {
  //       // setInstance(instance);
  //       const { documentViewer, annotationManager, Annotations } = instance.Core;
  //       documentViewer.addEventListener("documentLoaded", () => {
  //         const rectangleAnnot = new Annotations.RectangleAnnotation({
  //           PageNumber: 1,
  //           // values are in page coordinates with (0, 0) in the top left
  //           X: 100,
  //           Y: 150,
  //           Width: 200,
  //           Height: 50,
  //           Author: annotationManager.getCurrentUser(),
  //         });
  //         annotationManager.addAnnotation(rectangleAnnot);
  //         // need to draw the annotation otherwise it won't show up until the page is refreshed
  //         annotationManager.redrawAnnotation(rectangleAnnot);
  //       });
  //     });
  // }, [fileUrl]);

  return (
    <>
      {roundLoader && <SimpleBackdrop />}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure to delete</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete()} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {createFolderDrawer && (
        <Dialog
          open={createFolderDrawer}
          onClose={() => setCreateFolderDrawer}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" color="primary" sx={{ pb: 1 }}>
            {"Create Folder"}
          </DialogTitle>

          <DialogContent>
            <TextField
              value={folderName}
              label="Folder Name"
              variant="outlined"
              size="small"
              onChange={(e) => setFolderName(e.target.value)}
              sx={{ mt: 1 }}
            />
          </DialogContent>
          <Grid item xs={12} sm={12} align="right" style={{ margin: "10px" }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "600",
              }}
              onClick={() => {
                setCreateFolderDrawer(false);
                createFolder(
                  id,
                  { name: folderName, path: filePath },
                  setRoundLoader,
                  setOpenCustSnackbar,
                  setSnackbarMSG,
                  setSnackbarSeverity,
                  setCreateFolderDrawer,
                  setRefreshFiles,
                  callFrom
                );
              }}
            >
              Create
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={() => setCreateFolderDrawer(false)}
              variant="contained"
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Dialog>
      )}
      {fileUploadDrawer && (
        <Dialog
          open={fileUploadDrawer}
          onClose={() => setFileUploadDrawer(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle align="center" id="alert-dialog-title" color="primary">
            {"Upload File"}
          </DialogTitle>

          <DialogContent>
            <Divider />
            <Grid container spacing={3}>
              <Grid item xs={12} mt={1}>
                <TextField
                  value={fileName}
                  label="File- Name"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFileName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledDropZone setPlanFile={setFileData} setFileName={setFileName} />
              </Grid>
            </Grid>
          </DialogContent>
          <Grid item xs={12} sm={12} align="right" style={{ margin: "10px" }}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "600",
              }}
              onClick={() => {
                setFileUploadDrawer(false);
                uploadFile(
                  id,
                  { name: fileName, path: filePath, fileData },
                  setRoundLoader,
                  setRefreshFiles,
                  setOpenCustSnackbar,
                  setSnackbarMSG,
                  setSnackbarSeverity,
                  setFileUploadDrawer,
                  callFrom
                );
              }}
            >
              Upload
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={() => setFileUploadDrawer(false)}
              variant="contained"
              style={{
                marginRight: "10px",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Dialog>
      )}
      {openWebViewer ? (
        <Drawer
          anchor={"right"}
          open={openWebViewer}
          // onClose={setPlanDrawerOpen(false)}
          PaperProps={{
            sx: {
              width: "80%",
            },
          }}
        >
          <WebViewerForFile fileUrl={fileUrl} setOpenWebViewer={setOpenWebViewer} />
        </Drawer>
      ) : (
        <>
          <Grid container direction={"column"}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    style={linkCss}
                    onClick={() => {
                      setRoundLoader(true);
                      listFiles(id, "", setRoundLoader, setFileList, callFrom);
                      setFilePathArray([]);
                      setFilePath("");
                    }}
                  >
                    Home
                  </Link>
                  {filePathArray.length > 0 &&
                    filePathArray.map((name) => {
                      return (
                        <Link style={linkCss} onClick={() => openFolderFromNavigation(name)}>
                          {name}
                        </Link>
                      );
                    })}
                </Breadcrumbs>
              </Grid>
              <Grid item>
                <ButtonGroup variant="contained">
                  <Tooltip title="Create Folder" placement="top" TransitionComponent={Zoom} arrow>
                    <Button size="small" color="warning" onClick={() => setCreateFolderDrawer(true)}>
                      <CreateNewFolderIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Upload File" placement="top" TransitionComponent={Zoom} arrow>
                    <Button size="small" color="info" onClick={() => setFileUploadDrawer(true)}>
                      <UploadFileIcon />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item>
              {load ? (
                <ProgressBar />
              ) : fileList.length < 0 ? (
                <EmptyEstimatesDialog
                  heading="Empty Files"
                  msg="You don't have any files here yet.
Click the 'UPLOAD FILE' button above to get started"
                />
              ) : (
                <MUIDataTable data={fileList} columns={columns} options={options} />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {openCustSnackbar && (
        <CustomizedSnackbars
          openSnackbar={openCustSnackbar}
          setOpenSnackbar={setOpenCustSnackbar}
          snackbarMSG={snackbarMSG}
          severity={snackbarSeverity}
        />
      )}
    </>
  );
}

export default FileManager;
