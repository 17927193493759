/** @format */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import ProgressBar from "../../UI/ProgressBar";
import DateCreator from "../../UI/DateCreator";
import { createVariation } from "../orders/Order.helper";
import AddIcon from "@mui/icons-material/Add";
import { Stack, TextareaAutosize } from "@mui/material";
import { Label } from "recharts";
import DeleteAlertPop from "./DeleteAlertPop";
import { getLineItemsGroupsName } from "./variation.helper";
import { el } from "date-fns/locale";
import SaveIcon from "@mui/icons-material/Save";
import PercentIcon from "@mui/icons-material/Percent";
import NumberFormat from "react-number-format";
import SimpleBackdrop from "../../UI/SimpleBackdrop";
import { checkProperties } from "../../UI/GlobalApi";
import { styled, lighten, darken } from "@mui/system";
import { getGst } from "../../UI/GlobalApi";
import {
  getJobLineItemsByJobIdForAutoselect,
  getJobLineItemsGroupByJobIdForAutoselect,
} from "../actual_costings/helpers.actual.costings";
import EmptyEstimatesDialog from "../../UI/EmptyEstimatesDialog";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Checkbox, Divider, MenuItem, Select, InputAdornment } from "@mui/material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: 600,
    padding: 16,
  },
  textField: {
    width: "100%",
    // margin:'2%'
  },
  button: {
    marginRight: "5px",
  },
  createEstimateDrawer: {
    // width: "400px",
    // padding: "15px",
  },
}));

export default function VariationDrawer({
  purchaseItemcount,
  variationObj,
  setVariationObj,
  setVariation,
  setLoading,
  expiryDate,
  setExpiryDate,
  toggleDrawer,
  state,
  setState,
  jobId,
  setCheckedJobLineItems,
  setEdit,
  userConfigs,
  userConfigsuom,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
}) {
  const filter = createFilterOptions();
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState("");
  const [groupsInfo, setGroupsInfo] = useState([]);
  const [status, setStatus] = useState("DRAFT", "SENT", "ACCEPTED", "REJECTED");
  const [statusDate, setStatusDate] = useState("");
  const classes = useStyles();
  const [roundLoader, setRoundLoader] = useState(false);
  const [variationList, setVariationList] = useState([]);

  /* -------------------------------------------------------- */
  const [variationObject, setVariationObject] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lineItemsForAutoselect, setLineItemsForAutoselect] = React.useState([]);
  const [lineItemsGroupsForAutoselect, setLineItemsGroupsForAutoselect] = React.useState([]);
  /* -------------------------------------------------------- */

  useEffect(() => {
    /* i copied object using JSON method bcz  i have to copy object without any object refrence */
    setVariationObject(JSON.parse(JSON.stringify(variationObj)));
    console.log("useEffect", variationObj);
  }, [variationObj]);

  useEffect(() => {
    console.log("LINE iTEM USEEFFECT");
    getJobLineItemsGroupByJobIdForAutoselect(jobId, setLineItemsGroupsForAutoselect);
    getJobLineItemsByJobIdForAutoselect(setLineItemsForAutoselect, jobId, setDataLoaded);
    getLineItemsGroupsName(jobId, setGroupsInfo, setGroupObject);
  }, []);

  useEffect(() => {
    setStatusDate("");
  }, [status]);

  useEffect(() => {
    statusDate.$d &&
      setStatusDate(
        statusDate.$d
          .toLocaleString("en-us", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      );
  }, [statusDate]);

  /* -------------------------------------------------------- */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    console.log("close");
    setOpenSnackbar(false);
  };
  /* ----------------------------------------------------------- */

  const closeDrawer = () => {
    setState({ ...state, ["right"]: false });
    console.log("useEffect", variationObj);
    // setCheckedJobLineItems([]);
    //to disable main actual table edit
    // setEdit(true);
    //to disable edit table of variation drawer
    setIsEdit(false);
    setDescription("");
    setDetails("");
    setVariationObject([]);
  };
  /* ----------------------------------------------------------- */

  const handleSubmit = (e) => {
    let valid = true;
    let veriationArray = {
      jobId: jobId,
      description: description,
      details: details,
      status: status,
      statusDate: statusDate,
      variationLineItems: variationObj,
    };

    let errMsg = checkProperties(veriationArray);
    if ((status === "Accepted" || status === "Rejected") && statusDate === "") {
      errMsg = errMsg + "Status Date, ";
    }
    if (errMsg !== "") valid = false;

    if (valid === true) {
      createVariation(
        veriationArray,
        setOpenSnackbar,
        setSnackbarSeverity,
        setSnackbarMSG,
        setRoundLoader,
        setState,
        variationList,
        setVariationList,
        setVariation,
        jobId
      );
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
    setDescription("");
    setDetails("");
    setVariationObject([]);
  };
  const handleEdit = (i) => {
    setIsEdit(true);
  };

  const handleAdd = () => {
    // setDisableSave(false);
    // let lastBeforeRowLineId;
    // if (variationObject.length === 0) {
    //   lastBeforeRowLineId = 0;
    // } else {
    //   lastBeforeRowLineId = variationObject[variationObject.length - 1].itemCode;
    // }

    setIsEdit(true);
    setVariationObject([
      ...variationObject,
      {
        variationLineItemId: null,
        jobLineItemId: null,
        itemId: null,
        itemCode: "",
        itemName: "",
        itemGroupCode: "",
        itemGroupName: "",
        itemDescription: "",
        itemCategory: "",
        optionId: null,
        optionName: null,
        type: "",
        qty: 1,
        uom: "",
        unitCost: 0,
        //markupPercentage: 0,
        gst: true,
      },
    ]);
  };
  /* ----------------------------------------------------------- */

  const [gst, setGst] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [groupObject, setGroupObject] = useState([]);
  /* ----------------------------------------------------------- */
  const handleVarInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = JSON.parse(JSON.stringify(variationObject));
    list[index][name] = value;
    console.log("value", value);
    // list[index]["itemGroupName"] = groupsInfo[index];
    if (name === "unitCost" || name === "qty") {
      if (/^\d+(\.\d*)?$/.test(value)) {
        list[index][name] = parseInt(value);
      }
      else {
        list[index][name] = parseInt(0);
      }
    } else {
      list[index][name] = value;
    }
    setVariationObject(list);


  };

  //const [newGroupObject, setNewGroupObject] = useState({});

  // function handleChangeGroupAddObject(e, index, newValue) {
  //   if (newValue) {
  //     console.log("valueinout", newValue);

  //     var listGroup = [...groupObject];

  //     var newGroup = { groupCode: listGroup.length + 1, groupName: newValue };

  //     listGroup.push(newGroup);
  //     //setGroupObject(listGroup);
  //     console.log("newGroup", listGroup);
  //     var list = JSON.parse(JSON.stringify(variationObject));
  //     list[index]["itemGroupName"] = newGroup.groupName;
  //     list[index]["itemGroupCode"] = newGroup.groupCode;
  //     //setVariationObject(list);
  //   }
  // }
  function handleChangeGroupObject(e, index, newValue) {
    console.log("idddndex", newValue);
    if (newValue) {
      console.log("value", newValue);
      var list = JSON.parse(JSON.stringify(variationObject));
      list[index]["itemGroupName"] = newValue.groupName;

      list[index]["itemGroupCode"] = newValue.groupCode;
      //var listGroup = [...groupObject];
      //listGroup[index].groupName = newValue;
      setVariationObject(list);
      console.log("groupObjectcc", groupObject);
    }
  }

  /* ----------------------------------------------------------- */

  const handlesLineItemSave = () => {
    let valid = true;
    let errMsg = checkProperties(variationObject);
    if (errMsg !== "") valid = false;
    if (valid == true) {
      setVariationObject(variationObject);
      setVariationObj(variationObject);
      setIsEdit(false);
    } else {
      setSnackbarMSG(`${errMsg} can not be empty`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
  /* ---------------------------------------------------------------- */

  const handleChangeGST = (e, i, row) => {
    if (!e.target.checked) {
      setNewGstValue(false);
    } else {
      setNewGstValue(true);
    }
    function setNewGstValue(value) {
      const list = JSON.parse(JSON.stringify(variationObject));
      list[i][e.target.name] = value;
      setVariationObject(list);
    }
  };

  /* ---------------------------------------------------------------- */
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState();
  const handleDelete = (itemId) => {
    setDeleteItemId(itemId);
    console.log("cncle");
    setConfirmDelete(true);
    setIsEdit(false);
  };
  /* -------------------------------------------------------- */
  const clearLineItem = (row) => {
    let filterList = variationObject.filter((el) => {
      return row.itemCode != el.itemCode;
    });

    let count = 1;
    var test = filterList.filter(function (el) {
      return (row.itemCode = count++);
    });
    setVariationObject(test);
    console.log("rows", test);
  };

  /* ---------------------------------------------------------------- */
  const handleCancel = () => {
    setVariationObject(variationObj); /* Reset our old data after cancel edit/add operation  */

    setIsEdit(false);
  };
  /* ---------------------------------------------------------------- */

  var total = 0;
  var markuptotalcost = 0;
  var t = variationObject.filter((orderItem) => {
    var totalCost = orderItem.qty * orderItem.unitCost;
    if (orderItem.unitCost != 0) {
      var markup = (totalCost * orderItem.unitCost) / 100;
    } else {
      var markup = 0;
    }
    markuptotalcost = markup + totalCost;
    total = total + markuptotalcost;
    parseInt(total.toFixed(2));
  });
  var gstTotal = 0;
  var t = variationObject.filter((orderItem) => {
    var totalCost = orderItem.qty * orderItem.unitCost;
    var markup = 0;
    if (orderItem.unitCost != 0) {
      markup = (totalCost * orderItem.unitCost) / 100;
    }
    markuptotalcost = markup + totalCost;
    var rowGST = 0;
    if (orderItem.gst != 0) {
      var rowGST = (markuptotalcost * orderItem.gst) / 100;
    }
    parseInt(rowGST.toFixed(2));
    gstTotal = gstTotal + rowGST;
    parseInt(gstTotal.toFixed(2));
  });

  /* ---------------------------------------------------------------- */

  const [valuex, setValuex] = React.useState([]);

  var newItem, newInputItem;
  const AutocompleteGroup = (props) => {
    const { index, orderItem } = props;
    const filter = createFilterOptions();
    //var newItem;
    // const existingGroupValue = groupObject.find(
    //   (group) => group.groupName === orderItem.itemGroupName
    // );

    // if (existingGroupValue) {
    //   groupsInfo.filter((newVal, Grouplistindex) => {
    //     if (newVal.groupName === orderItem.itemGroupName) {
    //       newItem = Grouplistindex;
    //     }
    //   });
    // } else {
    //   if (orderItem.itemGroupName) {
    //     console.log("orderItem.itemGroupName", orderItem.itemGroupName);

    //     var listGroup = [...groupObject];

    //     var newGroup = {
    //       groupCode: listGroup.length + 1,
    //       groupName: orderItem.itemGroupNames,
    //     };
    //     newItem = listGroup.length + 1;
    //     listGroup.push(newGroup);
    //     setGroupsInfo(listGroup);
    //     console.log("newGroup", listGroup);
    //   }
    // }

    return (
      <Autocomplete
        freeSolo
        size="small"
        value={valuex[index]}
        onChange={(event, newValue) => {
          var selectListGroup = [...valuex];

          if (typeof newValue === "string") {
            selectListGroup[index] = {
              title: newValue,
            };
            setValuex(selectListGroup);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input

            var listGroup = [...groupObject];
            var newGroup = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            selectListGroup[index] = {
              groupCode: listGroup.length + 1,
              groupName: newValue.inputValue,
            };
            setValuex(selectListGroup);
            handleChangeGroupObject(event, index, newGroup);
          } else {
            selectListGroup[index] = newValue;
            setValuex(selectListGroup);
            handleChangeGroupObject(event, index, newValue);
          }
          console.log("newValuevvvv", valuex);
        }}
        // onChange={(event, newValue) => {
        //   handleChangeGroupObject(event, index, newValue);
        // }}

        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.groupName);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        getOptionLabel={(option) => {
          console.log("option", option);
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.groupName;
        }}
        //getOptionLabel={(option) => option.groupName}
        options={groupObject.map((option) => option)}
        renderInput={(params) => <TextField {...params} />}
      />
    );
  };
  // ----------------------------Calculations-------------------------------------------------
  var finalSubTotal = 0;
  var finalTax = 0;
  var finalTotal = 0;
  var itemTax = 0;
  var mult = 0;
  variationObject.filter((e) => {
    mult = parseInt(e.unitCost) * parseInt(e.qty);
    if (isNaN(mult)) mult = 0;
    finalSubTotal = finalSubTotal + mult;
    itemTax = (mult * getGst(e.gst)) / 100;
    if (isNaN(itemTax)) itemTax = 0;
    finalTax = finalTax + parseInt(itemTax);
    finalTax = finalTax + (finalTax * parseInt(e.unitCost)) / 100;
    finalTotal = finalSubTotal + finalTax;
    finalTotal = finalTotal + (finalTotal * parseInt(e.unitCost)) / 100;
  });
  // ----------------------------Calculations-Ends-------------------------------------------------
  const handleAutoselectLineItem = (i, row, newValue) => {
    var list = JSON.parse(JSON.stringify(variationObject));
    if (typeof newValue === "string") {
      list[i] = {
        ...list[i],
        itemDescription: newValue,
      };
      setVariationObject(list);
    } else if (typeof newValue === "object") {
      list[i] = {
        ...list[i],
        itemName: newValue?.itemName,
        itemDescription: newValue?.itemDescription,
        type: newValue?.type,
        uom: newValue?.uom,
        unitCost: newValue?.unitCost,
        qty: newValue?.qty,
        gst: newValue?.gst,
        itemGroupName: newValue?.itemGroupName,
        itemGroupCode: newValue?.itemGroupCode,
        itemCode: newValue?.itemCode,
      };
      setVariationObject(list);
    }
  };

  const handleAutoselectLineItemName = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemName: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemName: newValue?.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.unitCost,
          qty: newValue.qty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemCode: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemName: newValue?.itemName,
          itemDescription: newValue.itemDescription,
          type: newValue.type,
          uom: newValue.uom,
          unitCost: newValue.unitCost,
          qty: newValue.qty,
          gst: newValue.gst,
          itemGroupName: newValue.itemGroupName,
          itemGroupCode: newValue.itemGroupCode,
          itemCode: newValue.itemCode,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemGroup = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: "",
          itemGroupName: newValue,
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setVariationObject(list);
      }
    }
  };

  const handleAutoselectLineItemGroupCode = (i, row, newValue) => {
    if (newValue) {
      const list = JSON.parse(JSON.stringify(variationObject));
      if (typeof newValue === "string") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue,
          itemGroupName: "",
        };
        setVariationObject(list);
      } else if (typeof newValue === "object") {
        list[i] = {
          ...list[i],
          itemGroupCode: newValue.groupCode,
          itemGroupName: newValue.groupName,
        };
        setVariationObject(list);
      }
    }
  };
  // ----------------------------------------------------------------------------------------------

  return (
    <>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "80%",
          },
        }}
        classes={classes.createEstimateDrawer}
      >
        {!dataLoaded ? (
          <ProgressBar />
        ) : (
          <Box p={2}>
            {roundLoader && <SimpleBackdrop />}

            <Grid container xs={12} spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Create Variation
                </Typography>

                <Divider />
              </Grid>

              <Grid item xs={4} sm={8}>
                <TextField
                  value={description}
                  required="true"
                  size="small"
                  label="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setStatus(newValue);
                    setStatusDate("");
                  }}
                  value={status}
                  options={["DRAFT", "SENT", "ACCEPTED", "REJECTED"]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Status" size="small" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextareaAutosize
                  name="Details"
                  variant="outlined"
                  minRows={3}
                  maxRows={3}
                  aria-label="maximum height"
                  placeholder="Details"
                  value={details}
                  style={{
                    width: 1480,
                    backgroundColor: `${localStorage.getItem("dark-mode") === "true" ? "#333d49" : "#fff"
                      }`,
                  }}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Grid>

              {(status === "ACCEPTED" || status === "REJECTED") && (
                <Grid item xs={12} sm={4}>
                  <Stack sx={{ width: "100%" }}>
                    <DateCreator
                      setExpiryDate={setStatusDate}
                      expiryDate={statusDate}
                      label={"Status Date"}
                    />
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} p={1}>
              <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box component="span" p={1}>
                  <ButtonGroup variant="contained" aria-label="contained primary button group">
                    {isEdit ? (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button variant="contained" color="primary" onClick={handleAdd} size="small">
                          <AddIcon fontSize="small" />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          align="right"
                          onClick={handleCancel}
                          size="small"
                        >
                          <ClearIcon fontSize="small" />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlesLineItemSave}
                          size="small"
                        >
                          <SaveIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup variant="contained" aria-label="contained primary button group">
                        <Button
                          variant="contained"
                          color="primary"
                          align="right"
                          onClick={handleAdd}
                          size="small"
                        >
                          <AddIcon fontSize="small" />
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          align="right"
                          onClick={handleEdit}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    )}
                  </ButtonGroup>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {variationObject.length < 1 ? (
                  <EmptyEstimatesDialog
                    heading="Empty Line Items"
                    msg="Please add some order items by click on add icon abouv"
                  />
                ) : (
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: "20px" }}>
                            <strong>Code</strong>
                          </TableCell>
                          <TableCell sx={{ minWidth: 195 }}>
                            <strong>Name</strong>
                          </TableCell>
                          <TableCell sx={{ width: 1 / 2, minWidth: 295 }}>
                            <strong>Description</strong>
                          </TableCell>
                          <TableCell align="center">Group Code</TableCell>{" "}
                          <TableCell align="center">Group Name</TableCell>
                          <TableCell align="center" sx={{ minWidth: 155 }}>
                            <strong>Type</strong>
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: 75 }}>
                            <strong>Qty</strong>
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: 75 }}>
                            Unit Cost
                          </TableCell>
                          <TableCell align="center" sx={{ minWidth: 100 }}>
                            <strong>UOM</strong>
                          </TableCell>
                          {!isEdit && <TableCell align="right">Total Cost</TableCell>}
                          <TableCell align="right">GST</TableCell>
                          {/* <TableCell align="right" sx={{ minWidth: 85 }}>
                            Markup
                          </TableCell> */}
                          {isEdit && (
                            <TableCell align="right">
                              <strong>Actions</strong>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      {isEdit ? (
                        <TableBody align={"center"}>
                          {console.log("groupsInfo", groupsInfo)}
                          {variationObject?.map((orderItem, index) => (
                            <TableRow>
                              <TableCell align="center">
                                <Autocomplete
                                  id="grouped-demo"
                                  value={orderItem.itemCode}
                                  options={lineItemsForAutoselect}
                                  groupBy={(option) => option.itemGroupName}
                                  getOptionLabel={(option) => {
                                    console.log("option", typeof option);

                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    else if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    else return `${option.itemCode}: ${option.itemName}`;
                                  }}
                                  onChange={(event, newValue) => {
                                    handleAutoselectLineItemCode(index, orderItem, newValue);
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option.itemCode
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push(inputValue);
                                    }
                                    return filtered;
                                  }}
                                  fullWidth
                                  size="small"
                                  renderInput={(params) => <TextField {...params} />}
                                  renderGroup={(params) => (
                                    <li>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: 100 }}>
                                <Autocomplete
                                  id="grouped-demo"
                                  value={orderItem?.itemName}
                                  options={lineItemsForAutoselect}
                                  groupBy={(option) => option.itemGroupName}
                                  getOptionLabel={(option) => {
                                    console.log("option", typeof option);

                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    else if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    else return `${option.itemCode}: ${option?.itemName}`;
                                  }}
                                  onChange={(event, newValue) => {
                                    handleAutoselectLineItemName(index, orderItem, newValue);
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option?.itemName
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push(inputValue);
                                    }
                                    return filtered;
                                  }}
                                  fullWidth
                                  size="small"
                                  renderInput={(params) => <TextField {...params} />}
                                  renderGroup={(params) => (
                                    <li>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ minWidth: 180 }}>
                                <Autocomplete
                                  id="grouped-demo"
                                  value={orderItem.itemDescription}
                                  options={lineItemsForAutoselect}
                                  groupBy={(option) => option.itemGroupName}
                                  getOptionLabel={(option) => {
                                    console.log("option", typeof option);

                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    else if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    else return `${option.itemCode}: ${option.itemDescription}`;
                                  }}
                                  onChange={(event, newValue) => {
                                    handleAutoselectLineItem(index, orderItem, newValue);
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option.itemDescription
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push(inputValue);
                                    }
                                    return filtered;
                                  }}
                                  fullWidth
                                  size="small"
                                  renderInput={(params) => <TextField {...params} />}
                                  renderGroup={(params) => (
                                    <li>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  minWidth: 80,
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <Autocomplete
                                  id="grouped-demo"
                                  value={orderItem.itemGroupCode}
                                  options={lineItemsGroupsForAutoselect}
                                  // groupBy={(option) => option.itemGroupName}
                                  getOptionLabel={(option) => {
                                    console.log("option", typeof option);

                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    else if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    else return option.groupCode;
                                  }}
                                  onChange={(event, newValue) => {
                                    handleAutoselectLineItemGroupCode(index, orderItem, newValue);
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option.groupCode
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push(inputValue);
                                    }
                                    return filtered;
                                  }}
                                  fullWidth
                                  size="small"
                                  renderInput={(params) => <TextField {...params} />}
                                  renderGroup={(params) => (
                                    <li>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  minWidth: 80,
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <Autocomplete
                                  id="grouped-demo"
                                  value={orderItem.itemGroupName}
                                  options={lineItemsGroupsForAutoselect}
                                  getOptionLabel={(option) => {
                                    console.log("option", typeof option);

                                    // Value selected with enter, right from the input
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    else if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    else return option.groupName;
                                  }}
                                  onChange={(event, newValue) => {
                                    handleAutoselectLineItemGroup(index, orderItem, newValue);
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some(
                                      (option) => inputValue === option.groupName
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push(inputValue);
                                    }
                                    return filtered;
                                  }}
                                  fullWidth
                                  size="small"
                                  renderInput={(params) => <TextField {...params} />}
                                  renderGroup={(params) => (
                                    <li>
                                      <GroupHeader>{params.group}</GroupHeader>
                                      <GroupItems>{params.children}</GroupItems>
                                    </li>
                                  )}
                                />
                              </TableCell>
                              <TableCell align="center" className="muitable_cell_title_type">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  name="type"
                                  value={orderItem.type}
                                  onChange={(e) => handleVarInputChange(e, index)}
                                >
                                  {userConfigs.config?.length != 0 &&
                                    userConfigs.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  minWidth: 80,
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={orderItem.qty}
                                  name="qty"
                                  onChange={(e) => handleVarInputChange(e, index)}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                  },
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={orderItem.unitCost}
                                  name="unitCost"
                                  onChange={(e) => handleVarInputChange(e, index)}
                                />
                              </TableCell>
                              <TableCell align="center" className="muitable_cell_title_uom">
                                <Select
                                  className={classes.textField}
                                  size="small"
                                  fullWidth
                                  name="uom"
                                  value={orderItem.uom}
                                  onChange={(e) => handleVarInputChange(e, index)}
                                >
                                  {userConfigsuom.config?.length != 0 &&
                                    userConfigsuom.config?.map(
                                      (el) =>
                                        el.enabled === true && (
                                          <MenuItem value={el.value}>{el.value}</MenuItem>
                                        )
                                    )}
                                </Select>
                              </TableCell>
                              <TableCell align="center">
                                {orderItem.gst === true ? (
                                  <Checkbox
                                    size="small"
                                    value={10}
                                    name="gst"
                                    defaultChecked
                                    onChange={(e) => handleChangeGST(e, index, orderItem)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                ) : (
                                  <Checkbox
                                    size="small"
                                    name="gst"
                                    value={0}
                                    // defaultChecked
                                    onChange={(e) => handleChangeGST(e, index, orderItem)}
                                  // onChange={(e) =>{if(!e.target.checked){row.gst=0;}else{row.gst=10;}}}
                                  />
                                )}
                              </TableCell>
                              {/* <TableCell
                                align="center"
                                sx={{
                                  "& input": {
                                    textAlign: "right",
                                    minWidth: 50,
                                  },
                                }}
                              >
                                <TextField
                                  id="right"
                                  variant="outlined"
                                  size="small"
                                  className={classes.textField}
                                  type="number"
                                  value={orderItem.markupPercentage}
                                  name="markupPercentage"
                                  onChange={(e) => handleVarInputChange(e, index)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <PercentIcon
                                          style={{
                                            fontSize: "15px",
                                            margin: "-10px",
                                            color: "3A3B3C",
                                            border: "redius",
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>{" "} */}
                              <TableCell>
                                <ButtonGroup variant="contained" size="small">
                                  <Button
                                    size="small"
                                    color="secondary"
                                    className="mr10"
                                    key={orderItem.itemId}
                                    onClick={(e) => clearLineItem(orderItem)}
                                  >
                                    <ClearIcon fontSize="small" />
                                  </Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody>
                          {variationObject?.map((orderItem, i) => (
                            <TableRow>
                              <TableCell>{orderItem.itemCode}</TableCell>
                              <TableCell>{orderItem?.itemName}</TableCell>
                              <TableCell>{orderItem.itemDescription}</TableCell>
                              <TableCell align="center">{orderItem.itemGroupCode}</TableCell>
                              <TableCell align="center">{orderItem.itemGroupName}</TableCell>
                              <TableCell align="center">{orderItem.type}</TableCell>
                              <TableCell align="right">{orderItem.qty}</TableCell> {/* orderItem.qty */}
                              <TableCell align="right">
                                <NumberFormat
                                  value={(orderItem.unitCost * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </TableCell>
                              <TableCell align="center">{orderItem.uom}</TableCell>
                              <TableCell align="right">
                                <NumberFormat
                                  value={(
                                    orderItem.qty * orderItem.unitCost +
                                    (orderItem.qty * orderItem.unitCost) / 100
                                  ).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                                { }
                              </TableCell>
                              {/* total Cost */}
                              <TableCell align="center">
                                <NumberFormat
                                  value={(getGst(orderItem.gst) * 1).toFixed(1)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />
                              </TableCell>
                              {/* <TableCell align="right">
                                {" "}
                                <NumberFormat
                                  value={(orderItem.markupPercentage * 1).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix="%"
                                />{" "}
                              </TableCell> */}
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                )}
              </Grid>
              <br />
              <Grid
                container
                spacing={0}
                mt={2}
                mb={1}
                direction="column"
                justifyContent="center"
                alignItems="flex-end"
              >
                <Grid xs={4}>
                  <Table
                    size="small"
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <TableRow>
                      <TableCell align="left" variant="head">
                        {"Sub Total:"}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={finalSubTotal.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        {"GST :"}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={finalTax.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        {"Variation Total : "}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          value={finalTotal.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </TableCell>
                    </TableRow>
                  </Table>
                </Grid>
              </Grid>
              {!isEdit && (
                <Grid
                  container
                  xs={12}
                  mt={2}
                  style={{
                    float: "right",
                  }}
                >
                  <Grid xs={12} sm={12} align="right">
                    <Button
                      type="submit"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={(e) => handleSubmit(e)}
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.button}
                      onClick={closeDrawer}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </Drawer>
      <DeleteAlertPop
        deleteItemId={deleteItemId}
        setVariationObject={setVariationObject}
        setVariationObj={setVariationObj}
        variationObj={variationObj}
        setConfirmDelete={setConfirmDelete}
        confirmDelete={confirmDelete}
      />
    </>
  );
}
