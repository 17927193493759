/** @format */

import apis from "../../../utils/apis";

export const createInvoice = (
  invoiceData,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type,
  setRoundLoader,
  setInvoiceState,
  setInvoiceData
) => {
  setRoundLoader(true);
  let apiName;
  type === "purchaseOrder" ? (apiName = "purchase-invoice") : (apiName = "work-invoice");
  apis
    .POST({
      endpoint: `/${apiName}`,
      payload: invoiceData,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setSnackbarMSG(" Invoice Created Successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setInvoiceState({ ["right"]: false });

        setOpenSnackbar(true);
        setInvoiceData({
          orderId: "",
          referenceNo: "",
          invoiceDate: new Date()
            .toLocaleString("en-us", { year: "numeric", month: "2-digit", day: "2-digit" })
            .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2"),
          notes: "",
          invoiceLineItems: [],
        });
      } else {
        setSnackbarMSG("Invoice Creation Failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};

export const getAllInvoicesByOrderId = (orderId, setInvoices, setDataLoaded, type) => {
  let apiName;
  type === "purchaseOrder" ? (apiName = "purchase-invoices") : (apiName = "work-invoices");

  apis
    .GET({
      endpoint: `/${apiName}/${orderId}`,
    })
    .then((res) => {
      setInvoices(res.data);
      setDataLoaded(true);
    })
    .catch((err) => console.log("No invoice data "));
};

export const deleteInvoiceByInvoiceId = (
  orderId,
  invoiceId,
  invoices,
  setInvoices,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type
) => {
  let apiName;
  type === "purchaseOrder" ? (apiName = "purchase-invoice") : (apiName = "work-invoice");

  let sortedData = invoices.filter((invoice) => {
    return invoice.invoiceId != invoiceId;
  });
  apis
    .DELETE({
      endpoint: `/${apiName}/${orderId}/${invoiceId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setInvoices(sortedData);
        setOpenSnackbar(true);
        setSnackbarMSG("Invoice deleted successfully");
        setSnackbarSeverity("success");
      } else {
        setOpenSnackbar(true);
        setSnackbarMSG("Invoice Deletion failed");
        setSnackbarSeverity("error");
      }
    });
};

export const getInvoiceByInvoiceId = (orderId, invoiceId, setInvoiceData, setLoading, type) => {
  let apiName;
  type === "purchaseOrder" ? (apiName = "purchase-invoice") : (apiName = "work-invoice");

  apis
    .GET({
      endpoint: `/${apiName}/${orderId}/${invoiceId}`,
    })
    .then((res) => {
      setInvoiceData(res.data);
      setLoading(false);
    })
    .catch((err) => console.log("No invoice data "));
};

export const updateInvoice = (
  invoiceData,
  invoices,
  setInvoices,
  setOpenSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  type,
  setRoundLoader,
  setInvoiceState
) => {
  setRoundLoader(true);
  let apiName;
  type === "purchaseOrder" ? (apiName = "purchase-invoice") : (apiName = "work-invoice");

  let sortedData = JSON.parse(JSON.stringify(invoices));
  sortedData.map((item, i) => {
    if (item.invoiceId === invoiceData.invoiceId) {
      sortedData[i] = invoiceData;
    }
  });

  apis
    .PUT({
      endpoint: `/${apiName}`,

      payload: invoiceData,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setInvoices(sortedData);
        setSnackbarMSG("Invoice  Updated successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setInvoiceState({ ["right"]: false });
        setOpenSnackbar(true);
      } else {
        setSnackbarMSG("Invoice  Update failed!");
        setSnackbarSeverity("error");
        setRoundLoader(false);

        setOpenSnackbar(true);
      }
    });
};
