/** @format */

import React, { useState, useEffect } from "react";
import { Grid, FormControlLabel, Checkbox, InputAdornment } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { createClient, updateClientbyId } from "../clients/clients.helpers";
import ErrorStyles from "../UI/ErrorStyles";
import SimpleBackdrop from "../UI/SimpleBackdrop";

const useStyles = makeStyles((theme) => ({
  Drawerroot: {
    width: "100%",
    padding: 16,
  },
  textField: {
    width: "100%",
  },
}));

export default function ClientForm({
  toggleDrawer,
  Controller,
  handleSubmit,
  errors,
  control,
  getValues,
  state,
  setState,
  isSaveClicked,
  setisSaveClicked,
  wantToCallGet,
  setWantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setData,
  clientData,
  showPostelAddress,
  setShowPostelAddress,
}) {
  const ErrorClasses = ErrorStyles();
  const classes = useStyles();
  const [roundLoader, setRoundLoader] = useState(false);
  const [clientDetails, setClientDetails] = useState();
  const handleClose = (e) => {
    setState({ ...state, ["right"]: false });
    setisSaveClicked(true);
  };
  useEffect(() => {
    let clientdata = getValues();
    setClientDetails(clientdata);
  }, []);
  const handleUpdateSubmit = (e) => {
    if (showPostelAddress) {
      let ClientDetails = getValues();
      let clientId = ClientDetails.clientId;
      console.log("ClientDetails333", ClientDetails);
      if (showPostelAddress) {
        ClientDetails.Postalmobile = ClientDetails?.mobile;
        ClientDetails.postalAddress = ClientDetails?.address;
        ClientDetails.postalCityOrTown = ClientDetails?.cityOrTown;
        ClientDetails.postalState = ClientDetails?.state;
        ClientDetails.postalPostcode = ClientDetails?.postcode;
        ClientDetails.postalPhone = ClientDetails?.phone;
      }
      /*deleting clientId of this object  */
      delete ClientDetails["clientId"];
      updateClientbyId(
        clientId,
        ClientDetails,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setState,
        setClientDetails,
        setData,
        clientData
      ); //API call For Update
    } else {
      let ClientDetails = getValues();
      let clientId = ClientDetails.clientId;
      /*deleting clientId of this object  */
      delete ClientDetails["clientId"];
      updateClientbyId(
        clientId,
        ClientDetails,
        setOpenCustSnackbar,
        setSnackbarMSG,
        setSnackbarSeverity,
        setRoundLoader,
        setState,
        setClientDetails,
        setData,
        clientData
      ); //API call For Update
    }
  };

  const submitClientDetails = (data) => {
    setClientDetails(data);
    if (showPostelAddress) {
      data.Postalmobile = data?.mobile;
      data.postalAddress = data?.address;
      data.postalCityOrTown = data?.cityOrTown;
      data.postalState = data?.state;
      data.postalPostcode = data?.postcode;
      data.postalPhone = data?.phone;
    }
    data.emailAddress = data?.emailAddress;
    console.log("clientDetails15", data);
    createClient(
      data,
      setOpenCustSnackbar,
      setSnackbarMSG,
      setSnackbarSeverity,
      setRoundLoader,
      setState,
      setData,
      clientData
    );
    setisSaveClicked(true);

    setShowPostelAddress(true);
  };

  const handleSamePostalAddress = () => {
    let ClientDetails = getValues();
    ClientDetails.Postalmobile = ClientDetails?.mobile;
    ClientDetails.postalAddress = ClientDetails?.address;
    ClientDetails.postalCityOrTown = ClientDetails?.cityOrTown;
    ClientDetails.postalState = ClientDetails?.state;
    ClientDetails.postalPostcode = ClientDetails?.postcode;
    ClientDetails.postalPhone = ClientDetails?.phone;
    setClientDetails(ClientDetails);

    console.log(ClientDetails, "ClientDetails");
    setShowPostelAddress(!showPostelAddress);
  };
  const formatPhoneNumber = (inputNumber) => {
    // Remove any non-numeric characters from the input
    const numericOnly = inputNumber.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 3 and format accordingly
    if (numericOnly.length <= 3) {
      return numericOnly;
    } else if (numericOnly.length <= 6) {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3)}`;
    } else {
      return `${numericOnly.slice(0, 3)}-${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 9)}`;
    }
  };
  function formatMobileNumber(number) {
    // Remove all non-numeric characters from the input
    const numericOnly = number.replace(/\D/g, "");

    // Check if the numericOnly length is greater than 4 and format accordingly
    if (numericOnly.length <= 4) {
      return numericOnly;
    } else if (numericOnly.length <= 7) {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4)}`;
    } else {
      return `${numericOnly.slice(0, 4)}-${numericOnly.slice(4, 7)}-${numericOnly.slice(7, 10)}`;
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <div style={{ marginTop: "5px", marginRight: "18px" }}>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("left", false)}
          PaperProps={{
            sx: {
              width: "30%",
            },
          }}
        >
          <div className={classes.Drawerroot}>
            {roundLoader && <SimpleBackdrop />}
            <form onSubmit={handleSubmit(submitClientDetails)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {isSaveClicked ? (
                    <Typography variant="h4" color="primary" mb={1} style={{ fontSize: "20px" }}>
                      New Client
                    </Typography>
                  ) : (
                    <Typography variant="h4" color="primary" mb={1} style={{ fontSize: "20px" }}>
                      Update Client
                    </Typography>
                  )}
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        label="Client Name"
                        size="small"
                        className={classes.textField}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.name?.type === "required" && "First name is required"}
                  </p>
                </Grid>
                <Grid item xs={12} my={-2}>
                  <InputLabel id="labelClientGroup">Client Type</InputLabel>
                  <Controller
                    name="clientType"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <Select {...field} className={classes.textField} size="small">
                        <MenuItem value="Archived">Archived</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Lead">Lead</MenuItem>
                        <MenuItem value="Person">Person</MenuItem>
                        <MenuItem value="Customer">Customer</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.clientType && "Client Type required"}
                </Grid>
                <Grid item xs={12} mt={1.5}>
                  <Controller
                    name="address"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Address"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                      />
                    )}
                  />
                  {errors.address && "Address required"}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="cityOrTown"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="City / Town"
                        variant="outlined"
                        size="small"
                        className={classes.textField}
                      />
                    )}
                  />

                  {errors.cityOrTown && "City/Town required"}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="State"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                      />
                    )}
                  />
                  {errors.stateClient && "State required"}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="postcode"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Postcode"
                        type="number"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                      />
                    )}
                  />
                  {errors.postcode && "Postcode required"}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Phone"
                        size="small"
                        variant="outlined"
                        className={classes.textField}
                        rules={{
                          required: false,
                          minLength: 9,
                          maxLength: 9,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                        }}
                        onChange={(e) => {
                          const formattedNumber = formatPhoneNumber(e.target.value);
                          field.onChange(formattedNumber);
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {errors.phone && "Phone required"}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="mobile"
                    control={control}
                    rules={{ required: false }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mobile"
                        variant="outlined"
                        size="small"
                        rules={{
                          required: false,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                        }}
                        onChange={(e) => {
                          const formattedNumber = formatMobileNumber(e.target.value);
                          field.onChange(formattedNumber);
                        }}
                        className={classes.textField}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.mobile?.type === "required" && "Mobile Number required"}
                    {errors.mobile?.type === "minLength" && "Please enter valid Mobile number"}
                    {errors.mobile?.type === "maxLength" && "Please enter valid Mobile number"}
                  </p>
                </Grid>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={handleSamePostalAddress}
                        defaultChecked
                        value={showPostelAddress}
                      />
                    }
                    label="Postal Address Same As Address"
                    size="small"
                  />
                </Grid>
                {!showPostelAddress && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="postalAddress"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Address"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.address && "postalAddress required"}
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="postalCityOrTown"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal City / Town"
                            variant="outlined"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      />

                      {errors.postalCityOrTown && "City/Town required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalState"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal State"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.postalState && "postalState required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalPostcode"
                        control={control}
                        rules={{
                          required: false,
                        }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Post code"
                            type="number"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                          />
                        )}
                      />
                      {errors.postalPostcode && "PostalPostcode required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="postalPhone"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Phone"
                            size="small"
                            variant="outlined"
                            className={classes.textField}
                            rules={{
                              required: false,
                              minLength: 9,
                              maxLength: 9,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatPhoneNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                          />
                        )}
                      />
                      {errors.postalPhone && "postalPhone required"}
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="Postalmobile"
                        control={control}
                        rules={{ required: false }}
                        className="input"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Postal Mobile"
                            variant="outlined"
                            size="small"
                            rules={{
                              required: false,
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+61 </InputAdornment>,
                            }}
                            onChange={(e) => {
                              const formattedNumber = formatMobileNumber(e.target.value);
                              field.onChange(formattedNumber);
                            }}
                            className={classes.textField}
                          />
                        )}
                      />
                      <p className={ErrorClasses.errorMsg}>
                        {errors.Postalmobile?.type === "required" && "Postalmobile Number required"}
                        {errors.Postalmobile?.type === "minLength" && "Please enter valid Mobile number"}
                        {errors.Postalmobile?.type === "maxLength" && "Please enter valid Mobile number"}
                      </p>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="emailAddress"
                    control={control}
                    rules={{
                      required: true,
                      pattern:
                        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }}
                    className="input"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        size="small"
                        onChange={field.onChange}
                        variant="outlined"
                        className={classes.textField}
                      />
                    )}
                  />
                  <p className={ErrorClasses.errorMsg}>
                    {errors.emailAddress?.type === "required" && "Email required"}
                    {errors.emailAddress?.type === "pattern" && "Please enter a valid email"}
                  </p>
                </Grid>
                <Grid item xs={12} align="right">
                  {isSaveClicked ? (
                    <Button color="primary" size="small" type="submit" variant="contained">
                      save
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      size="small"
                      color="primary"
                      onClick={handleUpdateSubmit}
                      variant="contained"
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    style={{ marginLeft: "8px" }}
                    onClick={(e) => handleClose(e)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Drawer>
      </div>
    </div>
  );
}
