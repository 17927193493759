/** @format */

import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, Switch, Route, BrowserRouter, matchPath } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Takeoff from "../estimates/TakeoffPlans/Takeoff";
import Plans from "../estimates/TakeoffPlans/Plans";
import EstimateDetail from "../estimates/EstimateDetails/EstimateDetail";
import Quoterequests from "../quotes/quoterequests/Quoterequests";
import { useHistory } from "react-router-dom";
import { AppBar, Button, Grid, Tabs, Tab, Box } from "@mui/material";
import HeaderTotal from "../estimates/EstimateCosting/HeaderTotal";
import { getEstimatesbyID, getEstimatesbyIdForEstimateDetails } from "../estimates/estimates.helpers";
// import { getUserConfigsTypes, getUserConfigsUom } from "../UI/GlobalApi";
import CreateJobDrawer from "../jobs/CreateJobDrawer";
import { useForm, Controller } from "react-hook-form";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import JobPlanItemsRow from "../cost-center/job-plan/JobPlanItemsRow";
import {
  ESTIMATE_DELETE,
  ESTIMATE_READ,
  ESTIMATE_WRITE,
  IS_ADMIN,
  JOB_PLAN_WRITE,
  QUOTE_DELETE,
  QUOTE_READ,
  QUOTE_WRITE,
} from "../UI/GlobalVariables";
import { OAuthCtx } from "../../oauth/OAuthContext";
import ProgressBar from "../UI/ProgressBar";
import InfoIcon from "@mui/icons-material/Info";
import CustomizedSnackbars from "../UI/CustomizedSnackbars";
import Specifications from "../cost-center/specifications/Specifications";
import CatalogueNav from "./CatalogueNav";
import Catalogue from "../catalogue/Catalogue";
import FileManager from "../files/FileManager";
const drawerWidth = 240;
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", //chgd from flex-end to this
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    // overflow: 'auto',
    flexDirection: "column",
    backgroundColor: "#ccc",
  },
  fixedHeight: {
    height: "auto",
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function EstimateNav(props) {
  const { hasValidAccess } = React.useContext(OAuthCtx);
  const [snackbarMSG, setSnackbarMSG] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // console.log("His-> ",window.location);
  var { estimateId, tabname } = useParams();
  const [load, setLoad] = useState(true);
  const [loadForPlans, setLoadForPlans] = useState(true);
  // const [userConfigs, setUserConfigs] = useState({});
  // const [userConfigsuom, setUserConfigsuom] = useState({});
  const [clientObject, setClientObject] = React.useState([]);

  const [createJobOpenDrawer, setCreateJobOpenDrawer] = useState(false);
  const [state, setState] = React.useState({
    right: true,
  });
  const history = useHistory();
  const [row, setRow] = React.useState([]);
  const [isJobCreated, setJobCreated] = useState(false);
  const [quote, setQuote] = useState(0);
  const [value, setVal] = React.useState(0);
  const [estimateStatus, setEstimateStatus] = React.useState("");
  const handleChange = (event, newValue) => {
    setVal(newValue);
    console.log("value ", value);
  };


  const handleChangenav = (event, newValue) => {
    setVal("/estimate/:estimateId/take-offs");
    console.log("value1", value);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (open) => (event) => {
    setState({ right: open });
  };
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleCreateJob = () => {
    setState({ right: open });
    setCreateJobOpenDrawer(true);
    let newObj = {
      estimateId: row.estimateId,
      description: row.description,
      expectedStartDate: undefined,
    };
    reset(newObj);
    console.log("cusotmerNme", row.customerName);
    console.log("opened", newObj);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const location = useLocation();
  const routes = [
    {
      key: "details",
      exact: true,
      path: "/estimate/:estimateId/details",
    },
    {
      exact: true,
      key: "take-offs",
      path: "/estimate/:estimateId/take-offs",
    },
    {
      exact: true,
      key: "costing",
      path: "/estimate/:estimateId/costing",
    },
    {
      exact: true,
      key: "quoterequests",
      path: "/estimate/:estimateId/quoterequests/:estimateQuoteId",
    },
    {
      exact: true,
      key: "quoterequests",
      path: "/estimate/:estimateId/quoterequests",
    },
    {
      exact: true,
      key: "specification",
      path: "/estimate/:estimateId/specification",
    },
    {
      exact: true,
      key: "files",
      path: "/estimate/:estimateId/files",
    },
  ];
  const currentRoute = routes.find((route) => matchPath(location?.pathname, route));
  useEffect(() => {
    setVal(currentRoute?.path);

    getEstimatesbyIdForEstimateDetails(estimateId, setRow, setClientObject, setLoad, setEstimateStatus); //get estimates
    // getUserConfigsTypes(setUserConfigs, setLoadForPlans); //get all configs Types
    // getUserConfigsUom(setUserConfigsuom, setLoadForPlans); //get all configs Uom
  }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        <Grid container spacing={3}>
          {load !== true ? (
            <Grid item xs={12}>
              <EstimateDetail
                row={row}
                clientObject={clientObject}
                setRow={setRow}
                setClientObject={setClientObject}
                estimateId={estimateId}
                setEstimateStatus={setEstimateStatus}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ProgressBar />
            </Grid>
          )}
          <Grid item xs={12}>
            <AppBar
              className="innerHeader_Nav"
              color="inherit"
              position="static"
              style={{
                border: "none",
                boxShadow: "none",
              }}
              my={2}
            >
              <Tabs
                noWrap
                className={classes.title}
                value={value}
                // value={'/estimate/:estimateId/details'}
                inkBarStyle={{ background: "blue" }}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  label="Estimate Costing"
                  value={"/estimate/:estimateId/costing"}
                  component={Link}
                  to={"/estimate/" + estimateId + "/costing"}
                  {...a11yProps(2)}
                />
                <Tab
                  label="Plans"
                  {...a11yProps(1)}
                  value={"/estimate/:estimateId/take-offs"}
                  component={Link}
                  to={"/estimate/" + estimateId + "/take-offs"}
                />

                <Tab
                  label="Request for Quotes"
                  value={"/estimate/:estimateId/quoterequests"}
                  component={Link}
                  to={"/estimate/" + estimateId + "/quoterequests"}
                  {...a11yProps(3)}
                />
                <Tab
                  label="Specification"
                  value={"/estimate/:estimateId/specification"}
                  component={Link}
                  to={"/estimate/" + estimateId + "/specification"}
                  {...a11yProps(4)}
                />
                <Tab
                  label="Files"
                  value={"/estimate/:estimateId/files"}
                  component={Link}
                  to={"/estimate/" + estimateId + "/files"}
                  {...a11yProps(4)}
                />
                {hasValidAccess(JOB_PLAN_WRITE) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                      marginRight: 12,
                    }}
                  >

                    {row?.jobId !== null ? (
                      <Button
                        onClick={() => history.push(`/job/${row?.jobId}/Costings`)}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        startIcon={<InfoIcon />}
                      >
                        view Job
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => handleCreateJob(e)}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleSharpIcon />}
                      >
                        Create New Job
                      </Button>
                    )}
                  </div>
                )}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {/* <div style={{ padding: "3px" }}>&nbsp;</div> */}
        <Switch>
          {/* Start costing */}
          <Route
            path={"/estimate/:estimateId/costing"}
            render={() => (
              <div>
                <JobPlanItemsRow
                  handleChange={handleChangenav}
                  type={"estimate"}
                  editableJobplan={true}
                  setValue={setVal}
                  estimateStatus={estimateStatus}
                  estimateId={estimateId}
                  calledFrom={"estimate"}
                />
                {/* <HeaderTotal quote={quote} setQuote={setQuote} /> */}
              </div>
            )}
          />
          {/* END costing */}
          {/* Start Plans & Take-offs */}
          <Route
            path={"/estimate/:estimateId/take-offs"}
            render={() => (
              <Grid container spacing={4}>
                {/* Takeoff and Plans*/}
                {/* <Grid item xs={6}>
                  <Takeoff />
                </Grid> */}
                <Grid item xs={12}>
                  <Plans estimateId={estimateId} estimateStatus={estimateStatus} />
                </Grid>
              </Grid>
            )}
          />
          {/* END Plans & Take-offs */}

          {/* Start Estimate Detail */}
          {/* <Route
            path={"/estimate/:estimateId/details"}
            render={() =>
              load ? (
                <ProgressBar />
              ) : (
                <EstimateDetail
                  row={row}
                  clientObject={clientObject}
                  setRow={setRow}
                  setClientObject={setClientObject}
                />
              )
            }
          /> */}
          {/* END Estimate Detail */}

          {/* Start Quoterequests */}
          <Route
            path={[
              "/estimate/:estimateId/quoterequests/:estimateQuoteId",
              "/estimate/:estimateId/quoterequests",
            ]}
            render={() => (
              <Quoterequests
                isCameFromNavigation={true}
                handleChange={handleChange}
                estimateStatus={estimateStatus}
              />
            )}
          />
          {/* END Quoterequests */}

          {/* Start Quoterequests */}
          <Route
            path={"/estimate/:estimateId/specification"}
            render={() => (
              <div>
                <Specifications
                  type={"estimate"}
                  editableJobplan={true}
                  estimateStatus={estimateStatus}
                />
              </div>
            )}
          />
          <Route
            path={"/estimate/:estimateId/files"}
            render={() => (
              <div>
                <FileManager callFrom={"estimate"} />
              </div>
            )}
          />
          <Route
            path={"/catalogue/:contactId/:catalogueId/items"}
            render={() => (
              <div>
                <CatalogueNav />
              </div>
            )}
          />

          {/* END Quoterequests */}
        </Switch>
        <Grid container spacing={3} direction="row" alignItems="center">
          {createJobOpenDrawer && (
            <CreateJobDrawer
              state={state}
              setState={setState}
              quote={quote}
              handleSubmit={handleSubmit}
              Controller={Controller}
              control={control}
              setValue={setValue}
              getValues={getValues}
              isDisabled={true}
              setJobCreated={setJobCreated}
              setCreateJobOpenDrawer={setCreateJobOpenDrawer}
              toggleDrawer={toggleDrawer}
              setOpenSnackbar={setOpenSnackbar}
              setSnackbarMSG={setSnackbarMSG}
              setSnackbarSeverity={setSnackbarSeverity}
              estimateId={estimateId}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {openSnackbar && (
            <CustomizedSnackbars
              openSnackbar={openSnackbar}
              setOpenSnackbar={setOpenSnackbar}
              snackbarMSG={snackbarMSG}
              severity={snackbarSeverity}
            />
          )}
        </Grid>
      </BrowserRouter>
    </React.Fragment>
  );
}
