/** @format */

import apis from "../../utils/apis";
import moment from "moment";

import MomentDate from "../UI/MomentDate";
import { checkDate, formatDate } from "../UI/GlobalApi";
export const createQuoteRequest = (
  quoteRequestarr,
  setResponsesQuoteData,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setCreateQuote,
  wantToCallGetJobPlan,
  setWantToCallGetJobPlan,
  setRoundLoader,
  setQuoteStatus,
  setOpenErrDialog,
  setErrorCode,
  setErrorMsg,
  history,
  estimateId,
  setValue
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/quote",
      payload: quoteRequestarr,
      options: { type: "json" },
    })
    .then((res) => {
      console.log("data posting 2>>>", res);
      if (res.status == "200") {
        let location = {
          pathname: `/estimate/${estimateId}/quoterequests`,
        };

        wantToCallGetJobPlan ? setWantToCallGetJobPlan(false) : setWantToCallGetJobPlan(true);
        setSnackbarMSG("Quote Created successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setQuoteStatus({ ["right"]: false });
        setOpenSnackbar(true);
        setResponsesQuoteData(true);
        setCreateQuote([]);
        setValue(`/estimate/:estimateId/quoterequests`);
        history.push(location);
        console.log("data posting >>>", res);
      } else {
        if (res.data.errorCode && res.data.errorMessage) {
          setErrorCode(res.data.errorCode);
          setErrorMsg(res.data.errorMessage);
        } else {
          setErrorCode("Something went wrong");
          setErrorMsg("Issue in server");
        }
        setResponsesQuoteData(false);
        setRoundLoader(false);
        setOpenErrDialog(true);
        setCreateQuote([]);
      }
    })
    .catch((error) => {
      setErrorCode("Something went wrong");
      setErrorMsg("Issue in server");
      setRoundLoader(false);
      setOpenErrDialog(true);
    });
};

export const getQuoteRequest = (setGetQuoteData, setFirstLoader) => {
  apis
    .GET({
      endpoint: `/quotes`,
    })
    .then((res) => {
      console.log("res in quote-- ", res.data);
      if (res.status === 200) {
        var quoteAllData = res.data.sort((a, b) => {
          return b.quoteNo - a.quoteNo;
        });
        /* It is for getting days between deadlineDate and currentDate */
        // quoteAllData.map((res) => {
        //   var current = new Date();
        //   var diff = 0;
        //   var days = 1000 * 60 * 60 * 24;
        //   let expry = new Date(res.expiryDate);
        //   diff = expry.getTime() - current.getTime();
        //   let deadLineDays = Math.ceil(diff / days);
        //   if (deadLineDays > 0) {
        //     res.expiryDate = res.expiryDate;
        //   } else {
        //     res.expiryDate = "expired";
        //   }
        // });
        var now = moment();

        quoteAllData.map((res) => {
          let date = moment(res.expiryDate);
          if (now > date) {
            console.log("Date is Past");
            res.expiryDate = "expired";
          }
        });
        setGetQuoteData(quoteAllData);
        setFirstLoader(false);
        //console.log("get quote data ", quoteAllData);
      }
    });
};

/* ----------------------------------------------------------------- */

export const getQuoteDataAPI = (
  quoteID,
  setGetQuoteDataID,
  setLocalQuoteItemList,
  setApiQuoteItemList,
  setSecondLoader
) => {
  setSecondLoader(true);
  apis
    .GET({
      endpoint: `/quote/${quoteID}`,
    })
    .then((res) => {
      console.log(res.data);

      setGetQuoteDataID(res.data);
      setLocalQuoteItemList(JSON.parse(JSON.stringify(res.data.quoteLineItems)));
      setApiQuoteItemList(JSON.parse(JSON.stringify(res.data.quoteLineItems)));
      setSecondLoader(false);
    });
};
export const getEstimateLineItemList = (estimateId, setTempEstmtLineItemList, setEstmtLineItemList) => {
  apis
    .GET({
      endpoint: `/estimate/line-items/list/${estimateId}`,
    })
    .then((res) => {
      if (res.status === 200) {
        let filteredData = res.data.map((obj) => {
          return {
            description: obj.itemDescription,
            estimateLineItemId: obj.lineItemId,
            qty: obj.qty,
            quoteLineItemId: null,
            type: obj.type,
            uom: obj.uom,
          };
        });

        setTempEstmtLineItemList(JSON.parse(JSON.stringify(filteredData)));
        setEstmtLineItemList(JSON.parse(JSON.stringify(filteredData)));
      } else {
        setTempEstmtLineItemList([]);
        setEstmtLineItemList([]);
      }
    });
};
export const updateQuoteRequest = (
  arr,
  setResponsesQuoteData,
  setWantToCallGet,
  wantToCallGet,
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMSG,
  setRoundLoader,
  setState,
  setRefreshQuote
) => {
  setRoundLoader(true);
  apis
    .PUT({
      endpoint: `/quote`,

      payload: arr,

      options: { type: "json" },
    })

    .then((res) => {
      if (res.data.message == "SUCCESS") {
        setRefreshQuote(true);
        setResponsesQuoteData(true);
        console.log("update data posting >>>", arr);
        setSnackbarMSG("Quote Response  updated successfully!");
        setSnackbarSeverity("success");
        setRoundLoader(false);
        setState({ ["right"]: false });
        setOpenSnackbar(true);

        if (wantToCallGet) {
          setWantToCallGet(false);
        } else {
          setWantToCallGet(true);
        }
      } else {
        setResponsesQuoteData(false);
        setSnackbarMSG("Quote Response updation unsuccessfull!");
        setSnackbarSeverity("error");
        setRoundLoader(false);
        setOpenSnackbar(true);
      }
    });
};

//for sent quote by nikita
export const getSentQuote = (
  setSentQuotes,
  setTitle,
  setQuoteId,
  setQuoteNo,
  setMessage,
  setExpiryDate,
  setQuoteLineItems,
  quoteId,
  token,
  setLoading,
  setIsEdit,
  setBusinessAddress,
  setDeliveryAddress,
  setNotes,
  setCcTo
) => {
  apis
    .GET({
      endpoint: `/sent-quote/${quoteId}?token=${token}`,
    })
    .then((res) => {
      if (checkDate(res.data.expiryDate)) {
        console.log("Date is Past");
        setIsEdit(false);
      } else {
        setIsEdit(true);
        console.log("Date is not Past");
      }
      setSentQuotes(res.data);
      setBusinessAddress(res.data.businessAddress);
      setDeliveryAddress(res.data.deliveryAddress);
      setTitle(res.data.title);
      setQuoteId(res.data.quoteId);
      setQuoteNo(res.data.quoteNo);
      setMessage(res.data.message);
      setNotes(res.data.notes);
      setCcTo(res.data.ccEmailAddresses);
      // setExpiryDate(today.toLocaleDateString("en-US", options));
      // let tempObj = res.data.quoteLineItems.filter((ele) => {
      //   return Object.assign(ele, { gst: 0, unitCost: 0 });
      // });
      // setExpiryDate(MomentDate(res.data.expiryDate));
      setExpiryDate(formatDate(res.data.expiryDate));

      setQuoteLineItems(res.data.quoteLineItems);
      setLoading(false);
      console.log("sent quotes >>>", res.data);
    });
};

export const createSentQuote = (
  data,
  quoteId,
  token,
  setRoundLoader,
  setActiveStep,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  // var obj = {
  //   quoteResponseLineItems: quoteResarr,
  // };
  setRoundLoader(true);
  apis
    .POST({
      endpoint: `/quote-response/${quoteId}?token=${token}`,
      payload: data,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.status === 200) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Your Quote is Submitted");
        setSnackbarSeverity("success");
      } else {
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Your Quote is Submitted failed ! ");
        setSnackbarSeverity("error");
      }
    });
};

//Get Quote response
export const getQuoteResponseByQuoteId = (quoteID, setPosts, setLoading, setData) => {
  apis
    .GET({
      endpoint: `/sent-quotes/${quoteID}`,
    })
    .then((res) => {
      console.log("quoteID Response", res.data);
      setData(res.data);
      setPosts(res.data);
      console.log("2");
      setLoading(false);
    });
};
//Vire response in detail
export const getRespoDataFromAPI = (quoteResDataSelect, setSupplierData, setLoaderForResposeForm) => {
  apis
    .GET({
      endpoint: `/quote/response/${quoteResDataSelect.quoteId}/${quoteResDataSelect.contactId}`,
    })
    .then((res) => {
      setSupplierData(res.data);
      setLoaderForResposeForm(false);
      console.log("/quote/response/", res);
    });
};

/* GettingContact */
export const getContacts = (setContacts) => {
  apis
    .GET({
      endpoint: "/contacts",
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.contactNo - b.contactNo;
      });
      setContacts(res.data);
    });
};
/* Get Quote EstimatesId */
export const getQuoteRequestEstimateId = (estimateId, setGetQuoteData, setFirstLoader) => {
  apis
    .GET({
      endpoint: `/quotes/${estimateId}`,
    })
    .then((res) => {
      console.log("res in quote-- ", res.data);
      if (res.status === 200) {
        var quoteAllData = res.data.sort((a, b) => {
          return b.quoteNo - a.quoteNo;
        });
        /* It is for getting days between deadlineDate and currentDate */
        // quoteAllData.map((res) => {
        //   var current = new Date();
        //   var diff = 0;
        //   var days = 1000 * 60 * 60 * 24;
        //   let expry = new Date(res.expiryDate);
        //   diff = expry.getTime() - current.getTime();
        //   let deadLineDays = Math.ceil(diff / days);
        //   res.expiryDate = deadLineDays;
        // });

        var now = moment();

        quoteAllData.map((res) => {
          let date = moment(res.expiryDate);
          if (now > date) {
            console.log("Date is Past");
            res.expiryDate = "expired";
          }
        });

        setGetQuoteData(quoteAllData);
        setFirstLoader(false);
        console.log("get quote data pr", quoteAllData);
      }
    });
};

/* ---------------------get qoutes by estimate line item id------------------- */
export const getQuotesByEstimateLineItemId = (
  estimateId,
  setGetQuoteData,
  setFirstLoader,
  estimateIdLineItemId
) => {
  apis
    .GET({
      endpoint: `/quotes/${estimateId}/${estimateIdLineItemId}`,
    })
    .then((res) => {
      console.log("res in quote-- ", res.data);
      if (res.status === 200) {
        var quoteAllData = res.data.sort((a, b) => {
          return b.quoteNo - a.quoteNo;
        });
        /* It is for getting days between deadlineDate and currentDate */
        // quoteAllData.map((res) => {
        //   var current = new Date();
        //   var diff = 0;
        //   var days = 1000 * 60 * 60 * 24;
        //   let expry = new Date(res.expiryDate);
        //   diff = expry.getTime() - current.getTime();
        //   let deadLineDays = Math.ceil(diff / days);
        //   res.expiryDate = deadLineDays;
        // });
        var now = moment();

        quoteAllData.map((res) => {
          let date = moment(res.expiryDate);
          if (now > date) {
            console.log("Date is Past");
            res.expiryDate = "expired";
          }
        });
        setGetQuoteData(quoteAllData);
        setFirstLoader(false);
        console.log("get quote data pr", quoteAllData);
      }
    });
};
/* ---------------------select qoute for estimate------------------- */

export const selectQuoteForEstimate = (
  quoteResponseItem,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setWantToCallUseEffect
) => {
  apis
    .POST({
      endpoint: `/select-quote/${quoteResponseItem.quoteId}/${quoteResponseItem.contactId}`,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("res selected", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Quote Selected Successfully");
        setSnackbarSeverity("success");
        setWantToCallUseEffect(false);
      } else {
        console.log("res error", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Unable to Select Quote , Please try again");
        setSnackbarSeverity("error");
      }
    });
};

export const selectQuoteLineItemForEstimate = (
  quoteDataForEstimate,
  quoteLineItemsrow,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRefreshData,
  setRefreshViewDrawerData
) => {
  console.log("quoteDataForEstimate", quoteDataForEstimate);
  console.log("quoteLineItemsrow", quoteLineItemsrow);
  apis
    .POST({
      endpoint: `/select-quote-line-item/${quoteDataForEstimate.quoteId}/${quoteDataForEstimate.contactId}/${quoteLineItemsrow.quoteLineItemId}`,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("res selected", res);
        setRefreshData(true);
        setRefreshViewDrawerData(true);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Quote Item Selected Successfully");
        setSnackbarSeverity("success");
      } else {
        console.log("res error", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Unable to Select Quote Item , Please try again");
        setSnackbarSeverity("error");
      }
    });
};

export const deleteSelectedQuoteLineItemForEstimate = (
  quoteDataForEstimate,
  quoteLineItemsrow,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRefreshData,
  setRefreshViewDrawerData
) => {
  apis
    .DELETE({
      endpoint: `/select-quote-line-item/${quoteDataForEstimate.quoteId}/${quoteDataForEstimate.contactId}/${quoteLineItemsrow.quoteLineItemId}`,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("res selected", res);
        setRefreshData(true);
        setRefreshViewDrawerData(true);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Selected Quote Delete Successfully");
        setSnackbarSeverity("success");
      } else {
        console.log("res error", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Unable to Delete Selected Quote , Please try again");
        setSnackbarSeverity("error");
      }
    });
};

export const reminderForQuote = (
  quoteLineItemsrow,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity
) => {
  apis
    .POST({
      endpoint: `/quote/reminder/${quoteLineItemsrow.quoteId}/${quoteLineItemsrow.contactId}`,
      options: { type: "json" },
    })
    .then((res) => {
      if (res.status === 200) {
        console.log("res selected", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Reminder sent successfully");
        setSnackbarSeverity("success");
      } else {
        console.log("res error", res);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Unable to send reminder,please try again");
        setSnackbarSeverity("error");
      }
    });
};
