/** @format */

import apis from "../../../utils/apis";

export const getOutgoingInvoice = (setOutGoingInvoice, jobId, setLoading) => {
  apis
    .GET({
      endpoint: `/outgoing-invoices/${jobId}`,
    })
    .then((res) => {
      res.data.sort(function (a, b) {
        return a.outGoingInvoiceNo - b.outGoingInvoiceNo;
      });

      setOutGoingInvoice(res.data);
      console.log("OutgoingInvoice", res.data);
      setLoading(false);
    });
};
export const createOutgoingInvoice = (
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader
) => {
  setRoundLoader(true);
  apis
    .POST({
      endpoint: "/outgoing-invoice",
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setOpenCustSnackbar(true);
        setRoundLoader(false);
        setSnackbarMSG("Invoice created successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);

        console.log("Invoice Submited successFully>>>");
      } else {
        setOpenCustSnackbar(true);
        setRoundLoader(false);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("Invoice Submited failed>>>");
      }
    });
};

export const updateInvoiceByID = (
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setEditInvoiceDrawerLoading
) => {
  setRoundLoader(true);
  setEditInvoiceDrawerLoading(true);
  // setDelLoading(true);
  // setOpenSnackbar(false);

  apis
    .PUT({
      endpoint: `/outgoing-invoice`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setOpenCustSnackbar(true);
        setRoundLoader(false);
        setSnackbarMSG("Invoice updated successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setEditInvoiceDrawerLoading(false);
        console.log("Invoice updated successFully>>>");
      } else {
        setOpenCustSnackbar(true);
        setRoundLoader(false);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("Invoice updating failed>>>");
      }
    });
};

export const deleteOutgoingInvoiceByID = (
  outGoingInvoiceId,
  outgoingInvoice,
  setOutGoingInvoice,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  jobId,
  setWantToCallGet,
  wantToCallGet
) => {
  apis
    .DELETE({
      endpoint: `/outgoing-invoice/${jobId}/${outGoingInvoiceId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setOpenCustSnackbar(true);
        setSnackbarMSG("OutgoingInvoice deleted successFully");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setSnackbarSeverity("success");
        console.log("OutgoingInvoice data delete successFully>>>");
      } else {
        setOutGoingInvoice(outgoingInvoice);
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("OutgoingInvoice deleting failed>>>");
      }
    });
};

/*************** Receipt Api (GET,PUT,POST,DELETE) **************** */

export const getInvoiceReceipts = (setInvoiceReceipts, jobId, outGoingInvoiceId, setLoading) => {
  setLoading(true);
  apis
    .GET({
      endpoint: `/outgoing-invoice/receipts/${jobId}/${outGoingInvoiceId}`,
    })
    .then((res) => {
      setInvoiceReceipts(res.data);
      setLoading(false);

      console.log("Invoice Receipts ", res.data);
    })
    .catch((err) => console.log("No Invoice Receipts"));
};

export const createInvoiceReceipts = (
  obj,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setReceivedDrawer
) => {
  setRoundLoader(true);
  console.log("obj", obj);
  apis
    .POST({
      endpoint: `/outgoing-invoice/receipt`,
      payload: obj,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setOpenCustSnackbar(true);
        setSnackbarMSG("Invoice Receipts created successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setRoundLoader(false);
        setReceivedDrawer({ ["right"]: false });
        console.log("Invoice Receipts Submited successFully>>>");
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setRoundLoader(false);
        setSnackbarSeverity("error");
        console.log("Invoice Receipts Submited failed>>>");
      }
    });
};

export const updateInvoiceReceiptID = (
  receiptData,
  setWantToCallGet,
  wantToCallGet,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  setRoundLoader,
  setEditReceiptDrawer
) => {
  setRoundLoader(true);
  // setDelLoading(true);
  // setOpenSnackbar(false);
  apis
    .PUT({
      endpoint: `/outgoing-invoice/receipt`,
      payload: receiptData,
      options: {
        type: "json",
      },
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setSnackbarMSG("Receipt updated successFully");
        setSnackbarSeverity("success");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setEditReceiptDrawer({ right: false });
        console.log("Receipt updated successFully>>>");
      } else {
        setRoundLoader(false);
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("Receipt updating failed>>>");
      }
    });
};

export const deleteInvoiceReceiptByID = (
  outgoingInvoiceReceiptId,
  outGoingInvoiceId,
  setOpenCustSnackbar,
  setSnackbarMSG,
  setSnackbarSeverity,
  jobId,
  setWantToCallGet,
  wantToCallGet,
  invoiceReceipts,
  setInvoiceReceipts
) => {
  let sortedData = invoiceReceipts.filter((receipt) => {
    return receipt.receiptId != outgoingInvoiceReceiptId;
  });
  apis
    .DELETE({
      endpoint: `/outgoing-invoice/receipt/${jobId}/${outGoingInvoiceId}/${outgoingInvoiceReceiptId}`,
    })
    .then((res) => {
      if (res.data.message === "SUCCESS") {
        setInvoiceReceipts(sortedData);
        setOpenCustSnackbar(true);
        setSnackbarMSG("InvoiceReceipt deleted successFully");
        WantCallUSeEffect(setWantToCallGet, wantToCallGet);
        setSnackbarSeverity("success");

        console.log("InvoiceReceipt data delete successFully>>>");
      } else {
        setOpenCustSnackbar(true);
        setSnackbarMSG(res.data.errorMessage);
        setSnackbarSeverity("error");
        console.log("InvoiceReceipt deleting failed>>>");
      }
    });
};
export const getReceipteByReceipteId = (
  outGoingInvoiceId,
  outgoingInvoiceReceiptId,
  setReceiptData,
  jobId,
  setEditDrawerLoading
) => {
  setEditDrawerLoading(true);

  apis
    .GET({
      endpoint: `/outgoing-invoice/receipt/${jobId}/${outGoingInvoiceId}/${outgoingInvoiceReceiptId}`,
    })
    .then((res) => {
      setReceiptData(res.data);
      setEditDrawerLoading(false);
    })
    .catch((err) => console.log("No invoice data "));
};
function WantCallUSeEffect(setWantToCallGet, wantToCallGet) {
  if (wantToCallGet) {
    setWantToCallGet(false);
  } else {
    setWantToCallGet(true);
  }
}
